import { useEffect, useState } from 'react';

import { Box, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { useStyles } from './style';
import { Table } from '../../../../components';
import { isObject, decimalBase, getFinanceTableHeaders } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const ActivePeriod = (props) => {
	const { onFetchCurrentPersonalBillingPeriod, currentPersonalBillingPeriod } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);

	const { data: currentPersonalBillingPeriodData, loading: currentPersonalBillingPeriodLoading } =
		currentPersonalBillingPeriod;

	useEffect(() => {
		if (shouldDoInitialFetch && !currentPersonalBillingPeriodLoading) {
			onFetchCurrentPersonalBillingPeriod();
			setShouldDoInitialFetch(false);
		}
	}, [currentPersonalBillingPeriodLoading]);

	const tableHeader = getFinanceTableHeaders(false, false, true);

	const tableBody =
		isObject(currentPersonalBillingPeriodData) ?
			[
				[
					{
						content: (
							<Box className={classes.periodContent}>
								<Link
									className={classes.link}
									color='inherit'
									component={RouterLink}
									to={`/financeManagement/personal/${currentPersonalBillingPeriodData.start}/${currentPersonalBillingPeriodData.title}/billing`}
									variant='h6'
								>
									{currentPersonalBillingPeriodData.title}
								</Link>
							</Box>
						),
					},
					{
						content: decimalBase(
							currentPersonalBillingPeriodData.amount.subtotal,
							currentPersonalBillingPeriodData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							currentPersonalBillingPeriodData.amount.discount,
							currentPersonalBillingPeriodData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							currentPersonalBillingPeriodData.amount.subtotal -
								currentPersonalBillingPeriodData.amount.discount,
							currentPersonalBillingPeriodData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							currentPersonalBillingPeriodData.amount.priceVat,
							currentPersonalBillingPeriodData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							currentPersonalBillingPeriodData.amount.refund,
							currentPersonalBillingPeriodData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							currentPersonalBillingPeriodData.amount.totalPrice,
							currentPersonalBillingPeriodData.amount.currencySymbol,
						),
					},
					{
						content: (
							<Box className={classes.open}>
								{decimalBase(
									currentPersonalBillingPeriodData.open,
									currentPersonalBillingPeriodData.amount.currencySymbol,
								)}
							</Box>
						),
					},
				],
			]
		:	Array(2)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	return (
		<div className={classes.root}>
			<Table
				body={tableBody}
				header={tableHeader}
				isNotPaginate={true}
				loading={currentPersonalBillingPeriodLoading}
				title={t('ui.label.active')}
				withoutPages={true}
			/>
		</div>
	);
};

ActivePeriod.propTypes = {
	financeType: PropTypes.string,
	date: PropTypes.string,

	onFetchCurrentPersonalBillingPeriod: PropTypes.func,
	isSuperAdmin: PropTypes.bool,
	currentPersonalBillingPeriod: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentPersonalBillingPeriod: state.details.currentPersonalBillingPeriod,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCurrentPersonalBillingPeriod: () =>
			dispatch(actions.fetchCurrentPersonalBillingPeriod()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivePeriod);
