import { WeeklySchedule } from '~interfaces';

// With optional milliseconds
const timeRegExp = /^\d{2}:\d{2}:\d{2}(?:[.,]\d+)?$/;

/**
 * For performance
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString
 */
const datetimeWithoutSecondsFormat = new Intl.DateTimeFormat('nl-NL', {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
});

const time24Format = new Intl.DateTimeFormat('nl-NL', {
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
});

const time24FormatWithoutSeconds = new Intl.DateTimeFormat('nl-NL', {
	hour: '2-digit',
	minute: '2-digit',
});

/**
 * Parse a time to a date
 * @param time The time in the format HH:mm:ss
 * @param date The date to use. Default to the now
 * @returns
 */
const parseTime = (time: string, date = new Date()): Date | null => {
	const isTimeFormat = timeRegExp.test(time);
	if (!isTimeFormat) {
		console.warn('Unsupported time format', time);
		return null;
	}

	const timeElements = time.split(':');

	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		parseInt(timeElements[0]),
		parseInt(timeElements[1]),
		parseInt(timeElements[2]),
	);
};

// const tryMergeOverlappingSchedules = (schedules: WeeklySchedule[]): WeeklySchedule[] => {
// 	const openAllDays = schedules.filter(el => el.isOpenAllDay)

// 	return schedules;
// };

// const getOverlappingDateRanges = (
// 	dateRange
// )

export { datetimeWithoutSecondsFormat, time24Format, time24FormatWithoutSeconds, parseTime };
