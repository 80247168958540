import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 550,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%',
		padding: '12px 12px 0 12px',
	},
	description: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: theme.spacing(1.5),
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		justifyContent: 'flex-end',
		paddingRight: '0',
	},
	progress: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));
