import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	iconCell: {
		width: 73,
	},
	outgoingActionsCell: {
		width: 200,
	},
	incomingActionsCell: {
		width: 275,
	},
	avatar: {
		'& img': {
			objectFit: 'scale-down',
		},
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));
