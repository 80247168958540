import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));
