import { isObject } from '../../shared/utility';
import {
	FETCH_PLANBOARD_ITEMS_START,
	FETCH_PLANBOARD_ITEMS_SUCCESS,
	FETCH_PLANBOARD_ITEMS_FAIL,
} from '../actions/actionTypes';
import { planboardStates } from '../states';

const initialState = planboardStates.reduce(
	(state, stateName) => ({
		...state,
		[stateName]: { data: null, loading: false, error: null },
	}),
	{},
);

/* * * * * * * * * * * * * * * * * * * * * * * * *
 * METHODS THAT UPDATE STATES WITH CORRECT VALUES *
 * * * * * * * * * * * * * * * * * * * * * * * *  */
const fetchPlanboardItemsStart = (state, action) => {
	return {
		...state,
		...{ [action.identifier]: { ...state[action.identifier], ...{ loading: true } } },
	};
};

const fetchPlanboardItemsSuccess = (state, action) => {
	return {
		...state,
		...{
			[action.identifier]: {
				...state[action.identifier],
				...{
					// data: (isObject(state[action.identifier].data) && isObject(action.data) && state[action.identifier].data.pageNumber < action.data.pageNumber) ? (
					//     { ...action.data, ...{ results: [...state[action.identifier].data.results, ...action.data.results] } }
					// ) : action.data,
					data:
						!isObject(action.data) ? null
						: action.concat ?
							{
								...action.data,
								...{ items: [...state[action.identifier].data.items, ...action.data.items] },
								...{
									bookings: [...state[action.identifier].data.bookings, ...action.data.bookings],
								},
								...{
									unavailabilities: [
										...state[action.identifier].data.unavailabilities,
										...action.data.unavailabilities,
									],
								},
							}
						:	{ ...action.data },
					loading: false,
					error: null,
				},
			},
		},
	};
};

const fetchPlanboardItemsFail = (state, action) => ({
	...state,
	...{
		[action.identifier]: {
			...state[action.identifier],
			...{ data: null, success: false, loading: false, error: action.error },
		},
	},
});

/* * * * * * * * *
 * REDUCER METHOD *
 * * * * * * * *  */
// Depending on the active action type, execute the correct function to update the correct states with the correct values
// Pass two parameters, first is the old state values, second is the new state values, these are passed from actions
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_PLANBOARD_ITEMS_START:
			return fetchPlanboardItemsStart(state, action);
		case FETCH_PLANBOARD_ITEMS_SUCCESS:
			return fetchPlanboardItemsSuccess(state, action);
		case FETCH_PLANBOARD_ITEMS_FAIL:
			return fetchPlanboardItemsFail(state, action);
		default:
			return state;
	}
};

export default reducer;
