import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		paddingLeft: 0,
		paddingRight: 0,
	},
	itemText: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	inlineButton: {
		padding: 0,
	},
	instancesList: {
		width: '100%',
	},
	instance: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	expansionPanel: {
		'&.Mui-expanded': {
			margin: 0,
		},
	},
	sidepanelList: {
		width: '100%',
	},
}));
