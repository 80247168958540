import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	licenseNumber: {
		color: theme.palette.text.primary,
		fontSize: '12px',
		fontWeight: 400,
		letterSpacing: '-0.04px',
		lineHeight: '18px',
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));
