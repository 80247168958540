import React, { PropsWithChildren } from 'react';

import { Grid } from '@mui/material';

interface SummaryContainerProps extends PropsWithChildren {}

const SummaryContainer = ({ ...props }: SummaryContainerProps) => {
	return (
		<Grid container spacing={3}>
			{React.Children.map(props.children, (child, i) => (
				<Grid key={i} item xs={12} md={6} lg={6} xl={4}>
					{child}
				</Grid>
			))}
		</Grid>
	);
};

export default SummaryContainer;
