import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { StyledButton } from '../../../components';

const ActionDialog = (props) => {
	const {
		open,
		handleClose,
		actionButtonProps,
		title,
		children,
		loading,
		variant,
		noDivider,
		contentStyles,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	return (
		<Dialog classes={{ paper: classes.root }} onClose={handleClose} open={open}>
			<DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
			<DialogContent className={contentStyles ? contentStyles : classes.dialogContent}>
				{children}
			</DialogContent>
			{noDivider ? null : <Divider />}
			<DialogActions classes={{ root: classes.dialogActions }}>
				<StyledButton className={classes.button} onClick={handleClose} size='large'>
					{t('ui.button.contained.cancel')}
				</StyledButton>
				<StyledButton
					autoFocus
					className={classes.button}
					disabled={loading}
					onClick={actionButtonProps.action}
					size='large'
					variant={variant}
				>
					{actionButtonProps.text}
				</StyledButton>
			</DialogActions>
		</Dialog>
	);
};

ActionDialog.defaultProps = {
	variant: 'contained-primary',
};

ActionDialog.propTypes = {
	loading: PropTypes.bool,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	actionButtonProps: PropTypes.object,
	title: PropTypes.string,
	noDivider: PropTypes.bool,
	contentStyles: PropTypes.any,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	variant: PropTypes.string,
};

export default ActionDialog;
