import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { isNull } from './utility';
import { instanceAccess, updateListState } from '../store/actions';

function useAccess() {
	const [fetchData, setFetchData] = useState(true);
	const [itemId, setItemId] = useState(null);
	const [instanceId, setInstanceId] = useState(null);

	const dispatch = useDispatch();
	const access = useSelector((state) => state.list.instanceAccess);

	useEffect(() => {
		if (fetchData && itemId && instanceId) {
			dispatch(instanceAccess(itemId, instanceId));
			setFetchData((val) => !val);
		}

		return () => {
			if (!fetchData && isNull(access.data)) {
				dispatch(updateListState('instanceAcces', { data: null, error: null, loading: false }));
			}
		};
	}, [fetchData, itemId, instanceId]);

	return {
		access: access.data,
		setItemId,
		setInstanceId,
	};
}

export { useAccess };
