import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
	root: {
		// '& .MuiAutocomplete-inputRoot': {
		//   padding: '0px',

		// },
		'& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
			width: '5rem',
		},
	},
	drawer: {
		width: 450,
		maxWidth: '100%',
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	divider: {
		margin: theme.spacing(4.5, 0),
	},
	timeSection: {
		marginTop: '24px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	carOption: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: theme.spacing(2, 0),
	},
	freeLabel: {
		minWidth: '81px',
	},
	textField: {
		paddingLeft: '100px',
	},
	kilometerSwitch: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	allDaySpacing: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	removeButton: {
		marginTop: theme.spacing(1),
	},
}));
