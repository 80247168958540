import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		padding: 0,
		marginBottom: 'auto',
	},
	actions: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		'& > * + *': {
			marginLeft: 0,
		},
	},
	tableRow: {
		'&.Mui-selected': {
			backgroundColor: '#f5f6f8',
			'&:hover': {
				backgroundColor: '#f5f6f8',
			},
		},
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	editorContainer: {
		padding: theme.spacing(2),
		'& .public-DraftEditorPlaceholder-root': {
			...theme.typography.body2,
		},
		'& .public-DraftEditorPlaceholder-hasFocus': {
			display: 'none',
		},
		'& div': {
			...theme.typography.body1,
		},
		'& p': {
			...theme.typography.body1,
		},
		'& h1 div': {
			...theme.typography.h1,
		},
		'& h2 div': {
			...theme.typography.h2,
		},
		'& h3 div': {
			...theme.typography.h3,
		},
		'& h4 div': {
			...theme.typography.h4,
		},
		'& h5 div': {
			...theme.typography.h5,
		},
		'& h6 div': {
			...theme.typography.h6,
		},
		'& blockquote': {
			...theme.typography.subtitle1,
		},
		'& ul': {
			...theme.typography.body1,
			marginLeft: theme.spacing(4),
		},
		'& ol': {
			...theme.typography.body1,
			marginLeft: theme.spacing(4),
		},
		'& pre': {
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
			fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
			fontSize: 16,
			padding: 2,
		},
		'& .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr': {
			wordBreak: 'break-word',
		},
	},
}));
