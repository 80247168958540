import { DayOfWeekSchedule, WeeklySchedule } from '~interfaces';

const dayOfWeekToWeeklySchedule = (value: DayOfWeekSchedule): WeeklySchedule[] => {
	const { dayOfWeek, isOpen, isOpenAllDay, periods } = value;

	if (!isOpenAllDay && !isOpen) {
		return [];
	}

	if (isOpenAllDay) {
		return [
			{
				daysOfWeek: [dayOfWeek],
				isOpenAllDay: isOpenAllDay,
			},
		];
	}

	return periods.map((el) => {
		return {
			isOpenAllDay: isOpenAllDay,
			daysOfWeek: [dayOfWeek],
			period: el,
		};
	});
};

const flattenWeeklySchedules = (value: WeeklySchedule[]): WeeklySchedule[] => {
	value.sort((a, b) => {
		if (a.period?.start > b.period?.start) {
			return 1;
		}
		return -1;
	});

	return value.reduce((acc: WeeklySchedule[], el: WeeklySchedule) => {
		const last = acc[acc.length - 1];
		if (acc.length <= 0) {
			acc.push(el);
		} else if (last.period?.start === el.period?.start && last.period?.end === el.period?.end) {
			last.daysOfWeek = [...new Set([...last.daysOfWeek, ...el.daysOfWeek])];
		} else {
			acc.push(el);
		}

		return acc;
	}, []);
};

export { dayOfWeekToWeeklySchedule, flattenWeeklySchedules };
