import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(4),
	},
	filterButton: {
		marginRight: theme.spacing(2),
	},
	content: {
		padding: 0,
		'& .MuiPaper-rounded': {
			borderRadius: '0px',
		},
	},
	inner: {
		minWidth: 700,
	},
	actions: {
		padding: theme.spacing(0, 1),
		justifyContent: 'flex-end',
	},
	collapsButtonCell: {
		paddingRight: '8px',
	},
	tableContainer: {
		marginBottom: 85,
	},
	checkboxCell: {
		width: 110,
	},
	qtyCell: {
		width: 69,
	},
}));
