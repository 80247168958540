import React, { useState } from 'react';

import { Error as ErrorIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
	Box,
	Card,
	CardContent,
	CardContentProps,
	CardHeader,
	CardHeaderProps,
	CardProps,
	Divider,
	Grid,
	Icon,
	IconButton,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import { GridActionsCellItemProps } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { TooltipNew } from '~components';
import i18n from '~lib/i18n';

interface InfoCardProps extends CardProps {
	title: string;
	/**
	 * TODO: better naming. An additional component next to the actions
	 * E.g. for a status
	 */
	statusComponent?: JSX.Element;
	/**
	 * Define a list of actions connected to this card. We want to achieve
	 * the same functionality as the actions in the mui datagrid. Therefore
	 * this interface.
	 * TODO: determine if we want to remove this dependency
	 */
	actions?: GridActionsCellItemProps[];
	rows: InfoCardRowProps[];
	/**
	 * The amount of columns in the card
	 */
	columns?: number;
	loading?: boolean;
	/**
	 * Use the table layout, more in line with the classic
	 * way of Topology
	 */
	tableLayout?: boolean;
	slots?: {
		contentTop?: JSX.Element;
	};
	slotProps?: Partial<{
		cardHeader: CardHeaderProps;
		cardContent: CardContentProps;
	}>;
	error?: boolean;
	noResultsLabel?: string;
}

interface InfoCardRowProps {
	value: any;
	headerName: string;
	/**
	 * By default we render a string in the cell. Use a custom
	 * ReactNode component instead
	 * @param value
	 * @returns
	 */
	renderCell?: <T>(value: T) => React.ReactNode;
	/**
	 * Convert a value before displaying it.
	 * @param value
	 * @returns
	 */
	valueFormatter?: <T>(value: T) => string;
	/**
	 * Determine how much columns this rows spans
	 */
	columns?: number;
}

/**
 * A card for information used throughout the application
 * Inspiration taken from the mui datagrid https://mui.com/x/react-data-grid/
 * @returns
 */
const InfoCard = ({
	title,
	rows,
	columns = 2,
	statusComponent,
	actions,
	loading = false,
	error,
	tableLayout = false,
	slots,
	slotProps,
	noResultsLabel = i18n.t('noResult'),
	...cardProps
}: InfoCardProps) => {
	const { t } = useTranslation('general');
	const theme = useTheme();

	const [anchorActionsMenu, setAnchorActionsMenu] = useState<HTMLElement | null>(null);

	const handleActionsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorActionsMenu(event.currentTarget);
	};

	const handleActionsMenuClose = () => {
		setAnchorActionsMenu(null);
	};

	const TableLayout = () => (
		<Table>
			<TableBody>
				{rows.map((row, i) => (
					<TableRow key={i}>
						<TableCell sx={{ color: 'gray', fontSize: 12 }}>{row.headerName}</TableCell>
						<TableCell sx={{ fontSize: 12 }}>
							{row.renderCell ?
								row.renderCell(row.value)
							: row.valueFormatter ?
								row.valueFormatter(row.value)
							:	(row.value ?? '-')}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);

	const GridListLayout = () => (
		<Grid container>
			{rows.map((row, i) => (
				<Grid key={i} item xs={12 / (row.columns ?? columns)} py={1}>
					<Box fontFamily={theme.typography.fontFamily} fontSize={'12px'}>
						<Typography variant='body2'>{row.headerName}</Typography>
						{row.renderCell ?
							row.renderCell(row.value)
						: row.valueFormatter ?
							row.valueFormatter(row.value)
						:	<Typography fontSize='inherit'>{row.value ?? '-'}</Typography>}
					</Box>
				</Grid>
			))}
		</Grid>
	);

	return (
		<Card {...cardProps} sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
			<CardHeader
				title={title}
				{...slotProps?.cardHeader}
				action={
					<>
						{statusComponent}
						{actions
							?.filter((el) => !el.showInMenu)
							.map((el, i) => (
								<TooltipNew key={i} title={el.label}>
									<IconButton {...el}>{el.icon}</IconButton>
								</TooltipNew>
							))}
						{actions?.find((el) => el.showInMenu) && (
							<IconButton onClick={handleActionsMenuClick}>
								<MoreVertIcon />
							</IconButton>
						)}
					</>
				}
				sx={{ height: '52px', py: 1 }}
			/>
			<Divider />
			<CardContent {...slotProps?.cardContent} sx={{ flexGrow: 1 }}>
				{loading ?
					<LoadingOverlay />
				: error ?
					<ErrorOverlay />
				: rows.length <= 0 ?
					<NoContentOverlay label={noResultsLabel} />
				:	<>
						{slots?.contentTop}
						{tableLayout ?
							<TableLayout />
						:	<GridListLayout />}
					</>
				}
			</CardContent>
			<Menu
				anchorEl={anchorActionsMenu}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				open={Boolean(anchorActionsMenu)}
				onClick={handleActionsMenuClose}
				onClose={handleActionsMenuClose}
			>
				{actions
					?.filter((el) => el.showInMenu)
					.map((el, i) => (
						<MenuItem key={i} {...el}>
							<ListItemIcon>{el.icon}</ListItemIcon>
							<ListItemText>{el.label}</ListItemText>
						</MenuItem>
					))}
			</Menu>
		</Card>
	);
};

const NoContentOverlay = ({ label }: { label: string }) => (
	<Box>
		<Typography variant='body2'>{label}</Typography>
	</Box>
);

const LoadingOverlay = () => (
	<Table>
		<TableBody>
			{[...Array(4).keys()].map((row) => (
				<TableRow key={row} sx={{ display: 'flex', flexGrow: 1 }}>
					<TableCell sx={{ flexGrow: 1 }}>
						<Skeleton />
					</TableCell>
					<TableCell sx={{ flexGrow: 1 }}>
						<Skeleton />
					</TableCell>
				</TableRow>
			))}
		</TableBody>
	</Table>
);

const ErrorOverlay = () => {
	const { t } = useTranslation('general');

	return (
		<Box display='flex' height={1} justifyContent='center' alignItems='center'>
			<Stack direction='row' alignItems='center' spacing={1}>
				<ErrorIcon color='error' />
				<Typography>{t('somethingWentWrong')}</Typography>
			</Stack>
		</Box>
	);
};

export default InfoCard;
