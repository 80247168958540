import path from 'path';

import { IdReference, PagedResponse, SkcPagedResponse, SkcSingleResponse } from '~interfaces';
import { BaseReferenceResponse, IdReferenceResponse } from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';

import SkcUserGroup from '../interfaces/skcUserGroup';

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcUserGroupsService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'user-groups';

	constructor() {
		super();
	}

	async getUserGroups({
		page = 1,
		pageSize = 10,
		...args
	}: {
		page?: number,
		pageSize?: number;
		organisationId?: number;
	}): Promise<PagedResponse<SkcUserGroup>> {
		const { data } = await this._client.get<SkcPagedResponse<UserGroupResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId
			},
		});

		return {
			...this.mapMetaResponse(data),
			results: data.data.map((el) => SkcUserGroupsServiceMapper.fromResponse(el)),
		};
	}

	async getUserGroupById(id: string): Promise<SkcUserGroup> {
		const { data } = await this._client.get<SkcSingleResponse<UserGroupResponse>>(
			path.join(this.path, id),
			{
				headers: {
					prefer: 'return=representation'
				}
			}
		);

		return SkcUserGroupsServiceMapper.fromResponse(data.data);
	}

	async createUserGroup(data: SkcUserGroup, organisationId?: string): Promise<IdReference> {
		const content = {
			name: data.label,
			description: data.description,
		};

		const response = await this._client.post<SkcSingleResponse<IdReference>>(
			this.path, content, {
				params: {
					organisationId: organisationId
				}
			}
		);

		return {
			id: response.data.data.id.toString()
		};
	}

	async assignUsers(groupId: string, users: IdReference[]): Promise<null> {
		const { data } = await this._client.post<null>(
			path.join(this.path, groupId, 'users'),
			users.map(el => Number(el.id))
		);

		return null;
	}

	async assignNfcTags(groupId: string, nfcTags: IdReference[]): Promise<null> {
		const { data } = await this._client.post<null>(
			path.join(this.path, groupId, 'cards'),
			nfcTags.map(el => el.id)
		);

		return null;
	}

	async removeUsers(groupId: string, users: IdReference[]): Promise<null> {
		const { data } = await this._client.delete<null>(
			path.join(this.path, groupId, 'users'),
			{
				data: users.map(el => el.id)
			}
		);

		return null;
	}

	async removeNfcTags(groupId: string, nfcTags: IdReference[]): Promise<null> {
		const { data } = await this._client.delete<null>(
			path.join(this.path, groupId, 'cards'),
			{
				data: nfcTags.map(el => el.id)
			}
		);

		return null;
	}

	async deleteUserGroup(id: string): Promise<null> {
		const { data } = await this._client.delete(path.join(this.path, id));

		return null;
	}
}

class SkcUserGroupsServiceMapper {
	static fromResponse(data: UserGroupResponse): SkcUserGroup {
		const { id, name, users, cards, ...rest } = data;

		return {
			...rest,
			id: id.toString(),
			label: name,
			participants: {
				nfcTags: cards.map(el => ({
					id: el.id.toString(),
					label: el.name,
					tagNumber: el.cardNumber
				})) ?? [],
				users: users ?? []
			}
		};
	}
}

interface CardResponse extends BaseReferenceResponse {
	cardNumber: string;
	type: string;
}

interface UserResponse {
	id?: number;
	skcId: number;
	name: string;
	description?: string;
	email?: string;
}

interface UserGroupResponse {
	id: number;
	name: string;
	description?: string;
	users: IdReferenceResponse[] | CardResponse[];
}

export default SkcUserGroupsService;
