import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	tabs: {
		marginTop: theme.spacing(3),
	},
	divider: {
		backgroundColor: colors.grey[300],
	},
	content: {
		marginTop: theme.spacing(3),
	},
	panel: {
		margin: theme.spacing(8.6, 0, 0, 4),
	},
}));
