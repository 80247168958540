import path from 'path';

import { IdReference, PagedResponse, SkcPagedResponse, SkcSingleResponse } from '~interfaces';
import { IdReferenceResponse } from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';

import SkcDeviceGroup from '../interfaces/skcDeviceGroup';

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcDeviceGroupsService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'device-groups';

	constructor() {
		super();
	}

	async getDeviceGroups({
		page = 1,
		pageSize = 10,
		...args
	}: {
		page?: number,
		pageSize?: number;
		organisationId?: string;
	}): Promise<PagedResponse<SkcDeviceGroup>> {
		const { data } = await this._client.get<SkcPagedResponse<DeviceGroupResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId
			},
		});

		return {
			total: data.meta.totalRecordCount,
			pageNumber: data.meta.pageNumber,
			pageSize: data.meta.pageSize,
			results: data.data.map((el) => SkcDeviceGroupsServiceMapper.fromResponse(el)),
		};
	}

	async getDeviceGroupById(id: string): Promise<SkcDeviceGroup> {
		const { data } = await this._client.get<SkcSingleResponse<DeviceGroupResponse>>(
			path.join(this.path, id),
			{
				headers: {
					prefer: 'return=representation'
				}
			}
		);

		return SkcDeviceGroupsServiceMapper.fromResponse(data.data);
	}

	async createDeviceGroup(data: SkcDeviceGroup): Promise<IdReference> {
		const content = {
			name: data.label,
			description: data.description,
		};

		const response = await this._client.post<SkcSingleResponse<IdReferenceResponse>>(this.path, content);

		return {
			id: response.data.data.id.toString()
		};
	}

	async assignDevices(groupId: string, devices: IdReference[]): Promise<null> {
		const { data } = await this._client.post<null>(
			path.join(this.path, groupId, 'devices'),
			devices.map(el => el.id)
		);

		return data;
	}

	async removeDevices(groupId: string, devices: IdReference[]): Promise<null> {
		const { data } = await this._client.delete<null>(
			path.join(this.path, groupId, 'devices'),
			{
				data: devices.map(el => el.id)
			}
		);

		return data;
	}

	async deleteDeviceGroup(id: string): Promise<null> {
		const { data } = await this._client.delete(path.join(this.path, id));

		return null;
	}
}

class SkcDeviceGroupsServiceMapper {
	static fromResponse(data: DeviceGroupResponse): SkcDeviceGroup {
		const { id, name, description, devices } = data;

		return {
			id: id.toString(),
			label: name,
			description: description,
			devices: devices.map((el) => ({
				id: el.id.toString(),
				hardwareId: el.deviceHardwareId,
				type: el.deviceType,
				skopeiNumber: el.skopeiNr
			})),
		};
	}
}

interface DeviceResponse extends IdReferenceResponse {
	deviceHardwardId: string;
	deviceType: string;
	skopeiNr: string;
}

interface DeviceGroupRequest {
	name: string;
	description?: string;
}

interface DeviceGroupResponse extends DeviceGroupRequest, IdReferenceResponse {
	devices: IdReferenceResponse[] | DeviceResponse[];
}

export default SkcDeviceGroupsService;
