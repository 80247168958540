import { useState, useEffect } from 'react';

import CarRentalIcon from '@mui/icons-material/CarRental';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../components';
import { useError } from '../../../../shared/hooks';
import * as actions from '../../../../store/actions/index';

const PREFIX = 'LockUnlockButtons';

const classes = {
	button: `${PREFIX}-button`,
	buttonUnlock: `${PREFIX}-buttonUnlock`,
};

const Root = styled('div')(() => ({
	[`& .${classes.root}`]: {},

	[`& .${classes.button}`]: {
		marginRight: '16px',
	},
}));

/**
 *
 * @param props
 * @returns
 * @deprecated
 */
const LockUnlockButtons = (props) => {
	const {
		className,

		deviceId,
		itemId,
		itemInstanceId,
		deviceType,
		updateVehicleStatus,
		onUpdateVehicleStatus,
		updateItemInstanceCommands,
		onUpdateItemInstanceCommands,
	} = props;
	const { t } = useTranslation('general');

	const [openSecureLock, setOpenSecureLock] = useState(false);
	const [openUnlock, setOpenUnlock] = useState(false);

	const [enableIgnition, setEnableIgnition] = useState(false);

	const { success: updateVehicleSuccess, loading: updateVehicleLoading } = updateVehicleStatus;

	const { success: updateItemInstanceCommandsSuccess, loading: updateItemInstanceCommandsLoading } =
		updateItemInstanceCommands;

	const vehicleLock = useError({
		value: deviceType === 'bmwCar' ? updateVehicleStatus : updateItemInstanceCommands,
		message: t(`ui.device.successMessage.locked.${deviceType}`),
	});

	const vehicleUnlock = useError({
		value: deviceType === 'bmwCar' ? updateVehicleStatus : updateItemInstanceCommands,
		message: t(`ui.device.successMessage.unlock.${deviceType}`),
	});

	const vehicleIgnitionEnable = useError({
		value: updateItemInstanceCommands,
		message: t('ui.device.successMessage.disableIgnition'),
	});

	const vehicleIgnitionDisable = useError({
		value: updateItemInstanceCommands,
		message: t('ui.device.successMessage.enableIgnition'),
	});

	useEffect(() => {
		if (deviceType === 'bmwCar' && updateVehicleSuccess) {
			setOpenUnlock(false);
			setOpenSecureLock(false);
		}
	}, [updateVehicleLoading]);

	useEffect(() => {
		if (
			['vehicleTrackerCan', 'cpacBoat'].includes(deviceType) &&
			updateItemInstanceCommandsSuccess
		) {
			setOpenUnlock(false);
			setOpenSecureLock(false);
			setEnableIgnition(false);
		}
	}, [updateItemInstanceCommandsLoading]);

	const handleSecureLock = () => {
		setOpenSecureLock(true);
	};

	const handleUnlock = () => {
		setOpenUnlock(true);
	};

	const handleEnableIgnition = () => {
		setEnableIgnition(true);
	};

	const handleConfirmDialog = () => {
		if (openSecureLock) {
			if (deviceType === 'bmwCar') {
				onUpdateVehicleStatus(deviceId, 'secure');
				vehicleLock.setStartAction(true);
			} else {
				if (deviceType === 'cpacBoat') {
					onUpdateItemInstanceCommands(deviceId, itemId, itemInstanceId, 'disable');
					vehicleLock.setStartAction(true);
				} else {
					onUpdateItemInstanceCommands(deviceId, itemId, itemInstanceId, 'lock');
					vehicleLock.setStartAction(true);
					vehicleIgnitionDisable.setStartAction(true);
				}
			}
		} else if (openUnlock) {
			if (deviceType === 'bmwCar') {
				onUpdateVehicleStatus(deviceId, 'unlock');
			} else {
				if (deviceType === 'cpacBoat') {
					onUpdateItemInstanceCommands(deviceId, itemId, itemInstanceId, 'enable');
					vehicleUnlock.setStartAction(true);
				} else {
					onUpdateItemInstanceCommands(deviceId, itemId, itemInstanceId, 'unlock');
					vehicleUnlock.setStartAction(true);
				}
			}
		}
	};

	const handleConfirmIgnitionDialog = () => {
		onUpdateItemInstanceCommands(deviceId, itemId, itemInstanceId, 'disable');
		vehicleIgnitionEnable.setStartAction(true);
	};

	const handleCloseDialog = () => {
		if (openSecureLock) {
			setOpenSecureLock(false);
		} else if (openUnlock) {
			setOpenUnlock(false);
		}
	};

	const handleCloseIgnitionDialog = () => {
		setEnableIgnition(false);
	};

	const deviceLockStatus = [
		{
			name: 'lock',
			open: openSecureLock,
			title: t(`ui.device.label.lock.${deviceType}`),
			description: t(`ui.device.description.lock.${deviceType}`),
		},
		{
			name: 'unlock',
			open: openUnlock,
			title: t(`ui.device.label.unlock.${deviceType}`),
			description: t(`ui.device.description.unlock.${deviceType}`),
		},
	];

	return (
		<Root className={className}>
			<Box display='flex'>
				{['vehicleTrackerCan'].includes(deviceType) && (
					<LoadingButton
						className={classes.button}
						loading={updateItemInstanceCommandsLoading}
						onClick={handleEnableIgnition}
						startIcon={<CarRentalIcon />}
						variant='text'
					>
						{t('ui.device.label.disableIgnition')}
					</LoadingButton>
				)}
				<LoadingButton
					className={classes.button}
					loading={updateVehicleLoading || updateItemInstanceCommandsLoading}
					onClick={handleSecureLock}
					startIcon={<LockOutlinedIcon />}
					variant='text'
				>
					{t(`ui.device.label.lock.${deviceType}`)}
				</LoadingButton>
				<LoadingButton
					className={classes.button}
					loading={updateVehicleLoading || updateItemInstanceCommandsLoading}
					onClick={handleUnlock}
					startIcon={<LockOpenOutlinedIcon />}
					variant='text'
				>
					{t(`ui.device.label.unlock.${deviceType}`)}
				</LoadingButton>
			</Box>
			{openSecureLock || openUnlock ?
				deviceLockStatus.map((item) => (
					<ActionDialog
						actionButtonProps={{
							action: handleConfirmDialog,
							text:
								updateVehicleLoading || updateItemInstanceCommandsLoading ?
									<CircularProgress disableShrink size={24} />
								:	t('ui.confirm'),
						}}
						handleClose={handleCloseDialog}
						key={item.name}
						loading={updateVehicleLoading || updateItemInstanceCommandsLoading}
						open={item.open}
						title={item.title}
					>
						<Typography variant='body1'>{item.description}</Typography>
					</ActionDialog>
				))
			:	null}
			{enableIgnition ?
				<ActionDialog
					actionButtonProps={{
						action: handleConfirmIgnitionDialog,
						text:
							updateItemInstanceCommandsLoading ?
								<CircularProgress disableShrink size={24} />
							:	t('ui.confirm'),
					}}
					handleClose={handleCloseIgnitionDialog}
					key={'enable'}
					loading={updateItemInstanceCommandsLoading}
					open={enableIgnition}
					title={t('ui.device.label.disableIgnition')}
				>
					<Typography variant='body1'>{t('ui.device.description.disableIgnition')}</Typography>
				</ActionDialog>
			:	null}
		</Root>
	);
};

LockUnlockButtons.propTypes = {
	className: PropTypes.string,

	deviceId: PropTypes.string,
	deviceType: PropTypes.string,
	itemId: PropTypes.number,
	itemInstanceId: PropTypes.number,
	onUpdateVehicleStatus: PropTypes.func,
	onUpdateItemInstanceCommands: PropTypes.func,
	updateItemInstanceCommands: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updateVehicleStatus: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		updateVehicleStatus: state.condition.updateVehicleStatus,
		updateItemInstanceCommands: state.condition.updateItemInstanceCommands,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateVehicleStatus: (deviceId, commands) =>
			dispatch(actions.updateVehicleStatus(deviceId, commands)),
		onUpdateItemInstanceCommands: (deviceId, itemId, itemInstanceId, commands) =>
			dispatch(actions.updateItemInstanceCommands(deviceId, itemId, itemInstanceId, commands)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LockUnlockButtons);
