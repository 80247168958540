import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { Table } from '../../../../../components';
import { decimalAmount, isObject, getFinanceTableHeaders } from '../../../../../shared/utility';

const BookingsFinance = (props) => {
	const classes = useStyles();
	const { data, title } = props;
	const { t } = useTranslation('general');

	const tableHeader = getFinanceTableHeaders(false, true, true);

	const tableBody =
		isObject(data) ?
			[
				[
					{ content: <Typography className={classes.link}>{title}</Typography> },
					{ content: decimalAmount(data.amount.subtotal, data.amount) },
					{ content: decimalAmount(data.amount.discount, data.amount) },
					{ content: decimalAmount(data.amount.subtotal - data.amount.discount, data.amount) },
					{ content: decimalAmount(data.amount.priceVat, data.amount) },
					{ content: decimalAmount(data.amount.refund, data.amount) },
					{ content: decimalAmount(data.amount.totalPrice, data.amount) },
				],
			]
		:	Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation = {
		header: t('ui.label.personalBilling'),
	};

	return (
		<Table
			body={tableBody}
			cellStyle={classes.cellStyle}
			header={tableHeader}
			isNotPaginate={true}
			loading={isObject(data)}
			titleWithInformation={titleWithInformation}
			withoutPages={true}
		/>
	);
};

BookingsFinance.propTypes = {
	data: PropTypes.object,
	title: PropTypes.string,
};

export default BookingsFinance;
