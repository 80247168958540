import { useRef, useState } from 'react';

import { TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import CustomNumber from './CustomNumber';
import MaxLengthCountDown from './MaxLenghtCountDown';
import { isInteger } from '../../../shared/utility';

const NumberInput = (props) => {
	const {
		onChange,
		startAdornment,
		maxLength,
		value,
		thousandSeparator,
		decimalSeparators,
		...rest
	} = props;
	const inputRef = useRef(null);

	const lengthValue = Math.ceil(Math.log10(value + 1));

	const [focus, setFocus] = useState(false);

	const handleFocus = () => (maxLength ? setFocus(true) : null);
	const handleBlur = () => (maxLength ? setFocus(false) : null);

	return (
		<TextField
			InputProps={{
				inputComponent: CustomNumber,
				inputProps: {
					inputRef,
					onChange: onChange,
					name: 'numberInput',
					maxLength: maxLength,
					decimalSeparators: decimalSeparators,
					thousandSeparator: thousandSeparator,
				},
				startAdornment: <InputAdornment position='start'>{startAdornment}</InputAdornment>,
				...(isInteger(maxLength) && focus ?
					{
						endAdornment: (
							<InputAdornment position='end'>
								{
									<MaxLengthCountDown
										length={value?.length && isInteger(value.length) ? value.length : lengthValue}
										maxLength={maxLength}
									/>
								}
							</InputAdornment>
						),
					}
				:	null),
			}}
			maxLength
			inputRef={inputRef}
			onBlur={handleBlur}
			onFocus={handleFocus}
			value={value}
			{...rest}
		/>
	);
};

NumberInput.propTypes = {
	onChange: PropTypes.func,
	startAdornment: PropTypes.any,
	maxLength: PropTypes.number,
	value: PropTypes.number,
	thousandSeparator: PropTypes.number,
	decimalSeparators: PropTypes.number,
};

export default NumberInput;
