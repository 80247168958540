enum ItemCategoryEnum {
	Bikes = 'bikes',
	Offices = 'offices',
	Cars = 'cars',
	Trailers = 'trailers',
	ParkingLots = 'parkingLots',
	Assets = 'assets',
	Boats = 'boats'
}

export default ItemCategoryEnum;
