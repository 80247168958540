import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden, Grid } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';

const Header = (props) => {
	const { className, name, hasButton, buttonPlaceholder, buttonAction, loading } = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const navigate = useNavigate();

	const classes = useStyles();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => navigate('/user-management/user-groups');

	const handleBackLabel = () => {
		if (returnButton.isBackButton) {
			return t('ui.button.inline.back');
		} else if (location?.state?.label) {
			return location?.state?.label;
		} else {
			return t('view.usermanagement.usergroupdetails.button.inline.backtousergrouplist');
		}
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Hidden only={['xs', 'sm']}>
						<StyledButton
							className={classes.backButton}
							onClick={handleBackButtonChange}
							startIcon={<KeyboardArrowLeftOutlinedIcon />}
							variant='inline-default'
						>
							{handleBackLabel()}
						</StyledButton>
					</Hidden>
					<Typography component='h1' variant='h3'>
						{loading ?
							<AdjustedSkeleton animation='wave' width={200} />
						:	name}
					</Typography>
				</Grid>
				{hasButton ?
					<Grid item>
						<StyledButton onClick={buttonAction} variant='contained-primary'>
							{buttonPlaceholder}
						</StyledButton>
					</Grid>
				:	null}
			</Grid>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	hasButton: PropTypes.bool,
	buttonPlaceholder: PropTypes.string,
	buttonAction: PropTypes.func,
	loading: PropTypes.bool,
};

export default Header;
