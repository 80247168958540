import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, Typography, IconButton, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const InfoDialog = (props) => {
	const { open, dialogTitle, onClose, children } = props;

	const classes = useStyles();

	return (
		<Dialog open={open}>
			<DialogTitle className={classes.title}>
				<Typography variant='h6'>{dialogTitle}</Typography>
				{onClose ?
					<IconButton aria-label='close' onClick={onClose} size='small'>
						<CloseIcon />
					</IconButton>
				:	null}
			</DialogTitle>
			<DialogContent dividers>{children}</DialogContent>
		</Dialog>
	);
};

InfoDialog.propTypes = {
	open: PropTypes.bool,
	dialogTitle: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default InfoDialog;
