import { createTheme } from '@mui/material/styles';

import link from './link';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
	typography,
	components: {
		...overrides,
	},
	palette,
	link,
});

export default theme;
