import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	cardContent: {
		padding: theme.spacing(4.5),
	},
	subHeader: {
		margin: theme.spacing(1, 0, 2, 0),
	},
	counterText: {
		color: theme.palette.grey[400],
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(4),
	},
}));
