import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	listContainer: {
		height: 450,
		marginTop: theme.spacing(2),
	},
	loadingListItem: {
		height: 74,
	},
	listItem: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 0),
		'& + &': {
			borderTop: `1px solid ${theme.palette.divider}`,
		},
	},
	partnerDetails: {
		display: 'flex',
		alignItems: 'center',
	},
	paginationContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	avatar: {
		marginRight: theme.spacing(1),
		'& img': {
			objectFit: 'scale-down',
		},
	},
}));
