import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	container: {
		marginTop: theme.spacing(2),
	},
	actions: {
		justifyContent: 'flex-end',
	},
	expansionPanel: {
		flexBasis: '80%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	dividers: {
		margin: theme.spacing(1, 0),
	},
	border: {
		border: '1px solid red',
	},
	selectCard: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	formSelectTimeOpen: {
		margin: theme.spacing(0, 10, 0, 3),
		minWidth: 90,
	},
	formSelectTimeClosed: {
		marginLeft: theme.spacing(3),
		minWidth: 90,
	},
	locationName: {
		marginBottom: '16px',
	},
	formSpacing: {
		height: '100px',
	},
}));
