import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	description: {
		'& .MuiInputBase-root': {
			borderRadius: '12px',
		},
	},
}));
