import { colors } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Page, EmptyState } from '../../components';

const PREFIX = 'RedirectError';

const classes = {
	root: `${PREFIX}-root`,
	tabs: `${PREFIX}-tabs`,
	divider: `${PREFIX}-divider`,
	content: `${PREFIX}-content`,
};

const StyledPage = styled(Page)(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(3),
	},

	[`& .${classes.tabs}`]: {
		marginTop: theme.spacing(3),
	},

	[`& .${classes.divider}`]: {
		backgroundColor: colors.grey[300],
	},

	[`& .${classes.content}`]: {
		marginTop: theme.spacing(3),
	},
}));

export {};

/**
 * Use the errorpage in pages
 * @returns
 * @deprecated
 *
 */
const RedirectError = () => {
	const { message } = useParams();

	return (
		<StyledPage className={classes.root} title={'Bad request'}>
			<div className={classes.content}>
				<EmptyState image={'booking'} subTitle={message} title={'Bad request'} />
			</div>
		</StyledPage>
	);
};

RedirectError.propTypes = {
	location: PropTypes.any,
};

export default RedirectError;
