import { Suspense, useEffect, useState } from 'react';

import { Box, Drawer, LinearProgress, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { LoadingPage } from '~pages';

import MainHeader from './headers/mainHeader';
import MainNavBar from './navBars/mainNavBar';
import * as actions from '../../store/actions';

interface MainLayoutProps {
	onFetchCurrentUser: () => void;
}

const MainLayout = ({ onFetchCurrentUser }: MainLayoutProps) => {
	const auth = useAuth();
	const theme = useTheme();
	const largerThanLg = useMediaQuery(theme.breakpoints.up('lg'));

	const [navBarOpen, setNavBarOpen] = useState(false);

	useEffect(() => {
		if (auth.isAuthenticated) {
			onFetchCurrentUser();
		}
	}, [auth]);

	const navBar = (
		<Box overflow='auto' height={1} minWidth={250}>
			<MainNavBar />
		</Box>
	);

	return (
		<Box display='flex' flexDirection='column' width={1} height={1}>
			<MainHeader menuOpen={navBarOpen} onMenuOpenChange={(value) => setNavBarOpen(value)} />
			<Box display='flex' flexGrow={1} overflow='hidden'>
				<Drawer open={navBarOpen && !largerThanLg} onClose={() => setNavBarOpen((prev) => !prev)}>
					{navBar}
				</Drawer>
				{largerThanLg && (
					<Paper elevation={1} square sx={{ zIndex: 500 }}>
						{navBar}
					</Paper>
				)}
				<Box display='flex' flex='1 1 200px' flexDirection='column' overflow='auto' zIndex={400}>
					<Box flexGrow={1}>
						<Suspense fallback={<LoadingPage />}>
							<Outlet />
						</Suspense>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCurrentUser: () => dispatch(actions.fetchCurrentUser()),
	};
};

export default connect(null, mapDispatchToProps)(MainLayout);
