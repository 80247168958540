import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexGrow: 0,
		flexShrink: 0,
		borderRadius: theme.shape.borderRadius,
		height: 20,
		minWidth: 20,
		whiteSpace: 'nowrap',
		padding: theme.spacing(0.5, 1),
		...theme.typography.label,
	},
	rounded: {
		borderRadius: 10,
		padding: theme.spacing(0.5, 1.5),
	},
}));
