import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const localStorageLanguage = localStorage.getItem('languageCode');

i18n
	.use(initReactI18next)
	.use(Backend)
	.init({
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
		},
		lng:
			localStorageLanguage === 'de-DE' ? 'de'
			: localStorageLanguage === 'en-US' ? 'en'
			: 'nl',
		fallbackLng: 'nl',
		supportedLngs: ['en', 'nl', 'de'],
		debug: import.meta.env.NODE_ENV === 'development',
		ns: ['general'],
		defaultNS: 'general',
		interpolation: {
			escapeValue: false,
		},
		keySeparator: '.',
	});

i18n.services.formatter?.add('lowercase', (value: string) => {
	return value.toLowerCase();
});

i18n.loadLanguages(['de', 'nl', 'en'], (err) => console.log(err));

export default i18n;
