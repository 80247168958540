import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { decimalBase } from '../../../../../../shared/utility';

const GeneralInfo = (props) => {
	const { pricingModel, className } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.policyDetail.summary.cardHeaders.general')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.name')}</TableCell>
							<TableCell>{pricingModel.name}</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('ui.label.organisation')}</TableCell>
							<TableCell>{pricingModel.organisationReference.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.category')}</TableCell>
							<TableCell>{pricingModel.categoryReference.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.characteristics.carBodyType')}</TableCell>
							<TableCell>{t(`ui.label.pricing.${pricingModel.calculationType}`)}</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('ui.label.displayPrice')}</TableCell>
							<TableCell>{`${t('ui.from')} ${decimalBase(pricingModel.fromPrice.price, pricingModel.fromPrice.currencySymbol)}/${pricingModel.fromPrice.periodType}`}</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{`${t('ui.vat')}(%)`}</TableCell>
							<TableCell>{pricingModel.vatPercentage}</TableCell>
						</TableRow>
						{pricingModel.pricingUsage ?
							<>
								<TableRow>
									<TableCell>{t('ui.label.freeUse')}</TableCell>
									<TableCell>
										{pricingModel.pricingUsage.freeUnits ?
											`${pricingModel.pricingUsage.freeUnits}km`
										:	'-'}
									</TableCell>
								</TableRow>
								<TableRow className={classes.tableRow}>
									<TableCell>{t('ui.label.usageCosts')}</TableCell>
									<TableCell>
										{pricingModel.pricingUsage.price ?
											`${decimalBase(pricingModel.pricingUsage.price, pricingModel.fromPrice.currencySymbol)}/km`
										:	'-'}
									</TableCell>
								</TableRow>
							</>
						:	null}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

GeneralInfo.propTypes = {
	className: PropTypes.string,
	pricingModel: PropTypes.object.isRequired,
};

export default GeneralInfo;
