import { useState, useCallback, useEffect, useMemo } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
	Stepper,
	Step,
	StepLabel,
	Typography,
	Box,
	MobileStepper,
	CircularProgress,
	Divider,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { StyledButton } from '../../../components';
import { usePrevious } from '../../../shared/hooks';
import { isUndefined } from '../../../shared/utility';
import Page from '../Page';

/**
 * Use the /components/wizard/wizard
 * This component is a different approach where the wizard itself
 * is not responsible for keeping the data state
 * @param props
 * @returns
 * @deprecated
 */
const Wizard = (props) => {
	const classes = useStyles();

	const {
		title,
		steps,
		activeStartStep,
		stepperType,
		callback,
		prevButtonLabel,
		nextButtonLabel,
		finishButtonLabel,
		cancelButtonLabel,
		loading,
		sidebar,
		hasCancelButton,
		hasNavHeader,
		handleCancel,
		shouldNotShowNavHeader,
		shouldNotShowStepActionFooter,
		className,
	} = props;
	const { t } = useTranslation('general');

	const navigate = useNavigate();

	const [activeStep, setActiveStep] = useState(activeStartStep || 0);
	const [skipped, setSkipped] = useState(new Set());

	const isStepSkipped = (stepIndex) => {
		return skipped.has(stepIndex);
	};

	const handleNext = () => {
		//Should in the future also be able to do a validation check (of for example email) with an API call.
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		document.documentElement.scrollTop = 0;
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// const handleSkip = () => {
	//   if (steps[activeStep].optional) {
	//     // You probably want to guard against something like this,
	//     // it should never occur unless someone's actively trying to break something.
	//     throw new Error("You can't skip a step that isn't optional.");
	//   }

	//   setActiveStep(prevActiveStep => prevActiveStep + 1);
	//   setSkipped(prevSkipped => {
	//     const newSkipped = new Set(prevSkipped.values());
	//     newSkipped.add(activeStep);
	//     return newSkipped;
	//   });
	// };

	//wizardData
	const [wizardData, setWizardData] = useState({});

	const saveWizardData = useCallback((newData = {}) => {
		setWizardData((currentData) => ({ ...currentData, ...newData }));
	}, []);

	// useEffect(() => {
	// }, [wizardData]);

	// callback when finishing the wizard
	const finishWizard = useCallback(() => {
		const data = {
			...wizardData,
			activeStepIndex: activeStep,
			finishedWizard: true,
		};
		callback(data);
	}, [wizardData, activeStep, callback]);

	// callback and store wizard data into local storage
	// when wizardData changes
	const data = { ...wizardData, activeStep };
	const prevData = usePrevious(data);

	useEffect(() => {
		if (JSON.stringify(data) !== JSON.stringify(prevData)) {
			callback(data);
		}
	}, [data, prevData, callback]);

	// active step panel
	const stepPanels = steps.map(({ content: StepComponent, props = {}, index, name }) => (
		<StepComponent
			isActiveStep={index === activeStep}
			key={name}
			save={saveWizardData}
			setActiveStep={setActiveStep}
			stepIndex={index}
			{...props}
		/>
	));

	/* * * * * * * * * *
	 * FOOTER BUTTONS  *
	 * * * * * * * * * */
	//cancel
	const cancelButton = hasCancelButton && (
		<StyledButton
			className={classes.button}
			disabled={loading}
			onClick={handleCancel}
			variant='contained-tertiary'
		>
			{cancelButtonLabel ? cancelButtonLabel : t('ui.button.contained.cancel')}
		</StyledButton>
	);

	//previous
	const prevButton = activeStep !== 0 && (
		<StyledButton
			className={classes.button}
			disabled={loading}
			onClick={handleBack}
			variant='contained-tertiary'
		>
			{prevButtonLabel ? prevButtonLabel : t('ui.button.contained.previous')}
		</StyledButton>
	);

	// next
	const enableNextButton = useMemo(() => {
		if (loading) {
			return false;
		}
		return steps.every(({ valid }, index) => {
			return index > activeStep || valid;
		});
	}, [activeStep, steps]);

	const nextButton = activeStep !== steps.length - 1 && (
		<StyledButton
			className={classes.button + ' ' + classes.pushRight}
			disabled={!enableNextButton}
			onClick={handleNext}
			variant='contained-secondary'
		>
			{nextButtonLabel ? nextButtonLabel : t('ui.button.contained.next')}
		</StyledButton>
	);

	// finish
	const enableFinishButton = useMemo(() => {
		if (loading) {
			return false;
		}
		return steps.every(({ valid }) => valid);
	}, [steps]);

	const finishButton = activeStep === steps.length - 1 && (
		<StyledButton
			className={classes.button + ' ' + classes.pushRight}
			disabled={!enableFinishButton}
			onClick={finishWizard}
			variant='contained-secondary'
		>
			{loading ?
				<CircularProgress className={classes.progress} disableShrink size={25} />
			: finishButtonLabel ?
				finishButtonLabel
			:	t('ui.button.contained.finish')}
		</StyledButton>
	);

	return (
		<Page className={clsx(classes.root, className)} title={title}>
			<Box className={clsx({ [classes.header]: shouldNotShowNavHeader })}>
				{steps.length > 1 ?
					<div
						className={clsx({
							[classes.stepperHeaderContainer]: true,
							[classes.topHeader]: !shouldNotShowNavHeader,
						})}
					>
						{stepperType === 'steps' ?
							<Stepper activeStep={activeStep} className={classes.stepperHeader}>
								{steps.map((step, index) => {
									const stepProps = {};
									const labelProps = {};
									if (step.optional) {
										labelProps.optional = (
											<Typography variant='caption'>{t('ui.optional')}</Typography>
										);
									}
									if (isStepSkipped(index)) {
										stepProps.completed = false;
									}
									return (
										<Step key={step.name} {...stepProps}>
											<StepLabel {...labelProps}>{step.name}</StepLabel>
										</Step>
									);
								})}
							</Stepper>
						:	<MobileStepper
								activeStep={activeStep}
								backButton={
									activeStep === 0 ?
										<StyledButton
											className={classes.stepperBackButton}
											onClick={() => (window.history.length > 2 ? navigate(-1) : navigate('/'))}
											size='small'
											variant='inline-default'
										>
											<KeyboardArrowLeftIcon />
											{cancelButtonLabel ? cancelButtonLabel : t('ui.button.inline.cancel')}
										</StyledButton>
									:	<StyledButton
											className={classes.stepperBackButton}
											disabled={activeStep === 0 || loading}
											onClick={handleBack}
											size='small'
											variant='inline-default'
										>
											<KeyboardArrowLeftIcon />
											{t('ui.button.inline.previous')}
										</StyledButton>
								}
								className={classes.mobileStepperHeader}
								nextButton={
									<StyledButton
										className={classes.stepperNextButton}
										disabled={!enableNextButton || activeStep === steps.length - 1}
										onClick={handleNext}
										size='small'
										variant='inline-default'
									>
										{t('ui.button.inline.next')}
										<KeyboardArrowRightIcon />
									</StyledButton>
								}
								position='static'
								steps={steps.length}
								variant='progress'
							/>
						}
					</div>
				:	null}
				{steps.length === 1 && hasNavHeader ?
					<div
						className={clsx({
							[classes.stepperHeaderContainer]: true,
							[classes.topHeader]: true,
						})}
					>
						<StyledButton
							className={classes.stepperBackButton}
							disabled={loading}
							onClick={() => (window.history.length > 2 ? navigate(-1) : navigate('/'))}
							size='small'
							variant='inline-default'
						>
							<KeyboardArrowLeftIcon />
							{cancelButtonLabel ? cancelButtonLabel : t('ui.cancel')}
						</StyledButton>
					</div>
				:	null}
			</Box>
			<Divider />
			<div className={classes.wizardContentWrapper}>
				{activeStep === steps.length ?
					<div className={classes.mainColumn}>
						<Typography className={classes.instructions}>
							All steps completed - you&apos;re finished
						</Typography>
					</div>
				:	<>
						<div className={classes.columns}>
							<div
								className={clsx({
									[classes.mainColumn]: !isUndefined(sidebar),
									[classes.singleColumn]: isUndefined(sidebar),
								})}
							>
								{stepPanels[activeStep]}
							</div>
							{!isUndefined(sidebar) && <div className={classes.sidebarColumn}>{sidebar}</div>}
						</div>
						{!shouldNotShowStepActionFooter ?
							<div className={clsx(classes.stepActionFooterContainer, classes.columns)}>
								<Box
									className={clsx({
										[classes.stepActionFooter]: true,
										[classes.mainColumn]: !isUndefined(sidebar),
										[classes.singleColumn]: isUndefined(sidebar),
									})}
									display='flex'
									justifyContent='space-between'
								>
									{cancelButton}
									{prevButton}
									{nextButton}
									{finishButton}
								</Box>
							</div>
						:	null}
					</>
				}
			</div>
		</Page>
	);
};

Wizard.propTypes = {
	className: PropTypes.string,
	callback: PropTypes.func.isRequired,
	steps: PropTypes.array.isRequired,
	activeStartStep: PropTypes.number,
	title: PropTypes.string,
	sidebar: PropTypes.node,
	loading: PropTypes.bool,
	stepperType: PropTypes.oneOf(['steps', 'progress']),

	prevButtonLabel: PropTypes.string,
	nextButtonLabel: PropTypes.string,
	finishButtonLabel: PropTypes.string,
	cancelButtonLabel: PropTypes.string,

	hasCancelButton: PropTypes.bool,
	hasNavHeader: PropTypes.bool,
	handleCancel: PropTypes.func,
	shouldNotShowStepActionFooter: PropTypes.bool,

	shouldNotShowNavHeader: PropTypes.bool,
};

export default Wizard;
