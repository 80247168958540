import { Chip, ChipOwnProps, ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AccessEventEnum from '../../enums/accessEventEnum';

interface AccessEventChipProps extends ChipProps {
	status: AccessEventEnum;
}

const AccessEventChip = ({ status }: AccessEventChipProps) => {
	const { t } = useTranslation('general');

	const getStatusColor = (status: AccessEventEnum): ChipOwnProps['color'] => {
		let color: ChipOwnProps['color'] = 'default';
		switch (status) {
			case AccessEventEnum.NoReservationFound:
			case AccessEventEnum.NoRuleFound:
			case AccessEventEnum.CardNotFound:
				color = 'info';
				break;
			case AccessEventEnum.HashMissMatch:
				color = 'error';
				break;
			case AccessEventEnum.GrantedReservation:
			case AccessEventEnum.GrantedRule:
				color = 'success';
				break;
			default:
				color = 'default';
				break;
		}

		return color;
	};

	return <Chip size='small' label={t(`accessEvent.${status}`)} color={getStatusColor(status)} />;
};

export default AccessEventChip;
