import { useEffect } from 'react';

import path from 'path';

import { Link as MuiLink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { InfoCard } from '~components';
import { pagePathSegments } from '~constants';
import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { usePageButtons } from '~layouts';
import { datetimeWithoutSecondsFormat } from '~utils/dateUtils';

import useAccessRuleData from '../../hooks/useAccessRuleData';

interface AccessRuleGeneralInfoCardProps {
	id: string;
}

const AccessRuleGeneralInfoCard = ({ id }: AccessRuleGeneralInfoCardProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const location = useLocation();

	const { data, isLoading, error } = useAccessRuleData(id);

	const rows =
		!data ?
			[]
		:	[
				{
					headerName: 'ID',
					value: data.id,
				},
				{
					headerName: t('name'),
					value: data.label,
				},
				...(isSuperAdmin() ?
					[
						{
							headerName: t('organisation'),
							value: data.organisation.label,
							renderCell: () => (
								<MuiLink
									component={ReactRouterLink}
									to={`/${path.join(pagePathSegments.Organisations, data.organisation.id, pagePathSegments.Summary)}`}
									state={{ from: location.pathname }}
								>
									{data.organisation.label}
								</MuiLink>
							),
						},
					]
				:	[]),
				{
					headerName: t('ui.label.startDate'),
					value: data.period.start,
					valueFormatter: (value: Date) => value.toLocaleDateString(),
				},
				{
					headerName: t('ui.label.endDate'),
					value: data.period.end,
					valueFormatter: (value: Date) => value.toLocaleDateString(),
				},
				{
					headerName: t('ui.dateCreated'),
					value: data.created,
					valueFormatter: (value: Date) => datetimeWithoutSecondsFormat.format(value),
				},
			];

	return (
		<InfoCard
			title={t('info')}
			rows={rows}
			loading={isLoading}
			error={error != null}
			enableEdit={false}
		/>
	);
};

export default AccessRuleGeneralInfoCard;
