import { useState, useEffect } from 'react';

import { Box, CircularProgress, TextField, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog, NumberInput } from '../../../../../components';
import { isUndefined } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const EditMileageDialog = (props) => {
	const {
		openEditMileageDialog,
		setEditMileageDialog,
		options,
		clearOptions,
		startUsage,
		stopUsage,
		onBookingMileage,
		bookingMileage,
		data,
		onResetStateCondition,
		onUpdateDetailsState,
		currentUser,
	} = props;
	const { t } = useTranslation('general');

	const { enqueueSnackbar } = useSnackbar();

	const [editMileage, setEditMileage] = useState(options?.length === 3 ? 'both' : options[0]);

	const {
		success: bookingMileageSuccess,
		loading: bookingMileageLoading,
		error: bookingMileageError,
	} = bookingMileage;

	const { data: currentUserData } = currentUser;

	const [startMileage, setStartMileage] = useState(isUndefined(startUsage) ? 0 : startUsage);
	const [stopMileage, setStopMileage] = useState(isUndefined(stopUsage) ? 0 : stopUsage);

	const usage = data?.usage;

	useEffect(() => {
		if (bookingMileageSuccess) {
			onResetStateCondition('bookingMileage', false);
			enqueueSnackbar(t('views.bookingDetail.usageInfo.mileageDialog.success.message'), {
				variant: 'success',
			});
			onUpdateDetailsState('fetchBooking', {
				...data,
				usage: {
					...usage,
					...(options.includes('startMileage') && { startMileageKm: startMileage }),
					...(options.includes('stopMileage') && { stopMileageKm: stopMileage }),
					...(startMileage === startUsage && usage?.startMileageTimestamp ?
						{ startMileageTimestamp: usage.startMileageTimestamp }
					:	{ startMileageTimestamp: null }),
					...(stopMileage === stopUsage && usage?.stopMileageTimestam ?
						{ stopMileageTimestamp: usage.stopMileageTimestamp }
					:	{ stopMileageTimestamp: null }),
					kmUsage: parseFloat((stopMileage - startMileage).toFixed(2)),
					...(usage?.adminData && {
						adminData: {
							...usage.adminData,
							...((editMileage === 'both' || editMileage === 'startMileage') &&
								startMileage !== startUsage && {
									startMileageUser: {
										name: currentUserData.name,
									},
								}),
							...((editMileage === 'both' || editMileage === 'stopMileage') &&
								stopMileage !== stopUsage && {
									stopMileageUser: {
										name: currentUserData.name,
									},
								}),
						},
					}),
				},
			});
			setEditMileageDialog(false);
		} else if (bookingMileageError) {
			onResetStateCondition('bookingMileage', false);
			enqueueSnackbar(bookingMileageError?.message, { variant: 'error' });
		}
	}, [bookingMileage]);

	const handleCloseEditMileage = () => {
		setEditMileageDialog(false);
		clearOptions();
	};

	const handleStartMileage = (event) => {
		const value = parseFloat(event.target.value);
		if (isNaN(value)) {
			setStartMileage(0);
		} else {
			setStartMileage(value);
		}
	};

	const handleStopMileage = (event) => {
		const value = parseFloat(event.target.value);
		if (isNaN(value)) {
			setStopMileage(0);
		} else {
			setStopMileage(value);
		}
	};

	const handleConfirm = () => {
		const bodyData = {
			...(options.includes('startMileage') && { start: startMileage }),
			...(options.includes('stopMileage') && { stop: stopMileage }),
		};
		onBookingMileage(data.id, bodyData);
	};

	const handleTypeChange = (event) => {
		const value = event.target.value;
		if (value === 'startMileage' && startMileage > 0) {
			setStopMileage(stopUsage);
		} else if (value === 'stopMileage' && startMileage > 0) {
			setStartMileage(startUsage);
		}
		setEditMileage(event.target.value);
	};

	const selectProps = {
		onChange: handleTypeChange,
		value: editMileage,
		InputLabelProps: { shrink: true },
		label: t('views.bookingDetail.usageInfo.mileageDialog.selectOption'),
		select: true,
		size: 'medium',
		fullWidth: true,
		variant: 'outlined',
	};

	const startMileageError =
		(editMileage === 'both' || editMileage === 'startMileage') && startMileage > stopMileage;

	const stopMileageError =
		(editMileage === 'both' || editMileage === 'stopMileage') && startMileage > stopMileage;

	const mileageFields = [
		...(editMileage === 'both' || editMileage === 'startMileage' ?
			[
				{
					onChange: handleStartMileage,
					value: startMileage,
					error: startMileageError,
					label: 'startMileage',
					helperText: t('views.bookingDetail.usageInfo.mileageDialog.startMileage.error'),
					key: 1,
				},
			]
		:	[]),
		...(editMileage === 'both' || editMileage === 'stopMileage' ?
			[
				{
					onChange: handleStopMileage,
					value: stopMileage,
					error: stopMileageError,
					label: 'stopMileage',
					helperText: t('views.bookingDetail.usageInfo.mileageDialog.stopMileage.error'),
					key: 2,
				},
			]
		:	[]),
	];

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text:
					bookingMileageLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleCloseEditMileage}
			loading={
				(startMileage === 0 && stopMileage === 0) ||
				bookingMileageLoading ||
				startMileage > stopMileage
			}
			open={openEditMileageDialog}
			title={t('views.bookingDetail.usageInfo.title.editMileage')}
		>
			<Box display='flex' flexDirection='column' rowGap={4}>
				<TextField {...selectProps}>
					{options.map((item) => (
						<MenuItem key={item} value={item}>
							{t(`views.bookingDetail.usageInfo.edit.option.${item}`)}
						</MenuItem>
					))}
				</TextField>
				{mileageFields.map((mileage) => (
					<NumberInput
						FormHelperTextProps={{
							style: { position: 'absolute', top: '100%', marginLeft: '16px' },
						}}
						InputLabelProps={{ shrink: true }}
						error={mileage.error}
						fullWidth
						helperText={mileage.error && mileage.helperText}
						key={mileage.key}
						label={t(`views.bookingDetail.usageInfo.edit.option.${mileage.label}`)}
						maxLength={25}
						name={mileage.label}
						onChange={mileage.onChange}
						value={mileage.value}
					/>
				))}
			</Box>
		</ActionDialog>
	);
};

EditMileageDialog.propTypes = {
	openEditMileageDialog: PropTypes.bool,
	setEditMileageDialog: PropTypes.func,
	options: PropTypes.array,
	clearOptions: PropTypes.func,
	startUsage: PropTypes.number,
	stopUsage: PropTypes.number,
	onResetStateCondition: PropTypes.func,
	onUpdateDetailsState: PropTypes.func,
	onBookingMileage: PropTypes.func,
	data: PropTypes.object,
	bookingMileage: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		bookingMileage: state.condition.bookingMileage,
		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onBookingMileage: (bookingId, bodyData) =>
			dispatch(actions.bookingMileage(bookingId, bodyData)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMileageDialog);
