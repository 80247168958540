import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'&.full-width': {
			width: '100%',
		},
	},
	box: {
		display: 'inline-block',
		padding: '10px',
		boxSizing: 'border-box',
	},
	imgPreview: {
		overflow: 'hidden',
	},
	image: {
		width: 130,
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 3, 2, 3),
	},
	closeButton: {
		marginRight: '10px',
	},
	button: {
		marginLeft: '10px',
	},
}));
