import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	mainCard: {
		marginBottom: '60px',
	},
	mainCardMrgin: {
		marginBottom: '0px',
	},
	filterButton: {
		marginRight: theme.spacing(2),
	},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 700,
		'& .MuiTableHead-root': {
			backgroundColor: '#fff',
		},
	},
	actions: {
		padding: theme.spacing(0, 1),
		justifyContent: 'flex-end',
	},
	collapsButtonCell: {
		paddingRight: '8px',
	},
	cellSpacing: {
		width: '50px',
		// overflowWrap: 'break-word',
		// wordBreak: 'break-all'
	},
}));
