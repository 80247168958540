import { List } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { isUndefined } from '../../../../shared/utility';
import { useStyles } from '../style';

const InputList = (props) => {
	const { total, children } = props;

	const classes = useStyles();

	return (
		<List
			className={clsx({
				[classes.selectResults]: true,
				[classes.smallPopper]: total < 5 || isUndefined(total),
				[classes.mediumPopper]: 5 < total < 20,
				[classes.largePopper]: total > 20,
			})}
		>
			{children}
		</List>
	);
};

InputList.defaultProps = {
	total: undefined,
	children: null,
};

InputList.propTypes = {
	total: PropTypes.number,
	children: PropTypes.node,
};

export default InputList;
