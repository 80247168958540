import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

interface ErrorBoundaryProps extends WithTranslation {
	children?: React.ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
	public state: State = {
		hasError: false,
	};

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
	}

	public render() {
		if (!this.state.hasError) {
			return this.props.children;
		}

		if (import.meta.env.DEV) {
			return (
				<Box p={2}>
					<Typography>{this.props.t('somethingWentWrong')}</Typography>
					<Button reloadDocument component={Link} to=''>
						{this.props.t('home')}
					</Button>
				</Box>
			);
		}

		return <Navigate to={'/errors/error:message'} />;
	}
}

export default withTranslation()(ErrorBoundary);
