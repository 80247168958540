import { useEffect, useState } from 'react';

import ImportExportIcon from '@mui/icons-material/ImportExport';
import {
	Link,
	TablePagination,
	TableSortLabel,
	CardContent,
	CardActions,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { EmptyTable, LoadingBar, Label, GenericMoreButton } from '../../../../components';
import { commaTimeStrings } from '../../../../shared/datetime';
import {
	isObject,
	isEmptyArray,
	isUndefined,
	isFullArray,
	isFullString,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import Dialog from '../../../Maintenance/ServiceTickets/Dialog/Dialog';
import { useStyles } from '../../style';

const TicketsList = (props) => {
	const { status, onDashboardTickets, dashboardTickets, dashboardOrganisationFilter } = props;
	const { t } = useTranslation('general');
	const location = useLocation();

	const { data: dashboardTicketsData, loading: dashboardTicketsLoading } = dashboardTickets;
	const isPageEmpty =
		isObject(dashboardTicketsData) &&
		(isEmptyArray(dashboardTicketsData.results) || isUndefined(dashboardTicketsData.results));
	const isPageFull = isObject(dashboardTicketsData) && isFullArray(dashboardTicketsData.results);

	const [page, setPage] = useState({ number: 1, size: 5 });
	const [order, setOrder] = useState({ order: 'asc', descending: true, orderBy: 'defectType' });

	const [loading, setLoading] = useState(true);

	const classes = useStyles();

	// Dialog
	const [dialogOpen, setDialogOpen] = useState(false);
	const [ticketsStatus, setTicketsStatus] = useState('');
	const [ticketData, setTicketData] = useState(null);
	// Filter
	const filters = {
		state: status,
		orderDescending: order.descending,
		sortBy: order.orderBy,
		...(isFullString(dashboardOrganisationFilter?.valueName) && {
			organisationId: dashboardOrganisationFilter.valueId,
		}),
	};

	// Fetch tickets
	useEffect(() => {
		onDashboardTickets(page, filters);
		setLoading(false);
		return () => {
			setLoading(true);
		};
	}, [order, page, dashboardOrganisationFilter.valueId]);

	// Sorting
	const handleRequestSort = (property) => {
		const isDesc = order.orderBy === property && order.order === 'desc';
		setOrder({ order: isDesc ? 'asc' : 'desc', descending: !isDesc, orderBy: property });
	};

	const handlePageChange = (number) => {
		setPage({ ...page, number: number });
	};

	// Dialog
	const handleChangeTicketStatus = (ticket) => {
		setTicketsStatus(ticket.status);
		setTicketData(ticket);
		setDialogOpen(true);
	};

	const createMenuItems = (ticket) => [
		{
			icon: <ImportExportIcon />,
			text: t('view.ticketmanagement.button.inline.changestatus'),
			action: () => handleChangeTicketStatus(ticket),
		},
	];

	const dialogProps = {
		dialogOpen,
		setDialogOpen,
		status: ticketsStatus,
		setStatus: setTicketsStatus,
		data: ticketData,
		isDashboard: true,
		page,
		filterItems: filters,
		organisationId: dashboardOrganisationFilter.valueId,
	};

	// Table elements
	const ticketStatusColor = {
		nonCritical: 'warning',
		critical: 'error',
		missing: 'disabled',
	};

	const ticketText = {
		nonCritical: t('views.tableResults.serviceTickets.typeNonCritical'),
		critical: t('views.tableResults.serviceTickets.typeCritical'),
		missing: t('views.tableResults.serviceTickets.typeMissing'),
	};

	const tableHeaders =
		isPageEmpty ?
			[]
		:	[
				{ name: 'id', content: 'ui.label.id', hasSorting: true },
				{ name: 'created', content: 'ui.dateCreated' },
				{ name: 'defect', content: 'ui.label.defect' },
				{ name: 'defectType', content: 'ui.label.ticketType', hasSorting: true },
				{ name: 'itemName', content: 'ui.label.itemGroup' },
				{ name: 'item', content: 'ui.label.item' },
				{ name: '' },
			];

	const handleLinks = (path, label, returnButton = 'nav.category.dashboard') => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{
				from: location.pathname,
				label: t(returnButton),
				ticketStatus: status,
			}}
		>
			{label}
		</Link>
	);

	const loadingBody =
		dashboardTicketsLoading || loading ?
			Array(5)
				.fill(Array(tableHeaders?.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody = !(dashboardTicketsLoading && loading) &&
		isPageEmpty && [
			[
				{
					content: <EmptyTable label={`views.dashboard.tickets.emptyResults.${status}`} />,
				},
			],
		];

	const fullBody =
		!dashboardTicketsLoading && isPageFull ?
			dashboardTicketsData.results.map((ticket) => [
				{ content: handleLinks(`/ticket-details/${ticket.id}`, ticket.id) },
				{ content: commaTimeStrings(ticket.created) },
				{ content: ticket?.defect && ticket.defect.title },
				{
					content: (
						<Label type={ticketStatusColor[ticket.defect.type]}>
							{' '}
							{ticketText[ticket.defect.type]}{' '}
						</Label>
					),
				},
				{ content: ticket.item.name },
				{ content: ticket.instance.name },
				{ content: <GenericMoreButton menuItems={createMenuItems(ticket)} /> },
			])
		:	[];
	const body = loadingBody || emptyBody || fullBody;

	return (
		<>
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							<TableHead>
								<TableRow hover={false}>
									{tableHeaders.map((cell) => (
										<TableCell key={cell.name}>
											{cell.hasSorting ?
												<TableSortLabel
													active={order.orderBy === cell.name}
													direction={order.order === 'asc' ? 'desc' : 'asc'}
													onClick={() => handleRequestSort(cell.name)}
												>
													{t(cell.content)}
												</TableSortLabel>
											:	t(cell.content)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{body.map((row, rIndex) => (
									<TableRow hover={false} key={rIndex}>
										{row.map((cell, cIndex) => (
											<TableCell key={cIndex}>
												{cell.loading ?
													<LoadingBar />
												: cell.content ?
													cell.content
												:	<p>-</p>}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<TablePagination
					component='div'
					count={dashboardTicketsData ? dashboardTicketsData.total : 0}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} ${t('ui.of')} ${count !== -1 ? count : '0'}`
					}
					onPageChange={(e, page) => handlePageChange(page + 1)}
					page={page.number - 1}
					rowsPerPage={5}
					rowsPerPageOptions={[-1]}
				/>
			</CardActions>
			{dialogOpen ?
				<Dialog {...dialogProps} />
			:	null}
		</>
	);
};

TicketsList.propTypes = {
	onDashboardTickets: PropTypes.func,
	dashboardOrganisationFilter: PropTypes.object,
	status: PropTypes.string,
	dashboardTickets: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		dashboardTickets: state.paged.dashboardTickets,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDashboardTickets: (page, filters, concat) =>
			dispatch(actions.dashboardTickets(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsList);
