import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	cardRoot: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		padding: 12,
	},
	commentContainer: {
		padding: theme.spacing(3, 2),
	},
	emptyComment: {
		fontStyle: 'italic',
	},
	itemGroup: {
		...theme.typography.link1,
	},
	uploadedFile: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingTop: 0,
	},
	description: {
		marginRight: theme.spacing(1),
	},
	fileName: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	fileCard: {
		'& .MuiCardContent-root': {
			paddingBottom: '0px',
		},
		paddingBottom: '0px',
	},
	progress: {
		marginRight: '16px',
	},
}));
