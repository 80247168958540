import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const CustomBox = (props) => {
	const { children, ...rest } = props;
	return <Box {...rest}>{children}</Box>;
};

CustomBox.propTypes = {
	children: PropTypes.any,
};

export default CustomBox;
