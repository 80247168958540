import { isEmptyArray } from './utility';

export const itemReducer = (state, action) => {
	const { items, instances, selectedInstances, updatedItems } = action;

	switch (action.type) {
		case 'add':
			return state.concat(
				items.map((item) => {
					return {
						...item,
						isShowingInstances: false,
						isLoadingInstances: true,
						hasSelectAllSelected: false,
						instances: [],
						selectedInstanceIds: [],
					};
				}),
			);
		case 'update':
			return updatedItems;
		case 'removeItem':
			return state.filter((item) => item.id !== action.id);
		case 'removeInstance':
			return state.map((item) => ({
				...item,
				instances:
					!isEmptyArray(item.instances) ?
						item.instances.filter((instance) => instance.id !== action.instanceId)
					:	item.instances,
			}));
		case 'toggleSelectAll':
			return state.map((item) => ({
				...item,
				hasSelectAllSelected:
					item.id === action.id ? !item.hasSelectAllSelected : item.hasSelectAllSelected,
			}));
		case 'setSelectAllFalse':
			return state.map((item) => ({
				...item,
				hasSelectAllSelected: item.id === action.id ? false : item.hasSelectAllSelected,
			}));
		case 'toggleShowingInstances':
			return state.map((item) => ({
				...item,
				isShowingInstances:
					item.id === action.id ? !item.isShowingInstances : item.isShowingInstances,
			}));
		case 'addInstances':
			return state.map((item) => ({
				...item,
				isLoadingInstances: item.id === action.id ? false : item.isLoadingInstances,
				instances: item.id === action.id ? instances : item.instances,
				selectedInstanceIds:
					item.id === action.id && item.hasSelectAllSelected ?
						instances.map((instance) => instance.id)
					:	item.selectedInstanceIds,
			}));
		case 'addInstanceIds':
			return state.map((item) => ({
				...item,
				selectedInstanceIds:
					item.id === action.id ?
						item.selectedInstanceIds.concat(selectedInstances)
					:	item.selectedInstanceIds,
			}));
		case 'instanceRestriction':
			return state.map((item) =>
				item.id === items.itemId ?
					{
						...item,
						instances: item.instances.map((instance) =>
							instance.id === items.instanceId ?
								{
									...instance,
									isAvailable: !instance.isAvailable,
									restrictAvailable: !instance.restrictAvailable,
								}
							:	{ ...instance },
						),
					}
				:	{ ...item },
			);
		case 'removeInstanceIds':
			return state.map((item) => ({
				...item,
				selectedInstanceIds:
					item.id === action.id ?
						item.selectedInstanceIds.filter(
							(selectedInstanceId) => selectedInstances.indexOf(selectedInstanceId) === -1,
						)
					:	item.selectedInstanceIds,
			}));
		case 'clearInstanceIds':
			return state.map((item) => ({
				...item,
				selectedInstanceIds: item.id === action.id ? [] : item.selectedInstanceIds,
			}));
		case 'empty':
			return (state = []);
		default:
	}
};
