import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 700,
		'& .MuiTableHead-root': {
			backgroundColor: '#fff',
		},
	},
	actions: {
		padding: theme.spacing(0, 1),
		justifyContent: 'flex-end',
	},
	itemGroup: {
		...theme.typography.link1,
	},
	buble: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: theme.palette.error.light,
		minHeight: '20px',
		minWidth: '20px',
		borderRadius: '50%',
	},
	tooltipContainer: {
		borderRadius: '50%',
		backgroundColor: '#f1f3f7',
		padding: theme.spacing(1),
		marginRight: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',

		'&:hover': {
			boxShadow: '0px 0px 10px 1px gray',
		},
	},
	actionsHeader: {
		marginRight: theme.spacing(1),
		'&:link': {
			color: theme.palette.primary.main,
		},
		'&:visited': {
			color: theme.palette.primary.main,
		},
		'&:hover': {
			color: theme.palette.primary.main,
			textDecoration: 'underline',
		},
		'&:active': {
			color: theme.palette.primary.main,
		},
		...theme.typography.subtitle2,
		cursor: 'pointer',
	},
}));
