import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	drawer: {
		width: 450,
		maxWidth: '100%',
		padding: theme.spacing(2),
	},
	header: {
		margin: theme.spacing(4, 0, 3, 0),
	},
}));
