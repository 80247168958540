import { useState, useEffect } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
	Box,
	Card,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Link,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import VerificationIcon from '../../../../../../assets/icons/label-filled-icon-success.svg';
import {
	HeaderWithStatus,
	IconInfoLabel,
	StyledButton,
	ActionDialog,
} from '../../../../../../components';
import { localizeDateTime, commaTimeStrings } from '../../../../../../shared/datetime';
import { isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

const handleTimeStrings = (value) => {
	return localizeDateTime(value, undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
};

const LicenseDetails = (props) => {
	const {
		license,
		className,

		onResetLicensesAttempts,
		resetLicensesAttempts,
		customer,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const {
		success: resetLicensesAttemptsSuccess,
		loading: resetLicensesAttemptsLoading,
		error: resetLicensesAttemptsError,
	} = resetLicensesAttempts;

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const location = useLocation();

	const [openDialog, setOpenDialog] = useState(false);

	useEffect(() => {
		if (resetLicensesAttemptsSuccess) {
			onResetStateCondition('resetLicensesAttempts', false);
			setOpenDialog((val) => !val);
			enqueueSnackbar(t('views.licenses.dialog.success'), { variant: 'success' });
		} else if (resetLicensesAttemptsError) {
			onResetStateCondition('resetLicensesAttempts', false);
			enqueueSnackbar(resetLicensesAttemptsError?.message, { variant: 'error' });
		}
	}, [resetLicensesAttempts]);

	const statusColor = {
		rejected: 'error',
		pending: 'default',
		verified: 'success',
		expired: 'disabled',
	};

	const statusText = {
		rejected: t('ui.status.rejected'),
		pending: t('ui.status.pending'),
		verified: t('ui.verified'),
		expired: t('ui.label.expired'),
	};

	const headerProps = {
		header: t('views.userDetails.summary.cardHeaders.license'),
		headerSize: 'h6',
		justifyContent: 'space-between',
		padding: 2,
		status: isObject(license) ? statusText[license.status] : '',
		subheaderSize: 'body2',
		type: isObject(license) ? statusColor[license.status] : '',
	};

	const handleDialog = () => setOpenDialog((val) => !val);

	const confirmResetAttempts = () => onResetLicensesAttempts(customer.id);

	return (
		<Card className={clsx(classes.root, className)}>
			<Box alignItems='center' display='flex' justifyContent='space-between' mr={2}>
				<HeaderWithStatus {...headerProps} />
				{customer?.validationStatus === 'blocked' ?
					<StyledButton
						onClick={handleDialog}
						startIcon={<RestartAltIcon />}
						variant='inline-default'
					>
						{t('views.licenses.dialog.header')}
					</StyledButton>
				:	null}
			</Box>
			<Divider />
			<CardContent>
				{isObject(license) ?
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>{t('views.licenses.table.requestId')}</TableCell>
								<TableCell>
									<Link
										className={classes.itemGroup}
										color='inherit'
										component={RouterLink}
										to={`/licenses/request/${license.userReference.id}`}
										state={{ from: location.pathname, location: 'summary' }}
										variant='h6'
									>
										{license.id}
									</Link>
								</TableCell>
							</TableRow>
							{license.status === 'rejected' && (
								<TableRow>
									<TableCell>{t('views.userManagement.license.rejectionDate')}</TableCell>
									<TableCell> {handleTimeStrings(license.lastModifiedDate)} </TableCell>
								</TableRow>
							)}
							<TableRow>
								<TableCell>{t('views.userManagement.license.country')}</TableCell>
								<TableCell>
									{!!license && license.countryCode === 'nl' ?
										'The Netherlands'
									:	license.countryCode}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('ui.label.dateOfBirth')}</TableCell>
								<TableCell>
									<Box alignItems='center' display='flex'>
										{license ? handleTimeStrings(license.dateOfBirth) : '-'}
									</Box>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('ui.label.licenseNumber')}</TableCell>
								<TableCell>
									<Box display={'flex'} flexDirection={'row'}>
										{(
											license.validationStatus === 'validated' &&
											license.countryCode.localeCompare('NL', 'en', { sensitivity: 'base' }) === 0
										) ?
											<IconInfoLabel
												content={
													<Typography className={classes.licenseNumber}>
														{license.licenseNumber}{' '}
													</Typography>
												}
												icon={
													<img
														alt='verification icon'
														height='16'
														src={VerificationIcon}
														width='16'
													/>
												}
												infoTitle={`${t('ui.rdw.validated')} ${commaTimeStrings(license.validationDate)}`}
												showIcon={true}
											/>
										:	<Box alignItems='center' display='flex'>
												<Typography className={classes.licenseNumber}>
													{license.licenseNumber}
												</Typography>
											</Box>
										}
										{license.isDuplicate && (
											<IconInfoLabel
												icon={<ErrorOutlineOutlinedIcon color='error' fontSize='small' />}
												infoTitle={t('views.pendingLicenseDetails.duplicateLicense')}
												showIcon={true}
											/>
										)}
									</Box>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('ui.label.expiryDate')}</TableCell>
								<TableCell> {license ? handleTimeStrings(license.expiryDate) : '-'} </TableCell>
							</TableRow>
						</TableBody>
					</Table>
				:	<Typography variant='body2'>{t('views.userManagement.license.noLicense')}</Typography>}
			</CardContent>
			{openDialog ?
				<ActionDialog
					actionButtonProps={{
						action: confirmResetAttempts,
						text:
							resetLicensesAttemptsLoading ?
								<CircularProgress disableShrink size={24} />
							:	t('ui.confirm'),
					}}
					handleClose={handleDialog}
					loading={resetLicensesAttemptsLoading}
					open={openDialog}
					title={t('views.licenses.dialog.header')}
				>
					{t('views.licenses.dialog.description')}
				</ActionDialog>
			:	null}
		</Card>
	);
};

LicenseDetails.propTypes = {
	className: PropTypes.string,
	license: PropTypes.object,

	onResetLicensesAttempts: PropTypes.func,
	customer: PropTypes.object,
	onResetStateCondition: PropTypes.func,
	resetLicensesAttempts: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		resetLicensesAttempts: state.condition.resetLicensesAttempts,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetLicensesAttempts: (userId) => dispatch(actions.resetLicensesAttempts(userId)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseDetails);
