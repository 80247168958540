import { Switch } from '@mui/material';
import { withStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import palette from '../../../../theme/palette';

export const SwitchButton = withStyles({
	switchBase: {
		color: '#586b85',
		'&$checked': {
			color: palette.button.primary,
		},
		'&$checked + $track': {
			backgroundColor: palette.button.primary,
		},
	},
	checked: {},
	track: {},
})(Switch);

export const useStyles = makeStyles((theme) => ({
	root: {},
	arrowLeft: {
		cursor: 'pointer',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
	},
	sideCard: {
		'& .MuiBox-root ': {
			display: 'flex',
			flexDirection: 'column',
		},
		fullWidth: {
			display: 'inline',
			position: 'absolute',
		},
	},
	divider: {
		margin: theme.spacing(2, 0, 0, 0),
	},
	switch: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: '0.875rem',
	},
}));
