import { useEffect, useState } from 'react';

import {
	Box,
	Link,
	TablePagination,
	TableSortLabel,
	CardContent,
	CardActions,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import WarningIcon from '../../../../assets/icons/label-filled-icon-warning@3x.png';
import { EmptyTable, LoadingBar, Label, IconInfoLabel } from '../../../../components';
import { commaTimeStrings, isLaterThan, isEarlierThan } from '../../../../shared/datetime';
import {
	isObject,
	isEmptyArray,
	isUndefined,
	isFullArray,
	isFullString,
	isSuperAdmin,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../../style';

const BookingList = (props) => {
	const {
		status,
		onDashboardBookings,

		dashboardBookings,
		dashboardOrganisationFilter,
	} = props;

	const { t } = useTranslation('general');
	const auth = useAuth();
	const location = useLocation();

	const { data: dashboardBookingsData, loading: dashboardBookingsLoading } = dashboardBookings;
	const isPageEmpty =
		isObject(dashboardBookingsData) &&
		(isEmptyArray(dashboardBookingsData.results) || isUndefined(dashboardBookingsData.results));
	const isPageFull = isObject(dashboardBookingsData) && isFullArray(dashboardBookingsData.results);

	const [page, setPage] = useState({ number: 1, size: 5 });
	const [order, setOrder] = useState({ order: 'asc', descending: true, orderBy: 'id' });

	const [loading, setLoading] = useState(true);

	const classes = useStyles();

	const filters = {
		state: status,
		orderDescending: order.descending,
		sortBy: order.orderBy,
		...(isFullString(dashboardOrganisationFilter.valueName) && {
			organisationId: dashboardOrganisationFilter.valueId,
		}),
	};

	useEffect(() => {
		onDashboardBookings(page, filters);
		setLoading(false);
		return () => {
			setLoading(true);
		};
	}, [order, page, dashboardOrganisationFilter.valueId]);

	const handleRequestSort = (property) => {
		const isDesc = order.orderBy === property && order.order === 'desc';
		setOrder({ order: isDesc ? 'asc' : 'desc', descending: !isDesc, orderBy: property });
	};

	const handlePageChange = (number) => {
		setPage({ ...page, number: number });
	};

	const tableHeaders =
		isPageEmpty ?
			[]
		:	[
				{ name: 'id', content: 'ui.label.id', hasSorting: true },
				{ name: 'start', content: 'views.ItemDetail.activities.start', hasSorting: true },
				{ name: 'end', content: 'views.ItemDetail.activities.end', hasSorting: true },
				{ name: 'userName', content: 'ui.label.user' },
				{ name: 'itemName', content: 'ui.label.itemGroup' },
				{ name: 'item', content: 'ui.label.item' },
				{ name: 'status', content: 'ui.label.status' },
			];

	const handleLinks = (path, label, returnButton = 'nav.category.dashboard') => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={`/${path}`}
			state={{
				from: location.pathname,
				label: t(returnButton),
				status: status,
			}}
		>
			{label}
		</Link>
	);

	const handleStatus = (usageState, end = null) => {
		if (status === 'active') {
			if (usageState === 'ready') {
				return 'ui.status.readyToStart';
			} else if (usageState === 'started') {
				return 'ui.status.inUse';
			} else {
				return 'ui.label.active';
			}
		} else if (status === 'upcoming') {
			return 'ui.status.upcoming';
		} else if (
			status === 'overtime' ||
			(usageState === 'started' && status === 'attention' && isEarlierThan(new Date(end)))
		) {
			return 'ui.status.overtime';
		} else if (status === 'approval') {
			return 'ui.status.pending';
		} else if (usageState === 'stopped' && status === 'attention') {
			return 'ui.status.completed';
		} else if (usageState === 'started' && status === 'attention' && isLaterThan(new Date(end))) {
			return 'ui.status.inUse';
		}
	};

	const handleType = (usageState, end = null) => {
		if (status === 'active') {
			if (usageState === 'ready' || usageState === 'started') {
				return 'success';
			} else {
				return 'success';
			}
		} else if (status === 'upcoming') {
			return 'default';
		} else if (
			status === 'overtime' ||
			status === 'approval' ||
			(usageState === 'started' && status === 'attention' && isEarlierThan(new Date(end)))
		) {
			return 'error';
		} else if (usageState === 'stopped' && status === 'attention') {
			return 'disabled';
		} else if (status === 'attention' && isLaterThan(new Date(end))) {
			return 'success';
		}
	};

	const handleStatusLabel = (booking) => {
		const infoProps = {
			icon: <img alt='warning-icon' height='16' src={WarningIcon} width='16' />,
			showIcon: !booking.attentionStates.includes('none'),
			infoTitle: t('views.bookings.details.label.requiresAttention'),
		};

		return (
			isFullString(handleType(booking.usageState)) && (
				<Box alignItems='center' display='flex'>
					<Label
						type={
							isFullString(handleType(booking.usageState)) &&
							handleType(booking.usageState, booking?.end)
						}
					>
						{t(handleStatus(booking.usageState, booking?.end))}
					</Label>
					{isSuperAdmin(auth.user?.profile.role) ?
						<IconInfoLabel {...infoProps} />
					:	null}
				</Box>
			)
		);
	};

	const loadingBody =
		dashboardBookingsLoading || loading ?
			Array(5)
				.fill(Array(tableHeaders?.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody = !(dashboardBookingsLoading && loading) &&
		isPageEmpty && [
			[
				{
					content: <EmptyTable label={`views.dashboard.bookings.emptyResults.${status}`} />,
				},
			],
		];

	const fullBody =
		!dashboardBookingsLoading && isPageFull ?
			dashboardBookingsData.results.map((booking) => [
				{ content: handleLinks(`booking/booking-detail/${booking.id}/summary`, booking.id) },
				{ content: commaTimeStrings(booking.start) },
				{ content: booking?.end && commaTimeStrings(booking.end) },
				{ content: booking.user.name },
				{ content: booking.item.name },
				{ content: booking.instance.name },
				{ content: handleStatusLabel(booking) },
			])
		:	[];

	const body = loadingBody || emptyBody || fullBody;

	return (
		<>
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							<TableHead>
								<TableRow hover={false}>
									{tableHeaders.map((cell) => (
										<TableCell key={cell.name}>
											{cell.hasSorting ?
												<TableSortLabel
													active={order.orderBy === cell.name}
													direction={order.order === 'asc' ? 'desc' : 'asc'}
													onClick={() => handleRequestSort(cell.name)}
												>
													{t(cell.content)}
												</TableSortLabel>
											:	t(cell.content)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{body.map((row, rIndex) => (
									<TableRow hover={false} key={rIndex}>
										{row.map((cell, cIndex) => (
											<TableCell key={cIndex}>
												{cell.loading ?
													<LoadingBar />
												: cell.content ?
													cell.content
												:	<p>-</p>}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<TablePagination
					component='div'
					count={dashboardBookingsData ? dashboardBookingsData.total : 0}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} ${t('ui.of')} ${count !== -1 ? count : '0'}`
					}
					onPageChange={(e, page) => handlePageChange(page + 1)}
					page={page.number - 1}
					rowsPerPage={5}
					rowsPerPageOptions={[-1]}
				/>
			</CardActions>
		</>
	);
};

BookingList.propTypes = {
	status: PropTypes.string,
	onDashboardBookings: PropTypes.func,
	dashboardOrganisationFilter: PropTypes.object,

	dashboardBookings: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		dashboardBookings: state.paged.dashboardBookings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDashboardBookings: (page, filters, concat) =>
			dispatch(actions.dashboardBookings(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
