import { useState } from 'react';

import { TextField } from '@mui/material';
import Ajv from 'ajv';
import { useTranslation } from 'react-i18next';

import { FormContainer, FormContainerProps } from '~components';
import { NfcAutocomplete, NfcTag } from '~features/nfc';
import { OrganisationAutocomplete } from '~features/organisations';
import { BaseReference, IdReference } from '~interfaces';

import schema from './createAnonymousUserSchema.json';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';

const ajv = new Ajv();
const validate = ajv.compile(schema);

interface CreateAnonymousFormData {
	organisation: BaseReference;
	firstName: string;
	lastName: string;
	nfcTag: NfcTag;
}

interface CreateAnonymousFormProps extends Omit<FormContainerProps, 'onSubmit'> {
	onSubmit:(value: CreateAnonymousFormData) => void;
}

const CreateAnonymousForm = ({ onClose, onSubmit, ...formContainerProps }: CreateAnonymousFormProps) => {
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const [valid, setValid] = useState(false);
	const [value, setValue] = useState<Partial<CreateAnonymousFormData>>({
		organisation: isSuperAdmin() ? undefined : userInfo.organisation
	});

	const handleCloseClick = () => {
		setValue({});
		if (onClose) {
			onClose();
		}
	};

	const handleOrganisationChange = (newOrganisation: BaseReference | undefined) => {
		const newValue: Partial<CreateAnonymousFormData> = {
			...value,
			nfcTag: undefined,
			organisation: newOrganisation,
		};
		setValue(newValue);
	};

	const handleNfcTagChange = (newNfcTag: NfcTag | undefined) => {
		const newValue: Partial<CreateAnonymousFormData> = {
			...value,
			nfcTag: newNfcTag,
			...(newNfcTag && {
				organisation: newNfcTag?.organisation,
			}),
		};
		setValue(newValue);
	};

	const handleChange = (newValue: Partial<CreateAnonymousFormData>) => {
		setValid(validate(newValue));
		setValue(newValue);
		console.debug(newValue, validate.errors);
	};

	return (
		<FormContainer
			{...formContainerProps}
			submitDisabled={!valid}
			onClose={handleCloseClick}
			onSubmit={() => onSubmit && onSubmit(value as CreateAnonymousFormData)}
		>
			{isSuperAdmin() &&
				<OrganisationAutocomplete
					required
					label={t('organisation')}
					value={value.organisation ?? null}
					onChange={(_, newValue) => handleOrganisationChange(newValue)}
				/>
			}
			<NfcAutocomplete
				required
				label={t('ui.label.anonymousUserNFCTag')}
				value={value.nfcTag ?? null}
				organisation={value.organisation}
				onChange={(_, newValue) => handleNfcTagChange(newValue)}
			/>
			<TextField
				required
				label={t('ui.label.firstName')}
				value={value.firstName ?? null}
				onChange={(e) => handleChange({ ...value, firstName: e.target.value })}
			/>
			<TextField
				required
				label={t('ui.label.lastName')}
				value={value.lastName ?? null}
				onChange={(e) => handleChange({ ...value, lastName: e.target.value })}
			/>
		</FormContainer>
	);
};

export default CreateAnonymousForm;
