import { useRef, useState } from 'react';

import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';

import { AsyncAutocomplete, AsyncAutocompleteProps } from '~components';
import { useDebounce } from '~hooks';
import { BaseReference, IdReference, PagedResponse } from '~interfaces';
import i18n from '~lib/i18n';

import SkcDeviceGroup from '../../interfaces/skcDeviceGroup';
import SkcUserGroupsService from '../../services/skcUserGroupsService';

const service = new SkcUserGroupsService();

interface SkcUserGroupAutocompleteProps extends Omit<AsyncAutocompleteProps, 'options' | 'label'> {
	label?: string;
	count?: number;
	organisation?: IdReference;
}

const SkcUserGroupAutocomplete = ({
	label = i18n.t('userGroup'),
	count = 10,
	enableInfiniteScroll = false,
	disableInstantSearch = false,
	organisation,
	...asyncAutocompleteProps
}: SkcUserGroupAutocompleteProps) => {
	const { t } = useTranslation('general');

	const [inputValue, setInputValue] = useState('');
	const debouncedSearchQuery = useDebounce(inputValue);
	const [initialLoad, setInitialLoad] = useState(false);

	const total = useRef<number>(null!);
	const page = useRef(1);

	const getKey = (i: number, previousData: PagedResponse<SkcDeviceGroup[]>) => {
		if (!initialLoad || (previousData && !previousData.results.length)) {
			// reached the end
			return null;
		}

		page.current = i + 1;

		return [
			service.basePath,
			{
				page: page.current,
				pageSize: count,
				organisationId: organisation?.id
			}
		];
	};

	const { data, isLoading, isValidating, size, setSize } = useSWRInfinite(
		getKey,
		([_, args]) => service.getUserGroups(args),
		{
			keepPreviousData: true,
			revalidateFirstPage: false,
			onSuccess: (res) => (total.current = res[0].total),
		},
	);

	/**
	 * Just set the size of the pages
	 */
	const handleOverflow = () => {
		if ((size - 1) * count <= total.current) {
			setSize(size + 1);
		}
	};

	const handleChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: NonNullable<string | BaseReference> | (string | BaseReference)[] | null,
		reason: AutocompleteChangeReason,
	) => {
		if (asyncAutocompleteProps.onChange) {
			asyncAutocompleteProps.onChange(e, value, reason);
		}
	};

	const handleInputChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: string,
		reason: AutocompleteInputChangeReason,
	) => {
		setInputValue(value);

		if (asyncAutocompleteProps.onInputChange) {
			asyncAutocompleteProps.onInputChange(e, value, reason);
		}
	};

	const mappedData = data?.flatMap((el) => el.results);

	return (
		<AsyncAutocomplete
			{...asyncAutocompleteProps}
			disableInstantSearch={disableInstantSearch}
			label={label}
			loading={isLoading || isValidating}
			noOptionsText={t('noResults')}
			onOpen={() => setInitialLoad(true)}
			options={data ? mappedData : []}
			onChange={handleChange}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			enableInfiniteScroll={enableInfiniteScroll}
			onOverflow={handleOverflow}
		/>
	);
};

export default SkcUserGroupAutocomplete;
