import path from 'path';

import axios, { AxiosInstance } from 'axios';
import { User } from 'oidc-client-ts';

import BaseService from './baseService';

/**
 * The base service to setup basic functionality to communicate
 * with the backend
 */
abstract class TopologyService extends BaseService {
	protected _client: AxiosInstance;

	protected readonly apiVersionPath: string;
	protected readonly path: string = '';

	public get basePath(): string {
		return path.join(this.apiVersionPath, this.path);
	}

	constructor(version: 'v1' | 'v2') {
		super();

		this.apiVersionPath = path.join('api', version);
		this._client = axios.create({
			baseURL: new URL(this.apiVersionPath, import.meta.env.VITE_TOPOLOGY_API_URL).href,
			// Allows the the requests to be cancelled
			signal: this._controller.signal,
		});

		this.initInterceptor();
	}

	/**
	 * Abort the current get requests.
	 * @param reason A reason to abort, e.g. for logging
	 */
	public abortCurrentRequest(reason?: string) {
		this._controller.abort(reason);
		this._controller = new AbortController();

		// Reattach it to the client so we can abort the next request
		this._client.defaults.signal = this._controller.signal;
	};

	/**
	 * If interceptors are defined in the /src/lib folder
	 * that won't work for axios instances. We really have to set it
	 * on the instance
	 * We add the token though an interceptor because if we set it directly
	 * on the axios instance and the token expires? How to refresh? This
	 * seems the more easy and stable way to do it
	 */
	private initInterceptor() {
		const identityAuthority = import.meta.env.VITE_IDENTITY_AUTHORITY.replace(/\/$/, '');
		const oidcStorageString = `oidc.user:${identityAuthority}:${import.meta.env.VITE_IDENTITY_CLIENT_ID}`;

		this._client.interceptors.request.use((config) => {
			const oidcStorage = localStorage.getItem(oidcStorageString);
			if (oidcStorage) {
				const user = User.fromStorageString(oidcStorage);
				config.headers['Authorization'] = `Bearer ${user.access_token}`;
			}

			return config;
		});
	}
}

export default TopologyService;
