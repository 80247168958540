import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { usePrevious } from '../../../../shared/hooks';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import AddPolicy from '../../ActionFlows/AddPolicy';

const EditPolicy = (props) => {
	const { policyDetails, onFetchPolicy } = props;
	const location = useLocation();

	const { id } = useParams();

	const { data: policyDetailsData, loading: policyDetailsLoading } = policyDetails;

	const policyLoading = usePrevious(policyDetailsLoading);

	const data = policyLoading && policyDetailsData;

	useEffect(() => {
		if (isObject(policyDetailsData) && id !== policyDetailsData.id) {
			onFetchPolicy(id);
		}
	}, [id]);

	useEffect(() => {
		if (!isObject(policyDetailsData) && id) {
			onFetchPolicy(id);
		}
	}, [policyDetailsData, id]);

	return isObject(data) ?
			<AddPolicy editPolicyData={data} editRedirect={location.state.detail} editing={true} />
		:	null;
};

EditPolicy.propTypes = {
	policyDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchPolicy: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		policyDetails: state.details.fetchPolicy,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPolicy: (id) => dispatch(actions.fetchPolicy(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPolicy);
