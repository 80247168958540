import { useEffect } from 'react';

import {
	Card,
	CardContent,
	Typography,
	Radio,
	List,
	Box,
	ListItemButton,
	Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import {
	SelectedOrganisationCard,
	SearchAutocomplete,
	EmptyTable,
} from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';
import { isSuperAdmin, isEmptyObject, isNull } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import ItemHeader from '../ItemHeader';
import { useStyles } from '../stepsStyles';

const BookingCard = (props) => {
	const {
		save,
		bookingTypes,
		reservationType,

		organisationsList,
		onFetchOrganisations,
		selectedOrganisation: selectedOrg,
		bookingTypesList,
		onFetchBookingTypes,
		currentUser,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const classes = useStyles();

	const selectedOrganisation = useWizardFormField(selectedOrg, { required: true });
	useDebouncedWizardSave(
		'organisation',
		selectedOrganisation.value,
		selectedOrganisation.isValid,
		save,
		400,
	);

	const { data: bookingTypesData, loading: bookingTypesLoading } = bookingTypesList;

	const { data: currentUserData } = currentUser;

	useEffect(() => {
		if (!bookingTypesLoading && isNull(bookingTypesData)) {
			onFetchBookingTypes();
		}
	}, [bookingTypesData]);

	const handleChangeRadio = (event, item) => {
		save({
			updatedReservationType: item.reservationType,
			updatedBookingTypes: item.bookingTypes,
		});
	};

	const resetSelectedOrganisation = () => {
		selectedOrganisation.onChange({});
	};
	const isTelematics =
		currentUserData?.organisationReference.subscriptionModule.includes('telematics');

	return (
		<Card>
			<CardContent className={classes.mainCard}>
				<ItemHeader
					header={t('views.addItem.booking.title')}
					padding={2}
					subheader={t('views.addItem.booking.subTitle')}
					variant='h3'
					variantText='body2'
				/>
				<Box pb={3}>
					<EmptyTable
						label={
							isTelematics ?
								'views.addItem.booking.warning.telematics.message'
							:	'views.addItem.booking.warning.message'
						}
					/>
				</Box>
				{isSuperAdmin(auth.user?.profile.role) ?
					<div className={classes.itemSpacing}>
						{!isEmptyObject(selectedOrganisation.value) ?
							<SelectedOrganisationCard
								handleClose={resetSelectedOrganisation}
								hasCloseButton={false}
								name={selectedOrganisation.value.name}
							/>
						:	<SearchAutocomplete
								dataList={organisationsList}
								label={t('ui.organisation')}
								listType={'organisations'}
								onFetchData={onFetchOrganisations}
								placeholder={t('views.addPolicy.aboutPolicy.placeholder.searchOrganisation')}
								setSelected={selectedOrganisation.onChange}
							/>
						}
					</div>
				:	null}
				<List>
					{bookingTypesData &&
						bookingTypesData.map((item) => (
							<Tooltip
								arrow
								disableHoverListener={
									isTelematics && item.reservationType !== 'nonBookable' ? false : true
								}
								key={item.id}
								title={t('telematics.message.notAvailable')}
							>
								<Box pb={3}>
									<ListItemButton
										className={clsx(classes.option, {
											[classes.selectedOption]:
												reservationType === item.reservationType &&
												bookingTypes.some((type) => item.bookingTypes.includes(type)),
										})}
										disabled={isTelematics && item.reservationType !== 'nonBookable' ? true : false}
										onClick={(event) => handleChangeRadio(event, item)}
									>
										<Radio
											checked={
												reservationType === item.reservationType &&
												bookingTypes.some((type) => item.bookingTypes.includes(type))
											}
											className={classes.optionRadio}
											onChange={(event) => handleChangeRadio(event, item)}
											value={item.reservationType}
										/>
										<div className={classes.optionDetails}>
											<Typography gutterBottom variant='h5'>
												{item.title}
											</Typography>
											<Typography variant='body1'>{item.description}</Typography>
										</div>
									</ListItemButton>
								</Box>
							</Tooltip>
						))}
				</List>
			</CardContent>
		</Card>
	);
};

BookingCard.propTypes = {
	className: PropTypes.string,
	reservationType: PropTypes.string,
	bookingTypes: PropTypes.array,
	itemStep: PropTypes.number,
	save: PropTypes.func,

	onFetchOrganisations: PropTypes.func,
	selectedOrganisation: PropTypes.object,
	onFetchBookingTypes: PropTypes.func,
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	bookingTypesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		bookingTypesList: state.list.bookingTypes,
		organisationsList: state.paged.organisations,

		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchBookingTypes: () => dispatch(actions.fetchBookingTypes()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingCard);
