import { useEffect, useState } from 'react';

import path from 'path';

import {
	AdminPanelSettings as AdminPanelSettingsIcon,
	Block as BlockIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
} from '@mui/icons-material';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { userInfoAtom } from '~atoms';
import { ConfirmationDialog, Dialog, SummaryContainer } from '~components';
import { pagePathSegments } from '~constants';
import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { NfcGeneralInfoCard, NfcTag, NfcTagsService } from '~features/nfc';
import { usePageButtons } from '~layouts';

import UserGeneralInfoCard from '../components/cards/userGeneralInfoCard';
import UserLicenseCard from '../components/cards/userLicenseCard';
import UserOrganisationInfoCard from '../components/cards/userOrganisationInfoCard';
import LinkNfcTagToUserForm from '../forms/linkNfcTagToUser/linkNfcTagToUserForm';
import useUserData from '../hooks/useUserData';
import UserLicensesService from '../services/userLicensesService';
import UsersService from '../services/usersService';

const nfcTagsService = new NfcTagsService();
const userLicensesService = new UserLicensesService();

type UserAction =
	| {
			type: 'link';
			id?: never;
	  }
	| {
			type: 'unlink';
			id: string;
	  };

interface UserDetailsLayoutProps {
	id: string;
}

const UserDetailsLayout = ({ id }: UserDetailsLayoutProps) => {
	const { t } = useTranslation('general');
	const pageButtons = usePageButtons();
	const { enqueueSnackbar } = useSnackbar();
	const { isSuperAdmin } = useAuthorize();

	const userInfo = useAtomValue(userInfoAtom);

	const { trigger: linkNfcTrigger, isMutating: isLinkNfcMutating } = useSWRMutation(
		[nfcTagsService.basePath, id],
		([_, id], data2) => nfcTagsService.updateNfcTag(id, data2)
	);

	const { data, isLoading, error, mutate } = useUserData(id);

	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [userAction, setUserAction] = useState<UserAction | null>(null);

	useEffect(() => {
		if (isSuperAdmin()) {
			pageButtons.set([
				{
					children: t('changeRole'),
					startIcon: <AdminPanelSettingsIcon />,
				},
				{
					children: t('deactivate'),
					startIcon: <BlockIcon />,
					color: 'error',
				},
				{
					children: t('delete'),
					startIcon: <DeleteOutlinedIcon />,
					color: 'error',
				},
			]);
		}
	}, []);

	const handleLinkNfcTag = async (nfcTagData: NfcTag) => {
		setUserActionProcessing(true);

		try {
			const newData = {
				...nfcTagData,
				user: { id: data!.id, label: data!.label },
			};
			await nfcTagsService.updateNfcTag(nfcTagData.id, newData);

			enqueueSnackbar('Linked', { variant: 'success' });
			setUserAction(null);
			mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleUnlinkNfcTag = async (nfcId: string) => {
		setUserActionProcessing(true);

		try {
			await nfcTagsService.unlinkNfcTag(nfcId);
			enqueueSnackbar('Nfc unlinked', { variant: 'success' });

			// TODO: this probably doesn't work, because we lose the reference to
			// the id once unlinked
			// Look why it doesn't mutate
			setUserAction(null);
			mutate();
		} catch (error) {
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
			console.error(error);
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
		<>
			<SummaryContainer>
				<UserGeneralInfoCard userId={id} />
				<UserOrganisationInfoCard userId={id} />
				<UserLicenseCard userId={data?.hasUserLicense ? id : undefined} />
				<NfcGeneralInfoCard
					id={data?.cards[0]?.id}
					onUnlinkClick={(nfcId) => setUserAction({ type: 'unlink', id: nfcId })}
					onLinkClick={() => setUserAction({ type: 'link' })}
				/>
			</SummaryContainer>
			<Dialog
				title='Link nfc tag'
				open={userAction?.type === 'link'}
				onClose={() => setUserAction(null)}
			>
				<LinkNfcTagToUserForm
					userId={id}
					processing={userActionProcessing}
					onSubmit={(val) => handleLinkNfcTag(val.nfcTag)}
					onClose={() => setUserAction(null)}
				/>
			</Dialog>
			<ConfirmationDialog
				open={userAction?.type === 'unlink'}
				title={t('unlink')}
				subTitle={'TODO'}
				loading={userActionProcessing}
				onConfirm={() => handleUnlinkNfcTag(userAction?.id)}
				onDecline={() => setUserAction(null)}
			/>
		</>
	);
};

export default UserDetailsLayout;
