import BikeActive from '../../assets/icons/markers/marker-bike-active.svg';
import Bike from '../../assets/icons/markers/marker-bike.svg';
import BoatActive from '../../assets/icons/markers/marker-boat-active.svg';
import Boat from '../../assets/icons/markers/marker-boat.svg';
import CarActive from '../../assets/icons/markers/marker-car-active.svg';
import Car from '../../assets/icons/markers/marker-car.svg';
import ClusterActive from '../../assets/icons/markers/marker-cluster-active.svg';
import Cluster from '../../assets/icons/markers/marker-cluster.svg';
import DefaultActive from '../../assets/icons/markers/marker-default-active.svg';
import Default from '../../assets/icons/markers/marker-default.svg';
import DeviceActive from '../../assets/icons/markers/marker-device-active.svg';
import Device from '../../assets/icons/markers/marker-device.svg';
import LocationActive from '../../assets/icons/markers/marker-location-active.svg';
import Location from '../../assets/icons/markers/marker-location.svg';
import OfficeActive from '../../assets/icons/markers/marker-office-active.svg';
import Office from '../../assets/icons/markers/marker-office.svg';
import OtherActive from '../../assets/icons/markers/marker-other-active.svg';
import Other from '../../assets/icons/markers/marker-other.svg';
import ParkingActive from '../../assets/icons/markers/marker-parking-active.svg';
import Parking from '../../assets/icons/markers/marker-parking.svg';
import TrailerActive from '../../assets/icons/markers/marker-trailer-active.svg';
import Trailer from '../../assets/icons/markers/marker-trailer.svg';
import { isObject, isInteger } from '../../shared/utility';

export const mapIcons = {
	cluster: Cluster,
	default: Default,
	devices: Device,
	locations: Location,
	bikes: Bike,
	cars: Car,
	offices: Office,
	trailers: Trailer,
	parkingLots: Parking,
	assets: Other,
	boats: Boat,

	clusterActive: ClusterActive,
	defaultActive: DefaultActive,
	devicesActive: DeviceActive,
	locationsActive: LocationActive,
	bikesActive: BikeActive,
	carsActive: CarActive,
	officesActive: OfficeActive,
	trailersActive: TrailerActive,
	parkingLotsActive: ParkingActive,
	assetsActive: OtherActive,
	boatsActive: BoatActive,
};

export const getCount = (count) => (isInteger(count) && count !== 0 ? count : 1);
export const getTotal = (prev, current) =>
	(isObject(prev) ? getCount(prev.label.total) : prev) + getCount(current.label.total);

export const createMap = (element, zoom, center, styles, restriction) =>
	new window.google.maps.Map(element, {
		zoom: zoom,
		center: center,
		styles: styles,
		restriction: restriction,
	});
export const createMarker = (position, map, label, icon, title) =>
	new window.google.maps.Marker({
		position: position,
		label: label,
		map: map,
		icon: icon,
		title: title, // text to display when mouse is over the icon
	});
export const createLabel = (options = { total: 1, color: '#000' }) => ({
	text: `${options.total}`,
	color: options.total > 1 ? options.color : '#fff',
	fontSize: options.total > 1 ? '18px' : '0px',
	total: options.total,
	fontFamily: '"Poppins-Medium" sans-serif',
});
export const createIcon = (options) => ({
	// url: url,
	size: new window.google.maps.Size(70, 70),
	// origin: new window.google.maps.Point(0, 0),
	// anchor: new window.google.maps.Point(35, 35),
	labelOrigin: new window.google.maps.Point(35, 30),
	scaledSize: new window.google.maps.Size(70, 70),
	...options,
});

export const createPath = (
	coordinates,
	strokeColor = '#016dff',
	geodesic = true,
	strokeOpacity = 1.0,
	strokeWeight = 3,
) => ({
	path: coordinates,
	geodesic: geodesic,
	strokeColor: strokeColor,
	strokeOpacity: strokeOpacity,
	strokeWeight: strokeWeight,
});

export const renderer = {
	render: ({ count, position, markers }) =>
		new window.google.maps.Marker({
			label: {
				text: String(markers.reduce(getTotal, 0)),
				color: 'white',
				fontSize: '18px',
				fontFamily: '"Poppins-Medium" sans-serif',
			},
			position,
			// adjust zIndex to be above other markers
			zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
			icon: {
				url: mapIcons.cluster,
				size: new window.google.maps.Size(80, 80),
				// origin: new window.google.maps.Point(0, 0),
				// anchor: new window.google.maps.Point(40, 40),
				labelOrigin: new window.google.maps.Point(40, 35),
				scaledSize: new window.google.maps.Size(80, 80),
			},
		}),
};
export const style = {
	flexGrow: '1',
	height: '100%',
};
