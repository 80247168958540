import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		'& .MuiTableHead-root': {
			backgroundColor: '#fff',
		},
	},
	tabs: {
		marginTop: theme.spacing(3),
	},
	divider: {
		backgroundColor: colors.grey[300],
	},
	content: {
		marginTop: theme.spacing(3),
	},
}));
