import PropTypes from 'prop-types';

import { StyledTooltip } from './style';
import { isUndefined } from '../../../shared/utility';

const InfoPopup = (props) => {
	const { heading, title, children, open } = props;

	return (
		<StyledTooltip
			title={
				<>
					{heading ?
						<h4>{heading}</h4>
					:	null}
					{title}
				</>
			}
			{...(!isUndefined(open) && { open })}
		>
			{children}
		</StyledTooltip>
	);
};

InfoPopup.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	heading: PropTypes.string,
	open: PropTypes.bool,
};

export default InfoPopup;
