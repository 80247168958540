import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../../../style';

const UserComment = (props) => {
	const { bookingData } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<CardHeader title={t('views.bookingDetail.summary.cardHeaders.UserComment')} />
			<Divider />
			<CardContent className={classes.cardConten}>
				<Box className={classes.commentContainer}>
					{bookingData.comment ?
						<Typography variant='body2'>{bookingData.comment}</Typography>
					:	<Typography className={classes.emptyComment} variant='body2'>
							{t('views.bookingDetail.summary.userComment.empty')}
						</Typography>
					}
				</Box>
			</CardContent>
		</Card>
	);
};

UserComment.propTypes = {
	bookingData: PropTypes.object.isRequired,
};

export default UserComment;
