import PropTypes from 'prop-types';

import AboutPolicy from './AboutPolicy';
import Header from './Header';
import { useStyles } from './style';

const PolicyDetails = (props) => {
	const { save, name, selectedOrganisation, editing, currentPolicyName } = props;

	const classes = useStyles();

	const aboutPolicyProps = { name, selectedOrganisation, currentPolicyName };

	return (
		<>
			<Header editing={editing} />
			<AboutPolicy
				className={classes.aboutPolicy}
				editing={editing}
				save={save}
				{...aboutPolicyProps}
			/>
		</>
	);
};

PolicyDetails.propTypes = {
	save: PropTypes.func.isRequired,
	name: PropTypes.string,
	selectedOrganisation: PropTypes.object,
	editing: PropTypes.bool,
	currentPolicyName: PropTypes.string,
};

export default PolicyDetails;
