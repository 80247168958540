import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { MoreButton } from '../../..';

const DownloadButton = (props) => {
	const { downloadData } = props;

	const classes = useStyles();

	return (
		<div className={clsx(classes.root)}>
			<MoreButton
				disabled={downloadData.disabled}
				menuItems={downloadData.action()}
				startIcon={<InsertDriveFileOutlinedIcon />}
				text={downloadData.text}
			/>
		</div>
	);
};

DownloadButton.propTypes = {
	downloadData: PropTypes.object,
};

export default DownloadButton;
