import { atom } from 'jotai';
import { RESET, atomWithDefault, atomWithRefresh, atomWithReset, loadable } from 'jotai/utils';

import { User, UsersService } from '~features/users';

const service = new UsersService();

/**
 * An atom for the user information. On init this value is retrieved from
 * the user. After the initial call, this atom is the source of truth.
 * TODO: Do we need the following additions?
 * - localStorage: this is done currently for the currentUser, but what is
 * 	 the added value? We would only need this if we were maybe offline. But
 *   it just adds a additional 'caching' layer
 * - sync with the server: see atomWithReset
 * TODO: this triggers React.suspense. We want that for the inital fetch,
 * but not after that.
 * https://github.com/pmndrs/jotai/discussions/1102
 * https://github.com/pmndrs/jotai/discussions/1460
 */
const userInfoAtom = atomWithRefresh<Promise<User> | User>(async (get) => {
	return await service.getUserInformation();
});

const loadableUserInfoAtom = loadable(userInfoAtom);

export { loadableUserInfoAtom };

export default userInfoAtom;
