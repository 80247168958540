import { useState } from 'react';

import { FormControl, InputLabel, MenuItem, Select as MuiSelect, Stack, TextField } from '@mui/material';
import Ajv from 'ajv';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { FormContainer, FormContainerProps, Select } from '~components';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { useDebounceCallback } from '~hooks';
import { BaseReference } from '~interfaces';

import schema from './createNfcTagSchema.json';
import TagTypeEnum from '../../enums/tagTypeEnum';

const ajv = new Ajv();
const validate = ajv.compile(schema);

const tagTypeSelectId = 'tag-type-select-label';

const hexRegex = new RegExp(/^[0-9a-f]*$/i);

console.log(hexRegex.test(''), hexRegex.test('a'));

interface CreateNfcTagFormData {
	organisation: BaseReference;
	tagType: TagTypeEnum;
	tagNumber: string;
	id: string;
}

interface CreateNfcTagFormProps extends Omit<FormContainerProps, 'onSubmit'> {
	onSubmit?: (value: CreateNfcTagFormData) => void;
}

const CreateNfcTagForm = ({ onSubmit, onClose, ...formContainerProps }: CreateNfcTagFormProps) => {
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const [valid, setValid] = useState(false);
	const [value, setValue] = useState<Partial<CreateNfcTagFormData>>({
		organisation: isSuperAdmin() ? undefined : userInfo.organisation
	});

	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const handleCloseClick = () => {
		setValue({});
		if (onClose) {
			onClose();
		}
	};

	const handleNfcIdChange = (newNfcId: string) => {
		console.log(newNfcId, hexRegex.test(newNfcId));
		if (hexRegex.test(newNfcId)) {
			setErrorMessage(null);
		} else {
			setErrorMessage(`${t('ui.label.contract.status.invalid')} ${t('views.usersAndOrganisations.nfcCards.nfcId')}`)
		}
		handleChange({ ...value, id: newNfcId });
	};

	const handleChange = (newValue: Partial<CreateNfcTagFormData>) => {
		setValid(validate(newValue));
		setValue(newValue);
		console.debug(newValue, validate.errors);
	};

	return (
		<FormContainer
			{...formContainerProps}
			submitDisabled={!valid}
			onClose={handleCloseClick}
			onSubmit={() => onSubmit && onSubmit(value as CreateNfcTagFormData)}
		>
			{isSuperAdmin() &&
				<OrganisationAutocomplete
					required
					value={value.organisation ?? null}
					onChange={(_, newValue) => handleChange({ ...value, organisation: newValue })}
				/>
			}
			<Select
				required
				labelId='tag-type-select-label2'
				label={t('views.usersAndOrganisations.nfcCards.tagType')}
				defaultValue=''
				value={value.tagType ?? ''}
				onChange={(e) => handleChange({ ...value, tagType: e.target.value as TagTypeEnum })}
			>
				{Object.values(TagTypeEnum).map((el: TagTypeEnum) => (
					<MenuItem key={el} value={el}>
						{el === 'skopei' ? 'Skopei' : t('ui.label.external')}
					</MenuItem>
				))}
			</Select>
			<TextField
				required
				label={t('views.userDetails.summary.nfcCardNumber')}
				placeholder='SK123123'
				value={value.tagNumber ?? ''}
				onChange={(e) => handleChange({ ...value, tagNumber: e.target.value })}
				// placeholder={value.tagNumber === TagTypeEnum.Skopei ? 'SK123123': ''}
			/>
			<TextField
				required
				label={t('rfidUid')}
				placeholder='12AB56CD'
				value={value.id?.toUpperCase() ?? ''}
				onChange={e => handleNfcIdChange(e.target.value)}
				error={errorMessage != null}
				helperText={errorMessage}
				// onChange={(e) => handleChange({ ...value, id: e.target.value })}
				inputProps={{
					maxLength: schema.properties.id.maxLength,
				}}
			/>
		</FormContainer>
	);
};

export default CreateNfcTagForm;
