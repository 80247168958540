import { useState } from 'react';

import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Divider,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Box,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useStyles } from './style';
import { useSelectData } from '../../../shared/hooks';
import { isArray, isFullString, isObject } from '../../../shared/utility';
import { TableEditBar } from '../../elements';
import { LoadingBar } from '../../loading';

const CustomTable = (props) => {
	const {
		cellStyle,
		className,
		title,
		total,
		header,
		body,
		data,
		handleSorting,
		orderBy,
		order,
		page,
		handlePageChange,
		rowsPerPage,
		setRowsPerPage,
		hasSelectionEnabled,
		editActionButtons,

		timePicker,
		isNotPaginate,
		titleWithInformation,
		noTitle,
		headerLabel,
		checkboxAligning,
		loading,
		removeMrgin,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [selected, setSelected] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);

	const selectData = useSelectData(
		selected,
		setSelected,
		dataSelected,
		setDataSelected,
		data,
		loading,
	);

	const handleChangeRowsPerPage = (event) => setRowsPerPage(event.target.value);

	const onPageChange = (e, page) => handlePageChange(page + 1);

	const handleLabelDisplayedRows = ({ from, to, count }) =>
		`${from}-${to} ${t('ui.of')} ${count !== -1 ? count : '0'}`;

	const onSorting = (cellName) => () => handleSorting(cellName);

	const handleSelect = (rIndex, event) => () => selectData.handleSelectOne(event, rIndex);

	const actions = {
		changeRows: handleChangeRowsPerPage,
		changePage: onPageChange,
		displayedRows: handleLabelDisplayedRows,
		sorting: onSorting,
		handleSelect: handleSelect,
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Card className={removeMrgin ? classes.mainCardMrgin : classes.mainCard}>
				{noTitle ?
					null
				: isFullString(title) ?
					<CardHeader title={title} />
				: timePicker ?
					<Box className={classes.timePicker} display='flex'>
						{timePicker}
					</Box>
				: isObject(titleWithInformation) ?
					<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
						<Typography variant='h5'>{titleWithInformation.header}</Typography>
						{titleWithInformation.sideHeader ?
							<Typography variant='h6'>{`${isFullString(headerLabel) ? headerLabel : t('ui.label.total')}: ${titleWithInformation.sideHeader}`}</Typography>
						:	''}
					</Box>
				:	<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
						<LoadingBar />
					</Box>
				}

				{noTitle ? null : <Divider />}
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow hover={false}>
										{checkboxAligning ?
											<TableCell className={classes.cellSpacing}></TableCell>
										:	null}
										{hasSelectionEnabled ?
											<TableCell className={classes.cellSpacing} padding='checkbox'>
												<Checkbox
													checked={selected.length === body.length}
													color='primary'
													indeterminate={selected.length > 0 && selected.length < body.length}
													onChange={selectData.handleSelectAll}
												/>
											</TableCell>
										:	null}
										{header.map((cell) => (
											<TableCell key={cell.name}>
												{cell.hasSorting ?
													<TableSortLabel
														active={orderBy === cell.name}
														direction={order === 'asc' ? 'desc' : 'asc'}
														onClick={actions.sorting(cell.name)}
													>
														{cell.content}
													</TableSortLabel>
												:	cell.content}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{isArray(body) &&
										body?.map((row, rIndex) => (
											<TableRow
												hover={false}
												key={rIndex}
												selected={selected.indexOf(rIndex) !== -1}
											>
												{checkboxAligning ?
													<TableCell className={classes.cellSpacing}></TableCell>
												:	null}
												{hasSelectionEnabled ?
													<TableCell className={classes.cellSpacing} padding='checkbox'>
														<Checkbox
															checked={selected.indexOf(rIndex) !== -1}
															color='primary'
															onChange={actions.handleSelect(rIndex)}
															value={selected.indexOf(rIndex) !== -1}
														/>
													</TableCell>
												:	null}
												{row.map((cell, cIndex) => (
													<TableCell
														className={
															(cIndex === 0 || cell.classCell) && !isFullString(cell.classCell) ?
																null
															: isFullString(cellStyle) ?
																cellStyle
															:	cell.classCell
														}
														key={cIndex}
													>
														{cell.loading ?
															<LoadingBar />
														: cell.content ?
															cell.content
														:	<p>-</p>}
													</TableCell>
												))}
											</TableRow>
										))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
				{isNotPaginate ? null : (
					<CardActions className={classes.actions}>
						<TablePagination
							component='div'
							count={total}
							labelDisplayedRows={actions.displayedRows}
							labelRowsPerPage={t('ui.rowsPerPage')}
							onPageChange={actions.changePage}
							onRowsPerPageChange={actions.changeRows}
							page={page - 1}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={[5, 10, 20, 40, 50]}
						/>
					</CardActions>
				)}
				<TableEditBar actionButtons={editActionButtons} selected={dataSelected} />
			</Card>
		</div>
	);
};

CustomTable.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	total: PropTypes.number,
	timePicker: PropTypes.object,
	loading: PropTypes.bool,
	isNotPaginate: PropTypes.bool,
	handleSorting: PropTypes.func,
	handlePageChange: PropTypes.func,
	orderBy: PropTypes.string,
	order: PropTypes.string,
	header: PropTypes.array,
	body: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	data: PropTypes.array,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	setRowsPerPage: PropTypes.func,
	hasSelectionEnabled: PropTypes.bool,
	editActionButtons: PropTypes.array,
	titleWithInformation: PropTypes.object,
	noTitle: PropTypes.bool,
	cellStyle: PropTypes.string,
	headerLabel: PropTypes.string,
	checkboxAligning: PropTypes.bool,
	removeMrgin: PropTypes.bool,
};

export default CustomTable;
