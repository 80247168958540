import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

const TermInformation = (props) => {
	const { termData, className } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.termDetails.termInformation.header')} />
			<Divider />
			<CardContent>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.title')}</TableCell>
							<TableCell>{termData.title}</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell style={{ verticalAlign: 'top' }}>{t('ui.organisation')}</TableCell>
							<TableCell className={classes.editorContainer}>
								{termData.organisationReference.name}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

TermInformation.propTypes = {
	className: PropTypes.string,
	termData: PropTypes.object.isRequired,
};

export default TermInformation;
