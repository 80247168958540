import blobReducer from './blob';
import conditionReducer from './condition';
import detailsReducer from './details';
import filtersReducer from './filters';
import globalReducer from './global';
import listReducer from './list';
import pagedReducer from './paged';
import planboardReducer from './planboard';

// combine Redux reducers into one reducer to access Redux states accross the project
const rootReducer = {
	list: listReducer,
	paged: pagedReducer,
	filters: filtersReducer,
	global: globalReducer,
	details: detailsReducer,
	condition: conditionReducer,
	planboard: planboardReducer,
	blob: blobReducer,
};

export default rootReducer;
