export const contractTypes = {
	terminationRequested: 'error',
	expired: 'error',
	rejected: 'error',
	invalid: 'error',
	pendingAdmin: 'warning',
	pendingUser: 'warning',
	pending: 'warning',
	active: 'success',
	valid: 'success',
	terminated: 'disabled',
	signed: 'default',
};

export const contractStatus = [
	// 'none',
	'pendingAdmin',
	'pendingUser',
	'signed',
	'rejected',
	'active',
	'valid',
	'expired',
	'terminated',
	// 'invalid',
	// 'terminationRequested',
	// 'pending'
];
