import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		height: '100%',
	},
	content: {
		padding: 12,
	},
	mapLink: {
		...theme.link.body3,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	bottomCell: {
		borderBottom: 'none',
	},
	button: {
		whiteSpace: 'nowrap',
	},
	cardUnlockCode: {
		height: '69px',
	},
}));
