import React, { Fragment, useEffect, useState } from 'react';

import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	TablePagination,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * Maybe not handy naming because of mui list item?
 */
interface TransferListItemModel {
	key: string;
	primary?: string;
	secondary?: string;
	/**
	 * Hide the list item
	 */
	hidden?: boolean;
	/**
	 * Additional icon or element for
	 */
	icon?: JSX.Element;
	/**
	 * Disabled for transfer
	 */
	disabled?: boolean;
}

interface SingleTransferListProps {
	title?: string;
	items: TransferListItemModel[];
	loading?: boolean;
	itemsCount?: number;
	onTransfer?: (value: {
		remained: TransferListItemModel[];
		transferred: TransferListItemModel[];
	}) => void;
	transferIcon?: JSX.Element;
	page?: number;
	onPageChange?: (value: number) => void;
	rowsPerPage?: number;
	pagination?: 'server' | 'client';
}

const SingleTransferList = ({
	title,
	loading = false,
	items,
	onTransfer,
	transferIcon: selectionIcon,
	itemsCount,
	page = 1,
	onPageChange,
	rowsPerPage = 10,
	pagination = 'client',
}: SingleTransferListProps) => {
	const { t } = useTranslation('general');

	const listOffset = pagination === 'server' ? 0 : (page - 1) * rowsPerPage;

	useEffect(() => {
		if (!onPageChange) {
			return;
		}

		const maxPage = Math.floor(items.length / rowsPerPage);
		if (maxPage + 1 < page) {
			onPageChange(maxPage + 1);
		}
	}, [rowsPerPage]);

	const handlePageChange = (page: number) => {
		if (onPageChange) {
			onPageChange(page);
		}
	};

	/**
	 *
	 * @param key
	 */
	const handleTransferClick = (key: string) => {
		const transferItems = items.filter((el) => el.key === key);
		const remainItems = items.filter((el) => el.key !== key);

		onTransfer != null &&
			onTransfer({
				remained: remainItems,
				transferred: transferItems,
			});
	};

	/**
	 * Handle the click when transfering items
	 * @param {boolean} preselectAll Before transfering, select all items
	 */
	const handleTransferAllClick = () => {
		const remainItems: TransferListItemModel[] = [];
		const transferItems: TransferListItemModel[] = items;

		onTransfer != null &&
			onTransfer({
				remained: remainItems,
				transferred: transferItems,
			});
	};

	return (
		<Card sx={{ display: 'flex', flexDirection: 'column', width: 1, height: 1 }}>
			<CardHeader
				avatar={
					<IconButton disabled={items.length <= 0} onClick={handleTransferAllClick}>
						{selectionIcon}
					</IconButton>
				}
				title={
					<Box alignItems='center' display='flex' flexDirection='row'>
						<Typography variant='h5'>{title}</Typography>
					</Box>
				}
			/>
			<Divider />
			<CardContent
				sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 0, position: 'relative' }}
			>
				{loading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
				<List>
					{items.length > 0 ?
						items.slice(listOffset, listOffset + rowsPerPage).map((option) => (
							<React.Fragment key={option.key}>
								<ListItem sx={{ minHeight: '67px' }}>
									<ListItemIcon sx={{ pr: '5px' }}>
										<IconButton
											onClick={() => handleTransferClick(option.key)}
											disabled={option.disabled}
										>
											{selectionIcon}
										</IconButton>
									</ListItemIcon>
									<ListItemText primary={option.primary} secondary={option.secondary} />
									{option.icon != null && option.icon}
								</ListItem>
								<Divider />
							</React.Fragment>
						))
					:	<Box
							width={1}
							display='flex'
							justifyContent='center'
							alignItems='center'
							minHeight={200}
						>
							<Typography>{t('noResourceFound', { resource: title?.toLowerCase() })}</Typography>
						</Box>
					}
				</List>
				<Box flexGrow={1} />
				<Box display='flex' justifyContent='flex-end'>
					<TablePagination
						count={itemsCount ?? items.length}
						onPageChange={(e, newPage) => handlePageChange(newPage + 1)}
						page={page - 1}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={[]}
						sx={{ border: 0 }}
					/>
				</Box>
			</CardContent>
		</Card>
	);
};

export type { TransferListItemModel };
export default SingleTransferList;
