import { forwardRef } from 'react';

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

// eslint-disable-next-line no-unused-vars
const CustomNumber = forwardRef((props, ref) => {
	const { inputRef, onChange, thousandSeparator, decimalSeparators = [], ...other } = props;

	const handleChange = (values) => onChange({ target: { name: props.name, value: values.value } });

	return (
		<NumericFormat
			{...other}
			allowNegative={false}
			allowedDecimalSeparators={decimalSeparators}
			decimalScale={2}
			getInputRef={inputRef}
			isNumericString
			onValueChange={handleChange}
			ref={inputRef}
			thousandSeparator={thousandSeparator}
		/>
	);
});

CustomNumber.propTypes = {
	inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	decimalSeparators: PropTypes.array,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	thousandSeparator: PropTypes.bool,
};

export default CustomNumber;
