import { useState, useEffect, useRef } from 'react';

// Credits: https://github.com/vercel/swr/issues/110
/**
 * Debounce a value. E.g. to prevent unnecessary calls
 * @param value
 * @param delay Set the debounce delay.
 * @returns
 */
const useDebounce = <T>(value: T, delay = 350): T => {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);
	const timeout = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		timeout.current = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(timeout.current);
		};
	}, [value, delay]);

	return debouncedValue;
};

export default useDebounce;
