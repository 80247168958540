import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTableHead-root': {
			backgroundColor: '#fff',
		},
	},
	link: {
		fontWeight: theme.link.body2.fontWeight,
		fontSize: theme.link.body2.fontSize,
		letterSpacing: theme.link.body2.letterSpacing,
		lineHeight: theme.link.body2.lineHeight,
	},
	cell: {
		width: '250px',
	},
	cellStyle: {
		width: '9.25rem',
	},
}));
