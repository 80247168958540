import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles, withStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	panelSpacing: {
		marginRight: theme.spacing(1),
	},
	bottomPanel: {
		marginTop: theme.spacing(3),
		width: '320px',
	},
	imageItem: {
		maxWidth: '88px',
		height: '50',
		left: 0,
		objectFit: 'scale-down',
	},
	topPanel: {
		// maxHeight: '320px',
		// height: '200px',
		width: '320px',
	},
	panelName: {
		wordWrap: 'break-word',
		maxWidth: '100px',
	},
	summaryDetails: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	summaryDetailsSpacing: {
		marginRight: theme.spacing(5),
	},
	expansionDetails: {
		margin: '0',
		padding: '0',
	},

	panelText: {
		display: 'flex',
		width: '200px',
		margin: '0',
	},
	expansion: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	media: {
		height: 80,
		width: 80,
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		objectFit: 'contain',
	},
	showAllButton: {
		margin: theme.spacing(2, 0),
	},
}));

export const AccordionSummary = withStyles({
	root: {
		padding: '0',
		backgroundColor: 'rgba(255, 255, 255, .03)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

export const Accordion = withStyles({
	root: {
		borderBottom: '1px dotted #e4e7eb',
		boxShadow: 'none',
		'&:last-child': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);
