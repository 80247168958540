import path from 'path';

import { TopologyService } from '~services';

class OrganisationVerificationService extends TopologyService {
	public readonly path = 'organisations';

	constructor() {
		super('v1');
	}

	/**
	 * Check the verification status of the user.
	 * Probably based on the info in the access token
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getOrganisationVerificationStatus(): Promise<OrganisationVerificationResponse | null> {
		const { data } = await this._client.get<OrganisationVerificationResponse | null>(
			path.join(this.path, 'me/status'),
		);

		return data;
	}
}

interface OrganisationVerificationResponse {
	verified: boolean;
}

export default OrganisationVerificationService;
