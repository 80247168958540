import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		padding: theme.spacing(2, 1, 0),
		marginBottom: 'auto',
	},
	tableRow: {
		'&.Mui-selected': {
			backgroundColor: '#f5f6f8',
			'&:hover': {
				backgroundColor: '#f5f6f8',
			},
		},
	},
	subscriptionSelect: {
		marginBottom: theme.spacing(1),
	},
	subscriptionCard: {
		marginBottom: theme.spacing(1),
	},
}));
