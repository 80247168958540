import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(4, 2),
		maxWidth: '60rem',
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	formGroup: {
		'& + &': {
			marginTop: theme.spacing(3),
		},
		'&:last-child': {
			marginBottom: theme.spacing(3),
		},
	},
	switchLabel: {
		width: '100%',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		margin: 0,
	},
	cardHeader: {
		padding: theme.spacing(2, 1.5),
	},
}));
