import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style.js';
import Bar from '../../../../../../components/loading/Bar';

const SelectedHubCard = (props) => {
	const { hubData, hasCloseButton, loading, handleClose } = props;

	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent className={classes.content}>
				<Typography variant='h5'>
					{loading ?
						<Bar />
					:	hubData.name}
				</Typography>
			</CardContent>
			{hasCloseButton ?
				<IconButton className={classes.closeButton} onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			:	null}
		</Card>
	);
};

SelectedHubCard.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	loading: PropTypes.bool,
	handleClose: PropTypes.func,
	hasCloseButton: PropTypes.bool,
	hubData: PropTypes.object,
};

export default SelectedHubCard;
