import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { isObject } from '../../../../../shared/utility';
import { itemSharings } from '../../../../../store/actions';

function usePoliciesChange(data) {
	const [openDialog, setOpenDialog] = useState(false);
	const [initialValue, setInitialValue] = useState(data);

	const cancelDialog = () => {
		setOpenDialog(false);
		setInitialValue(data);
		change();
	};

	useEffect(() => {
		if (data.id !== initialValue.id) {
			setOpenDialog(true);
		}
	}, [initialValue]);

	const change = (selected) => {
		if (isObject(selected)) {
			const id = selected?.partnerOrganisation ? selected.partnerOrganisation.id : selected.id;
			const name =
				selected?.name ? selected.name
				: selected?.partnerOrganisation ? selected?.partnerOrganisation.name
				: selected.title;
			setInitialValue(() => ({ id: id, name: name }));
		}
	};

	return {
		openDialog,
		setOpenDialog,
		cancelDialog,
		initialValue,
		setInitialValue,
		change,
	};
}

function useItemSharings(data) {
	const { fetch, type, id } = data;
	const initial = { equal: 0, total: 0 };
	const [initialValue, setInitialValue] = useState(initial);

	const dispatch = useDispatch();

	const itemSharingsData = useSelector((state) => state.details.itemSharings);

	useEffect(() => {
		if (fetch && type && !itemSharingsData.loading) {
			dispatch(itemSharings(id, type));
		}
	}, [fetch]);

	useEffect(() => {
		if (itemSharingsData?.data) {
			setInitialValue(itemSharingsData?.data);
		}
	}, [itemSharingsData]);

	return initialValue;
}

export { usePoliciesChange, useItemSharings };
