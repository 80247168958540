import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	sideCard: {
		flexBasis: '25%',
		'& .MuiCardHeader-root': {
			padding: '0',
		},
		'& .MuiCardContent-root': {
			padding: theme.spacing(4, 5, 5, 5),
		},
	},
}));
