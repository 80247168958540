import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { isFullString } from '../../../shared/utility';

const OpenEmail = (props) => {
	const { email, subject, body } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();
	const providedValue = `?subject=${encodeURIComponent(subject) || ''}`;
	const notProvided = `?subject=${encodeURIComponent(t('components.elements.openEmail.subject')) || ''}`;
	const providedBody = `&body=${encodeURIComponent(body) || ''}`;
	const notProvidedBody = `&body=${encodeURIComponent(t('components.elements.openEmail.body')) || ''}`;
	return (
		<a
			className={classes.styledLink}
			href={`mailto:${email}${isFullString(subject) ? providedValue : notProvided}${isFullString(body) ? providedBody : notProvidedBody}`}
		>
			{email}
		</a>
	);
};

OpenEmail.propTypes = {
	email: PropTypes.string,
	subject: PropTypes.string,
	body: PropTypes.string,
};

export default OpenEmail;
