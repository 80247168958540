import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(4, 2),
		maxWidth: '60rem',
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	formGroup: {
		'& + &': {
			marginTop: theme.spacing(3),
		},
		'&:last-child': {
			marginBottom: theme.spacing(3),
		},
	},
	numberInput: {
		width: 200,
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(2),
	},
	inputWithExtraLabel: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
	},
	switchLabel: {
		width: '100%',
		justifyContent: 'space-between',
		margin: 0,
	},
}));
