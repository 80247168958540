import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	detailTitle: {
		textDecoration: 'underline',
	},
	btnLink: {
		borderRadius: '14px',
		minWidth: '119.5px',
	},
	cellStyle: {
		whiteSpace: 'nowrap',
	},
}));
