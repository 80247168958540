import { useEffect } from 'react';

import { ButtonProps } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';

import pageButtonsAtom from '../atoms/pageButtonsAtom';

interface PageButtonsProps {
	value: ButtonProps[];
	set: (value: ButtonProps[]) => void;
}

/**
 * A hook to set the buttons defined in the header of
 * the page layout.
 * Why use this hook instead of using the atom directly? We want
 * the buttons to be reset if a user leaves the pages the button
 * are set. By using this hook we have control and we can make sure
 * that the buttons are reset whenever the component unmounts.
 * Tried to do this using context of React. But the state wasn't
 * properly updated
 */
const usePageButtons = (): PageButtonsProps => {
	const [pageButtons, setPageButtons] = useAtom(pageButtonsAtom);

	useEffect(() => {
		return () => {
			setPageButtons([]);
		};
	}, []);

	return {
		value: pageButtons,
		set: setPageButtons,
	};
};

export default usePageButtons;
