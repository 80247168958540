import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	formGroup: {
		'& + &': {
			marginTop: theme.spacing(4),
		},
		// '&:last-child': {
		//   marginBottom: theme.spacing(3)
		// }
	},
	fieldGroup: {
		display: 'flex',
		alignItems: 'flex-start',
	},
	fieldGroupItem: {
		marginLeft: theme.spacing(3),
	},
	logoLabel: {
		marginBottom: theme.spacing(1),
	},
	counterText: {
		color: theme.palette.grey[400],
	},
	imageLabel: {
		marginBottom: theme.spacing(1.5),
	},
}));
