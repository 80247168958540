import { PropsWithChildren } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Divider, Skeleton, Stack, StackProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from '~lib/i18n';

interface FormContainerProps extends StackProps {
	/**
	 * Set a variant for the layout of the close and save buttons
	 */
	buttonVariant?: 'rightBottom' | 'centerBottom'
	/**
	 * A boolean to set a loading overlay. E.g. for loading
	 * initial data
	 */
	loading?: boolean;
	/**
	 * The label to use for the close button
	 */
	closeLabel?: string;
	onClose?: () => void;
	/**
	 * The label to use for the submit button
	 */
	submitLabel?: string;
	/**
	 * Disable the submit button. E.g. in combination with a validation
	 * process to determine if the form is valid and be submitted
	 */
	submitDisabled?: boolean;
	/**
	 * A boolean to set the loading state of the submit proces
	 */
	processing?: boolean;
	onSubmit?: <T>(value: T) => void;
}

const FormContainer = ({
	buttonVariant = 'rightBottom',
	loading,
	closeLabel = i18n.t('ui.close'),
	onClose,
	submitLabel = i18n.t('ui.add'),
	submitDisabled = false,
	onSubmit,
	processing = false,
	children,
	spacing = 2,
	width = 1,
	...stackProps
}: FormContainerProps) => {
	const { t } = useTranslation('general');

	return (
		<Stack
			{...stackProps}
			spacing={spacing}
			width={width}
			divider={buttonVariant === 'centerBottom' ? <Divider /> : undefined}
			// height={1}
			display='flex'
			flexDirection='column'
		>
			<Stack spacing={3} flexGrow={1} p={1} sx={{ overflowY: 'auto' }}>
				{loading ? <LoadingOverlay /> : children}
			</Stack>
			<Stack
				direction='row'
				spacing={1}
				{...buttonVariant === 'centerBottom' ? {
					alignSelf: 'center',
					justifyContent: 'space-evenly',
					maxWidth: 600,
					width: 1
				} : {
					justifyContent: 'flex-end'
				}}
			>
				<Button
					onClick={onClose}
					{...buttonVariant === 'centerBottom' ? {
						fullWidth: true,
						size: 'large',
						variant: 'text'
					} : {
						variant: 'outlined'
					}}
				>
					{closeLabel}
				</Button>
				<LoadingButton
					variant='contained'
					loading={processing}
					disabled={submitDisabled || loading}
					onClick={onSubmit}
					{...buttonVariant === 'centerBottom' ? {
						color: 'primary',
						fullWidth: true
					} : {
						color: 'secondary'
					}}
				>
					{submitLabel}
				</LoadingButton>
			</Stack>
		</Stack>
	);
};

const LoadingOverlay = () => (
	<Stack spacing={2} height={1}>
		<Skeleton animation='wave' variant='rounded' height={30} />
		<Skeleton animation='wave' variant='rounded' height={30} />
	</Stack>
);

export type { FormContainerProps };
export default FormContainer;
