import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { useStyles } from './style';

const Paginate = (props) => {
	const { pageCount, pageRangeDisplayed, onPageChange, className, ...rest } = props;

	const classes = useStyles();

	return (
		<ReactPaginate
			activeClassName={classes.active}
			activeLinkClassName={classes.activeLink}
			breakClassName={classes.break}
			breakLabel='...'
			breakLinkClassName={classes.breakLink}
			containerClassName={clsx(classes.root, className)}
			disabledClassName={classes.disabled}
			marginPagesDisplayed={2}
			nextClassName={classes.next}
			nextLabel='next'
			nextLinkClassName={classes.nextLink}
			onPageChange={onPageChange}
			pageClassName={classes.page}
			pageCount={pageCount}
			pageLinkClassName={classes.pageLink}
			pageRangeDisplayed={pageRangeDisplayed}
			previousClassName={classes.previous}
			previousLabel='previous'
			previousLinkClassName={classes.previousLink}
			subContainerClassName='pages pagination'
			renderOnZeroPageCount={null}
			{...rest}
		/>
	);
};

Paginate.propTypes = {
	className: PropTypes.string,
	onPageChange: PropTypes.func.isRequired,
	pageCount: PropTypes.number.isRequired,
	pageRangeDisplayed: PropTypes.number.isRequired,
};

Paginate.defaultProps = {
	onPageChange: () => {},
	pageRangeDisplayed: 5,
};

export default Paginate;
