import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Card, CardContent, Typography, IconButton } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style.js';
import { getInitials } from '../../../shared/utility';
import Bar from '../../loading/Bar/Bar.js';

const SelectedUserCard = (props) => {
	const { className, user, loading, handleClose, hasCloseButton } = props;
	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent className={classes.content}>
				<Box className={classes.avatarContainer}>
					{user.imagesReference && user.imagesReference[0] ?
						<Avatar src={user.imagesReference[0]} />
					:	<Avatar>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>}
				</Box>
				<Box>
					<Typography variant='h5'>
						{loading ?
							<Bar />
						:	`${user.firstName} ${user.lastName}`}
					</Typography>
					<Typography className={classes.body3}>
						{loading ?
							<Bar />
						:	user.emailAddress}
					</Typography>
				</Box>
			</CardContent>
			{hasCloseButton ?
				<IconButton className={classes.closeButton} onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			:	null}
		</Card>
	);
};

SelectedUserCard.propTypes = {
	className: PropTypes.string,
	user: PropTypes.object,
	loading: PropTypes.bool,
	handleClose: PropTypes.func,
	hasCloseButton: PropTypes.bool,
};

export default SelectedUserCard;
