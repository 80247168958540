import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	styledLink: {
		'&:link': {
			color: theme.palette.primary.main,
		},
		'&:visited': {
			color: theme.palette.primary.main,
		},
		'&:hover': {
			color: theme.palette.primary.main,
			textDecoration: 'underline',
		},
		'&:active': {
			color: theme.palette.primary.main,
		},
		...theme.typography.link1,
	},
}));
