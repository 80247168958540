import React, { useEffect, useRef, useState } from 'react';

import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import {
	Divider,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	TextFieldProps,
} from '@mui/material';

import { useDebounce, useDebounceCallback } from '~hooks';
import i18n from '~lib/i18n';

type AsyncTextFieldProps = {
	/**
	 * A handler to get the direct input from the textfield
	 * Value disabled, use defaultvalue
	 */
	onInputChange?: () => void;
	/**
	 * The debounce delay
	 */
	delay?: number;
	/**
	 * Search
	 */
	manual?: boolean;
} & Omit<TextFieldProps, 'value'>;

/**
 * A textfield for the purpose of async searching
 * @returns
 */
const AsyncTextField = ({
	placeholder = i18n.t('search'),
	onChange,
	onInputChange,
	defaultValue = '',
	delay,
	manual = false,
	...textFieldProps
}: AsyncTextFieldProps) => {
	// Save a reference to the event so we can return through custom handle functions
	// Is this appropiate?
	const eventRef = useRef<React.ChangeEvent<HTMLInputElement>>(null!);

	const [inputValue, setInputValue] = useState(defaultValue);
	const debounceCallback = useDebounceCallback();

	const handleClearClick = () => {
		setInputValue('');
		eventRef.current.target.value = '';

		if (!onChange) {
			return;
		}

		if (!manual) {
			debounceCallback(() => onChange(eventRef.current));
		} else {
			onChange(eventRef.current);
		}
	};

	const handleSearchClick = () => {
		if (onChange) {
			onChange(eventRef.current);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		eventRef.current = e;
		setInputValue(e.target.value);

		if (onChange && !manual) {
			debounceCallback(() => onChange(e));
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && onChange && manual) {
			onChange(eventRef.current);
		}
	};

	return (
		<TextField
			{...textFieldProps}
			placeholder={placeholder}
			value={inputValue}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			InputProps={{
				...textFieldProps.InputProps,
				...(textFieldProps.size === 'small' && {
					sx: {
						height: 44,
					},
				}),
				endAdornment: (
					<InputAdornment position='end'>
						<Stack
							direction='row'
							spacing={0.5}
							divider={<Divider orientation='vertical' flexItem />}
						>
							{inputValue !== '' && (
								<IconButton size='small' onClick={handleClearClick}>
									<CloseIcon fontSize='small' />
								</IconButton>
							)}
							{manual && (
								<IconButton size='small' onClick={handleSearchClick}>
									<SearchIcon fontSize='small' />
								</IconButton>
							)}
						</Stack>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default AsyncTextField;
