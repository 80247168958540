import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Link as MuiLink, Portal, Stack } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
	GridToolbarContainer,
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { userInfoAtom } from '~atoms';
import { DataGrid, DataGridToolbar, GridActionsCellItem } from '~components';
import selectedOrganisationAtom from '~features/access/atoms/selectedOrganisationAtom';
import { useAuthorize } from '~features/authentication';
import { BaseReference } from '~interfaces';

import SkcAccessRule from '../../interfaces/skcAccessRule';
import SkcAccessRulesService from '../../services/skcAccessRulesService';

const toolbarIdString = 'topology-access-rules-datagrid';

const service = new SkcAccessRulesService();

interface RefProps {
	mutate: () => void;
}

interface AccessRulesDataGridProps {
	enableCreate?: boolean;
	enableEdit?: boolean;
	enableDelete?: boolean;
	onEdit?: (value: BaseReference) => void;
	onDelete?: (value: BaseReference) => void;
	onCreate?: () => void;
}

const AccessRulesDataGrid = forwardRef<RefProps, AccessRulesDataGridProps>(
	(
		{ enableCreate = true, enableEdit = true, enableDelete = true, onEdit, onDelete, onCreate },
		ref,
	) => {
		const { t } = useTranslation('general');
		const { isSuperAdmin } = useAuthorize();
		const location = useLocation();
		const [userInfo] = useAtom(userInfoAtom);
		const [selectedOrganisation, setSelectedOrganisation] = useAtom(selectedOrganisationAtom);

		const [filterModel, setFilterModel] = useState<GridFilterModel>({
			items: [{
				field: 'organisation',
				operator: 'contains',
				value: selectedOrganisation
			}]
		});

		const [fetchParameters, setFetchParameters] = useState<object | null>(null);

		useEffect(() => {
			if (selectedOrganisation) {
				setFetchParameters(prev => ({
					...prev,
					organisationId: selectedOrganisation.id
				}));
			}
		}, [selectedOrganisation]);

		const columns: GridColDef<SkcAccessRule>[] = useMemo(
			() => [
				{
					field: 'id',
					headerName: 'ID',
					width: 80,
					hideable: false,
					renderCell: (params) =>
						import.meta.env.DEV ?
							<MuiLink
								component={ReactRouterLink}
								to={params.value.toString()}
								state={{ from: location.pathname }}
							>
								{params.value}
							</MuiLink>
						:	params.value,
				},
				{
					field: 'label',
					headerName: t('ui.label.name'),
					flex: 1,
				},
				{
					field: 'description',
					headerName: t('ui.label.description'),
					valueFormatter: (value: string) => value ?? '-',
					flex: 1,
				},
				{
					field: 'startDate',
					headerName: t('ui.label.startDate'),
					valueGetter: (_, row) => row.period.start,
					valueFormatter: (value: Date | null) => (value ? value.toLocaleDateString() : '-'),
					flex: 1,
				},
				{
					field: 'endDate',
					headerName: t('ui.label.endDate'),
					valueGetter: (_, row) => row.period.end,
					valueFormatter: (value: Date | undefined) =>
						value ? value.toLocaleDateString() : t('noEnding'),
					flex: 1,
				},
				{
					field: 'userGroup',
					headerName: t('userGroup'),
					valueGetter: (value: BaseReference) => value.label,
					flex: 1,
				},
				{
					field: 'deviceGroup',
					headerName: t('deviceGroup'),
					valueGetter: (value: BaseReference) => value.label,
					flex: 1,
				},
				{
					field: 'schedule',
					headerName: t('schedule'),
					valueGetter: (value: BaseReference) => value.label,
					flex: 1,
				},
				{
					field: 'actions',
					type: 'actions',
					display: 'text',
					width: 50,
					resizable: false,
					align: 'right',
					hideable: false,
					getActions: (params) => [
						<GridActionsCellItem
							key={`${params.id}-edit`}
							label={t('ui.edit')}
							icon={<EditIcon />}
							color='inherit'
							onClick={() =>
								onEdit != null && onEdit({ id: params.id.toString(), label: params.row.label })
							}
							showInMenu
						/>,
						<GridActionsCellItem
							key={`${params.id}-delete`}
							label={t('ui.delete')}
							icon={<DeleteOutlinedIcon color='error' />}
							color='error'
							onClick={() =>
								onDelete != null && onDelete({ id: params.id.toString(), label: params.row.label })
							}
							showInMenu
						/>,
					],
				},
			],
			[],
		);

		const { data, error, isLoading, isValidating, mutate } = useSWR(
			fetchParameters ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getAccessRules(args),
			{
				onSuccess: (res) => res?.total != null && setTotalCount(res.total),
				keepPreviousData: true,
			},
		);

		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useImperativeHandle(
			ref,
			() => ({
				mutate: mutate,
			}),
			[mutate],
		);

		const handlePaginationModelChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
			const organisation = model.items.length > 0 ? model.items[0].value : undefined;
			if (organisation) {
				setFilterModel(model);
				setSelectedOrganisation(organisation);
			}
		};

		return (
			<Stack height={1} spacing={2}>
				<Box id={toolbarIdString} minHeight={48} />
				<DataGrid
					snapshotKey='accr'
					// autoHeight
					columns={columns}
					error={error}
					loading={isLoading || isValidating}
					rows={data?.results ?? []}
					rowCount={totalCount}
					onPaginationModelChange={handlePaginationModelChange}
					filterModel={filterModel}
					onFilterModelChange={handleFilterModelChange}
					slots={{
						toolbar: Toolbar,
					}}
					slotProps={{
						toolbar: {
							onCreate: onCreate
						},
						noRowsOverlay: {
							title: t('noResourceFound', { resource: t('rules') }),
						},
						noResultsOverlay: {
							title: t('noResourceFound', { resource: t('rules') }),
						},
					}}
					// initialState={{
					// 	columns: {
					// 		columnVisibilityModel: {
					// 			organisation: isSuperAdmin(),
					// 		},
					// 	},
					// }}
					initialState={{
						filter: {
							filterModel: {
								items: [{
									field: 'organisation',
									operator: 'contains',
									value: selectedOrganisation
								}]
							}
						}
					}}
					sx={{ flexGrow: 1, flexShrink: 1, minHeight: 400 }}
				/>
			</Stack>
		);
	},
);
AccessRulesDataGrid.displayName = 'AccessRulesDataGrid';

const Toolbar = (props) => {
	const { t } = useTranslation('general');

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar disableClearableOrganisation>
				<Button variant='contained' onClick={() => props.onCreate != null && props.onCreate()}>
					{t('addResource', { resource: t('rule') })}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default AccessRulesDataGrid;
