import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	textField: {
		'& .MuiOutlinedInput-root': {
			borderRadius: '12px',
		},
	},
	dialog: {
		width: '450px',
		minHeight: '95px',
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 3, 4, 3),
	},
	closeButton: {
		marginRight: '10px',
	},
	button: {
		marginLeft: '10px',
	},
}));
