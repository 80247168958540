import { Grid, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
	const { className, editing } = props;
	const { t } = useTranslation('general');

	return (
		<div className={className}>
			<Grid alignItems='flex-end' container justifyContent='space-between'>
				<Grid item>
					<Box pb={5}>
						<Typography gutterBottom variant='overline'>
							{editing ?
								t('views.addPricing.header.editPricing')
							:	t('views.addPricing.header.addPricing')}
						</Typography>
						<Typography variant='h2'>{t('ui.pricing')}</Typography>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,

	editing: PropTypes.bool,
};

export default Header;
