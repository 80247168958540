import PropTypes from 'prop-types';

import { useStyles } from './style.js';

const MaxLengthCountDown = (props) => {
	const { maxLength, length } = props;

	const classes = useStyles();

	return <p className={classes.counterText}>{length + '/' + maxLength}</p>;
};

MaxLengthCountDown.propTypes = {
	maxLength: PropTypes.number,
	length: PropTypes.number,
};

export default MaxLengthCountDown;
