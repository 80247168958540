import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ButtonCard from './DockingElements/ButtonCard';
import DockingCard from './DockingElements/DockingCard';
import LocationCard from './DockingElements/LocationCard';
import { useStyles } from './style';
import { Drawer, Typography, StyledButton } from '../../../components';
import { isObject } from '../../../shared/utility';
import * as actions from '../../../store/actions';
const LinkDockingLocation = (props) => {
	const {
		open,
		onClose,

		locationDetails,
		setLocationDetails,
		onLinkDockingLocationn,
		linkDockingLocation,
		onResetState,
	} = props;
	const { t } = useTranslation('general');

	const { data: linkDockingLocationData, loading: linkDockingLocationLoading } =
		linkDockingLocation;

	const classes = useStyles();

	const [docking, setDocking] = useState(null);

	const clearSelectionLocation = () => setLocationDetails(null);

	const handleClearDocking = () => setDocking(null);

	const handleLinkDockingLocation = () => {
		onLinkDockingLocationn(docking?.slug, locationDetails?.id);
	};

	useEffect(() => {
		if (!linkDockingLocationLoading && isObject(linkDockingLocationData)) {
			onClose();
			onResetState('linkDockingLocation');
		}
	}, [linkDockingLocationData]);

	useEffect(() => {
		if (!open) {
			setDocking(null);
			setLocationDetails(null);
		}
	}, [open]);

	const buttonProps = {
		size: 'large',
		variant: 'contained-primary',
		onClick: handleLinkDockingLocation,
	};

	const locationCardProps = {
		open,

		clearSelection: clearSelectionLocation,
		cardDetails: locationDetails,
		selectCardDetails: setLocationDetails,
	};

	const dockingCardProps = { open, docking, setDocking, clearSelection: handleClearDocking };

	return (
		<Drawer onClose={onClose} open={open}>
			<form className={classes.drawerForm}>
				<div>
					<ButtonCard handleCloseDrawer={onClose} />
					<Typography variant='h2'>
						{t('views.actions.linkDockingLocation.drawer.title')}
					</Typography>
					<LocationCard {...locationCardProps} />
					<DockingCard {...dockingCardProps} />
				</div>
				<StyledButton {...buttonProps}>{t('ui.confirm')}</StyledButton>
			</form>
		</Drawer>
	);
};

LinkDockingLocation.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	locationDetails: PropTypes.object,
	setLocationDetails: PropTypes.func,
	onResetState: PropTypes.func,
	onLinkDockingLocationn: PropTypes.func,

	linkDockingLocation: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		linkDockingLocation: state.details.linkDockingLocation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetState: (state) => dispatch(actions.resetState(state)),
		onLinkDockingLocationn: (slug, locationId) =>
			dispatch(actions.linkDockingLocation(slug, locationId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkDockingLocation);
