import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	filterInputContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(1, 1.5),
		alignItems: 'center',
		width: '100%',
		height: 'auto',
	},
	filterInput: {
		width: '100%',
		minWidth: 180,
		maxWidth: 280,
		height: '44px',
		'& .MuiOutlinedInput-input': {
			padding: '13.5px 14px',
		},
	},
	selectLazyLoading: {
		height: '44px',
		width: '100%',
		minWidth: 180,
		maxWidth: 280,
	},
	button: {
		textTransform: 'none',
		marginLeft: theme.spacing(2),
		whiteSpace: 'nowrap',
		padding: theme.spacing(1, 4),
	},
	'@media (min-width: 500px)': {
		filterInputContainer: {
			flexWrap: 'nowrap',
		},
	},
	'@media (min-width: 650px)': {
		root: {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
}));
