// export const window.google.maps = window.google.window.google.maps;
// export const MarkerClusterer = window.MarkerClusterer;

const pinSymbol = (color) => {
	return {
		path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
		fillColor: color,
		fillOpacity: 1,
		strokeColor: '#fff',
		strokeWeight: 1,
		scale: 1,
	};
};

export const createGoogleMap = (mapElement, pos = { lat: 52.0071797, lng: 4.3673422 }) =>
	new window.google.maps.Map(mapElement, {
		zoom: 14,
		center: pos,
	});

export const createMarker = (pos = { lat: 52.0071797, lng: 4.3673422 }, markerColor = '#304ffe') =>
	new window.google.maps.Marker({
		position: pos,
		draggable: true,
		icon: pinSymbol(markerColor),
	});

export const createDragEvent = (marker, updatePosition = () => {}) => {
	window.google.maps.event.addListener(marker, 'dragend', () => {
		updatePosition(marker.getPosition());
	});
};

export const clearDragEvents = (marker) => {
	window.google.maps.event.clearListeners(marker, 'dragend');
};
