import { useState, useEffect } from 'react';

import { Avatar, Box, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import BillingPeriod from './BillingPeriod';
import { useStyles } from './style';
import { Table, Label, SearchBar } from '../../../../../components';
import {
	isFullArray,
	isObject,
	getInitials,
	isSuperAdmin,
	isEmptyString,
	isEmptyArray,
	decimalAmount,
	getFinanceTableHeaders,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const BillingsUsers = (props) => {
	const {
		date,
		onFetchPersonalBillingPeriodByDate,
		personalBillingPeriodDetails,

		setFullName,
		id,
		name,
		title,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const { data: personalBillingPeriodDetailsData, loading: personalBillingPeriodDetailsLoading } =
		personalBillingPeriodDetails;
	const classes = useStyles();
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [shouldSearch, setShouldSearch] = useState(false);
	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(20);

	const handlePageChange = (newPage) => {
		setPageNumber(newPage);
		setShouldDoInitialFetch(true);
	};

	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
		setShouldDoInitialFetch(true);
	};

	const filters = {
		...(!isEmptyString(searchValue) && { searchTerm: searchValue }),
		...(isSuperAdmin(auth.user?.profile.role) && { organisationId: id }),
	};

	useEffect(() => {
		if (isObject(personalBillingPeriodDetailsData) && !personalBillingPeriodDetailsLoading) {
			setFullName(personalBillingPeriodDetailsData.title);
		}
	}, [personalBillingPeriodDetailsLoading]);

	useEffect(() => {
		if (shouldSearch || shouldDoInitialFetch) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			if (date && !personalBillingPeriodDetailsLoading) {
				onFetchPersonalBillingPeriodByDate(date, filters);
			}
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [date, shouldSearch, shouldDoInitialFetch, pageNumber, pageSize]);

	const handleSearchClick = () => {
		if (!isEmptyString(searchValue)) {
			setShouldSearch(true);
			setIsShowingSearchResults(true);
		}
		if (isEmptyString(searchValue) && isShowingSearchResults) {
			setShouldSearch(true);
			setIsShowingSearchResults(false);
		}
	};

	const handleResetSearch = () => {
		setSearchValue('');
		if (isShowingSearchResults === true) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const events = {
		onClick: handleSearchClick,
		onChange: (e) => setSearchValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const headers = getFinanceTableHeaders(true);

	const tableHeader = [
		{ name: 'users', content: t('ui.category.users') },
		{ name: 'userId', content: `${t('ui.label.user')} id` },
		...headers,
	];

	const emptyBody =
		(
			isObject(personalBillingPeriodDetailsData) &&
			!personalBillingPeriodDetailsLoading &&
			isEmptyArray(personalBillingPeriodDetailsData.billings)
		) ?
			Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ content: '-' })))
		:	null;

	const loadingBody =
		!isObject(personalBillingPeriodDetailsData) || personalBillingPeriodDetailsLoading ?
			Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const dataBody =
		isObject(personalBillingPeriodDetailsData) && !personalBillingPeriodDetailsLoading ?
			personalBillingPeriodDetailsData.billings.map((user) => [
				{
					content:
						isFullArray(user.userReference.imagesReference) ?
							<Box className={classes.cell}>
								<Avatar src={user.userReference.imagesReference[0]} />
								<Box pl={1.5}>
									<Link
										className={classes.link}
										color='inherit'
										component={RouterLink}
										to={`/financeManagement/revenue/${user.start}/${title}/partner/${id}/${name}/parsonal/bookings`}
										state={{ data: user }}
										variant='h6'
									>
										{user.userReference.name}
									</Link>
								</Box>
							</Box>
						:	<Box className={classes.cell}>
								<Avatar>{getInitials(user.userReference.name)}</Avatar>
								<Box pl={1}>
									<Link
										className={classes.link}
										color='inherit'
										component={RouterLink}
										to={`/financeManagement/revenue/${user.start}/${title}/partner/${id}/${name}/parsonal/bookings`}
										state={{ data: user }}
										variant='h6'
									>
										{user.userReference.name}
									</Link>
								</Box>
							</Box>,
				},
				{ content: user.userReference.id },
				{ content: decimalAmount(user.amount.subtotal, user.amount) },
				{ content: decimalAmount(user.amount.discount, user.amount) },
				{ content: decimalAmount(user.amount.subtotal - user.amount.discount, user.amount) },
				{ content: decimalAmount(user.amount.priceVat, user.amount) },
				{ content: decimalAmount(user.amount.refund, user.amount) },
				{ content: decimalAmount(user.amount.totalPrice, user.amount) },
				{ content: decimalAmount(user.open, user.amount) },
				{
					content: <Label type={statusColor[user.status]}>{statusText[user.status]}</Label>,
				},
			])
		:	Array(10)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	return (
		<>
			<Box pt={3}>
				<BillingPeriod
					data={personalBillingPeriodDetailsData}
					loading={personalBillingPeriodDetailsLoading}
				/>
				<Box pt={3}>
					<SearchBar
						hasSearchButton
						placeholder={t('views.usersAndOrganisations.organisations.searchPlaceholder')}
						searchEvents={events}
						searchValue={searchValue}
					/>
				</Box>
			</Box>
			<Table
				body={loadingBody || emptyBody || dataBody}
				cellStyle={classes.cellStyle}
				data={personalBillingPeriodDetailsData ? personalBillingPeriodDetailsData.billings : []}
				handlePageChange={handlePageChange}
				header={tableHeader}
				// page={pageNumber}
				// rowsPerPage={pageSize}
				isNotPaginate={true}
				loading={personalBillingPeriodDetailsLoading}
				noTitle={true}
				setRowsPerPage={handlePageSizeChange}
				total={personalBillingPeriodDetailsData ? personalBillingPeriodDetailsData.total : 0}
				withoutPages={true}
			/>
		</>
	);
};

BillingsUsers.propTypes = {
	financeType: PropTypes.string,
	date: PropTypes.string,
	id: PropTypes.string,
	personalBillingPeriodDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchPersonalBillingPeriodByDate: PropTypes.func,
	setFullName: PropTypes.func,
	name: PropTypes.string,
	title: PropTypes.string,
};

const mapStateToProps = (state) => {
	return {
		personalRevenue: state.details.personalRevenue,
		currentUser: state.details.currentUser,
		personalBillingPeriodDetails: state.details.personalBillingPeriodByDate,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPersonalBillingPeriodByDate: (date, filters) =>
			dispatch(actions.fetchPersonalBillingPeriodByDate(date, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingsUsers);
