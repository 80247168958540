import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style.js';
import Bar from '../../loading/Bar/Bar.js';

const BasicSelectedCard = (props) => {
	const { className, name, loading, handleClose, hasCloseButton } = props;
	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent className={classes.content}>
				<Typography variant='h5'>
					{loading ?
						<Bar />
					:	name}
				</Typography>
			</CardContent>
			{hasCloseButton ?
				<IconButton onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			:	null}
		</Card>
	);
};

BasicSelectedCard.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	loading: PropTypes.bool,
	handleClose: PropTypes.func,
	hasCloseButton: PropTypes.bool,
};

export default BasicSelectedCard;
