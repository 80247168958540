export const actionType = {
	ADD: 'add',
	UPDATE: 'update',
	FILLUP: 'fillUp',
};
export const characteristicsReducer = (state, action) => {
	const { items, characteristic } = action;
	switch (action.type) {
		case actionType.ADD:
			return state.concat(items.map((itme) => ({ ...itme, checked: false })));
		case actionType.UPDATE:
			return state.map((item) =>
				characteristic.name === item.name ?
					{
						...item,
						checked: characteristic.checked,
					}
				:	item,
			);
		case actionType.FILLUP:
			return state.map((item) =>
				characteristic.includes(item.name) ?
					{
						...item,
						checked: true,
					}
				:	item,
			);
		default:
			return [];
	}
};
