import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		padding: 12,
		marginBottom: 'auto',
	},
	actions: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		'& > * + *': {
			marginLeft: 0,
		},
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
}));
