/* eslint-disable react/display-name */
import { useEffect, useRef, useState, useCallback, memo } from 'react';

import {
	TextField,
	Popper,
	Paper,
	List,
	ListItem,
	Avatar,
	Box,
	Typography,
	ClickAwayListener,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '~hooks';

import { useStyles } from './style';
import {
	isObject,
	isEmptyString,
	isNull,
	getInitials,
	isUndefined,
	isFullString,
	handleLockType,
	handleHubReference,
} from '../../../shared/utility';
import { LoadingBar } from '../../loading';

const propsAreEqual = (prevProps, nextProps) => {
	return (
		prevProps.className === nextProps.className &&
		JSON.stringify(prevProps.dataList) === JSON.stringify(nextProps.dataList) &&
		JSON.stringify(prevProps.filter) === JSON.stringify(nextProps.filter)
	);
};

const SearchSuggestAutocomplete = memo((props) => {
	const {
		className,
		placeholder,
		emptyStateText,
		fetch = true,
		defaultListValues = [],
		dataList,
		onFetchData,
		setSelected,
		listType,
		filter,
		extraFilter,
		deepFilter,
		fetchById,
		shouldNotUseLazyLoading,
		popperSize,

		label,
		sortingFilter,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const [fetchingData, setFetchingData] = useState(false);
	const { data, loading: dataLoading, error: dataError } = dataList || {};
	const dataReady = isObject(data) && !dataLoading && !dataError;
	const loading = isObject(data) ? dataLoading : !dataReady;

	const [shouldLazyLoadFetch, setShouldLazyLoadFetch] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [shouldSearch, setShouldSearch] = useState(false);
	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);
	const [isNewSearchValue, setIsNewSearchValue] = useState(true);

	const [shouldFilter, setShouldFilter] = useState(false);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(5);

	const refStartOfList = useRef();
	const refEndOfList = useRef();

	const popperRef = useRef(null);

	useEffect(() => {
		if (popperRef.current) {
			popperRef.current.update();
		}
	}, []);

	const inputRef = useRef(null);

	const [openResultsPopper, setOpenResultsPopper] = useState(false);

	const [shouldDoInitialFetchOnFocus, setShouldDoInitialFetchOnFocus] = useState(true);

	/* * * * * * * *
	 * FETCH DATA  *
	 * * * * * * * */

	const page = { number: pageNumber, size: pageSize };

	const filters = {
		...(!isEmptyString(searchValue) && {
			searchTerm: listType === 'users' ? encodeURIComponent(searchValue) : searchValue,
		}),
		...(filter &&
			!isNull(filter.value) &&
			!isUndefined(filter.value) && { [filter.name]: filter.value }),
		...(extraFilter &&
			!isNull(extraFilter.value) &&
			!isUndefined(extraFilter.value) && { [extraFilter.name]: extraFilter.value }),
		...(sortingFilter &&
			!isNull(sortingFilter.value) &&
			!isUndefined(sortingFilter.value) && { [sortingFilter.name]: sortingFilter.value }),
		...(deepFilter &&
			!isNull(deepFilter.value) &&
			!isUndefined(deepFilter.value) && { [deepFilter.name]: deepFilter.value }),
	};

	useEffect(() => {
		if (fetch && !dataLoading && (shouldSearch || shouldLazyLoadFetch || shouldFilter)) {
			if (!isUndefined(fetchById)) {
				onFetchData(fetchById, page, filters, true);
			} else {
				onFetchData(page, filters, true);
			}
			setFetchingData(true);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldLazyLoadFetch) {
			setShouldLazyLoadFetch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		}
	}, [
		shouldSearch,
		shouldLazyLoadFetch,
		searchValue,
		filter,
		extraFilter,
		shouldFilter,
		sortingFilter,
	]);

	useEffect(() => {
		if (dataReady) {
			setIsNewSearchValue(false);
		}
	}, [dataReady]);

	useEffect(() => {
		if (isObject(data)) {
			setFetchingData(dataLoading);
		} else if (dataReady) {
			setFetchingData(false);
		}
	}, [data, dataLoading, dataReady]);

	/* * * * * *
	 * SEARCH  *
	 * * * * * */
	const handleSearch = () => {
		if (!isEmptyString(searchValue)) {
			setShouldSearch(true);
			setIsShowingSearchResults(true);
		}
	};

	const handleResetSearch = () => {
		setSearchValue('');
		if (isShowingSearchResults) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUpSearch = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearch();
				break;
			default:
				return;
		}
	};

	const searchEvents = {
		onChange: (e) => setSearchValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUpSearch(e),
	};

	const debouncedSearchValue = useDebounce(searchValue, 500);

	useEffect(() => {
		if (debouncedSearchValue) {
			setPageNumber(1);
			setShouldSearch(true);
		}
	}, [debouncedSearchValue]);

	useEffect(() => {
		if (isEmptyString(searchValue)) {
			setIsNewSearchValue(true);
			setPageNumber(1);
			if (!shouldDoInitialFetchOnFocus) {
				setShouldSearch(true);
			}
		}
	}, [searchValue]);

	/* * * * * *
	 * SELECT  *
	 * * * * * */
	const handleSelect = (item) => {
		setSelected(item);
		setSearchValue('');
		setOpenResultsPopper(false);
	};

	/* * * * * * * * *
	 * AUTOCOMPLETE  *
	 * * * * * * * * */
	const [activeIndex, setActiveIndex] = useState(null);

	const handleOnKeyUp = (e) => {
		if (!isObject(data) || !data.total) {
			return;
		}

		switch (e.key) {
			case 'Enter':
				if (!isNull(activeIndex)) {
					handleSelect(data.results[activeIndex]);
				}
				break;
			case 'ArrowUp':
				if (activeIndex === 0) {
					setActiveIndex(data.results.length - 1);
				} else if (isNull(activeIndex)) {
					setActiveIndex(0);
				} else {
					setActiveIndex(activeIndex - 1);
				}
				break;
			case 'ArrowDown':
				if (activeIndex === data.results.length - 1) {
					setActiveIndex(0);
				} else if (isNull(activeIndex)) {
					setActiveIndex(0);
				} else {
					setActiveIndex(activeIndex + 1);
				}
				break;
			default:
				return;
		}
	};

	/* * * * * * * * * * * * * *
	 * LAZY LOADING LISTITEMS  *
	 * * * * * * * * * * * * * */
	const handleLazyLoading = () => {
		if (!fetchingData) {
			setPageNumber((p) => p + 1);
			setShouldLazyLoadFetch(true);
			setFetchingData(true);
		}
	};

	const lazyLoadMore = useCallback(() => {
		if (refEndOfList && refEndOfList.current && isObject(data)) {
			const bcrStart = refStartOfList.current.getBoundingClientRect();
			const bcrEnd = refEndOfList.current.getBoundingClientRect();
			if (bcrEnd.top + bcrEnd.height - 250 < bcrStart.top + bcrStart.height && !loading) {
				handleLazyLoading();
			}
		}
	}, [data, loading, fetchingData]);

	useEffect(() => {
		if (
			!shouldNotUseLazyLoading &&
			isObject(data) &&
			data.hasMore &&
			document.querySelector(`.${classes.selectResults}`)
		) {
			document.querySelector(`.${classes.selectResults}`).addEventListener('scroll', lazyLoadMore);
		}

		return () => {
			if (document.querySelector(`.${classes.selectResults}`)) {
				document
					.querySelector(`.${classes.selectResults}`)
					.removeEventListener('scroll', lazyLoadMore);
			}
		};
	}, [
		lazyLoadMore,
		classes.selectResults,
		data,
		loading,
		openResultsPopper,
		shouldNotUseLazyLoading,
	]);

	const loadingContent = (
		<>
			<ListItem button className={classes.listItem}>
				<LoadingBar />
			</ListItem>
			<ListItem button className={classes.listItem}>
				<LoadingBar />
			</ListItem>
			<ListItem button className={classes.listItem}>
				<LoadingBar />
			</ListItem>
		</>
	);

	return (
		<ClickAwayListener onClickAway={() => setOpenResultsPopper(false)}>
			<div className={className}>
				<TextField
					InputLabelProps={{
						shrink: true,
					}}
					className={classes.input}
					fullWidth
					inputRef={inputRef}
					label={label}
					onChange={searchEvents.onChange}
					onFocus={() => {
						setOpenResultsPopper(true);
						if (!data || isNewSearchValue) {
							setShouldSearch(true);
							setShouldDoInitialFetchOnFocus(false);
						}
					}}
					onKeyUp={handleOnKeyUp}
					placeholder={placeholder ? placeholder : ''}
					value={searchValue}
					variant='outlined'
				/>
				<Popper
					anchorEl={inputRef.current ? inputRef.current : null}
					disablePortal
					modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
					open={openResultsPopper}
					popperRef={popperRef}
					style={{ width: inputRef.current ? inputRef.current.clientWidth : null, zIndex: 2 }}
				>
					<Paper className={classes.popper}>
						<span ref={refStartOfList} />
						{(
							(!isObject(data) && loading) ||
							isNewSearchValue ||
							(isFullString(searchValue) &&
								pageNumber === 1 &&
								(debouncedSearchValue !== searchValue || fetchingData))
						) ?
							<List>{loadingContent}</List>
						: data.total > 0 || (data.predictions && data.predictions.length > 0) ?
							<List
								className={clsx({
									[classes.selectResults]: true,
									[classes.smallPopper]: !isFullString(popperSize) || popperSize === 'small',
									[classes.mediumPopper]: popperSize === 'medium',
									[classes.largePopper]: popperSize === 'large',
								})}
							>
								{listType === 'users' ?
									defaultListValues.concat(data.results).map((user, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											key={`user-${index}`}
											onClick={() => handleSelect({ ...user, clear: true })}
										>
											{user.imagesReference && user.imagesReference[0] ?
												<Avatar
													alt='User'
													className={classes.avatar}
													src={user.imagesReference[0]}
												/>
											:	<Avatar alt='User' className={classes.avatar}>
													{getInitials(`${user.firstName} ${user.lastName}`)}
												</Avatar>
											}
											<Box>
												<Typography variant='body1'>
													{`${user.firstName} ${user.lastName}`}
												</Typography>
												<Typography variant='body2'>{user.emailAddress}</Typography>
											</Box>
										</ListItem>
									))
								: listType === 'locations' ?
									defaultListValues.concat(data.predictions).map((item, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											key={`location-${index}`}
											onClick={() => handleSelect(item)}
										>
											<Box>
												<Typography>{item.description}</Typography>
											</Box>
										</ListItem>
									))
								: listType === 'items' ?
									defaultListValues.concat(data.results).map((item, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											key={`item-${index}`}
											onClick={() => handleSelect(item)}
										>
											<Box>
												<Typography variant='h6'>{item.name}</Typography>
												<Typography variant='body2'>{item.hubReference.name}</Typography>
											</Box>
										</ListItem>
									))
								: listType === 'planboard-items' ?
									defaultListValues.concat(data.results).map((item, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											disabled={item?.isUnavailable}
											key={`item-${index}`}
											onClick={() => handleSelect(item)}
										>
											<Box>
												<Typography variant='h6'>{item.title}</Typography>
												<Typography variant='body2'>{handleHubReference(item.childHub)}</Typography>
											</Box>
										</ListItem>
									))
								: listType === 'terms' ?
									defaultListValues.concat(data.results).map((variant, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											key={`organisation-${index}`}
											onClick={() => handleSelect(variant)}
										>
											<Box>
												<Typography variant='h6'>{variant.title}</Typography>
											</Box>
										</ListItem>
									))
								: listType === 'partnerOrganisation' ?
									defaultListValues.concat(data.results).map((variant, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											key={`parner-organisation-${index}`}
											onClick={() => handleSelect(variant)}
										>
											<Box>
												<Typography variant='h6'>{variant.partnerOrganisation.name}</Typography>
											</Box>
										</ListItem>
									))
								: listType === 'devices' ?
									defaultListValues.concat(data.results).map((variant, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											key={`device-${index}`}
											onClick={() => handleSelect(variant)}
										>
											<Box>
												<Typography variant='h6'>{`Hw UID: ${variant.deviceId}`}</Typography>
												<Typography variant='body2'>
													{handleLockType(variant.deviceType)}
												</Typography>
											</Box>
										</ListItem>
									))
								: listType === 'hubs' ?
									defaultListValues.concat(data.results).map((variant, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											key={`device-${index}`}
											onClick={() => handleSelect(variant)}
										>
											<Box>
												<Typography variant='h6'>{variant.name}</Typography>
												{/* <Typography variant='body2'>
                          {`${!isUndefined(variant.address.street) ? variant.address.street : ''} ${isNumber(variant.address.number) ? variant.address.number : '' } ${variant.address.city}`}
                        </Typography> */}
											</Box>
										</ListItem>
									))
								:	defaultListValues.concat(data.results).map((variant, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true,
											})}
											key={`organisation-${index}`}
											onClick={() => handleSelect(variant)}
										>
											<Box>
												<Typography variant='h6'>{variant.name}</Typography>
											</Box>
										</ListItem>
									))
								}
								{loading ? loadingContent : null}
								<span ref={refEndOfList} />
							</List>
						:	<div className={classes.empty}>
								{emptyStateText ? emptyStateText : t('ui.noResultsFound')}
							</div>
						}
					</Paper>
				</Popper>
			</div>
		</ClickAwayListener>
	);
}, propsAreEqual);

SearchSuggestAutocomplete.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	emptyStateText: PropTypes.string,
	defaultListValues: PropTypes.array,
	dataList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	listType: PropTypes.oneOf([
		'users',
		'items',
		'organisations',
		'partnerOrganisation',
		'userGroups',
		'locations',
		'planboard-items',
		'terms',
		'devices',
		'hubs',
	]),
	onFetchData: PropTypes.func.isRequired,
	setSelected: PropTypes.func.isRequired,
	filter: PropTypes.object,
	extraFilter: PropTypes.object,
	sortingFilter: PropTypes.object,
	fetchById: PropTypes.number,
	shouldNotUseLazyLoading: PropTypes.bool,
	popperSize: PropTypes.oneOf(['small', 'medium', 'large']),

	deepFilter: PropTypes.object,
	label: PropTypes.string,
	fetch: PropTypes.bool,
};

export default SearchSuggestAutocomplete;
