import { useEffect, useState } from 'react';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Box,
	IconButton,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { bytesToSize, isFullString } from '../../../../../../shared/utility';

function download(pdfUrl, fileName) {
	fetch(pdfUrl)
		.then((resp) => resp.arrayBuffer())
		.then((resp) => {
			// set the blog type to final pdf
			const file = new Blob([resp], { type: 'application/pdf' });
			// process to auto download it
			const fileURL = URL.createObjectURL(file);
			const link = document.createElement('a');
			link.href = fileURL;
			link.download = `${fileName}.pdf`;
			link.click();
		});
}

const OtherActions = (props) => {
	const { className, termData } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();
	const [size, setSize] = useState(null);

	const handlePdfVisibility = (e) => {
		e.preventDefault();
		download(
			termData.url,
			`${termData.name}_${termData.language}_${t('view.policymanagement.termdetails.button.inline.returntotermslist')}`,
		);
	};

	useEffect(() => {
		if (termData) {
			fetch(termData.url)
				.then((resp) => resp.arrayBuffer())
				.then((resp) => {
					// set the blog type to final pdf
					const file = new Blob([resp], { type: 'application/pdf' });
					// process to auto download it
					setSize(bytesToSize(file.size));
				});
		}
	}, []);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.terms.summary.header')} />
			<Divider />
			<CardContent>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('views.userDetails.summary.language')}</TableCell>
							<TableCell>{termData.language}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			<Card className={classes.fileCard}>
				<CardContent className={classes.uploadedFile}>
					<Box className={classes.fileName}>
						<DescriptionOutlinedIcon className={classes.description} fontSize='large' />
						<Box>
							<Typography variant='h6'>{`${termData.language}.pdf`}</Typography>
							<Typography variant='body2'>{isFullString(size) && size}</Typography>
						</Box>
					</Box>
					<IconButton className={classes.description} onClick={handlePdfVisibility}>
						<DownloadIcon fontSize='medium' />
					</IconButton>
				</CardContent>
			</Card>
		</Card>
	);
};

OtherActions.propTypes = {
	className: PropTypes.string,

	termData: PropTypes.object,
};

export default OtherActions;
