import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const PREFIX = 'PricingInfo';

const classes = {
	root: `${PREFIX}-root`,
	icon: `${PREFIX}-icon`,
	colorBox: `${PREFIX}-colorBox`,
};

const StyledBox = styled(Box)(({ theme }) => ({
	[`&.${classes.root}`]: {
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing(0, 3, 0, 0),
	},

	[`& .${classes.icon}`]: {
		paddingRight: theme.spacing(0.9),
	},

	[`& .${classes.colorBox}`]: {
		padding: '3px',
		borderRadius: '50%',
		marginRight: '11px',
	},
}));

const PricingInfo = (props) => {
	const { className, pricingName, bColor } = props;

	return (
		<StyledBox className={clsx(classes.root, className)}>
			<Box bgcolor={bColor} className={classes.colorBox} />
			<Typography className={classes.icon}>{pricingName}</Typography>
		</StyledBox>
	);
};

PricingInfo.propTypes = {
	className: PropTypes.string,
	pricingName: PropTypes.string,
	infoLable: PropTypes.string,
	styles: PropTypes.string,
	bColor: PropTypes.string,
};

export default PricingInfo;
