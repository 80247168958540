import React from 'react';

import path from 'path';

import { Business as BusinessIcon } from '@mui/icons-material';
import { Avatar, Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { pagePathSegments } from '~constants';

import Page from '../page';

/**
 * A simple page from where to registration process can be started
 */
const RegisterLaunchPage = () => {
	const { t } = useTranslation('general');
	const auth = useAuth();

	const handleClick = (e: React.SyntheticEvent) => {
		e.preventDefault();
		e.stopPropagation();

		const callbackPath = `/${path.join(pagePathSegments.Register, pagePathSegments.Organisation)}`;
		auth.signinRedirect({
			url_state: callbackPath,
		});
	};

	return (
		<Page title='Register'>
			<Stack spacing={3} justifyContent='center' alignItems='center' maxWidth={540} p={3}>
				<Avatar
					sx={{
						color: '#304FFE',
						bgcolor: '#E4EEFB',
						width: '91px',
						height: '91px',
					}}
				>
					<SvgIcon component={BusinessIcon} sx={{ fontSize: '55px' }} />
				</Avatar>
				<Typography variant='h4'>{t('views.register.organisation.start.title')}</Typography>
				<Typography>{t('views.register.organisation.start.subtitle')}</Typography>
				<Button variant='contained' onClick={handleClick}>
					{t('view.register.button.contained.getstarted')}
				</Button>
			</Stack>
		</Page>
	);
};

export default RegisterLaunchPage;
