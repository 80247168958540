import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { TextField, WeeklyScheduleForm } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';

import schema from './accessSchedulesSchema.json';
import SkcSchedulesService from '../../services/skcSchedulesService';

const service = new SkcSchedulesService();

interface AccessSchedulesFormProps {
	id?: string;
}

const AccessSchedulesForm = ({ id }: AccessSchedulesFormProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const { data, isLoading, error } = useSWRImmutable(
		id ? path.join(service.basePath, id) : null,
		() => service.getScheduleById(id!),
		{
			revalidateOnMount: true,
		},
	);

	const { handlers, setDisabled } = useFormContainerState();
	const { getValues, control, formState, reset, watch } = useForm({
		defaultValues: useMemo(
			() => data ?? { organisation: isSuperAdmin() ? undefined : userInfo.organisation },
			[data],
		),
		mode: 'onChange',
		resolver: ajvResolver(schema),
	});

	useEffect(() => {
		if (data) {
			// Used to reset the useform, otherwise the page won't properly reload
			reset(data);
		}
	}, [data]);

	useEffect(() => {
		if (!handlers) {
			return;
		}

		handlers.onSubmit = () => getValues();
	}, [handlers]);

	useEffect(() => {
		if (setDisabled) {
			setDisabled(!formState.isValid);
		}
	}, [formState.isValid, setDisabled]);

	if (id && isLoading) {
		return <>Loading...</>;
	}

	return (
		<Stack direction='column' spacing={2} my={1}>
			{isSuperAdmin() && (
				<Controller
					name='organisation'
					control={control}
					render={({ field }) => (
						<OrganisationAutocomplete
							required
							value={field.value}
							onChange={(e, newValue) => field.onChange(newValue)}
						/>
					)}
				/>
			)}
			<Controller
				name='label'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.name')}
						required
						slotProps={{
							htmlInput: {
								minLength: schema.properties.label.minLength,
								maxLength: schema.properties.label.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='description'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.description')}
						slotProps={{
							htmlInput: {
								maxLength: schema.properties.description.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='schedule'
				control={control}
				render={({ field }) => (
					<WeeklyScheduleForm
						value={field.value}
						onChange={(newValue) => field.onChange(newValue)}
					/>
				)}
			/>
		</Stack>
	);
};

export default AccessSchedulesForm;
