import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	maiCard: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		padding: theme.spacing(1.5),
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 'auto',
	},
	bottomCell: {
		borderBottom: 'none',
	},
	mapLink: {
		...theme.link.body3,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	qrCode: {
		objectFit: 'fill',
		height: '160px',
		width: '160px',
	},
	button: {},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 2, 2, 2),
		marginTop: 'auto',
		width: '100%',
	},
	editRoot: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: theme.spacing(4),
	},
}));
