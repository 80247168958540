import React, { useState } from 'react';

import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';

import { AsyncAutocomplete, AsyncAutocompleteProps } from '~components';
import { useDebounce } from '~hooks';
import { BaseReference } from '~interfaces';
import i18n from '~lib/i18n';

import UserGroupsService from '../../services/userGroupsService';

const service = new UserGroupsService();

interface UserGroupAutocompleteProps extends Omit<AsyncAutocompleteProps, 'options' | 'label'> {
	label?: string;
	count?: number;
	organisationId?: string;
}

const UserGroupAutocomplete = ({
	label = i18n.t('userGroups'),
	count = 10,
	organisationId,
	...asyncAutocompleteProps
}: UserGroupAutocompleteProps) => {
	const { t } = useTranslation('general');

	const [inputValue, setInputValue] = useState('');
	const debouncedSearchQuery = useDebounce(inputValue);

	const [initialLoad, setInitialLoad] = useState(false);
	const refreshString =
		initialLoad ? `${service.basePath}.1.${count}.${debouncedSearchQuery}` : null;
	const { data, isLoading, isValidating } = useSWR(refreshString, () =>
		service.getUserGroups(1, count, organisationId, debouncedSearchQuery),
	);

	const handleOpen = () => {
		setInitialLoad(true);
		mutate(refreshString);
	};

	const handleChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: NonNullable<string | BaseReference> | (string | BaseReference)[] | null,
		reason: AutocompleteChangeReason,
	) => {
		if (asyncAutocompleteProps.onChange) {
			asyncAutocompleteProps.onChange(e, value, reason);
		}
	};

	const handleInputChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: string,
		reason: AutocompleteInputChangeReason,
	) => {
		setInputValue(value);

		if (asyncAutocompleteProps.onInputChange) {
			asyncAutocompleteProps.onInputChange(e, value, reason);
		}
	};

	return (
		<AsyncAutocomplete
			{...asyncAutocompleteProps}
			label={label}
			loading={isLoading || isValidating}
			noOptionsText={t('noResults')}
			onOpen={handleOpen}
			options={data?.results ?? []}
			onChange={handleChange}
			inputValue={inputValue}
			onInputChange={handleInputChange}
		/>
	);
};

export default UserGroupAutocomplete;
