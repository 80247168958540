import { Close as CloseIcon } from '@mui/icons-material';
import { Card, Typography, Avatar, IconButton, Box } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { getInitials } from '../../../../../../shared/utility';

const UserCard = (props) => {
	const { name, email, imagesReference, onClick } = props;
	const classes = useStyles();

	return (
		<Card className={classes.userCard}>
			<Avatar className={classes.avatar} alt='User' src={imagesReference}>
				{getInitials(name)}
			</Avatar>
			<Box className={classes.labelCard}>
				<Typography variant='h6'>{name}</Typography>
				<Typography className={classes.emailLabel}>{email}</Typography>
			</Box>
			<IconButton onClick={onClick} className={classes.closeButton}>
				<CloseIcon />
			</IconButton>
		</Card>
	);
};

UserCard.propTypes = {
	name: PropTypes.string,
	email: PropTypes.string,
	imagesReference: PropTypes.string,
	onClick: PropTypes.func,
};

export default UserCard;
