import { Typography, Box, Tooltip as MUITooltip } from '@mui/material';
import PropTypes, { oneOfType } from 'prop-types';

import { useStyles } from './style';

const Tooltip = (props) => {
	const { infoTitle, content } = props;
	const classes = useStyles();

	return (
		<Box alignItems='center' display='flex'>
			<MUITooltip
				arrow
				classes={classes}
				placement='top'
				title={<Typography>{infoTitle}</Typography>}
			>
				{content}
			</MUITooltip>
		</Box>
	);
};

Tooltip.propTypes = {
	infoTitle: oneOfType([PropTypes.string, PropTypes.object]),
	content: PropTypes.object,
};

export default Tooltip;
