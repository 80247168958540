import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	arrowLeft: {
		cursor: 'pointer',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
	},
	extraButton: {
		width: 'max-content',
		height: 40.26,
		marginLeft: theme.spacing(2),
	},
}));
