import React, { useRef, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTripContext } from './Summary';
import { mapStyles, mapRestriction } from '../../../../../../constantsOld';
import { useCreateControl } from '../../../../../../shared/hooks';
import { isNull, isFullArray, isObject, isUndefined } from '../../../../../../shared/utility';
import {
	mapIcons,
	createMap,
	createMarker,
	createPath,
	createIcon,
} from '../../../../../FleetManagement/Configuration';

const TripMap = (props) => {
	const { children, data, tripData } = props;
	const { t } = useTranslation('general');

	const style = { width: '100%', height: '600px' };

	const { center, zoom } = useTripContext();
	const ref = useRef(null);
	const [map, setMap] = useState(null);
	const [markers, setMarkers] = useState(null);
	const [startAction, setStartAction] = useState(false);
	const [startActionAdd, setStartActionAdd] = useState(false);

	const centerControlDiv = document.createElement('div');

	const handleAction = () => {
		setStartAction(true);
		centerControl.innerText = t(' ui.button.removePoints');
	};

	const handleUndefined = (value) => (!isUndefined(value) ? value : '-');

	useEffect(() => {
		if (startAction && !startActionAdd) {
			setStartActionAdd(true);
			const iconOptions = {
				url: mapIcons.cluster,
				size: new window.google.maps.Size(20, 20),
				anchor: new window.google.maps.Point(10, 10),
				scaledSize: new window.google.maps.Size(20, 20),
			};
			const updatedMarkers = data.map((item) => {
				const labelOptions = { text: '', color: '#fff', fontSize: '0px' };
				const dataPointTest = `${text.speed}: ${handleUndefined(item.speed)}, ${text.mileage}: ${handleUndefined(item.mileage)}, ${text.engin}: ${handleUndefined(item.isEngineOn) ? text.on : text.off}`;
				return addMarker(item, '', dataPointTest, iconOptions, labelOptions);
			});
			setMarkers(updatedMarkers);
			setStartAction(false);
		} else if (startActionAdd && startAction) {
			setStartAction(false);
			setStartActionAdd(false);
			removeMarkers(markers);
		}
	}, [startAction, startActionAdd]);

	const centerControl = useCreateControl(handleAction, t('ui.button.showPoints'));

	const text = {
		speed: t('ui.label.speed'),
		mileage: t('ui.label.mileage'),
		engin: t('ui.label.engin'),
		on: t('ui.label.on'),
		off: t('ui.label.off'),
	};

	useEffect(() => {
		if (data && isNull(map)) {
			setMap(createMap(ref.current, zoom, center, mapStyles, mapRestriction));
		}
	}, [data]);

	useEffect(() => {
		if (centerControl && map) {
			centerControlDiv.appendChild(centerControl);
			// eslint-disable-next-line no-unused-vars, no-undef
			map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
		}
	}, [map]);

	useEffect(() => {
		if (isFullArray(data) || !isNull(map)) {
			if (isFullArray(markers)) {
				markers.forEach((marker) => {
					marker.setMap(null);
				});
			}
			const updatedPath = new window.google.maps.Polyline(createPath(data));

			updatedPath.setMap(map);

			const latlngBounds = new window.google.maps.LatLngBounds();

			const iconOptionsStartEnd = {
				url: mapIcons.cluster,
				size: new window.google.maps.Size(60, 60),
				labelOrigin: new window.google.maps.Point(26, 23),
				anchor: new window.google.maps.Point(15, 30),
				scaledSize: new window.google.maps.Size(50, 50),
			};

			const startEnd = data.filter((item, index) => index === 0 || index === data.length - 1);

			const updatedMarkers = startEnd.map((item, index) => {
				latlngBounds?.extend(item);
				if (index === 0) {
					return addMarker(
						item,
						'A',
						`${t('ui.label.startTrip')}, ${tripData?.startAddress}`,
						iconOptionsStartEnd,
					);
				} else if (index === 1) {
					return addMarker(
						item,
						'B',
						`${t('ui.label.endTrip')}, ${tripData?.endAddress}`,
						iconOptionsStartEnd,
					);
				}
			});
			map?.fitBounds(latlngBounds);

			setMarkers(updatedMarkers);
		}
	}, [data, map]);

	const addMarker = (
		position,
		labelText,
		infoText,
		iconOptions = { url: mapIcons.default },
		labelOptions = {
			text: labelText,
			color: '#fff',
			fontSize: '18px',
			fontFamily: '"Poppins-Medium" sans-serif',
		},
	) => {
		const infoWindow = new window.google.maps.InfoWindow({
			content: infoText,
		});

		const icon = createIcon(iconOptions);
		const marker = createMarker(position, map, labelOptions, icon);

		// open info window on marker is hover
		// eslint-disable-next-line no-unused-vars
		marker.addListener('mouseover', (e) => {
			infoWindow.open({ anchor: marker, map, shouldFocus: false });
		});
		// close info window on mouse exit
		// eslint-disable-next-line no-unused-vars
		marker.addListener('mouseout', (e) => infoWindow.close());

		return marker;
	};

	const removeMarker = (marker) => {
		if (isObject(marker)) {
			marker.setMap(null);
		}
	};

	// // Clear markers from map
	const removeMarkers = (clusterMarkers) => {
		if (isFullArray(clusterMarkers)) {
			clusterMarkers.forEach((marker) => {
				removeMarker(marker);
			});
		}
	};

	return (
		<>
			<div ref={ref} style={style} />
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					return React.cloneElement(child, { map });
				}
			})}
		</>
	);
};

TripMap.propTypes = {
	data: PropTypes.array,
	loading: PropTypes.bool,
	tripData: PropTypes.object,

	children: PropTypes.array,
};

export default TripMap;
