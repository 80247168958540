import { useEffect } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../../../components';
import * as actions from '../../../../../../store/actions';

const UnassignDevices = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	const {
		open,
		itmeId,
		deviceIds,
		onClose,
		fetchData,
		removeAccessDevices,
		onRemoveAccessDevices,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const {
		success: removeAccessDevicesSuccess,
		loading: removeAccessDevicesLoading,
		error: removeAccessDevicesError,
	} = removeAccessDevices;

	const handleConfirm = () => {
		onRemoveAccessDevices(itmeId, deviceIds);
	};

	const handleCloseUnassignDevices = () => onClose();

	useEffect(() => {
		if (removeAccessDevicesSuccess) {
			fetchData();
			handleCloseUnassignDevices();
			onResetStateCondition('removeAccessDevices', false);
			enqueueSnackbar(t('views.unlinkDialog.success.message'), { variant: 'success' });
		} else if (removeAccessDevicesError) {
			onResetStateCondition('removeAccessDevices', false);
			enqueueSnackbar(
				removeAccessDevicesError?.message ? removeAccessDevicesError.message : 'error',
				{ variant: 'error' },
			);
		}
	}, [removeAccessDevicesSuccess, removeAccessDevicesError]);

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text:
					removeAccessDevicesLoading ?
						<CircularProgress disableShrink size={24} />
					:	t('ui.confirm'),
			}}
			handleClose={handleCloseUnassignDevices}
			loading={removeAccessDevicesLoading || removeAccessDevicesSuccess}
			open={open}
			title={t('ui.unassignDevices.dialog.title')}
		>
			<Typography>{t('ui.unassignDevices.dialog.description')}</Typography>
		</ActionDialog>
	);
};

UnassignDevices.defaultProps = {
	open: false,
};

UnassignDevices.propTypes = {
	open: PropTypes.bool,

	onClose: PropTypes.func,
	itmeId: PropTypes.string,
	deviceIds: PropTypes.array,
	fetchData: PropTypes.func,
	onRemoveAccessDevices: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	removeAccessDevices: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		removeAccessDevices: state.condition.removeAccessDevices,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRemoveAccessDevices: (itemId, devices) =>
			dispatch(actions.removeAccessDevices(itemId, devices)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnassignDevices);
