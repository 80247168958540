import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const PREFIX = 'CountController';

const classes = {
	root: `${PREFIX}-root`,
	buttonContainer: `${PREFIX}-buttonContainer`,
	buttonCard: `${PREFIX}-buttonCard`,
	container: `${PREFIX}-container`,
	valueSpacing: `${PREFIX}-valueSpacing`,
};

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		display: 'flex',
		flexWrap: 'wrap',
		'& .MuiButton-outlined': {
			padding: '8px 0px 8px 0px',
			margin: '0',
		},
	},

	[`& .${classes.buttonContainer}`]: {
		display: 'flex',
		flexWrap: 'wrap',
	},

	[`& .${classes.buttonCard}`]: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: '24px',
	},

	[`& .${classes.container}`]: {
		flexDirection: 'column',
		border: 'solid 1px #dedede',
		borderRadius: '4px',
		alignContent: 'center',
		padding: theme.spacing(1),
	},

	[`& .${classes.valueSpacing}`]: {
		width: '10px',
	},
}));
/* * * * * * * * * *
 * Count Controller *
 * * * * * * * *  * */
const CountController = (props) => {
	const { className, name, icon, value, callbackIncrement, callbackDecrement } = props;

	return (
		<Root className={clsx(classes.root, className)}>
			<div className={classes.container}>
				<Box display='flex' justifyContent='center' pb={3} pt={1}>
					{icon}
				</Box>
				<Typography align='center' gutterBottom={true}>
					{name}
				</Typography>
				<div className={classes.buttonContainer}>
					<Paper className={classes.buttonCard} elevation={1}>
						<Button
							className={classes.subtract}
							color='primary'
							onClick={callbackDecrement}
							variant='outlined'
						>
							<RemoveIcon />
						</Button>
						<Box pl={3} pr={3}>
							<Typography className={classes.valueSpacing}>{value}</Typography>
						</Box>
						<Button color='primary' onClick={callbackIncrement} variant='outlined'>
							<AddIcon />
						</Button>
					</Paper>
				</div>
			</div>
		</Root>
	);
};

CountController.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	icon: PropTypes.object,
	value: PropTypes.number,
	callbackIncrement: PropTypes.func,
	callbackDecrement: PropTypes.func,
};

export default CountController;
