import { useEffect, useState } from 'react';

import { Card, Box, CardHeader, Divider, Tabs, Tab, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BookingList from './BookingList';
import MaintenanceList from './MaintenanceList';
import { isNumber, isSuperAdmin } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../../style';

const Bookings = (props) => {
	const { onDashboardStatus, dashboardStatus, dashboardOrganisationFilter } = props;
	const { t } = useTranslation('general');
	const auth = useAuth();
	const location = useLocation();

	const { data: dashboardStatusData } = dashboardStatus;

	const [tab, setTab] = useState(location?.state?.status ? location?.state?.status : 'active');

	const classes = useStyles();

	const handleTabsChange = (event, value) => setTab(value);

	useEffect(() => {
		onDashboardStatus();
	}, [dashboardOrganisationFilter.valueId]);

	const tabs = [
		{ value: 'active', label: 'ui.label.active', status: 0 },
		{ value: 'upcoming', label: 'ui.label.upcoming', status: 0 },
		{ value: 'overtime', label: 'ui.label.overTime', status: dashboardStatusData?.overtime },
		...(isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					value: 'attention',
					label: 'ui.label.needAttention',
					status: dashboardStatusData?.attention,
				},
			]
		:	[]),
		{ value: 'approval', label: 'ui.label.approval', status: dashboardStatusData?.approval },
		{ value: 'maintenance', label: 'ui.label.maintenance', status: 0 },
	];

	return (
		<Card className={classes.card}>
			<CardHeader title={t('nav.category.bookings')} />
			<Divider />
			<Tabs
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab
						icon={
							isNumber(tab?.status) && tab?.status > 0 ?
								<Box className={classes.buble}>
									<Typography color='error'>{tab.status}</Typography>
								</Box>
							:	<Box minHeight='20px'></Box>
						}
						iconPosition='end'
						key={tab.value}
						label={t(tab.label)}
						value={tab.value}
					/>
				))}
			</Tabs>
			<Divider />
			{tab === 'active' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='active' />
			)}
			{tab === 'upcoming' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='upcoming' />
			)}
			{tab === 'overtime' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='overtime' />
			)}
			{tab === 'attention' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='attention' />
			)}
			{tab === 'approval' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='approval' />
			)}
			{tab === 'maintenance' && (
				<MaintenanceList
					dashboardOrganisationFilter={dashboardOrganisationFilter}
					status='maintenance'
				/>
			)}
		</Card>
	);
};

Bookings.propTypes = {
	onDashboardBookings: PropTypes.func,
	dashboardOrganisationFilter: PropTypes.object,
	onDashboardStatus: PropTypes.func,

	dashboardStatus: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		dashboardStatus: state.details.dashboardStatus,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDashboardStatus: () => dispatch(actions.dashboardStatus()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
