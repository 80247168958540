import { useState, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import EditGeneralInfo from './EditGeneralInfo';
import { useStyles } from './style';
import { OpenEmail, OpenPhoneNumber, StyledButton, Canvas } from '../../../../../../components';
import { usePrevious } from '../../../../../../shared/hooks';
import { isObject, isFullString, isFullArray, isUndefined } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

const GeneralInfo = (props) => {
	const {
		organisation,
		className,

		onUpdateOrganisationImage,
		updateOrganisationImage,
		onUpdateDetailsState,
	} = props;
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const defaultImage =
		isObject(organisation) && isFullString(organisation.logo) ? organisation.logo : '';

	const {
		data: updateImageData,
		loading: updateImageLoading,
		error: updateImageError,
	} = updateOrganisationImage;
	const [previewImage, setPreviewImage] = useState(defaultImage);

	const [openEdit, setOpenEdit] = useState(false);

	const previousImageLoading = usePrevious(updateImageLoading);

	useEffect(() => {
		if (previousImageLoading && !updateImageLoading) {
			if (isObject(updateImageError)) {
				enqueueSnackbar(updateImageError.message, { variant: 'error' });
			} else {
				enqueueSnackbar(t('views.organisationDetail.summary.generalInfo.imageUpdate'), {
					variant: 'success',
				});
			}
		}
	}, [updateImageLoading]);

	useEffect(() => {
		if (!updateImageLoading) {
			if (isFullArray(updateImageData)) {
				onUpdateDetailsState('organisation', { ...organisation, logo: updateImageData });
			} else if (isObject(updateImageError)) {
				const initialState =
					isObject(organisation) && isFullString(organisation.logo) ? organisation.logo : '';
				setPreviewImage(initialState);
			}
		}
	}, [updateOrganisationImage]);

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	const handleOnChange = (file, preview) => {
		setPreviewImage(preview);
		onUpdateOrganisationImage(organisation.id, [file]);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.organisationDetail.summary.cardHeaders.general')} />
			<Divider />
			<CardContent className={classes.content}>
				<Box className={classes.imageBox}>
					<Canvas onChange={handleOnChange} previewImage={previewImage} />
				</Box>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.name')}</TableCell>
							<TableCell>{organisation.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.legalName')}</TableCell>
							<TableCell>
								{!isUndefined(organisation.legalName) ? organisation.legalName : '-'}
							</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('ui.label.email')}</TableCell>
							<TableCell>
								{isFullString(organisation.emailAddress) ?
									<OpenEmail email={organisation.emailAddress} />
								:	'-'}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.phoneNumber')}</TableCell>
							<TableCell>
								{isFullString(organisation.phoneNumber) ?
									<OpenPhoneNumber number={organisation.phoneNumber} />
								:	'-'}
							</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('ui.label.users')}</TableCell>
							<TableCell>{organisation.totalUsers}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.category.userGroups')}</TableCell>
							<TableCell>{organisation.totalUserGroups}</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell style={{ verticalAlign: 'top' }}>{t('ui.label.description')}</TableCell>
							<TableCell>{organisation.description}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			{organisation.isVerified ?
				<>
					<CardActions className={classes.actions}>
						<StyledButton
							onClick={handleEditOpen}
							startIcon={<EditIcon />}
							variant='inline-default'
						>
							{t('ui.button.inline.edit')}
						</StyledButton>
					</CardActions>
					<EditGeneralInfo onClose={handleEditClose} open={openEdit} organisation={organisation} />
				</>
			:	null}
		</Card>
	);
};

GeneralInfo.propTypes = {
	className: PropTypes.string,
	organisation: PropTypes.object.isRequired,

	onUpdateOrganisationImage: PropTypes.func,
	updateOrganisationImage: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onUpdateDetailsState: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		category: state.details.category,
		updateOrganisationImage: state.details.updateOrganisationImage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateOrganisationImage: (id, imageArray) =>
			dispatch(actions.updateOrganisationImage(id, imageArray)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
