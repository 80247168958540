import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import { ajvResolver } from '@hookform/resolvers/ajv';
import {
	Box,
	Stack,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { DatePicker, TextField, Tip } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';

import schema from './skcAccessRuleSchema.json';
import SkcDeviceGroupAutocomplete from '../../components/autocompletes/skcDeviceGroupsAutocomplete';
import SkcScheduleAutocomplete from '../../components/autocompletes/skcSchedulesAutocomplete';
import SkcUserGroupAutocomplete from '../../components/autocompletes/skcUserGroupsAutocomplete';
import SkcAccessRulesService from '../../services/skcAccessRulesService';

const service = new SkcAccessRulesService();

interface SkcAccessRuleFormProps {
	id?: string;
}

const SkcAccessRuleForm = ({
	id,
}: SkcAccessRuleFormProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const [datePickerFocus, setDatePickerFocus] = useState({});

	const { data, isLoading, isValidating, error } = useSWRImmutable(
		id ? path.join(service.basePath, id) : undefined,
		() => service.getAccessRuleById(id!),
		{
			revalidateOnMount: true,
		},
	);

	const { handlers, setDisabled } = useFormContainerState();
	const { getValues, control, formState, reset, watch } = useForm({
		defaultValues: useMemo(() => data ?? { organisation: isSuperAdmin() ? undefined : userInfo.organisation }, [data]),
		mode: 'onChange',
		resolver: ajvResolver(schema)
	});
	
	const watchOrganisation = watch('organisation');

	useEffect(() => {
		if (data) {
			// Used to reset the useform, otherwise the page won't properly reload
			reset(data);
		}
	}, [data]);

	useEffect(() => {
		if (!handlers) {
			return;
		}

		handlers.onSubmit = () => getValues();
	}, [handlers]);

	useEffect(() => {
		if (setDisabled) {
			setDisabled(!(formState.isValid && formState.isDirty));
		}
	}, [formState.isValid, setDisabled]);

	const handleDateChange = (newValue, onChange: () => void) => {
		newValue.startDate?.set('hour', 0);
		newValue.startDate?.set('minute', 0);
		newValue.startDate?.set('second', 0);
		newValue.endDate?.set('hour', 23);
		newValue.endDate?.set('minute', 59);
		newValue.endDate?.set('second', 59);

		onChange({
			start: newValue.startDate?.toDate(),
			end: newValue.endDate?.toDate(),
		});
	};

	if (id && isLoading) {
		return <>Loading...</>;
	}

	return (
		<Stack direction='column' spacing={2} my={1}>
			{isSuperAdmin() &&
				<Controller
					name='organisation'
					control={control}
					render={({ field }) =>
						<OrganisationAutocomplete
							value={field.value}
							onChange={(_, newValue) => field.onChange(newValue)}
							required
						/>
					}
				/>
			}
			<Controller
				name='label'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.name')}
						required
						slotProps={{
							htmlInput: {
								minLength: schema.properties.label.minLength,
								maxLength: schema.properties.label.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='description'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.description')}
						slotProps={{
							htmlInput: {
								maxLength: schema.properties.description.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='period'
				control={control}
				render={({ field }) => (
					<Box width='fit-content' overflow='visible' sx={{ zIndex: 10000 }}>
						<DatePicker
							focused={datePickerFocus?.focusedInput}
							handleDatesChange={(val) => handleDateChange(val, field.onChange)}
							handleFocusChange={(focusedInput) => setDatePickerFocus({ focusedInput })}
							start={field.value?.start !== undefined ? moment(field.value?.start) : null}
							end={field.value?.end !== undefined ? moment(field.value?.end) : null}
							minDate={moment().subtract(1, 'days')}
						/>
					</Box>
				)}
			/>
			<Tip message={t('addAccessRuleTipMessage')} />
			<Controller
				name='deviceGroup'
				control={control}
				render={({ field, formState }) =>
					<SkcDeviceGroupAutocomplete
						value={field.value}
						enableInfiniteScroll
						disabled={!watchOrganisation}
						organisation={watchOrganisation}
						onChange={(_, newVal) => field.onChange(newVal)}
					/>
				}
			/>
			<Controller
				name='userGroup'
				control={control}
				render={({ field }) =>
					<SkcUserGroupAutocomplete
						value={field.value}
						enableInfiniteScroll
						disabled={!watchOrganisation}
						organisation={watchOrganisation}
						onChange={(_, newVal) => field.onChange(newVal)}
					/>
				}
			/>
			<Controller
				name='schedule'
				control={control}
				render={({ field }) =>
					<SkcScheduleAutocomplete
						value={field.value}
						enableInfiniteScroll
						disabled={!watchOrganisation}
						organisation={watchOrganisation}
						onChange={(_, newVal) => field.onChange(newVal)}
					/>
				}
			/>
		</Stack>
	);
};

export default SkcAccessRuleForm;
