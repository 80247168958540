import { useState } from 'react';

import { Typography, Box } from '@mui/material';
import Lightbox from 'lightbox-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import 'lightbox-react/style.css';

const LightBox = (props) => {
	const { images, excludeLabel, classes } = props;
	const { t } = useTranslation('general');

	const [isOpen, setIsOpen] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);

	const openLightbox = (index) => {
		setIsOpen(true);
		setPhotoIndex(index);
	};

	const closeLightbox = () => {
		setIsOpen(false);
	};

	return (
		<div>
			<Box display='flex' flexDirection='column' flexWrap='wrap'>
				{images.map((image, index) => (
					<Box key={index}>
						{excludeLabel ? null : (
							<Typography variant='h4'>
								{t(`views.pendingLicenseDetails.images.${image.alt}`)}
							</Typography>
						)}
						<Box display='flex' flexWrap='wrap' pb={3} pt={2}>
							<div className={classes.imageContainer}>
								<img
									alt={image.alt}
									className={classes.imageSpacing}
									key={index}
									onClick={() => openLightbox(index)}
									src={image.src}
								/>
							</div>
						</Box>
					</Box>
				))}
			</Box>
			{isOpen && (
				<Lightbox
					mainSrc={images[photoIndex].src}
					nextSrc={images[(photoIndex + 1) % images.length].src}
					onCloseRequest={closeLightbox}
					onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
					onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
					prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
				/>
			)}
		</div>
	);
};

LightBox.propTypes = {
	children: PropTypes.any,
	images: PropTypes.any,

	excludeLabel: PropTypes.any,
	classes: PropTypes.any,
};

export default LightBox;
