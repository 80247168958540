import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { UserDetailsLayout } from '~features/users';

import PageNotFoundPage from '../feedbacks/pageNotFoundPage';
import Page from '../page';

const UserDetailsPage = () => {
	const { t } = useTranslation('general');
	const { id } = useParams();

	if (!id) {
		return <PageNotFoundPage />;
	}

	return (
		<Page title={t('userDetails')}>
			<UserDetailsLayout id={id} />
		</Page>
	);
};

export default UserDetailsPage;
