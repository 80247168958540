import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(4, 2),
		maxWidth: '60rem',
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	formGroup: {
		'& + &': {
			marginTop: theme.spacing(3),
		},
		'&:last-child': {
			marginBottom: theme.spacing(3),
		},
	},
	endLabel: {
		marginLeft: theme.spacing(4),
	},
	numberInput: {
		width: 200,
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(2),
	},
	inputWithExtraLabel: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
	},
	switchLabel: {
		width: '100%',
		justifyContent: 'space-between',
		margin: 0,
	},
	subheader: {
		margin: theme.spacing(4, 0),
	},
	divider: {
		marginTop: theme.spacing(3),
	},
	licenseDivider: {
		margin: theme.spacing(3, 0),
	},
	inputCooldownBefore: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
	},
	inputCooldown: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		'& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd':
			{
				paddingTop: '10px',
			},
	},
	label: {
		minWidth: '6rem',
	},
	dayInput: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(1.5),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	hourInput: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(2.5),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	minInput: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(2.5),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	dayInputAfter: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(2),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	hourInputAfter: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(2.5),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	minInputAfter: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(2.5),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	inputCancellation: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
	},
	dayInputCancel: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(1.5),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	hourInputCancel: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(2.5),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	minInputCancel: {
		width: 100,
		marginLeft: theme.spacing(3),
		'& .MuiInputLabel-formControl': {
			marginLeft: theme.spacing(2.5),
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: 'black',
		},
	},
	licenseVerification: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
	},
	useNowVerifyLaterLabel: {
		'& .MuiTypography-root': {
			margin: theme.spacing(1, 0),
			marginBottom: theme.spacing(0),
		},
	},
}));
