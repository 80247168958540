import { Card, CardContent, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import IconsUpload from './IconsUpload';
import LoadingIconBlock from './LoadingIconBlock';
import { LoadingBar } from '../../../components';

const PREFIX = 'Characteristics';

const classes = {
	root: `${PREFIX}-root`,
	characteristicsListItem: `${PREFIX}-characteristicsListItem`,
	iconBlock: `${PREFIX}-iconBlock`,
	iconBlockText: `${PREFIX}-iconBlockText`,
	enumList: `${PREFIX}-enumList`,
	mainCharacteristics: `${PREFIX}-mainCharacteristics`,
	enumItems: `${PREFIX}-enumItems`,
	container: `${PREFIX}-container`,
};

const StyledCard = styled(Card)(({ theme }) => ({
	[`&.${classes.root}`]: {
		maxWidth: 650,
	},

	[`& .${classes.characteristicsListItem}`]: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		borderTop: `1px solid ${theme.palette.divider}`,
		marginTop: theme.spacing(2),
		paddingTop: theme.spacing(3),
		'&:nth-of-type(1)': {
			borderTop: 'none',
			marginTop: theme.spacing(0),
			paddingTop: theme.spacing(0),
		},
	},

	[`& .${classes.iconBlock}`]: {
		display: 'flex',
		alignItems: 'center',
	},

	[`& .${classes.iconBlockText}`]: {
		marginRight: theme.spacing(3),
	},

	[`& .${classes.enumList}`]: {
		flexBasis: '100%',
		display: 'flex',
		flexDirection: 'column',
	},

	[`& .${classes.mainCharacteristics}`]: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexBasis: '100%',
	},

	[`& .${classes.enumItems}`]: {
		justifyContent: 'space-between',
		padding: theme.spacing(2, 0),
	},

	[`& .${classes.container}`]: {
		display: 'flex',
		flexWrap: 'wrap',
	},
}));

const Characteristics = (props) => {
	const { className, characteristicsData, data } = props;
	const { t } = useTranslation('general');

	return (
		<StyledCard className={clsx(classes.root, className)}>
			<CardContent>
				<List>
					{data ?
						data.map((item) =>
							item ?
								<ListItem className={classes.characteristicsListItem} key={item.id}>
									<div className={classes.mainCharacteristics}>
										<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
										{item.type !== 'enum' ?
											<div className={classes.iconBlock}>
												<Typography
													className={classes.iconBlockText}
												>{`${t('ui.label.iconLower')}:`}</Typography>
												<IconsUpload
													characteristic={item}
													characteristicsData={characteristicsData}
												/>
											</div>
										:	null}
									</div>
									<List className={classes.enumList}>
										{item.enumValues ?
											item.enumValues.map((eValue) => (
												<ListItem className={classes.enumItems} key={eValue.id}>
													<Typography variant='body1'>
														{t(`ui.characteristics.${eValue.name}`)}
													</Typography>
													<div className={classes.iconBlock}>
														<Typography
															className={classes.iconBlockText}
														>{`${t('ui.label.iconLower')}:`}</Typography>
														<IconsUpload
															characteristic={item}
															characteristicEnumValues={eValue}
															characteristicsData={characteristicsData}
														/>
													</div>
												</ListItem>
											))
										:	null}
									</List>
								</ListItem>
							:	null,
						)
					:	Array(4)
							.fill()
							.map((_, index) => (
								<ListItem
									className={classes.characteristicsListItem}
									key={`loading-category-${index}`}
								>
									<Typography variant='h5'>
										<LoadingBar />
									</Typography>
									<div className={classes.iconBlock}>
										<Typography className={classes.iconBlockText}>{t('ui.label.icon')}:</Typography>
										<LoadingIconBlock />
									</div>
								</ListItem>
							))
					}
				</List>
			</CardContent>
		</StyledCard>
	);
};

Characteristics.propTypes = {
	className: PropTypes.string,

	characteristicsData: PropTypes.object,
	data: PropTypes.array,
};

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
	};
};

export default connect(mapStateToProps)(Characteristics);
