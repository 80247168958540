import palette from '../palette';
import typography from '../typography';

export default {
	styleOverrides: {
		root: {
			...typography.input,
			'&.Mui-error': {
				color: palette.error.main,
			},
		},
		input: {
			'&::placeholder': {
				opacity: 0.4,
				color: palette.text.primary,
			},
		},
	},
};
