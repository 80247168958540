import { Box, Typography, Switch } from '@mui/material';
import PropTypes from 'prop-types';

const SwitchBtn = (props) => {
	const { extraSwitch } = props;

	return (
		<div>
			{extraSwitch.map((item, index) => (
				<Box alignItems='center' display='flex' key={index}>
					<Typography variant='h6'>{item.switchLabel}</Typography>
					<Switch checked={item.checked} disabled={false} onChange={item.actions} />
				</Box>
			))}
		</div>
	);
};

SwitchBtn.propTypes = {
	extraSwitch: PropTypes.arrayOf(Object).isRequired, //adjust to proper proptype when this is clear
};
export default SwitchBtn;
