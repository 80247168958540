import PropTypes from 'prop-types';

import { useStyles } from './style';

const OpenPhoneNumber = (props) => {
	const { number } = props;
	const classes = useStyles();

	return (
		<a className={classes.styledLink} href={`tel:${number}`}>
			{number}
		</a>
	);
};

OpenPhoneNumber.propTypes = {
	number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default OpenPhoneNumber;
