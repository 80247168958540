import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		paddingRight: theme.spacing(0.9),
		display: 'flex',
		alignItems: 'center',
	},
}));
