import { Card, CardContent, Divider } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import AdjustedSkeleton from '../AdjustedSkeleton';

const LoadingDetailActionsBlock = (props) => {
	const classes = useStyles();

	const { className } = props;

	return (
		<Card className={clsx(classes.root, className)}>
			<div className={classes.header}>
				<AdjustedSkeleton animation='wave' width='50%' />
			</div>
			<Divider />
			<CardContent className={classes.content}>
				<div className={classes.header}>
					<AdjustedSkeleton animation='wave' />
					<AdjustedSkeleton animation='wave' />
					<AdjustedSkeleton animation='wave' width='60%' />
				</div>
			</CardContent>
		</Card>
	);
};

LoadingDetailActionsBlock.propTypes = {
	className: PropTypes.string,
};

export default LoadingDetailActionsBlock;
