import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
		borderRadius: 50,
	},
	input: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		flex: 1,
	},
	hidden: {
		display: 'none',
	},
	divider: {
		height: 28,
		marginLeft: 4,
		marginRight: 4,
	},
}));
