import { useState, useEffect } from 'react';

import {
	Share as ShareIcon,
	Link as LinkIcon,
	InfoOutlined as InfoOutlinedIcon,
	DeviceHub as DeviceHubIcon,
} from '@mui/icons-material';
import { Paper, Typography, Box, CircularProgress, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import LinkedDevicesTable from './LinkedDevicesTable';
import { useStyles } from './style';
import { Tooltip } from './Tooltip';
import { Table, SearchBar, ActionDialog, GenericMoreButton } from '../../../components';
import { commaTimeStrings } from '../../../shared/datetime';
import {
	useSearchComponent,
	useError,
	useBasicFilter,
	useComplexFilter,
	usePagination,
} from '../../../shared/hooks';
import {
	isEmptyArray,
	isObject,
	isEmptyString,
	isSuperAdmin,
	isFullArray,
	handleLockType,
	handleDeviceTypes,
	isUndefined,
	isInteger,
	isFullString,
	convertBatteryLevelToEnum,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import AssignDevices from '../ActionFlows/AssignDevices';
import LinkDevices from '../ActionFlows/LinkDevices';

const DevicesList = (props) => {
	const {
		assigned,
		unassigned,
		devices,
		onFetchDevices,

		assignDevices,
		currentUser,
		unassignDevices,
		unlinkDevices,
		linkDevices,
		fetchLinkedDevices,
		onUnassignDevices,
		devicesTypesFilter,
		devicesFirmWarVersionsFilter,
		fetchDevicesOrganisations,
		devicesUnlockTypesFilter,
		onFetchDevicesOrganisations,
		linkDeviceToItemGroup,
		unlinkItemGroup,
		unlinkAllItemGroup,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();
	const location = useLocation();

	const [openAssignDevices, setOpenAssignDevices] = useState(false);
	const [openUnassignDevices, setOpenUnassignDevices] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);

	const [order, setOrder] = useState('asc');
	const [loading, setLoading] = useState(true);

	const [orderDescending, setOrderDescending] = useState(true);
	const [sortingById, setSortingById] = useState('id');

	const [shouldSort, setShouldSort] = useState(false);
	const [shouldFilter, setShouldFilter] = useState(false);
	const [openLinkDevices, setOpenLinkDevices] = useState(false);
	const [selectedDevices, setSelectedDevices] = useState(null);

	const { data: devicesData, loading: devicesLoading } = devices;
	const { loading: fetchLinkedDevicesLoading } = fetchLinkedDevices;
	const { data: currentUserData } = currentUser;
	const classes = useStyles();
	const isEmptyResults = isObject(devicesData) && isEmptyArray(devicesData.results);
	const isFullResults = isObject(devicesData) && !isEmptyArray(devicesData.results);

	const pagination = usePagination(
		`pageNumber${assigned ? 'assigned' : ''}`,
		`pageSize${assigned ? 'assigned' : ''}`,
	);
	const paginationLinked = usePagination('pageNumberLinkedDevices', 'pageSizeLinkedDevices');
	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch, 'searchDevice');
	const deviceUnlockTypeFilter = useBasicFilter('deviceUnlockTypeFilter', 'unlockType');
	const deviceFwFilter = useBasicFilter('deviceFwFilter', 'fw');
	const deviceDeviceTypeFilter = useBasicFilter('deviceDeviceTypeFilter');
	const deviceOrganisationFilter = useComplexFilter(
		'deviceOrganisationNameFilter',
		'deviceOrganisationIdFilter',
	);
	const showUnusedDevicesFilter = useBasicFilter('showUnusedDevicesFilter', false);

	const {
		data: assignDevicesData,
		loading: assignDevicesLoading,
		error: assignDevicesError,
	} = assignDevices;
	const assignDevicesDone =
		isObject(assignDevicesData) && !assignDevicesLoading && !assignDevicesError;

	const {
		data: unassignDevicesData,
		loading: unassignDevicesLoading,
		error: unassignDevicesError,
	} = unassignDevices;
	const unassignDevicesDone =
		isObject(unassignDevicesData) && !unassignDevicesLoading && !unassignDevicesError;

	const {
		success: unlinkDevicesSuccess,
		loading: unlinkDevicesLoading,
		error: unlinkDevicesError,
	} = unlinkDevices;

	const unlinkDevicesDone = unlinkDevicesSuccess && !unlinkDevicesLoading && !unlinkDevicesError;

	const {
		data: linkDevicesData,
		loading: linkDevicesLoading,
		error: linkDevicesError,
	} = linkDevices;
	const linkDevicesDone = isObject(linkDevicesData) && !linkDevicesLoading && !linkDevicesError;

	const {
		data: typesFilterData,
		loading: typesFilterLoading,
		error: typesFilterError,
	} = devicesTypesFilter;
	const typesFilterReady = isFullArray(typesFilterData) && !typesFilterLoading && !typesFilterError;

	const {
		data: firmWarVersionsFilterData,
		loading: firmWarVersionsFilterLoading,
		error: firmWarVersionsFilterError,
	} = devicesFirmWarVersionsFilter;
	const firmWarVersionsFilterReady =
		isFullArray(firmWarVersionsFilterData) &&
		!firmWarVersionsFilterLoading &&
		!firmWarVersionsFilterError;

	const {
		data: unlockTypesFilterData,
		loading: unlockTypesFilterLoading,
		error: unlockTypesFilterError,
	} = devicesUnlockTypesFilter;
	const unlockTypesFilterReady =
		isFullArray(unlockTypesFilterData) && !unlockTypesFilterLoading && !unlockTypesFilterError;

	const {
		data: linkDeviceToItemGroupData,
		loading: linkDeviceToItemGroupLoading,
		error: linkDeviceToItemGroupError,
	} = linkDeviceToItemGroup;
	const linkDeviceToItemGroupDone =
		isObject(linkDeviceToItemGroupData) &&
		!linkDeviceToItemGroupLoading &&
		!linkDeviceToItemGroupError;

	const { success: unlinkItemGroupSuccess } = unlinkItemGroup;

	const { success: unlinkAllItemGroupSuccess } = unlinkAllItemGroup;

	const unassignDevicesMessage = useError({
		value: unassignDevices,
		message: t('views.devices.successfullyUnassigned'),
	});

	const filters = {
		...(isFullString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingById) && { sortBy: sortingById }),
		...(isSuperAdmin(auth.user?.profile.role) && assigned && { isAssigned: true }),
		...(isSuperAdmin(auth.user?.profile.role) && unassigned && { isAssigned: false }),
		...(!isSuperAdmin(auth.user?.profile.role) &&
			assigned &&
			isObject(currentUserData) && { organisationId: currentUserData.organisationReference.id }),
		...(deviceDeviceTypeFilter?.value !== 'all' && { deviceType: deviceDeviceTypeFilter.value }),
		...(deviceFwFilter.value !== 'fw' && { firmwareVersion: deviceFwFilter.value }),
		...(deviceUnlockTypeFilter.value !== 'unlockType' && {
			identificationType: deviceUnlockTypeFilter.value,
		}),
		...(deviceOrganisationFilter.valueId !== 'all' && {
			organisationId: deviceOrganisationFilter.valueId,
		}),
		...(assigned && { isLinked: false }), // isAssigned: true,

		// isLinked: false,
		orderDescending,
	};

	const eventsFilters = {
		deviceType: deviceDeviceTypeFilter.value,
		firmwareVersionFilter: deviceFwFilter.value,
		unlockTypeFilter: deviceUnlockTypeFilter.value,
		shouldFilter: shouldFilter,
		setShouldFilter: setShouldFilter,
		pageNumber: pagination.pageNumber,
		organisationFilter: deviceOrganisationFilter.valueId,
	};

	useEffect(() => {
		if (!unassignDevicesLoading) {
			setOpenUnassignDevices(false);
		}
	}, [unassignDevicesLoading]);

	useEffect(() => {
		if (
			!devicesLoading &&
			(pagination.fetch ||
				!isObject(devicesData) ||
				assigned ||
				unassigned ||
				shouldSearch ||
				shouldSort ||
				sortingById ||
				linkDeviceToItemGroupDone ||
				unlinkItemGroupSuccess ||
				unlinkAllItemGroupSuccess ||
				assignDevicesDone ||
				unassignDevicesDone ||
				shouldFilter ||
				(!fetchLinkedDevicesLoading && unlinkDevicesDone) ||
				(!fetchLinkedDevicesLoading && linkDevicesDone))
		) {
			onFetchDevices(pagination.page, filters);
			setSelectedDevices(null);
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [
		assigned,
		unassigned,
		unlinkItemGroupSuccess,
		unlinkAllItemGroupSuccess,
		linkDeviceToItemGroupDone,
		pagination.fetch,
		shouldSearch,
		sortingById,
		order,
		assignDevicesDone,
		unassignDevicesDone,
		shouldFilter,
		unlinkDevicesDone,
		linkDevicesDone,
	]);

	useEffect(() => {
		if (isObject(devicesData) && !devicesLoading) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [devices]);

	const handleRequestSort = (property) => {
		const isDesc = sortingById === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortingById(property);
		setShouldSort(true);
	};

	const handleAssignDevicesClose = () => {
		setOpenAssignDevices(false);
	};

	const handleLinkDevicesClose = () => {
		setOpenLinkDevices(false);
	};

	const handleDeviceTypeFilter = (event) => {
		const value = event.target.value;
		if (value !== deviceDeviceTypeFilter.value) {
			deviceDeviceTypeFilter.setValue(value);
			setShouldFilter(true);
		}
		pagination.setPageNumber(1);
	};

	const handleFirmwareVersionFilter = (event) => {
		const value = event.target.value;
		if (value !== deviceFwFilter.value) {
			deviceFwFilter.setValue(value);
			setShouldFilter(true);
		}
		pagination.setPageNumber(1);
	};

	const handleUnlockTypeFilter = (event) => {
		const value = event.target.value;
		if (value !== deviceUnlockTypeFilter.value) {
			deviceUnlockTypeFilter.setValue(value);
			setShouldFilter(true);
		}
		pagination.setPageNumber(1);
	};

	const handleChangeOrganisationFilterSelect = (event) => {
		if (event.id === deviceOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(event.id)) {
			deviceOrganisationFilter.setValueName(event.name);
		} else if (isEmptyString(event.id)) {
			deviceOrganisationFilter.setValueName('');
		}
		deviceOrganisationFilter.setValueId(event.id);
		setShouldFilter(true);
		pagination.setPageNumber(1);
	};

	const deviceFilter = [
		{
			value: 'all',
			label: t('ui.label.allDevices'),
		},
	];

	const mainFilters = [
		{
			events: { onChange: (e) => handleDeviceTypeFilter(e) },
			value: deviceDeviceTypeFilter.value,
			selectOptions: deviceFilter.concat(
				typesFilterReady ?
					typesFilterData.map((type) => ({
						value: type,
						label: handleLockType(type),
					}))
				:	[],
			),
		},
		{
			events: { onChange: (e) => handleUnlockTypeFilter(e) },
			value: deviceUnlockTypeFilter.value,
			selectOptions: [
				{
					value: 'unlockType',
					label: t('views.organisationManagement.devices.unlockType'),
				},
			].concat(
				unlockTypesFilterReady ?
					unlockTypesFilterData.map((type) => ({
						value: type,
						label: handleDeviceTypes(type),
					}))
				:	[],
			),
		},
		{
			events: { onChange: (e) => handleFirmwareVersionFilter(e) },
			value: deviceFwFilter.value,
			selectOptions: [
				{
					value: 'fw',
					label: t('ui.label.FW'),
				},
			].concat(
				firmWarVersionsFilterReady ?
					firmWarVersionsFilterData.map((firmWar) => ({
						value: firmWar,
						label: firmWar,
					}))
				:	[],
			),
		},
		...(assigned && isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleChangeOrganisationFilterSelect,
						searchHandle: deviceOrganisationFilter.setValueName,
						filter: 'verified',
					},
					value: deviceOrganisationFilter.valueName,
					dataList: fetchDevicesOrganisations,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchDevicesOrganisations,
					listType: 'hubs',
					defaultListItem: {
						id: '',
						name: t('ui.filter.organisations.all'),
					},
				},
			]
		:	[]),
	];

	const handleClearFilters = () => {
		deviceUnlockTypeFilter.setValue('unlockType');
		deviceFwFilter.setValue('fw');
		deviceDeviceTypeFilter.setValue('all');
		deviceOrganisationFilter.setValueName('');
		deviceOrganisationFilter.setValueId('all');
		showUnusedDevicesFilter.setValue(false);
		search.events.onClear();
		setShouldFilter(true);
		pagination.resetPagination();
		paginationLinked.resetPagination();
	};

	const clearFilters = {
		clear:
			paginationLinked.pageNumber !== 1 ||
			paginationLinked.pageSize !== 10 ||
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			deviceUnlockTypeFilter.value !== 'unlockType' ||
			deviceFwFilter.value !== 'fw' ||
			deviceOrganisationFilter.valueId !== 'all' ||
			deviceDeviceTypeFilter.value !== 'all' ||
			showUnusedDevicesFilter.value,
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const extraButtons = [
		...(!isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					text: t('views.devices.linkDevices.linkToItem'),
					onClick: () => {
						setOpenLinkDevices(true);
					},
					variant: 'contained-primary',
				},
			]
		:	[]),
	];

	const extraSwitch = [
		...(assigned ?
			[
				{
					switchLabel: t('views.devices.filter.showLinkedDevices'),
					actions: (event) => showUnusedDevicesFilter.setValue(event.target.checked),
					checked: showUnusedDevicesFilter.value,
				},
			]
		:	[]),
	];

	const editActionButtons = [
		...(unassigned && isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					icon: <DeviceHubIcon className={classes.buttonIcon} />,
					text: t('views.devices.assignDevices'),
					action: (devicesValue) => {
						setOpenAssignDevices(true);
						setSelectedDevices(devicesValue);
					},
					variant: 'inline-default',
				},
			]
		:	[]),
		...(assigned && isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					icon: <ShareIcon className={classes.buttonIcon} color='error' />,
					text: t('views.devices.unassignDevices'),
					action: (devicesValue) => {
						setOpenUnassignDevices(true);
						setSelectedDevices(devicesValue);
					},
				},
			]
		:	[]),
	];

	const handleUnlinkDevices = (data) => {
		setSelectedDevices(data);
		setOpenLinkDevices(true);
	};

	const handleCloseDialog = () => {
		setOpenUnassignDevices(false);
	};

	const handleUnassignDevices = () => {
		if (isFullArray(selectedDevices)) {
			const devices = selectedDevices.map((item) => item.deviceId);
			onUnassignDevices(devices);
			unassignDevicesMessage.setStartAction(true);
		}
	};

	const createMenuItems = (item) => [
		{
			icon: <LinkIcon />,
			text: t('views.devices.linkDevices.button.linkToGroup'),
			action: () => handleUnlinkDevices({ ...item, link: 'itemGroup' }),
		},
		{
			icon: <LinkIcon />,
			text: t('views.devices.linkDevices.button.linkItem'),
			action: () => handleUnlinkDevices({ ...item, link: 'item' }),
		},
	];

	const tableHeaders =
		!loading && isObject(devicesData) && isEmptyArray(devicesData.results) ?
			[]
		:	[
				{ name: 'id', content: t('ui.label.HwUID'), hasSorting: true },
				{ name: 'name', content: t('nav.category.devices'), hasSorting: true },
				{
					name: 'unlockType',
					content: t('views.organisationManagement.devices.unlockType'),
					hasSorting: false,
				},
				{
					name: 'firmware',
					content: t('views.organisationManagement.devices.firmware'),
					hasSorting: false,
				},
				...(isSuperAdmin(auth.user?.profile.role) && assigned ?
					[{ name: 'organisation', content: t('ui.organisation'), hasSorting: false }]
				:	[]),
				{ name: 'battery', content: t('ui.label.battery'), hasSorting: true },
				{ name: 'deviceState', content: t('ui.label.deviceStatus'), hasSorting: false },
				{ name: 'lastOnline', content: t('ui.label.lastOnline'), hasSorting: true },
			];

	const loadingBody =
		loading ?
			Array(3)
				.fill(Array(tableHeaders.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody =
		isObject(devicesData) && isEmptyArray(devicesData.results) ?
			[
				[
					{
						content: (
							<Paper className={classes.tipCard}>
								<InfoOutlinedIcon className={classes.icon} fontSize='small' />
								<Typography className={classes.tip}>
									{assigned ?
										t('views.devices.devices.emptyBody.noDevicesToLink')
									:	t('views.devices.devices.emptyBody.noUnassigned')}
								</Typography>
							</Paper>
						),
					},
				],
			]
		:	null;

	const handleLinks = (path, label) => (
		<Link
			className={classes.itemGroup}
			component={RouterLink}
			to={path}
			state={{ from: location.pathname }}
		>
			{label}
		</Link>
	);

	const tableBody =
		!loading && isObject(devicesData) && !isEmptyArray(devicesData.results) ?
			devicesData.results.map((device) => [
				{
					content: handleLinks(`/devices/${device.deviceId}/summary`, device.deviceId),
					classCell: classes.cellStyle,
				},
				{ content: handleLockType(device.deviceType), classCell: classes.cellStyle },
				{ content: <Tooltip titles={device.identificationType} />, classCell: classes.cellStyle },
				{
					content: isSuperAdmin(auth.user?.profile.role) && assigned && device.firmwareVersion,
					classCell: classes.cellStyle,
				},
				...(isSuperAdmin(auth.user?.profile.role) && assigned ?
					[
						{
							content:
								device?.organisationReference &&
								handleLinks(
									`/organisations/${device.organisationReference.id}/summary`,
									device.organisationReference.name,
								),
							classCell: classes.longCellStyle,
						},
					]
				:	[]),
				{
					content:
						!isUndefined(device.batteryLevel) &&
						(device.deviceType === 'doorLock' ?
							convertBatteryLevelToEnum(device.batteryLevel)
						:	device.batteryLevel),
					classCell: classes.batteryLevelCell,
				},
				{
					content: t(`ui.label.device.state.${device.deviceState}`),
					classCell: classes.cellStyle,
				},
				{
					content: (
						<Box
							alignItems='center'
							display='flex'
							flexDirection={assigned ? 'row-reverse' : ''}
							justifyContent='space-between'
							maxHeight='20px'
						>
							{assigned ?
								<Box pl={1}>
									<GenericMoreButton menuItems={createMenuItems(device)} />
								</Box>
							:	null}
							{!isUndefined(device.lastUpdated) ? commaTimeStrings(device.lastUpdated) : '-'}
						</Box>
					),
					classCell: classes.lastUpdatedCell,
				},
			])
		:	null;

	const handleTableTitle = () => {
		if (!isSuperAdmin(auth.user?.profile.role)) {
			return `${devicesData ? devicesData.total : 0} ${t('views.devices.unlinkedDevices')}`;
		} else if (unassigned) {
			return `${devicesData ? devicesData.total : 0} ${t('views.devices.unassignedDevices')}`;
		} else {
			return `${devicesData ? devicesData.total : 0} ${t('views.devices.unlinkedDevices')}`;
		}
	};

	return (
		<>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				extraSwitch={extraSwitch}
				hasExtraButtons
				hasMainFilter
				hideMoreFilters
				mainFilters={mainFilters}
				placeholder={t('ui.placeholders.search.generic')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{isSuperAdmin(auth.user?.profile.role) && assigned ?
				<Box pb={3}>
					<LinkedDevicesTable
						eventsFilters={eventsFilters}
						paginationLinked={paginationLinked}
						search={search}
						setShouldSearch={setShouldSearch}
						shouldSearch={shouldSearch}
					/>
				</Box>
			:	null}
			{!showUnusedDevicesFilter.value ?
				<Table
					body={loadingBody || emptyBody || tableBody}
					cellStyle={classes.cellStyle}
					data={isObject(devicesData) ? devicesData.results : []}
					editActionButtons={editActionButtons}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					hasSelectionEnabled={
						isEmptyResults || !isSuperAdmin(auth.user?.profile.role) ? false : true
					}
					header={tableHeaders}
					isNotPaginate={loading || isEmptyResults || !isFullResults}
					loading={devicesLoading}
					loadingTest={devicesLoading}
					order={order}
					orderBy={sortingById}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={handleTableTitle()}
					total={devicesData ? devicesData.total : 0}
				/>
			:	null}

			{isSuperAdmin(auth.user?.profile.role) ? null : (
				<Box>
					<LinkedDevicesTable
						eventsFilters={eventsFilters}
						mainFilters={mainFilters}
						search={search}
						setShouldSearch={setShouldSearch}
						shouldSearch={shouldSearch}
					/>
				</Box>
			)}
			<AssignDevices
				onClose={handleAssignDevicesClose}
				open={openAssignDevices}
				selectedDevices={selectedDevices}
				setSelectedDevices={setSelectedDevices}
				unassigned={unassigned}
			/>
			<LinkDevices
				id={isObject(selectedDevices) ? selectedDevices.organisationReference.id : null}
				onClose={handleLinkDevicesClose}
				open={openLinkDevices}
				selectedDevices={selectedDevices}
				setSelectedDevices={setSelectedDevices}
				unassigned={false}
			/>
			{openUnassignDevices ?
				<ActionDialog
					actionButtonProps={{
						action: handleUnassignDevices,
						text:
							unassignDevicesLoading ?
								<CircularProgress disableShrink size={24} />
							:	t('ui.confirm'),
					}}
					handleClose={handleCloseDialog}
					loading={false}
					open={openUnassignDevices}
					title={t('views.devices.unassignDevices')}
				>
					<Typography variant='body2'>{t('views.devices.dialog.unassign')}</Typography>
				</ActionDialog>
			:	null}
		</>
	);
};

DevicesList.propTypes = {
	assigned: PropTypes.bool,
	unassigned: PropTypes.bool,
	onFetchDevices: PropTypes.func,
	onUnassignDevices: PropTypes.func,
	onFetchDevicesOrganisations: PropTypes.func,
	devices: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	assignDevices: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	unassignDevices: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	linkDevices: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	unlinkDevices: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchLinkedDevices: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	devicesTypesFilter: PropTypes.PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	devicesFirmWarVersionsFilter: PropTypes.PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchDevicesOrganisations: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	devicesUnlockTypesFilter: PropTypes.PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	linkDeviceToItemGroup: PropTypes.PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	unlinkItemGroup: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	unlinkAllItemGroup: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
		devices: state.paged.devices,

		assignDevices: state.details.assignDevices,
		unassignDevices: state.details.unassignDevices,
		linkDevices: state.details.linkDevices,
		unlinkDevices: state.condition.unlinkDevices,
		fetchLinkedDevices: state.paged.fetchLinkedDevices,
		devicesTypesFilter: state.list.devicesTypesFilter,
		devicesFirmWarVersionsFilter: state.list.devicesFirmWarVersionsFilter,
		fetchDevicesOrganisations: state.paged.fetchDevicesOrganisations,
		devicesUnlockTypesFilter: state.list.devicesUnlockTypesFilter,
		linkDeviceToItemGroup: state.details.linkDeviceToItemGroup,
		unlinkItemGroup: state.condition.unlinkItemGroup,
		unlinkAllItemGroup: state.condition.unlinkAllItemGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchDevices: (page, filters) => dispatch(actions.fetchDevices(page, filters)),
		onUnassignDevices: (devices) => dispatch(actions.unassignDevices(devices)),
		onFetchDevicesOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchDevicesOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesList);
