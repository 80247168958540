import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
	root: {
		'& .DateRangePickerInput': {
			...theme.typography.h6,
			display: 'flex',
			alignItems: 'center',
		},
		transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		boxShadow: '1px 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
		borderRadius: 24,
		maxHeight: 44,
		paddingBottom: theme.spacing(1),
		'& .DateInput_input.DateInput_input_1': {
			display: 'flex',
			alignSelf: 'center',
			padding: '8px 2px 5px 2px',
			fontSize: '12px',
			fontWeight: '600',
			cursor: 'pointer',
			textAlign: 'center',
		},
		'& .CalendarMonth_caption': {
			paddingBottom: '39px',
		},
		'& .DateInput_input__focused': {
			borderBottom: '2px solid #15263E',
		},
		'& .DayPickerKeyboardShortcuts_show__bottomRight': {
			display: 'none',
		},
		'& .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2':
			{
				margin: '0',
				display: 'flex',
				justifyContent: 'space-between',
				boxShadow: '0 0 0 1px rgba(63,63,68,0.05) 0 1px 3px 0 rgba(63,63,68,0.15)',
				alignSelf: 'center',
				borderRight: '1px #15263E',
				borderRadius: '24px',
			},
		'& .DateRangePickerInput__withBorder': {
			border: 'none',
		},

		'& .CalendarDay__selected': {
			background: '#15263E',
			border: '1px double #15263E',
			'&:active': {
				background: '#15263E',
				border: '1px double #15263E',
			},
			'&:hover': {
				background: '#15263E',
				border: '1px double #15263E',
			},
		},
		'& .CalendarDay__selected_span': {
			background: 'rgba(2, 119, 189, 0.7)',
			border: '1px double #15263E',
			'&:active': {
				background: 'rgba(2, 119, 189, 0.7)',
				border: '1px double #15263E',
			},
			'&:hover': {
				background: 'rgba(2, 119, 189, 0.7)',
				border: '1px double #15263E',
			},
		},
		'& .CalendarDay__hovered_span': {
			border: '1px double #15263E',
			background: 'rgba(2, 119, 189, 0.7)',
			'&:hover': {
				background: '#15263E',
				border: '1px double #15263E',
				color: '#fff',
			},
		},
	},
	timePickerContainer: {
		marginLeft: theme.spacing(2),
		cursor: 'poin',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	timePickerComponent: {
		minHeight: '31px',
		zIndex: 2,
		padding: theme.spacing(0, 2),
		margin: theme.spacing(0, 2),
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));
