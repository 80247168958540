import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	tipToggle: {
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		fontWeight: '500',
		color: '#586b85',
		marginBottom: '1rem',

		'&:hover': {
			cursor: 'pointer',
			color: '#5B6778',
		},
	},
	tipCard: {
		backgroundColor: colors.indigo[50],
		boxShadow: 'none',
		padding: '1rem',
		position: 'relative',
	},
	arrowTop: {
		'&:before': {
			content: '""',
			width: 0,
			height: 0,
			borderLeft: '10px solid transparent',
			borderRight: '10px solid transparent',

			borderBottom: `10px solid ${colors.indigo[50]}`,

			position: 'absolute',
			top: '-10px',
		},
	},
	arrowLeft: {
		maxWidth: '25rem',
		'&:before': {
			content: '""',
			width: 0,
			height: 0,
			borderTop: '10px solid transparent',
			borderBottom: '10px solid transparent',

			borderRight: `10px solid ${colors.indigo[50]}`,

			position: 'absolute',
			right: '100%',
		},
	},
	iconContainer: {
		display: 'flex',
		marginRight: '.5rem',
	},
}));
