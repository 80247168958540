export const consumersReducer = (state, action) => {
	const { consumers, item, items } = action;

	switch (action.type) {
		case 'add':
			return state.concat(
				consumers.map((consumer) => ({
					...consumer,
					isShowingItems: false,
					isLoadingItems: true,
					selectedItems: [],
					itemsPage: { pageNumber: 1, pageSize: 5 },
					items: [],
				})),
			);
		case 'addItems':
			return state.map((consumer) =>
				consumer.id === item.consumerId ?
					{
						...consumer,
						isLoadingItems: false,
						items: items.map((cItem) =>
							(
								consumer.selectedItems.find(
									(selectedItem) => selectedItem.item.id === cItem.item.id,
								)
							) ?
								{ ...cItem, selected: true, pageNumber: consumer.itemsPage.pageNumber }
							:	{ ...cItem, pageNumber: consumer.itemsPage.pageNumber },
						),
						total: item.total,
					}
				:	{ ...consumer },
			);
		case 'addInternalAndPublicItems':
			return state.map((consumer) =>
				consumer.type === item.consumerType ?
					{
						...consumer,
						isLoadingItems: false,
						items: items.map((cItem) =>
							(
								consumer.selectedItems.find(
									(selectedItem) => selectedItem.item.id === cItem.item.id,
								)
							) ?
								{ ...cItem, selected: true, pageNumber: consumer.itemsPage.pageNumber }
							:	{ ...cItem, pageNumber: consumer.itemsPage.pageNumber },
						),
						total: item.total,
					}
				:	{ ...consumer },
			);
		case 'toggleShowingItems':
			return state.map((consumer) =>
				consumer.id === item.consumerId && consumer.type === item.type ?
					{ ...consumer, isShowingItems: !item.isExpanded }
				:	{ ...consumer },
			);
		case 'updateItemsPage':
			return state.map((consumer) =>
				consumer.id === item.consumerId && consumer.type === item.type ?
					{
						...consumer,
						isLoadingItems: true,
						isShowingItems: true,
						itemsPage:
							item?.pageSize ?
								{ pageNumber: 1, pageSize: item.pageSize }
							:	{ ...consumer.itemsPage, pageNumber: item.pageNumber },
					}
				:	{ ...consumer },
			);
		case 'selectAllItems':
			return state.map((consumer) =>
				consumer.id === item.consumerId && consumer.type === item.type ?
					{
						...consumer,
						items: consumer.items.map((cItem) =>
							cItem.canEdit && cItem.pageNumber === consumer.itemsPage.pageNumber ?
								{
									...cItem,
									selected: item.selecteAll,
									pageNumber: consumer.itemsPage.pageNumber,
								}
							:	cItem,
						),
						selectedItems: consumer.selectedItems.concat(
							item.items.filter((sItem) => sItem.pageNumber === consumer.itemsPage.pageNumber),
						),
						selecteAll: item.selecteAll,
					}
				:	{ ...consumer },
			);
		case 'selectItem':
			return state.map((consumer) =>
				consumer.id === item.consumerId && consumer.type === item.type ?
					{
						...consumer,
						selectedItems:
							item?.selected ?
								consumer.selectedItems.concat([
									{
										item: item.item,
										userGroup: item.userGroup,
										pageNumber: consumer.itemsPage.pageNumber,
									},
								])
							:	consumer.selectedItems.filter(
									(selectedItem) =>
										!(
											selectedItem.item.id === item.item.id &&
											item.userGroup?.id === selectedItem.userGroup?.id
										),
								),
						items: consumer.items.map((consumerItem) =>
							(
								consumerItem.item.id === item.item.id &&
								consumerItem.userGroup?.id === item.userGroup?.id
							) ?
								{
									...consumerItem,
									selected: item?.selected,
								}
							:	consumerItem,
						),
					}
				:	{ ...consumer },
			);
		case 'removeAllItems':
			return state.map((consumer) =>
				consumer.id === item.consumerId && consumer.type === item.type ?
					{
						...consumer,
						selectedItems: consumer.selectedItems.filter(
							(rItme) => rItme.pageNumber !== consumer.itemsPage.pageNumber,
						),
						items: consumer.items.map((reItem) => ({ ...reItem, selected: false })),
					}
				:	{ ...consumer },
			);
		default:
			return [];
	}
};
