import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	mainActions: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	notice: {
		marginTop: theme.spacing(1),
	},
	deleteButton: {
		marginTop: theme.spacing(1),
	},
	contact: {
		margin: theme.spacing(2, 2),
	},
	rejectButton: {
		color: theme.palette.white,
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
	subtitle: {
		margin: theme.spacing(2, 0),
	},
	organisationContainer: {
		margin: theme.spacing(5, 0),
	},
	subscriptionSelect: {
		paddingBottom: theme.spacing(2),
	},
	alertOrganisationName: {
		marginLeft: theme.spacing(2),
	},
	counterText: {
		color: theme.palette.grey[400],
	},
	rejectionAlertContent: {
		padding: theme.spacing(0, 0, 3),
	},
	avatar: {
		'& img': {
			objectFit: 'scale-down',
		},
	},
}));
