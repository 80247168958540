import { useEffect, useRef } from 'react';

/**
 * A hook to perform a callback for function with a delay
 * @returns
 */
const useDebounceCallback = () => {
	// track args & timeout handle between calls
	const timeout = useRef<ReturnType<typeof setTimeout>>();

	// make sure our timeout gets cleared if
	// our consuming component gets unmounted
	useEffect(() => {
		return () => {
			clearTimeout(timeout.current);
		};
	}, []);

	/**
	 *
	 * @param func The debounceable callback function
	 */
	const debounceCallback = (func: () => void, delay = 275) => {
		// Clean the old function before setting the new one
		clearTimeout(timeout.current);

		timeout.current = setTimeout(func, delay);
	};

	return debounceCallback;
};

export default useDebounceCallback;
