import { useState, useEffect } from 'react';

import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UpdatePerDay from './UpdatePerDay';
import { InfoLabel, Tip } from '../../../../../components';
import { modifyDate } from '../../../../../shared/datetime';
import { isObject } from '../../../../../shared/utility';
import CalendarDetails from '../CalendarDetails';
import Header from '../Header';

const PREFIX = 'PerDay';

const classes = {
	root: `${PREFIX}-root`,
	calendarComponents: `${PREFIX}-calendarComponents`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
	[`& .${classes.root}`]: {},

	[`& .${classes.calendarComponents}`]: {
		'& .fc-scroller.fc-time-grid-container': {
			display: 'none',
		},
		'& .fc-divider.fc-widget-header': {
			display: 'none',
		},
	},
}));

const curr = new Date();
const firstday = modifyDate(new Date(), { date: `-${curr.getDay()}` });

const PerDay = (props) => {
	const {
		className,
		perDayValue,
		save,
		editing,
		pricingPerDayModel,
		eventColors,
		createPricingModel,
	} = props;
	const { t } = useTranslation('general');

	const [pricingPerDay, setPricingPerDay] = useState(null);
	const [eventsArray, setEventsArray] = useState(
		pricingPerDayModel.reduce((acc, item) => {
			return [
				...acc,
				...item.days.map((day) => ({
					id: item.id + day.id,
					name: `${item.price} / ${item.periodType}`,
					start: modifyDate(firstday, { hours: 0, minutes: 0, date: `+${day.id}` }),
					end: modifyDate(firstday, { hours: 0, minutes: 0, date: `+${day.id + 1}` }),
					color: item.price === 0 ? eventColors.free : eventColors.fixed,
					title: item.price === 0 ? t('ui.free') : `€${item.price}/${t('ui.daysShort')}`,
					price: item.price,
				})),
			];
		}, []),
	);

	useEffect(() => {
		setEventsArray(
			pricingPerDayModel.reduce((acc, item) => {
				return [
					...acc,
					...item.days.map((day) => ({
						id: item.id,
						name: `${item.price} / ${item.periodType}`,
						start: modifyDate(firstday, { hours: 0, minutes: 0, date: `+${day.id}` }),
						end: modifyDate(firstday, { hours: 0, minutes: 0, date: `+${day.id + 1}` }),
						color: item.price === 0 ? eventColors.free : eventColors.fixed,
						title: item.price === 0 ? t('ui.free') : `€${item.price}/${t('ui.daysShort')}`,
						price: item.price,
					})),
				];
			}, []),
		);
	}, [pricingPerDayModel]);

	const handleCloseDrawer = () => {
		setPricingPerDay(null);
	};

	const handleSelect = (selectionInfo) => {
		setPricingPerDay({
			start: selectionInfo.start,
			end: selectionInfo.end,
			allDay: true,
			price: 0.0,
		});
	};

	const handleClickEvent = (info) => {
		const clickedEvent = eventsArray.find((item) => item.id === parseInt(info.event.id, 10));
		const dayOfWeek = info.event.start.getDay();
		setPricingPerDay({
			id: clickedEvent.id,
			start: modifyDate(info.event.start, { hours: 0, minutes: 0 }),
			end: modifyDate(info.event.start, { hours: 0, minutes: 0, date: '+1' }),
			allDay: true,
			price: clickedEvent.price,
			dayOfWeek: dayOfWeek,
		});
	};

	const fullCalendarEvents = {
		select: handleSelect,
		clickEvent: handleClickEvent,
		// resizeEvent: handleResizeEvent
	};

	return (
		<Root>
			<Header editing={editing} />
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<InfoLabel
						info={t('views.addPricing.perDay.infoPer')}
						name={t('views.addPricing.perDay.infoName')}
						paddingBottom={4.5}
						paddingTop={1.5}
						variant='h4'
					/>
					<Tip message={t('views.addPricing.perDay.tip')} />
					<CalendarDetails
						allDayText={t('ui.price')}
						className={classes.calendarComponents}
						constraint={{}}
						eventArray={eventsArray}
						events={fullCalendarEvents}
						periodLabel={perDayValue.label}
					/>
					{isObject(pricingPerDay) ?
						<UpdatePerDay
							createPricingModel={createPricingModel}
							onClose={handleCloseDrawer}
							open={isObject(pricingPerDay)}
							periodLabel={perDayValue.label}
							periodType={perDayValue.name}
							pricingPerDay={pricingPerDay}
							pricingPerDayModel={pricingPerDayModel}
							save={save}
							setPricingPerDay={setPricingPerDay}
						/>
					:	null}
				</CardContent>
			</Card>
		</Root>
	);
};

PerDay.propTypes = {
	className: PropTypes.string,
	perDayValue: PropTypes.object,
	save: PropTypes.func,
	editing: PropTypes.bool,
	pricingPerDayModel: PropTypes.array,
	eventColors: PropTypes.object,
	createPricingModel: PropTypes.func,
};

export default PerDay;
