import { useState, useEffect } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Typography,
	List,
	ListItem,
	IconButton,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import SelectUserGroups from './SelectUserGroups';
import { useStyles } from './style';
import {
	Wizard,
	SidepanelContainer,
	SidePanel,
	LoadingBar,
	StyledButton,
} from '../../../../components';
import { useItemInstanceSelection } from '../../../../shared/hooks';
import {
	isEmptyArray,
	isFullString,
	isUndefined,
	isArray,
	isObject,
	instanceName,
	isEmptyObject,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

const AssignItemsToUserGroups = (props) => {
	const {
		assignedItemsToUserGroup,
		onAssignItemsToUserGroup,
		onFetchItemInstancesList,
		itemInstances,
	} = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const navigate = useNavigate();
	const auth = useAuth();

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const role = auth.user?.profile.role.toLowerCase();
	const superAdmin = isFullString(role) && role === 'superadmin';

	const itemSelection = useItemInstanceSelection(
		location.state && location.state.selectedItems ? location.state.selectedItems : [],
	);

	const [selectedUserGroups, setSelectedUserGroups] = useState(
		location.state && location.state.selectedUserGroup ? [location.state.selectedUserGroup] : [],
	);
	const [itemToFetchInstancesOf, setItemToFetchInstancesOf] = useState();
	const [fetchingInstancesForSummary, setFetchingInstancesForSummary] = useState(false);
	const [selectedOrganisation, setSelectedOrganisation] = useState({});
	const { data: instancesData, loading: instancesLoading, error: instancesError } = itemInstances;
	const instancesReady = isArray(instancesData) && !instancesLoading && !instancesError;

	useEffect(() => {
		if (fetchingInstancesForSummary && instancesReady) {
			itemSelection.setSelection(
				itemSelection.selection.map((sItem) => ({
					...sItem,
					selectedInstances:
						sItem.item.id === itemToFetchInstancesOf ? instancesData : sItem.selectedInstances,
				})),
			);
			setFetchingInstancesForSummary(false);
		}
	}, [fetchingInstancesForSummary, instancesReady]);

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);
	const [shouldSubmitSelection, setShouldSubmitSelection] = useState(false);
	const [submittingSelection, setSubmittingSelection] = useState(false);
	const [updatedUserGroupCounter, setUpdatedUserGroupCounter] = useState(0);

	const { data: assignedItems, error: assignedItemsError } = assignedItemsToUserGroup;
	const [initialAssignedItems, setInitialAssignedItems] = useState(false);

	const assignItemsDone =
		updatedUserGroupCounter === selectedUserGroups.length && !isEmptyArray(selectedUserGroups);

	useEffect(() => {
		if (shouldSubmitSelection && !submittingSelection) {
			setSubmittingSelection(true);
			const itemsArray = itemSelection.selection.map((sItem) => ({
				itemId: sItem.item.id,
				instanceIds: sItem.selectedInstances.map((sInstance) => sInstance.id),
			}));
			selectedUserGroups.forEach((userGroup) => {
				onAssignItemsToUserGroup(userGroup.id, { items: itemsArray });
			});
		}
	}, [shouldSubmitSelection, submittingSelection]);

	//Each time items are assigned to a specific user group
	useEffect(() => {
		if (assignedItems && submittingSelection) {
			if (initialAssignedItems) {
				setInitialAssignedItems(false);
			} else {
				//success notifications
				if (!isEmptyArray(assignedItems.accepted)) {
					enqueueSnackbar(
						`${t('views.assignItemsToUserGroup.messages.success.accepted.partOne')} ${assignedItems.accepted.length} ${t('views.assignItemsToUserGroup.messages.success.accepted.partTwo')} ${assignedItems.name}`,
						{ variant: 'success' },
					);
				}

				if (!isEmptyArray(assignedItems.rejected)) {
					enqueueSnackbar(
						`${t('views.assignItemsToUserGroup.messages.success.rejected')} ${assignedItems.name}`,
					);
				}
				//up counter
				setUpdatedUserGroupCounter(updatedUserGroupCounter + 1);
			}
		}
	}, [assignedItems, submittingSelection]);

	useEffect(() => {
		if (submittingSelection && assignedItemsError) {
			enqueueSnackbar(
				isObject(assignedItemsError) ? assignedItemsError.message : assignedItemsError,
				{ variant: 'error' },
			);
			setStartedFinishWizard(false);
		}
	}, [submittingSelection, assignedItemsError]);

	useEffect(() => {
		if (assignItemsDone) {
			navigate(-1);
		}
	}, [assignItemsDone]);

	const handleClickRemoveUserGroup = (userGroupId) => {
		setSelectedUserGroups(selectedUserGroups.filter((sUserGroup) => sUserGroup.id !== userGroupId));
	};

	const handleClickShowInstancesInSummary = (item) => {
		itemSelection.setSelection(
			itemSelection.selection.map((selectedItem) => ({
				...selectedItem,
				showInstances:
					item.item.id === selectedItem.item.id ?
						!selectedItem.showInstances
					:	selectedItem.showInstances,
			})),
		);

		if (item.selectedInstances.length === 0) {
			onFetchItemInstancesList(item.item.id);
			setItemToFetchInstancesOf(item.item.id);
			setFetchingInstancesForSummary(true);
		}
	};

	const callback = (saved) => {
		const { selectedUserGroups: userGroupsToSave, savedOrganisation, finishedWizard } = saved;

		if (!isUndefined(userGroupsToSave)) {
			setSelectedUserGroups(userGroupsToSave);
		}

		if (finishedWizard && !startedFinishWizard) {
			setStartedFinishWizard(true);
			setShouldSubmitSelection(true);
		}
		if (!isEmptyObject(savedOrganisation)) {
			setSelectedOrganisation(savedOrganisation);
		}
	};

	// const handleCancel = () => {
	//   navigate(`/user-management/user-groups/${id}/items`);
	// };

	const assignItemSteps = [
		{
			name: 'Assign Items Step',
			content: SelectUserGroups,
			valid: !isEmptyArray(selectedUserGroups),
			props: {
				selectedUserGroups,
				selectedOrganisation,
			},
		},
	];

	const sidepanel = (
		<SidepanelContainer>
			<SidePanel title={<Typography variant='h4'>{t('ui.summary')}</Typography>}>
				<Accordion className={classes.expansionPanel} defaultExpanded elevation={0} square>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant='h5'>{t('ui.category.items')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{!isEmptyArray(itemSelection.selection) ?
							<List className={classes.sidepanelList}>
								{itemSelection.selection.map((selectedItem) => (
									<ListItem className={classes.item} key={selectedItem.item.id}>
										<div className={classes.itemText}>
											<Typography variant='h5'>{selectedItem.item.name}</Typography>
											<Typography>
												{t('ui.label.category') + ': ' + selectedItem.item.categoryReference.name}
											</Typography>
											<StyledButton
												className={classes.inlineButton}
												endIcon={
													selectedItem.showInstances ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
												}
												onClick={() => handleClickShowInstancesInSummary(selectedItem)}
												variant='inline-default'
											>
												{`${t('ui.button.inline.qty')}: ${selectedItem.selectedInstances.length > 0 ? selectedItem.selectedInstances.length : selectedItem.item.totalItemInstances}/${selectedItem.item.totalItemInstances}`}
											</StyledButton>
											{selectedItem.showInstances ?
												selectedItem.selectedInstances.length > 0 ?
													<List className={classes.instancesList}>
														{selectedItem.selectedInstances.map((sInstance) => (
															<ListItem
																className={classes.instance}
																key={`summary-item-${selectedItem.item.id}-instance-${sInstance.id}`}
															>
																<Typography>{instanceName(sInstance)}</Typography>
																<IconButton
																	onClick={() =>
																		itemSelection.handleRemoveItemFromSidebar(
																			selectedItem.item,
																			sInstance,
																		)
																	}
																	size='small'
																>
																	<CloseIcon />
																</IconButton>
															</ListItem>
														))}
													</List>
												:	Array(5)
														.fill({})
														.map((_, index) => (
															<ListItem
																key={`item-${selectedItem.item.id}-instance-loading-${index}`}
															>
																<LoadingBar />
															</ListItem>
														))

											:	null}
										</div>
										<IconButton
											onClick={() => itemSelection.handleRemoveItemFromSidebar(selectedItem.item)}
											size='small'
										>
											<CloseIcon />
										</IconButton>
									</ListItem>
								))}
							</List>
						:	<Typography>
								{t('views.assignItemsToUserGroup.sidebar.list.itemInstances.empty')}
							</Typography>
						}
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion className={classes.expansionPanel} defaultExpanded elevation={0} square>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant='h5'>{t('ui.category.userGroups')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{!isEmptyArray(selectedUserGroups) ?
							<List className={classes.sidepanelList}>
								{selectedUserGroups.map((userGroup) => (
									<ListItem className={classes.item} key={userGroup.id}>
										<div className={classes.itemText}>
											<Typography variant='h6'>{userGroup.name}</Typography>
											{superAdmin ?
												<Typography>
													{t('ui.label.organisation') + ': ' + userGroup.organisationReference.name}
												</Typography>
											:	null}
										</div>
										<IconButton
											onClick={() => handleClickRemoveUserGroup(userGroup.id)}
											size='small'
										>
											<CloseIcon />
										</IconButton>
									</ListItem>
								))}
							</List>
						:	<Typography>
								{t('views.assignItemsToUserGroup.sidebar.list.userGroups.empty')}
							</Typography>
						}
					</AccordionDetails>
				</Accordion>
			</SidePanel>
		</SidepanelContainer>
	);

	return (
		<Wizard
			callback={callback}
			finishButtonLabel={t('views.assignItemsToUserGroup.button.finish')}
			loading={startedFinishWizard}
			// hasCancelButton={true}
			// handleCancel={handleCancel}
			sidebar={sidepanel}
			steps={assignItemSteps}
			title={t('views.assignItemsToUserGroup.page.title')}
		/>
	);
};

AssignItemsToUserGroups.propTypes = {
	assignedItemsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	assignedItemsToUserGroup: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	itemInstances: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	//functions
	onFetchUserGroup: PropTypes.func.isRequired,
	onFetchAssignedItems: PropTypes.func.isRequired,
	onAssignItemsToUserGroup: PropTypes.func.isRequired,
	onFetchItemInstancesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		assignedItemsList: state.paged.assignedItems,
		assignedItemsToUserGroup: state.details.assignedItemsToUserGroup,
		itemInstances: state.list.itemInstancesList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserGroup: (id) => dispatch(actions.fetchUserGroup(id)),
		onFetchAssignedItems: (userGroupId, page, concat) =>
			dispatch(actions.fetchAssignedItems(userGroupId, page, concat)),
		onAssignItemsToUserGroup: (userGroupId, bodyData) =>
			dispatch(actions.assignItemsToUserGroup(userGroupId, bodyData)),
		onFetchItemInstancesList: (itemId) => dispatch(actions.fetchItemInstancesList(itemId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignItemsToUserGroups);
