import { Modal, Card } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const ModalPopup = (props) => {
	const { className, onClose, open, children } = props;

	const classes = useStyles();

	if (!open) {
		return null;
	}

	return (
		<Modal onClose={onClose} open={open}>
			<Card className={clsx(classes.root, className)}>{children}</Card>
		</Modal>
	);
};

ModalPopup.propTypes = {
	onClose: PropTypes.func,
	className: PropTypes.string,
	children: PropTypes.object,
	open: PropTypes.bool,
};

export default ModalPopup;
