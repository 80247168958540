import path from 'path';

import { AxiosResponse } from 'axios';

import { DayOfWeekSchedule, IdReference, PagedResponse, WeeklySchedule } from '~interfaces';
import { IdReferenceRequest } from '~interfaces/requests';
import { BaseReferenceResponse } from '~interfaces/responses';
import { BaseServiceMapper, TopologyService } from '~services';
import { parseTime, time24Format } from '~utils/dateUtils';
import { dayOfWeekToWeeklySchedule, flattenWeeklySchedules } from '~utils/scheduleUtils';

import ParticipantTypeEnum from '../enums/participantTypeEnum';
import TargetTypeEnum from '../enums/targetTypeEnum';
import AccessRule from '../interfaces/accessRule';
import AccessRuleWriteable from '../interfaces/accessRuleWriteable';

class AccessRulesService extends TopologyService {
	public readonly path = 'accessrules';

	constructor() {
		super('v2');
	}

	/**
	 * Get a paginated list of access rules
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getAccessRules(
		page = 1,
		pageSize = 10,
		organisationId?: string,
	): Promise<PagedResponse<AccessRule> | null> {
		const { data } = await this._client.get<PagedResponse<AccessRuleResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: organisationId,
			},
		});

		return {
			...data,
			results: data.results.map((el) => AccessRulesServiceMapper.fromResponse(el)),
		};
	}

	/**
	 * Get the details of a single access rule
	 * @param id
	 * @returns
	 */
	async getAccessRuleById(id: string): Promise<AccessRule> {
		const response = await this._client.get<AccessRuleResponse>(path.join(this.path, id));

		return AccessRulesServiceMapper.fromResponse(response.data);
	}

	/**
	 * Create an access rule
	 * @param data
	 * @returns Reference to the access rule
	 */
	async createAccessRule(data: AccessRuleWriteable): Promise<IdReference> {
		const content = AccessRulesServiceMapper.toRequest(data);

		const response = await this._client.post<
			IdReference,
			AxiosResponse<IdReference, AccessRuleRequest>,
			AccessRuleRequest
		>(this.path, content);

		return response.data;
	}

	/**
	 * Delete an access rule
	 * @param id
	 * @returns
	 */
	async deleteAccessRule(id: string): Promise<IdReference> {
		const { data } = await this._client.delete<IdReference>(path.join(this.path, id));

		return data;
	}
}

class AccessRulesServiceMapper {
	static fromResponse(res: AccessRuleResponse): AccessRule {
		const {
			id,
			name,
			organisation,
			period,
			created,
			modified,
			schedules,
			targets,
			participants,
			...rest
		} = res;

		return {
			...rest,
			...BaseServiceMapper.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			period: {
				start: new Date(period.start),
				end: period?.end ? new Date(period.end) : null,
			},
			// TODO: eventually we expect the targets, participants and schedules
			// to be present. So not null checking
			organisation: BaseServiceMapper.fromBaseReferenceResponse(organisation),
			targets:
				targets?.map((el) => ({
					type: el.type as TargetTypeEnum,
					value: { id: el.id },
				})) ?? [],
			participants:
				participants?.map((el) => ({
					type: el.type as ParticipantTypeEnum,
					value: { id: el.id },
				})) ?? [],
			schedules: schedules?.map((el) => this.fromScheduleResponse(el)),
			created: new Date(created),
			modified: new Date(modified),
		};
	}

	static toRequest(data: AccessRuleWriteable): AccessRuleRequest {
		const { label, period, schedules, targets, participants, organisation, ...rest } = data;

		const weeklySchedules: WeeklySchedule[] = flattenWeeklySchedules(
			schedules.map((el) => dayOfWeekToWeeklySchedule(el)).flat(1),
		);

		return {
			...rest,
			name: label,
			organisation: {
				id: organisation.id,
			},
			period: {
				start: period.start.toISOString(),
				end: period.end?.toISOString(),
			},
			schedules: weeklySchedules.map((el) => this.toScheduleRequest(el)),
			targets: targets.map((el) => ({
				id: el.value.id,
				type: el.type,
			})),
			participants: participants.map((el) => ({
				id: el.type === ParticipantTypeEnum.NfcTag ? el.value.tagNumber : el.value.id,
				type: el.type,
			})),
		};
	}

	static fromScheduleResponse(res: ScheduleDto): WeeklySchedule {
		const { timeStart, timeEnd, ...rest } = res;

		// What if the input time is not proper?
		return {
			...rest,
			timeOpen: parseTime(timeStart),
			timeClosed: parseTime(timeEnd),
		};
	}

	static toScheduleRequest(data: WeeklySchedule): ScheduleDto {
		const { period, isOpenAllDay, ...rest } = data;

		return {
			...rest,
			...(period != null ?
				{
					timeStart: time24Format.format(period.start),
					timeEnd: time24Format.format(period.end),
				}
			:	{
					timeStart: '08:00:00',
					timeEnd: '17:00:00',
				}),
			openAllDay: isOpenAllDay,
		};
	}
}

interface BaseAccessRuleDto {
	name: string;
	description?: string;
	period: {
		start: string;
		end?: string;
	};
	targets: TargetDto[];
	participants: ParticipantDto[];
	schedules: ScheduleDto[];
}

interface AccessRuleResponse extends BaseAccessRuleDto {
	id: number;
	created: string;
	modified: string;
	organisation: BaseReferenceResponse;
}

interface AccessRuleRequest extends BaseAccessRuleDto {
	organisation: IdReferenceRequest;
}

interface TargetDto extends IdReference {
	type: 'itemGroup' | 'item' | 'device';
}

interface ParticipantDto extends IdReference {
	type: 'userGroup' | 'user' | 'nfc';
}

interface ScheduleDto {
	// Sunday is first so it is inline with dotnet
	openAllDay: boolean;
	daysOfWeek: (
		| 'sunday'
		| 'monday'
		| 'tuesday'
		| 'wednesday'
		| 'thursday'
		| 'friday'
		| 'saturday'
	)[];
	timeStart: string;
	timeEnd: string;
}

export { AccessRulesServiceMapper };
export default AccessRulesService;
