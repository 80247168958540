import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	actions: {
		padding: theme.spacing(2, 2, 2, 2),
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	resetButton: {
		textAlign: 'left',
	},
	editButton: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
}));
