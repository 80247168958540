import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	content: {
		padding: 12,
		paddingBottom: '0px',
	},
	actionsButton: {
		marginRight: '14px',
		color: '#fff',
	},
}));
