/* eslint-disable no-undef */
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

/**
 * Use /pages/page from now on
 * @param props
 * @returns
 * @deprecated
 */
const Page = (props) => {
	const { title, children, ...rest } = props;
	const { t } = useTranslation('general');

	return (
		<div {...rest}>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			{children}
		</div>
	);
};

Page.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
};

export default Page;
