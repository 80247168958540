import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Grid, Typography, Hidden, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton, ButtonWithAlertDialog } from '../../../../../components';
import { useDetailPageBackButton, useError } from '../../../../../shared/hooks';
import { isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const Header = (props) => {
	const { className, loading, pricingModel, onDeletePricingModel, deletedPricingModel } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();

	const classes = useStyles();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => navigate('/policy-management/pricing');

	const handleBackLabel = () => {
		if (returnButton.isBackButton) {
			return t('ui.button.inline.back');
		} else if (location?.state?.label) {
			return location?.state?.label;
		} else {
			return t('view.policymanagement.pricingdetails.button.inline.returntopricinglist');
		}
	};

	const {
		success: deleteSuccess,
		loading: deleteLoading,
		error: deleteError,
	} = deletedPricingModel;
	const deletePricingModelDone = deleteSuccess && !deleteLoading && !deleteError;
	const [deletingPricingModel, setDeletingPricingModel] = useState(false);

	const deletedPricingMessage = useError({
		value: deletedPricingModel,
		message: `${t('views.pricingModelDetail.messages.success.delete')} ${isObject(pricingModel) && pricingModel.name}`,
	});

	useEffect(() => {
		if (deletingPricingModel && deletePricingModelDone) {
			setDeletingPricingModel(false);
			navigate('/policy-management/pricing');
		} else if (deletingPricingModel && !!deleteError) {
			setDeletingPricingModel(false);
		}
	}, [deletePricingModelDone, deletingPricingModel, deleteError]);

	const handleDeletePricingModel = () => {
		setDeletingPricingModel(true);
		onDeletePricingModel(pricingModel.id);
		deletedPricingMessage.setStartAction(true);
	};

	const handleEdit = () => {
		navigate(`/pricing/${pricingModel.id}/edit`, {
			state: { detail: true },
		});
	};

	return (
		<div className={className}>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Hidden only={['xs', 'sm']}>
						<StyledButton
							className={classes.backButton}
							onClick={handleBackButtonChange}
							startIcon={<KeyboardArrowLeftOutlinedIcon />}
							variant='inline-default'
						>
							{handleBackLabel()}
						</StyledButton>
					</Hidden>
					<Typography component='h1' variant='h3'>
						{loading ?
							<AdjustedSkeleton animation='wave' width={200} />
						:	pricingModel.name}
					</Typography>
				</Grid>
				<Grid item>
					<Box display='flex'>
						<ButtonWithAlertDialog
							actionDone={deletePricingModelDone}
							callback={handleDeletePricingModel}
							dialogDescription={t('views.pricingModelDetail.summary.deleteDialog.description')}
							dialogLoading={deleteLoading && !deletePricingModelDone}
							dialogTitle={t('views.pricingModelDetail.summary.deleteDialog.title')}
							startIcon={<DeleteIcon className={classes.buttonIcon} />}
							variant='inline-delete'
						>
							{t('ui.button.inline.delete')}
						</ButtonWithAlertDialog>
						<Box pl={2}>
							<StyledButton onClick={handleEdit} startIcon={<EditIcon />} variant='inline-default'>
								{t('ui.button.inline.edit')}
							</StyledButton>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	buttonPlaceholder: PropTypes.string,

	action: PropTypes.func,
	href: PropTypes.string,

	pricingModel: PropTypes.object,
	loading: PropTypes.bool,

	deletedPricingModel: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onDeletePricingModel: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		deletedPricingModel: state.condition.deletedPricingModel,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeletePricingModel: (id) => dispatch(actions.deletePricingModel(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
