import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		gap: theme.spacing(0, 2),
		justifyContent: 'space-between',
	},
	extraButton: {
		width: 'max-content',
		height: 40.26,
	},
}));
