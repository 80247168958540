import { useTranslation } from 'react-i18next';

import { UserGroupsDataGrid } from '~features/userGroups';
import UserGroupsOverviewLayout from '~features/userGroups/layouts/userGroupsOverviewLayout';

import Page from '../page';

const UserGroupsPage = () => {
	const { t } = useTranslation('general');

	return (
		<Page title={t('userGroups')}>
			<UserGroupsOverviewLayout />
		</Page>
	);
};

export default UserGroupsPage;
