import PropTypes from 'prop-types';

import ActiveFinance from './ActiveFinance';
import History from './History';

const Expenses = (props) => {
	const { isSuperAdmin } = props;

	return (
		<>
			<ActiveFinance isSuperAdmin={isSuperAdmin} />
			<History isSuperAdmin={isSuperAdmin} />
		</>
	);
};

Expenses.propTypes = {
	isSuperAdmin: PropTypes.bool,
};

export default Expenses;
