import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	content: {
		'&:last-child': {
			paddingBottom: '12px',
		},
		padding: 12,
	},
}));
