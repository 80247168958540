import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	cell: {
		width: '350px',
	},
	link: {
		fontWeight: theme.link.body2.fontWeight,
		fontSize: theme.link.body2.fontSize,
		letterSpacing: theme.link.body2.letterSpacing,
		lineHeight: theme.link.body2.lineHeight,
	},
	cellStyle: {
		width: '9.25rem',
	},
}));
