import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Label } from '../../../components';

const PREFIX = 'HeaderWithStatus';

const classes = {
	header: `${PREFIX}-header`,
};

const StyledBox = styled(Box)(() => ({
	[`& .${classes.header}`]: {
		paddingRight: '8px',
	},
}));

export {};
const HeaderWithStatus = (props) => {
	const {
		padding,
		justifyContent,
		header,
		headerSize,
		subheader,
		subheaderSize,
		type,
		status,
		text,
		variantText,
	} = props;

	return (
		<StyledBox display='flex' flexDirection='column' p={padding}>
			<Box display='flex' justifyContent={justifyContent}>
				<Typography className={classes.header} variant={headerSize}>
					{header}
				</Typography>
				{status ?
					<Label type={type}>{status}</Label>
				:	<Typography variant={variantText}>{text}</Typography>}
			</Box>
			{subheader ?
				<Typography variant={subheaderSize}>{subheader}</Typography>
			:	null}
		</StyledBox>
	);
};

HeaderWithStatus.propTypes = {
	justifyContent: PropTypes.string,
	header: PropTypes.string,
	headerSize: PropTypes.string,
	subheader: PropTypes.string,
	subheaderSize: PropTypes.string,
	type: PropTypes.string,
	status: PropTypes.string,
	text: PropTypes.string,
	variantText: PropTypes.string,
	padding: PropTypes.number,
};

export default HeaderWithStatus;
