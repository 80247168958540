import { useState, useEffect } from 'react';

import { Card, CardHeader, CardContent, Typography, FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

const AdministrativeConfiguration = (props) => {
	const classes = useStyles();

	const { save, requireReservationComment: savedRequireReservationComment } = props;
	const { t } = useTranslation('general');

	const [requireReservationComment, setRequireReservationComment] = useState(
		savedRequireReservationComment || false,
	);

	useEffect(() => {
		save({
			requireReservationComment,
		});
	}, [requireReservationComment]);

	const handleToggleRequireReservationComment = () => {
		setRequireReservationComment(!requireReservationComment);
	};

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	return (
		<Card className={classes.root}>
			<CardHeader
				subheader={t(
					'views.addPolicy.policyConfiguration.category.administrativePolicies.description',
				)}
				title={t('views.addPolicy.policyConfiguration.category.administrativePolicies.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={requireReservationComment}
								color='primary'
								onChange={handleToggleRequireReservationComment}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t(
										'views.addPolicy.administrativeConfiguration.section.reservationComment.title',
									)}
								</Typography>
								<Typography color='textSecondary'>
									{t(
										'views.addPolicy.administrativeConfiguration.section.reservationComment.discription',
									)}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
				</div>
			</CardContent>
		</Card>
	);
};

AdministrativeConfiguration.propTypes = {
	save: PropTypes.func,
	requireReservationComment: PropTypes.bool,
};

export default AdministrativeConfiguration;
