import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
	const { className, tab } = props;
	const { t } = useTranslation('general');

	return (
		<div className={className}>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography component='h2' gutterBottom variant='overline'>
						{tab === 'characteristics' ? 'Characteristics' : 'Categories'}
					</Typography>
					<Typography component='h1' variant='h3'>
						{t('ui.label.settings')}
						{/* {t('ui.category.categories')} */}
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,

	tab: PropTypes.string,
};

export default Header;
