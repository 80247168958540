import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
	AccordionDetails,
	Divider,
	Box,
	Checkbox,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Table,
	IconButton,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Headers from './Header';
import { LoadingBar } from '../../../../components';
import { TableEditBar } from '../../../../components/elements';
import { isFullArray } from '../../../../shared/utility';
import { useStyles, Accordion } from '../style';

const ProvidersTable = (props) => {
	const {
		selectionEvents,
		setRowsPerPage,
		handlePageChange,
		title,
		headers,
		body,
		itemsActions,
		consumerActions,
		total,
		page,
		rowsPerPage,

		managementHeaders,
		selection,
		editActionButtons,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const handleChangeRowsPerPage = (event) => setRowsPerPage(event.target.value);

	const onPageChange = (e, page) => handlePageChange(page + 1);

	const handleLabelDisplayedRows = ({ from, to, count }) =>
		`${from}-${to} ${t('ui.of')} ${count !== -1 ? count : '0'}`;

	const actions = {
		changeRows: handleChangeRowsPerPage,
		changePage: onPageChange,
		displayedRows: handleLabelDisplayedRows,
	};

	const handleExpanded = (provider) => {
		selectionEvents.onShowConsumers(provider);
	};

	const handleExpandedConsumers = (consumers) => {
		selectionEvents.onShowConsumerItems(consumers);
	};

	const filterResults = (items) => {
		const selectedItems = items
			.filter((item) => item.canEdit && item)
			.map((itemRest) => {
				// eslint-disable-next-line no-unused-vars
				const { results, selected, canEdit, ...rest } = itemRest;
				return rest;
			});
		return selectedItems;
	};

	const handleSelectedAllItems = (items) => {
		selectionEvents.onSelectedAllItems({ ...items, selected: isFullArray(items.items) });
	};

	const handleSelectedItem = (items) => {
		selectionEvents.onSelectedItem(items);
	};

	const handleFilterItem = (consumer) => {
		const filteredObj = Object.fromEntries(
			// eslint-disable-next-line no-unused-vars
			Object.entries(consumer).filter(([key, value]) => !['items', 'results'].includes(key)),
		);
		return filteredObj;
	};

	return (
		<div className={classes.root}>
			<Card className={classes.mainCard}>
				<CardHeader title={title} />
				<Divider />
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow hover={false}>
										<TableCell sx={{ width: 60 }}>
											<Box width='28px'></Box>
										</TableCell>
										{managementHeaders.map((cell, cIndex) => (
											<TableCell key={cIndex}>{t(cell.content)}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{body.map((provider, rIndex) => (
										<TableRow key={rIndex}>
											<TableCell colSpan={11} style={{ padding: 0 }}>
												<Accordion
													expanded={provider.isExpanded}
													style={{ padding: 0, marginBottom: 0, margin: 0 }}
												>
													<Table>
														<TableBody>
															<TableRow>
																<TableCell sx={{ border: 'none', width: 60 }}>
																	<IconButton
																		aria-label='expand-provider'
																		onClick={() => handleExpanded(provider)}
																		size='small'
																		style={{ padding: 0 }}
																	>
																		{provider.isExpanded ?
																			<ArrowDropUpIcon />
																		:	<ArrowDropDownIcon />}
																	</IconButton>
																</TableCell>
																{provider.results.map((result, cIndex) => (
																	<TableCell
																		colSpan={10}
																		key={cIndex}
																		sx={provider.isExpanded ? {} : { border: 'none' }}
																	>
																		{result.loading && cIndex === 0 ?
																			<LoadingBar />
																		:	result.content}
																	</TableCell>
																))}
															</TableRow>
														</TableBody>
													</Table>
													<AccordionDetails style={{ padding: 0, borderBottom: 'none' }}>
														<Table>
															<TableBody>
																{isFullArray(provider.consumers) &&
																	provider.consumers.map((consumer, consumerIndex) => (
																		<TableRow key={consumerIndex}>
																			<TableCell
																				colSpan={11}
																				style={{ padding: 0, border: 'none' }}
																			>
																				<Accordion
																					expanded={
																						consumer?.isShowingItems ?
																							consumer.isShowingItems
																						:	false
																					}
																					style={{ padding: 0, marginBottom: 0, margin: 0 }}
																				>
																					<Table>
																						<TableBody>
																							<TableRow>
																								<TableCell
																									className={classes.cellWidth}
																									sx={{ border: 'none' }}
																								></TableCell>
																								<TableCell
																									className={classes.checkboxHeaders}
																									sx={
																										consumer.isShowingItems ?
																											{ borderBottom: 'none' }
																										:	{}
																									}
																								>
																									<IconButton
																										aria-label='expand-consumer'
																										onClick={() =>
																											handleExpandedConsumers(consumer)
																										}
																										size='small'
																										style={{ padding: 0 }}
																									>
																										{consumer.isShowingItems ?
																											<ArrowDropUpIcon />
																										:	<ArrowDropDownIcon />}
																									</IconButton>
																								</TableCell>
																								{isFullArray(consumer.results) ?
																									consumer.results.map((result, cIndex) => (
																										<TableCell colSpan={8} key={cIndex}>
																											{' '}
																											{result.loading && cIndex === 1 ?
																												<LoadingBar />
																											:	result.content}
																										</TableCell>
																									))
																								:	<TableCell colSpan={8}>
																										<LoadingBar />
																									</TableCell>
																								}
																							</TableRow>
																						</TableBody>
																					</Table>
																					<AccordionDetails
																						style={{ padding: 0, borderBottom: 'none' }}
																					>
																						<Table>
																							<TableHead>
																								<TableRow>
																									<TableCell
																										sx={{ width: '6%', border: 'none', padding: 0 }}
																									></TableCell>
																									<TableCell sx={{ width: '60px' }}>
																										<Checkbox
																											checked={
																												consumer.selecteAll ?
																													consumer.selecteAll
																												:	false
																											}
																											indeterminate={
																												consumer.indeterminate ?
																													consumer.indeterminate
																												:	false
																											}
																											onChange={(event) =>
																												event.target.checked ?
																													handleSelectedAllItems({
																														items: filterResults(consumer.items),
																														type: consumer.type,
																														providerId: consumer.providerId,
																														consumerId: consumer.id,
																														pageNumber:
																															consumer.itemsPage.pageNumber,
																														consumerName: consumer.consumerName,
																													})
																												:	handleSelectedAllItems({
																														items: [],
																														type: consumer.type,
																														providerId: consumer.providerId,
																														consumerId: consumer.id,
																														pageNumber:
																															consumer.itemsPage.pageNumber,
																														consumerName: consumer.consumerName,
																													})
																											}
																											sx={{ padding: 0 }}
																										/>
																									</TableCell>
																									{headers.map((cell, cIndex) => (
																										<Headers
																											cIndex={cIndex}
																											cell={cell}
																											key={cell.name}
																										/>
																									))}
																								</TableRow>
																							</TableHead>
																							<TableBody>
																								{isFullArray(consumer.items) &&
																									consumer.items.map((item, itemIndex) => (
																										<TableRow key={itemIndex}>
																											<TableCell
																												sx={{
																													width: 120,
																													border: 'none',
																													padding: 0,
																												}}
																											>
																												<Box width='120px'></Box>
																											</TableCell>
																											<TableCell sx={{ width: '60px' }}>
																												<Checkbox
																													checked={
																														item?.selected ? item?.selected : false
																													}
																													disabled={!item.canEdit}
																													onChange={(event) =>
																														handleSelectedItem({
																															selected: event.target.checked,
																															item: item.item,
																															type: consumer.type,
																															providerId: consumer.providerId,
																															consumerId: consumer.id,
																															userGroup: item.userGroup,
																															pageNumber:
																																consumer.itemsPage.pageNumber,
																															consumerName: item.consumerName,
																														})
																													}
																													sx={{ padding: 0 }}
																												/>
																											</TableCell>
																											{isFullArray(item.results) ?
																												item.results.map((result, rIndex) => (
																													<TableCell
																														key={rIndex}
																														sx={
																															rIndex === 1 ?
																																{ width: '5%' }
																															:	{ width: '15%' }
																														}
																													>
																														{result.loading ?
																															<LoadingBar />
																														:	result.content}
																													</TableCell>
																												))
																											:	<TableCell colSpan={7}>
																													{' '}
																													<LoadingBar />
																												</TableCell>
																											}
																										</TableRow>
																									))}
																							</TableBody>
																						</Table>
																						<Table>
																							<TableBody>
																								<TableRow>
																									<TableCell
																										sx={{ width: 60, border: 'none', padding: 0 }}
																									></TableCell>
																									<TableCell sx={{ width: 120, padding: 0 }}>
																										<Box width='120px'></Box>
																									</TableCell>
																									<TableCell colSpan={11} sx={{ padding: 0 }}>
																										{consumer?.total || consumer.total === 0 ?
																											<CardActions className={classes.actions}>
																												<TablePagination
																													component='div'
																													count={consumer.total}
																													labelDisplayedRows={actions.displayedRows}
																													labelRowsPerPage={t('ui.rowsPerPage')}
																													onPageChange={(event, page) =>
																														itemsActions.changePageNumber(
																															page,
																															handleFilterItem(consumer),
																														)
																													}
																													onRowsPerPageChange={(event) =>
																														itemsActions.changePageSize(
																															event.target.value,
																															handleFilterItem(consumer),
																														)
																													}
																													page={consumer.itemsPage.pageNumber - 1}
																													rowsPerPage={consumer.itemsPage.pageSize}
																													rowsPerPageOptions={[5, 10, 20, 40, 50]}
																												/>
																											</CardActions>
																										:	null}
																									</TableCell>
																								</TableRow>
																							</TableBody>
																						</Table>
																					</AccordionDetails>
																				</Accordion>
																			</TableCell>
																		</TableRow>
																	))}
																<TableRow>
																	<TableCell colSpan={11} sx={{ border: 'none', padding: 0 }}>
																		{provider?.total || provider.total === 0 ?
																			<CardActions className={classes.actions}>
																				<TablePagination
																					component='div'
																					count={provider.total}
																					labelDisplayedRows={actions.displayedRows}
																					labelRowsPerPage={t('ui.rowsPerPage')}
																					onPageChange={(event, page) =>
																						consumerActions.changePageNumber(
																							page,
																							provider.providerId,
																						)
																					}
																					onRowsPerPageChange={(event) =>
																						consumerActions.changePageSize(
																							event.target.value,
																							provider.providerId,
																						)
																					}
																					page={provider.consumersPage.pageNumber - 1}
																					rowsPerPage={provider.consumersPage.pageSize}
																					rowsPerPageOptions={[5, 10, 20, 40, 50]}
																				/>
																			</CardActions>
																		:	null}
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
				{total ?
					<CardActions className={classes.actions}>
						<TablePagination
							component='div'
							count={total}
							labelDisplayedRows={actions.displayedRows}
							labelRowsPerPage={t('ui.rowsPerPage')}
							onPageChange={actions.changePage}
							onRowsPerPageChange={actions.changeRows}
							page={!total ? 0 : page - 1}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={[5, 10, 20, 40, 50]}
						/>
					</CardActions>
				:	null}
			</Card>
			{isFullArray(selection) ?
				<TableEditBar actionButtons={editActionButtons} selected={selection} />
			:	null}
		</div>
	);
};

ProvidersTable.propTypes = {
	selectionEvents: PropTypes.object,
	setRowsPerPage: PropTypes.func,
	handlePageChange: PropTypes.func,
	title: PropTypes.string,
	headers: PropTypes.array,
	body: PropTypes.array,
	itemsActions: PropTypes.object,
	consumerActions: PropTypes.object,
	total: PropTypes.number,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	managementHeaders: PropTypes.array,
	selection: PropTypes.array,
	editActionButtons: PropTypes.array,
};

export default ProvidersTable;
