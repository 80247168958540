import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import HeartbeatsList from './HeartbeatsList';
import { useStyles } from './style';
import Summary from './Summary';
import TripsList from './TripsList';
import { Page } from '../../../components';
import { isObject, isNull } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';

const DeviceDetails = (props) => {
	const {
		fetchDevice,
		onFetchDevice,

		onFetchVehicleStatus,
		vehicleStatus,
		onFetchDeviceHeartbeats,
		fetchDeviceHeartbeats,
		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();

	const { id, tab } = useParams();

	const { data: deviceData, loading: deviceLoading, error: deviceError } = fetchDevice;
	const deviceReady = isObject(deviceData) && !deviceLoading && !deviceError;

	const { data: vehicleStatusData, loading: vehicleLoading } = vehicleStatus;
	const { data: fetchDeviceHeartbeatsData, loading: fetchDeviceHeartbeatsLoading } =
		fetchDeviceHeartbeats;

	const isBMW = isObject(deviceData) && deviceData.deviceType === 'bmwCar';
	const notBMW = isObject(deviceData) && deviceData.deviceType !== 'bmwCar';
	const tripsAccess =
		isObject(deviceData) &&
		['vehicleTrackerObd', 'vehicleTrackerPro', 'vehicleTrackerCan', 'vehicleTracker'].includes(
			deviceData.deviceType,
		);
	const isVehicle =
		isObject(deviceData) &&
		[
			'vehicleTrackerObd',
			'vehicleTrackerPro',
			'bmwCar',
			'vehicleTrackerCan',
			'vehicleTracker',
		].includes(deviceData.deviceType);
	const isBoat = isObject(deviceData) && ['cpacBoat'].includes(deviceData.deviceType);
	const isSummary = tab === 'summary';
	const gpsIcons = ['bikeLock', 'doorLock', 'trailerLock'].includes(deviceData?.deviceType);

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	// const fetch = isFullString(id) && !isObject(deviceData) || isObject(deviceData) && deviceData?.deviceId !== id;
	useEffect(() => {
		if (!deviceLoading) {
			onFetchDevice(id);
			onResetState('fetchDeviceHeartbeats');
			onResetState('vehicleStatus');
		}
	}, [id, onFetchDevice]);

	useEffect(() => {
		if (!vehicleLoading && isBMW && isSummary && deviceData.deviceId === id) {
			onFetchVehicleStatus(deviceData.deviceId);
		} else if (!fetchDeviceHeartbeatsLoading && notBMW && deviceData.deviceId === id) {
			onFetchDeviceHeartbeats(deviceData.deviceId);
		}
	}, [deviceLoading, id]);

	const tabs = [
		{ value: 'summary', label: t('ui.summary') },
		{ value: 'heartbeat', label: t('views.devices.heartbeatHistory') },
		...(tripsAccess ? [{ value: 'trips', label: t('ui.label.trips') }] : []),
	];

	if (!tab) {
		return <Navigate to={'/dashboard'} />;
	}

	if (isObject(deviceData) && !tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const handleDeviceData = () => {
		if (isVehicle) {
			if (isBMW && !isNull(vehicleStatusData)) {
				return { ...deviceData, deviceStatus: vehicleStatusData };
			} else if (isObject(fetchDeviceHeartbeatsData)) {
				return { ...deviceData, deviceStatus: fetchDeviceHeartbeatsData };
			}
		} else {
			return deviceData;
		}
	};

	const summaryProps = {
		deviceData: handleDeviceData(),
		gpsIcons: gpsIcons,
		id,
		isBMW,
		isVehicle,
		loading: deviceLoading && isObject(deviceData) && (notBMW || isBMW),
	};
	const heartbeatsProps = {
		isTrailerLock: deviceData?.deviceType === 'trailerLock',
		gpsIcons: gpsIcons,
		vehicleFuelVolume: fetchDeviceHeartbeatsData?.vehicleFuelVolume,
		vehicleFuelLevel: fetchDeviceHeartbeatsData?.vehicleFuelLevel,
		isVehicleTracker: [
			'cpacBoat',
			'vehicleTrackerObd',
			'vehicleTrackerPro',
			'vehicleTrackerCan',
			'vehicleTracker',
		].includes(deviceData?.deviceType),
		isBoat,
	};

	const tripsListProps = { id, isDeviceTrip: true };

	return (
		<Page className={classes.root} title={t('ui.label.details')}>
			<Header deviceData={deviceData} loading={!deviceReady} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && <Summary {...summaryProps} />}
				{tab === 'heartbeat' && <HeartbeatsList {...heartbeatsProps} />}
				{tab === 'trips' && <TripsList {...tripsListProps} />}
			</div>
		</Page>
	);
};

DeviceDetails.propTypes = {
	fetchDevice: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	onFetchDevice: PropTypes.func,
	onFetchVehicleStatus: PropTypes.func,
	vehicleStatus: PropTypes.shape({
		data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchDeviceHeartbeats: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchDeviceHeartbeats: PropTypes.func,
	onResetState: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		fetchDevice: state.details.fetchDevice,

		fetchDeviceHeartbeats: state.details.fetchDeviceHeartbeats,
		vehicleStatus: state.details.vehicleStatus,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchDevice: (id) => dispatch(actions.fetchDevice(id)),
		onFetchVehicleStatus: (id) => dispatch(actions.fetchVehicleStatus(id)),
		onFetchDeviceHeartbeats: (id) => dispatch(actions.fetchDeviceHeartbeats(id)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
