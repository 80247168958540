import { IconButton, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'DialogTitleCustom';

const classes = {
	root: `${PREFIX}-root`,
	closeButton: `${PREFIX}-closeButton`,
};

const StyledMuiDialogTitle = styled(MuiDialogTitle)(() => ({
	[`&.${classes.root}`]: {
		// alignItems: 'center',
		// margin: 0,
		// // padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		paddingBottom: '14px',
		paddingTop: '14px',
	},

	[`& .${classes.closeButton}`]: {
		padding: 0,
	},
}));

const DialogTitleCustom = (props) => {
	const { onClose, title, icon } = props;

	return (
		<StyledMuiDialogTitle className={classes.root} disableTypography>
			<Typography variant='h6'>{title}</Typography>
			{onClose ?
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onClose}
					size='large'
				>
					{icon}
				</IconButton>
			:	null}
		</StyledMuiDialogTitle>
	);
};

DialogTitleCustom.propTypes = {
	onClose: PropTypes.func,
	title: PropTypes.string,
	icon: PropTypes.object,
};

export default DialogTitleCustom;
