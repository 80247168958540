import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
	root: {
		padding: '3rem',
	},
	textContainer: {
		marginBottom: '1.5rem',
	},
	emptyStateSubtitle: {
		marginTop: '0',
		textAlign: 'center',
	},
}));
