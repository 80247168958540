import { useEffect } from 'react';

import { Typography, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../../../components';
import * as actions from '../../../../../../store/actions';

const DeleteUserDialog = (props) => {
	const {
		openDeleteUserDialog,
		setOpenDeleteUserDialog,
		userId,

		onHardDeleteUser,
		hardDeleteUser,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const {
		success: hardDeleteUserSuccess,
		loading: hardDeleteUserLoading,
		error: hardDeleteUserError,
	} = hardDeleteUser;

	const { enqueueSnackbar } = useSnackbar();

	const handleCloseAttentionDialog = () => setOpenDeleteUserDialog(false);

	useEffect(() => {
		if (hardDeleteUserSuccess) {
			enqueueSnackbar(t('views.userDetails.summary.deleteDialog.userDeletion'), {
				variant: 'success',
			});
			handleCloseAttentionDialog();
		} else if (hardDeleteUserError) {
			enqueueSnackbar(hardDeleteUserError?.message, { variant: 'error' });
			onResetStateCondition('hardDeleteUser', false);
		}
	}, [hardDeleteUser]);

	const handleConfirm = () => {
		onHardDeleteUser(userId, true);
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text:
					hardDeleteUserLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleCloseAttentionDialog}
			loading={hardDeleteUserLoading}
			open={openDeleteUserDialog}
			title={t('views.userDetails.summary.deleteDialog.title')}
		>
			<Typography variant='body2'>
				{t('views.userDetails.summary.deleteDialog.description')}
			</Typography>
		</ActionDialog>
	);
};

DeleteUserDialog.propTypes = {
	openDeleteUserDialog: PropTypes.bool,
	setOpenDeleteUserDialog: PropTypes.func,
	userId: PropTypes.number,
	onHardDeleteUser: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	hardDeleteUser: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		hardDeleteUser: state.condition.hardDeleteUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onHardDeleteUser: (id, hardDelete) => dispatch(actions.hardDeleteUser(id, hardDelete)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserDialog);
