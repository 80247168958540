import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	arrowLeft: {
		cursor: 'pointer',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
	},
	buttonsCancel: {
		borderRadius: '19px',
		marginLeft: '16px',
		color: '#CE2A3D',
		borderColor: '#CE2A3D',
	},
	buttons: {
		borderRadius: '19px',
		marginLeft: '16px',
	},
	partialRefundCard: {
		padding: '8px 0',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	partialRefund: {
		...theme.typography.link1,
	},
	selectWithLabel: {
		'& .MuiInputBase-input': {
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
	attention: {
		marginRight: theme.spacing(1),
	},
}));
