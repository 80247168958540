import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	wizardSection: {
		marginTop: theme.spacing(3),
	},
	formGroup: {
		'& + &': {
			marginTop: theme.spacing(4),
		},
		'&:last-child': {
			marginBottom: theme.spacing(3),
		},
	},
	fieldGroup: {
		display: 'flex',
		alignItems: 'flex-start',
	},
	fieldGroupItem: {
		flex: 1,
		'& + &': {
			marginLeft: theme.spacing(3),
		},
	},
	labelOnTop: {
		display: 'flex',
		flexDirection: 'column',
	},
}));
