import { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import BillingsUsers from './BillingsUsers';
import Header from './Header';
import { useStyles } from './style';
import { Page } from '../../../../components';

const PersonalBillings = (props) => {
	const { t } = useTranslation('general');

	const { date, id } = useParams();
	const [fullName, setFullName] = useState('');

	const classes = useStyles();

	return (
		<Page className={classes.root} title={t('ui.finance')}>
			<Header fullName={fullName} />
			<BillingsUsers date={date} id={id} setFullName={setFullName} />
		</Page>
	);
};

PersonalBillings.propTypes = {};

const mapStateToProps = (state) => {
	return {
		revenues: state.paged.revenues,
	};
};

export default connect(mapStateToProps, null)(PersonalBillings);
