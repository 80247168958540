import { useState } from 'react';

import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Box, ConfirmationDialog, Dialog, EditableDrawer } from '~components';

import UsersDataGrid from '../components/dataGrids/usersDataGrid';
import CreateAnonymousForm from '../forms/createAnonymousUser/createAnonymousUserForm';
import UserIdentitiesService from '../services/userIdentitiesService';
import UsersService from '../services/usersService';

type UserAction = {
	type: 'create';
	variant: 'anonymous' | 'normal';
	id?: never;
} | {
	type: 'delete' | 'deactivate' | 'edit';
	variant?: never;
	id: string;
};

const usersService = new UsersService();
const userIdentitiesService = new UserIdentitiesService();

const UsersOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();

	const [userAction, setUserAction] = useState<UserAction | null>(null);
	const [userActionProcessing, setUserActionProcessing] = useState(false);

	const handleCreateAnonymousUser = async (data) => {
		setUserActionProcessing(true);

		try {
			await userIdentitiesService.createAnonymousUser(data);
			enqueueSnackbar('Succesful deleted', { variant: 'success' });
			// mutate(refreshString);
			setUserAction(null);
		} catch (error) {
			console.error(error);
			enqueueSnackbar('somethingwrong', { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
		<Box height={1}>
			<UsersDataGrid
				onCreate={(type) => setUserAction({ type: 'create', variant: 'anonymous' }) }
			/>
			<Dialog
				title={t('createUser')}
				open={userAction != null}
				onClose={() => setUserAction(null)}
			>
				<CreateAnonymousForm
					onSubmit={(data) => handleCreateAnonymousUser(data)}
					onClose={() => setUserAction(null)}
				/>
			</Dialog>
			<ConfirmationDialog
				open={userAction?.type === ''}
			/>
		</Box>
	);
};

export default UsersOverviewLayout;
