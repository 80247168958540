import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	drawer: {
		width: 450,
		maxWidth: '100%',
		padding: theme.spacing(2),
	},
	pageActionButtons: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(3),
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	subHeader: {
		margin: theme.spacing(3, 0, 2, 0),
	},
	progress: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		color: theme.palette.primary.contrastText,
	},
	textField: {
		'& .MuiOutlinedInput-root': {
			borderRadius: theme.spacing(1.5),
		},
	},
	selectWithLabel: {
		'& .MuiInputBase-input': {
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
	menuItem: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	formGroup: {
		'& + &': {
			marginTop: theme.spacing(4),
		},
		// '&:last-child': {
		//   marginBottom: theme.spacing(3)
		// }
	},
	counterText: {
		color: theme.palette.grey[400],
	},
	notification: {
		marginTop: theme.spacing(1.5),
	},
}));
