import { Drawer } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const CustomDrawer = (props) => {
	const { open, onClose, children } = props;

	const classes = useStyles();

	return (
		<Drawer
			anchor='right'
			classes={{ paper: classes.drawer }}
			onClose={() => onClose()}
			open={open}
			variant='temporary'
		>
			{children}
		</Drawer>
	);
};

CustomDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.any,
};

export default CustomDrawer;
