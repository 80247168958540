import { useEffect } from 'react';

import { Modal, Card, CardContent, CardActions, Grid, Typography, MenuItem } from '@mui/material';
import clsx from 'clsx';
import { getData } from 'country-list';
import countries from 'i18n-iso-countries';
import isoCountriesDe from 'i18n-iso-countries/langs/de.json';
import isoCountriesEn from 'i18n-iso-countries/langs/en.json';
import isoCountriesNl from 'i18n-iso-countries/langs/nl.json';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { FormField, StyledButton, FormFieldSelect } from '../../../../../../../components';
import { countryList } from '../../../../../../../constantsOld';
import { useWizardFormField, useError } from '../../../../../../../shared/hooks';
import { isEmptyArray, isObject, isUndefined } from '../../../../../../../shared/utility';
import * as actions from '../../../../../../../store/actions';

countries.registerLocale(isoCountriesEn);
countries.registerLocale(isoCountriesNl);
countries.registerLocale(isoCountriesDe);

const EditAddress = (props) => {
	const {
		open,
		onClose,
		organisation,
		className,

		onUpdateOrganisation,
		updateOrganisation,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const address = isObject(organisation) && isObject(organisation.address) && organisation.address;
	const street = useWizardFormField(address ? address.street : '', {
		required: true,
		maxLength: 128,
		minLength: 3,
		isRequired: true,
	});
	const number = useWizardFormField(address ? address.number : '', {
		required: true,
		maxLength: 16,
		isRequired: true,
	});
	const postalCode = useWizardFormField(address ? address.postalCode : '', {
		required: true,
		maxLength: 10,
		isRequired: true,
	});
	const city = useWizardFormField(address ? address.city : '', {
		required: true,
		maxLength: 25,
		isRequired: true,
	});
	const country = useWizardFormField(address.countryCode || '', { required: true });

	const isValid = street.isValid && number.isValid && postalCode.isValid && city.isValid;

	const isChange =
		isObject(address) &&
		address.street === street.value &&
		address.number === number.value &&
		address.postalCode === postalCode.value &&
		address.city === city.value &&
		address.countryCode === country.value;

	const errorHandle = useError(
		isObject(updateOrganisation) ?
			{
				value: updateOrganisation,
				message: `${t('ui.successfully')} ${t('ui.updated')} ${organisation.name}`,
			}
		:	null,
	);

	useEffect(() => {
		if (isEmptyArray(countries)) {
			getData();
		}
	}, [countries]);

	if (!open) {
		return null;
	}

	const editData = [
		{
			name: 'street',
			label: t('ui.label.street'),
			variable: street,
			placeholder: t('ui.label.street'),
			maxLength: 128,
		},
		{
			name: 'number',
			label: t('ui.nummer'),
			variable: number,
			placeholder: t('ui.nummer'),
			maxLength: 16,
		},
		{
			name: 'postalCode',
			label: t('ui.label.postalCode'),
			variable: postalCode,
			placeholder: t('ui.label.postalCode'),
			maxLength: 10,
		},
		{
			name: 'city',
			label: t('ui.label.city'),
			variable: city,
			placeholder: t('ui.label.city'),
			maxLength: 25,
		},
	];

	const handleSave = () => {
		onUpdateOrganisation(organisation.id, {
			name: organisation.name,
			legalName: !isUndefined(organisation.legalName) ? organisation.legalName : organisation.name,
			description: organisation.description,
			phoneNumber: organisation.phoneNumber,
			email: organisation.emailAddress,
			address: {
				street: street.value,
				number: number.value,
				postalCode: postalCode.value,
				city: city.value,
				countryCode: country.value,
			},
			contactPerson: {
				email: organisation.contactPerson.emailAddress,
				firstName: organisation.contactPerson.firstName,
				lastName: organisation.contactPerson.lastName,
				phoneNumber: organisation.contactPerson.phoneNumber,
			},
			invoiceContact: {
				email: organisation.invoiceContact.emailAddress,
				name: organisation.invoiceContact.name,
			},
			...(organisation.administration ?
				{
					administration: {
						...(!isUndefined(organisation.administration.vatNumber) && {
							vatNumber: organisation.administration.vatNumber,
						}),
						...(!isUndefined(organisation.administration.chamberOfCommerceNumber) && {
							chamberOfCommerceNumber: organisation.administration.chamberOfCommerceNumber,
						}),
						...(!isUndefined(organisation.administration.ibanNumber) && {
							ibanNumber: organisation.administration.ibanNumber,
						}),
						...(!isUndefined(organisation.administration.enterpriseNumber) && {
							enterpriseNumber: organisation.administration.enterpriseNumber,
						}),
					},
				}
			:	null),
			subscriptionId: organisation.subscription.id,
		});
		onClose();
		errorHandle.setStartAction(true);
	};

	const handleClose = () => {
		resetData();
		onClose();
	};

	const resetData = () => {
		street.resetToInitialValue();
		number.resetToInitialValue();
		postalCode.resetToInitialValue();
		city.resetToInitialValue();
		country.resetToInitialValue();
	};

	return (
		<Modal onClose={onClose} open={open}>
			<Card className={clsx(classes.root, className)}>
				<form>
					<CardContent>
						<Typography align='center' gutterBottom variant='h3'>
							{t('views.organisationDetail.summary.editAddress')}
						</Typography>
						<div className={classes.container}>
							{editData.map((edit) => (
								<div className={classes.formGroup} key={edit.name}>
									<FormField
										hideCharacterCounter={!edit.variable.hasFocus}
										label={edit.label}
										maxLength={edit.maxLength}
										name={edit.name}
										placeholder={edit.placeholder}
										required={true}
										variable={edit.variable}
									/>
								</div>
							))}
						</div>
						<Grid item md={12} xs={12}>
							<FormFieldSelect
								displayEmpty
								fullWidth
								label={t('ui.label.country')}
								name='country'
								required
								variable={country}
							>
								<MenuItem disabled value={''}>
									<em>{t('views.addOrganisation.contactDetails.firstSelectOption.country')}</em>
								</MenuItem>
								{countryList.map((country) => (
									//Get ISO country code in English, use it to fetch translated country name
									<MenuItem key={country} value={countries.getAlpha2Code(country, 'en')}>
										{countries.getName(countries.getAlpha2Code(country, 'en'))}
									</MenuItem>
								))}
							</FormFieldSelect>
						</Grid>
					</CardContent>
					<CardActions className={classes.actions}>
						<StyledButton onClick={handleClose} variant='contained-tertiary'>
							{t('ui.button.contained.close')}
						</StyledButton>
						<StyledButton
							disabled={isChange || !isValid}
							onClick={handleSave}
							variant='contained-secondary'
						>
							{t('ui.button.contained.save')}
						</StyledButton>
					</CardActions>
				</form>
			</Card>
		</Modal>
	);
};

EditAddress.displayName = 'EditAddress';

EditAddress.propTypes = {
	className: PropTypes.string,
	organisation: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool,

	onUpdateOrganisation: PropTypes.func,
	updateOrganisation: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

EditAddress.defaultProps = {
	open: false,
	onClose: () => {},
};

const mapStateToProps = (state) => {
	return {
		updateOrganisation: state.details.updateOrganisation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateOrganisation: (orgId, bodyData) =>
			dispatch(actions.updateOrganisation(orgId, bodyData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
