import { Switch } from '@mui/material';
import { withStyles } from '@mui/styles';

import palette from '../../../theme/palette';

const SwitchButton = withStyles({
	switchBase: {
		color: '#e4e7eb',
		'&$checked': {
			color: palette.button.primary,
		},
		'&$checked + $track': {
			backgroundColor: palette.button.primary,
		},
	},
	checked: {},
	track: {},
})(Switch);

export default SwitchButton;
