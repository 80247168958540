import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	paginationContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	actionCell: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	nameCell: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1),
	},
	link: {
		fontWeight: theme.link.body2.fontWeight,
		fontSize: theme.link.body2.fontSize,
		letterSpacing: theme.link.body2.letterSpacing,
		lineHeight: theme.link.body2.lineHeight,
	},
	mapLink: {
		...theme.link.body3,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	cellStyle: {
		whiteSpace: 'nowrap',
		minWidth: '80px',
	},
}));
