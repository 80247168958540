import { useEffect, useRef, useState } from 'react';

import path from 'path';

import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ConfirmationDialog, FormDialog } from '~components';
import { useAuthorize } from '~features/authentication';

import selectedOrganisationAtom from '../atoms/selectedOrganisationAtom';
import SkcUserGroupsDataGrid from '../components/dataGrids/skcUserGroupsDataGrid';
import SkcUserGroupForm from '../forms/skcUserGroupForm/skcUserGroupForm';
import SkcUserGroup from '../interfaces/skcUserGroup';
import SkcUserGroupsService from '../services/skcUserGroupsService';

type UserAction =
	| {
			type: 'delete' | 'edit';
			label?: string;
			id: string;
	  }
	| {
			type: 'add';
			label?: never;
			id?: never;
	  };

const service = new SkcUserGroupsService();

const SkcUserGroupsOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();
	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [userAction, setUserAction] = useState<UserAction | null>(null);
	const dataGridRef = useRef(null);

	const { isSuperAdmin } = useAuthorize();
	const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

	useEffect(() => {
		// Should be temporary
		if (selectedOrganisation) {
			service.organisation = selectedOrganisation;
		}
	}, [selectedOrganisation]);

	const {
		data: userGroupData,
		isLoading,
		isValidating,
		error,
	} = useSWR(
		userAction?.type === 'edit' ? path.join(service.basePath, userAction.id) : undefined,
		() => service.getUserGroupById(userAction.id),
	);

	const handleEdit = async (id: string, data: SkcUserGroup) => {
		setUserActionProcessing(true);

		// NFCs
		const currentNfcTagsArray = userGroupData?.participants.nfcTags.map((el) => el.id) ?? [];
		const newNfcTagsArray = data.participants.nfcTags.map((el) => el.id);

		const nfcTagsToRemove = userGroupData?.participants.nfcTags.filter((el) => !newNfcTagsArray.includes(el.id));
		const nfcTagsToAdd = data.participants.nfcTags.filter((el) => !currentNfcTagsArray.includes(el.id));

		const currentUsersArray = userGroupData?.participants.users.map((el) => el.id) ?? [];
		const newUsersArray = data.participants.users.map((el) => el.id);

		const usersToRemove = userGroupData?.participants.users.filter((el) => !newUsersArray.includes(el.id));
		const usersToAdd = data.participants.users.filter((el) => !currentUsersArray.includes(el.id));

		try {
			if (nfcTagsToRemove != null && nfcTagsToRemove?.length > 0) {
				await service.removeNfcTags(id, nfcTagsToRemove);
			}
			if (nfcTagsToAdd.length > 0) {
				await service.assignNfcTags(id, nfcTagsToAdd);
			}
			if (usersToRemove != null && usersToRemove.length > 0) {
				await service.removeUsers(id, usersToRemove);
			}
			if (usersToAdd.length > 0) {
				await service.assignUsers(id, usersToAdd);
			}

			enqueueSnackbar(t('ui.success.message.updated'), { variant: 'success' });
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	/**
	 *
	 * @param data
	 */
	const handleCreate = async (data: SkcUserGroup) => {
		setUserActionProcessing(true);

		try {
			const res = await service.createUserGroup(data, selectedOrganisation.id);
			if (data.participants.nfcTags?.length > 0) {
				await service.assignNfcTags(res.id, data.participants.nfcTags);
			}
			if (data.participants.users?.length > 0) {
				await service.assignUsers(res.id, data.participants.users);
			}
			enqueueSnackbar(`${t('ui.successfullyAdded')} ${t('userGroup').toLowerCase()}`, {
				variant: 'success',
			});
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleDelete = async (id: string) => {
		setUserActionProcessing(true);

		try {
			await service.deleteUserGroup(id);
			enqueueSnackbar(t('deleted'), { variant: 'success' });
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
		<Stack height={1}>
			<SkcUserGroupsDataGrid
				ref={dataGridRef}
				onEdit={(value) => setUserAction({ type: 'edit', ...value })}
				onDelete={(value) => setUserAction({ type: 'delete', ...value })}
				onCreate={() => setUserAction({ type: 'add' })}
			/>
			<FormDialog
				title={t(userAction?.type === 'edit' ? 'editResource' : 'addResource', {
					resource: t('userGroup'),
				})}
				loading={userActionProcessing}
				open={userAction?.type === 'edit' || userAction?.type === 'add'}
				onSubmit={(data) =>
					userAction?.type === 'edit' ? handleEdit(userAction.id, data) : handleCreate(data)
				}
				onClose={() => setUserAction(null)}
				fullWidth
				maxWidth='lg'
			>
				<SkcUserGroupForm
					id={userAction?.id}
				/>
			</FormDialog>
			<ConfirmationDialog
				loading={userActionProcessing}
				open={userAction?.type === 'delete'}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('userGroup'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={() => userAction?.id != null && handleDelete(userAction.id)}
				onDecline={() => setUserAction(null)}
				onClose={() => setUserAction(null)}
			/>
		</Stack>
	);
};

export default SkcUserGroupsOverviewLayout;
