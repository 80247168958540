import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 650,
	},
	categoryListItem: {
		display: 'flex',
		justifyContent: 'space-between',
		'& + &': {
			borderTop: `1px solid ${theme.palette.divider}`,
			marginTop: theme.spacing(2),
			paddingTop: theme.spacing(3),
		},
	},
	iconBlock: {
		display: 'flex',
		alignItems: 'center',
	},
	iconBlockText: {
		marginRight: theme.spacing(3),
	},
}));
