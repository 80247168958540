import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { Input } from '../..';
import { StyledButton } from '../../../components';
import { isFullString } from '../../../shared/utility';

const Search = (props) => {
	const {
		placeholder,
		events,
		value,
		hasSearchButton,
		hasInlineSearchButton,
		inputType,
		className,

		styledInput,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<Input
				className={isFullString(styledInput) ? styledInput : classes.searchInput}
				events={events}
				hasClearButton={true}
				hasInlineSearchButton={hasInlineSearchButton}
				icon={!inputType ? <SearchIcon /> : null}
				inputType={inputType ? inputType : null}
				placeholder={placeholder ? placeholder : t('ui.search')}
				value={value}
			/>
			{hasSearchButton ?
				<StyledButton
					className={classes.searchButton}
					onClick={events.onClick}
					size='large'
					variant='contained-tertiary'
				>
					{t('ui.button.contained.search')}
				</StyledButton>
			:	null}
		</div>
	);
};

Search.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	events: PropTypes.object,
	value: PropTypes.string,
	hasSearchButton: PropTypes.bool,
	hasInlineSearchButton: PropTypes.bool,
	inputType: PropTypes.oneOf(['search-suggest']),

	styledInput: PropTypes.string,
};

export default Search;
