import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { pagePathSegments } from '~constants';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';

const Header = (props) => {
	const { className, name, loading } = props;
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation('general');

	const classes = useStyles();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () =>
		navigate(
			location.state?.from ??
				`/${pagePathSegments.OrganisationManagement}/${pagePathSegments.Organisations}`,
		);

	const handleBackButton = () => {
		if (returnButton.isBackButton || !location.state?.label) {
			return t('ui.button.inline.back');
		} else {
			return location.state.label;
		}
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Hidden only={['xs', 'sm']}>
				<StyledButton
					className={classes.backButton}
					onClick={handleBackButtonChange}
					startIcon={<KeyboardArrowLeftOutlinedIcon />}
					variant='inline-default'
				>
					{handleBackButton()}
				</StyledButton>
			</Hidden>
			<Typography component='h1' variant='h3'>
				{loading ?
					<AdjustedSkeleton animation='wave' width={200} />
				:	name}
			</Typography>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	loading: PropTypes.bool,
};

export default Header;
