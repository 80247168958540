import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import GeneralInfo from './GeneralInfo';
import OtherActions from './OtherActions';
import Settings from './Settings';
import SummaryLoading from './SummaryLoading';

const PREFIX = 'Summary';

const classes = {
	root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(() => ({
	[`&.${classes.root}`]: {
		flexGrow: 1,
	},
}));

const Summary = (props) => {
	const { className, userGroup, loading } = props;

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return loading ?
			<SummaryLoading />
		:	<StyledGrid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<GeneralInfo userGroup={userGroup} />
				</Grid>
				<Grid {...gridProps}>
					<Settings userGroup={userGroup} />
				</Grid>
				<Grid {...gridProps}>
					<OtherActions userGroup={userGroup} />
				</Grid>
			</StyledGrid>;
};

Summary.propTypes = {
	className: PropTypes.string,
	userGroup: PropTypes.object,
	loading: PropTypes.bool,
};

export default Summary;
