import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		// display: 'flex'
	},
	divider: {
		backgroundColor: colors.grey[300],
	},
	card: {
		margin: theme.spacing(0, 3, 0, 12),
		flexBasis: '50%',
	},
	cardContent: {
		padding: theme.spacing(4, 5, 5, 5),
	},
	innerCard: {
		margin: theme.spacing(2, 0),
	},
	sideCard: {
		flexBasis: '25%',
		'& .MuiCardHeader-root': {
			padding: '0',
		},
		'& .MuiCardContent-root': {
			padding: theme.spacing(4, 5, 5, 5),
		},
	},
	imageContainer: {
		height: '122px',
		width: '122px',
		marginRight: theme.spacing(4),
	},
	imageSpacing: {
		objectFit: 'cover',
		width: '100%',
		height: '100%',
		margin: '0',
		padding: '0',
		cursor: 'pointer',
	},
	arrowLeft: {
		cursor: 'pointer',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
	},
	removedUser: {
		marginLeft: theme.spacing(1),
	},
}));
