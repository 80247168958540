import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	ButtonGroup,
	Portal,
	Stack,
	useTheme,
} from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridColumnVisibilityModel,
	GridFilterModel,
	GridPaginationModel,
	GridToolbarContainer,
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar, GridActionsCellItem } from '~components';
import { DayOfWeekEnum } from '~enums';
import selectedOrganisationAtom from '~features/access/atoms/selectedOrganisationAtom';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { BaseReference, WeeklySchedule } from '~interfaces';
import { time24FormatWithoutSeconds } from '~utils/dateUtils';

import SkcSchedule from '../../interfaces/skcSchedule';
import SkcSchedulesService from '../../services/skcSchedulesService';

const toolbarIdString = 'topology-participant-toolbar';

const service = new SkcSchedulesService();

interface RefProps {
	mutate: () => void;
}

interface AccessSchedulesDataGridProps {
	onEdit?: (value: BaseReference) => void;
	onDelete?: (value: BaseReference) => void;
	onCreate?: () => void;
}

const AccessSchedulesDataGrid = forwardRef<RefProps, AccessSchedulesDataGridProps>(
	({ onEdit, onDelete, onCreate }, ref) => {
		const { t } = useTranslation('general');
		const theme = useTheme();

		const { isSuperAdmin } = useAuthorize();
		const [selectedOrganisation, setSelectedOrganisation] = useAtom(selectedOrganisationAtom);

		const [fetchParameters, setFetchParameters] = useState<object | null>({
			organisationId: selectedOrganisation?.id
		});

		useEffect(() => {
			if (selectedOrganisation) {
				setFetchParameters(prev => ({
					...prev,
					organisationId: selectedOrganisation.id
				}));
			}
		}, [selectedOrganisation]);

		const [filterModel, setFilterModel] = useState<GridFilterModel>({
			items: [{
				field: 'organisation',
				operator: 'contains',
				value: selectedOrganisation
			}]
		});

		const columns: GridColDef<SkcSchedule>[] = useMemo(() => [
			{ field: 'id', headerName: 'ID', width: 80, hideable: false },
			{
				field: 'label',
				headerName: t('ui.label.name'),
				flex: 1,
			},
			{
				field: 'description',
				headerName: t('ui.label.description'),
				flex: 1,
				valueFormatter: (value: string) => value ?? '-',
			},
			{
				field: 'open',
				headerName: t('ui.open'),
				valueGetter: (_, row) => row.schedule,
				valueFormatter: (value: WeeklySchedule) =>
					value.isOpenAllDay ?
						t('ui.allDay')
						:	`${time24FormatWithoutSeconds.format(value.period.start)} - ${time24FormatWithoutSeconds.format(value.period.end)}`,
				flex: 1,
			},
			{
				field: 'daysOfWeek',
				headerName: t('daysOfWeek'),
				type: 'singleSelect',
				flex: 1,
				valueOptions: Object.values(DayOfWeekEnum),
				valueGetter: (_, row) => row.schedule.daysOfWeek,
				renderCell: (params) => (
					<ButtonGroup
						disabled
						// sx={{
						// 	'& .Mui-disabled': {
						// 		bgcolor: 'yellow'
						// 	}
						// }}
					>
						{Object.values(DayOfWeekEnum).map((el: DayOfWeekEnum) => (
							<Button
								key={el}
								sx={{
									color: params.value.includes(el) ? theme.palette.common.white : 'inherit',
									bgcolor: params.value.includes(el) ? theme.palette.primary.main : 'inherit',
								}}
							>
								{el[0]}
							</Button>
						))}
					</ButtonGroup>
				),
				// renderCell: (params) => (
				// 	<Stack direction='row' spacing={0.5} alignItems='center' height={1}>
				// 		{Object.values(DayOfWeekEnum).map((el: DayOfWeekEnum) =>
				// 			<Avatar
				// 				key={el}
				// 				sx={{
				// 					width: 24,
				// 					height: 24,
				// 					color: params.value.includes(el) ? 'inherit' : theme.palette.common.black,
				// 					bgcolor: params.value.includes(el) ? theme.palette.primary.main : 'transparent',
				// 					fontSize: '12px',
				// 				}}
				// 			>
				// 				{el[0]}
				// 			</Avatar>
				// 		)}
				// 	</Stack>
				// ),
			},
			// {
			// 	field: 'startTime',
			// 	headerName: t('startTime'),
			// 	valueGetter: (_, row) =>
			// 		!row.schedule.isOpenAllDay ? row.schedule.period.start : undefined,
			// 	valueFormatter: (value: Date) => value?.toLocaleTimeString(),
			// 	type: 'dateTime', // TODO: type time?
			// 	// flex: 1,
			// },
			// {
			// 	field: 'endTime',
			// 	headerName: t('endTime'),
			// 	valueGetter: (_, row) => (!row.schedule.isOpenAllDay ? row.schedule.period.end : undefined),
			// 	valueFormatter: (value: Date) => value?.toLocaleTimeString(),
			// 	type: 'dateTime', // TODO: type time?
			// 	// flex: 1,
			// },
			{
				field: 'actions',
				type: 'actions',
				display: 'text',
				width: 50,
				resizable: false,
				align: 'right',
				hideable: false,
				getActions: (params) => [
					<GridActionsCellItem
						key={`${params.id}-edit`}
						icon={<EditIcon />}
						label={t('ui.edit')}
						color='inherit'
						onClick={() => onEdit != null && onEdit({ id: params.id.toString(), label: params.row.label })}
						showInMenu
					/>,
					<GridActionsCellItem
						key={`${params.id}-delete`}
						icon={<DeleteOutlinedIcon color='error' />}
						label={t('ui.delete')}
						onClick={() => onDelete != null && onDelete({ id: params.id.toString(), label: params.row.label })}
						color='error'
						showInMenu
					/>,
				],
			},
		], []);

		const { data, isLoading, isValidating, error, mutate } = useSWR(
			fetchParameters ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getSchedules(args),
			{
				onSuccess: (res) => res?.total != null && setTotalCount(res.total),
				keepPreviousData: true,
			},
		);
		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
			organisation: isSuperAdmin(),
		});

		useImperativeHandle(ref, () => ({
			mutate: mutate,
		}), [mutate]);

		const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters(prev => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
			const organisation = model.items.length > 0 ? model.items[0].value : undefined;
			if (organisation) {
				// setFilterModel(prev => ({
				// 	...prev,
				// 	items: [{

				// 	}]
				// }))
				setFilterModel(model);
				setSelectedOrganisation(organisation);
			}
		};

		const handleColumnVisibilityModelChange = (newModel: GridColumnVisibilityModel) => {
			setColumnVisibilityModel(newModel);
		};

		const getTogglableColumns = (columns: GridColDef[]): string[] => {
			const hiddenFields = isSuperAdmin() ? [] : ['organisation'];
			const visibleColumns = columns.filter(
				(column) => column.hideable && !hiddenFields.includes(column.field),
			);
			return visibleColumns.map((column) => column.field);
		};

		return (
			<Stack height={1} spacing={2}>
				<Box id={toolbarIdString} minHeight={48} />
				<DataGrid
					snapshotKey='accs'
					columns={columns}
					rows={data?.results ?? []}
					rowCount={totalCount}
					loading={isLoading || isValidating}
					error={error}
					onPaginationModelChange={handlePaginationChange}
					filterModel={filterModel}
					onFilterModelChange={handleFilterModelChange}
					// columnVisibilityModel={columnVisibilityModel}
					// onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					initialState={{
						filter: {
							filterModel: {
								items: [{
									field: 'organisation',
									operator: 'contains',
									value: selectedOrganisation
								}]
							}
						}
					}}
					slots={{
						toolbar: Toolbar,
					}}
					slotProps={{
						toolbar: {
							onCreate: onCreate
						},
						columnsManagement: {
							getTogglableColumns,
						},
						noRowsOverlay: {
							title: t('noResourceFound', { resource: t('schedules') }),
						},
						noResultsOverlay: {
							title: t('noResourceFound', { resource: t('schedules') }),
						},
					}}
					sx={{ flexGrow: 1, flexShrink: 1, minHeight: 400 }}
				/>
			</Stack>
		);
	},
);
AccessSchedulesDataGrid.displayName = 'AccessSchedulesDataGrid';

const Toolbar = (props) => {
	const { t } = useTranslation('general');

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar disableClearableOrganisation>
				<Button variant='contained' onClick={() => props.onCreate != null && props.onCreate()}>
					{t('addResource', { resource: t('schedule') })}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default AccessSchedulesDataGrid;
