import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	sideCardContainer: {
		flexBasis: '18%',
		'& .MuiCardContent-root': {
			padding: theme.spacing(0, 4, 4, 4),
		},
	},
	rdwCard: {
		display: 'flex',
		flexDirection: 'row !important',
		alignItems: 'center',
		borderRadius: 10,
		padding: `${theme.spacing(0, 0.5)} !important`,
		backgroundColor: theme.palette.success.light,
	},
	dialogCard: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: theme.spacing(2),
	},
	rdwLabel: {
		...theme.typography.pagination,
	},
	rdwLabelRejected: {
		...theme.typography.pagination,
		color: theme.palette.error.main,
	},
	rdwIcon: {
		paddingRight: theme.spacing(0.5),
	},
	rdwIconRejected: {
		paddingRight: theme.spacing(0.5),
		backgroundColor: '#fff2f2',
	},
	rejectedLabel: {
		display: 'flex',
		flexDirection: 'row !important',
		alignItems: 'center',
		borderRadius: 10,
		padding: `${theme.spacing(1, 1)} !important`,
		backgroundColor: '#fff2f2',
	},
	duplicateCard: {
		display: 'flex',
		flexDirection: 'row !important',
		alignItems: 'center',
		borderRadius: 10,
		padding: `${theme.spacing(2, 0.5)} !important`,
		backgroundColor: theme.palette.error.light,
	},
	duplicateIcon: {
		paddingRight: theme.spacing(0.5),
	},
}));

const useStylesMyTooltip = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.white,
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
	tooltip: {
		backgroundColor: '#ffffff',
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
}));

export function MyTooltip(props) {
	const classes = useStylesMyTooltip();
	return <Tooltip classes={classes} {...props} />;
}
