import { useState, useEffect } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import { SwitchButton, NumberInput } from '../../../../components';
import { isUndefined, isObject } from '../../../../shared/utility';
import { useStyles } from '../style';

const Discount = (props) => {
	const {
		price,
		discount,
		setDiscount,
		applyingDiscount,
		setApplyingDiscount,
		disabled,

		setFetchBookingPrice,
		planboardBooking,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const { data: bookingData } = planboardBooking;

	const [checked, setChecked] = useState(applyingDiscount);

	useEffect(() => {
		if (!isUndefined(discount) && discount > 0 && !checked) {
			setChecked(true);
		}
	}, [discount]);

	useEffect(() => {
		setApplyingDiscount(checked);
	}, [checked]);

	useEffect(() => {
		if (!checked && discount > 0) {
			setDiscount(() => 0);
			setFetchBookingPrice(() => true);
		}
	}, [checked]);

	const debounceDiscount = useDebounce(discount, 1000);

	const handleSwitch = () => setChecked(!checked);

	useEffect(() => {
		if (
			isObject(bookingData) &&
			(debounceDiscount === discount || (isNaN(discount) && isNaN(debounceDiscount)))
		) {
			setFetchBookingPrice(() => true);
		}
	}, [debounceDiscount]);

	const handleNumber = (event) => {
		const value = parseFloat(event.target.value);
		if (value > price.price) {
			setDiscount(price.price);
		} else {
			setDiscount(value);
		}
	};

	if (!isObject(price)) {
		return null;
	}

	return (
		<div>
			<Accordion className={classes.accordionCard} elevation={0} expanded={checked}>
				<AccordionSummary className={classes.accordion}>
					<Box alignItems='center' display='flex' justifyContent='space-between'>
						<Box display='flex' flexDirection='column'>
							<Typography variant='h6'>{t('views.planboard.discount.applyDiscount')}</Typography>
							<Typography variant='body2'>{t('views.planboard.discount.toggle')}</Typography>
						</Box>
						<SwitchButton checked={checked} disabled={disabled} onChange={handleSwitch} />
					</Box>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<Box>
						{checked ?
							<NumberInput
								decimalSeparators={['.', ',']}
								disabled={disabled}
								fullWidth={true}
								onChange={handleNumber}
								size='medium'
								startAdornment={price.currencySymbol}
								thousandSeparator={true}
								value={discount}
								variant='outlined'
							/>
						:	null}

						<Box alignItems='center' display='flex' mt={1}>
							<Box mr={0.5}>
								<ErrorOutlineIcon fontSize='small' />
							</Box>
							<Typography variant='body2'>
								{t('views.planboard.addBooking.discount.warning')}
							</Typography>
						</Box>
					</Box>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

Discount.propTypes = {
	price: PropTypes.object,
	discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setDiscount: PropTypes.func,
	applyingDiscount: PropTypes.bool,
	setApplyingDiscount: PropTypes.func,
	disabled: PropTypes.bool,
	setFetchBookingPrice: PropTypes.func,
	planboardBooking: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		planboardBooking: state.details.planboardBooking,
	};
};

export default connect(mapStateToProps, null)(Discount);
