import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import FileReaderInput from 'react-file-reader-input';

import { useStyles } from './style';
import { StyledButton } from '../../../../components';

const FileReader = (props) => {
	const classes = useStyles();

	const { loading, setFileReaderData, setFileName, setLoading, name } = props;

	return (
		<FileReaderInput
			accept={'.csv'}
			as={'text'}
			multiple={false}
			onChange={(ev, results) => {
				setLoading(false);
				// eslint-disable-next-line no-unused-vars
				const [progressEvent, file] = results[0];
				setFileReaderData(file);
				setFileName(file.name);
			}}
			onInput={() => {
				setLoading(true);
			}}
		>
			<StyledButton className={classes.root} size='medium' variant='contained-tertiary'>
				{loading ?
					<CircularProgress color='info' size={24} />
				:	name}
			</StyledButton>
		</FileReaderInput>
	);
};

FileReader.propTypes = {
	setFileName: PropTypes.func.isRequired,
	setFileReaderData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
};

export default FileReader;
