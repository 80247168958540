import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	selectWithLabel: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiInputBase-input': {
			display: 'flex',
			justifyContent: 'space-between',
			whiteSpace: 'normal',
		},
		'& .MuiFormHelperText-root': {
			position: 'relative !important',
			marginTop: `${theme.spacing(1)} !important`,
		},
	},
	menuItem: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));
