import { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog, FormField } from '../../../../../../components';
import { useWizardFormField } from '../../../../../../shared/hooks';
import * as actions from '../../../../../../store/actions/index';

const EditDevice = (props) => {
	const {
		open,

		onClose,
		deviceId,
		name,
		onUpdateDeviceName,
		updateDeviceName,
		onResetState,
		onUpdateDetailsState,
	} = props;
	const { t } = useTranslation('general');

	const {
		data: updateDeviceNameData,
		loading: updateDeviceNameLoading,
		error: updateDeviceNameError,
	} = updateDeviceName;

	const deviceName = useWizardFormField(name || '', {
		minLength: 3,
		maxLength: 30,
		required: true,
	});

	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		onClose();
		deviceName.resetToInitialValue();
	};

	const handleConfirmEditDeviceName = () => onUpdateDeviceName(deviceId, deviceName.value);

	useEffect(() => {
		if (updateDeviceNameData) {
			enqueueSnackbar(t('ui.label.edit.deviceName.success'), { variant: 'success' });
			onResetState('updateDeviceName');
			onClose();
			onUpdateDetailsState('fetchDevice', updateDeviceNameData);
		} else if (updateDeviceNameError) {
			enqueueSnackbar(updateDeviceNameError?.message ? updateDeviceNameError.message : 'error', {
				variant: 'error',
			});
			onResetState('updateDeviceName');
		}
	}, [updateDeviceNameData, updateDeviceNameError]);

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirmEditDeviceName,
				text:
					updateDeviceNameLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleClose}
			loading={!deviceName.isValid || deviceName.value === name || updateDeviceNameLoading}
			open={open}
			title={t('ui.label.edit.deviceName.title')}
		>
			<FormField
				hideCharacterCounter={!deviceName.hasFocus}
				label={t('ui.label.deviceName')}
				maxLength={deviceName.validationOptions.maxLength}
				name={'deviceName'}
				placeholder={t('ui.label.edit.deviceName.placeholder')}
				required={true}
				variable={deviceName}
			/>
		</ActionDialog>
	);
};

EditDevice.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	name: PropTypes.string,
	onUpdateDeviceName: PropTypes.func,
	deviceId: PropTypes.string,
	onResetState: PropTypes.func,
	onUpdateDetailsState: PropTypes.func,
	updateDeviceName: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		updateDeviceName: state.details.updateDeviceName,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateDeviceName: (deviceId, deviceName) =>
			dispatch(actions.updateDeviceName(deviceId, deviceName)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDevice);
