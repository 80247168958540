import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	extraButton: {
		width: 'max-content',
		height: 40.26,
		marginLeft: theme.spacing(2),
	},
}));
