export const providersReducer = (state, action) => {
	const { providers, consumers, items, consumerId, providerId, item, consumerType, total } = action;

	switch (action.type) {
		case 'add':
			return state.concat(
				providers.map((provider) => {
					return {
						...provider,
						isShowingConsumers: false,
						isLoadingConsumers: true,
						consumersPage: { pageNumber: 1, pageSize: 10 },
						consumers: [],
					};
				}),
			);
		case 'addConsumers':
			return state.map((provider) => ({
				...provider,
				total: total,
				isLoadingConsumers: provider.id === action.id ? false : provider.isLoadingConsumers,
				consumers:
					provider.id === action.id ?
						consumers.map((consumer) => ({
							...consumer,
							items: [],
							selectedItems: [],
							itemsPage: { pageNumber: 1, pageSize: 5 },
							isShowingItems: false,
							isLoadingItems: true,
						}))
					:	provider.consumers,
			}));
		case 'addItems':
			return state.map((provider) =>
				provider.id === providerId ?
					{
						...provider,
						consumers: provider.consumers.map((consumer) =>
							consumer.id === consumerId ?
								{
									...consumer,
									isLoadingItems: consumer.id === consumerId ? false : consumer.isLoadingItems,
									items: items.map((cItem) =>
										(
											consumer.selectedItems.find(
												(selectedItem) => selectedItem.item.id === cItem.item.id,
											)
										) ?
											{ ...cItem, selected: true }
										:	cItem,
									),
									total: total,
								}
							:	consumer,
						),
					}
				:	provider,
			);
		case 'addInternalAndPublicItems':
			return state.map((provider) =>
				provider.id === providerId ?
					{
						...provider,
						consumers: provider.consumers.map((consumer) =>
							consumer.type === consumerType ?
								{
									...consumer,
									isLoadingItems: consumer.type === consumerType ? false : consumer.isLoadingItems,
									items: items.map((cItem) =>
										(
											consumer.selectedItems.find(
												(selectedItem) => selectedItem.item.id === cItem.item.id,
											)
										) ?
											{ ...cItem, selected: true }
										:	cItem,
									),
									total: total,
								}
							:	consumer,
						),
					}
				:	provider,
			);
		case 'toggleShowingConsumers':
			return state.map((provider) =>
				provider.id === item.providerId ?
					{ ...provider, isShowingConsumers: !item.isExpanded }
				:	{ ...provider, isShowingConsumers: false },
			);
		case 'toggleShowingItems':
			return state.map((provider) =>
				provider.id === item.providerId ?
					{
						...provider,
						consumers: provider.consumers.map((consumer) => {
							if (consumer.type === item.type && consumer.id === item.id) {
								return { ...consumer, isShowingItems: !item.isShowingItems };
							} else {
								return consumer;
							}
						}),
					}
				:	provider,
			);
		case 'updateConsumersPage':
			return state.map((provider) =>
				provider.id === item.providerId ?
					{
						...provider,
						isLoadingConsumers: true,
						consumersPage:
							item?.pageSize ?
								{ pageNumber: 1, pageSize: item.pageSize }
							:	{ ...provider.consumersPage, pageNumber: item.pageNumber },
					}
				:	provider,
			);
		case 'updateItemsPage':
			return state.map((provider) =>
				provider.id === item.providerId ?
					{
						...provider,
						consumers: provider.consumers.map((consumer) => {
							if (consumer.type === item.type && consumer.id === item.id) {
								return {
									...consumer,
									isLoadingItems: true,
									isShowingItems: true,
									itemsPage:
										item?.pageSize ?
											{ pageNumber: 1, pageSize: item.pageSize }
										:	{ ...consumer.itemsPage, pageNumber: item.pageNumber },
								};
							} else {
								return consumer;
							}
						}),
					}
				:	provider,
			);
		case 'selectAllItems':
			return state.map((provider) =>
				provider.id === item.providerId ?
					{
						...provider,
						consumers: provider.consumers.map((consumer) =>
							consumer.type === item.type && consumer.id === item.consumerId ?
								{
									...consumer,
									selectedItems: consumer.selectedItems.concat(
										item.items.filter(
											(sItem) => sItem.pageNumber === consumer.itemsPage.pageNumber,
										),
									),
									// selectedItems: item.items.map(sItem => ({ ...sItem, pageNumber: consumer.itemsPage.pageNumber })),
									items: consumer.items.map((consumerItem) =>
										consumerItem.canEdit ?
											{
												...consumerItem,
												selected: item.selected,
											}
										:	{ ...consumerItem, selected: false },
									),
								}
							:	consumer,
						),
					}
				:	provider,
			);
		case 'selectItem':
			return state.map((provider) =>
				provider.id === item.providerId ?
					{
						...provider,
						consumers: provider.consumers.map((consumer) =>
							consumer.type === item.type && consumer.id === item.consumerId ?
								{
									...consumer,
									selectedItems:
										item?.selected ?
											consumer.selectedItems.concat([
												{
													item: item.item,
													userGroup: item.userGroup,
													pageNumber: consumer.itemsPage.pageNumber,
												},
											])
										:	consumer.selectedItems.filter(
												(selectedItem) =>
													!(
														item.item.id === selectedItem.item.id &&
														item.userGroup?.id === selectedItem.userGroup?.id
													),
											),
									items: consumer.items.map((consumerItem) =>
										(
											consumerItem.item.id === item.item.id &&
											consumerItem.userGroup?.id === item.userGroup?.id
										) ?
											{
												...consumerItem,
												selected: item?.selected,
											}
										:	consumerItem,
									),
								}
							:	consumer,
						),
					}
				:	provider,
			);
		case 'removeAllItems':
			return state.map((provider) =>
				provider.id === item.providerId ?
					{
						...provider,
						consumers: provider.consumers.map((consumer) =>
							consumer.type === item.type && consumer.id === item.consumerId ?
								{
									...consumer,
									selectedItems: consumer.selectedItems.filter(
										(rItme) => rItme.pageNumber !== consumer.itemsPage.pageNumber,
									),
									items: consumer.items.map((consumerItem) =>
										consumerItem.canEdit ?
											{
												...consumerItem,
												selected: false,
											}
										:	consumerItem,
									),
								}
							:	consumer,
						),
					}
				:	provider,
			);
		case 'empty':
			return (state = []);
		default:
	}
};
