import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
	Drawer,
	Typography,
	Divider,
	FormControl,
	TextField,
	FormControlLabel,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { useStyles } from './style';
import { SwitchButton, StyledButton } from '../../../../../../components';
import {
	modifyDate,
	stringifyTime,
	unsetTime,
	weekdays,
	weekend,
	weekOptions,
	timeSlots,
} from '../../../../../../shared/datetime';
import {
	isFullString,
	isFullArray,
	isEmptyArray,
	isInteger,
	ignoreKeyClick,
} from '../../../../../../shared/utility';

function CustomNumberFormat(props) {
	const { inputRef, onChange, ...other } = props;
	const { t } = useTranslation('general');

	return (
		<NumericFormat
			{...other}
			allowNegative={false}
			decimalScale={2}
			fixedDecimalScale
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			prefix={'€'}
			thousandSeparator
		/>
	);
}

CustomNumberFormat.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

const UpdatePerMinute = (props) => {
	const {
		className,
		open,
		onClose,
		periodLabel,
		pricingPerMinuteModel,
		pricingPerMinute,
		periodType,
		setPricingPerMinute,
		save,
		createPricingModel,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const [isEdit] = useState(isInteger(pricingPerMinute.dayOfWeek));
	const [selectedDays, setSelectedDays] = useState(
		weekOptions.filter((item) =>
			isEdit ? item.id === pricingPerMinute.dayOfWeek : item.id === pricingPerMinute.start.getDay(),
		),
	);

	const updatedModels =
		!isEdit ? pricingPerMinuteModel : (
			pricingPerMinuteModel.reduce((acc, cur) => {
				const updatedModel =
					cur.id !== pricingPerMinute.id ?
						cur
					:	{ ...cur, days: cur.days.filter((day) => day.id !== pricingPerMinute.dayOfWeek) };

				return [...acc, ...(isFullArray(updatedModel.days) ? [updatedModel] : [])];
			}, [])
		);

	const forceDisable =
		!pricingPerMinute.allDay &&
		pricingPerMinute.end.getHours() === 0 &&
		pricingPerMinute.end.getMinutes() === 0;
	const disableSave =
		isEmptyArray(selectedDays) ||
		isFullArray(
			updatedModels.filter((model) => {
				const daysToAdd = model.start.getDate() !== model.end.getDate() ? 1 : 0;

				const selectedStart = modifyDate(model.start, { date: pricingPerMinute.start.getDate() });
				const selectedEnd = modifyDate(model.end, {
					date: pricingPerMinute.start.getDate() + daysToAdd,
				});

				const endOfDay =
					pricingPerMinute.end.getHours() === 23 && pricingPerMinute.end.getMinutes() === 59;
				const end = modifyDate(endOfDay ? pricingPerMinute.start : pricingPerMinute.end, {
					...(endOfDay && { hours: 0, minutes: 0, date: '+1' }),
				});
				return (
					isFullArray(
						model.days.filter((day) =>
							isFullArray(selectedDays.filter((selectedDay) => day.id === selectedDay.id)),
						),
					) &&
					((pricingPerMinute.start.getTime() <= selectedStart.getTime() &&
						end.getTime() >= selectedEnd.getTime()) ||
						(pricingPerMinute.start.getTime() >= selectedStart.getTime() &&
							end.getTime() <= selectedEnd.getTime()))
				);
			}),
		);

	const disabledTimes = updatedModels.reduce((acc, cur) => {
		const endOfDay = cur.end.getHours() === 0 && cur.end.getMinutes() === 0;
		const selectedTimes = cur.days
			.filter((day) => isFullArray(selectedDays.filter((selected) => selected.id === day.id)))
			.map(() => ({
				start: stringifyTime(cur.start),
				end: stringifyTime(modifyDate(cur.end, { ...(endOfDay && { hours: 23, minutes: 59 }) })),
			}));

		return [...acc, ...selectedTimes];
	}, []);

	const handlePriceChange = (event) => {
		// let newValue = parseInt(event.target.value, 10);
		setPricingPerMinute({
			...pricingPerMinute,
			price: parseFloat(event.target.value).toFixed(2),
		});
	};

	const getOptionLabel = (selected) => {
		return selected.name;
	};

	const toggleAllDay = () => {
		const start = unsetTime(new Date());

		if (!isFullArray(disabledTimes)) {
			setPricingPerMinute({
				...pricingPerMinute,
				start: start,
				end: modifyDate(start, { hours: 23, minutes: 59 }),
				allDay: !pricingPerMinute.allDay,
			});
		}
	};

	const handleAutocomplete = (event, values) => {
		const updatedOptions = values.reduce((accumulator, currentValue) => {
			if (currentValue.id === 7) {
				return [...weekdays, ...weekend];
			} else if (currentValue.id === 8) {
				return [...weekdays];
			} else if (currentValue.id === 9) {
				return [...weekend];
			} else {
				return [...accumulator, currentValue];
			}
		}, []);
		setSelectedDays(updatedOptions);
	};

	const handlePricingClose = () => {
		onClose();
	};

	const handleStartTimeChange = (event, value) => {
		if (isFullString(value)) {
			const modifiedStart = modifyDate(pricingPerMinute.start, {
				hours: parseInt(value.slice(0, 2), 10),
				minutes: parseInt(value.slice(3, 5), 10),
			});
			if (modifiedStart.getTime() < pricingPerMinute.end.getTime()) {
				const updatedTime = {
					...pricingPerMinute,
					start: modifiedStart,
					// allDay: modifiedStart.getDate() !== pricingPerMinute.end.getDate()
				};

				setPricingPerMinute(updatedTime);
			}
		}
	};

	const handleEndTimeChange = (event, value) => {
		if (isFullString(value)) {
			const hours = parseInt(value.slice(0, 2), 10);
			const minutes = parseInt(value.slice(3, 5), 10);
			const modifiedEnd = modifyDate(pricingPerMinute.end, {
				hours: hours,
				minutes: minutes,
				...((hours > 0 || minutes > 0) && { date: pricingPerMinute.start.getDate() }),
			});

			if (modifiedEnd.getTime() > pricingPerMinute.start.getTime()) {
				setPricingPerMinute({
					...pricingPerMinute,
					end: modifiedEnd,
					// allDay: modifiedEnd.getDate() !== pricingPerMinute.start.getDate()
				});
			}
		}
	};

	const handleDisabledOption = (option, isEnd = false) => {
		const totalOccurences = option === '00:00' || option === '23:59' ? 1 : 2;
		return (
			isFullArray(disabledTimes.filter((time) => time.start < option && time.end > option)) ||
			disabledTimes.filter((time) => time.start === option || time.end === option).length ===
				totalOccurences ||
			(!isEnd ?
				option >= stringifyTime(pricingPerMinute.end)
			:	option <= stringifyTime(pricingPerMinute.start))
		);
	};

	const handleDisabledOptionStart = (option) => handleDisabledOption(option);
	const handleDisabledOptionEnd = (option) => handleDisabledOption(option, true);

	const handlePricingAdd = () => {
		const priceValue = createPricingModel(
			Date.now(),
			pricingPerMinute.start,
			pricingPerMinute.end,
			pricingPerMinute.allDay,
			pricingPerMinute.price,
			periodType,
			selectedDays,
		);

		if (isEdit) {
			priceValue.isEdit = true;
			priceValue.initialModelId = pricingPerMinute.id;
			priceValue.dayOfWeek = pricingPerMinute.dayOfWeek;
		}

		save({ pricePerMinute: priceValue, pricePerMinuteRemove: null });
		onClose();
	};

	const handlePricingRemove = () => {
		save({ pricePerMinuteRemove: pricingPerMinute, pricePerMinute: null });
		onClose();
	};

	const handleKeyUp = (event) => {
		ignoreKeyClick(event, 'Enter');
	};

	const timeComponent = [
		{
			label: 'from',
			name: t('ui.from'),
			disabled: pricingPerMinute.allDay,
			options: timeSlots,
			action: handleStartTimeChange,
			value: stringifyTime(pricingPerMinute.start),
			disabledValue: handleDisabledOptionStart,
		},
		{
			label: 'to',
			name: t('ui.to'),
			disabled: pricingPerMinute.allDay,
			options: timeSlots,
			action: handleEndTimeChange,
			value: stringifyTime(pricingPerMinute.end),
			disabledValue: handleDisabledOptionEnd,
		},
	];

	return (
		<Drawer
			anchor='right'
			className={clsx(classes.root, className)}
			classes={{ paper: classes.drawer }}
			onClose={handlePricingClose}
			open={open}
			variant='temporary'
		>
			<div>
				<StyledButton
					onClick={handlePricingClose}
					startIcon={<CloseIcon />}
					variant='inline-default'
				>
					{t('ui.button.inline.close')}
				</StyledButton>
				<Typography className={classes.header} variant='h3'>
					{isEdit ?
						`${t('ui.editPrice')} ${periodLabel.toLowerCase()}`
					:	`${t('ui.price')} ${periodLabel.toLowerCase()}`}
				</Typography>
				<Divider className={classes.divider} />
				<form>
					<TextField
						InputLabelProps={{ shrink: true }}
						InputProps={{ inputComponent: CustomNumberFormat }}
						fullWidth
						label={t('ui.amount')}
						name='amountPer'
						onChange={handlePriceChange}
						onKeyPress={handleKeyUp}
						size='medium'
						value={pricingPerMinute.price}
						variant='outlined'
					/>
				</form>
				<Divider className={classes.divider} />
				<Autocomplete
					ListboxProps={{ style: { maxHeight: '210px' } }}
					// disabled={isEdit}
					filterSelectedOptions={true}
					getOptionLabel={getOptionLabel}
					multiple
					onChange={handleAutocomplete}
					options={weekOptions}
					renderInput={(params) => (
						<TextField
							{...params}
							InputLabelProps={{ shrink: true }}
							fullWidth
							label={t('ui.label.days')}
							size='medium'
							variant='outlined'
						/>
					)}
					value={selectedDays}
				/>
				<Divider className={classes.divider} />
				{periodType === 'minute' || periodType === 'hours' ?
					<div className={classes.timeSection}>
						{timeComponent.map((item) => (
							<div className={classes.saction} key={item.label}>
								<Autocomplete
									disabled={item.disabled}
									getOptionDisabled={item.disabledValue}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: true }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={item.value}
								/>
							</div>
						))}
					</div>
				:	null}
				<div className={classes.allDaySpacing}>
					<FormControl disabled={isFullArray(disabledTimes)} variant='standard'>
						<FormControlLabel
							control={
								<SwitchButton
									checked={pricingPerMinute.allDay && !isFullArray(disabledTimes)}
									onChange={toggleAllDay}
								/>
							}
							label={
								pricingPerMinute.allDay ?
									<Typography color='primary' variant='h6'>
										{t('ui.allDay')}
									</Typography>
								:	<Typography variant='h6'>{t('ui.selectAllDay')}</Typography>
							}
							labelPlacement='start'
						/>
					</FormControl>
				</div>
			</div>
			<div>
				<StyledButton
					disabled={forceDisable || disableSave}
					fullWidth
					onClick={handlePricingAdd}
					variant='contained-secondary'
				>
					{t('ui.button.contained.save')}
				</StyledButton>
				{isEdit ?
					<StyledButton
						className={classes.removeButton}
						fullWidth
						onClick={handlePricingRemove}
						variant='contained-delete'
					>
						{t('ui.button.contained.remove')}
					</StyledButton>
				:	null}
			</div>
		</Drawer>
	);
};

UpdatePerMinute.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	periodLabel: PropTypes.string,
	currency: PropTypes.string,
	save: PropTypes.func,
	pricingPerMinute: PropTypes.object,
	pricingPerMinuteModel: PropTypes.array,
	periodType: PropTypes.string,
	setPricingPerMinute: PropTypes.func,
	createPricingModel: PropTypes.func,
};

export default UpdatePerMinute;
