import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	content: {
		padding: 12,
	},
	mapLink: {
		...theme.link.body3,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
}));
