import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	tabs: {
		marginTop: theme.spacing(3),
	},
	divider: {
		backgroundColor: colors.grey[300],
	},
	content: {
		marginTop: theme.spacing(3),
	},
	panel: {
		marginLeft: theme.spacing(4),
	},
	panelBorder: {
		'& .MuiExpansionPanelSummary-content': {
			margin: 0,
		},
	},
	expansionDetails: {
		padding: theme.spacing(1, 3),
	},
	removeButton: {
		margin: theme.spacing(2, 0),
	},
	stepActionFooterContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	stepActionFooter: {
		maxWidth: '60rem',
		flex: 1,
		marginBottom: '4rem',
		marginTop: '2rem',
		display: 'flex',
		justifyContent: 'space-between',

		borderTop: `.05rem solid ${colors.grey[300]}`,
		paddingTop: theme.spacing(3),
	},
	columns: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: 'auto',
		maxWidth: '80rem',
	},
	mainColumn: {
		flex: '1 1 53rem',
		maxWidth: '53rem',
		marginRight: '2rem',
		'&:only-child': {
			marginRight: '27rem',
			marginLeft: 'auto',
		},
	},
	wizardContentWrapper: {
		padding: theme.spacing(6, 3, 3),
	},
	sidebarColumn: {
		position: 'relative',
		flex: '0 0 25rem',
	},
	showAllButton: {
		margin: theme.spacing(2, 0),
	},
}));
