import ApartmentIcon from '@mui/icons-material/Apartment';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import {
	Card,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Label, ItemCard } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { isFullArray, handleHubReference } from '../../../../../shared/utility';
import { contractTypes } from '../../../constants';
import { useStyles } from '../style';

const ContractInfo = (props) => {
	const { contractData } = props;
	const { t } = useTranslation('general');
	const location = useLocation();

	const classes = useStyles();
	const navigate = useNavigate();

	const data = [
		{
			label: 'views.ItemDetail.activities.start',
			value: contractData?.start && commaTimeStrings(contractData.start),
		},
		{
			label: 'views.ItemDetail.activities.end',
			value: contractData?.end && commaTimeStrings(contractData.end),
		},
		{ label: 'ui.provider', value: contractData.itemInstance.hub.organisation.name },
	];

	const handleItemView = () => {
		navigate(
			`/item-management/${contractData?.itemInstance?.item?.id}/instance/${contractData.itemInstance.id}/summary`,
			{
				state: { from: location.pathname, label: t('ui.label.contracts') },
			},
		);
	};

	return (
		<Card className={classes.cardRoot}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>{t('views.contract.details.contractInfo')}</Typography>
				<Label type={contractTypes[contractData.status]}>
					{t(`ui.label.contract.status.${contractData.status}`)}
				</Label>
			</Box>
			<Divider />
			<CardContent className={classes.content}>
				<ItemCard
					address={handleHubReference(contractData.itemInstance.hub)}
					addressIcon={<RoomOutlinedIcon fontSize='small' />}
					handleItemView={handleItemView}
					iconButton={true}
					image={
						isFullArray(contractData?.itemInstance?.item?.images) ?
							contractData.itemInstance.item.images[0]
						:	null
					}
					instanceName={contractData.itemInstance.name}
					locationIcon={<ApartmentIcon fontSize='small' />}
					locationName={contractData.itemInstance.hub.name}
					name={contractData.itemInstance.item.name}
				/>
				<Table>
					<TableBody>
						{data.map((contract) => (
							<TableRow key={contract.label}>
								<TableCell>{t(contract.label)}</TableCell>
								<TableCell>{contract?.value ? contract.value : '-'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

ContractInfo.propTypes = {
	className: PropTypes.string,

	contractData: PropTypes.object,
};

export default ContractInfo;
