import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
	root: {},
	arrowLeft: {
		cursor: 'pointer',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
	},
	buttonsCancel: {
		borderRadius: '19px',
		marginLeft: '16px',
		color: '#CE2A3D',
		borderColor: '#CE2A3D',
	},
}));
