import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
	AccordionDetails,
	Divider,
	Box,
	Checkbox,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Table,
	IconButton,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Headers from './Header';
import { LoadingBar } from '../../../components';
import { TableEditBar } from '../../../components/elements';
import { isFullArray } from '../../../shared/utility';
import { useStyles, Accordion } from '../Management/style';

const ConsumerTable = (props) => {
	const {
		title,
		managementHeaders,

		body,
		selectionEvents,
		headers,
		setRowsPerPage,
		itemsActions,
		total,
		handlePageChange,
		page,
		rowsPerPage,
		selection,
		editActionButtons,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const handleChangeRowsPerPage = (event) => setRowsPerPage(event.target.value);

	const onPageChange = (e, page) => handlePageChange(page + 1);

	const handleLabelDisplayedRows = ({ from, to, count }) =>
		`${from}-${to} ${t('ui.of')} ${count !== -1 ? count : '0'}`;

	const actions = {
		changeRows: handleChangeRowsPerPage,
		changePage: onPageChange,
		displayedRows: handleLabelDisplayedRows,
	};

	const handleExpanded = (consumer) => selectionEvents.onShowItems(consumer);

	const handleFilterItem = (consumer) => {
		const filteredObj = Object.fromEntries(
			// eslint-disable-next-line no-unused-vars
			Object.entries(consumer).filter(([key, value]) => !['items', 'results'].includes(key)),
		);
		return filteredObj;
	};

	const filterResults = (items) => {
		const selectedItems = items
			.filter((item) => item.canEdit && item)
			.map((itemRest) => {
				// eslint-disable-next-line no-unused-vars
				const { results, selected, canEdit, ...rest } = itemRest;
				return rest;
			});
		return selectedItems;
	};

	const handleSelectedAllItems = (items) => {
		selectionEvents.onSelectedAllItems({ ...items, selecteAll: isFullArray(items.items) });
	};

	const handleSelectedItem = (item) => {
		selectionEvents.onSelectedItem(item);
	};

	return (
		<div className={classes.root}>
			<Card className={classes.mainCard}>
				<CardHeader title={title} />
				<Divider />
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell sx={{ width: 60 }}>
											<Box width='28px'></Box>
										</TableCell>
										{managementHeaders.map((headers, hIndex) => (
											<TableCell key={hIndex}>{t(headers.content)}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{body.map((consumer, cIndex) => (
										<TableRow key={cIndex}>
											<TableCell colSpan={11} style={{ padding: 0 }}>
												<Accordion expanded={consumer.isExpanded}>
													<Table>
														<TableBody>
															<TableRow>
																<TableCell sx={{ border: 'none', width: 60 }}>
																	<IconButton
																		aria-label='expand-provider'
																		onClick={() => handleExpanded(consumer)}
																		size='small'
																		style={{ padding: 0 }}
																	>
																		{consumer.isExpanded ?
																			<ArrowDropUpIcon />
																		:	<ArrowDropDownIcon />}
																	</IconButton>
																</TableCell>
																{consumer.results.map((result, rIndex) => (
																	<TableCell
																		key={rIndex}
																		sx={consumer.isExpanded ? {} : { border: 'none' }}
																	>
																		{result.loading && rIndex === 0 ?
																			<LoadingBar />
																		:	result.content}
																	</TableCell>
																))}
															</TableRow>
														</TableBody>
													</Table>
													<AccordionDetails style={{ padding: 0, borderBottom: 'none' }}>
														<Table>
															<TableHead>
																<TableRow>
																	{/* business - price */}
																	{/* <TableCell sx={{ width: 60, border: 'none', padding: 0 }}><Box></Box></TableCell> */}
																	<TableCell sx={{ width: 60, border: 'none', padding: 0 }}>
																		<Box width={60}></Box>
																	</TableCell>
																	<TableCell>
																		<Checkbox
																			checked={consumer.selecteAll ? consumer.selecteAll : false}
																			indeterminate={
																				consumer.indeterminate ? consumer.indeterminate : false
																			}
																			onChange={(event) =>
																				event.target.checked ?
																					handleSelectedAllItems({
																						consumerId: consumer.consumerId,
																						type: consumer.type,
																						items: filterResults(consumer.items),
																						pageNumber: consumer.itemsPage.pageNumber,
																						consumerName: consumer.consumerName,
																					})
																				:	handleSelectedAllItems({
																						consumerId: consumer.consumerId,
																						type: consumer.type,
																						items: [],
																						pageNumber: consumer.itemsPage.pageNumber,
																						consumerName: consumer.consumerName,
																					})
																			}
																			sx={{ padding: 0 }}
																		/>
																	</TableCell>
																	{headers.map((cell, cIndex) => (
																		<Headers cIndex={cIndex} cell={cell} key={cell.name} />
																	))}
																</TableRow>
															</TableHead>
															<TableBody>
																{consumer?.items &&
																	consumer.items.map((item, itemIndex) => (
																		<TableRow key={itemIndex}>
																			<TableCell sx={{ width: 60, border: 'none', padding: 0 }}>
																				<Box></Box>
																			</TableCell>
																			<TableCell>
																				<Checkbox
																					checked={item?.selected ? item.selected : false}
																					disabled={!item.canEdit}
																					onChange={(event) =>
																						handleSelectedItem({
																							selected: event.target.checked,
																							item: item.item,
																							type: consumer.type,
																							consumerId: consumer.consumerId,
																							userGroup: item.userGroup,
																							pageNumber: consumer.itemsPage.pageNumber,
																							consumerName: item.consumerName,
																						})
																					}
																					sx={{ padding: 0 }}
																				/>
																			</TableCell>
																			{item?.results ?
																				item.results.map((result, rIndex) => (
																					//  <TableCell key={rIndex}> business - price
																					<TableCell
																						key={rIndex}
																						sx={rIndex === 1 ? { width: '5%' } : { width: '17%' }}
																					>
																						{result.loading ?
																							<LoadingBar />
																						:	result.content}
																					</TableCell>
																				))
																			:	<TableCell colSpan={7}>
																					{' '}
																					<LoadingBar />
																				</TableCell>
																			}
																		</TableRow>
																	))}
															</TableBody>
														</Table>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell sx={{ width: 230, border: 'none', padding: 0 }}>
																		<Box width='230px'></Box>
																	</TableCell>
																	<TableCell colSpan={11} sx={{ border: 'none', padding: 0 }}>
																		{consumer?.total || consumer.total === 0 ?
																			<CardActions className={classes.actions}>
																				<TablePagination
																					component='div'
																					count={consumer.total}
																					labelDisplayedRows={actions.displayedRows}
																					labelRowsPerPage={t('ui.rowsPerPage')}
																					onPageChange={(event, page) =>
																						itemsActions.changePageNumber(
																							page,
																							handleFilterItem(consumer),
																						)
																					}
																					onRowsPerPageChange={(event) =>
																						itemsActions.changePageSize(
																							event.target.value,
																							handleFilterItem(consumer),
																						)
																					}
																					page={consumer.itemsPage.pageNumber - 1}
																					rowsPerPage={consumer.itemsPage.pageSize}
																					rowsPerPageOptions={[5, 10, 20, 40, 50]}
																				/>
																			</CardActions>
																		:	null}
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
				{total ?
					<CardActions className={classes.actions}>
						<TablePagination
							component='div'
							count={total}
							labelDisplayedRows={actions.displayedRows}
							labelRowsPerPage={t('ui.rowsPerPage')}
							onPageChange={actions.changePage}
							onRowsPerPageChange={actions.changeRows}
							page={!total ? 0 : page - 1}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={[5, 10, 20, 40, 50]}
						/>
					</CardActions>
				:	null}
			</Card>
			{isFullArray(selection) ?
				<TableEditBar actionButtons={editActionButtons} selected={selection} />
			:	null}
		</div>
	);
};

ConsumerTable.propTypes = {
	title: PropTypes.string,
	managementHeaders: PropTypes.array,

	body: PropTypes.array,
	selectionEvents: PropTypes.object,
	headers: PropTypes.array,
	setRowsPerPage: PropTypes.func,
	itemsActions: PropTypes.object,
	total: PropTypes.number,
	handlePageChange: PropTypes.func,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	selection: PropTypes.array,
	editActionButtons: PropTypes.array,
};

export default ConsumerTable;
