import { useEffect, useState } from 'react';

import { isObject } from './utility';

function useExport(data) {
	const { label, exportData } = data;

	const [download, setDownload] = useState(false);

	const [exportType, setExportType] = useState('csv');

	const { data: exportDataFile, loading: exportDataLoading, error: exportDataError } = exportData;
	const ready = isObject(exportDataFile) && !exportDataLoading && !exportDataError;

	useEffect(() => {
		if (ready && download) {
			const link = document.createElement('a');
			link.href = URL.createObjectURL(exportDataFile);
			link.download = `${label}.${exportType}`;
			link.click();
			setDownload(false);
		}
	}, [exportDataLoading, download]);

	return { setDownload, exportType, setExportType };
}

export { useExport };
