// TODO: there are more commands
// Check commandtype in backend
enum DeviceCommandEnum {
	GetHeartbeat = 'requestHeartbeat',
	GetVehicleState = 'getVehicleState',
	Lock = 'lock',
	/**
	 * Lock through a "normal" key.
	 * Enables any car alarm systems and eventually sends the car into standby mode.
	 */
	Secure = 'secure',
	FlashLights = 'flashLights',
	Unlock = 'unlock',
	DetectCards = 'detectCards',
	EnableImmobiliser = 'enable', // E.g. for Cpac
	DisableImmobiliser = 'disable', // E.g. for Cpac
	Reboot = 'reboot',
	StartRental = 'start',
	StopRental = 'stop',
}

export default DeviceCommandEnum;
