import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	itemGroup: {
		...theme.typography.link1,
	},
	cellStyle: {
		whiteSpace: 'nowrap',
	},
	userCard: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '0 1px',
	},
	content: {
		padding: theme.spacing(1.5, 1, 1.5, 1.5),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 'auto',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(0),
		},
	},
	detailTitle: {
		textDecoration: 'underline',
		...theme.typography.body3,
	},
}));
