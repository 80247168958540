import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Link, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { EmptyState, SearchBar, Table, GenericMoreButton, AlertDialog } from '../../../components';
import {
	usePrevious,
	useError,
	useCloseTimeout,
	useSearchComponent,
	useComplexFilter,
	usePagination,
} from '../../../shared/hooks';
import {
	isEmptyString,
	isEmptyArray,
	isObject,
	handleCultureCode,
	isUndefined,
	isFullArray,
	isSuperAdmin,
	isInteger,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { MyTooltip } from '../../Devices/DevicesList/style';

const TermsAndConditions = (props) => {
	const {
		className,
		tcList,
		onFetchTerms,
		onDeleteTerm,
		deleteTerm,
		updateTerm,
		organisationsList,
		onFetchOrganisations,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useAuth();

	const classes = useStyles();
	const [shouldSearch, setShouldSearch] = useState(false);

	const [sortingCategory, setSortingCategory] = useState('id');
	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(false);
	const [shouldSort, setShouldSort] = useState(false);

	const [shouldFilter, setShouldFilter] = useState(false);
	const { data: tcData, loading: tcLoading, error: tcError } = tcList;
	const tcReady = isObject(tcData) && !tcLoading && !tcError;

	const { loading: updateTermLoading } = updateTerm;

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [termsId, setTermsId] = useState(null);
	const [deletedTermIndex, setDeletedTermIndex] = useState(null);

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deleteTerm;
	const deleteDone = deleteSuccess && !deleteLoading && !deleteError;

	const loadingPrevTerms = usePrevious(updateTermLoading);

	const [termsTitle, setTermsTitle] = useState(null);

	const termsOrganisationFilter = useComplexFilter(
		'termsOrganisationNameFilter',
		'termsOrganisationIdFilter',
	);

	const pagination = usePagination('pageNumberTerms', 'pageSizeTerms');

	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch);

	const tcFilters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingCategory) && { sortBy: sortingCategory }),
		...(termsOrganisationFilter.valueId !== 'all' && {
			organisationId: termsOrganisationFilter.valueId,
		}),
		orderDescending,
	};

	const deleteTermMessage = useError({
		value: deleteTerm,
		message: `${t('ui.successfullyDeleted')} ${termsTitle}`,
	});

	const dialogAction = useCloseTimeout(
		isObject(deleteTerm) ?
			{
				loading: deleteLoading,
				error: deleteError,
				actionDone: deleteDone,
			}
		:	null,
	);

	useEffect(() => {
		if (loadingPrevTerms) {
			onFetchTerms(pagination.page, tcFilters);
		}
	}, [loadingPrevTerms]);

	useEffect(() => {
		if (deleteDone && deletedTermIndex) {
			setDeletedTermIndex(false);
			onFetchTerms(pagination.page, tcFilters);
		}
	}, [deleteDone, deletedTermIndex, pagination, sortingCategory, orderDescending]);
	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		if (shouldSearch || shouldFilter || shouldSort || shouldDoInitialFetch || pagination.fetch) {
			if ((shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchTerms(pagination.page, tcFilters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [
		sortingCategory,
		order,
		shouldFilter,
		shouldSearch,
		shouldSort,
		shouldDoInitialFetch,
		showingInitialResults,
		orderDescending,
		pagination,
	]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	/* * * * * *
	 * SORTING *
	 * * * * * */
	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	const deleteSingleTerm = (data) => {
		setTermsTitle(data.title);
		setTermsId(data.id);
		dialogAction.setAction(true);
	};

	const handleEditTerms = (data) => {
		if (isObject(data)) {
			navigate(`/terms/${data.id}/edit`, {
				state: { detail: false },
			});
		}
	};

	const createMenuItems = (data) => [
		{
			icon: <EditIcon color='action' />,
			text: t('ui.button.inline.edit'),
			action: () => handleEditTerms(data),
		},
		{
			icon: <DeleteIcon color='error' />,
			text: t('ui.button.inline.delete'),
			action: () => deleteSingleTerm(data),
			isRed: true,
		},
	];

	const extraButtons = [
		{
			text: t('view.policymanagement.terms.button.contained.addterms'),
			onClick: () => navigate('/policy-management/terms-and-conditions/add'),
			variant: 'contained-primary',
		},
	];

	const handleOrganisationFilter = (item) => {
		if (item.id === termsOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			termsOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			termsOrganisationFilter.setValueName('');
		}
		termsOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const mainFilters = [
		...(isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleOrganisationFilter,
						searchHandle: termsOrganisationFilter.setValueName,
						filter: 'verified',
					},
					value: termsOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: t('ui.filter.organisations.all'),
					},
				},
			]
		:	[]),
	];

	const handleClearFilters = () => {
		termsOrganisationFilter.setValueName('');
		termsOrganisationFilter.setValueId('all');
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			termsOrganisationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	/* * * * * * * * *
	 * TABLE CONTENT *
	 * * * * * * * * */
	const tableHeader = [
		{ name: 'title', content: t('ui.label.name'), hasSorting: true },
		{ name: 'languages', content: t('ui.label.languages') },
		...(isSuperAdmin(auth.user?.profile.role) ?
			[{ name: 'organisation', content: t('ui.label.organisation') }]
		:	''),
		{ name: '', content: '' },
	];

	const tableBody =
		tcReady ?
			tcData.results.map((tc) => [
				{
					content: (
						<Link
							className={classes.itemGroup}
							component={RouterLink}
							to={`/term/details/${tc.id}/summary`}
							state={{ from: location.pathname }}
						>
							{tc.title}
						</Link>
					),
				},
				{
					content:
						!isUndefined(tc.termsLocalizations) ?
							<Box maxWidth='6rem' width='6rem'>
								{tc.termsLocalizations.length > 1 ?
									<MyTooltip
										arrow
										title={tc.termsLocalizations.map((type, index) => (
											<Typography className={classes.tooltip} key={index}>
												{handleCultureCode(type.cultureCode)}
											</Typography>
										))}
									>
										<span>{`${tc.termsLocalizations.length} ${t('ui.label.languages')}`}</span>
									</MyTooltip>
								: isFullArray(tc.termsLocalizations) ?
									<span>{handleCultureCode(tc.termsLocalizations[0].cultureCode)}</span>
								:	'-'}
							</Box>
						:	'-',
				},
				...(isSuperAdmin(auth.user?.profile.role) ?
					[{ content: tc.organisationReference ? tc.organisationReference.name : '-' }]
				:	''),
				{
					content: (
						<Box display='flex' flexDirection='row-reverse' ml={50}>
							<Box>
								<GenericMoreButton className={classes.deletTac} menuItems={createMenuItems(tc)} />
							</Box>
						</Box>
					),
				},
			])
		:	Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const handleCancelDialog = () => {
		dialogAction.setAction(false);
	};

	const handleConfirmDialog = () => {
		if (termsId) {
			onDeleteTerm(termsId);
			setDeletedTermIndex(termsId);
			deleteTermMessage.setStartAction(true);
		}
	};

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const tcListEmpty =
		isObject(tcData) && !tcLoading && isEmptyArray(tcData.results) ?
			showingInitialResults ?
				<EmptyState
					callToAction={'/policy-management/terms-and-conditions/add'}
					callToActionText={t('view.policymanagement.terms.button.contained.addterms')}
					image={'booking'}
					subTitle={t('views.assets.termsAndConditions.empty.description.zeroInSystem')}
					title={t('views.assets.termsAndConditions.empty.title')}
				/>
			:	<EmptyState
					callToAction={'/policy-management/terms-and-conditions/add'}
					callToActionText={t('view.policymanagement.terms.button.contained.addterms')}
					image={'booking'}
					subTitle={t('views.assets.termsAndConditions.empty.description.zeroMatching')}
					title={t('views.assets.termsAndConditions.empty.title')}
				/>
		:	null;

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	return (
		<div className={clsx(classes.root, className)}>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons
				hasMainFilter={true}
				hideMoreFilters={true}
				mainFilters={mainFilters}
				placeholder={t('views.assets.items.placeholder.search')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{tcListEmpty ?
				tcListEmpty
			:	<Table
					body={tableBody}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeader}
					loading={tcLoading}
					order={order}
					orderBy={sortingCategory}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('views.assets.termsAndConditions.table.title')}
					total={tcData ? tcData.total : 0}
				/>
			}
			{dialogAction.action ?
				<AlertDialog
					dialogDescription={`${t('ui.deleted.dialogDescription')} ${termsTitle} ${t('ui.permanently')}`}
					dialogTitle={t('ui.delete')}
					handleClose={handleCancelDialog}
					handleConfirm={handleConfirmDialog}
					loading={deleteLoading && !deleteDone}
					open={dialogAction.action}
				/>
			:	null}
		</div>
	);
};

TermsAndConditions.propTypes = {
	className: PropTypes.string,

	tcList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchTerms: PropTypes.func.isRequired,

	onDeleteTerm: PropTypes.func,
	deleteTerm: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updateTerm: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	onFetchOrganisations: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		tcList: state.paged.terms,
		deleteTerm: state.condition.deleteTerm,
		updateTerm: state.details.updateTerm,
		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchTerms: (page, filters) => dispatch(actions.fetchTerms(page, filters)),
		onDeleteTerm: (id) => dispatch(actions.deleteTerm(id)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
