import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

// import FinanceHistory from './FinanceHistory';
import { useNavigate, useParams } from 'react-router-dom';

import Expenses from './Expenses';
import Header from './Header';
import Personal from './Personal';
import Revenue from './Revenue';
import { useStyles } from './style';
import { Page } from '../../components';

const FinanceManagement = (props) => {
	const auth = useAuth();
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { tab } = useParams();

	const role = auth.user?.profile.role.toLowerCase();
	const isSuperAdmin = role === 'superadmin';

	const classes = useStyles();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'revenue', label: t('nav.financialReports.revenue') },
		{ value: 'expenses', label: t('ui.expenses') },
		...(isSuperAdmin ? [{ value: 'personal', label: t('ui.label.personalBilling') }] : []),
	];

	const headerButtons = [
		{ key: 'revenue', buttons: [] },
		{ key: 'expenses', buttons: [] },
		...(isSuperAdmin ? [{ key: 'personal', buttons: [] }] : []),
	];

	return (
		<Page className={classes.root} title={t('ui.finance')}>
			{headerButtons.map((item) => item.key === tab && <Header key={item.key} {...item} />)}
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'revenue' && <Revenue isSuperAdmin={isSuperAdmin} />}
				{tab === 'expenses' && <Expenses isSuperAdmin={isSuperAdmin} />}
				{tab === 'personal' && <Personal />}
			</div>
		</Page>
	);
};

export default FinanceManagement;
