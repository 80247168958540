import { FETCH_LIST_START, FETCH_LIST_SUCCESS, FETCH_LIST_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import {
	isArray,
	isFunction,
	isEmptyString,
	isFullString,
	isNull,
	isFullArray,
	isEmptyArray,
	isEmptyObject,
} from '../../shared/utility';
import { listStates } from '../states';

/* * * * * * * * * * * * * * *
 * ACTIVE ACTION TYPE METHODS *
 * * * * * * * * * * * * * *  */
const ListStatesCounter = new Counter(listStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchListStart = (identifier) => {
	return {
		type: FETCH_LIST_START,
		identifier: identifier,
	};
};

export const fetchListSuccess = (identifier, data = [], concat = false) => {
	ListStatesCounter.reset(identifier);
	return {
		type: FETCH_LIST_SUCCESS,
		identifier: identifier,
		data: data,
		concat: concat,
	};
};

export const fetchListFail = (
	identifier,
	error = 'Error message missing. Please contact site administrator.',
) => {
	ListStatesCounter.reset(identifier);
	return {
		type: FETCH_LIST_FAIL,
		identifier: identifier,
		error: error,
	};
};

export const updateListState = (identifier, data = [], concat = false) => {
	return (dispatch) => {
		dispatch(fetchListSuccess(identifier, data, concat));
	};
};

export const resetListState = (identifier) => {
	return (dispatch) => {
		dispatch(fetchListSuccess(identifier));
	};
};

export const flattenFilters = (filters) => {
	const filtersStr =
		isFullArray(Object.keys(filters)) ?
			Object.entries(filters)
				.reduce(
					(arr, map) =>
						// flatten filterproperties is passed in array
						isEmptyArray(map[1]) ? arr
						: isFullArray(map[1]) ? [...arr, ...map[1].map((value) => [map[0], value])]
						: [...arr, ...[map]],
					[],
				)
				.filter((map) => isFullArray(map))
				.map((map) => `${map[0]}=${map[1]}`)
				.join('&')
		:	'';

	const filterString = '?' + [filtersStr].filter((str) => str.length).join('&');

	return filterString;
};

/* * * * * * * * * * * * * * * *
 * GENERALIZED FETCH FUNCTION  *
 ** * * * * * * * * * * * * * * */
const fetchList = (identifier, path, settings = {}) => {
	const current = ListStatesCounter.increment(identifier);
	const { method = 'get', transformer, concat = false, bodyData = null, errorCallback } = settings;
	return async (dispatch) => {
		dispatch(fetchListStart(identifier));
		try {
			let data = await (isArray(path) ?
				Promise.all(path.map((p) => events[method](p, bodyData)))
			:	events[method](path, bodyData));

			if (ListStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(transformer)) {
					data = transformer(data);
				}
				dispatch(fetchListSuccess(identifier, data, !!concat));
			}
		} catch (error) {
			if (ListStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(errorCallback)) {
					errorCallback(dispatch, error);
				} else {
					dispatch(fetchListFail(identifier, error));
				}
			}
		}
	};
};

/* * * * * * * * * * * *  *
 * USER ACCESSIBLE METHODS *
 * * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * * *
 * USER METHODS *
 * * * * * * *  */
export const fetchUsers = () => {
	// const filtersStr = isFullArray(Object.keys(filters)) ? (
	//   Object.entries(filters).reduce((arr, map) => (
	//     // flattened filterproperties are passed in array
	//     isEmptyArray(map[1]) ? (
	//       arr
	//     ) : isFullArray(map[1]) ? (
	//       [...arr, ...map[1].map(value => [map[0], value])]
	//     ) : [...arr, ...[map]]
	//   ), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
	// ) : '';

	// const filterString = '?searchTerm=' + (filters.organisation ? filters.organisation : '');

	return fetchList('users', 'users');
};

export const searchUsers = (searchValue = '') => {
	return fetchList('users', `users?searchTerm=${searchValue}`);
};

export const searchFilterSortUsers = (
	searchValue = null,
	filterString = null,
	sortingCategory = 'id',
	order = 'asc',
) => {
	return fetchList(
		'users',
		`users?${!isEmptyString(searchValue) && !isNull(searchValue) ? 'search=' + searchValue : ''}${
			!isEmptyString(filterString) && !isNull(filterString) ?
				!isNull(searchValue) ?
					'&filter=' + filterString
				:	'filter=' + filterString
			:	''
		}${!isNull(searchValue) || !isNull(filterString) ? '&sortBy=' + sortingCategory + '&order=' + order : 'sortBy=' + sortingCategory + '&order=' + order}`,
	);
};

/* * *  * * * *
 *  COUNTRIES  *
 * * * * * * * */
export const fetchCountries = () => {
	return fetchList('countries', 'countries');
};

/* * *  * * * *
 *  CATEGORIES *
 * * * * * * * */
export const fetchCategories = (filters = 'none') => {
	const queryParams = isFullString(filters) ? `?filters=${filters}` : '';
	return fetchList('categories', `categories${isFullString(queryParams) ? queryParams : ''}`);
};

/* * *  * * * *
 *  USER ROLES *
 * * * * * * * */
export const fetchUserRoles = () => {
	return fetchList('userRoles', 'identityroles');
};

/* * * * * * * * * * * * * *
 * ITEM INSTANCES METHODS  *
 * * * * * * * * * * * * * */
export const fetchItemInstancesList = (itemId, itemAccess = null) => {
	const queryParams = `?itemAccess=${itemAccess}`;
	return fetchList(
		'itemInstancesList',
		`items/${itemId}/iteminstances/list${isFullString(itemAccess) ? queryParams : ''}`,
	);
};

export const userGroupItemInstances = (userGroupId = null, itemId = null) => {
	return fetchList('itemInstancesList', `usergroups/${userGroupId}/items/${itemId}/iteminstances`);
};

export const fetchPartnerItemInstances = (partnerId = null, itemId = null) => {
	return fetchList('itemInstancesList', `partnerships/${partnerId}/items/${itemId}/iteminstances`);
};

export const instanceAccess = (itemId = null, instanceId = null) => {
	return fetchList('instanceAccess', `items/${itemId}/iteminstances/${instanceId}/access`);
};

/* * * * * * * * * * * * *
 * ORGANISATION METHODS  *
 * * * * * * * * * * * * */
export const fetchOrganisations = () => {
	return fetchList('organisations', 'organisations');
};

/* * * * * * * * * * * * * *
 * DEVICE FILTERS METHODS  *
 * * * * * * * * * * * * * */
export const fetchDevicesTypes = (filters = null) => {
	const queryParams = isFullString(filters) ? `?usageType=${filters}` : '';
	return fetchList('devicesTypesFilter', `devices/filters/devicetypes${queryParams}`);
};

export const fetchDevicesFirmWarVersions = () => {
	return fetchList('devicesFirmWarVersionsFilter', 'devices/filters/firmwareversions');
};

export const fetchDevicesUnlockTypes = () => {
	return fetchList('devicesUnlockTypesFilter', 'devices/filters/unlocktypes');
};

/* * * * * * * * * * * * *
 * DEVICE IMAGE METHODS  *
 * * * * * * * * * * * * */

export const fetchUserImage = (userId = null) => {
	return fetchList('fetchUserImage', `users/${userId}/images`);
};

export const fetchLocationImage = (locationId = null) => {
	return fetchList('fetchLocationImage', `hubs/${locationId}/images`);
};

export const getTicketActivities = (id = null) => {
	return fetchList('getTicketActivities', `tickets/${id}/activities`);
};

export const fetchBookingTypes = () => {
	return fetchList('bookingTypes', 'bookingtypes');
};

/* * * * * * * * * *
 * TICKET METHODS  *
 * * * * * * * * * */
export const fetchTicketDefects = (itemId = null) => {
	return fetchList('ticketDefects', `items/${itemId}/defects`);
};

/* * * * * * * * * * * * *
 * SUBSCRIPTION METHODS  *
 * * * * * * * * * * * * */
export const fetchSubscriptions = () => {
	return fetchList('subscriptions', 'subscriptions');
};

export const searchOrganisations = (searchTerm = null, sortingCategory = 'name', order = false) => {
	return fetchList(
		'organisations',
		`organisations${isFullString(searchTerm) ? '?searchTerm=' + searchTerm : ''}
      ${isFullString(sortingCategory) ? '&sortBy=' + sortingCategory + `&order=${order}` : ''}`,
	);
};

export const fetchHardwareversions = () => {
	return fetchList('fetchHardwareversions', 'devices/felters/hardwareversions');
};

export const fetchFleetsInstances = (filters = {}) => {
	const filterString = !isEmptyObject(filters) ? flattenFilters(filters) : '';
	return fetchList('fetchFleetsInstances', `fleets${filterString}`);
};

export const fetchFleetsInstancesList = (filters = {}) => {
	const filterString = !isEmptyObject(filters) ? flattenFilters(filters) : '';
	return fetchList('fetchFleetsInstancesList', `fleets/list${filterString}`);
};

export const fetchFleetsInstancesListSearch = (filters = {}) => {
	const filterString = !isEmptyObject(filters) ? flattenFilters(filters) : '';
	return fetchList('fetchFleetsInstancesListSearch', `fleets/list${filterString}`);
};

export const latestActivity = (itemInstanceId = null, requestedResources = null) => {
	return fetchList(
		'latestActivity',
		`fleets/instances/${itemInstanceId}/activity?requestedResources=${requestedResources}`,
	);
};

export const tripCoordinates = (deviceId = null, tripId = null) => {
	return fetchList('tripCoordinates', `devices/${deviceId}/trips/${tripId}/coordinates`);
};

export const instanceTripCoordinates = (instanceId = null, tripId = null) => {
	return fetchList(
		'instanceTripCoordinates',
		`iteminstances/${instanceId}/trips/${tripId}/coordinates`,
	);
};

export const bookingTripCoordinates = (bookingId = null, tripId = null) => {
	return fetchList('bookingTripCoordinates', `bookings/${bookingId}/trips/${tripId}/coordinates`);
};

/* * * * * * *
 * DASHBOARD  *
 * * * * * * */
export const dashboardActions = (filters = {}) => {
	const filterString = !isEmptyObject(filters) ? flattenFilters(filters) : '';
	return fetchList('dashboardActions', `dashboard/actions${filterString}`);
};

/* * * * * *
 * SHARINGS *
 * * * * * */
export const internalSharingInstances = (providerId = null, userGroupId = null, itemId = null) => {
	return fetchList(
		'internalSharingInstances',
		`sharings/${providerId}/internals/consumers/${userGroupId}/items/${itemId}/iteminstances`,
	);
};

export const publicSharingInstances = (providerId = null, itemId = null) => {
	return fetchList(
		'publicSharingInstances',
		`sharings/${providerId}/publics/consumers/items/${itemId}/iteminstances`,
	);
};

export const externalSharingInstances = (providerId = null, consumerId = null, itemId = null) => {
	return fetchList(
		'externalSharingInstances',
		`sharings/${providerId}/externals/consumers/${consumerId}/items/${itemId}/iteminstances`,
	);
};

export const internalAvailableSharing = (providerId = null, userGroupId = null, itemId = null) => {
	return fetchList(
		'internalAvailableSharing',
		`sharings/${providerId}/internals/consumers/${userGroupId}/items/${itemId}/shareable-iteminstances`,
	);
};

export const publicAvailableSharing = (providerId = null, itemId = null) => {
	return fetchList(
		'publicAvailableSharing',
		`sharings/${providerId}/publics/consumers/items/${itemId}/shareable-iteminstances`,
	);
};

export const externalAvailableSharing = (providerId = null, consumerId = null, itemId = null) => {
	return fetchList(
		'externalAvailableSharing',
		`sharings/${providerId}/externals/consumers/${consumerId}/items/${itemId}/shareable-iteminstances`,
	);
};

export const searchInstance = (filters = {}) => {
	const filterString = !isEmptyObject(filters) ? flattenFilters(filters) : '';
	return fetchList('searchInstance', `autocompletes/instances${filterString}`);
};

export const searchUser = (filters = {}) => {
	const filterString = !isEmptyObject(filters) ? flattenFilters(filters) : '';
	return fetchList('searchUser', `autocompletes/users${filterString}`);
};

export const itemGroupDevices = (itemId) => {
	return fetchList('itemGroupDevices', `items/${itemId}/devices`);
};
