import { useRef } from 'react';

import useSWR from 'swr';

import AccessRulesService from '../../accessOld/services/accessRulesService';

const useAccessRuleData = (id: string) => {
	const service = useRef(new AccessRulesService());

	const refreshString = `${service.current.basePath}.${id}`;
	const response = useSWR(refreshString, () => service.current.getAccessRuleById(id));

	return response;
};

export default useAccessRuleData;
