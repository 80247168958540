import { useState, useEffect } from 'react';

import { Box, Link, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useDebounce } from '~hooks';

import Header from './Header';
import { useStyles } from './style';
import { Table, Label, SearchBar, Page } from '../../../../components';
import {
	isObject,
	getInitials,
	isEmptyString,
	isEmptyArray,
	decimalAmount,
	getFinanceTableHeaders,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const Organisations = (props) => {
	const { onFetchBusinessRevenue, businessRevenue } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	const { date, title } = useParams();
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [shouldSearch, setShouldSearch] = useState(false);
	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);

	const shouldSearchOnDebounce = false;

	const { data: businessRevenueData, loading: businessRevenueLoading } = businessRevenue;

	const filters = {
		...(!isEmptyString(searchValue) && { searchTerm: searchValue }),
	};

	useEffect(() => {
		if (shouldSearch || shouldDoInitialFetch) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			if (date && !businessRevenueLoading) {
				onFetchBusinessRevenue(date, filters);
			}
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [date, shouldSearch, shouldDoInitialFetch]);

	const handleSearchClick = () => {
		if (!isEmptyString(searchValue)) {
			setShouldSearch(true);
			setIsShowingSearchResults(true);
		}
		if (isEmptyString(searchValue) && isShowingSearchResults) {
			setShouldSearch(true);
			setIsShowingSearchResults(false);
		}
	};

	const handleResetSearch = () => {
		setSearchValue('');
		if (isShowingSearchResults === true) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const events = {
		onClick: handleSearchClick,
		onChange: (e) => setSearchValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	const debouncedSearchValue = useDebounce(searchValue, 500);

	useEffect(() => {
		if (shouldSearchOnDebounce) {
			handleSearchClick();
		}
	}, [shouldSearchOnDebounce, debouncedSearchValue]);

	const headers = getFinanceTableHeaders(true);

	const tableHeader = [
		{ name: 'organisations', content: t('ui.category.organisations') },
		...headers,
	];

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const emptyBody =
		(
			isObject(businessRevenueData) &&
			!businessRevenueLoading &&
			isEmptyArray(businessRevenueData.billings)
		) ?
			Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ content: '-' })))
		:	null;

	const loadingBody =
		!isObject(businessRevenueData) || businessRevenueLoading ?
			Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const dataBody =
		isObject(businessRevenueData) && !businessRevenueLoading ?
			businessRevenueData.billings.map((revenue) => [
				{
					content: (
						<Box alignItems='center' display='flex' height='19px' width='250px'>
							{revenue.organisationReference.logo ?
								<Avatar
									className={classes.avatar}
									src={revenue.organisationReference.logo}
									variant='square'
								/>
							:	<Avatar variant='square'>{getInitials(revenue.organisationReference.name)}</Avatar>}
							<Box pl={1}>
								<Link
									className={classes.link}
									color='inherit'
									component={RouterLink}
									to={`/financeManagement/revenue/${date}/${title}/partner/${revenue.organisationReference.id}/${revenue.organisationReference.name}`}
								>
									{`${revenue.organisationReference.name}`}
								</Link>
							</Box>
						</Box>
					),
				},
				{ content: `${decimalAmount(revenue.amount.subtotal, revenue.amount)}` },
				{ content: `${decimalAmount(revenue.amount.discount, revenue.amount)}` },
				{
					content: `${decimalAmount(revenue.amount.subtotal - revenue.amount.discount, revenue.amount)}`,
				},
				{ content: `${decimalAmount(revenue.amount.priceVat, revenue.amount)}` },
				{ content: `${decimalAmount(revenue.amount.refund, revenue.amount)}` },
				{ content: `${decimalAmount(revenue.amount.totalPrice, revenue.amount)}` },
				{ content: `${decimalAmount(revenue.open, revenue.amount)}` },
				{
					content: <Label type={statusColor[revenue.status]}>{statusText[revenue.status]}</Label>,
				},
			])
		:	Array(3)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation =
		isObject(businessRevenueData) ?
			{
				header: t('views.financeHistory.organisationalBilling.header'),
			}
		:	null;

	return (
		<Page className={classes.root} title={t('ui.details')}>
			<Header title={title} />
			<Box pt={3}>
				<SearchBar
					hasSearchButton
					placeholder={t('views.usersAndOrganisations.organisations.searchPlaceholder')}
					searchEvents={events}
					searchValue={searchValue}
				/>
			</Box>
			<Table
				body={loadingBody || emptyBody || dataBody}
				header={tableHeader}
				isNotPaginate={true}
				loading={businessRevenueLoading}
				noTitle={true}
				titleWithInformation={titleWithInformation}
				withoutPages={true}
			/>
		</Page>
	);
};

Organisations.propTypes = {
	date: PropTypes.string,

	onFetchBusinessRevenue: PropTypes.func,
	businessRevenue: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		businessRevenue: state.details.businessRevenue,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBusinessRevenue: (date, filters) =>
			dispatch(actions.fetchBusinessRevenue(date, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Organisations);
