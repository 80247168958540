import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '../../../../../components';

const DownloadCsvDialog = (props) => {
	const { open, onClose, onClickDownload } = props;
	const { t } = useTranslation('general');

	const handleDownloadCsv = () => onClickDownload();

	const handleCloseDownloadCsv = () => onClose();

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleDownloadCsv,
				text: t('view.itemmanagement.itemdetails.button.contained.downloadCSV'),
			}}
			handleClose={handleCloseDownloadCsv}
			loading={false}
			open={open}
			title={t('views.itemDetail.instances.dialog.downloadCSV.title')}
		>
			{t('views.itemDetail.instances.dialog.downloadCSV.description')}
		</ActionDialog>
	);
};

DownloadCsvDialog.propTypes = {
	open: PropTypes.bool,

	onClose: PropTypes.func,
	onClickDownload: PropTypes.func,
};

export default DownloadCsvDialog;
