import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	numberStyles: {
		color: '#1e88e5',
	},
	cellStyle: {
		whiteSpace: 'nowrap',
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));
