import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
	root: {
		padding: '3rem',
		display: 'flex',
		justifyContent: 'center',
	},
	emptyStateContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		width: '40%',
	},
	textContainer: {
		marginBottom: '1.5rem',
	},
	emptyStateSubtitle: {
		paddingTop: '10px',
		textAlign: 'center',
	},
}));
