import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { SkcUserGroupsOverviewLayout } from '~features/access';

import Page from '../page';

const SkcUserGroupsPage = () => {
	const { t } = useTranslation('general');

	const [userInfo] = useAtom(userInfoAtom);

	return (
		<Page title={t('userGroups')}>
			<SkcUserGroupsOverviewLayout />
		</Page>
	);
};

export default SkcUserGroupsPage;
