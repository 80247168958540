/* * * * * * * * * * * * *
 * FORM INPUT VALIDATION *
 * * * * * * * * * * * * */
import { isUndefined, isEmptyString, isFullString, isFullArray, isEmptyObject } from './utility';

const toLowerCaseArray = (input) => [].concat(input).map((str) => `${str}`.toLowerCase());

export const validateInput = (value, rules) => {
	if (!rules || (!rules.required && isEmptyString(value))) {
		return {
			valid: true,
			error: {},
		};
	}

	const availableRules = [
		{
			name: 'required',
			test: (val) => isFullString(val.toString()),
		},
		{
			name: 'minLength',
			test: (val) => val.length >= rules.minLength,
		},
		{
			name: 'maxLength',
			test: (val) => val.length <= rules.maxLength,
		},
		{
			name: 'isEmail',
			test: (val) => {
				// const pattern = /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
				const pattern =
					/^([\w\d\.\+-])+@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; //eslint-disable-line
				return pattern.test(val);
			},
		},
		{
			name: 'isEmailDomain',
			test: (val) => {
				const pattern = /^@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; //eslint-disable-line
				return pattern.test(val);
			},
		},
		{
			name: 'isUrl',
			test: (val) => {
				const pattern =
					'^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
				return pattern.test(val);
			},
		},
		{
			name: 'isNumeric',
			test: (val) => {
				const pattern = /^\d+$/;
				return pattern.test(val);
			},
		},
		{
			name: 'isDecimal',
			test: (val) => {
				const pattern = /\d+\.?\d*/; //Matches decimals like 0.01 and not 0,01
				return pattern.test(val);
			},
		},
		{
			name: 'minAmount',
			test: (val) => val >= rules.minAmount,
		},
		{
			name: 'maxAmount',
			test: (val) => val <= rules.maxAmount,
		},
		{
			name: 'isNumAndLetter',
			test: (val) => {
				const pattern = /^\d+([-]\d*)*/;
				return pattern.test(val);
			},
		},
		{
			name: 'isPassword',
			test: (val) => {
				// not one of the top 25 common passwords
				return !FORBIDDEN_PASSWORDS.includes(val);
			},
		},
		{
			name: 'isHex',
			test: (val) => {
				const pattern = /^#[0-9A-F]{6}$/i;
				return pattern.test(val);
			},
		},
		{
			name: 'isUUID',
			test: (val) => {
				const pattern =
					/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
				return pattern.test(val);
			},
		},
		{
			name: 'isNumber',
			test: (val) => {
				const pattern = /^-?\d+(?:\.\d+)?$/;
				return pattern.test(val);
			},
		},
		{
			name: 'isVIN',
			test: (val) => {
				const pattern = /[0-9A-Z]{11}[0-9]{6}/;
				return pattern.test(val);
			},
		},
		{
			name: 'isDutchNumberPlate',
			test: (val) => {
				const pattern =
					/([A-Z]{2}-[0-9]{2}-[0-9]{2})|([0-9]{2}-[0-9]{2}-[A-Z]{2})|([0-9]{2}-[A-Z]{2}-[0-9]{2})|([A-Z]{2}-[0-9]{2}-[A-Z]{2})|([0-9]{2}-[A-Z]{2}-[A-Z]{2})|([A-Z]{2}-[A-Z]{2}-[0-9]{2})|([0-9]{2}-[A-Z]{3}-[0-9]{1})|([0-9]{1}-[A-Z]{3}-[0-9]{2})|([A-Z]{2}-[0-9]{3}-[A-Z]{1})|([A-Z]{1}-[0-9]{3}-[A-Z]{2})|([A-Z]{3}-[0-9]{2}-[A-Z]{1})/;
				return pattern.test(val);
			},
		},
		{
			name: 'is',
			test: (val) => {
				if (!isFullString(rules.is)) {
					return true;
				}
				return val === rules.is;
			},
		},
		{
			name: 'isNot',
			test: (val) => {
				if (!isFullArray(rules.isNot) && !isFullString(rules.isNot)) {
					return true;
				}
				const isNot = toLowerCaseArray(rules.isNot);
				return !isNot.includes(`${val}`.toLowerCase());
			},
		},
		{
			name: 'contains',
			test: (val) => {
				if (!isFullArray(rules.contains) && !isFullString(rules.contains)) {
					return true;
				}
				const containsValues = toLowerCaseArray(rules.contains);

				let contains = false;
				containsValues.forEach((test) => {
					if (val.toLowerCase().includes(test)) {
						contains = true;
					}
				});

				return contains;
			},
		},
		{
			name: 'containsNot',
			test: (val) => {
				if (!isFullArray(rules.containsNot) && !isFullString(rules.containsNot)) {
					return true;
				}
				const containsNotValues = [].concat(rules.containsNot).map((str) => `${str}`.toLowerCase());

				let contains = false;
				containsNotValues.forEach((test) => {
					if (val.toLowerCase().includes(test)) {
						contains = true;
					}
				});

				return !contains;
			},
		},
		{
			name: 'isEnterpriseNumber',
			test: (val) => {
				const pattern = /^[01]{1}[0-9]{3}[.]{0,1}[0-9]{3}[.]{0,1}[0-9]{3}$/;
				return pattern.test(val);
			},
		},
		{
			name: 'isFullObject',
			test: (val) => !isEmptyObject(val),
		},
		{
			name: 'custom',
			test: rules.custom,
		},
	];

	let isValid = true;

	let error = {};

	availableRules.forEach(({ name, test }) => {
		if (!isUndefined(rules[name]) && !test(value)) {
			isValid = false;
			if (isEmptyObject(error)) {
				error = {
					key: name,
				};
			}
		}
	});

	return {
		valid: isValid,
		error,
	};
};
