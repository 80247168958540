import { useMemo, useState } from 'react';

import path from 'path';

import {
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
	Link as LinkIcon,
	LinkOff as LinkOffIcon,
} from '@mui/icons-material';
import { Box, Button, Chip, Link as MuiLink, Portal, Stack } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridPaginationModel,
	GridRenderCellParams,
	GridRowParams,
	GridSortModel,
	GridToolbarContainer,
} from '@mui/x-data-grid';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { userInfoAtom } from '~atoms';
import {
	DataGrid,
	GridActionsCellItem,
} from '~components';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';
import { BaseReference } from '~interfaces';
import { datetimeWithoutSecondsFormat } from '~utils/dateUtils';

import NfcTag from '../../interfaces/nfcTag';
import NfcTagsService from '../../services/nfcTagsService';

const toolbarIdString = 'topology-nfc-tags-toolbar';

const service = new NfcTagsService();

interface NfcsDataGridProps {
	enableEdit?: boolean;
	enableLinking?: boolean;
	enableDelete?: boolean;
	enableCreate?: boolean;
	onEdit?: (id: string) => void;
	onCreate?: () => void;
	onLink?: (id: string) => void;
	onUnlink?: (id: string) => void;
	onDelete?: (id: string) => void;
}

const NfcsDataGrid = ({
	enableEdit = false,
	enableLinking = true,
	enableCreate = false,
	enableDelete = false,
	onCreate,
	onEdit,
	onLink,
	onUnlink,
	onDelete
}: NfcsDataGridProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const [fetchParameters, setFetchParameters] = useState<object | null>(null);
	const [searchQuery, setSearchQuery] = useState<string | null>(null);
	const [organisationFilter, setOrganisationFilter] = useState<BaseReference | null>(
		!isSuperAdmin() ? userInfo.organisation : null,
	);

	const columns: GridColDef<NfcTag>[] = useMemo(() => [
		{
			field: 'tagNumber',
			headerName: t('cardNumber'),
			flex: 1,
		},
		{
			field: 'id',
			headerName: t('rfidUid'),
			valueFormatter: (value: string) => value.toUpperCase(),
			flex: 1,
		},
		{
			field: 'user',
			headerName: t('linkedWith'),
			renderCell: (params: GridRenderCellParams<NfcTag>) =>
				!params.value ? '-' : (
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, params.value.id, pagePathSegments.Summary)}`}
					>
						{params.value.label}
					</MuiLink>
				),
			flex: 1,
		},
		{
			field: 'userGroup',
			headerName: t('userGroup'),
			renderCell: (params: GridRenderCellParams<NfcTag>) =>
				!params.value ? '-' : (
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.UserGroups, params.value.id, pagePathSegments.Summary)}`}
					>
						{params.value.label}
					</MuiLink>
				),
			flex: 1,
		},
		...(isSuperAdmin() ?
			[
				{
					field: 'organisation',
					headerName: t('organisation'),
					renderCell: (params: GridRenderCellParams<NfcTag>) =>
						!params.value ? '-' : (
							<MuiLink
								component={ReactRouterLink}
								to={`/${path.join(pagePathSegments.Organisations, params.value.id, pagePathSegments.Summary)}`}
							>
								{params.value.label}
							</MuiLink>
						),
					flex: 1,
				},
			]
		:	[]),
		{
			field: 'dateLinked',
			headerName: t('linkDate'),
			valueFormatter: (value: Date) => datetimeWithoutSecondsFormat.format(value),
			flex: 1,
		},
		{
			field: 'tagType',
			headerName: t('type'),
			// valueFormatter: (value: Date) => datetimeWithoutSecondsFormat.format(value),
			flex: 1,
		},
		...(isSuperAdmin() ?
			[
				{
					field: 'status',
					headerName: t('status'),
					renderCell: (params: GridRenderCellParams<NfcTag>) => (
						<Chip label={params.value} color='success' sx={{ fontSize: '12px' }} />
					),
				},
			]
		:	[]),
		{
			field: 'actions',
			type: 'actions',
			width: 90,
			resizable: false,
			hideable: false,
			align: 'right',
			getActions: (params: GridRowParams) => [
				<GridActionsCellItem
					key={`${params.id}-link`}
					{...(!params.row.user ?
						{
							icon: <LinkIcon />,
							label: t('link'),
							onClick: () => onLink != null && onLink(params.id.toString()),
						}
					:	{
							icon: <LinkOffIcon />,
							label: t('unlink'),
							onClick: () => onUnlink != null && onUnlink(params.id.toString()),
						})}
				/>,
				...(enableEdit ?
					[
						<GridActionsCellItem
							key={`${params.id}-edit`}
							icon={<EditIcon />}
							label={t('edit')}
							showInMenu
							onClick={() => onEdit != null && onEdit(params.id.toString())}
						/>,
					]
				:	[]),
				...(enableDelete ?
					[
						<GridActionsCellItem
							key={`${params.id}-delete`}
							icon={<DeleteOutlinedIcon color='error' />}
							label={t('delete')}
							showInMenu
							onClick={() => onDelete != null && onDelete(params.id.toString())}
						/>,
					]
				:	[]),
			],
		},
	], []);

	const { data, isLoading, isValidating, error } = useSWR(
		fetchParameters ? [service.basePath, fetchParameters] : null,
		([_, args]) => service.getNfcTags(args),
		{
			onSuccess: (res) => res?.total != null && setTotalCount(res.total),
			keepPreviousData: true,
		},
	);

	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	const handlePaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
		if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
			service.abortCurrentRequest('pagination change');
		}

		setFetchParameters((prev) => ({
			...prev,
			page: model.page + 1,
			pageSize: model.pageSize,
		}));
	};

	const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
		if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
			service.abortCurrentRequest('pagination change');
		}

		setFetchParameters((prev) => ({
			...prev,
			sortBy: model ? model[0]?.field : undefined,
			descending: model ? model[0]?.sort === 'desc' : undefined
		}));
	};

	const Toolbar = () =>
		useMemo(
			() => (
				<Portal container={() => document.getElementById(toolbarIdString)}>
					<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
						<Button
							variant='contained'
							onClick={() => onCreate != null && onCreate()}
						>
							{t('add')}
						</Button>
					</GridToolbarContainer>
				</Portal>
			),
			[],
		);

	return (
		<Stack height={1} spacing={2}>
			<Box id={toolbarIdString} />
			<DataGrid
				snapshotKey='nfcTags'
				columns={columns}
				error={error}
				loading={isLoading || isValidating}
				rows={data?.results ?? []}
				rowCount={totalCount}
				onPaginationModelChange={handlePaginationModelChange}
				onSortModelChange={handleSortModelChange}
				slots={{
					toolbar: Toolbar
				}}
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'id',
								sort: 'desc',
							},
						]
					}
				}}
				sx={{ flexGrow: 1, flexShrink: 1, minHeight: 400 }}
			/>
		</Stack>
	);
};

export default NfcsDataGrid;
