import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
	const { t } = useTranslation('general');

	// const handleBackButtonChange = () => {
	//   navigate('/user-management/users');
	// };

	return (
		<div>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography component='h1' variant='h3'>
						{t('views.partnerships.page.title')}
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
};

export default Header;
