import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const PricingModelDetail = (props) => {
	const { onFetchPrice, pricingDetails } = props;
	const location = useLocation();
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { id, tab } = useParams();
	const classes = useStyles();

	const {
		data: pricingDetailsData,
		loading: pricingDetailsLoading,
		error: pricingDetailsError,
	} = pricingDetails;
	const pricingDetailsReady =
		isObject(pricingDetailsData) && !pricingDetailsLoading && !pricingDetailsError;

	useEffect(() => {
		onFetchPrice(id);
	}, [id, onFetchPrice]);

	const tabs = [{ value: 'summary', label: t('ui.summary') }];

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	if (!tab) {
		return <Navigate to={'/policy-management/pricing'} />;
	} else if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<Page className={classes.root} title={t('views.policyManagement.pricingModelDetail.title')}>
			<Header loading={!pricingDetailsReady} pricingModel={pricingDetailsData} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && (
					<Summary loading={!pricingDetailsReady} pricingModel={pricingDetailsData} />
				)}
			</div>
		</Page>
	);
};

PricingModelDetail.propTypes = {
	pricingDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchPrice: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		pricingDetails: state.details.fetchPrice,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPrice: (id) => dispatch(actions.fetchPrice(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingModelDetail);
