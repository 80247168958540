import { useRef } from 'react';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { isObject } from '../../../shared/utility';

const Canvas = (props) => {
	const classes = useStyles();
	const { height, width, previewImage, onChange, message } = props;
	const canvasRef = useRef(null);

	const loadImage = async (url) => {
		const image = new Image();
		image.crossOrigin = 'anonymous';

		return new Promise((resolve, reject) => {
			image.onload = () => resolve(image);
			image.onerror = reject;
			image.src = url;
		});
	};

	const drawLogo = (img, ctx) => {
		const canvas = ctx.canvas;
		const hRatio = canvas.width / img.width;
		const vRatio = canvas.height / img.height;
		const ratio = Math.min(hRatio, vRatio);
		const centerShift_x = (canvas.width - img.width * ratio) / 2;
		const centerShift_y = (canvas.height - img.height * ratio) / 2;

		ctx.clearRect(0, 0, canvas.width, canvas.height);

		ctx.drawImage(
			img,
			0,
			0,
			img.width,
			img.height,
			centerShift_x,
			centerShift_y,
			img.width * ratio,
			img.height * ratio,
		);
	};

	const handleOnChange = async (e) => {
		const dataUrl = URL.createObjectURL(e.target.files[0]);
		const image = await loadImage(dataUrl);

		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		drawLogo(image, ctx);

		// Convert canvas image to blob
		canvas.toBlob((blob) => {
			// Convert blob to file
			const updatedFile = new File([blob], 'logo.png', {
				type: 'image/png',
			});

			onChange(updatedFile, dataUrl);
		}, 'image/png');
	};

	return (
		<div>
			<div className={classes.imageContainer}>
				<img alt='logo' className={classes.imageSpacing} src={previewImage} />
				<canvas className={classes.canvas} height={height} ref={canvasRef} width={width} />
			</div>
			<label>
				<input
					accept='image/png, image/svg+xml, image/jpg'
					hidden={true}
					multiple={false}
					onChange={handleOnChange}
					type='file'
				/>
				<Box alignItems='center' display='flex' justifyContent='space-between'>
					{<PhotoCamera className={classes.button} />}
					<Typography color='error' variant='h6'>
						{isObject(message) ? message.additionalData.message : null}
					</Typography>
				</Box>
			</label>
		</div>
	);
};

Canvas.defaultProps = {
	height: 300,
	width: 300,
};

Canvas.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	previewImage: PropTypes.string,
	onChange: PropTypes.func,
	message: PropTypes.object,
};

export default Canvas;
