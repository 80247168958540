import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DownloadButton from './DownloadButton';
import ExtraButtons from './ExtraButtons';
import FileReader from './FileReader/FileReader';
import MainFilter from './MainFilter';
import { useStyles } from './style';
import Switch from './Switch';
import TimePicker from './TimePicker';
import { Search, StyledButton } from '../../../components';
import { isFullArray, isObject } from '../../../shared/utility';
import { LazyInput } from '../../elements';

const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width='xs' />;

const SearchBar = (props) => {
	const {
		placeholder,
		hideSearch,
		hasMainFilter,
		mainFilters,
		hasExtraButtons,
		extraButtons,
		searchEvents,
		searchValue,
		periodFilter,
		extraSwitch,
		fileReaderData,
		downloadData,
		timePickerData,
		clearFilters,

		lazyFilters,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.searchFilterContainer}>
				{hideSearch ? null : (
					<Search
						className={classes.search}
						events={searchEvents}
						hasInlineSearchButton
						inputType='search-suggest'
						placeholder={placeholder}
						value={searchValue}
					/>
				)}
				{periodFilter}
				{isObject(timePickerData) && <TimePicker {...timePickerData} />}
				{hasMainFilter ?
					mainFilters.map((mainFilter, index) => (
						<MainFilter
							className={classes.mainFilter}
							dataList={mainFilter.dataList}
							defaultListItem={mainFilter.defaultListItem}
							events={mainFilter.events}
							isSearchAutocomplete={mainFilter.isSearchAutocomplete}
							isSelectWithLazyLoading={mainFilter.isSelectWithLazyLoading}
							key={index}
							listType={mainFilter.listType}
							onFetchData={mainFilter.onFetchData}
							placeholder={mainFilter.placeholder}
							selectOptions={mainFilter.selectOptions}
							value={mainFilter.value}
						/>
					))
				:	null}
				{isFullArray(lazyFilters) &&
					lazyFilters.map((item) => <LazyInput key={item.key} stateType={item.key} {...item} />)}
				{isFullArray(extraSwitch) ?
					<Switch extraSwitch={extraSwitch} />
				:	null}
				{isObject(clearFilters) && clearFilters.clear ?
					<StyledButton
						onClick={clearFilters.action}
						size='medium'
						startIcon={<RefreshIcon />}
						variant='inline-default'
					>
						{t(clearFilters.btnText)}
					</StyledButton>
				:	null}
			</Box>
			{hasExtraButtons ?
				<Box className={classes.extraButtonsContainer}>
					{isObject(fileReaderData) && <FileReader {...fileReaderData} />}
					{isObject(downloadData) && <DownloadButton downloadData={downloadData} />}
					{isFullArray(extraButtons) && <ExtraButtons buttons={extraButtons} />}
				</Box>
			:	null}
		</Box>
	);
};

SearchBar.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	searchEvents: PropTypes.object,
	searchValue: PropTypes.string,
	hideSearch: PropTypes.bool,
	hasMainFilter: PropTypes.bool,
	mainFilters: PropTypes.arrayOf(Object),
	hideMoreFilters: PropTypes.bool,
	hasExtraButtons: PropTypes.bool,
	extraButtons: PropTypes.arrayOf(Object),
	onFilter: PropTypes.func,
	width: PropTypes.string,
	periodFilter: PropTypes.object,
	extraSwitch: PropTypes.arrayOf(Object),
	fileReaderData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	downloadData: PropTypes.object,
	clearFilters: PropTypes.object,
	timePickerData: PropTypes.object,
	lazyFilters: PropTypes.array,
};

export default withWidth()(SearchBar);
