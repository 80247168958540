import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiPaper-root.MuiAccordion-root': {
			cursor: 'none',
		},
	},
	cardHeaders: {
		marginBottom: theme.spacing(1.5),
	},
	drawer: {
		width: 450,
		maxWidth: '100%',
		padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
	},
	buttonCard: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(1.5),
	},
	cancelBooking: {
		alignSelf: 'flex-end',
	},
	allowedCooldow: {
		marginRight: theme.spacing(1),
		marginLeft: 'auto',
	},
	cooldowIcon: {
		'material-symbols-outlined': {
			fontVariationSettings: {
				FILL: 0,
				wght: 400,
				GRAD: 0,
				opsz: 24,
			},
		},
	},
	itemCard: {
		display: 'flex',
		alignItems: 'center',
	},
	media: {
		height: 80,
		width: 80,
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		objectFit: 'contain',
	},
	placeholder: {
		objectFit: 'none',
	},
	closeButton: {
		marginLeft: 'auto',
		alignSelf: 'flex-start',
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	userCard: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	avatarContainer: {
		marginRight: theme.spacing(1.5),
	},
	body3: {
		...theme.typography.body3,
	},
	content: {
		padding: theme.spacing(1.5, 1, 1.5, 1.5),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 'auto',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(0),
		},
	},
	label: {
		paddingLeft: theme.spacing(1),
	},
	iconButton: {
		padding: 0,
	},
	dateTimePickerContainer: {
		display: 'flex',
		gap: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'space-between',
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	helperText: {
		...theme.typography.helperText,
		marginTop: theme.spacing(0.5),
	},
	errorText: {
		color: theme.palette.error.main,
	},
	subHeader: {
		margin: theme.spacing(3, 0, 2, 0),
	},
	usageCard: {
		marginRight: theme.spacing(2),
	},
	loadingListItem: {
		height: 77,
		'& + &': {
			borderTop: `1px solid ${theme.palette.divider}`,
		},
	},
	radioFormControlLabel: {
		padding: theme.spacing(1.5, 0),
		'& + &': {
			borderTop: `1px solid ${theme.palette.divider}`,
		},
	},
	radioLabel: {
		marginRight: 'auto',
	},
	radioLabelStart: {
		marginLeft: 0,
	},
	radioImage: {
		width: 40,
		height: 40,
		objectFit: 'contain',
		marginRight: 10,
	},
	labelDetails: {
		marginTop: 5,
	},
	description: {
		'& .MuiOutlinedInput-root': {
			borderRadius: '12px',
		},
	},
	accordion: {
		padding: '0',
		'& .MuiAccordionSummary-content': {
			display: 'inline',
			margin: theme.spacing(2, 0),
			'&.Mui-expanded': {
				margin: theme.spacing(2, 0),
			},
		},
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '0px',
		paddingRight: '0px',
		paddingTop: '0px',
		paddingBottom: theme.spacing(2.75),
	},
	inputAdornment: {
		marginRight: 2,
	},
	divider: {
		margin: theme.spacing(0, 0, 3, 0),
	},
	usageCardHeaders: {
		margin: theme.spacing(2, 0, 0, 0),
	},
	accordionCard: {
		'& .MuiAccordionDetails-root': {
			paddingBottom: '12px',
		},
	},
	drawerHeader: {
		marginBottom: theme.spacing(1.5),
	},

	searchIcon: {
		'& .MuiButtonBase-root': {
			transform: 'rotate(0deg)',
		},
	},
	avatar: {
		marginRight: theme.spacing(1),
		width: theme.spacing(4.5),
		height: theme.spacing(4.5),
	},
}));
