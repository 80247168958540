import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../components';

const Header = (props) => {
	const { buttonPlaceholder, href, action } = props;
	const { t } = useTranslation('general');

	return (
		<div>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography component='h1' variant='h3'>
						{t('views.assets.page.title')}
					</Typography>
				</Grid>
				{buttonPlaceholder ?
					<Grid item>
						<StyledButton href={href} onClick={action} variant='contained-primary'>
							{buttonPlaceholder}
						</StyledButton>
					</Grid>
				:	null}
			</Grid>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	buttonPlaceholder: PropTypes.string,

	action: PropTypes.func,
	href: PropTypes.string,
};

export default Header;
