import { makeStyles } from '@mui/styles';

import theme from '../../theme';

export const useStyles = makeStyles(() => ({
	filterContainerRoot: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	vehicleTrackerCan: {
		width: '16px',
		height: '16px',
	},
	filterBarRoot: {
		minHeight: '80px',
		zIndex: 2,
		padding: theme.spacing(1.5, 2),
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 0,
	},
	mainCard: {
		display: 'flex',
		height: '100%',
	},

	list: {
		height: '100%',
		overflow: 'auto',
	},
	licationIcon: {
		fontSize: '2rem',
		position: 'relative',
	},
	loaderContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
	clusterMarker: {
		color: 'white',
		background: '#15263E',
		borderRadius: '50%',
		padding: '8px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	locationInfo: {
		position: 'absolute',
		top: '40px',
		right: '50px',
		width: '400px',
		minHeight: '200px',
		padding: '20px',
		backgroundColor: 'rgba(0, 0, 0, 0.6)',
		borderRadius: '10px',
	},
	menuItem: {
		width: '200px',
	},
	filterInput: {
		paddingLeft: '20px',
		minWidth: 200,
		maxWidth: 280,
	},
	listItem: {
		...theme.typography.h6,
	},
	listButton: {
		borderLeft: '1px solid red',
		coler: 'red',
		backgroundColor: 'transparent',
	},
	drawer: {
		width: 450,
		maxWidth: '100%',
		padding: theme.spacing(2),
	},
	buttonCard: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(1.5),
	},
	imageContainer: {
		display: 'flex',
		alignItems: 'center',
		minHeight: '95px',
		width: '94px',
	},
	imageSpacing: {
		objectFit: 'scale-down',
		width: '100%',
		minHeight: '95px',
		margin: '0',
		padding: '0',
	},
	myTest: {
		'& .MuiButtonBase-root': {
			transform: 'rotate(0deg)',
		},
	},
	deviceCard: {
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: '16px',
		'& .MuiLinearProgress-root': {
			backgroundColor: '#f1f3f7',
		},
	},
	deviceCardItems: {
		...theme.typography.link1,
	},
	tipCard: {
		backgroundColor: '#e4eefb',
		color: '#15263e',
		boxShadow: 'none',
		padding: '0.632rem',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		flexShrink: 3,
		width: 'fit-content',
		marginTop: '8px',
	},
	tip: {
		...theme.typography.pagination,
	},
	icon: {
		marginRight: theme.spacing(1),
	},
	activityListItem: {
		padding: '4px 0px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		marginTop: '16px',
	},
	activityListItemStatus: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	itemGroup: {
		...theme.typography,
	},
}));
