import { useState, useEffect } from 'react';

import { Typography, Avatar, Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { ButtonCard, NfcCard, UserCard } from './DraweElements';
import { useStyles } from './style';
import { Drawer, StyledButton } from '../../../../../components';
import { getInitials, isNull, isSuperAdmin } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { Search } from '../../../../Actions';

const LinkDrawer = (props) => {
	const {
		open,
		onClose,

		linkNfcData,
		onSearchUser,
		searchUser,
		onResetListState,
		onLinkNfc,
		linkNfc,
		getNfcData,
		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const [selectedUser, setSelectedUser] = useState(null);

	const { data: linkedNfcData, loading: linkNfcLoading, error: linkNfcError } = linkNfc;

	const handleClose = () => {
		onClose();
		setSelectedUser(null);
	};

	useEffect(() => {
		if (linkedNfcData) {
			enqueueSnackbar(t('views.usersAndOrganisations.nfcLink.successMessage'), {
				variant: 'success',
			});
			handleClose();
			getNfcData();
			onResetState('linkNfc');
		} else if (linkNfcError) {
			enqueueSnackbar(linkNfcError?.message ? linkNfcError.message : 'Error', { variant: 'error' });
		}
	}, [linkNfcLoading]);

	const filters = {
		hideAnonymous: true,
		hideNfcUsers: true,
		...(isSuperAdmin(auth.user?.profile.role) &&
			linkNfcData?.organisationReference?.id && {
				organisationId: linkNfcData.organisationReference.id,
			}),
	};

	const handleRenderOption = (props, option) => (
		<li {...props} key={option.key}>
			<Avatar
				alt='User'
				className={classes.avatar}
				src={option?.imagesReference && option.imagesReference[0]}
			>
				{getInitials(option.name)}
			</Avatar>
			<Box disabled={true} display='flex' flexDirection='column'>
				<Typography variant='h6'>{option.optionsName}</Typography>
				<Typography variant='body2'>{option.address}</Typography>
			</Box>
		</li>
	);

	const searchSelect = (value) => {
		setSelectedUser({
			id: value.id,
			name: value.name,
			email: value.email,
			imagesReference: value?.imagesReference?.at(0),
		});
	};

	const restState = () =>
		onResetListState('searchUser', { data: null, loading: false, error: null });

	const clearUser = () => setSelectedUser(null);

	const searchProps = {
		renderOption: handleRenderOption,
		fetchData: onSearchUser,
		filters,

		searchSelect: searchSelect,
		data: searchUser,
		restState,
		noOptions: t('views.planboard.noOptions.user'),
	};

	const linkUser = () => {
		const bodyData = {
			cardStatus: linkNfcData.status,
			userId: selectedUser.id,
			cardNumber: linkNfcData.cardNumber,
			organisationId: linkNfcData?.organisationReference?.id,
		};
		onLinkNfc(linkNfcData.nfcId, bodyData);
	};

	const buttonProps = {
		disabled: isNull(selectedUser) || linkNfcLoading,
		className: classes.confirmButton,
		variant: 'contained-primary',
		onClick: linkUser,
	};

	return (
		<Drawer open={open} onClose={handleClose}>
			<ButtonCard onClose={handleClose} />
			<Typography variant='h4'>{t('views.usersAndOrganisations.nfcLink.linkToUser')}</Typography>
			<NfcCard cardNumber={linkNfcData?.cardNumber} nfcId={linkNfcData?.nfcId} />
			<Typography sx={{ margin: '12px 0' }}>{t('ui.label.user')}</Typography>
			{isNull(selectedUser) ?
				<Search {...searchProps} />
			:	<UserCard onClick={clearUser} {...selectedUser} />}
			<StyledButton {...buttonProps}>
				{linkNfcLoading ?
					<CircularProgress disableShrink size={24} />
				:	t('ui.confirm')}
			</StyledButton>
		</Drawer>
	);
};
LinkDrawer.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	linkNfcData: PropTypes.object,
	onSearchUser: PropTypes.func,
	onResetListState: PropTypes.func,
	onLinkNfc: PropTypes.func,
	getNfcData: PropTypes.func,
	onResetState: PropTypes.func,

	searchUser: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	linkNfc: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		searchUser: state.list.searchUser,
		linkNfc: state.details.linkNfc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateListState: (state, data) => dispatch(actions.updateListState(state, data)),
		onResetState: (state) => dispatch(actions.resetState(state)),
		onSearchUser: (filters) => dispatch(actions.searchUser(filters)),
		onResetListState: (state, data) => dispatch(actions.updateListState(state, data)),
		onLinkNfc: (nfcId, bodyData) => dispatch(actions.linkNfc(nfcId, bodyData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkDrawer);
