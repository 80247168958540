import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

const EmptyTable = (props) => {
	const { label } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Paper className={classes.tipCard}>
			<InfoOutlinedIcon className={classes.icon} fontSize='small' />
			<Typography className={classes.tip}>{t(label)}</Typography>
		</Paper>
	);
};

EmptyTable.propTypes = {
	label: PropTypes.string,
};

export default EmptyTable;
