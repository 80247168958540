import path from 'path';

import InputIcon from '@mui/icons-material/Input';
import { AppBar, Toolbar } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { pagePathSegments, pagePaths } from '~constants';

import { useStyles } from './style';
import TopologyIcon from '../../../assets/images/topology_logo.svg';
import { StyledButton } from '../../../components';

const Topbar = (props) => {
	const { className } = props;

	const { t } = useTranslation('general');
	const auth = useAuth();
	const navigate = useNavigate();

	const classes = useStyles();

	const handleSignoutClick = () => {
		navigate(`/${pagePaths.AuthLogout}`);
	};

	return (
		<AppBar className={clsx(classes.root, className)}>
			<Toolbar className={classes.toolbar}>
				<RouterLink to='/'>
					<img src={TopologyIcon} width='140px' />
				</RouterLink>
				{window.location.pathname === '/auth/login' ?
					<StyledButton href='/register/start' variant='contained-secondary'>
						{t('view.login.button.contained.addorganisation')}
					</StyledButton>
				: (
					window.location.pathname === '/register/organisation' ||
					window.location.pathname === '/register/verification-status'
				) ?
					<StyledButton
						onClick={handleSignoutClick}
						startIcon={<InputIcon className={classes.logoutIcon} />}
						variant='inline-default'
					>
						{t('ui.button.inline.signout')}
					</StyledButton>
				:	null}
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
};

export default Topbar;
