import {
	FETCH_CONDITION_START,
	FETCH_CONDITION_SUCCESS,
	FETCH_CONDITION_FAIL,
} from './actionTypes';
import { fetchDetailsSuccess } from './details';
import events from './eventServices';
import { updateRedirect } from './global';
import { fetchPagedSuccess } from './paged';
import {
	isFullString,
	isFullArray,
	isObject,
	stringifyQueryParams,
	isNull,
	isNumber,
} from '../../shared/utility';

/* * * * * * * * * * * * * * *
 * ACTIVE ACTION TYPE METHODS *
 * * * * * * * * * * * * * *  */
// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchConditionStart = (identifier) => {
	return {
		type: FETCH_CONDITION_START,
		identifier: identifier,
	};
};

export const fetchConditionSuccess = (identifier, value = true) => {
	return {
		type: FETCH_CONDITION_SUCCESS,
		identifier: identifier,
		value: value,
	};
};

export const fetchConditionFail = (
	identifier,
	error = 'Error message missing. Please contact site administrator.',
) => {
	return {
		type: FETCH_CONDITION_FAIL,
		identifier: identifier,
		error: error,
	};
};

export const resetStateCondition = (identifier, value) => {
	return (dispatch) => {
		dispatch(fetchConditionSuccess(identifier, value));
	};
};

//Utility sleep method
// function sleep(delay) {
// 	return new Promise(res => {
// 		setTimeout(() => res(), delay);
// 	});
// }

/* * * * * * * * * * * *  *
 * USER ACCESSIBLE METHODS *
 * * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * * *
 * USER METHODS *
 * * * * * * * */
export const deleteUser = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteUser'));
		try {
			await events.delete(`identityusers/${userId}`);
			dispatch(fetchConditionSuccess('deleteUser', true));
		} catch (error) {
			dispatch(fetchConditionFail('deleteUser', error));
		}
	};
};

export const hardDeleteUser = (userId = null, hardDelete = false) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('hardDeleteUser'));
		try {
			await events.delete(`users/${userId}?hardDelete=${hardDelete}`);
			dispatch(fetchConditionSuccess('hardDeleteUser', true));
		} catch (error) {
			dispatch(fetchConditionFail('hardDeleteUser', error));
		}
	};
};

export const disableUser = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('disableUser'));
		try {
			await events.put(`identityusers/${userId}/disable`);
			dispatch(fetchConditionSuccess('disableUser', true));
			dispatch(updateRedirect('/user-management/users'));
		} catch (error) {
			dispatch(fetchConditionFail('disableUser', error));
		}
	};
};

export const moveUserToDefaultUserGroup = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('movedUserToDefaultGroup'));
		try {
			await events.put(`users/${userId}/defaultusergroup`);
			dispatch(fetchConditionSuccess('movedUserToDefaultGroup', true));
		} catch (error) {
			dispatch(fetchConditionFail('movedUserToDefaultGroup', error));
		}
	};
};

export const removeUserFromOrganisation = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('removeUserFromOrganisation'));
		try {
			await events.put(`users/${userId}/remove`);
			dispatch(fetchConditionSuccess('removeUserFromOrganisation', true));
			dispatch(updateRedirect('/user-management/users'));
		} catch (error) {
			dispatch(fetchConditionFail('removeUserFromOrganisation', error));
		}
	};
};

export const nfcServiceFlag = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('nfcServiceFlag'));
		try {
			await events.put(`users/${userId}/services/nfc`);
			dispatch(fetchConditionSuccess('nfcServiceFlag', true));
		} catch (error) {
			dispatch(fetchConditionFail('nfcServiceFlag', error));
		}
	};
};

export const removeNfcServiceFlag = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('removeNfcServiceFlag'));
		try {
			await events.delete(`users/${userId}/services/nfc`);
			dispatch(fetchConditionSuccess('removeNfcServiceFlag', true));
		} catch (error) {
			dispatch(fetchConditionFail('removeNfcServiceFlag', error));
		}
	};
};
/* * * * * * * * * * * * *
 * ORGANISATION METHODS  *
 * * * * * * * * * * * * */
export const validateOrganisationNameField = (value = null, organisationId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueName',
		value,
		...(!isNull(organisationId) && { organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('checkedOrganisationNameField'));
		try {
			const data = await events.get(`organisations/validate${queryParams}`);
			dispatch(fetchConditionSuccess('checkedOrganisationNameField', data));
		} catch (error) {
			dispatch(fetchConditionFail('checkedOrganisationNameField', error));
		}
	};
};

export const validateOrganisationVATField = (value = null, organisationId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueVATNumber',
		value,
		...(!isNull(organisationId) && { organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('checkedOrganisationVATField'));
		try {
			const data = await events.get(`organisations/validate${queryParams}`);
			dispatch(fetchConditionSuccess('checkedOrganisationVATField', data));
		} catch (error) {
			dispatch(fetchConditionFail('checkedOrganisationVATField', error));
		}
	};
};

export const validateOrganisationCOCField = (value = null, organisationId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueChamberOfCommerceNumber',
		value,
		...(!isNull(organisationId) && { organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('checkedOrganisationCOCField'));
		try {
			const data = await events.get(`organisations/validate${queryParams}`);
			dispatch(fetchConditionSuccess('checkedOrganisationCOCField', data));
		} catch (error) {
			dispatch(fetchConditionFail('checkedOrganisationCOCField', error));
		}
	};
};

export const validateOrganisationEnterpriseField = (value = null, organisationId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueEnterpriseNumber',
		value,
		...(!isNull(organisationId) && { organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('checkedOrganisationEnterpriseField'));
		try {
			const data = await events.get(`organisations/validate${queryParams}`);
			dispatch(fetchConditionSuccess('checkedOrganisationEnterpriseField', data));
		} catch (error) {
			dispatch(fetchConditionFail('checkedOrganisationEnterpriseField', error));
		}
	};
};

export const validateOrganisationIBANField = (value = null, organisationId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueIBAN',
		value,
		...(!isNull(organisationId) && { organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('checkedOrganisationIBANField'));
		try {
			const data = await events.get(`organisations/validate${queryParams}`);
			dispatch(fetchConditionSuccess('checkedOrganisationIBANField', data));
		} catch (error) {
			dispatch(fetchConditionFail('checkedOrganisationIBANField', error));
		}
	};
};

/* * * * * * * * * *
 * UPDATE USER ROLE *
 * * * * * * * * * */
export const updateUserRole = (userId = null, name = null) => {
	return async (dispatch) => {
		const body =
			isFullString(name) ?
				{
					name: name,
				}
			:	null;
		dispatch(fetchConditionStart('updateUserRole'));
		try {
			await events.put(`identityusers/${userId}/role`, body);
			dispatch(fetchConditionSuccess('updateUserRole', true));
		} catch (error) {
			dispatch(fetchConditionFail('updateUserRole', error));
		}
	};
};

/* * * * * * * * * *
 * LOCATION METHODS *
 * * * * * * * * * */
export const deleteHub = (hubId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteHub'));
		try {
			await events.delete(`hubs/${hubId}`);
			dispatch(fetchConditionSuccess('deleteHub', true));
			dispatch(updateRedirect('/location-management/locations'));
		} catch (error) {
			dispatch(fetchConditionFail('deleteHub', error));
		}
	};
};

export const updateHub = (hubId = null, body = null) => {
	const bodyData = isObject(body) ? body : null;

	return async (dispatch) => {
		dispatch(fetchConditionStart('updateHubDetails'));
		try {
			await events.put(`hubs/${hubId}`, bodyData);
			dispatch(fetchConditionSuccess('updateHubDetails', true));
			dispatch(updateRedirect(`/location-management/location/${hubId}/summary`));
		} catch (error) {
			dispatch(fetchConditionFail('updateHubDetails', error));
		}
	};
};

/* * * * * * * * * * * * *
 * ORGANISATION METHODS  *
 * * * * * * * * * * * * */
export const deleteOrganisation = (organisationId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteOrganisation'));
		try {
			await events.delete(`Organisations/${organisationId}`);
			dispatch(fetchConditionSuccess('deleteOrganisation', true));
			dispatch(updateRedirect('/organisation-management/organisations'));
		} catch (error) {
			dispatch(fetchConditionFail('deleteOrganisation', error));
		}
	};
};

export const disableOrganisation = (organisationId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('disableOrganisation'));
		try {
			await events.post(`Organisations/${organisationId}/disable`);
			dispatch(fetchConditionSuccess('disableOrganisation', true));
		} catch (error) {
			dispatch(fetchConditionFail('disableOrganisation', error));
		}
	};
};

/* * * * * * * *
 * ITEM METHODS *
 * * * * * * * */
export const deleteItem = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteItem'));
		try {
			await events.delete(`items/${userId}`);
			dispatch(fetchConditionSuccess('deleteItem', true));
			dispatch(updateRedirect('/item-management/items'));
		} catch (error) {
			dispatch(fetchConditionFail('deleteItem', error));
		}
	};
};

/* * * * * * * * * * * * *
 * ITEMINSTANCES METHODS *
 * * * * * * * * * * * * */
export const deleteItemInstance = (itemId = null, itemInstanceId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteItemInstance'));
		try {
			await events.delete(`items/${itemId}/iteminstances/${itemInstanceId}`);
			dispatch(fetchConditionSuccess('deleteItemInstance', true));
		} catch (error) {
			dispatch(fetchConditionFail('deleteItemInstance', error));
		}
	};
};

export const validateInstanceRoomNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueRoomNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedRoomNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedRoomNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedRoomNumber', error));
		}
	};
};

export const validateInstanceBikeLockId = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueBikeLockId',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedBikeLockId'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedBikeLockId', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedBikeLockId', error));
		}
	};
};

export const validateInstanceBikeNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueBikeNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedBikeNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedBikeNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedBikeNumber', error));
		}
	};
};

export const validateInstanceFrameNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueFrameNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedFrameNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedFrameNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedFrameNumber', error));
		}
	};
};

export const validateInstanceLicensePlate = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueLicensePlate',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedLicensePlate'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedLicensePlate', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedLicensePlate', error));
		}
	};
};

export const validateInstanceCarLockId = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueCarLockId',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedCarLockId'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedCarLockId', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedCarLockId', error));
		}
	};
};

export const validateInstanceVinNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueVinNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedVinNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedVinNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedVinNumber', error));
		}
	};
};

export const validateInstanceParkingNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueParkingNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedParkingNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedParkingNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedParkingNumber', error));
		}
	};
};

export const validateInstanceAssetNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueAssetNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedAssetNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedAssetNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedAssetNumber', error));
		}
	};
};

export const validateInstanceTrailerNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueTrailerNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedTrailerNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedTrailerNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedTrailerNumber', error));
		}
	};
};

export const validateInstanceBoatNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueBoatNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validateBoatNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validateBoatNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validateBoatNumber', error));
		}
	};
};

export const validateInstanceTrailerVinNumber = (itemId, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueTrailerVinNumber',
		value,
	});

	return async (dispatch) => {
		dispatch(fetchConditionStart('validatedTrailerVinNumber'));
		try {
			const data = await events.get(`items/${itemId}/itemInstances/validate${queryParams}`);
			dispatch(fetchConditionSuccess('validatedTrailerVinNumber', data));
		} catch (error) {
			dispatch(fetchConditionFail('validatedTrailerVinNumber', error));
		}
	};
};

export const updateItemInstance = (itemId = null, itemInstanceId = null, bodyData = {}) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('updatedItemInstance'));
		try {
			await events.put(`items/${itemId}/itemInstances/${itemInstanceId}`, bodyData);
			dispatch(fetchConditionSuccess('updatedItemInstance', true));
			const data = await events.get(`items/${itemId}/iteminstances/${itemInstanceId}`);
			dispatch(fetchDetailsSuccess('fetchInstance', data));
		} catch (error) {
			dispatch(fetchConditionFail('updatedItemInstance', error));
		}
	};
};

/**
 *
 * @deprecated See /features/items/services/itemsService
 */
export const updateItemInstanceCommands = (
	deviceId = null,
	itemId = null,
	itemInstanceId = null,
	commands = null,
) => {
	const bodyData =
		isFullString(commands) ?
			{
				command: commands,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchConditionStart('updateItemInstanceCommands'));
		try {
			await events.post(`items/${itemId}/itemInstances/${itemInstanceId}/commands`, bodyData);
			dispatch(fetchConditionSuccess('updateItemInstanceCommands', true));
			if (commands === 'lock') {
				await events.post(`items/${itemId}/itemInstances/${itemInstanceId}/commands`, {
					command: 'enable',
				});
				const data = await events.get(`devices/${deviceId}/heartbeats/latest`, true);
				dispatch(fetchDetailsSuccess('fetchDeviceHeartbeats', data));
			} else {
				const data = await events.get(`devices/${deviceId}/heartbeats/latest`, true);
				dispatch(fetchDetailsSuccess('fetchDeviceHeartbeats', data));
			}
		} catch (error) {
			dispatch(fetchConditionFail('updateItemInstanceCommands', error));
		}
	};
};

export const updateVehicleStatus = (deviceId = null, commands = null) => {
	const body =
		isFullString(commands) ?
			{
				command: commands,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchConditionStart('updateVehicleStatus'));
		try {
			await events.post(`devices/${deviceId}/commands`, body);
			dispatch(fetchConditionSuccess('updateVehicleStatus', true));
			const data = await events.get(`devices/${deviceId}/vehiclestatus`);
			dispatch(fetchDetailsSuccess('vehicleStatus', data));
		} catch (error) {
			dispatch(fetchConditionFail('updateVehicleStatus', error));
		}
	};
};

/* * * * * * * * * * *
 * USERGROUP METHODS *
 * * * * * * * * * * */
export const deleteUserGroup = (userGroupId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deletedUserGroup'));
		try {
			await events.delete(`usergroups/${userGroupId}`);
			dispatch(fetchConditionSuccess('deletedUserGroup', true));
			dispatch(updateRedirect('/user-management/user-groups'));
		} catch (error) {
			dispatch(fetchConditionFail('deletedUserGroup', error));
		}
	};
};

/* * * * * * * * * *
 * BOOKING METHODS *
 * * * * * * * * * */
export const updateBooking = (bookingId = null, bodyData = {}) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('updatedBookingDetails'));
		try {
			await events.put(`planboard/${bookingId}`, bodyData);
			//dispatch(fetchDetailsSuccess('booking', bookingData));
			dispatch(fetchConditionSuccess('updatedBookingDetails', true));
		} catch (error) {
			dispatch(fetchConditionFail('updatedBookingDetails', error));
		}
	};
};

export const confirmBooking = (bookingId = null, fetch = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('confirmBooking'));
		try {
			await events.put(`bookings/${bookingId}/confirm`);
			dispatch(fetchConditionSuccess('confirmBooking', true));
			if (fetch) {
				const data = await events.get(`bookings/${bookingId}`);
				dispatch(fetchDetailsSuccess('fetchBooking', data));
			}
		} catch (error) {
			dispatch(fetchConditionFail('confirmBooking', error));
		}
	};
};

export const rejectBooking = (bookingId = null, fetch = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('rejectBooking'));
		try {
			await events.put(`bookings/${bookingId}/decline`);
			dispatch(fetchConditionSuccess('rejectBooking', true));
			if (fetch) {
				const data = await events.get(`bookings/${bookingId}`);
				dispatch(fetchDetailsSuccess('fetchBooking', data));
			}
		} catch (error) {
			dispatch(fetchConditionFail('rejectBooking', error));
		}
	};
};

export const bookingMileage = (bookingId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('bookingMileage'));
		try {
			await events.put(`bookings/${bookingId}/mileage`, bodyData);
			dispatch(fetchConditionSuccess('bookingMileage', true));
		} catch (error) {
			dispatch(fetchConditionFail('bookingMileage', error));
		}
	};
};

export const updateCooldown = (bookingId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('updateCooldown'));
		try {
			await events.put(`bookings/${bookingId}/cooldown/reset`);
			dispatch(fetchConditionSuccess('updateCooldown', true));
		} catch (error) {
			dispatch(fetchConditionFail('updateCooldown', error));
		}
	};
};

export const removeAttentions = (bookingId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('removeAttentions'));
		try {
			await events.put(`bookings/${bookingId}/attentions/remove`, bodyData);
			dispatch(fetchConditionSuccess('removeAttentions', true));
		} catch (error) {
			dispatch(fetchConditionFail('removeAttentions', error));
		}
	};
};

export const patchUpdateBooking = (bookingId = null, properties = null) => {
	const bodyData =
		isObject(properties) ?
			Object.entries(properties).map((prop) => ({
				value: prop[1],
				path: `/${prop[0]}`,
				op: 'replace',
			}))
		:	{};
	return async (dispatch) => {
		dispatch(fetchConditionStart('updatedBookingDetails'));
		try {
			let data = await events.patch(`planboard/${bookingId}`, bodyData);
			if (data instanceof Blob) {
				data = JSON.parse(await data.text());
			}
			dispatch(fetchDetailsSuccess('fetchBooking', data));
			dispatch(fetchConditionSuccess('updatedBookingDetails', true));
		} catch (error) {
			dispatch(fetchConditionFail('updatedBookingDetails', error));
		}
	};
};

export const removeAccessDevices = (itemId = null, devices = null) => {
	const bodyData = {
		deviceIds: devices,
	};

	return async (dispatch) => {
		dispatch(fetchConditionStart('removeAccessDevices'));
		try {
			await events.put(`items/${itemId}/devices/remove`, bodyData);
			dispatch(fetchConditionSuccess('removeAccessDevices', true));
		} catch (error) {
			dispatch(fetchConditionFail('removeAccessDevices', error));
		}
	};
};

export const cancelBooking = (bookingId = null, redirect = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('canceledBooking'));
		try {
			await events.put(`bookings/${bookingId}/cancel`);
			dispatch(fetchConditionSuccess('canceledBooking', true));
			if (redirect) {
				dispatch(updateRedirect('/bookings'));
			}
		} catch (error) {
			dispatch(fetchConditionFail('canceledBooking', error));
		}
	};
};

export const refundBooking = (bookingId = null, subtotal = null, fetch = null) => {
	const bodyData =
		isNumber(subtotal) ?
			{
				refundAmount: subtotal,
			}
		:	null;
	return async (dispatch) => {
		dispatch(fetchConditionStart('refundBooking'));
		try {
			await events.put(`bookings/${bookingId}/refund`, bodyData);
			dispatch(fetchConditionSuccess('refundBooking', true));
			if (fetch) {
				const data = await events.get(`bookings/${bookingId}`);
				dispatch(fetchDetailsSuccess('fetchBooking', data));
			}
		} catch (error) {
			dispatch(fetchConditionFail('refundBooking', error));
		}
	};
};

/* * * * * * * * * *
 * PRICING METHODS *
 * * * * * * * * * */
export const deletePricingModel = (pricingModelId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deletedPricingModel'));
		try {
			await events.delete(`pricingmodels/${pricingModelId}`);
			dispatch(fetchConditionSuccess('deletedPricingModel', true));
		} catch (error) {
			dispatch(fetchConditionFail('deletedPricingModel', error));
		}
	};
};

export const deleteTerm = (termId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteTerm'));
		try {
			await events.delete(`terms/${termId}`);
			dispatch(fetchConditionSuccess('deleteTerm', true));
			dispatch(updateRedirect('/policy-management/terms-and-conditions'));
		} catch (error) {
			dispatch(fetchConditionFail('deleteTerm', error));
		}
	};
};

/* * * * * * * * * *
 * Policy METHODS *
 * * * * * * * * * */
export const deletePolicy = (termId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deletePolicy'));
		try {
			await events.delete(`policies/${termId}`);
			dispatch(fetchConditionSuccess('deletePolicy', true));
			dispatch(updateRedirect('/policy-management/item-policies'));
		} catch (error) {
			dispatch(fetchConditionFail('deletePolicy', error));
		}
	};
};

export const deleteInvitations = (id = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteInvitations'));
		try {
			await events.delete(`invitations/${id}`);
			dispatch(fetchConditionSuccess('deleteInvitations', true));
		} catch (error) {
			dispatch(fetchConditionFail('deleteInvitations', error));
		}
	};
};

export const payBillingPeriod = (billingId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('payBillingPeriod'));
		try {
			await events.put(`billingperiods/${billingId}/paid`);
			dispatch(fetchConditionSuccess('payBillingPeriod', true));
		} catch (error) {
			dispatch(fetchConditionFail('payBillingPeriod', error));
		}
	};
};

/* * * * * * * * * * * * *
 * Email Domain METHODS *
 * * * * * * * * * * * */
//#region Email Domain

export const updateEmailDomainLinking = (organisationId = null, enabled = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('updateEmailDomainLinking'));
		try {
			await events.put(`organisations/${organisationId}/emailDomains`, { value: enabled });
			dispatch(fetchConditionSuccess('updateEmailDomainLinking', true));
			const data = await events.get(`organisations/${organisationId}`);
			dispatch(fetchDetailsSuccess('organisation', data));
		} catch (error) {
			dispatch(fetchConditionFail('updateEmailDomainLinking', error));
		}
	};
};

export const addEmailDomains = (organisationId = null, emailDomains = null) => {
	const bodyData = isFullArray(emailDomains) ? emailDomains : null;

	return async (dispatch) => {
		dispatch(fetchConditionStart('addEmailDomains'));
		try {
			await events.put(`organisations/${organisationId}/domains`, bodyData);
			dispatch(fetchConditionSuccess('addEmailDomains', true));
		} catch (error) {
			dispatch(fetchConditionFail('addEmailDomains', error));
		}
	};
};

export const editEmailDomain = (organisationId = null) => {
	//TP-3703: FUNCTIONALITY WILL BE ADDED LATER ON
	return async (dispatch) => {
		dispatch(fetchConditionStart('editEmailDomain'));
		try {
			await events.put(`organisations/${organisationId}/domain`);
			dispatch(fetchConditionSuccess('editEmailDomain', true));
			const data = await events.get(`organisations/${organisationId}/domains`);
			dispatch(fetchPagedSuccess('emailDomains', data));
		} catch (error) {
			dispatch(fetchConditionFail('editEmailDomain', error));
		}
	};
};

export const deleteEmailDomain = (organisationId = null, emailDomain = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteEmailDomain'));
		try {
			await events.delete(`organisations/${organisationId}/domains/${emailDomain}`);
			dispatch(fetchConditionSuccess('deleteEmailDomain', true));
			const data = await events.get(`organisations/${organisationId}/domains`);
			dispatch(fetchPagedSuccess('emailDomains', data));
		} catch (error) {
			dispatch(fetchConditionFail('deleteEmailDomain', error));
		}
	};
};

export const deleteAllEmailDomains = (organisationId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteEmailDomain'));
		try {
			await events.delete(`organisations/${organisationId}/domains`);
			dispatch(fetchConditionSuccess('deleteEmailDomain', true));
			const data = await events.get(`organisations/${organisationId}/domains`);
			dispatch(fetchPagedSuccess('emailDomains', data));
		} catch (error) {
			dispatch(fetchConditionFail('deleteEmailDomain', error));
		}
	};
};

export const validateEmailDomain = (organisationId = null, value = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueName',
		value,
	});
	return async (dispatch) => {
		dispatch(fetchConditionStart('validateEmailDomain'));
		try {
			const data = await events.get(
				`organisations/${organisationId}/domains/validate${queryParams}`,
			);
			dispatch(fetchConditionSuccess('validateEmailDomain', data));
		} catch (error) {
			dispatch(fetchConditionFail('validateEmailDomain', error));
		}
	};
};
//#endregion

export const updateDeviceAccess = (organisationsId = null, access = null) => {
	const bodyData =
		isNumber(organisationsId) ?
			{
				value: access,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchConditionStart('deviceAccess'));
		try {
			await events.put(`organisations/${organisationsId}/devices`, bodyData);
			dispatch(fetchConditionSuccess('deviceAccess', true));
			const dataOrganisation = await events.get(`Organisations/${organisationsId}`);
			dispatch(fetchDetailsSuccess('organisation', dataOrganisation));
		} catch (error) {
			dispatch(fetchConditionFail('deviceAccess', error));
		}
	};
};

export const unlinkItemGroup = (deviceId = null, organisationId = null, itemId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('unlinkItemGroup'));
		try {
			await events.put(
				`devices/${deviceId}/organisations/${organisationId}/items/${itemId}/unassign`,
			);
			dispatch(fetchConditionSuccess('unlinkItemGroup', true));
		} catch (error) {
			dispatch(fetchConditionFail('unlinkItemGroup', error));
		}
	};
};

export const unlinkAllItemGroup = (deviceId = null, organisationId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('unlinkAllItemGroup'));
		try {
			await events.put(`devices/${deviceId}/organisations/${organisationId}/items/unassignall`);
			dispatch(fetchConditionSuccess('unlinkAllItemGroup', true));
		} catch (error) {
			dispatch(fetchConditionFail('unlinkAllItemGroup', error));
		}
	};
};

export const unlinkDevices = (deviceId = null, organisationId = null, instanceId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('unlinkDevices'));
		try {
			await events.put(
				`devices/${deviceId}/organisations/${organisationId}/iteminstance/${instanceId}/unassign`,
			);
			dispatch(fetchConditionSuccess('unlinkDevices', true));
		} catch (error) {
			dispatch(fetchConditionFail('unlinkDevices', error));
		}
	};
};

export const approveUserLicense = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('approveUserLicense'));
		try {
			await events.put(`userlicenses/${userId}/approve`);
			dispatch(fetchConditionSuccess('approveUserLicense', true));
		} catch (error) {
			dispatch(fetchConditionFail('approveUserLicense', error));
		}
	};
};

export const rejectUserLicense = (userId = null, reasonForRejection = '') => {
	const requestBody =
		isFullString(reasonForRejection) ?
			{
				message: reasonForRejection,
			}
		:	null;
	return async (dispatch) => {
		dispatch(fetchConditionStart('rejectUserLicense'));
		try {
			await events.put(`userlicenses/${userId}/reject`, requestBody);
			dispatch(fetchConditionSuccess('rejectUserLicense', true));
		} catch (error) {
			dispatch(fetchConditionFail('rejectUserLicense', error));
		}
	};
};

/* * * * * * * * * *
 * NFC Tag METHODS *
 * * * * * * * * * */

export const addNFCTag = (
	userId = null,
	nfcId = null,
	cardNumber = null,
	cardType = 'business',
) => {
	const body =
		isFullString(nfcId) && isFullString(cardNumber) && isFullString(cardType) ?
			{
				userId,
				nfcId,
				cardNumber,
				cardType,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchConditionStart('addedNfcTag'));
		try {
			await events.post('nfccards', body);
			dispatch(fetchConditionSuccess('addedNfcTag', true));
			const data = await events.get(`nfccards/${userId}`);
			dispatch(fetchDetailsSuccess('NFCTags', data));
		} catch (error) {
			dispatch(fetchConditionFail('addedNfcTag', error));
		}
	};
};

export const deleteNFCTag = (userId = null, cardNumber = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deletedNfcTag'));
		try {
			await events.put(`nfccards/${cardNumber}/unlink`);
			dispatch(fetchConditionSuccess('deletedNfcTag', true));
			const data = await events.get(`nfccards/${userId}`);
			dispatch(fetchDetailsSuccess('NFCTags', data));
		} catch (error) {
			dispatch(fetchConditionFail('deletedNfcTag', error));
		}
	};
};

/**
 * NFC card to a specific ID.
 * @param {string|null} nfcId - The ID of the NFC card
 */
export const deleteNfc = (nfcId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteNfc'));
		try {
			await events.delete(`nfccards/${nfcId}`);
			dispatch(fetchConditionSuccess('deleteNfc', true));
		} catch (error) {
			dispatch(fetchConditionFail('deleteNfc', error));
		}
	};
};

/**
 * Link NFC card to a specific ID.
 * @param {string|null} nfcId - The ID of the NFC card to link. Set to null for default behavior.
 */
export const unlinkNfc = (nfcId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('unlinkNfc'));
		try {
			await events.put(`nfccards/${nfcId}/unlink`);
			dispatch(fetchConditionSuccess('unlinkNfc', true));
		} catch (error) {
			dispatch(fetchConditionFail('unlinkNfc', error));
		}
	};
};

export const deleteTankCards = (itemId = null, instanceId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteTankCards'));
		try {
			await events.delete(`items/${itemId}/iteminstances/${instanceId}/tankcards`);
			dispatch(fetchConditionSuccess('deleteTankCards', true));
			const data = await events.get(`items/${itemId}/iteminstances/${instanceId}`);
			dispatch(fetchDetailsSuccess('fetchInstance', data));
		} catch (error) {
			dispatch(fetchConditionFail('deleteTankCards', error));
		}
	};
};

export const deleteKeyTag = (itemId = null, instanceId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteKeyTag'));
		try {
			await events.delete(`items/${itemId}/iteminstances/${instanceId}/keytags`);
			dispatch(fetchConditionSuccess('deleteKeyTag', true));
			const data = await events.get(`items/${itemId}/iteminstances/${instanceId}`);
			dispatch(fetchDetailsSuccess('fetchInstance', data));
		} catch (error) {
			dispatch(fetchConditionFail('deleteKeyTag', error));
		}
	};
};

export const assignUserContract = (contractId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('assignUserContract'));
		try {
			await events.put(`contracts/${contractId}/assignuser`, bodyData);
			dispatch(fetchConditionSuccess('assignUserContract', true));
		} catch (error) {
			dispatch(fetchConditionFail('assignUserContract', error));
		}
	};
};

export const createAnonymousUser = (
	firstName = null,
	lastName = null,
	cardNumber = null,
	nfcId = null,
	organisationId = null,
	userGroupId = null,
) => {
	const bodyData = {
		firstName,
		lastName,
		cardNumber,
		nfcId,
		organisationId,
		userGroupId,
	};

	return async (dispatch) => {
		dispatch(fetchConditionStart('createAnonymousUser'));
		try {
			await events.post('identityusers/anonymous', bodyData);
			dispatch(fetchConditionSuccess('createAnonymousUser', true));
			dispatch(updateRedirect('/user-management/users'));
		} catch (error) {
			dispatch(fetchConditionFail('createAnonymousUser', error));
		}
	};
};

export const deleteContract = (contractId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('deleteContract'));
		try {
			await events.delete(`contracts/${contractId}`);
			dispatch(fetchConditionSuccess('deleteContract', true));
		} catch (error) {
			dispatch(fetchConditionFail('deleteContract', error));
		}
	};
};

export const extendContract = (contractId = null, body = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('extendContract'));
		try {
			await events.put(`contracts/${contractId}/extend`, body);
			dispatch(fetchConditionSuccess('extendContract', true));
		} catch (error) {
			dispatch(fetchConditionFail('extendContract', error));
		}
	};
};

export const patchTrip = (deviceId = null, tripId = null, properties = null) => {
	const bodyData =
		isObject(properties) ?
			Object.entries(properties).map((prop) => ({
				value: prop[1],
				path: `/${prop[0]}`,
				op: 'replace',
			}))
		:	{};
	return async (dispatch) => {
		dispatch(fetchConditionStart('patchTrip'));
		try {
			await events.patch(`devices/${deviceId}/trips/${tripId}`, bodyData);
			dispatch(fetchConditionSuccess('patchTrip', true));
		} catch (error) {
			dispatch(fetchConditionFail('patchTrip', error));
		}
	};
};

export const businessUsage = (contractId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('businessUsage'));
		try {
			await events.put(`contracts/${contractId}/businessusage`, bodyData);
			dispatch(fetchConditionSuccess('businessUsage', true));
		} catch (error) {
			dispatch(fetchConditionFail('businessUsage', error));
		}
	};
};

export const resetLicensesAttempts = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('resetLicensesAttempts'));
		try {
			await events.put(`userlicenses/${userId}/reset`);
			dispatch(fetchConditionSuccess('resetLicensesAttempts', true));
		} catch (error) {
			dispatch(fetchConditionFail('resetLicensesAttempts', error));
		}
	};
};

export const instanceRestriction = (itemId = null, instanceId = null, isAvailable = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('instanceRestriction'));
		try {
			await events.put(
				`items/${itemId}/iteminstances/${instanceId}/availability/${isAvailable ? 'allow' : 'restrict'}`,
			);
			dispatch(fetchConditionSuccess('instanceRestriction', true));
		} catch (error) {
			dispatch(fetchConditionFail('instanceRestriction', error));
		}
	};
};

export const verifyEmail = (body = null) => {
	return async (dispatch) => {
		dispatch(fetchConditionStart('verifyEmail'));
		try {
			await events.put('emailverification/verify', body);
			dispatch(fetchConditionSuccess('verifyEmail', true));
		} catch (error) {
			dispatch(fetchConditionFail('verifyEmail', error));
		}
	};
};
