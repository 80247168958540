import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	premium: {
		marginBottom: theme.spacing(3),
	},
	nameSpace: {
		display: 'flex',
		flexDirection: 'row',
	},
	card: {
		marginBottom: theme.spacing(3),
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	daySection: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	timeSpacing: {
		paddingLeft: '100px',
	},
	weekDaySection: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(1),
		alignItems: 'center',
	},
	nameValue: {
		paddingLeft: '20px',
	},
	textField: {
		paddingLeft: '100px',
	},
	kilometerSwitch: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: theme.spacing(2, 0),
	},
	carOption: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '10px',
	},
	freeLabel: {
		minWidth: '81px',
	},
}));
