import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(3),
		// display: 'flex'
	},
	header: {
		padding: theme.spacing(2, 0),
	},
	divider: {
		backgroundColor: colors.grey[300],
		margin: theme.spacing(0, 0, 4, 0),
	},
	organisationDivider: {
		margin: theme.spacing(1.5, 0),
	},
	card: {
		margin: theme.spacing(0, 3, 0, 12),
		flexBasis: '50%',
	},
	cardContent: {
		padding: theme.spacing(4, 5, 5, 5),
	},
	innerCard: {
		margin: theme.spacing(2, 0),
	},
	activityCard: {
		marginTop: theme.spacing(3),
	},
	sideCard: {
		flexBasis: '25%',
		'& .MuiCardHeader-root': {
			padding: '0',
		},
		'& .MuiCardContent-root': {
			padding: theme.spacing(4, 5, 5, 5),
		},
	},
	imageContainer: {
		height: '258px',
		width: '495px',
		marginRight: theme.spacing(4),
		marginTop: theme.spacing(2),
	},
	imageSpacing: {
		objectFit: 'cover',
		width: '100%',
		height: '100%',
		margin: '0',
		padding: '0',
		cursor: 'pointer',
	},
	arrowLeft: {
		cursor: 'pointer',
	},
	backButton: {
		minHeight: '60px',
		marginLeft: theme.spacing(12),
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
	},
	logo: {
		marginRight: theme.spacing(1),
		'& img': {
			objectFit: 'scale-down',
		},
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1),
	},
	nameCell: {
		display: 'flex',
		alignItems: 'center',
	},
}));
