import path from 'path';

import WeekdayEnum from '~enums/dayOfWeekEnum';
import { PagedResponse, SkcPagedResponse, SkcSingleResponse, WeeklySchedule } from '~interfaces';
import { BaseReferenceResponse, IdReferenceResponse } from '~interfaces/responses';
import { BaseServiceMapper, TopologyProxiedSkopeiConnectService } from '~services';
import { parseTime, time24Format } from '~utils/dateUtils';

import AccessLog from '../interfaces/accessLog';

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcAccessLogsService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'access-logs';

	constructor() {
		super();
	}

	async getAccessLogs({
		page = 1,
		pageSize = 10,
		...args
	}: {
		page?: number,
		pageSize?: number;
		organisation?: number;
	}): Promise<PagedResponse<AccessLog>> {
		const { data } = await this._client.get<SkcPagedResponse<SkcAccessLogResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
			},
		});

		return {
			...this.mapMetaResponse(data),
			results: data.data.map((el) => SkcAccessLogServiceMapper.fromResponse(el)),
		};
	}

	async getAccessLogById(id: string): Promise<AccessLog> {
		const { data } = await this._client.get<SkcSingleResponse<SkcAccessLogResponse>>(
			path.join(this.path, id),
		);

		return SkcAccessLogServiceMapper.fromResponse(data.data);
	}
}

class SkcAccessLogServiceMapper {
	static fromResponse(data: SkcAccessLogResponse): AccessLog {
		const { id, event, timestamp, rule, card, device } = data;

		return {
			id: id.toString(),
			timestamp: new Date(timestamp),
			eventType: event,
			...(rule != null && {
				rule: BaseServiceMapper.fromBaseReferenceResponse(rule)
			}),
			...(card != null && {
				nfcTag: BaseServiceMapper.fromBaseReferenceResponse(card)
			}),
			...(device != null && {
				device: {
					id: device.id.toString()
				}
			})
		};
	}
}

interface SkcAccessLogResponse extends IdReferenceResponse {
	event: string
	timestamp: string;
	rule?: BaseReferenceResponse;
	card?: BaseReferenceResponse;
	device?: IdReferenceResponse;
}

export default SkcAccessLogsService;
