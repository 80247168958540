import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Paper, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { ReactDatePicker, StyledButton } from '../../../../components';
import { isDateToday } from '../../../../shared/datetime';

const TopBar = ({
	date,
	onDateToday,
	onDateNext,
	onDatePrev,
	onDateChange,
	onAddBooking,
	disabled,
}) => {
	const classes = useStyles();
	const { t } = useTranslation('general');

	return (
		<Paper className={classes.root} elevation={0} square>
			<Box alignItems='center' display='flex' flexWrap='wrap'>
				<Box alignItems='center' display='flex'>
					<ReactDatePicker
						dateFormat='d MMMM yyyy'
						onChange={onDateChange}
						selected={date}
						variant='text'
					/>
					<Box display='flex' ml={3} mr={3}>
						<IconButton disabled={disabled} color='primary' onClick={onDatePrev} size='small'>
							<NavigateBeforeIcon />
						</IconButton>
						<IconButton
							disabled={disabled}
							className={classes.navigateNext}
							color='primary'
							onClick={onDateNext}
							size='small'
						>
							<NavigateNextIcon />
						</IconButton>
					</Box>
				</Box>
				<StyledButton
					className={classes.button}
					disabled={isDateToday(date)}
					onClick={onDateToday}
					variant='contained-tertiary'
				>
					{t('view.planboard.button.contained.today')}
				</StyledButton>
			</Box>
			<Box alignItems='center' display='flex'>
				<StyledButton disabled={disabled} onClick={onAddBooking} variant='contained-primary'>
					{t('ui.button.contained.newbooking')}
				</StyledButton>
			</Box>
		</Paper>
	);
};

TopBar.propTypes = {
	date: PropTypes.instanceOf(Date),
	onDateToday: PropTypes.func,
	onDateNext: PropTypes.func,
	onDatePrev: PropTypes.func,
	onDateChange: PropTypes.func,
	onAddBooking: PropTypes.func,
	disabled: PropTypes.bool,
};

export default TopBar;
