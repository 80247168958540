import EuroIcon from '@mui/icons-material/Euro';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { Table, GenericMoreButton } from '../../../../../components';
import { isObject } from '../../../../../shared/utility';
import { useStyles } from '../../../Management/style';
import { editTypes } from '../../../ManagementConstants';

const SummaryTable = (props) => {
	const { totalInstances, providerId, itemDetails, sharingType } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();

	const consumerName =
		itemDetails?.consumerName ? itemDetails.consumerName
		: itemDetails?.consumer?.name ? itemDetails.consumer.name
		: undefined;

	const handleEdit = (editType) => {
		navigate('/item-management/management/edit', {
			state: {
				editType: editType,
				providerId: parseInt(providerId),
				selectedItems: [
					{
						...itemDetails.item,
						consumerName: consumerName,
						type: sharingType,
						userGroup: itemDetails?.userGroup,
					},
				],
			},
		});
	};

	const createMenuItems = () => [
		{
			action: () => handleEdit(editTypes.POLICY),
			icon: <LocalPoliceOutlinedIcon />,
			text: t('views.itemManagement.management.button.edit.policy'),
		},
		{
			action: () => handleEdit(editTypes.PRICING),
			icon: <EuroIcon />,
			text: t('views.itemManagement.management.button.edit.pricing'),
		},
		{
			action: () => handleEdit(editTypes.TERMS),
			icon: <GavelOutlinedIcon />,
			text: t('views.itemManagement.management.button.edit.terms'),
		},
	];

	const handleLinks = (
		path,
		label,
		returnButton = 'views.itemManagement.management.table.header',
	) => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			state={{ from: location.pathname, label: t(returnButton) }}
		>
			{label}
		</Link>
	);

	const header = [
		{ name: 'itemGroep', content: t('ui.label.item') },
		{ name: 'qty', content: t('ui.qty') },
		{ name: 'userGroup', content: t('ui.label.userGroup') },
		{ name: 'terms', content: t('views.addItem.steps.sidePanelDetails.settings.terms') },
		{ name: 'price', content: t('views.management.header.pricing') },
		{ name: 'businessPrice', content: t('ui.label.businessPrice') },
		{ name: 'policy', content: t('views.addItem.settings.subsection.policy.title') },
		...(itemDetails?.canEdit ? [{ name: '', content: '' }] : []),
	];

	const body =
		isObject(itemDetails) ?
			[
				[
					{
						content: handleLinks(
							`/item-management/items/${itemDetails.item.id}/summary`,
							itemDetails.item.name,
						),
					},
					{ content: totalInstances },
					{
						content:
							itemDetails?.userGroup?.name ?
								handleLinks(
									`/user-management/user-groups/${itemDetails.userGroup.id}/summary`,
									itemDetails.userGroup.name,
								)
							:	t('views.itemManagement.management.userGroup.placeholder'),
					},
					{
						content:
							itemDetails?.terms?.name &&
							handleLinks(`/term/details/${itemDetails.terms.id}/summary`, itemDetails.terms.name),
					},
					{
						content:
							itemDetails?.pricingModel?.name &&
							handleLinks(
								`/pricing/${itemDetails.pricingModel.id}/summary`,
								itemDetails.pricingModel.name,
							),
					},
					{
						content:
							itemDetails?.businessPricingModel?.name &&
							handleLinks(
								`/pricing/${itemDetails.businessPricingModel.id}/summary`,
								itemDetails.businessPricingModel.name,
							),
					},
					{
						content:
							itemDetails?.policy?.name &&
							handleLinks(
								`/policy-management/item-policies/${itemDetails.policy.id}/summary`,
								itemDetails.policy.name,
							),
					},
					...(itemDetails?.canEdit ?
						[{ content: <GenericMoreButton menuItems={createMenuItems()} /> }]
					:	[]),
				],
			]
		:	Array(2)
				.fill(Array(header.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const tableProps = {
		body,
		header,
		isNotPaginate: true,
		loading: false,
		noTitle: true,
		withoutPages: true,
		removeMrgin: true,
	};

	return <Table {...tableProps} />;
};

SummaryTable.propTypes = {
	totalInstances: PropTypes.number,
	providerId: PropTypes.number,
	itemDetails: PropTypes.object,
	sharingType: PropTypes.string,
};

export default SummaryTable;
