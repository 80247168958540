import { Box, Stack, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { InfoCard } from '~components';
import { DayOfWeekEnum } from '~enums';
import { DayOfWeekSchedule } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';

import useAccessRuleData from '../../hooks/useAccessRuleData';

interface DayOfWeekSchedulesCardProps {
	/**
	 * Id of the access rule
	 */
	id: string;
}

const DayOfWeekSchedulesCard = ({ id, ...cardInfoProps }: DayOfWeekSchedulesCardProps) => {
	const { t } = useTranslation('general');

	const { data, isLoading, error } = useAccessRuleData(id);

	const rows =
		!data?.schedules ?
			[]
		:	Object.values(DayOfWeekEnum).map((dayOfWeek, i) => ({
				headerName: t(dayOfWeek),
				value: data?.schedules?.find((el) => el.dayOfWeek === dayOfWeek),
				renderCell: (value: DayOfWeekSchedule<Dayjs>) => (
					<Stack>
						{value.isOpenAllDay ?
							t('openAllDay')
						: !value.isOpen ?
							t('closed')
						:	value.periods?.map((el, i) => (
								<Box key={i}>
									{`${el?.start?.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${el?.end?.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
								</Box>
							))
						}
					</Stack>
				),
				columns: 1,
			}));

	return (
		<InfoCard
			{...cardInfoProps}
			title={t('timeSlots')}
			rows={rows}
			error={error != null}
			loading={isLoading}
		/>
	);
};

export default DayOfWeekSchedulesCard;
