export const availabilityTypes = {
	AVAILABLE: 'makeAvailable',
	UNAVAILABLE: 'makeUnavailable',
	BOOKABLE: 'makeBookable',
	UNBOOKABLE: 'makeUnbookable',
};
export const initialState = {
	openDialog: false,
	itemId: null,
	instanceId: null,
	type: null,
};

export const reducer = (state, action) => {
	switch (action.type) {
		case 'updateAvailability':
			return action.payload;
		case 'updateInstance':
			return { ...state, instanceId: action.payload };
		case 'updateItem':
			return { ...state, itemId: action.payload };
		case 'updateType':
			return { ...state, type: action.payload };
		case 'openDialog':
			return { ...state, openDialog: !action.payload };
		case 'reset':
			return initialState;
		default: {
			return state;
		}
	}
};
