/* eslint-disable indent */

import { ListItem } from '@mui/material';

import { LoadingBar } from '../../../loading';
import { useStyles } from '../style';

const LoadingContent = () => {
	const classes = useStyles();

	return (
		<>
			<ListItem className={classes.listItem}>
				<LoadingBar />
			</ListItem>
			<ListItem className={classes.listItem}>
				<LoadingBar />
			</ListItem>
			<ListItem className={classes.listItem}>
				<LoadingBar />
			</ListItem>
		</>
	);
};

export default LoadingContent;
