import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const SelectedUserGroupCard = (props) => {
	const { name, handleClose } = props;

	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent className={classes.content}>
				<Typography variant='h5'>{name}</Typography>
			</CardContent>
			<IconButton onClick={handleClose} size='large'>
				<CloseIcon />
			</IconButton>
		</Card>
	);
};

SelectedUserGroupCard.propTypes = {
	name: PropTypes.string,
	handleClose: PropTypes.func,
};

export default SelectedUserGroupCard;
