import { useTranslation } from 'react-i18next';

import { AccessLogsDataGrid } from '~features/access';

import Page from '../page';

const AccessLogsPage = () => {
	const { t } = useTranslation('general');

	return (
		<Page title={t('logs')}>
			<AccessLogsDataGrid />
		</Page>
	);
};

export default AccessLogsPage;
