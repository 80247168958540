import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';

const Header = (props) => {
	const { className, customer, loading } = props;
	const location = useLocation();
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const classes = useStyles();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => {
		if (location?.state?.from) {
			navigate(location.state.from);
		} else {
			navigate('/user-management/users');
		}
	};

	const handleBackButton = () => {
		if (returnButton.isBackButton || !location.state?.label) {
			return t('ui.button.inline.back');
		} else {
			return location.state.label;
		}
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{handleBackButton()}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				{loading ?
					<AdjustedSkeleton animation='wave' width={200} />
				:	`${customer.firstName} ${customer.lastName}`}
			</Typography>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,

	customer: PropTypes.object,

	loading: PropTypes.bool,
};

export default Header;
