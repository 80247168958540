enum DeviceTypeEnum {
	None = 'none',
	BikeLock = 'bikeLock',
	DoorLock = 'doorLock',
	Bmw = 'bmwCar',
	TrailerLock = 'trailerLock',
	BatteryBackup = 'batteryBackupSystem',
	BorderRouter = 'borderRouter',
	VehicleTracker = 'vehicleTracker',
	VehicleTrackerCan = 'vehicleTrackerCan',
	VehicleTrackerObd = 'vehicleTrackerObd',
	VehicleTrackerPro = 'vehicleTrackerPro',
	CpacBoat = 'cpacBoat',
}

export default DeviceTypeEnum;
