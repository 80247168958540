import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EmptyListIcon from '~components/elements/EmptyState/assets/booking.svg?react';
import i18n from '~lib/i18n';

interface PageNotFoundPageProps {
	title?: string;
}

const PageNotFoundPage = ({ title = i18n.t('notFound') }: PageNotFoundPageProps) => {
	const { t } = useTranslation('general');

	return (
		<Stack
			width={1}
			height={1}
			display='flex'
			alignItems='center'
			justifyContent='center'
			direction='column'
			spacing={1}
		>
			<EmptyListIcon />
			<Typography variant='h6'>{title}</Typography>
		</Stack>
	);
};

export default PageNotFoundPage;
