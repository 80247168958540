import PropTypes from 'prop-types';

import { useStyles } from './style';

/**
 * Mui Material linearprogress should be used instead of this
 * @param props
 * @returns
 * @deprecated
 */
const Bar = (props) => {
	const classes = useStyles();
	return <span className={classes.loadingBar} style={{ ...props.style }} />;
};

Bar.propTypes = {
	style: PropTypes.object,
};

export default Bar;
