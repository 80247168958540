import { useMemo, useState } from 'react';

import { GridCallbackDetails, GridColDef, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DataGrid } from '~components';
import { BaseReference } from '~interfaces';

import AccessLog from '../../interfaces/accessLog';
import SkcAccessLogsService from '../../services/skcAccessLogsService';
import AccessEventChip from '../chips/accessEventChip';

const service = new SkcAccessLogsService();

interface AccessLogsDataGridProps {}

const AccessLogsDataGrid = ({ ...props }: AccessLogsDataGridProps) => {
	const { t } = useTranslation('general');

	const [fetchParameters, setFetchParameters] = useState<object | null>(null);

	const columns: GridColDef<AccessLog>[] = useMemo(() => [
		{ field: 'id', headerName: 'ID', width: 80, hideable: false },
		{
			field: 'timestamp',
			headerName: t('timestamp'),
			flex: 1,
			valueGetter: (_, row) => row.timestamp.toLocaleString(),
		},
		{
			field: 'device',
			headerName: t('device'),
			flex: 1,
			valueGetter: (value?: BaseReference) => value?.label ?? value?.id,
			valueFormatter: (value: string) => value ?? '-',
		},
		{
			field: 'nfcTag',
			headerName: t('ui.label.anonymousUserNFCTag'),
			flex: 1,
			valueGetter: (value?: BaseReference) => value?.label,
			valueFormatter: (value: string) => value ?? '-',
		},
		{
			field: 'rule',
			headerName: t('rule'),
			flex: 1,
			valueGetter: (value?: BaseReference) => value?.label,
			valueFormatter: (value: string) => value ?? '-',
		},
		{
			field: 'eventType',
			headerName: t('ui.label.event'),
			flex: 1,
			renderCell: (params) => (
				<AccessEventChip status={params.value} />
			)
		},
	], []);

	const { data, isLoading, isValidating, error } = useSWR(
		fetchParameters ? [service.basePath, fetchParameters] : null,
		([_, args]) => service.getAccessLogs(args),
		{
			onSuccess: (res) => res?.total != null && setTotalCount(res.total),
			keepPreviousData: true,
		},
	);

	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
		if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
			service.abortCurrentRequest('pagination change');
		}

		setFetchParameters(prev => ({
			...prev,
			page: value.page + 1,
			pageSize: value.pageSize
		}));
	};

	return (
		<DataGrid
			snapshotKey='accl'
			columns={columns}
			error={error}
			loading={isLoading || isValidating}
			rows={data?.results ?? []}
			rowCount={totalCount}
			onPaginationModelChange={handlePaginationChange}
			slotProps={{
				noRowsOverlay: {
					title: t('noResourceFound', { resource: t('logs') }),
				},
				noResultsOverlay: {
					title: t('noResourceFound', { resource: t('logs') }),
				},
			}}
			sx={{ flexGrow: 1, flexShrink: 1 }}
		/>
	);
};

export default AccessLogsDataGrid;
