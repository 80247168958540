import { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog, Dialog, FormDialog } from '~components';

import selectedOrganisationAtom from '../atoms/selectedOrganisationAtom';
import AccessSchedulesDataGrid from '../components/dataGrids/accessSchedulesDataGrid';
import AccessSchedulesForm from '../forms/accessSchedulesForm/accessSchedulesForm';
import SkcSchedule from '../interfaces/skcSchedule';
import SkcSchedulesService from '../services/skcSchedulesService';

type UserAction =
	| {
			type: 'delete' | 'edit';
			label?: string;
			id: string;
	  }
	| {
			type: 'add';
			label?: never;
			id?: never;
	  };

const service = new SkcSchedulesService();

const SchedulesOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();
	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [userAction, setUserAction] = useState<UserAction | null>(null);
	const dataGridRef = useRef();
	const selectedOrganisation = useAtomValue(selectedOrganisationAtom);
	// const { trigger, isMutating, error } = useSWRMutation(
	// 	service.basePath,
	// 	(_, data: SkcSchedule) => service.createSchedule(data)
	// );

	useEffect(() => {
		// Should be temporary
		if (selectedOrganisation) {
			service.organisation = selectedOrganisation;
		}
	}, [selectedOrganisation]);

	const handleEdit = async (id: string, data: SkcSchedule) => {
		setUserActionProcessing(true);

		try {
			const response = await service.updateSchedule(id, data);

			enqueueSnackbar(t('ui.success.message.updated'), { variant: 'success' });
			setUserAction(null);
			dataGridRef.current.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleCreate = async (data: SkcSchedule) => {
		setUserActionProcessing(true);

		try {
			await service.createSchedule(data);

			enqueueSnackbar(`${t('ui.successfullyAdded')} ${t('schedule').toLowerCase()}`, { variant: 'success' });
			setUserAction(null);
			dataGridRef.current.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleDelete = async (id: string) => {
		setUserActionProcessing(true);

		try {
			await service.deleteSchedule(id);
			enqueueSnackbar(t('deleted'), { variant: 'success' });
			setUserAction(null);
			dataGridRef.current.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
		<Box height={1}>
			<AccessSchedulesDataGrid
				ref={dataGridRef}
				onEdit={(value) => setUserAction({ type: 'edit', ...value })}
				onDelete={(value) => setUserAction({ type: 'delete', ...value })}
				onCreate={() => setUserAction({ type: 'add' })}
			/>
			<FormDialog
				open={userAction?.type === 'edit' || userAction?.type === 'add'}
				title={t(userAction?.type === 'add' ? 'addResource' : 'editResource', { resource: t('schedule')})}
				loading={userActionProcessing}
				onSubmit={(data) => {
					if (userAction?.type === 'edit') {
						handleEdit(userAction.id, data);
					} else {
						handleCreate(data);
					}
				}}
				onClose={() => setUserAction(null)}
				maxWidth='xl'
			>
				<AccessSchedulesForm
					id={userAction?.id}
				/>
			</FormDialog>
			<ConfirmationDialog
				loading={userActionProcessing}
				open={userAction?.type === 'delete'}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('schedule'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={() => userAction?.id != null && handleDelete(userAction.id)}
				onDecline={() => setUserAction(null)}
				onClose={() => setUserAction(null)}
			/>
		</Box>
	);
};

export default SchedulesOverviewLayout;
