import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	iconCell: {
		width: 73,
	},
	link: {
		fontWeight: theme.link.body2.fontWeight,
		fontSize: theme.link.body2.fontSize,
		letterSpacing: theme.link.body2.letterSpacing,
		lineHeight: theme.link.body2.lineHeight,
	},
	tipCard: {
		backgroundColor: colors.indigo[50],
		boxShadow: 'none',
		padding: '0.333rem',
	},
}));
