import { useEffect } from 'react';

import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { useStyles } from './style';
import {
	FormField,
	SearchAutocomplete,
	SelectedOrganisationCard,
	Tip,
} from '../../../../../../components';
import {
	useWizardFormField,
	useDebouncedWizardSave,
	useDebouncedBackendFieldCheck,
} from '../../../../../../shared/hooks';
import { isEmptyObject, isFullString, isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

const AboutPolicy = (props) => {
	const {
		className,
		save,

		organisationsList,
		onFetchOrganisations,
		name: savedName,
		selectedOrganisation: savedOrganisation,
		currentUser,
		onValidatePolicyName,
		validatedPolicyName,
		editing,

		currentPolicyName,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const classes = useStyles();

	const role = auth.user?.profile.role.toLowerCase();
	const { data: currentUserData } = currentUser;

	const selectedOrganisation = useWizardFormField(savedOrganisation, { required: true });

	useEffect(() => {
		if (isFullString(role) && role === 'superadmin') {
			save({
				selectedOrganisation: {
					value: selectedOrganisation.value,
					valid: selectedOrganisation.isValid,
				},
			});
		}
	}, [selectedOrganisation.value, selectedOrganisation.isValid]);

	useEffect(() => {
		if (isFullString(role) && role === 'admin' && isObject(currentUserData)) {
			save({
				selectedOrganisation: {
					valid: true,
					value: currentUserData.organisationReference,
				},
			});
		}
	}, [currentUserData]);

	const resetSelectedOrganisation = () => {
		selectedOrganisation.onChange({});
	};

	const name = useWizardFormField(savedName, { required: true, minLength: 3, maxLength: 25 });
	const checkedName = useDebouncedBackendFieldCheck(
		isFullString(role) && role === 'superadmin' ?
			!isEmptyObject(selectedOrganisation.value) ?
				name.value
			:	''
		:	name.value,
		onValidatePolicyName,
		validatedPolicyName,
		isFullString(role) && role === 'superadmin' ?
			{ organisationId: selectedOrganisation.value.id }
		:	{},
		true,
	);
	const shouldValidateName =
		!editing || (editing && name.value.toLowerCase() !== currentPolicyName.toLowerCase());

	useDebouncedWizardSave(
		'name',
		name.value,
		name.isValid && (shouldValidateName ? checkedName.isUnique : true),
		save,
		300,
		name.isRequired,
	);

	return (
		<Card className={className}>
			<CardHeader
				title={t('views.addPolicy.steps.details')}
				titleTypographyProps={{
					variant: 'h4',
				}}
			/>
			<CardContent>
				{(
					(isFullString(role) && role === 'superadmin' && editing) ||
					(role === 'superadmin' && !editing)
				) ?
					<div className={classes.formGroup}>
						<Typography gutterBottom={true} variant='h5'>
							{t('views.addPolicy.aboutPolicy.subheader.selectOrganisation')}
							<sup>*</sup>
						</Typography>
						{!isEmptyObject(selectedOrganisation.value) ?
							<SelectedOrganisationCard
								handleClose={resetSelectedOrganisation}
								hasCloseButton={editing}
								name={selectedOrganisation.value.name}
							/>
						:	<SearchAutocomplete
								dataList={organisationsList}
								listType={'organisations'}
								onFetchData={onFetchOrganisations}
								placeholder={t('views.addPolicy.aboutPolicy.placeholder.searchOrganisation')}
								setSelected={selectedOrganisation.onChange}
							/>
						}
					</div>
				:	null}
				<div className={classes.formGroup}>
					<FormField
						extraValidHelperText={
							(
								isFullString(role) &&
								role === 'superadmin' &&
								isEmptyObject(selectedOrganisation.value)
							) ?
								t('views.addPolicy.error.nameAlreadyInSystem')
							:	t('views.addPolicy.error.nameAlreadyInUse')
						}
						isExtraValid={shouldValidateName ? checkedName.isUnique : true}
						label={t('ui.label.name')}
						maxLength={25}
						name='name'
						variable={name}
					/>
				</div>
				<Tip
					arrowDirection={'top'}
					message={t('views.policyManagement.actionFlows.aboutPolicy.tipDescription')}
				/>
			</CardContent>
		</Card>
	);
};

AboutPolicy.propTypes = {
	className: PropTypes.string,
	save: PropTypes.func.isRequired,
	name: PropTypes.string,
	selectedOrganisation: PropTypes.object,

	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchOrganisations: PropTypes.func,
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onValidatePolicyName: PropTypes.func,
	validatedPolicyName: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	editing: PropTypes.bool,

	currentPolicyName: PropTypes.string,
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,
		validatedPolicyName: state.details.validatedPolicyName,

		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onValidatePolicyName: ({ value, organisationId }) =>
			dispatch(actions.validatePolicyName(value, organisationId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutPolicy);
