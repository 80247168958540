import { Fragment, Suspense } from 'react';

import { LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { useStyles } from './style';
import Topbar from './Topbar';

/**
 * See authentication layout
 * @param props
 * @returns
 * @deprecated
 */
const Auth = (props) => {
	const classes = useStyles();

	return (
		<Fragment>
			<Topbar />
			<main className={classes.content}>
				<Outlet />
			</main>
		</Fragment>
	);
};

Auth.propTypes = {
	route: PropTypes.object,
};

export default Auth;
