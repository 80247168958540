import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import LocationCard from './LocationCard';
import OpeningHoursCard from '../../../../../components/elements/OpeningHoursCard';

const PREFIX = 'LocationDetails';

const classes = {
	root: `${PREFIX}-root`,
	openingCard: `${PREFIX}-openingCard`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
	[`& .${classes.root}`]: {},
	[`& .${classes.openingCard}`]: {
		marginTop: theme.spacing(5),
	},
}));

const LocationDetails = (props) => {
	const {
		save,
		name,
		description,
		images,
		openingTime,
		selectedOrganisation,
		openingHours,
		searchValue,
	} = props;

	const locationCardProps = {
		name,
		description,
		images,
		searchValue,
		selectedOrganisation,
	};
	const openingHoursCardProps = {
		openingTime,
		openingHours,
	};

	return (
		<Root>
			<LocationCard className={classes} save={save} {...locationCardProps} />
			<OpeningHoursCard className={classes.openingCard} save={save} {...openingHoursCardProps} />
		</Root>
	);
};

LocationDetails.propTypes = {
	save: PropTypes.func.isRequired,
	className: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	images: PropTypes.array,
	openingTime: PropTypes.string,
	hubData: PropTypes.object,
	selectedOrganisation: PropTypes.object,
	openingHours: PropTypes.array,
	searchValue: PropTypes.string,
};

export default LocationDetails;
