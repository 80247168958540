import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	cta: {
		'& + &': {
			marginLeft: theme.spacing(1),
		},
	},
	headerSpacing: {
		minHeight: '60px',
	},
}));
