import { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
	Card,
	CardHeader,
	CardContent,
	Checkbox,
	Typography,
	Link,
	IconButton,
	Box,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { Modal } from '../../../../../components';

const TermsAndConditions = (props) => {
	const { className, save, isTermsChecked } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [isCheckedTermsAndConditions, setIsCheckedTermsAndConditions] = useState(
		isTermsChecked || false,
	);

	// save whether someone accepted the terms or not
	useEffect(() => {
		save({ isCheckedTermsAndConditions });
	}, [isCheckedTermsAndConditions]);

	const [isOpenTermsModal, setIsOpenTermsModal] = useState(false);

	const handleToggleTermsAndConditions = () => {
		setIsCheckedTermsAndConditions(!isCheckedTermsAndConditions);
	};

	const handleCloseTermsModal = () => {
		setIsOpenTermsModal(false);
	};

	const handleClickTerms = () => {
		setIsOpenTermsModal(true);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader
				title={t('views.register.organisation.termsAndConditions.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					<div className={classes.formGroup}>
						<Box alignItems='center' display='flex' justifyContent='space-between'>
							<Typography variant='h5'>
								{t('views.register.organisation.termsAndConditions.checkbox.title')}{' '}
								<Link className={classes.link} color='inherit' onClick={handleClickTerms}>
									{t('ui.terms')}
								</Link>
							</Typography>
							<Checkbox
								checked={isCheckedTermsAndConditions}
								onChange={handleToggleTermsAndConditions}
							/>
						</Box>
					</div>
				</form>
				<Modal onClose={handleCloseTermsModal} open={isOpenTermsModal}>
					<div className={classes.termsContainer}>
						<div className={classes.termsTitleContainer}>
							<Typography variant='h4'>
								{t('views.register.organisation.termsAndConditions.checkbox.modal.title')}
							</Typography>
							<IconButton
								className={classes.closeButton}
								onClick={handleCloseTermsModal}
								size='small'
							>
								<CloseIcon />
							</IconButton>
						</div>
						<Typography>
							{`${t('views.register.organisation.termsAndConditions.checkbox.modal.description')}`}{' '}
							{
								<a className={classes.link} href='https://topology.nl/terms-of-services'>
									{t('views.register.organisation.termsAndConditions.checkbox.modal.terms')}
								</a>
							}
						</Typography>
					</div>
				</Modal>
			</CardContent>
		</Card>
	);
};

TermsAndConditions.propTypes = {
	className: PropTypes.string,

	save: PropTypes.func,
	isTermsChecked: PropTypes.bool,
};

export default TermsAndConditions;
