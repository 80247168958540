import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	detailTitle: {
		textDecoration: 'underline',
		...theme.typography.body3,
	},
	tableLink: {
		...theme.typography.link1,
	},
	tableText: {
		...theme.typography.body3,
	},
	instanceContent: {
		display: 'flex',
	},
	notAvailable: {
		color: theme.palette.error.main,
	},
}));

const useStylesMyTooltip = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.white,
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
	tooltip: {
		backgroundColor: '#ffffff',
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
}));

export function MyTooltip(props) {
	const classes = useStylesMyTooltip();
	return <Tooltip classes={classes} {...props} />;
}
