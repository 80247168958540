import { colors } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles, withStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(3, 0, 0, 0),
	},
	createNewGroup: {
		display: 'flex',
		color: colors.blue['A700'],
		alignItems: 'center',
		paddingRight: theme.spacing(1),
	},
	createNewUser: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
	},
}));

export const Accordion = withStyles({
	root: {
		borderColor: colors.blue['A700'],
		border: '1px solid ',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(255, 255, 255, .03)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);
