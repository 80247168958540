import { useEffect } from 'react';

import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { SearchAutocomplete, SelectedOrganisationCard, FormField } from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';
import { isEmptyObject, isFullString, isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const AddTermsAndConditionsTitle = (props) => {
	const {
		save,

		organisationsList,
		onFetchOrganisations,
		currentUser,

		termsAndConditionsTitle,
		editing,
		fetchedTerm,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const classes = useStyles();

	const { data: currentUserData } = currentUser;

	const { data: fetchedTermData } = fetchedTerm;

	const selectedOrganisation = useWizardFormField({}, { required: true });

	const admin =
		isFullString(auth.user?.profile.role) && auth.user?.profile.role.toLowerCase() === 'admin';

	useEffect(() => {
		if (admin && isObject(currentUserData)) {
			save({ selectedOrganisation: { value: currentUserData.organisationReference, valid: true } });
		} else if (editing && isObject(fetchedTermData) && isEmptyObject(selectedOrganisation.value)) {
			selectedOrganisation.setValue({ name: fetchedTermData.organisationReference.name });
			save({ selectedOrganisation: { value: fetchedTermData.organisationReference, valid: true } });
		} else {
			save({
				selectedOrganisation: {
					value: selectedOrganisation.value,
					valid: !isEmptyObject(selectedOrganisation.value),
				},
			});
		}
	}, [
		selectedOrganisation.value,
		selectedOrganisation.isValid,
		admin,
		currentUserData,
		fetchedTerm,
	]);

	const resetSelectedOrganisation = () => {
		selectedOrganisation.onChange({});
	};

	const documentName = useWizardFormField(termsAndConditionsTitle, {
		maxLength: 128,
		minLength: 1,
	});
	useDebouncedWizardSave('title', documentName.value, documentName.isValid, save, 300);

	useEffect(() => {
		save({ title: documentName.value });
	}, [documentName.value]);

	return (
		<Card className={clsx(classes.root, classes.termsAndConditions)}>
			<CardHeader
				title={
					editing ?
						t('views.addTerms.addTermsAndConditionsTitle.editing')
					:	t('ui.category.termsAndConditions')
				}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					{admin ? null : (
						<>
							<div className={classes.formGroup}>
								<Typography gutterBottom={true} variant='h5'>
									{t('views.addTermsAndConditions.subheader.selectOrganisation')}
									<sup>*</sup>
								</Typography>
								{!isEmptyObject(selectedOrganisation.value) ?
									<SelectedOrganisationCard
										handleClose={resetSelectedOrganisation}
										hasCloseButton={editing}
										name={selectedOrganisation.value.name}
									/>
								:	<SearchAutocomplete
										dataList={organisationsList}
										listType={'organisations'}
										onFetchData={onFetchOrganisations}
										placeholder={t('views.addTermsAndConditions.placeholder.searchAutocomplete')}
										setSelected={selectedOrganisation.onChange}
									/>
								}
							</div>
						</>
					)}
					<div className={classes.formGroup}>
						<FormField label={t('ui.title')} name={'title'} variable={documentName} />
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

AddTermsAndConditionsTitle.propTypes = {
	className: PropTypes.string,
	save: PropTypes.func,

	editing: PropTypes.bool,
	termsAndConditionsTitle: PropTypes.string,
	onFetchOrganisations: PropTypes.func,
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchedTerm: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,

		currentUser: state.details.currentUser,

		fetchedTerm: state.details.fetchTerm,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTermsAndConditionsTitle);
