import { useEffect, useState } from 'react';

import { Card, CardHeader, CardContent, Typography, Box } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import { useStyles } from './style';
import { FormField, Tip, ImageDropzone, FormFieldLabel } from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';
import { isEmptyString, isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const OrganisationInfo = (props) => {
	const {
		className,
		save,
		onValidateOrganisationNameField,
		name,
		legalName,
		checkedNameField,
		description,
		images,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	// eslint-disable-next-line no-unused-vars
	const [fileName, setFileName] = useState(null);
	const organisationName = useWizardFormField(name || '', { required: true, maxLength: 128 });
	const [isUniqueName, setIsUniqueName] = useState(true);
	useDebouncedWizardSave(
		'organisationName',
		organisationName.value,
		organisationName.isValid && isUniqueName,
		save,
		300,
	);

	const debouncedNameValue = useDebounce(organisationName.value, 300);

	const organisationLegalName = useWizardFormField(legalName || '', { maxLength: 128 });
	useDebouncedWizardSave(
		'legalName',
		organisationLegalName.value,
		organisationLegalName.isValid,
		save,
		300,
	);

	useEffect(() => {
		if (!isEmptyString(debouncedNameValue)) {
			onValidateOrganisationNameField(debouncedNameValue);
		}
	}, [debouncedNameValue]);

	const {
		success: checkedNameSuccess,
		loading: checkedNameLoading,
		error: checkedNameError,
	} = checkedNameField;

	useEffect(() => {
		if (!checkedNameLoading && !isObject(checkedNameError)) {
			setIsUniqueName(true);
		} else if (!checkedNameLoading) {
			if (checkedNameError.key === 'DuplicateOrganisation') {
				setIsUniqueName(false);
			}
		}
	}, [checkedNameSuccess, checkedNameLoading, checkedNameError]);

	const organisationInfoChecksReady =
		checkedNameSuccess && !checkedNameLoading && !checkedNameError;

	useEffect(() => {
		save({ organisationInfoChecksReady });
	}, [organisationInfoChecksReady]);

	const organisationDescription = useWizardFormField(description || '', { maxLength: 500 });
	useDebouncedWizardSave(
		'organisationDescription',
		organisationDescription.value,
		organisationDescription.isValid,
		save,
		300,
	);

	const handleUploadLogo = (image) => {
		save({ logoImage: image });
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader
				subheader={t('views.register.organisation.organisationInfo.subheader')}
				title={t('views.register.organisation.organisationInfo.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					<div className={classes.formGroup}>
						<FormField
							extraValidHelperText={t(
								'views.register.organisation.organisationInfo.error.nameAlreadyInUse',
							)}
							hideCharacterCounter={!organisationName.hasFocus}
							isExtraValid={isUniqueName}
							label={t('ui.label.name')}
							maxLength={25}
							name='organisationName'
							placeholder={t('views.register.organisation.placeholder.name')}
							required
							variable={organisationName}
						/>
					</div>
					<div className={classes.formGroup}>
						<FormField
							extraValidHelperText={t(
								'views.register.organisation.organisationInfo.error.nameAlreadyInUse',
							)}
							hideCharacterCounter={!organisationLegalName.hasFocus}
							isExtraValid={isUniqueName}
							label={t('ui.label.legalName')}
							maxLength={128}
							name='legalName'
							placeholder={t('views.register.organisation.placeholder.name')}
							variable={organisationLegalName}
						/>
					</div>
					<div className={classes.formGroup}>
						<Typography>
							{t('views.register.organisation.organisationInfo.explanation.description')}
						</Typography>
					</div>
					<div className={classes.formGroup}>
						<Box display='flex' justifyContent='space-between' pb={1}>
							<Typography variant='h5'>{t('ui.label.description')}</Typography>
							<Typography className={classes.counterText}>
								{organisationDescription.value.length}/500 {t('ui.characters')}
							</Typography>
						</Box>
						<FormField
							multiline
							name='organisationDescription'
							placeholder={t(
								'views.register.organisation.organisationInfo.placeholder.description',
							)}
							rows={5}
							variable={organisationDescription}
						/>
					</div>
					<div className={classes.formGroup}>
						<Tip
							arrowDirection={'top'}
							message={t('views.register.organisation.organisationInfo.tip.description')}
						/>
					</div>
					<div className={classes.formGroup}>
						<FormFieldLabel
							className={classes.imageLabel}
							description={t('views.register.organisation.organisationInfo.logo.description')}
							label={t('views.register.organisation.organisationInfo.logo.label')}
							required
						>
							<ImageDropzone
								callback={handleUploadLogo}
								dropZoneSize='small'
								imageRatio='1:1'
								images={images}
								maxImageSize={1024}
								maxImages={1}
								setFileName={setFileName}
							/>
						</FormFieldLabel>
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

OrganisationInfo.propTypes = {
	className: PropTypes.string,
	save: PropTypes.func,
	onValidateOrganisationNameField: PropTypes.func,
	name: PropTypes.string,
	checkedNameField: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	description: PropTypes.string,
	images: PropTypes.array,

	legalName: PropTypes.string,
};

const mapStateToProps = (state) => {
	return {
		checkedNameField: state.condition.checkedOrganisationNameField,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onValidateOrganisationNameField: (value) =>
			dispatch(actions.validateOrganisationNameField(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationInfo);
