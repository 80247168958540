import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const InfoMessage = (props) => {
	const { message, className } = props;

	const classes = useStyles();

	return (
		<Paper className={clsx(classes.messageCard, className)}>
			<InfoIcon className={classes.infoIcon} fontSize='small' />
			<Typography color='primary'>{message}</Typography>
		</Paper>
	);
};

InfoMessage.propTypes = {
	message: PropTypes.string,
	className: PropTypes.string,
};

export default InfoMessage;
