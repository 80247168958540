import { Tabs, Tab, Divider, colors } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import IncomingRequests from './IncomingRequests';
import OrganisationsListViews from './Organisations';
import { Page } from '../../components';

const PREFIX = 'OrganisationManagement';

const classes = {
	root: `${PREFIX}-root`,
	tabs: `${PREFIX}-tabs`,
	divider: `${PREFIX}-divider`,
	content: `${PREFIX}-content`,
};

const StyledPage = styled(Page)(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(3),
	},

	[`& .${classes.tabs}`]: {
		marginTop: theme.spacing(3),
	},

	[`& .${classes.divider}`]: {
		backgroundColor: colors.grey[300],
	},

	[`& .${classes.content}`]: {
		marginTop: theme.spacing(3),
	},
}));

const OrganisationManagement = (props) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { tab } = useParams();

	const handleTabsChange = (e, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'organisations', label: t('ui.category.organisations') },
		{ value: 'incoming-requests', label: t('views.organisationManagement.incomingRequests') },
	];

	if (!tab) {
		return <Navigate to={'/organisation-management/organisations'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<StyledPage className={classes.root} title={t('ui.category.organisations')}>
			<Header />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'organisations' && <OrganisationsListViews />}
				{tab === 'incoming-requests' && <IncomingRequests />}
			</div>
		</StyledPage>
	);
};

OrganisationManagement.propTypes = {
	className: PropTypes.string,
};

export default OrganisationManagement;
