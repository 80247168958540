import { createContext, useCallback, useContext, useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogProps, DialogTitle, Dialog as MuiDialog, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FormContainerContext, { FormContainerProps } from './formContainerProvider';

interface FormDialogProps extends DialogProps {
	loading?: boolean;
}

const FormDialog = ({
	children,
	title,
	onSubmit,
	loading = false,
	...dialogProps
}: FormDialogProps) => {
	const { t } = useTranslation('general');
	const theme = useTheme();
	const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));

	const handlers = useRef<{ onSubmit: () => void }>({});
	const [disabled, setDisabled] = useState(true);

	const handleClose = () => {
		dialogProps.onClose && dialogProps.onClose();
	};

	const handleSubmit = () => {
		const value = handlers.current?.onSubmit();
		console.log(value)
		onSubmit && onSubmit(value);
	};

	return (
		<MuiDialog fullScreen={!largerThanMd} {...dialogProps}>
			<DialogTitle variant='h4'>
				{title}
			</DialogTitle>
			<DialogContent>
				<FormContainerContext.Provider value={{
					handlers: handlers.current,
					disabled: disabled,
					setDisabled: setDisabled
				}}>
					{children}
				</FormContainerContext.Provider>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>
					{t('ui.close')}
				</Button>
				<LoadingButton
					variant='contained'
					onClick={handleSubmit}
					disabled={disabled}
					loading={loading}
				>
					{t('ui.save')}
				</LoadingButton>
			</DialogActions>
		</MuiDialog>
	);
};

export default FormDialog;
