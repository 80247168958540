import path from 'path';

import { PagedResponse } from '~interfaces';
import { AddressResponse, BaseReferenceResponse, IdReferenceResponse } from '~interfaces/responses';
import { BaseServiceMapper, TopologyService } from '~services';

import UserLicenseValidationStatusEnum from '../enums/userLicenseValidationStatusEnum';
import UserLicenseVerificationStatusEnum from '../enums/userLicenseVerificationStatusEnum';
import UserLicense from '../interfaces/userLicense';

/**
 * A service to get "business logic" users. E.g. user data from
 * the Core service
 */
class UserLicensesService extends TopologyService {
	public readonly path = 'userlicenses';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of users
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getUserLicenses(
		page = 1,
		pageSize = 10,
		organisationId?: string,
		userGroupId?: string,
		searchQuery?: string,
		sortBy = 'id',
		descending = true,
	): Promise<PagedResponse<UserLicense>> {
		const { data } = await this._client.get<PagedResponse<UserLicenseResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: organisationId,
				userGroupId: userGroupId,
				sortBy: sortBy,
				orderDescending: descending,
				...(searchQuery && { searchTerm: searchQuery }),
			},
		});

		return {
			...data,
			results: data.results.map((el) => UserLicenseServiceMapper.fromResponse(el)),
		};
	}

	/**
	 * Get details about a specific user
	 * @param id
	 * @returns
	 */
	async getUserLicenseById(id: string): Promise<UserLicense> {
		const { data } = await this._client.get<UserLicenseResponse>(path.join(this.path, id));

		return UserLicenseServiceMapper.fromResponse(data);
	}
}

class UserLicenseServiceMapper {
	static fromResponse(data: UserLicenseResponse): UserLicense {
		const {
			id,
			userReference,
			dateOfBirth,
			expiryDate,
			validationDate,
			createdDate,
			submitDate,
			lastModifiedDate,
			verificationStatus,
			validationStatus,
			...rest
		} = data;

		return {
			id: id.toString(),
			user: BaseServiceMapper.fromBaseReferenceResponse(userReference),
			dateOfBirth: new Date(dateOfBirth),
			submitDate: new Date(submitDate),
			expiryDate: new Date(expiryDate),
			validationDate: new Date(validationDate),
			modifiedDate: new Date(lastModifiedDate),
			createdDate: new Date(createdDate),
			verificationStatus: verificationStatus as UserLicenseVerificationStatusEnum,
			validationStatus: validationStatus as UserLicenseValidationStatusEnum,
			...rest,
		};
	}
}

interface UserLicenseResponse extends IdReferenceResponse {
	licenseNumber: string;
	isDuplicate: boolean;
	dateOfBirth: string;
	expiryDate: string;
	countryCode: string;
	status: string;
	verificationStatus: string;
	validationStatus: string;
	validationDate: string;
	userReference: BaseReferenceResponse;
	images: [{ front: string[] }, { back: string[] }, { selfie: string[] }];
	createdDate: string;
	submitDate: string;
	lastModifiedDate: string;
	isOnHold: boolean;
}

export default UserLicensesService;
