import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { StyledButton } from '../../../components';
import ModalPopup from '../../elements/ModalPopup/ModalPopup';

import 'cropperjs/dist/cropper.css';

const ImageCroper = (props) => {
	const {
		open,
		handleClose,
		aspectRatio,

		isLoading,
		disabled,
		handleCropeData,
		loading,
		cropper,
		setCropper,
		src,
		header,
		description,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	return (
		<div>
			<ModalPopup onClose={handleClose} open={open}>
				<>
					<Box pb={2} pl={3} pr={3} pt={2}>
						<Box display='flex' flexDirection='column' pb={2} pt={1}>
							<Typography variant='h4'>{header}</Typography>
							<Typography variant='body2'>{description}</Typography>
						</Box>
						<Cropper
							autoCropArea={1}
							background={false}
							center={true}
							checkOrientation={false}
							guides={true}
							initialAspectRatio={aspectRatio}
							minCropBoxHeight={10}
							minCropBoxWidth={10}
							onInitialized={(instance) => {
								setCropper(instance);
							}}
							preview={classes.imgPreview}
							responsive={true}
							src={src}
							style={{ height: 350, width: '100%' }}
							viewMode={1}
							zoomTo={0}
						/>
					</Box>
					<Box className={classes.actions}>
						<StyledButton
							className={classes.closeButton}
							fullWidth
							onClick={handleClose}
							variant='contained-tertiary'
						>
							{t('ui.button.contained.cancel')}
						</StyledButton>
						<StyledButton
							autoFocus
							className={classes.button}
							disabled={disabled}
							fullWidth
							onClick={() => handleCropeData(cropper)}
							variant='contained-primary'
						>
							{isLoading ?
								loading ?
									<CircularProgress className={classes.confirmLoading} disableShrink size={24} />
								:	t('ui.confirm')
							:	t('ui.button.contained.confirm')}
						</StyledButton>
					</Box>
				</>
			</ModalPopup>
		</div>
	);
};

ImageCroper.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	aspectRatio: PropTypes.number,

	handleCropeData: PropTypes.func,
	setCropper: PropTypes.func,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	src: PropTypes.string,
	loading: PropTypes.bool,
	cropper: PropTypes.object,
	header: PropTypes.string,
	description: PropTypes.string,
};

export default ImageCroper;
