import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
	Box,
	Typography,
	Card,
	CardContent,
	IconButton,
	SearchAutocomplete,
} from '../../../../components';
import { isObject, handleHubReference } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../style';

const LocationCard = (props) => {
	const {
		open,

		clearSelection,
		cardDetails,
		selectCardDetails,
		onFetchUnrelatedHubs,
		fetchUnrelatedHubs,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	const [filters] = useState({ name: 'filters', value: 'none' });

	const handleSelectOption = () => {
		if (open) {
			return (
				<SearchAutocomplete
					dataList={fetchUnrelatedHubs}
					filter={filters}
					listType={'hubs'}
					onFetchData={onFetchUnrelatedHubs}
					placeholder={t('ui.placeholders.search.generic')}
					popperSize='large'
					setSelected={selectCardDetails}
				/>
			);
		}
	};

	return (
		<Box pt={3}>
			<Typography variant='h6'>{t('ui.label.location')}</Typography>
			<Box mt={1.5}>
				{isObject(cardDetails) ?
					<Card className={classes.card}>
						<CardContent>
							<Typography variant='h5'>{cardDetails.name}</Typography>
							<Typography variant='body2'>{handleHubReference(cardDetails)}</Typography>
							<Typography variant='body2'>{cardDetails?.address?.country}</Typography>
						</CardContent>
						<IconButton className={classes.closeButton} onClick={clearSelection}>
							<CloseIcon />
						</IconButton>
					</Card>
				:	handleSelectOption()}
			</Box>
		</Box>
	);
};

LocationCard.propTypes = {
	name: PropTypes.string,
	address: PropTypes.string,
	region: PropTypes.string,

	clearSelection: PropTypes.func,
	onFetchUnrelatedHubs: PropTypes.func,
	open: PropTypes.bool,
	cardDetails: PropTypes.object,
	selectCardDetails: PropTypes.func,
	fetchUnrelatedHubs: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		fetchUnrelatedHubs: state.paged.fetchUnrelatedHubs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUnrelatedHubs: (page, filters, concat) =>
			dispatch(actions.fetchUnrelatedHubs(page, filters, concat)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationCard);
