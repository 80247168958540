import { useEffect, useState, useCallback } from 'react';

import { Card, CardContent, Box, MenuItem, Typography, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import {
	Tip,
	ImageDropzone,
	FormField,
	FormFieldLabel,
	ImageCroper,
} from '../../../../../components';
import { descriptionMaxLength } from '../../../../../constantsOld';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';
import {
	isArray,
	isObject,
	isEmptyString,
	isUndefined,
	isFullArray,
	getExtension,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import ItemHeader from '../ItemHeader';
import { useStyles } from '../stepsStyles';

const ItemDetails = (props) => {
	const {
		save,
		selectedCategory: savedCategory,
		itemImages,
		onFetchCategories,
		categories,
		selectedOrganisation: selectedOrg,
		itemStep,
		selectedName,
		selectedDescription,
		onValidateItemName,
		validateItemName,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [selectedCategory, setSelectedCategory] = useState('');

	const name = useWizardFormField(selectedName || '', { required: true, maxLength: 25 });
	useDebouncedWizardSave('name', name.value, name.isValid, save, 400);
	const debouncedNameValue = useDebounce(name.value, 300);
	const [isUniqueItemName, setIsUniqueItemName] = useState(true);

	const documentContent = useWizardFormField(selectedDescription, {
		maxLength: descriptionMaxLength,
		minLength: 3,
	});
	useDebouncedWizardSave('name', name.value, name.isValid, save, 400);

	const [openCroper, setOpenCroper] = useState(false);
	const [cropper, setCropper] = useState(null);
	const [cropData, setCropData] = useState([]);
	const [fileName, setFileName] = useState(null);
	const [isOpenCrop, setIsOpenCrop] = useState(true);

	const { data: categoriesData, loading: categoriesLoading, error: categoriesError } = categories;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	const {
		data: validateItemNameData,
		loading: validateItemNameLoading,
		error: validateItemNameError,
	} = validateItemName;

	useEffect(() => {
		if (!categoriesLoading && !isFullArray(categoriesData)) {
			onFetchCategories();
		}
	}, [categories]);

	useEffect(() => {
		if (!isFullArray(itemImages)) {
			setIsOpenCrop(true);
		}
	}, [itemImages]);

	useEffect(() => {
		if (fileName && isOpenCrop) {
			setOpenCroper(true);
			setIsOpenCrop(false);
		}
	}, [itemImages, cropData]);

	useEffect(() => {
		if (!isEmptyString(name.value) && isObject(selectedOrg)) {
			onValidateItemName(debouncedNameValue, selectedOrg.id);
		}
	}, [debouncedNameValue, selectedOrg]);

	useEffect(() => {
		if (isObject(validateItemNameData)) {
			setIsUniqueItemName(validateItemNameData.allowed);
		}
	}, [validateItemNameData, validateItemNameLoading, validateItemNameError]);

	useEffect(() => {
		if (isObject(validateItemNameData)) {
			save({ validItemName: validateItemNameData.allowed });
		}
	}, [validateItemNameData]);

	useEffect(() => {
		if (!isUndefined(savedCategory)) {
			setSelectedCategory(savedCategory);
		}
	}, [savedCategory]);

	const handleCategories = (event) => {
		event.preventDefault();
		const value = event.target.value;
		setSelectedCategory(value);
		save({ updatedCategory: value });
		const categoryIdsArray = categoriesData.map((category) => category.id);
		const index = categoryIdsArray.indexOf(value);
		save({ updatedCategoryObject: categoriesData[index] });
	};

	const handleUploadProfileImage = (image) => {
		if (itemStep === 1) {
			save({ updatedItemImages: image });
		}
	};

	useEffect(() => {
		save({
			content: {
				value: documentContent.value,
				valid: documentContent.isValid,
			},
		});
	}, [documentContent.value, documentContent.isValid]);

	const handleCloseCroper = () => {
		setOpenCroper(false);
	};

	const handleCropeData = useCallback(
		(crop) => {
			const type = getExtension(fileName).toLowerCase();
			const value = [
				{
					uri: crop.getCroppedCanvas().toDataURL(`image/${type === 'jpg' ? 'jpeg' : type}`),
					name: fileName,
				},
			];
			setCropData(value);
			save({ updatedItemImages: value });
			setOpenCroper(false);
		},
		[cropper, openCroper],
	);

	return (
		<Card>
			<CardContent className={classes.mainCard}>
				<ItemHeader
					header={t('views.addItem.steps.itemDetails')}
					padding={3}
					subheader={t('views.addItem.itemDetails.subheader.text')}
					variant='h3'
					variantText='body2'
				/>
				<form>
					<div className={classes.itemSpacing}>
						<FormFieldLabel
							className={classes.inputLabelSpacing}
							info={t('views.addItem.bookingType.categoryInfo')}
							label={t('views.addItem.chooseItemCategory')}
							variant='h4'
						/>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							SelectProps={{ value: selectedCategory || '' }}
							fullWidth={true}
							label={t('ui.label.category')}
							onChange={handleCategories}
							placeholder='category'
							required
							select
							size='medium'
							variant='outlined'
						>
							{categoriesReady ?
								categoriesData.map((tStatus) => (
									<MenuItem key={tStatus.id} value={tStatus.id}>
										{tStatus.name}
									</MenuItem>
								))
							:	[]}
						</TextField>
					</div>
					<div className={classes.itemSpacing}>
						<FormFieldLabel
							className={classes.inputLabelSpacing}
							info={t('views.addItem.bookingType.nameInfo')}
							label={t('views.addItem.itemGroupName')}
							variant='h4'
						/>
						<FormField
							extraValidHelperText={t('views.addItem.itemDetails.itemName')}
							hideCharacterCounter={!name.hasFocus}
							isExtraValid={isUniqueItemName}
							label={t('ui.label.name')}
							maxLength={25}
							name='name'
							required
							variable={name}
						/>
						<Tip
							arrowDirection='top'
							className={classes.tip}
							message={t('views.addItem.itemDetails.subsection.name.tip')}
						/>
					</div>
					<div className={classes.itemSpacing}>
						<Box pb={1}>
							<Box display='flex' justifyContent='space-between'>
								<Typography variant='h5'>
									{t('views.addItem.itemDetails.subsection.description.title')}
								</Typography>
								<Typography className={classes.counterText}>
									{documentContent.value.length}/{descriptionMaxLength} {t('ui.characters')}
								</Typography>
							</Box>
							<Typography>
								{t('views.addItem.itemDetails.subsection.description.subtitle')}
							</Typography>
						</Box>
						<FormField
							hideCharacterCounter
							maxLength={descriptionMaxLength}
							multiline
							name={'description'}
							placeholder={t('views.addItem.itemDetails.subsection.description.placeholder')}
							required
							rows={4}
							variable={documentContent}
						/>
					</div>

					<div className={classes.itemSpacing}>
						<Box pb={1}>
							<Typography variant='h5'>
								{t('views.addItem.itemDetails.subsection.image.title')}
								<sup>*</sup>
							</Typography>
						</Box>
						{openCroper ?
							<ImageCroper
								aspectRatio={21 / 9}
								cropper={cropper}
								description={t('views.addItem.imageCroper.description')}
								handleClose={handleCloseCroper}
								handleCropeData={handleCropeData}
								header={t('views.addItem.setItemImage')}
								itemImages={itemImages}
								open={openCroper}
								setCropper={setCropper}
								src={itemImages[0].uri}
							/>
						:	null}
						<ImageDropzone
							callback={handleUploadProfileImage}
							cropImage={cropData}
							dropzoneSize='full-width'
							imageType='image/jpeg, image/png, image/jpg'
							images={itemImages}
							maxImageSize={1024}
							maxImages={1}
							setFileName={setFileName}
						/>
						<Tip
							arrowDirection='top'
							className={classes.tip}
							message={t('views.addItem.itemDetails.subsection.image.tip')}
						/>
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

ItemDetails.propTypes = {
	className: PropTypes.string,

	itemStep: PropTypes.number,
	save: PropTypes.func,
	onFetchCategories: PropTypes.func,
	selectedDescription: PropTypes.string,
	selectedCategory: PropTypes.number,
	selectedOrganisation: PropTypes.object,
	itemImages: PropTypes.array,
	categories: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	selectedName: PropTypes.string,
	onValidateItemName: PropTypes.func,
	validateItemName: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		categories: state.list.categories,
		currentUser: state.details.currentUser,
		validateItemName: state.details.validateItemName,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCategories: (sortBy, orderDescending, name) =>
			dispatch(actions.fetchCategories(sortBy, orderDescending, name)),
		onValidateItemName: (name, id) => dispatch(actions.validateItemName(name, id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);
