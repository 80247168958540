import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	cardControl: {
		padding: theme.spacing(3),
	},
	header: {
		padding: theme.spacing(0, 1, 1, 0),
	},
	subHeader: {
		marginBottom: theme.spacing(5),
	},
	switchButtons: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	dividers: {
		margin: theme.spacing(2, 0),
	},
	formLabel: {
		flexGrow: 3,
	},
	expansionPanel: {
		flexBasis: '100%',
		marginTop: theme.spacing(3),
	},
	textColor: {
		color: '#9e9e9e',
	},
	menu: {
		height: '100px',
	},
	textField: {
		overFlow: 'visible',
	},
}));
