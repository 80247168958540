import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles, withStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	panelSpacing: {
		marginRight: theme.spacing(1),
	},
	topPanel: {
		maxWidth: '320px',
		width: '320px',
		padding: theme.spacing(2, 0, 2, 0),
	},
	cardConttent: {
		padding: theme.spacing(4, 4.5),
	},
	expansionDetails: {
		margin: theme.spacing(0, 0, 1, 0),
		padding: theme.spacing(1, 0),
	},
	noTermsCard: {
		display: 'flex',
		flexDirection: 'row !important',
		alignItems: 'center',
		borderRadius: 20,
		padding: theme.spacing(1, 0),
		margin: theme.spacing(3, 0, 2.5, 0),
		backgroundColor: theme.palette.info.light,
	},
	noTermsIcon: {
		padding: theme.spacing(0.25, 0.5),
		marginLeft: theme.spacing(1),
	},
	noTermsLabel: {
		fontSize: '12px',
	},
	confirmButton: {
		padding: theme.spacing(1, 0),
		margin: theme.spacing(2, 0, 0, 0),
	},
	fileName: {
		margin: theme.spacing(0.5),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	description: {
		marginRight: theme.spacing(1),
	},
	closeButton: {
		// paddingRight: 0
	},
}));

export const AccordionSummary = withStyles({
	root: {
		padding: '0',
		backgroundColor: 'rgba(255, 255, 255, .03)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

export const Accordion = withStyles({
	root: {
		borderBottom: '1px dotted #e4e7eb',
		boxShadow: 'none',
		'&:last-child': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);
