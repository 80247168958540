import { Grid } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { LoadingDetailsBlock, LoadingDetailActionsBlock } from '../../../../../../components';

const SummaryLoading = (props) => {
	const classes = useStyles();

	const { className } = props;

	return (
		<Grid className={clsx(classes.root, className)} container spacing={3}>
			<Grid item lg={4} md={6} xl={3} xs={12}>
				<LoadingDetailsBlock />
			</Grid>
			<Grid item lg={4} md={6} xl={3} xs={12}>
				<LoadingDetailsBlock />
			</Grid>
			<Grid item lg={4} md={6} xl={3} xs={12}>
				<LoadingDetailsBlock />
			</Grid>
			<Grid item lg={4} md={6} xl={3} xs={12}>
				<LoadingDetailActionsBlock />
			</Grid>
		</Grid>
	);
};

SummaryLoading.propTypes = {
	className: PropTypes.string,
};

export default SummaryLoading;
