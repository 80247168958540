import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

function CustomNumberFormat(props) {
	const { inputRef, onChange, currencySymbol, ...other } = props;

	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			isNumericString
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			prefix={currencySymbol}
			thousandSeparator
		/>
	);
}

CustomNumberFormat.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	currencySymbol: PropTypes.string,
};

export default CustomNumberFormat;
