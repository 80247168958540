import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const SidepanelContainer = (props) => {
	const { sticky, children, className } = props;

	const classes = useStyles();

	return (
		<div
			className={clsx(
				{
					[classes.root]: true,
					[classes.sticky]: sticky,
				},
				className,
			)}
		>
			{children}
		</div>
	);
};

SidepanelContainer.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	sticky: PropTypes.bool,
};

export default SidepanelContainer;
