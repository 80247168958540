import PropTypes from 'prop-types';

import BillingInfo from './BillingInfo';
import ContactInfo from './ContactInfo';
import Header from './Header';
import OrganisationInfo from './OrganisationInfo';
import { useStyles } from './style';

const OrganisationDetails = (props) => {
	const classes = useStyles();

	const {
		save,
		name,
		legalName,
		description,
		images,
		organisationEmail,
		organisationPhoneNumber,
		address,
		vatNumber,
		enterpriseNumber,
		chamberOfCommerceNumber,
		ibanNumber,
	} = props;

	const organisationInfoProps = {
		name,
		legalName,
		description,
		images,
	};

	const contactInfoProps = {
		organisationEmail,
		organisationPhoneNumber,
		address,
	};

	const billingInfoProps = {
		vatNumber,
		enterpriseNumber,
		chamberOfCommerceNumber,
		ibanNumber,
		country: address.country,
	};

	return (
		<>
			<Header />
			<OrganisationInfo className={classes.wizardSection} save={save} {...organisationInfoProps} />
			<ContactInfo className={classes.wizardSection} save={save} {...contactInfoProps} />
			<BillingInfo className={classes.wizardSection} save={save} {...billingInfoProps} />
		</>
	);
};

OrganisationDetails.propTypes = {
	save: PropTypes.func,
	name: PropTypes.string,
	legalName: PropTypes.string,
	description: PropTypes.string,
	images: PropTypes.array,
	organisationEmail: PropTypes.string,
	organisationPhoneNumber: PropTypes.string,
	address: PropTypes.object,
	vatNumber: PropTypes.string,
	enterpriseNumber: PropTypes.string,
	chamberOfCommerceNumber: PropTypes.string,
	ibanNumber: PropTypes.string,
};

export default OrganisationDetails;
