import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		padding: theme.spacing(1.5),
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 'auto',
	},
	qrCode: {
		objectFit: 'fill',
		height: '160px',
		width: '160px',
	},
	button: {},
	bottomCell: {
		borderBottom: 'none',
	},
	mapLink: {
		...theme.link.body3,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
}));
