import { useEffect, useRef, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog, Dialog, FormDialog } from '~components';
import { useAuthorize } from '~features/authentication';

import selectedOrganisationAtom from '../atoms/selectedOrganisationAtom';
import AccessRulesDataGrid from '../components/dataGrids/accessRulesDataGrid';
import SkcAccessRuleForm from '../forms/skcRuleForm/skcAccessRuleForm';
import SkcAccessRule from '../interfaces/skcAccessRule';
import SkcAccessRulesService from '../services/skcAccessRulesService';

type UserAction =
	| {
			type: 'delete' | 'edit';
			label?: string;
			id: string;
	  }
	| {
			type: 'add';
			label?: never;
			id?: never;
	  };

const service = new SkcAccessRulesService();

const AccessRulesOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();
	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [userAction, setUserAction] = useState<UserAction | null>(null);
	const dataGridRef = useRef();
	
	const { isSuperAdmin } = useAuthorize();
	const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

	useEffect(() => {
		// Should be temporary
		if (selectedOrganisation) {
			service.organisation = selectedOrganisation;
		}
	}, [selectedOrganisation]);

	const handleEdit = async (id: string, data: SkcAccessRule) => {
		setUserActionProcessing(true);

		try {
			await service.updateAccessRule(id, data);
			enqueueSnackbar(t('ui.success.message.updated'), { variant: 'success' });
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleCreate = async (data: SkcAccessRule) => {
		setUserActionProcessing(true);

		try {
			await service.createAccessRule(data);
			enqueueSnackbar(`${t('ui.successfullyAdded')} ${t('rule').toLowerCase()}`, {
				variant: 'success',
			});
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleDelete = async (id: string) => {
		setUserActionProcessing(true);

		try {
			await service.deleteAccessRule(id);
			enqueueSnackbar(t('deleted'), { variant: 'success' });
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
		<Stack height={1}>
			<AccessRulesDataGrid
				ref={dataGridRef}
				onEdit={(value) => setUserAction({ type: 'edit', ...value })}
				onDelete={(value) => setUserAction({ type: 'delete', ...value })}
				onCreate={() => setUserAction({ type: 'add' })}
			/>
			<FormDialog
				title={t(userAction?.type === 'edit' ? 'editResource' : 'addResource', {
					resource: t('rule'),
				})}
				open={userAction?.type === 'edit' || userAction?.type === 'add'}
				loading={userActionProcessing}
				onSubmit={(data) => {
					if (userAction?.type === 'edit') {
						handleEdit(userAction.id, data);
					} else {
						handleCreate(data);
					}
				}}
				onClose={() => setUserAction(null)}
				maxWidth='md'
				fullWidth
			>
				<SkcAccessRuleForm id={userAction?.id} />
			</FormDialog>
			<ConfirmationDialog
				loading={userActionProcessing}
				open={userAction?.type === 'delete'}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('rule'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={() => userAction?.id != null && handleDelete(userAction.id)}
				onDecline={() => setUserAction(null)}
				onClose={() => setUserAction(null)}
			/>
		</Stack>
	);
};

export default AccessRulesOverviewLayout;
