import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		maxWidth: 550,
		overflow: 'visible',
	},
	dialogTitle: {
		...theme.typography.h3,
		padding: 0,
	},
	dialogContent: {
		...theme.typography.body2,
		padding: '40px 0 !important',
		lineHeight: '22px',
	},
	dialogActions: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: theme.spacing(4),
	},
	button: {
		height: 40,
		minWidth: '220px !important',
	},
}));
