import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import ContractsList from './ContractsList/ContractsList';
import Header from './Header';
import { useStyles } from './style';
import { Page } from '../../components';
import { isSuperAdmin } from '../../shared/utility';

const Contracts = (props) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const { t } = useTranslation('general');
	const classes = useStyles();
	const { tab } = useParams();

	const handleTabsChange = (e, value) => navigate(`../${value}`, { relative: 'path' });

	const tabs = [
		{ value: 'contracts', label: 'ui.label.contracts' },
		...(!isSuperAdmin(auth.user?.profile.role) ?
			[{ value: 'sharedContracts', label: 'ui.label.sharedContracts' }]
		:	[]),
	];

	if (!tab) {
		return <Navigate to={'/dashboard'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const headerButtons = [
		{ key: 'contracts', buttons: [] },
		{ key: 'sharedContracts', buttons: [] },
	];

	const sharedContractsProps = {
		sharedContracts: true,
	};

	return (
		<Page className={classes.root} title={t('ui.label.contracts')}>
			{headerButtons.map((item) => item.key === tab && <Header key={item.value} {...item} />)}
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tabVal) => (
					<Tab key={tabVal.value} label={t(tabVal.label)} value={tabVal.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'contracts' && <ContractsList />}
				{tab === 'sharedContracts' && <ContractsList {...sharedContractsProps} />}
			</div>
		</Page>
	);
};

export default Contracts;
