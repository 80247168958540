import { useState, useEffect } from 'react';

import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import {
	Box,
	Card,
	CardContent,
	CardActions,
	CardHeader,
	CircularProgress,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog, AlertDialog, FormField, StyledButton } from '../../../../../../components';
import { commaTimeStrings } from '../../../../../../shared/datetime';
import { useWizardFormField } from '../../../../../../shared/hooks';
import { isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
import { useStyles } from '../style';

const KeyTag = (props) => {
	const {
		instanceData,
		className,

		deleteKeyTag,
		onAddKeyTag,
		addKeyTag,
		onDeleteKeyTag,
		onResetState,
		onResetStateCondition,
		isSuperAdmin,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const instanceKeyTag = isObject(instanceData?.keyTag) && instanceData.keyTag;

	const { data: addKeyTagData, loading: addKeyTagLoading } = addKeyTag;

	const {
		success: deleteKeyTagSuccess,
		loading: deleteKeyTagLoading,
		error: deleteKeyTagError,
	} = deleteKeyTag;

	const [openAddKeyTagDialog, setOpenAddKeyTagDialog] = useState(false);
	const [openDeleteKeyTagDialog, setOpenDeleteKeyTagDialog] = useState(false);

	const keyTagName = useWizardFormField('', { required: true, minLength: 1, maxLength: 32 });
	const keyTagToken = useWizardFormField('', { required: true, minLength: 1, maxLength: 32 });

	const handleReset = () => {
		keyTagName.resetToInitialValue();
		keyTagToken.resetToInitialValue();
	};

	const handleAddKeyTag = () => {
		const bodyData = { keyTagName: keyTagName.value, keyTagToken: keyTagToken.value };
		onAddKeyTag(instanceData.itemReference.id, instanceData.id, bodyData, instanceData);
	};

	const handleCloseAddKeyTag = () => {
		setOpenAddKeyTagDialog(false);
		handleReset();
	};

	const handleOpenAddKeyTagDialog = () => setOpenAddKeyTagDialog(true);

	useEffect(() => {
		if (isObject(addKeyTagData)) {
			handleCloseAddKeyTag();
			enqueueSnackbar(`${t('ui.successfullyAdded')} ${t('ui.label.keyTag.keyTag')}`, {
				variant: 'success',
			});
			onResetState('addKeyTag');
		}
	}, [addKeyTag]);

	const handleOpenDeleteKeyTagdDialog = () => {
		setOpenDeleteKeyTagDialog(true);
	};

	const handleCloseDeleteKeyTagDialog = () => {
		setOpenDeleteKeyTagDialog(false);
	};

	const handleDeleteKeyTag = () => {
		onDeleteKeyTag(instanceData.itemReference.id, instanceData.id);
	};

	useEffect(() => {
		if (deleteKeyTagSuccess) {
			handleCloseDeleteKeyTagDialog();
			enqueueSnackbar(`${t('ui.successfullyDeleted')} ${t('ui.label.keyTag.keyTag')}`, {
				variant: 'success',
			});
			onResetStateCondition('deleteKeyTag', false);
		} else if (deleteKeyTagError) {
			enqueueSnackbar(deleteKeyTagError.message, { variant: 'error' });
			onResetStateCondition('deleteKeyTag', false);
		}
	}, [deleteKeyTag]);

	// isInternalUser
	const keyTagData = [
		...(instanceKeyTag ?
			[
				{ label: 'ui.label.keyTag.keyTagName', content: instanceKeyTag.keyTagName },
				{ label: 'ui.label.keyTag.keyTagToken', content: instanceKeyTag.keyTagToken },
				{ label: 'ui.label.linkedDate', content: commaTimeStrings(instanceKeyTag.linkedDate) },
			]
		:	[]),
	];

	const addData = [
		{
			name: 'keyTagName',
			label: 'ui.label.keyTag.keyTagName',
			focus: !keyTagName.hasFocus,
			maxLength: 32,
			variable: keyTagName,
		},
		{
			name: 'keyTagToken',
			label: 'ui.label.keyTag.keyTagToken',
			focus: !keyTagToken.hasFocus,
			maxLength: 16,
			variable: keyTagToken,
		},
	];

	return (
		<>
			<Card className={clsx(classes.root, className)}>
				<CardHeader title={t('ui.label.keyTag.keyTag')} />
				<Divider />
				<CardContent>
					<Table>
						<TableBody>
							{instanceKeyTag &&
								keyTagData.map((card, index) => (
									<TableRow key={card.label}>
										<TableCell className={index === 2 ? classes.bottomCell : ''}>
											{t(card.label)}
										</TableCell>
										<TableCell className={index === 2 ? classes.bottomCell : ''}>
											{card?.content ? card.content : '-'}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</CardContent>
				{isSuperAdmin && (
					<CardActions className={classes.actions}>
						{instanceKeyTag ?
							<StyledButton
								onClick={handleOpenDeleteKeyTagdDialog}
								startIcon={<ContactlessOutlinedIcon />}
								variant='inline-delete'
							>
								{t('ui.button.keyTag.delete')}
							</StyledButton>
						:	<StyledButton
								onClick={handleOpenAddKeyTagDialog}
								startIcon={<ContactlessOutlinedIcon />}
								variant='inline-default'
							>
								{t('ui.button.keyTag.add')}
							</StyledButton>
						}
					</CardActions>
				)}
			</Card>
			{openAddKeyTagDialog && (
				<ActionDialog
					actionButtonProps={{
						action: handleAddKeyTag,
						text: addKeyTagLoading ? <CircularProgress disableShrink size={24} /> : t('ui.add'),
					}}
					handleClose={handleCloseAddKeyTag}
					loading={addKeyTagLoading || !keyTagToken.isValid || !keyTagName.isValid}
					open={openAddKeyTagDialog}
					title={t('ui.button.keyTag.add')}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
						{addData.map((item) => (
							<FormField
								hideCharacterCounter={item.focus}
								key={item.name}
								label={t(item.label)}
								maxLength={item.maxLength}
								name={item.name}
								placeholder={'Ex. 123123123123123123123123'}
								required
								variable={item.variable}
							/>
						))}
					</Box>
				</ActionDialog>
			)}
			{openDeleteKeyTagDialog && (
				<AlertDialog
					dialogDescription={t('ui.label.keyTag.deleteDialog.description')}
					dialogTitle={t('ui.label.keyTag.delete')}
					handleClose={handleCloseDeleteKeyTagDialog}
					handleConfirm={handleDeleteKeyTag}
					loading={deleteKeyTagLoading}
					open={openDeleteKeyTagDialog}
				/>
			)}
		</>
	);
};

KeyTag.propTypes = {
	className: PropTypes.string,
	instanceData: PropTypes.object,

	isInternalUser: PropTypes.bool,
	onAddKeyTag: PropTypes.func,
	onDeleteKeyTag: PropTypes.func,
	onResetState: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	isSuperAdmin: PropTypes.bool,
	addKeyTag: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	deleteKeyTag: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		addKeyTag: state.details.addKeyTag,
		deleteKeyTag: state.condition.deleteKeyTag,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddKeyTag: (itemId, instanceId, bodyData, data) =>
			dispatch(actions.addKeyTag(itemId, instanceId, bodyData, data)),
		onDeleteKeyTag: (itemId, instanceId) => dispatch(actions.deleteKeyTag(itemId, instanceId)),
		onResetState: (state) => dispatch(actions.resetState(state)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyTag);
