import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'baseline',
	},
	arrowLeft: {
		cursor: 'pointer',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
	},
}));
