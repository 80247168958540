import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0.1),
		'& .MuiTableHead-root': {
			backgroundColor: '#fff',
		},
	},
	deletLoc: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '0',
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));
