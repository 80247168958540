import { RouteObject } from 'react-router-dom';

import { pagePathSegments } from '~constants';
import { PageLayout } from '~layouts';
import i18n from '~lib/i18n';
import {
	NfcTagsPage,
	UserDetailsPage,
	UserGroupsPage,
	UserInvitationsPage,
	UsersPage,
} from '~pages';

const userManagementRoutes = (): RouteObject[] => [
	{
		path: `${pagePathSegments.UserManagement}2`,
		element: (
			<PageLayout
				title={i18n.t('views.users.title')}
				pages={[
					{ label: i18n.t('ui.category.users'), path: pagePathSegments.Users },
					{ label: i18n.t('views.users.tab'), path: pagePathSegments.UserInvitations },
					{ label: i18n.t('ui.category.userGroups'), path: pagePathSegments.UserGroups },
					{ label: i18n.t('ui.label.nfcTags'), path: pagePathSegments.NfcTags },
				]}
			/>
		),
		children: [
			{
				path: pagePathSegments.Users,
				element: <UsersPage />,
			},
			{
				path: pagePathSegments.UserGroups,
				element: <UserGroupsPage />,
			},
			{
				path: pagePathSegments.UserInvitations,
				element: <UserInvitationsPage />,
			},
			{
				path: pagePathSegments.NfcTags,
				element: <NfcTagsPage />,
			},
		],
	},
	{
		path: `${pagePathSegments.UserManagement}2`,
		children: [
			{
				path: pagePathSegments.Users,
				children: [
					{
						path: ':id',
						element: <PageLayout title={i18n.t('views.userDetails.page.title')} />,
						children: [
							{
								index: true,
								element: <UserDetailsPage />,
							},
						],
					},
				],
			},
		],
	},
];

export default userManagementRoutes;
