import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Card, CardContent, Typography, Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Currency from '../../../../assets/icons/currency-24-px@3x.webp';
import { Tooltip, LoadingBar } from '../../../../components';
import { useStyles } from '../../style';

const Revenue = (props) => {
	const classes = useStyles();
	const { monthSwitcher, dashboardBookingsOverview } = props;
	const { t } = useTranslation('general');

	const { data: dashboardBookingsOverviewData, loading: dashboardBookingsOverviewLoading } =
		dashboardBookingsOverview;

	const handleRevenue = () =>
		dashboardBookingsOverviewData?.revenue?.price ?
			`${dashboardBookingsOverviewData?.revenue?.currencySymbol}${dashboardBookingsOverviewData?.revenue?.price.toFixed(2)}`
		:	'€0';

	return (
		<Card className={classes.card}>
			<CardContent
				sx={{
					padding: '24px 0px 24px 16px',
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
				}}
			>
				<Box display='flex' width='175px'>
					<Box className={classes.tooltipContainer}>
						<Tooltip
							content={<img alt={'currency-icon'} height='25' src={Currency} width='25' />}
							infoTitle={t('views.dashboard.finance.tooltip')}
						/>
					</Box>
					<Box display='flex' flexDirection='column'>
						<Typography>{t('nav.financialReports.revenue')}</Typography>
						<Typography>
							{dashboardBookingsOverviewLoading ?
								<LoadingBar />
							:	handleRevenue()}
						</Typography>
					</Box>
				</Box>
				<Box alignItems='center' display='flex' ml={3}>
					<IconButton
						color='primary'
						disableRipple={true}
						onClick={monthSwitcher.prevMonth}
						size='small'
					>
						<NavigateBeforeIcon />
					</IconButton>
					<Typography sx={{ whiteSpace: 'nowrap' }}>
						{monthSwitcher.currentMonth.toLocaleString('default', {
							month: 'long',
							year: 'numeric',
						})}
					</Typography>
					<IconButton color='primary' onClick={monthSwitcher.nextMonth} size='small'>
						<NavigateNextIcon />
					</IconButton>
				</Box>
			</CardContent>
		</Card>
	);
};

Revenue.propTypes = {
	currentMonth: PropTypes.object,
	monthSwitcher: PropTypes.object,
	dashboardBookingsOverview: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		dashboardBookingsOverview: state.details.dashboardBookingsOverview,
	};
};

export default connect(mapStateToProps, null)(Revenue);
