import { Grid } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import Address from './Address';
import BillingAndAdminInfo from './BillingAndAdminInfo';
import ContactInfo from './ContactInfo';
import Devices from './Devices';
import GeneralInfo from './GeneralInfo';
import OtherActions from './OtherActions';
import { useStyles } from './style';
import Subscription from './Subscription';
import SummaryLoading from './SummaryLoading';
import { isSuperAdmin, isObject } from '../../../../../shared/utility';

const Summary = (props) => {
	const { className, organisation, loading, currentUser } = props;
	const auth = useAuth();

	const userRole = auth.user?.profile.role.toLowerCase();

	const { data: currentUserData } = currentUser;
	const classes = useStyles();
	const superAdmin = isSuperAdmin(userRole);
	const isMyOrganisation =
		isObject(currentUserData) &&
		isObject(organisation) &&
		organisation.id === currentUserData.organisationReference.id;

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return loading ?
			<SummaryLoading />
		:	<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<GeneralInfo organisation={organisation} />
				</Grid>
				<Grid {...gridProps}>
					<ContactInfo organisation={organisation} />
				</Grid>
				<Grid {...gridProps}>
					<BillingAndAdminInfo organisation={organisation} />
				</Grid>
				<Grid {...gridProps}>
					<Address organisation={organisation} />
				</Grid>
				<Grid {...gridProps}>
					<Subscription organisation={organisation} />
				</Grid>
				{superAdmin && isMyOrganisation ? null : (
					<Grid {...gridProps}>
						<Devices organisation={organisation} />
					</Grid>
				)}
				<Grid {...gridProps}>
					{superAdmin ?
						<OtherActions organisation={organisation} />
					:	null}
				</Grid>
			</Grid>;
};

Summary.propTypes = {
	className: PropTypes.string,

	organisation: PropTypes.object,
	loading: PropTypes.bool,
	verified: PropTypes.bool,

	currentUser: PropTypes.shape({
		data: PropTypes.any,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
	};
};

export default connect(mapStateToProps, null)(Summary);
