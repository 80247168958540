/**
 * A base service class to e.g. give the ability to cancel requests
 */
abstract class BaseService {
	protected _controller = new AbortController();

	/**
	 * Abort the current get requests.
	 * TODO: should also do this for the others (post, put etc)?
	 * @param reason The reason for the abort
	 */
	public abortCurrentRequest(reason?: string) {
		this._controller.abort(reason);
		this._controller = new AbortController();
	}
}

export default BaseService;
