import { useEffect, useState } from 'react';

import {
	CardContent,
	CardActions,
	Grid,
	Typography,
	Select,
	FormControlLabel,
	FormControl,
	Box,
	Divider,
	MenuItem,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { Modal, StyledButton } from '../../../../../../../components';
import SwitchButton from '../../../../../../../components/local/SwitchButton/SwitchButton';
import {
	getTimes,
	getLocalTime,
	modifyDate,
	parseTime,
	stringifyTime,
	localToUTCTime,
	changeZone,
} from '../../../../../../../shared/datetime';
import { useError } from '../../../../../../../shared/hooks';
import {
	isString,
	capitalizeFirstCharacter,
	isObject,
	isFullString,
} from '../../../../../../../shared/utility';
import * as actions from '../../../../../../../store/actions';

const timeClose = 17;

const OpeningHoursEdit = (props) => {
	const { openHours, open, onClose, className, updateHubDetails, onUpdateHub, hub } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const { success: updateHubDetailsSuccess, loading: updateHubDetailsLoading } = updateHubDetails;

	const errorHandleUpdate = useError(
		isObject(updateHubDetails) ?
			{
				value: updateHubDetails,
				message: `${t('ui.successfully')} ${t('ui.updated')} ${hub.name}`,
			}
		:	null,
	);

	useEffect(() => {
		if (!updateHubDetailsLoading && updateHubDetailsSuccess) {
			errorHandleUpdate.setStartAction(true);
		}
	}, [updateHubDetailsSuccess]);

	const [state, setState] = useState({
		weekdays: openHours.map((openHour, index) => ({
			id: index,
			name: openHour.dayOfWeek,
			open:
				openHour.openStatus === 'open' ?
					changeZone(openHour.openHour)
				:	modifyDate(new Date(), { hours: 0, minutes: 0 }),
			close:
				openHour.openStatus === 'open' ?
					changeZone(openHour.closeHour)
				:	modifyDate(new Date(), { hours: 0, minutes: 0 }),
			isOpen: openHour.openStatus === 'open' || openHour.openStatus === 'openAllDay',
			openAllDay: openHour.openStatus === 'openAllDay',
		})),
		times: getTimes(),
	});

	if (!open) {
		return null;
	}
	const handleOpenChange = (event) => {
		event.persist();
		const updatedWeekdays = state.weekdays.map((weekday) =>
			weekday.id === parseInt(event.target.value, 10) ?
				{
					...weekday,
					isOpen: !weekday.isOpen,
					...(weekday.isOpen && { open: getLocalTime(), close: getLocalTime(timeClose) }),
				}
			:	weekday,
		);

		setState({ ...state, weekdays: updatedWeekdays });
	};
	const handleOpenAllDayChange = (event) => {
		event.persist();
		const updatedWeekdays = state.weekdays.map((weekday) =>
			weekday.id === parseInt(event.target.value, 10) ?
				{
					...weekday,
					openAllDay: !weekday.openAllDay,
					...(!weekday.openAllDay && { open: getLocalTime(0), close: getLocalTime(0) }),
				}
			:	weekday,
		);
		setState({ ...state, weekdays: updatedWeekdays });
	};
	const handleOpenTimeChange = (event) => {
		event.preventDefault();
		const value = event.target.value;
		if (isString(value)) {
			const updatedWeekdays = state.weekdays.map((weekday) =>
				weekday.name === event.target.name ?
					{
						...weekday,
						open: parseTime(value, weekday.open),
					}
				:	weekday,
			);
			setState({ ...state, weekdays: updatedWeekdays });
		}
	};
	const handleCloseTimeChange = (event) => {
		event.preventDefault();
		const value = event.target.value;
		if (isString(value)) {
			const updatedWeekdays = state.weekdays.map((weekday) =>
				weekday.name === event.target.name ?
					{
						...weekday,
						close: parseTime(value, weekday.close),
					}
				:	weekday,
			);
			setState({ ...state, weekdays: updatedWeekdays });
		}
	};

	const handleOpeningHoursUpdate = () => {
		const newOpenHours = state.weekdays.map((weekday) => ({
			dayOfWeek: weekday.name.toLowerCase(),
			openStatus:
				weekday.openAllDay && weekday.isOpen ? 'openAllDay'
				: weekday.isOpen ? 'open'
				: 'closed',
			...(!weekday.openAllDay &&
				weekday.isOpen && {
					openTime: localToUTCTime(weekday.open).toISOString(),
					closeTime: localToUTCTime(weekday.close).toISOString(),
				}),
		}));
		const body = {
			name: hub.name,
			newOpenHours: newOpenHours,
			latitude: hub.latitude,
			longitude: hub.longitude,
			address: hub.address,
			...(isFullString(hub.description) && { description: hub.description }),
		};
		onUpdateHub(hub.id, body);
	};

	const handleClose = () => {
		onClose();
		setState({
			weekdays: openHours.map((openHour, index) => ({
				id: index,
				name: openHour.dayOfWeek,
				open:
					openHour.openStatus === 'open' ?
						changeZone(openHour.openHour)
					:	modifyDate(new Date(), { hours: 0, minutes: 0 }),
				close:
					openHour.openStatus === 'open' ?
						changeZone(openHour.closeHour)
					:	modifyDate(new Date(), { hours: 0, minutes: 0 }),
				isOpen: openHour.openStatus === 'open' || openHour.openStatus === 'openAllDay',
				openAllDay: openHour.openStatus === 'openAllDay',
			})),
			times: getTimes(),
		});
	};

	return (
		<Modal className={classes.modelWs} onClose={handleClose} open={open}>
			<form className={clsx(classes.root, className)}>
				<CardContent>
					<Typography align='center' gutterBottom variant='h3'>
						{t('ui.edit')}
					</Typography>
					<Grid className={classes.container} container>
						<Grid item lg={12} md={12} xs={12}>
							{state.weekdays.map((weekday) => (
								<div key={weekday.id}>
									<Box alignItems='center' display='flex' justifyContent='space-between'>
										<Typography>{capitalizeFirstCharacter(weekday.name)}</Typography>
										<FormControl variant='standard'>
											<FormControlLabel
												control={
													<SwitchButton
														checked={weekday.isOpen}
														name={weekday.name}
														onChange={handleOpenChange}
														value={weekday.id}
													/>
												}
												label={
													!weekday.isOpen ?
														<Typography variant='subtitle2'> {t('ui.closed')}</Typography>
													:	<Typography color='primary' variant='subtitle2'>
															{t('ui.open')}
														</Typography>
												}
												labelPlacement='start'
											/>
										</FormControl>
									</Box>
									{weekday.isOpen ?
										<div className={classes.selectCard}>
											<Box className={classes.expansionPanel}>
												<Typography>{t('ui.label.openingAt')}</Typography>
												<FormControl
													className={classes.formSelectTimeOpen}
													disabled={weekday.openAllDay}
													size='small'
												>
													<Select
														name={weekday.name}
														onChange={handleOpenTimeChange}
														value={stringifyTime(weekday.open)}
														variant='outlined'
													>
														{state.times.map((time) => (
															<MenuItem
																disabled={
																	stringifyTime(weekday.close) <= time &&
																	stringifyTime(weekday.open) !== stringifyTime(weekday.close)
																}
																key={time}
																value={time}
															>
																{time}
															</MenuItem>
														))}
													</Select>
												</FormControl>
												<Typography>{t('ui.label.closingAt')}</Typography>
												<FormControl
													className={classes.formSelectTimeClosed}
													disabled={weekday.openAllDay}
													size='small'
													variant='standard'
												>
													<Select
														name={weekday.name}
														onChange={handleCloseTimeChange}
														value={stringifyTime(weekday.close)}
														variant='outlined'
													>
														{state.times.map((time) => (
															<MenuItem
																disabled={stringifyTime(weekday.open) >= time}
																key={time}
																value={time}
															>
																{time}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Box>
											<Box>
												<FormControl variant='standard'>
													<FormControlLabel
														control={
															<SwitchButton
																checked={weekday.openAllDay}
																name={weekday.name}
																onChange={handleOpenAllDayChange}
																value={weekday.id}
															/>
														}
														label={
															weekday.openAllDay ?
																<Typography color='secondary' variant='subtitle2'>
																	{t('ui.label.allDay')}
																</Typography>
															:	<Typography variant='subtitle2'>{t('ui.label.allDay')}</Typography>
														}
														labelPlacement='start'
													/>
												</FormControl>
											</Box>
										</div>
									:	null}
									<Divider className={classes.dividers} variant='fullWidth' />
								</div>
							))}
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.actions}>
					<StyledButton onClick={handleClose} variant='contained-tertiary'>
						{t('ui.button.contained.close')}
					</StyledButton>
					<StyledButton
						disabled={updateHubDetailsLoading}
						onClick={handleOpeningHoursUpdate}
						variant='contained-primary'
					>
						{updateHubDetailsLoading ?
							<CircularProgress disableShrink size={24} />
						:	t('ui.save')}
					</StyledButton>
				</CardActions>
			</form>
		</Modal>
	);
};

OpeningHoursEdit.displayName = 'LocationEdit';
OpeningHoursEdit.propTypes = {
	className: PropTypes.string,
	id: PropTypes.number,
	name: PropTypes.string,
	openHours: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	hub: PropTypes.object,

	updateHubDetails: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onUpdateHub: PropTypes.func,
};

OpeningHoursEdit.defaultProps = {
	open: false,
	onClose: () => {},
};

const mapStateToProps = (state) => {
	return {
		updateHubDetails: state.condition.updateHubDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateHub: (hubId, body) => dispatch(actions.updateHub(hubId, body)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OpeningHoursEdit);
