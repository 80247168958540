import { useEffect, useState } from 'react';

import { Box, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { useStyles } from './style';
import { Table, EmptyTable, Label } from '../../../../../components';
import {
	isEmptyArray,
	isFullArray,
	isUndefined,
	decimalAmount,
	getFinanceTableHeaders,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const UserFinance = (props) => {
	const { userId, onFetchBillings, userBillings, onUpdatePagedState, userDetails } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [fetch, setFetch] = useState(false);
	const page = { number: pageNumber, size: pageSize };

	const { data: userBillingsData, loading: userBillingsLoading } = userBillings;

	const { data: userDetailsData } = userDetails;

	const filters = { userId: userId, includeCurrent: true };

	useEffect(() => {
		if (!userBillingsLoading && (userId || fetch)) {
			onFetchBillings(page, filters);
			if (fetch) {
				setFetch(false);
			}
		}
	}, [userId, onFetchBillings, fetch]);

	useEffect(() => {
		return () => {
			onUpdatePagedState('billings', null, false);
		};
	}, []);

	const handlePageChange = (newPage) => {
		setPageNumber(newPage);
		setFetch(true);
	};

	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
		setFetch(true);
	};

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const billingLink = (billing) => {
		return (
			<Link
				className={classes.link}
				component={RouterLink}
				to={`/financeManagement/personal/${billing.start}/${billing.title}/billing/${userDetailsData?.name}/${userDetailsData?.id}`}
				state={{
					data: {
						...billing,
						userDetails: true,
						userReference: {
							id: userDetailsData?.id,
							name: userDetailsData?.name,
						},
					},
				}}
				variant='h6'
			>
				{billing.title}
			</Link>
		);
	};

	const headers = getFinanceTableHeaders();

	const tableHeaders =
		isEmptyArray(userBillingsData?.results) ?
			[{ name: 'period', content: t('ui.period') }]
		:	[...headers];

	const loadingBody =
		isUndefined(userBillingsData?.results) || fetch || userBillingsLoading ?
			Array(5)
				.fill(Array(tableHeaders?.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const tableBody =
		isFullArray(userBillingsData?.results) ?
			userBillingsData.results.map((billing) => [
				{ content: <Box className={classes.periodContent}>{billingLink(billing)}</Box> },
				{ content: `${decimalAmount(billing.amount.subtotal, billing.amount)}` },
				{ content: `${decimalAmount(billing.amount.discount, billing.amount)}` },
				{
					content: `${decimalAmount(billing.amount.subtotal - billing.amount.discount, billing.amount)}`,
				},
				{ content: `${decimalAmount(billing.amount.priceVat, billing.amount)}` },
				{ content: `${decimalAmount(billing.amount.refund, billing.amount)}` },
				{ content: `${decimalAmount(billing.amount.totalPrice, billing.amount)}` },
				{ content: `${decimalAmount(billing.open, billing.amount)}` },
				{
					content: (
						<Label className={classes.status} type={statusColor[billing.status]}>
							{' '}
							{statusText[billing.status]}{' '}
						</Label>
					),
				},
			])
		:	null;

	const emptyBody =
		isEmptyArray(userBillingsData?.results) ?
			[
				[
					{
						content: <EmptyTable label={'views.userDetails.finance.emptyResults'} />,
					},
				],
			]
		:	null;

	return (
		<Box>
			<Table
				body={loadingBody || tableBody || emptyBody}
				data={userBillingsData ? userBillingsData.results : []}
				handlePageChange={handlePageChange}
				header={tableHeaders}
				loading={userBillingsLoading}
				page={pageNumber}
				rowsPerPage={pageSize}
				setRowsPerPage={handlePageSizeChange}
				noTitle={true}
				total={userBillingsData?.total ? userBillingsData.total : 0}
				isNotPaginate={isEmptyArray(userBillingsData?.results)}
			/>
		</Box>
	);
};

UserFinance.propTypes = {
	userId: PropTypes.number,
	onFetchBillings: PropTypes.func,

	onUpdatePagedState: PropTypes.func,
	userBillings: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	userDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};
const mapStateToProps = (state) => {
	return {
		userBillings: state.paged.billings,
		userDetails: state.details.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBillings: (page, filters, concat) =>
			dispatch(actions.fetchBillings(page, filters, concat)),
		onUpdatePagedState: (identifier, data, concat) =>
			dispatch(actions.updatePagedState(identifier, data, concat)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(UserFinance);
