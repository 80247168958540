import available from '../data/translations/_.json';

// TODO: can this file be removed, because of translations refactor

export const availableLocales = available;

export const availableLanguages = availableLocales.map(({ language }) => language);

export const getDefaultLocale = () => availableLocales.find((locale) => locale.isDefault);

export const getLocale = (localeString) => {
	localeString =
		localeString ||
		navigator.language ||
		(navigator.languages ? navigator.languages[0] : undefined) ||
		navigator.userLanguage;

	return availableLocales.find((locale) => locale.id === localeString) || getDefaultLocale();
};
