import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import ConsumersManagement from './ConsumersManagement';
import Header from './Header';
import Items from './Items';
import Management from './Management';
import { useStyles } from './style';
import { Page } from '../../components';
import { useError, usePrevious } from '../../shared/hooks';
import { isObject, isInteger, isSuperAdmin } from '../../shared/utility';

const ItemManagement = (props) => {
	const { currentUser, addInstance } = props;
	const auth = useAuth();
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { tab } = useParams();

	const { data: currentUserData } = currentUser;
	const organisationId =
		isObject(currentUserData) && isInteger(currentUserData.organisationReference.id) ?
			currentUserData.organisationReference.id
		:	null;

	const classes = useStyles();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'items', label: 'ui.category.items' },
		...(!isSuperAdmin(auth.user?.profile.role) ?
			[{ value: 'itemSharedWithMe', label: 'views.assets.tab.itemSharedWithMe' }]
		:	[]),
		{ value: 'publicItems', label: 'views.assets.tab.publicItems' },
		{ value: 'management', label: 'ui.tab.management' },
	];
	// import.meta.env.VITE_APP_ENV === 'development'
	const usePreviousInstance = usePrevious(addInstance);
	const itemInstanceErrorHandling = useError({
		value: addInstance,
		message: t('views.addItem.message.success.addItemInstance'),
		variant: 'success',
	});

	useEffect(() => {
		if (usePreviousInstance && addInstance && usePreviousInstance.loading) {
			itemInstanceErrorHandling.setStartAction(true);
		}
	}, [usePreviousInstance]);

	if (!tab) {
		return <Navigate to={'/item-management/items'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const headerButtons = [
		{ key: 'items', className: classes.header },
		{ key: 'itemSharedWithMe', className: classes.header },
		{ key: 'publicItems', className: classes.header },
		{ key: 'management', className: classes.header },
	];

	return (
		<Page className={classes.root} title={t('views.assets.page.title')}>
			{headerButtons.map((item) => item.key === tab && <Header key={item.key} {...item} />)}
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={t(tab.label)} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'items' && (
					<Items itemAccess={'private'} itemUse='privateItems' itemsOfOrganisation={true} />
				)}
				{tab === 'itemSharedWithMe' && (
					<Items
						itemAccess={'public'}
						itemSharedWithMe={true}
						itemUse='sharedWithMe'
						organisationId={organisationId}
					/>
				)}
				{tab === 'publicItems' && (
					<Items itemAccess={'public'} itemUse='publicItems' itemsOfOrganisation={true} />
				)}
				{tab === 'management' &&
					(isSuperAdmin(auth.user?.profile.role) ? <Management /> : <ConsumersManagement />)}
			</div>
		</Page>
	);
};

ItemManagement.propTypes = {
	currentUser: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	addInstance: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,

		addInstance: state.details.addInstance,
	};
};

export default connect(mapStateToProps)(ItemManagement);
