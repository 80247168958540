import React, { useEffect, useState } from 'react';

import path from 'path';

import {
	Check as CheckIcon,
	ErrorOutline as ErrorOutlineIcon,
	RestartAlt as RestartAltIcon,
} from '@mui/icons-material';
import { Avatar, Box, Chip, ChipOwnProps, ChipProps, Link as MuiLink, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { InfoCard, TooltipNew } from '~components';
import { pagePathSegments } from '~constants';
import { datetimeWithoutSecondsFormat } from '~utils/dateUtils';

import UserLicenseValidationStatusEnum from '../../enums/userLicenseValidationStatusEnum';
import UserLicenseVerificationStatusEnum from '../../enums/userLicenseVerificationStatusEnum';
import UserLicensesService from '../../services/userLicensesService';

// TODO: eventually through a theme
const colors = {
	success: {
		bgColor: '#CFF5E7',
		color: 'success',
	},
};

const service = new UserLicensesService();

interface UserLicenseCardProps {
	userId?: string;
	onEditClick?: () => void;
}

const UserLicenseCard = ({ userId, ...props }: UserLicenseCardProps) => {
	const { t } = useTranslation('general');

	const refreshString = `${service.basePath}.${userId}`;
	const { data, isLoading, error } = useSWR(userId != null && refreshString, () =>
		service.getUserLicenseById(userId!),
	);

	const rows =
		!data || !userId ?
			[]
		:	[
				// {
				// 	headerName: t('status'),
				// 	value: data.verificationStatus,
				// 	renderCell: () => <VerificationChip status={data.verificationStatus} />,
				// },
				{
					headerName: t('views.licenses.table.requestId'),
					value: data.id,
					renderCell: (value) => (
						<MuiLink
							component={ReactRouterLink}
							to={`/${path.join(pagePathSegments.Licenses, pagePathSegments.Request, userId)}`}
						>
							{value}
						</MuiLink>
					),
				},
				{
					headerName: t('views.userManagement.license.country'),
					value: data.countryCode,
				},
				{
					headerName: t('ui.label.dateOfBirth'),
					value: data.dateOfBirth,
					valueFormatter: (value: Date) => value.toLocaleDateString(),
				},
				{
					headerName: t('ui.label.licenseNumber'),
					value: data.licenseNumber,
					renderCell: (value) => (
						<Stack direction='row' alignItems='center' spacing={0.5}>
							<Box>{value}</Box>
							{data.validationStatus === UserLicenseValidationStatusEnum.Validated &&
								data.countryCode.localeCompare('NL', 'en', { sensitivity: 'base' }) === 0 && (
									<TooltipNew
										title={`${t('ui.rdw.validated')} ${datetimeWithoutSecondsFormat.format(data.validationDate)}`}
									>
										<Avatar sx={{ bgcolor: colors.success.bgColor, width: 16, height: 16 }}>
											<CheckIcon color='success' sx={{ fontSize: 12 }} />
										</Avatar>
									</TooltipNew>
								)}
							{data.isDuplicate && (
								<TooltipNew title={t('views.pendingLicenseDetails.duplicateLicense')}>
									<ErrorOutlineIcon color='error' fontSize='small' />
								</TooltipNew>
							)}
						</Stack>
					),
				},
				{
					headerName: t('ui.label.expiryDate'),
					value: data.expiryDate,
					valueFormatter: (value: Date) => value.toLocaleDateString(),
				},
			];

	return (
		<InfoCard
			title={t('views.userDetails.summary.cardHeaders.license')}
			rows={rows}
			statusComponent={
				data != null ? <VerificationChip status={data.verificationStatus} /> : undefined
			}
			actions={[
				...(data?.validationStatus === UserLicenseValidationStatusEnum.Blocked ?
					[
						{
							label: t('views.licenses.dialog.header'),
							icon: <RestartAltIcon />,
							// onClick: props.onEditClick
						},
					]
				:	[]),
			]}
			loading={isLoading}
			error={error != null}
			noResultsLabel={t('views.userManagement.license.noLicense')}
		/>
	);
};

interface VerificationChipProps extends ChipProps {
	status: UserLicenseVerificationStatusEnum;
}

const VerificationChip = ({ status }: VerificationChipProps) => {
	const { t } = useTranslation('general');

	const getStatusColor = (status: UserLicenseVerificationStatusEnum): ChipOwnProps['color'] => {
		let color: ChipOwnProps['color'] = 'default';
		switch (status) {
			case UserLicenseVerificationStatusEnum.Expired:
				color = 'info';
				break;
			case UserLicenseVerificationStatusEnum.Rejected:
				color = 'error';
				break;
			case UserLicenseVerificationStatusEnum.Verified:
				color = 'success';
				break;
		}

		return color;
	};

	return <Chip size='small' label={t(status)} color={getStatusColor(status)} />;
};

export default UserLicenseCard;
