import MuiAccordion from '@mui/material/Accordion';
import { makeStyles, withStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	mainCard: {
		marginBottom: '60px',
	},
	mainCardMrgin: {
		marginBottom: '0px',
	},
	filterButton: {
		marginRight: theme.spacing(2),
	},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 700,
		'& .MuiTableHead-root': {
			backgroundColor: '#fff',
		},
	},
	actions: {
		padding: theme.spacing(0, 1),
		justifyContent: 'flex-end',
	},
	collapsButtonCell: {
		paddingRight: '8px',
	},
	cellSpacing: {
		width: '50px',
		// overflowWrap: 'break-word',
		// wordBreak: 'break-all'
	},
	cellWidth: {
		width: 60,
	},
	accordionSummary: {
		'& .MuiAccordionSummary-content': {
			margin: 0,
		},
	},
	actionsPagination: {
		padding: theme.spacing(0, 1),
		marginLeft: '60px',
		justifyContent: 'flex-end',
		borderBottom: '1px solid #E4E7EB',
	},
	providerHeader: {
		width: 110,
	},
	itemGroepHeader: {
		width: '10%',
	},
	itemHeader: {
		width: '10%',
	},
	userGroupHeader: {
		width: '10%',
	},
	termsHeader: {
		width: '10%',
	},
	priceHeader: {
		width: '10%',
	},
	businesPriceHeader: {
		width: '10%',
	},
	policyHeader: {
		width: '10%',
	},
	lastUpdateHeader: {
		width: '10%',
	},
	consumerHeaders: {
		width: '15%',
	},
	qtyHeader: {
		width: '5%',
	},
	checkboxHeaders: {
		width: 60,
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));

export const Accordion = withStyles({
	root: {
		borderBottom: '1px dotted #e4e7eb',
		boxShadow: 'none',
		'&:last-child': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);
