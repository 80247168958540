import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import FileReaderInput from 'react-file-reader-input';

import { useStyles } from './style';
import { isFullString } from '../../../shared/utility';

const ImageUpload = (props) => {
	const { onChange, previewImage, message, isOwnItem } = props;
	const classes = useStyles();

	return (
		<div>
			<div className={classes.imageContainer}>
				<img alt='itemImage' className={classes.imageSpacing} src={previewImage} />
			</div>
			{isOwnItem ?
				<FileReaderInput
					accept={['image/png', 'image/svg+xml', 'image/jpeg', 'image/jpg']}
					multiple={false}
					onChange={onChange}
				>
					<Box alignItems='center' display='flex' justifyContent='space-between'>
						{<PhotoCamera className={classes.button} />}
						<Typography color='error' variant='h6'>
							{isFullString(message) && message}
						</Typography>
					</Box>
				</FileReaderInput>
			:	null}
		</div>
	);
};

ImageUpload.propTypes = {
	className: PropTypes.string,

	onChange: PropTypes.func,
	previewImage: PropTypes.string,
	message: PropTypes.string,
	isOwnItem: PropTypes.bool,
};

export default ImageUpload;
