import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	content: {
		padding: 12,
		paddingBottom: '0px',
	},
	actionsButton: {
		marginRight: '14px',
		color: '#fff',
	},
}));
