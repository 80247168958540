import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	sticky: {
		position: 'sticky',
		top: theme.spacing(4),
	},
}));
