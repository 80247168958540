import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	formGroup: {
		marginBottom: theme.spacing(5),
	},
	fillInHeader: {
		margin: theme.spacing(0, 0, 4, 0),
	},
	paragraph: {
		marginBottom: theme.spacing(3),
	},
	formSpacing: {
		fontSize: '20px',
		marginBottom: theme.spacing(5.4),
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
	addButton: {
		alignContent: 'flex-end',
		marginTop: theme.spacing(5.4),
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	alertCard: {
		marginTop: theme.spacing(2),
		backgroundColor: theme.palette.error.light,
		boxShadow: 'none',
	},
	alertCardContent: {
		padding: theme.spacing(2),
		'&:last-child': {
			padding: theme.spacing(2),
		},
	},
	alertCardText: {
		color: theme.palette.error.main,
	},
}));
