import { useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog, FormField } from '../../../../../../../components';
import { categories, licensePlateLength } from '../../../../../../../constantsOld';
import {
	useBackendFieldCheck,
	useError,
	useWizardFormField,
} from '../../../../../../../shared/hooks';
import { isFullArray, isObject, isUndefined } from '../../../../../../../shared/utility';
import * as actions from '../../../../../../../store/actions';
import { useStyles } from '../../style';

const EditInstanceInfo = (props) => {
	const {
		open,
		onClose,

		instanceData,
		patchInstance,
		validatedBikeNumber,
		validatedFrameNumber,
		validatedRoomNumber,
		validatedTrailerNumber,
		validatedTrailerVinNumber,
		validatedLicensePlate,
		validatedVinNumber,
		validatedParkingNumber,
		validatedAssetNumber,
		onValidateInstanceBikeNumber,
		onValidateInstanceFrameNumber,
		onValidateInstanceRoomNumber,
		onValidateInstanceTrailerNumber,
		onValidateInstanceTrailerVinNumber,
		onValidateInstanceLicensePlate,
		onValidateInstanceVinNumber,
		onValidateInstanceParkingNumber,
		onValidateInstanceAssetNumber,
		onResetStateCondition,
		onValidateInstanceBoatNumber,
		validateBoatNumber,
		isDeviceMileagePresent,
		onPatchInstance,
		onUpdateDetailsState,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const {
		data: updatedItemInstanceData,
		loading: updatedItemInstaceLoading,
		error: updatedItemInstanceError,
	} = patchInstance;
	const updatedItemInstanceDone =
		!updatedItemInstaceLoading && !updatedItemInstanceError && updatedItemInstanceData;

	const selectedCategory = instanceData.categoryReference.id;
	const itemId = instanceData.itemReference.id;

	const updatedItemInstanceMessage = useError({
		value: patchInstance,
		message: t('views.updateItemInstance.success'),
	});

	useEffect(() => {
		if (updatedItemInstanceDone) {
			onUpdateDetailsState('fetchInstance', updatedItemInstanceData);
			onClose();
		}
	}, [updatedItemInstanceDone]);

	// Bikes
	const isBike = isObject(instanceData?.itemBike);
	const bikeNumber = useWizardFormField(isBike ? instanceData.itemBike.bikeNumber : '', {
		required: true,
		maxLength: 16,
	});
	const checkedBikeNumber = useBackendFieldCheck(
		bikeNumber.value,
		onValidateInstanceBikeNumber,
		validatedBikeNumber,
		{ itemId },
	);
	const frameNumber = useWizardFormField(isBike ? instanceData.itemBike.frameNumber : '', {
		required: true,
		minLength: 6,
		maxLength: 32,
	});
	const checkedFrameNumber = useBackendFieldCheck(
		frameNumber.value,
		onValidateInstanceFrameNumber,
		validatedFrameNumber,
		{ itemId },
	);

	// Office
	const isOffice = isObject(instanceData?.itemOffice);
	const officeRoomNumber = useWizardFormField(isOffice ? instanceData.itemOffice.roomNumber : '', {
		required: true,
		maxLength: 16,
	});
	const checkedRoomNumber = useBackendFieldCheck(
		officeRoomNumber.value,
		onValidateInstanceRoomNumber,
		validatedRoomNumber,
		{ itemId },
	);

	//Trailer
	const isTrailer = isObject(instanceData?.itemTrailer);
	const trailerNumber = useWizardFormField(
		isTrailer ? instanceData.itemTrailer.trailerNumber : '',
		{ required: true, maxLength: 25 },
	);
	const checkedTrailerNumber = useBackendFieldCheck(
		trailerNumber.value,
		onValidateInstanceTrailerNumber,
		validatedTrailerNumber,
		{ itemId },
	);
	const trailerVinNumber = useWizardFormField(isTrailer ? instanceData.itemTrailer.vinNumber : '', {
		required: true,
		maxLength: 17,
	});
	const checkedTrailerVinNumber = useBackendFieldCheck(
		trailerVinNumber.value,
		onValidateInstanceTrailerVinNumber,
		validatedTrailerVinNumber,
		{ itemId },
	);

	//Car
	const isCar = isObject(instanceData?.itemCar);
	const licensePlate = useWizardFormField(isCar ? instanceData.itemCar.licensePlate : '', {
		required: true,
		minLength: 1,
		maxLength: licensePlateLength,
	});
	const checkedLicensePlate = useBackendFieldCheck(
		licensePlate.value,
		onValidateInstanceLicensePlate,
		validatedLicensePlate,
		{ itemId },
	);
	const carBrand = useWizardFormField(isCar ? instanceData.itemCar.brand : '', {
		required: true,
		maxLength: 16,
	});
	const model = useWizardFormField(isCar ? instanceData.itemCar.model : '', {
		required: true,
		maxLength: 32,
	});
	const vinNumber = useWizardFormField(isCar ? instanceData.itemCar.vinNumber : '', {
		required: true,
		minLength: 17,
		maxLength: 17,
	});
	const checkedVinNumber = useBackendFieldCheck(
		vinNumber.value,
		onValidateInstanceVinNumber,
		validatedVinNumber,
		{ itemId },
	);
	const mileage = useWizardFormField(isCar ? instanceData.itemCar.mileageKm : '', {
		required: true,
		isNumeric: true,
		minAmount: 0,
		maxAmount: 2147483647,
	});
	const co2GramsPerKilometer = useWizardFormField(
		isCar ? instanceData.itemCar.gramsPerKilometer : '',
		{ isNumeric: true, maxAmount: 2147483647 },
	);

	//Parking Lot
	const isParking = isObject(instanceData?.itemParkingLot);
	const parkingNumber = useWizardFormField(
		isParking ? instanceData.itemParkingLot.parkingNumber : '',
		{ required: true, maxLength: 16 },
	);
	const checkedParkingNumber = useBackendFieldCheck(
		parkingNumber.value,
		onValidateInstanceParkingNumber,
		validatedParkingNumber,
		{ itemId },
	);

	//Assets
	const isAsset = isObject(instanceData?.itemAsset);
	const assetNumber = useWizardFormField(isAsset ? instanceData.itemAsset.assetNumber : '', {
		required: true,
		maxLength: 16,
	});
	const checkedAssetNumber = useBackendFieldCheck(
		assetNumber.value,
		onValidateInstanceAssetNumber,
		validatedAssetNumber,
		{ itemId },
	);

	// Boat
	const isBoat = isObject(instanceData?.itemBoat);
	const boatNumber = useWizardFormField(isBoat ? instanceData.itemBoat.boatNumber : '', {
		required: true,
		minLength: 1,
		maxLength: 16,
	});
	const checkedBoatNumber = useBackendFieldCheck(
		boatNumber.value,
		onValidateInstanceBoatNumber,
		validateBoatNumber,
		{ itemId },
	);
	const boatBrand = useWizardFormField(isBoat ? instanceData.itemBoat.brand : '', {
		required: true,
		maxLength: 16,
	});
	const boatModel = useWizardFormField(isBoat ? instanceData.itemBoat.model : '', {
		required: true,
		maxLength: 32,
	});

	const isFieldChanged =
		bikeNumber.isChanged ||
		frameNumber.isChanged ||
		officeRoomNumber.isChanged ||
		licensePlate.isChanged ||
		carBrand.isChanged ||
		model.isChanged ||
		vinNumber.isChanged ||
		mileage.isChanged ||
		co2GramsPerKilometer.isChanged ||
		parkingNumber.isChanged ||
		trailerNumber.isChanged ||
		trailerVinNumber.isChanged ||
		assetNumber.isChanged ||
		boatNumber.isChanged ||
		boatBrand.isChanged ||
		boatModel.isChanged;

	const itemInstanceFields = [
		...(selectedCategory === categories.BIKES ?
			[
				{
					name: 'bikeNumber',
					label: t('ui.label.bikeNumber'),
					value: bikeNumber,
					isExtraValid: checkedBikeNumber.isUnique,
					extraValidHelperText: t('views.addItem.addInstance.error.bikeNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.bikeNumber'),
				},
				{
					name: 'frameNumber',
					label: t('ui.label.frameNumber'),
					value: frameNumber,
					maxLength: 32,
					isExtraValid: checkedFrameNumber.isUnique,
					extraValidHelperText: t('views.addItem.addInstance.error.frameNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.frameNumber'),
				},
			]
		:	[]),
		...(selectedCategory === categories.OFFICES ?
			[
				{
					name: 'roomNumber',
					label: t('ui.label.roomNumberInstance'),
					value: officeRoomNumber,
					isExtraValid: checkedRoomNumber.isUnique,
					extraValidHelperText: t('views.addItem.addInstance.error.roomNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.roomNumber'),
				},
			]
		:	[]),
		...(selectedCategory === categories.CARS ?
			[
				{
					name: 'licensePlate',
					label: t('ui.label.licensePlate'),
					value: licensePlate,
					isExtraValid: checkedLicensePlate.isUnique,
					extraValidHelperText: t('views.addItem.addInstance.error.licensePlateAlreadyInUse'),
					placeholder: t('ui.placeholder.licensePlate'),
					maxLength: licensePlateLength,
				},
				{
					name: 'brand',
					label: t('ui.label.brand'),
					value: carBrand,
					placeholder: t('ui.placeholder.brand'),
				},
				{
					name: 'model',
					label: t('ui.label.model'),
					value: model,
					placeholder: t('ui.placeholder.model'),
				},
				{
					name: 'vinNumber',
					label: t('ui.label.vinNumber'),
					value: vinNumber,
					isExtraValid: checkedVinNumber.isUnique,
					extraValidHelperText: t('views.addItem.addInstance.error.vinNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.vinNumber'),
					maxLength: 17,
				},
				...(!isDeviceMileagePresent ?
					[
						{
							name: 'currentHectometers',
							label: t('ui.label.mileage'),
							value: mileage,
							placeholder: t('ui.placeholder.mileage'),
						},
					]
				:	[]),
				{
					name: 'gramsPerKilometer',
					label: t('ui.label.co2GramsPerKilometer'),
					value: co2GramsPerKilometer,
				},
			]
		:	[]),
		...(selectedCategory === categories.TRAILERS ?
			[
				{
					name: 'trailerNumber',
					label: t('ui.label.trailerNumber'),
					maxLength: 32,
					value: trailerNumber,
					isExtraValid: checkedTrailerNumber.isUnique,
					extraValidHelperText: t('views.addItem.addInstance.error.trailerNumberAlreadyInUse'),
					placeholder: t('ui.label.trailerNumber'),
				},
				{
					name: 'vinNumber',
					label: t('ui.label.vinNumber'),
					value: trailerVinNumber,
					maxLength: 17,
					isExtraValid: checkedTrailerVinNumber.isUnique,
					extraValidHelperText: t('views.addItem.addInstance.error.vinNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.vinNumber'),
				},
			]
		:	[]),
		...(selectedCategory === categories.PARKINGLOTS ?
			[
				{
					name: 'parkingNumber',
					label: t('ui.label.parking.parkingNumber'),
					maxLength: 16,
					isExtraValid: checkedParkingNumber.isUnique,
					value: parkingNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.parkingNumberAlreadyInUse'),
					placeholder: t('ui.label.parkingNumber.placeholder'),
				},
			]
		:	[]),
		...(selectedCategory === categories.ASSETS ?
			[
				{
					name: 'assetNumber',
					label: t('ui.label.parking.assetNumber'),
					maxLength: 16,
					isExtraValid: checkedAssetNumber.isUnique,
					value: assetNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.assetNumberAlreadyInUse'),
					placeholder: t('ui.label.assetNumber.placeholder'),
				},
			]
		:	[]),
		...(selectedCategory === categories.BOATS ?
			[
				{
					name: 'boatNumber',
					label: t('ui.label.registrationNumber'),
					value: boatNumber,
					isExtraValid: checkedBoatNumber.isUnique,
					extraValidHelperText: t('views.addItem.addInstance.error.registrationNumberAlreadyInUse'),
					placeholder: t('ui.label.registrationNumber'),
					maxLength: 16,
				},
				{
					name: 'brand',
					label: t('ui.label.brand'),
					value: boatBrand,
					placeholder: t('ui.placeholder.brand'),
				},
				{
					name: 'model',
					label: t('ui.label.model'),
					value: boatModel,
					placeholder: t('ui.placeholder.model'),
				},
			]
		:	[]),
	];

	const resetToInitialState = () => {
		if (isBike) {
			bikeNumber.resetToInitialValue();
			frameNumber.resetToInitialValue();
			onResetStateCondition('validatedBikeNumber');
			onResetStateCondition('validatedFrameNumber');
		} else if (isOffice) {
			officeRoomNumber.resetToInitialValue();
			onResetStateCondition('validatedRoomNumber');
		} else if (isCar) {
			licensePlate.resetToInitialValue();
			carBrand.resetToInitialValue();
			model.resetToInitialValue();
			vinNumber.resetToInitialValue();
			mileage.resetToInitialValue();
			co2GramsPerKilometer.resetToInitialValue();
			onResetStateCondition('validatedLicensePlate');
			onResetStateCondition('validatedVinNumber');
		} else if (isTrailer) {
			trailerNumber.resetToInitialValue();
			trailerVinNumber.resetToInitialValue();
			onResetStateCondition('validatedTrailerNumber');
			onResetStateCondition('validatedTrailerVinNumber');
		} else if (isParking) {
			parkingNumber.resetToInitialValue();
			onResetStateCondition('validatedParkingNumber');
		} else if (isAsset) {
			assetNumber.resetToInitialValue();
			onResetStateCondition('validatedAssetNumber');
		} else if (isBoat) {
			boatNumber.resetToInitialValue();
			boatBrand.resetToInitialValue();
			boatModel.resetToInitialValue();
			onResetStateCondition('validateBoatNumber');
		}
	};

	const handleClose = () => {
		onClose();
		resetToInitialState();
	};

	if (!open) {
		return null;
	}

	const formInvalid = isFullArray(
		itemInstanceFields.filter(
			(field) =>
				!field.value.isValid ||
				(!isUndefined(field.isExtraValid) && field.value.isTouched && !field.isExtraValid),
		),
	);
	const formTouched = isFullArray(itemInstanceFields.filter((field) => field.value.isTouched));

	const handleUpdateInstance = () => {
		if (!formInvalid && formTouched) {
			const itemInstances = itemInstanceFields.reduce(
				(instance, field) => ({
					...instance,
					[field.name]: field.value.value,
				}),
				{},
			);

			onPatchInstance(instanceData.itemReference.id, instanceData.id, {
				isPublic: instanceData.isPublic,
				hubId: instanceData.hubReference.id,
				...(isBike && {
					bike: { ...itemInstances },
				}),
				...(isOffice && {
					office: { ...itemInstances },
				}),
				...(isCar && {
					car: {
						...itemInstances,
						...(!isDeviceMileagePresent ?
							{ currentHectometers: itemInstances.currentHectometers * 10 }
						:	{}),
						// TODO: shouldn this mapped in a more general place?
						...{ gramsPerKilometers: itemInstances.gramsPerKilometer },
					},
				}),
				...(isTrailer && {
					trailer: { ...itemInstances },
				}),
				...(isParking && {
					parkingLot: { ...itemInstances },
				}),
				...(isAsset && {
					asset: { ...itemInstances },
				}),
				...(isBoat && {
					boat: { ...itemInstances },
				}),
			});
			updatedItemInstanceMessage.setStartAction(true);
		}
	};

	const disabled = !isFieldChanged || formInvalid;

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleUpdateInstance,
				text:
					updatedItemInstaceLoading ? <CircularProgress disableShrink size={24} /> : t('ui.save'),
			}}
			handleClose={handleClose}
			loading={disabled}
			open={open}
			title={t('views.updateItemInstance.title')}
		>
			<Box className={classes.editRoot}>
				{itemInstanceFields.map((item) => (
					<FormField
						className={classes.formSpacing}
						hideCharacterCounter={!item.value.hasFocus}
						key={item.name}
						label={item.label}
						name={item.name}
						required={item.value?.isRequired}
						variable={item.value}
						{...(!isUndefined(item.isExtraValid) && {
							isExtraValid: item.isExtraValid,
							extraValidHelperText: item.extraValidHelperText,
						})}
						{...(!isUndefined(item.maxLength) && { maxLength: item.maxLength })}
						{...(!isUndefined(item.placeholder) && { placeholder: item.placeholder })}
					/>
				))}
			</Box>
		</ActionDialog>
	);
};

EditInstanceInfo.displayName = 'EditInstanceInfo';

EditInstanceInfo.propTypes = {
	className: PropTypes.string,
	organisation: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool,

	instanceData: PropTypes.object,
	isDeviceMileagePresent: PropTypes.bool,
	onValidateInstanceBoatNumber: PropTypes.func,
	patchInstance: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedBikeLockId: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedBikeNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedFrameNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedCarLockId: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedLicensePlate: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedVinNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedRoomNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedParkingNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedAssetNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedTrailerNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedTrailerVinNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validateBoatNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onValidateInstanceRoomNumber: PropTypes.func,
	onValidateInstanceBikeLockId: PropTypes.func,
	onValidateInstanceBikeNumber: PropTypes.func,
	onValidateInstanceFrameNumber: PropTypes.func,
	onValidateInstanceLicensePlate: PropTypes.func,
	onValidateInstanceCarLockId: PropTypes.func,
	onValidateInstanceVinNumber: PropTypes.func,
	onValidateInstanceParkingNumber: PropTypes.func,
	onValidateInstanceAssetNumber: PropTypes.func,
	onValidateInstanceTrailerNumber: PropTypes.func,
	onValidateInstanceTrailerVinNumber: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	onPatchInstance: PropTypes.func,
	onUpdateDetailsState: PropTypes.func,
};

EditInstanceInfo.defaultProps = {
	open: false,
	onClose: () => {},
};

const mapStateToProps = (state) => {
	return {
		patchInstance: state.details.patchInstance,
		validatedRoomNumber: state.condition.validatedRoomNumber,
		validatedBikeLockId: state.condition.validatedBikeLockId,
		validatedBikeNumber: state.condition.validatedBikeNumber,
		validatedFrameNumber: state.condition.validatedFrameNumber,
		validatedCarLockId: state.condition.validatedCarLockId,
		validatedLicensePlate: state.condition.validatedLicensePlate,
		validatedVinNumber: state.condition.validatedVinNumber,
		validatedParkingNumber: state.condition.validatedParkingNumber,
		validatedAssetNumber: state.condition.validatedAssetNumber,
		validatedTrailerNumber: state.condition.validatedTrailerNumber,
		validatedTrailerVinNumber: state.condition.validatedTrailerVinNumber,
		validateBoatNumber: state.condition.validateBoatNumber,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onPatchInstance: (itemId, instanceId, properties) =>
			dispatch(actions.patchInstance(itemId, instanceId, properties)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
		onValidateInstanceRoomNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceRoomNumber(itemId, value)),
		onValidateInstanceBikeLockId: ({ itemId, value }) =>
			dispatch(actions.validateInstanceBikeLockId(itemId, value)),
		onValidateInstanceBikeNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceBikeNumber(itemId, value)),
		onValidateInstanceFrameNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceFrameNumber(itemId, value)),
		onValidateInstanceLicensePlate: ({ itemId, value }) =>
			dispatch(actions.validateInstanceLicensePlate(itemId, value)),
		onValidateInstanceCarLockId: ({ itemId, value }) =>
			dispatch(actions.validateInstanceCarLockId(itemId, value)),
		onValidateInstanceVinNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceVinNumber(itemId, value)),
		onValidateInstanceParkingNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceParkingNumber(itemId, value)),
		onValidateInstanceAssetNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceAssetNumber(itemId, value)),
		onValidateInstanceTrailerNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceTrailerNumber(itemId, value)),
		onValidateInstanceTrailerVinNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceTrailerVinNumber(itemId, value)),
		onValidateInstanceBoatNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceBoatNumber(itemId, value)),
		onResetStateCondition: (state) => dispatch(actions.resetStateCondition(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInstanceInfo);
