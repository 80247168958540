import { useAtom } from 'jotai';
import { RouteObject } from 'react-router-dom';

import { pagePathSegments } from '~constants';
import { PageLayout } from '~layouts';
import i18n from '~lib/i18n';
import {
	AccessLogsPage,
	AccessRuleDetailsPage,
	AccessRulesPage,
	AccessSchedulesPage,
	SkcDeviceGroupsPage,
	SkcUserGroupsPage,
} from '~pages';

const skcAccessRoutes = (): RouteObject[] => [
	{
		path: pagePathSegments.Access,
		element: (
			<PageLayout
				title={i18n.t('nav.category.accessManagement')}
				pages={[
					{ label: i18n.t('rules'), path: pagePathSegments.Rules },
					{ label: i18n.t('logs'), path: pagePathSegments.Logs },
					{ label: i18n.t('deviceGroups'), path: pagePathSegments.DeviceGroups },
					{ label: i18n.t('userGroups'), path: pagePathSegments.UserGroups },
					{ label: i18n.t('schedules'), path: pagePathSegments.Schedules },
				]}
				// tempEl={
				// 	<OrganisationAutocomplete
				// 		value={selectedOrganisation}
				// 		onChange={(_, value) => setSelectedOrganisation(value)}
				// 		size='small'
				// 		sx={{ width: 220 }}
				// 	/>
				// }
			/>
		),
		children: [
			{
				path: pagePathSegments.Rules,
				element: <AccessRulesPage />,
			},
			{
				path: pagePathSegments.Logs,
				element: <AccessLogsPage />,
			},
			{
				path: pagePathSegments.DeviceGroups,
				element: <SkcDeviceGroupsPage />,
			},
			{
				path: pagePathSegments.UserGroups,
				element: <SkcUserGroupsPage />,
			},
			{
				path: pagePathSegments.Schedules,
				element: <AccessSchedulesPage />,
			},
		],
	},
	{
		path: pagePathSegments.Access,
		children: [
			{
				path: pagePathSegments.Rules,
				children: [
					{
						path: ':id',
						element: (
							<PageLayout
								title={i18n.t('nav.category.accessManagement')}
								pages={[
									{ label: i18n.t('summary'), path: '' },
									{ label: i18n.t('devices'), path: pagePathSegments.Devices },
									{ label: i18n.t('users'), path: pagePathSegments.Users },
								]}
							/>
						),
						children: [
							{
								index: true,
								element: <AccessRuleDetailsPage />,
							},
						],
					},
				],
			},
		],
	},
];

export default skcAccessRoutes;
