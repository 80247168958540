import { BaseReference } from '~interfaces';
import { BaseReferenceResponse } from '~interfaces/responses';

class BaseServiceMapper {
	static fromBaseReferenceResponse(response: BaseReferenceResponse): BaseReference {
		const { id, name } = response;

		return {
			label: name,
			id: id.toString(),
		};
	}
}

export default BaseServiceMapper;
