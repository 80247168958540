/* eslint-disable no-case-declarations */
export const itemsReducer = (state, action) => {
	const { item } = action;

	const handleFilterItem = (consumer) => {
		const filteredObj = Object.fromEntries(
			// eslint-disable-next-line no-unused-vars
			Object.entries(consumer).filter(
				([key, value]) => !['items', 'item', 'selected', 'selecteAll'].includes(key),
			),
		);
		return filteredObj;
	};

	switch (action.type) {
		case 'addItem':
			const updateItem = item.items.map((upItem) => ({ ...upItem, ...handleFilterItem(item) }));
			const newState = state.concat(updateItem);
			return newState;
		case 'removeItem':
			return state.filter(
				(rItem) =>
					!(
						rItem.consumerId === item.consumerId &&
						rItem.id === item.item.id &&
						rItem.type === item.type &&
						rItem.userGroup?.id === item.userGroup?.id &&
						rItem?.pageNumber === item?.pageNumber
					),
			);
		case 'addAllItems':
			const updateAllItems = item.items.map((upItem) => ({
				...handleFilterItem(item),
				id: upItem.item.id,
				name: upItem.item.name,
				userGroup: upItem.userGroup,
			}));

			const newItems = updateAllItems.filter((newItem) => {
				const matchingItem = state.find(
					(existingItem) =>
						existingItem.id === newItem.id &&
						existingItem.userGroup?.id === newItem.userGroup?.id &&
						existingItem.type === newItem.type &&
						existingItem.consumerId === newItem.consumerId,
				);
				return !matchingItem;
			});
			return state.concat(newItems);
		case 'removeAllItems':
			return state.filter(
				(removeItem) =>
					!(
						removeItem.consumerId === item.consumerId &&
						removeItem.type === item.type &&
						removeItem?.pageNumber === item?.pageNumber
					),
			);
		default:
			return [];
	}
};

export const tableHeaders = [
	{ name: 'itemGroep', content: 'ui.label.item' },
	{ name: 'qty', content: 'ui.qty' },
	{ name: 'userGroup', content: 'ui.label.userGroup' },
	{ name: 'terms', content: 'views.addItem.steps.sidePanelDetails.settings.terms' },
	{ name: 'price', content: 'views.management.header.pricing' },
	{ name: 'businessPrice', content: 'ui.label.businessPrice' },
	{ name: 'policy', content: 'views.addItem.settings.subsection.policy.title' },
];

export const editTypes = {
	TERMS: 'terms',
	PRICING: 'pricing',
	POLICY: 'policy',
};

export const sharingTypes = {
	INTERNAL: 'internal',
	PUBLIC: 'public',
	EXTERNAL: 'external',
};
