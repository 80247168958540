import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { LoadingBar } from '../../../components';

const SelectedDeviceCard = (props) => {
	const { name, hasCloseButton, handleClose, loading, deviceType, version } = props;
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography variant='h5'>
					{loading ?
						<>
							<LoadingBar />
							<LoadingBar />
						</>
					:	name}
				</Typography>
				<Typography variant='body2'>
					{loading ?
						<>
							<LoadingBar />
							<LoadingBar />
						</>
					:	deviceType}
				</Typography>
				<Typography variant='body2'>
					{loading ?
						<>
							<LoadingBar />
							<LoadingBar />
						</>
					:	version}
				</Typography>
			</CardContent>
			{hasCloseButton ?
				<IconButton className={classes.closeButton} onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			:	null}
		</Card>
	);
};

SelectedDeviceCard.propTypes = {
	name: PropTypes.string,
	address: PropTypes.string,
	image: PropTypes.string,
	hasCloseButton: PropTypes.bool,
	handleClose: PropTypes.func,
	loading: PropTypes.bool,
	deviceType: PropTypes.string,
	version: PropTypes.string,
};

SelectedDeviceCard.defaultProps = {
	hasCloseButton: true,
};

export default SelectedDeviceCard;
