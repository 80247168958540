import { FETCH_BLOB_START, FETCH_BLOB_SUCCESS, FETCH_BLOB_FAIL } from '../actions/actionTypes';
import { blobStates } from '../states';
// import { isObject } from '../../shared/utility';

const initialState = blobStates.reduce(
	(state, stateName) => ({
		...state,
		[stateName]: { data: null, loading: false, error: null },
	}),
	{},
);

/* * * * * * * * * * * * * * * * * * * * * * * * *
 * METHODS THAT UPDATE STATES WITH CORRECT VALUES *
 * * * * * * * * * * * * * * * * * * * * * * * *  */

const fetchBlobStart = (state, action) => ({
	...state,
	...{ [action.identifier]: { ...state[action.identifier], ...{ loading: true } } },
});

const fetchBlobSuccess = (state, action) => ({
	...state,
	...{
		[action.identifier]: {
			...state[action.identifier],
			...{
				data: action.data,
				loading: false,
				error: null,
			},
		},
	},
});

const fetchBlobFail = (state, action) => ({
	...state,
	...{
		[action.identifier]: {
			...state[action.identifier],
			...{ data: null, success: false, loading: false, error: action.error },
		},
	},
});

/* * * * * * * * *
 * REDUCER METHOD *
 * * * * * * * *  */
// Depending on the active action type, execute the correct function to update the correct states with the correct values
// Pass two parameters, first is the old state values, second is the new state values, these are passed from actions
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_BLOB_START:
			return fetchBlobStart(state, action);
		case FETCH_BLOB_SUCCESS:
			return fetchBlobSuccess(state, action);
		case FETCH_BLOB_FAIL:
			return fetchBlobFail(state, action);
		default:
			return state;
	}
};

export default reducer;
