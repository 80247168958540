import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	formGroup: {
		'& + &': {
			marginTop: theme.spacing(4),
		},
		'&:last-child': {
			marginBottom: theme.spacing(3),
		},
	},
	selectWithLabel: {
		'& .MuiInputBase-input': {
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
	menuItem: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));
