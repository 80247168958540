import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, 30, 0, 30),
		'& .fc-day-header': {
			padding: theme.spacing(1),
		},
		'& .fc-day.fc-widget-content.fc-today': {
			backgroundColor: 'transparent',
		},
		'& .fc-content': {
			...theme.typography,
			color: '#ffffff',
			fontWeight: 500,
		},
		'& .fc-widget-header': {
			...theme.typography.h6,
		},
		'& .fc-event': {
			cursor: 'pointer',
		},
	},
	timeSection: {
		marginTop: '24px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	drawer: {
		width: 450,
		maxWidth: '100%',
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'&  .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
			width: '5rem',
		},
	},
	divider: {
		margin: theme.spacing(4.5, 0),
	},
	allDaySpacing: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	removeButton: {
		marginTop: theme.spacing(1),
		color: theme.palette.white,
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
}));
