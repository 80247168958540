import path from 'path';

import { AxiosResponse } from 'axios';

import { UserRoleEnum } from '~enums';
import { NfcTag } from '~features/nfc';
import { BaseReference, IdReference, PagedResponse } from '~interfaces';
import { AddressResponse, BaseReferenceResponse, IdReferenceResponse } from '~interfaces/responses';
import { BaseServiceMapper, TopologyService } from '~services';

import UserIdentity from '../interfaces/userIdentity';

/**
 * A service to get identiy data about users. E.g. user data from
 * the Identity service
 */
class UserIdentitiesService extends TopologyService {
	public readonly path = 'identityusers';

	constructor() {
		super('v1');
	}

	/**
	 * Get details about a specific user
	 * @param id
	 * @returns
	 */
	async getUserIdentityById(id: string): Promise<UserIdentity> {
		const { data } = await this._client.get<UserIdentityResponse>(path.join(this.path, id));

		return UserIdentityServiceMapper.fromResponse(data);
	}

	/**
	 * Create an anonymous user.
	 * WIP!!
	 * @param data
	 * @returns
	 */
	async createAnonymousUser(data: AnonymousUser): Promise<null> {
		const content = UserIdentityServiceMapper.toAnonymousUserCreateRequest(data);
		const response = await this._client.post<
			null,
			AxiosResponse<null, AnonymousUserCreateRequest>,
			AnonymousUserCreateRequest
		>(path.join(this.path, 'anonymous'), content);

		return response.data;
	}

	async disableUser(id: string): Promise<null> {
		const { data } = await this._client.put(path.join(this.path, id, 'disable'));

		return data;
	}
}

class UserIdentityServiceMapper {
	static fromResponse(data: UserIdentityResponse): UserIdentity {
		const { id, lastLogin, lastUserAgent, providerName, ...rest } = data;

		return {
			...rest,
			id: id.toString(),
			LatestLogin: lastLogin ? new Date(lastLogin) : undefined,
			provider: providerName,
			LatestUserAgent: lastUserAgent,
		};
	}

	static toAnonymousUserCreateRequest(data: AnonymousUser): AnonymousUserCreateRequest {
		const { organisation, userGroup, ...rest } = data;

		return {
			...rest,
			organisationId: organisation.id,
			userGroupId: userGroup?.id,
		};
	}
}

interface AnonymousUser {
	organisation: BaseReference;
	userGroup?: BaseReference;
	firstName: string;
	lastName: string;
}

interface AnonymousUserCreateRequest {
	firstName: string;
	lastName: string;
	nfcId?: string;
	cardNumber?: string;
	organisationId: string | number;
	userGroupId?: string | number;
	tagType?: 'none' | 'skopei' | 'skopeiEncryped';
}

interface UserIdentityResponse extends IdReferenceResponse {
	email: string;
	providerName?: string;
	lastLogin?: string;
	lastUserAgent?: string;
}

export default UserIdentitiesService;
