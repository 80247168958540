import { memo } from 'react';

import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import BlockIcon from '@mui/icons-material/Block';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Card, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CalendarInfoPopup from './CalendarInfoPopup';
import { useStyles } from './style';
import { Chart, InfoPopup, LoadingBar } from '../../../components';
import { isEarlierThan, sameDates, modifyDate } from '../../../shared/datetime';
import { isObject, handleHubReference, isSuperAdmin, isNumber } from '../../../shared/utility';

const propsAreEqual = (prevProps, nextProps) => {
	return (
		prevProps.className === nextProps.className &&
		JSON.stringify(prevProps.resourceArray) === JSON.stringify(nextProps.resourceArray) &&
		JSON.stringify(prevProps.eventArray) === JSON.stringify(nextProps.eventArray) &&
		JSON.stringify(prevProps.events) === JSON.stringify(nextProps.events) &&
		JSON.stringify(prevProps.chartData) === JSON.stringify(nextProps.chartData)
	);
};

const FullCalendarResourceTimeline = (props) => {
	const {
		chartData,
		resourceArray,
		eventArray,
		events,
		calendarRef,

		currentUser,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();
	const classes = useStyles();
	const navigate = useNavigate();

	/* * * * * * * * * *
	 * UTILITY METHODS *
	 * * * * * * * * * */
	const getDateTimeString = (dateTime, stringifyTimeOnly = false) => {
		const date = new Date(dateTime);
		const monthsArray = [
			t('ui.months.january'),
			t('ui.months.february'),
			t('ui.months.march'),
			t('ui.months.april'),
			t('ui.months.may'),
			t('ui.months.june'),
			t('ui.months.july'),
			t('ui.months.august'),
			t('ui.months.september'),
			t('ui.months.october'),
			t('ui.months.november'),
			t('ui.months.december'),
		];

		const dateString = `${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}, ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
		const timeString = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
		return stringifyTimeOnly ? timeString : dateString;
	};

	/* * * * * * * * * * * * * *
	 * RESOURCE RENDER METHOD  *
	 * * * * * * * * * * * * * */
	const renderResource = (info) => {
		const resource = info.resource?._resource?.extendedProps;

		const resourceClassName = resource?.className;
		const childOrSingleTrack =
			resourceClassName === 'child-track' || resourceClassName === 'single-track';

		const resourceAddress =
			info.resource?._resource?.extendedProps?.hubReference ?
				`${handleHubReference(info.resource?._resource?.extendedProps?.hubReference)}`
			:	null;
		const itemPathname =
			resource.className === 'child-track' ?
				`/item-management/${info.resource._resource.parentId}/instance/${info.resource._resource.id.split('-')[1]}/summary`
			:	`/item-management/items/${info.resource._resource.id}/summary`;

		const resourceNeedsAttention = resourceClassName === 'parent-track' &&
			resource?.needsAttention && (
				<InfoPopup
					heading={t('views.planboard.info.attentionRequired.title')}
					title={t('views.planboard.info.attentionRequired.description')}
				>
					<ErrorOutlineIcon className={classes.warningIcon} size='small' />
				</InfoPopup>
			);

		const resourceUnavailable = childOrSingleTrack &&
			resource?.availabilityState === 'isUnavailable' && (
				<InfoPopup
					heading={t('views.planboard.info.attentionUnavailable.title')}
					title={t('views.planboard.info.attentionUnavailable.description')}
				>
					<BlockIcon className={classes.warningIcon} size='small' />
				</InfoPopup>
			);

		const resourceOvertime = childOrSingleTrack &&
			resource?.availabilityState === 'hasOvertimeBooking' && (
				<InfoPopup
					heading={t('views.planboard.info.attentionOvertime.title')}
					title={t('views.planboard.info.attentionOvertime.description')}
				>
					<AccessTimeIcon className={classes.warningIcon} size='small' />
				</InfoPopup>
			);

		return (
			<>
				{resourceClassName === 'loading-track' ?
					<span className={'loading-track'}>
						<LoadingBar />
					</span>
				:	<>
						<div className={classes.resourceText}>
							<Typography
								className={classes.tableLink}
								onClick={() => navigate(itemPathname, { state: { from: '/planboard' } })}
							>
								{info.fieldValue}
							</Typography>
							{resourceAddress && <Typography variant={'body2'}>{resourceAddress}</Typography>}
						</div>
						<div>
							{resourceNeedsAttention}
							{resourceUnavailable}
							{resourceOvertime}
						</div>
					</>
				}
			</>
		);
	};

	/* * * * * * * * * * * *
	 * EVENT RENDER METHOD *
	 * * * * * * * * * * * */
	const eventRender = (eventObject) => {
		const eventToRender = eventArray.find(
			(event) => `${event.id}` === eventObject.event._def.publicId,
		);

		const bookingPeriod =
			sameDates(new Date(eventToRender?.start), new Date(eventToRender?.end), false, true) ?
				getDateTimeString(eventToRender?.start, true).concat(
					' - ',
					getDateTimeString(eventToRender?.end, true),
				)
			:	getDateTimeString(eventToRender?.start).concat(' - ', getDateTimeString(eventToRender?.end));
		const displayTimeAndDetail =
			eventToRender?.title !== 'cooldown' &&
			eventToRender?.access === 'full' &&
			eventToRender?.status !== 'overtime';
		const isUnavailability = eventToRender?.title === 'unavailability';

		return (
			<div
				className={clsx({
					[classes.overlap]: true,
					[eventToRender?.className]: !!eventToRender?.className,
					[classes.unclickable]: eventToRender?.isBeingUpdated,
				})}
			>
				{displayTimeAndDetail && (
					<>
						<CalendarInfoPopup
							heading={t('views.planboard.info.bookingDetails.title')}
							title={`${bookingPeriod}, ${eventToRender.title}`}
						/>
						<div className='fc-event-time'> {bookingPeriod} </div>
						<div className='fc-event-title-container'>
							<div className='fc-event-title fc-sticky'> {eventToRender?.title} </div>
						</div>
					</>
				)}
				{eventToRender?.title === 'cooldown' && (
					<div>
						<CalendarInfoPopup
							heading={`${eventToRender.cooldownBufferMinutes} 
								${t('views.planboard.info.bufferPeriod.title')}`}
							title={t('views.planboard.info.bufferPeriod.description')}
						/>
					</div>
				)}
				{eventToRender?.access !== 'full' && !isUnavailability && (
					<>
						{eventToRender?.status === 'overtime' ?
							<CalendarInfoPopup
								heading={t('views.planboard.info.obscuredOvertimeBooking.title')}
								title={t('views.planboard.info.obscuredOvertimeBooking.description')}
							/>
						:	<InfoPopup
								heading={t('views.planboard.info.obscuredBooking.title')}
								title={t('views.planboard.info.obscuredBooking.description')}
							>
								<Box className={'infoPopupHoverSpan'} />
							</InfoPopup>
						}
						<div className='fc-event-time'> {bookingPeriod}</div>
						<VisibilityIcon className={classes.bookingIcon} />
					</>
				)}
				{isUnavailability && (
					<>
						<div className='fc-event-time'> {bookingPeriod}</div>
						<Box alignItems='center' display='flex'>
							<InfoPopup
								heading={t('views.planboard.info.unavailability.title')}
								title={`${bookingPeriod}${eventObject.event._def?.extendedProps?.adminNote ? ',' : ''} ${eventObject.event._def?.extendedProps?.adminNote ? eventObject.event._def?.extendedProps?.adminNote : ''}`}
							>
								<Box className={'infoPopupHoverSpan'} />
							</InfoPopup>
							<AssignmentTurnedInOutlinedIcon className={classes.bookingIcon} />
							<Box className='fc-event-title-container' color='#15263E' pl={1}>
								{
									<Typography className={classes.unavailability}>
										{eventObject?.event?._def.publicId}
									</Typography>
								}
							</Box>
						</Box>
					</>
				)}
			</div>
		);
	};

	/* * * * * * * * * * * * * * * *
	 * AVAILABILITY GRAPH METHODS  *
	 * * * * * * * * * * * * * * * */
	const parentTrackContent = (dataObject) => {
		const chartObjectId = dataObject?.resource?._resource?.id;
		const isParentTrack =
			dataObject?.resource?._resource?.extendedProps?.className === 'parent-track';

		return (
			!!chartObjectId &&
			isParentTrack &&
			isObject(chartData) &&
			isObject(chartData[chartObjectId]) && (
				<Chart
					className={classes.chart}
					data={chartData[chartObjectId]}
					key={chartData[chartObjectId]}
					labels={[
						'1',
						'2',
						'3',
						'4',
						'5',
						'6',
						'7',
						'8',
						'9',
						'10',
						'11',
						'12',
						'13',
						'14',
						'15',
						'16',
						'17',
						'18',
						'19',
						'20',
						'21',
						'22',
						'23',
						'24',
						'25',
						'26',
						'27',
						'28',
						'29',
						'30',
						'31',
						'32',
						'33',
						'34',
						'35',
						'36',
						'37',
						'38',
						'39',
						'40',
						'41',
						'42',
						'43',
						'44',
						'45',
						'46',
						'47',
						'48',
						'49',
					]}
				/>
			)
		);
	};

	return (
		<Card className={classes.root}>
			<FullCalendar
				displayEventEnd
				displayEventTime
				editable
				eventAllow={(dropInfo, draggedEvent) => {
					return (
						dropInfo.resource.id.split('-')[0] === draggedEvent._def.resourceIds[0].split('-')[0] &&
						!isEarlierThan(dropInfo.start) &&
						!(
							!isSuperAdmin(auth.user?.profile.role) && //Require external admins to make bookings that require confirmation at least 15 minutes in advance
							isNumber(
								dropInfo.resource._resource.extendedProps.hubReference?.organisationReference?.id,
							) &&
							currentUser !==
								dropInfo.resource._resource.extendedProps.hubReference?.organisationReference?.id &&
							dropInfo.resource._resource.extendedProps.policies?.requireHostConfirmation &&
							isEarlierThan(dropInfo.start, modifyDate(new Date(), { minutes: '+15' }))
						) &&
						(!dropInfo.resource._resource.extendedProps.availabilityState ||
							dropInfo.resource._resource.extendedProps.availabilityState === 'available' ||
							dropInfo.resource._resource.extendedProps.availabilityState === 'hasOvertimeBooking')
					);
				}}
				eventClassNames={classes.events}
				eventClick={events.clickEvent}
				eventContent={eventRender}
				eventDrop={events.dropEvent}
				eventOverlap={(stillEvent, movingEvent) => {
					return stillEvent.id.split('-')[1] === movingEvent.id;
				}}
				eventResizableFromStart={true}
				eventResize={events.resizeEvent}
				eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
				events={eventArray}
				headerToolbar={false}
				height={'100%'}
				initialView='resourceTimeline'
				locale='en-GB'
				nowIndicator={true}
				plugins={[resourceTimelinePlugin, interactionPlugin]}
				ref={calendarRef}
				resourceAreaHeaderContent={' '}
				resourceAreaWidth={'25%'}
				resourceLabelContent={renderResource}
				resourceLaneContent={parentTrackContent}
				resources={resourceArray}
				resourcesInitiallyExpanded={false}
				schedulerLicenseKey={import.meta.env.VITE_FULLCALENDAR_LICENSE_KEY}
				scrollTime={moment().add(-2, 'hours').format('HH:mm:ss')}
				select={events.select}
				selectAllow={(selectInfo) => {
					return (
						(!selectInfo.resource.extendedProps.className ||
							selectInfo.resource.extendedProps.className !== 'parent-track') &&
						!isEarlierThan(selectInfo.start) &&
						(!selectInfo.resource._resource.extendedProps.availabilityState ||
							selectInfo.resource._resource.extendedProps.availabilityState === 'available' ||
							selectInfo.resource._resource.extendedProps.availabilityState ===
								'hasOvertimeBooking')
					);
				}}
				selectable
				slotEventOverlap={true}
				slotLabelClassNames={classes.slotLabel}
				slotLabelFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
				slotLabelInterval={'00:30'}
				slotMinWidth={30}
			/>
		</Card>
	);
};

FullCalendarResourceTimeline.propTypes = {
	className: PropTypes.string,
	chartData: PropTypes.object,
	resourceArray: PropTypes.array,
	eventArray: PropTypes.array,
	events: PropTypes.object,
	calendarRef: PropTypes.object,
	refreshing: PropTypes.bool,

	currentUser: PropTypes.number,
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser?.data?.organisationReference.id,
	};
};

export default memo(connect(mapStateToProps, null)(FullCalendarResourceTimeline), propsAreEqual);
