import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles, Accordion, AccordionDetails, AccordionSummary } from './style';

const PanelExpansion = (props) => {
	const { className, name, children } = props;

	const classes = useStyles();

	return (
		<Box className={clsx(classes.root, className)}>
			<Accordion>
				<AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
					<AddCircleOutlineIcon className={classes.createNewGroup} fontSize='large' />
					<Typography className={classes.createNewGroup}>{name}</Typography>
				</AccordionSummary>
				<AccordionDetails>{children}</AccordionDetails>
			</Accordion>
		</Box>
	);
};

PanelExpansion.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	children: PropTypes.node,
};

export default PanelExpansion;
