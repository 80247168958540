import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	tableContainer: {
		height: 520,
	},
	tableRow: {
		height: 51.91,
		'&.Mui-selected': {
			backgroundColor: '#f5f6f8',
			'&:hover': {
				backgroundColor: '#f5f6f8',
			},
		},
	},
	avatarCell: {
		width: 80,
	},
	paginationContainer: {
		marginTop: theme.spacing(3),
		height: 40,
	},
	emptyState: {
		padding: 0,
		paddingTop: theme.spacing(2),
	},
	avatar: {
		'& img': {
			objectFit: 'scale-down',
		},
	},
}));
