import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const CustomExpansionPanel = (props) => {
	const { title, children } = props;
	const classes = useStyles();

	return (
		<Accordion className={classes.expansionPanel} defaultExpanded elevation={0} square>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant='h5'>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

CustomExpansionPanel.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

export default CustomExpansionPanel;
