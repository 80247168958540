import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiAutocomplete-popper': {
			display: 'none',
		},
	},
	chipInputContainer: {
		display: 'flex',
		marginBottom: theme.spacing(3),
	},
	inputRoot: {
		'&.MuiAutocomplete-inputRoot': {
			paddingLeft: 14,
			paddingTop: 0,
		},
	},
	chip: {
		marginLeft: 5,
		'&:focus': {
			backgroundColor: colors.blue[300],
		},
		'&:hover': {
			backgroundColor: '#CECECE',
		},
	},
	existingChip: {
		backgroundColor: colors.red[100],
		color: colors.red[800],
		'&:hover': {
			backgroundColor: colors.red[300],
		},
	},
	addButton: {
		marginLeft: theme.spacing(1),
		maxHeight: 51,
		alignSelf: 'flex-end',
	},
}));
