import { Card, CardHeader, CardContent } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { SubscriptionSelectField } from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';

const Subscription = (props) => {
	const { className, save } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const subscription = useWizardFormField('', { required: true });
	useDebouncedWizardSave('subscription', subscription.value, subscription.isValid, save, 300);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader
				subheader={t('views.register.organisation.setSubscription.subtitle')}
				title={t('views.register.organisation.setSubscription.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					<div className={classes.formGroup}>
						<SubscriptionSelectField variable={subscription} />
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

Subscription.propTypes = {
	className: PropTypes.string,

	save: PropTypes.func,
};

export default Subscription;
