import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	messageCard: {
		backgroundColor: colors.indigo[50],
		border: `1px solid ${theme.palette.primary.light}`,
		boxShadow: 'none',
		padding: theme.spacing(1),
		display: 'flex',
		color: theme.palette.primary.main,
	},
	infoIcon: {
		marginRight: theme.spacing(1),
	},
}));
