import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		padding: theme.spacing(4.5, 4.5, 4.5, 4.5),
	},
	listComponent: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: theme.palette.primary.main,
		},
	},
	option: {
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: '24px',
		display: 'flex',
		alignItems: 'flex-start',
		padding: theme.spacing(1, 2, 1, 2),
		margin: theme.spacing(0, 2, 2, 0),
		maxWidth: '220px',
	},
	selectedOption: {
		borderColor: theme.palette.primary.main,
	},
	optionRadio: {
		margin: -10,
	},
	optionDetails: {
		marginLeft: theme.spacing(2),
	},
	additionalCharactaristics: {
		marginTop: '2.5rem',
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: theme.palette.primary.main,
		},
	},
	additional: {
		display: 'flex',
		flexDirection: 'row',
	},
	formGroup: {
		paddingRight: '100px',
	},
	slider: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	sliderItem: {
		maxWidth: '600px',
	},
	labelSpacing: {
		padding: theme.spacing(4, 2, 2, 0),
	},
	labelRang: {
		paddingRight: '1.2rem',
	},
	selectedController: {
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: '4px',
	},
	capacityControllers: {
		display: 'flex',
		flexDirection: 'row',
		'& .MuiListItem-root.MuiListItem-gutters': {
			width: 'auto',
			padding: theme.spacing(1, 4, 1, 0),
		},
	},
	rangeinSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingTop: '8px',
	},
	textFieldRange: {
		maxWidth: '80px',
	},
	capacitySpacing: {
		padding: theme.spacing(3, 0, 3, 0),
	},
	peopleSpacing: {
		padding: theme.spacing(1, 0, 1, 0),
		width: '100px',
	},
	cardCharacteristics: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(4.5, 4.5, 4.5, 4.5),
	},
	cardTop: {
		margin: theme.spacing(10, 10, 5, 10),
	},
	button: {
		minWidth: '12.5rem',
		minHeight: '2.5rem',
	},
	oneLine: {
		clear: 'both',
		display: 'inline-block',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
}));
