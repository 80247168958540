import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { SkcDeviceGroupsOverviewLayout } from '~features/access';

import Page from '../page';

const SkcDeviceGroupsPage = () => {
	const { t } = useTranslation('general');

	const [userInfo] = useAtom(userInfoAtom);

	return (
		<Page title={t('deviceGroups')}>
			<SkcDeviceGroupsOverviewLayout />
		</Page>
	);
};

export default SkcDeviceGroupsPage;
