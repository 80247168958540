import { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Grid, Typography, Hidden, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton, ButtonWithAlertDialog } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';
import { isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const Header = (props) => {
	const { className, loading, policy, onDeletePolicy, deletePolicy } = props;
	const location = useLocation();
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deletePolicy;
	const deletePolicyDone = deleteSuccess && !deleteLoading && !deleteError;
	const [deletingPolicy, setDeletingPolicy] = useState(false);

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => navigate('/policy-management/item-policies');

	const handleBackLabel = () => {
		if (returnButton.isBackButton) {
			return t('ui.button.inline.back');
		} else if (location?.state?.label) {
			return location?.state?.label;
		} else {
			return t('view.policymanagement.itempolicydetails.button.inline.returntoitempolicylist');
		}
	};

	useEffect(() => {
		if (deletingPolicy) {
			if (deletePolicyDone) {
				setDeletingPolicy(false);
				enqueueSnackbar(`${t('ui.successfullyDeleted')} ${policy.name}`, { variant: 'success' });
			} else if (deleteError) {
				setDeletingPolicy(false);
				enqueueSnackbar(isObject(deleteError) ? deleteError.message : deleteError, {
					variant: 'error',
				});
			}
		}
	}, [deletingPolicy, deletePolicyDone, deleteError]);

	const handleEditAction = () => {
		navigate(`/policy/${policy.id}/edit`, {
			state: { detail: true },
		});
	};

	const handleDeleteItem = () => {
		onDeletePolicy(policy.id);
		setDeletingPolicy(true);
	};

	return (
		<div className={className}>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Hidden only={['xs', 'sm']}>
						<StyledButton
							className={classes.backButton}
							onClick={handleBackButtonChange}
							startIcon={<KeyboardArrowLeftOutlinedIcon />}
							variant='inline-default'
						>
							{handleBackLabel()}
						</StyledButton>
					</Hidden>
					<Typography component='h1' variant='h3'>
						{loading ?
							<AdjustedSkeleton animation='wave' width={200} />
						:	policy.name}
					</Typography>
				</Grid>
				<Grid item>
					<Box display='flex'>
						<Box pr={2}>
							<ButtonWithAlertDialog
								actionDone={deletePolicyDone}
								callback={handleDeleteItem}
								color='secondary'
								dialogDescription={`${t('ui.deleted.dialogDescription')} ${isObject(policy) && policy.name} ${t('ui.permanently')}`}
								dialogLoading={deleteLoading && !deletePolicyDone}
								dialogTitle={t('ui.delete')}
								startIcon={<DeleteIcon />}
								variant='inline-delete'
							>
								{t('ui.delete')}
							</ButtonWithAlertDialog>
						</Box>
						<StyledButton
							onClick={handleEditAction}
							startIcon={<EditIcon />}
							variant='inline-default'
						>
							{t('ui.button.inline.edit')}
						</StyledButton>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	buttonPlaceholder: PropTypes.string,

	action: PropTypes.func,
	href: PropTypes.string,

	policy: PropTypes.object,
	loading: PropTypes.bool,

	onDeletePolicy: PropTypes.func,
	deletePolicy: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		deletePolicy: state.condition.deletePolicy,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeletePolicy: (id) => dispatch(actions.deletePolicy(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
