import { useState, useEffect } from 'react';

import { Typography, Box, CircularProgress, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import { useStyles } from './style';
import { ActionDialog, SwitchButton, NumberInput } from '../../../../components';
import { useError } from '../../../../shared/hooks';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const RefundDialog = (props) => {
	const {
		openRefundDialog,
		setOpenRefundDialog,

		onRefundBooking,
		refundBooking,
		onRefundPrice,
		priceTotal,
		price,
		bookingId,
		refundPrice,
		onResetState,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [partialRefund, setPartialRefund] = useState(false);
	const [inputRefund, setInputRefund] = useState(0);
	const currency = price.currencySymbol;

	const { loading: refundLoading } = refundBooking;

	const { data: refundPriceData } = refundPrice;

	const [total, setTotal] = useState(priceTotal);

	const [allowedRefund, setAllowedRefund] = useState(false);

	const [isMounted, setIsMounted] = useState(false);

	const debounceRefund = useDebounce(inputRefund, 500);

	useEffect(() => {
		if (debounceRefund === 0 || debounceRefund < 1 || isNaN(debounceRefund)) {
			setAllowedRefund(false);
		} else {
			setAllowedRefund(true);
		}
	}, [debounceRefund]);

	useEffect(() => {
		if (debounceRefund > 0 || isNaN(debounceRefund)) {
			const priceValue = isNaN(debounceRefund) ? 0 : debounceRefund;
			onRefundPrice(bookingId, priceValue);
			setIsMounted(true);
		}
	}, [debounceRefund]);

	useEffect(() => {
		if (refundPriceData?.totalPriceIncludingVat && isMounted) {
			setTotal(refundPriceData.refund);
			setIsMounted(false);
		}
	}, [refundPriceData]);

	const partialRefundSwitch = () => {
		setPartialRefund((val) => {
			if (val) {
				onResetState('refundPrice');
				setTotal(priceTotal);
			}
			return !val;
		});
	};

	const handleCloseRefundBooking = () => {
		setOpenRefundDialog(false);
		onResetState('refundPrice');
	};

	const handleRefundBooking = () => {
		const vatRefund =
			partialRefund ? refundPriceData.refund : Number((total - price.total.totalVat).toFixed(2));
		onRefundBooking(bookingId, vatRefund, true);
		refundBookingMessage.setStartAction(true);
	};

	const refundBookingMessage = useError({
		value: refundBooking,
		message: t('views.bookings.successfullyRefundedBooking'),
	});

	const handleRefund = (event) => {
		const value = parseFloat(event.target.value);
		if (value > priceTotal) {
			setInputRefund(priceTotal);
		} else {
			setInputRefund(value);
		}
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleRefundBooking,
				text:
					refundLoading ?
						<CircularProgress disableShrink size={24} />
					:	t('views.bookings.bookingDetails.button.inline.refund'),
			}}
			handleClose={handleCloseRefundBooking}
			loading={(partialRefund || refundLoading) && (refundLoading || !allowedRefund)}
			noDivider={true}
			open={openRefundDialog}
			title={t('views.bookings.bookingDetails.refundDialog.title')}
		>
			<Box>
				<Typography variant='body2'>
					{t('views.bookings.bookingDetails.refundDialog.description')}
				</Typography>
				<Box className={classes.partialRefundCard}>
					<Typography className={classes.partialRefund}>{t('ui.label.partialRefund')}</Typography>
					<SwitchButton checked={partialRefund} onChange={partialRefundSwitch} />
				</Box>
				{partialRefund ?
					<Box>
						<NumberInput
							FormHelperTextProps={{
								style: { position: 'absolute', top: '100%', marginLeft: '16px' },
							}}
							decimalSeparators={['.', ',']}
							error={inputRefund < 1}
							fullWidth
							helperText={
								inputRefund < 1 && `${t('ui.helperText.refund')} ${currency} ${(1).toFixed(2)}`
							}
							onChange={handleRefund}
							startAdornment={currency}
							thousandSeparator={true}
							value={inputRefund}
						/>
					</Box>
				:	null}
				{isObject(price) && (
					<Box display='flex' flexDirection='column' pt={3} rowGap={1}>
						<Box display='flex' justifyContent='space-between'>
							<Typography variant='body2'>{`${t('ui.label.currentTotal')} (${price.vatPercentage}% ${t('ui.vat')})`}</Typography>
							<Typography variant='body2'>{`${currency}${priceTotal.toFixed(2)}`}</Typography>
						</Box>
						{isObject(refundPriceData) ?
							<>
								<Box display='flex' justifyContent='space-between'>
									<Typography variant='body2'>{`${t('ui.refund')} (${price.vatPercentage}% ${t('ui.vat')})`}</Typography>
									<Typography variant='body2'>{`${currency}${isNaN(inputRefund) ? 0 : inputRefund.toFixed(2)}`}</Typography>
								</Box>
								<Divider />
								<Box display='flex' justifyContent='space-between'>
									<Typography variant='body2'>{`${t('ui.label.newTotal')} (${price.vatPercentage}% ${t('ui.vat')})`}</Typography>
									<Typography variant='body2'>{`${currency}${refundPriceData.totalPriceIncludingVat.toFixed(2)}`}</Typography>
								</Box>
							</>
						:	null}
					</Box>
				)}
			</Box>
		</ActionDialog>
	);
};

RefundDialog.propTypes = {
	onRefundBooking: PropTypes.func,
	onRefundPrice: PropTypes.func,
	priceTotal: PropTypes.number,
	bookingId: PropTypes.number,
	onResetState: PropTypes.func,
	refundBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	refundPrice: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	openRefundDialog: PropTypes.bool,
	setOpenRefundDialog: PropTypes.func,
	price: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		refundBooking: state.condition.refundBooking,
		refundPrice: state.details.refundPrice,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRefundBooking: (bookingId, refundAmount, fetch) =>
			dispatch(actions.refundBooking(bookingId, refundAmount, fetch)),
		onRefundPrice: (bookingId, refundAmount) =>
			dispatch(actions.refundPrice(bookingId, refundAmount)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(RefundDialog);
