import { colors } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles, withStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	backButton: {
		margin: theme.spacing(3),
	},
	divider: {
		backgroundColor: colors.grey[300],
	},
	mainContent: {
		padding: theme.spacing(9, 6),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		margin: 'auto',
		columnGap: theme.spacing(8),
		flexGrow: 1,
	},
	emailDomainForm: {
		flex: '1 0 40rem',
	},
	sidePanel: {
		flex: '0.15 0 20rem',
	},
	emailDomainFormContent: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: theme.spacing(4),
	},
	addButton: {
		padding: theme.spacing(1, 6),
	},
	noEmailDomainsCard: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 20,
		padding: theme.spacing(1, 0),
		margin: theme.spacing(3, 0, 2.5, 0),
		backgroundColor: theme.palette.info.light,
	},
	noEmailDomainsIcon: {
		padding: theme.spacing(0.25, 0.5),
		marginLeft: theme.spacing(1),
	},
	noEmailDomainsLabel: {
		fontSize: '12px',
	},
}));

export const AccordionSummary = withStyles({
	root: {
		padding: '0',
		backgroundColor: 'rgba(255, 255, 255, .03)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

export const Accordion = withStyles({
	root: {
		borderBottom: '1px dotted #e4e7eb',
		boxShadow: 'none',
		'&:last-child': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);
