import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	divider: {
		margin: theme.spacing(2, 0),
		backgroundColor: colors.grey[300],
	},
	userCard: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'baseline',
		flexFlow: 'wrap',
	},
	superAdmin: {
		flexBasis: '100%',
	},
}));
