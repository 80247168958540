import React, { useRef, useState } from 'react';

import {
	CarRental as CarRentalIcon,
	LockOpenOutlined as LockOpenOutlinedIcon,
	LockOutlined as LockOutlinedIcon,
	Refresh as RefreshIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonGroup, ButtonGroupProps, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '~components';
import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { BaseReference, IdReference } from '~interfaces';

import DeviceCommandEnum from '../../enums/deviceCommandEnum';
import DeviceTypeEnum from '../../enums/deviceTypeEnum';
import DevicesService from '../../services/devicesService';

interface CommandButtonProps {
	label: string;
	icon?: JSX.Element;
	command: DeviceCommandEnum;
	hidden?: boolean;
	confirmSubTitle?: string;
	successMessage?: string;
}

interface DeviceCommandButtonGroupProps extends ButtonGroupProps {
	device: IdReference;
	type: DeviceTypeEnum;
}

const devicesService = new DevicesService();

const DeviceCommandButtonGroup = ({
	device,
	type: deviceType,
	...buttonGroupProps
}: DeviceCommandButtonGroupProps) => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();
	const { isSuperAdmin } = useAuthorize();

	const [commandType, setCommandType] = useState<DeviceCommandEnum | null>(null);
	const [commandProcessing, setCommandProcessing] = useState(false);

	const isTeltonika = (type: DeviceTypeEnum) =>
		type === DeviceTypeEnum.VehicleTracker ||
		type === DeviceTypeEnum.VehicleTrackerCan ||
		type === DeviceTypeEnum.VehicleTrackerObd ||
		type === DeviceTypeEnum.VehicleTrackerPro;

	const handleConfirmClick = () => {
		if (commandType) {
			executeCommand(commandType);
		} else {
			enqueueSnackbar({
				variant: 'error',
				message: 'Unknown command',
			});
		}
	};

	/**
	 * Run the command
	 * @param types
	 */
	const executeCommand = async (type: DeviceCommandEnum) => {
		setCommandProcessing(true);

		// Add additional commands that are appropiate for the action.
		// For example, if we lock a device als enable immobiliser
		const commands: DeviceCommandEnum[] = [
			type,
			...(type.includes(DeviceCommandEnum.Lock) ? [DeviceCommandEnum.EnableImmobiliser] : []),
		];

		try {
			await devicesService.sendCommands(device.id, commands);
			enqueueSnackbar({
				variant: 'success',
				message: buttons.find((el) => el.command === commandType)?.successMessage,
			});
		} catch (error) {
			enqueueSnackbar({
				variant: 'error',
				message: t('somethingWentWrong'), // TODO
			});
			console.log(error);
		} finally {
			setCommandProcessing(false);
			setCommandType(null);
		}
	};

	const buttons: CommandButtonProps[] = [
		{
			icon: <RefreshIcon />,
			command: DeviceCommandEnum.Reboot,
			hidden: !(isTeltonika(deviceType) && isSuperAdmin()),
			label: t('reset'),
			confirmSubTitle: t('pleaseConfirmActionForResource', {
				action: t('reset'),
				resource: t('vehicle'),
			}),
			successMessage: t(`actionMessageWasSendToResource`, {
				action: t('reset'),
				resource: t('vehicle'),
			}),
		},
		{
			icon: <CarRentalIcon />,
			command: DeviceCommandEnum.DisableImmobiliser,
			hidden: ![DeviceTypeEnum.VehicleTrackerCan].includes(deviceType),
			label: t('ui.device.label.disableIgnition'),
			confirmSubTitle: t('ui.device.description.disableIgnition'),
			successMessage: t('ui.device.successMessage.enableIgnition'),
		},
		{
			icon: <LockOutlinedIcon />,
			command:
				deviceType === DeviceTypeEnum.CpacBoat ?
					DeviceCommandEnum.DisableImmobiliser
				:	DeviceCommandEnum.Lock,
			label: t(deviceType === DeviceTypeEnum.CpacBoat ? 'deactivate' : 'lock'),
			confirmSubTitle: t(`ui.device.description.lock.${deviceType}`),
			successMessage: t(`ui.device.successMessage.locked.${deviceType}`),
		},
		{
			icon: <LockOpenOutlinedIcon />,
			command:
				deviceType === DeviceTypeEnum.CpacBoat ?
					DeviceCommandEnum.EnableImmobiliser
				:	DeviceCommandEnum.Unlock,
			label: t(deviceType === DeviceTypeEnum.CpacBoat ? 'activate' : 'unlock'),
			confirmSubTitle: t(`ui.device.description.unlock.${deviceType}`),
			successMessage: t(`ui.device.successMessage.unlock.${deviceType}`),
		},
	];

	return (
		<>
			<Stack direction='row' spacing={1}>
				{buttons.map((el, i) =>
					el.hidden ? null : (
						<LoadingButton
							key={i}
							startIcon={el.icon}
							loading={commandProcessing && el.command === commandType}
							onClick={() => setCommandType(el.command)}
							variant='text'
							disabled={commandProcessing && el.command !== commandType}
						>
							{el.label}
						</LoadingButton>
					),
				)}
			</Stack>
			{/* <ButtonGroup {...buttonGroupProps} variant='text' disabled={commandProcessing}>
				{buttons.map((el, i) =>
					el.hidden ? null : (
						<LoadingButton
							key={i}
							startIcon={el.icon}
							loading={commandProcessing && el.command === commandType}
							onClick={() => setCommandType(el.command)}
						>
							{el.label}
						</LoadingButton>
					),
				)}
			</ButtonGroup> */}
			<ConfirmationDialog
				title={buttons.find((el) => el.command === commandType)?.label}
				subTitle={buttons.find((el) => el.command === commandType)?.confirmSubTitle}
				open={commandType != null && !commandProcessing}
				onConfirm={handleConfirmClick}
				onDecline={() => setCommandType(null)}
			/>
		</>
	);
};

export default DeviceCommandButtonGroup;
