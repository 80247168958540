import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 500,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%',
		padding: theme.spacing(1, 2, 1, 2),
	},
	container: {
		marginTop: theme.spacing(3),
		paddingBottom: '0px',
	},
	actions: {
		justifyContent: 'flex-end',
	},
	formGroup: {
		marginBottom: theme.spacing(3),
	},
	cardContent: {
		paddingBottom: '0px',
	},
}));
