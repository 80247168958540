import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { SchedulesOverviewLayout } from '~features/access';

import Page from '../page';

const AccessSchedulesPage = () => {
	const { t } = useTranslation('general');

	const [userInfo] = useAtom(userInfoAtom);

	return (
		<Page title={t('schedules')}>
			<SchedulesOverviewLayout />
		</Page>
	);
};

export default AccessSchedulesPage;
