import { Grid } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import AssignPolicies from './AssignPolicies/AssignPolicies';
import AssignPricing from './AssignPricing/AssignPricing';
import AssignServiceProvider from './AssignServiceProvider/AssignServiceProvider';
import AssignTerms from './AssignTerms/AssignTerms';
import BusinessPricing from './BusinessPricing/BusinessPricing';
import Characteristics from './Characteristics/Characteristics';
import ItemInformation from './ItemInformation/ItemInformation';
import LocationInformation from './LocationInformation/LocationInformation';
import OtherActions from './OtherActions/OtherActions';
import { useStyles } from './style';
import SummaryLoading from '../../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

const Summary = (props) => {
	const { className, itemData, itemLoading, priceData, isOwnItem, businessPricingData } = props;
	const classes = useStyles();

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return itemLoading && itemData != null ?
			<>
				<Grid className={clsx(classes.root, className)} container spacing={3}>
					<Grid {...gridProps}>
						<ItemInformation isOwnItem={isOwnItem} itemData={itemData} />
					</Grid>
					{itemData?.categoryReference?.type !== 'assets' && (
						<Grid {...gridProps}>
							<Characteristics isOwnItem={isOwnItem} itemData={itemData} />
						</Grid>
					)}
					<Grid {...gridProps}>
						<LocationInformation isOwnItem={isOwnItem} itemData={itemData} />
					</Grid>
					{itemData?.reservationType !== 'nonBookable' && (
						<>
							<Grid {...gridProps}>
								<AssignPricing isOwnItem={isOwnItem} itemData={itemData} priceData={priceData} />
							</Grid>
							<Grid {...gridProps}>
								<BusinessPricing
									businessPricingData={businessPricingData}
									isOwnItem={isOwnItem}
									itemData={itemData}
								/>
							</Grid>
							<Grid {...gridProps}>
								<AssignPolicies isOwnItem={isOwnItem} itemData={itemData} />
							</Grid>
						</>
					)}
					<Grid {...gridProps}>
						<AssignTerms isOwnItem={isOwnItem} itemData={itemData} />
					</Grid>
					<Grid {...gridProps}>
						<AssignServiceProvider isOwnItem={isOwnItem} itemData={itemData} />
					</Grid>
					{isOwnItem ?
						<Grid {...gridProps}>
							<OtherActions isOwnItem={isOwnItem} itemData={itemData} />
						</Grid>
					:	null}
				</Grid>
			</>
		:	<SummaryLoading />;
};

Summary.propTypes = {
	className: PropTypes.string,
	itemData: PropTypes.object,
	itemLoading: PropTypes.bool,
	imagesData: PropTypes.array,
	imagesLoading: PropTypes.bool,
	hubInfo: PropTypes.object,
	priceData: PropTypes.object,
	isOwnItem: PropTypes.bool,

	businessPricingData: PropTypes.object,
};

export default Summary;
