import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		padding: theme.spacing(1.5),
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 'auto',
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 2, 2, 2),
	},
	body3: {
		...theme.typography.body3,
	},
	tableCell: {
		padding: 16,
	},
	adjustedTableCell: {
		paddingTop: 0,
		wordBreak: 'break-word',
	},
}));
