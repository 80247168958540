import { useEffect } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import i18n from '~lib/i18n';

import LoadingPage from '../feedbacks/loadingPage';
import Page from '../page';

interface LogoutPageProps {
	title?: string;
	description?: string;
}

const LogoutPage = ({ title = i18n.t('signingOut'), description }: LogoutPageProps) => {
	const { t } = useTranslation('general');

	const auth = useAuth();

	useEffect(() => {
		signout();
	}, []);

	const signout = async () => {
		localStorage.removeItem('currentUser');
		sessionStorage.removeItem('currentUser');
		await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
	};

	// TODO: translate
	return (
		<Page title={title}>
			<LoadingPage title={title} description={description} />
		</Page>
	);
};

export default LogoutPage;
