import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	uploadButton: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minWidth: 125,
		border: '1px solid rgba(0, 0, 0, .23)',
		borderRadius: 4,
		padding: '10px 15px',
		color: colors.grey[400],
		'&:hover': {
			backgroundColor: colors.grey[100],
			cursor: 'pointer',
		},
	},
	uploadButtonText: {
		color: colors.grey[400],
		textTransform: 'uppercase',
		fontWeight: 500,
	},
	iconPreview: {
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		minWidth: 125,
		border: '1px solid rgba(0, 0, 0, .23)',
		borderRadius: 4,
		padding: '11.5px 38.42px',
		color: colors.grey[400],
	},
	iconContainer: {
		width: 42,
		height: 42,
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		maxWidth: 42,
		maxHeight: 42,
	},
	removeIconButton: {
		position: 'absolute',
		top: -4,
		right: -4,
		transform: 'translate(40%, -40%)',
		backgroundColor: colors.grey[100],
		'&:hover': {
			backgroundColor: colors.grey[300],
		},
	},
}));
