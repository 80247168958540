import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, colors } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import {
	Table,
	GenericMoreButton,
	AlertDialog,
	SearchBar,
	EmptyState,
	StyledButton,
	Label,
} from '../../../components';
import { localizeDateTime } from '../../../shared/datetime';
import {
	useError,
	useCloseTimeout,
	useSearchComponent,
	useBasicFilter,
	useComplexFilter,
	usePagination,
} from '../../../shared/hooks';
import {
	isObject,
	isEmptyString,
	isEmptyArray,
	isSuperAdmin,
	isInteger,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

const InvitationsUsers = (props) => {
	const {
		invitationsList,
		onFetchInvitations,
		onDeleteInvitations,
		deleteInvitations,
		onInviteUsers,
		invitedUsers,

		organisationsList,
		onFetchOrganisations,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const classes = useStyles();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [shouldSearch, setShouldSearch] = useState(false);
	const [shouldSort, setShouldSort] = useState(false);

	const [orderDescending, setOrderDescending] = useState(true);
	const [sortingCategory, setSortingCategory] = useState('email');
	const [order, setOrder] = useState('asc');
	const [shouldFilter, setShouldFilter] = useState(false);

	const pagination = usePagination('pageNumberInvitations', 'pageSizeInvitations');
	const search = useSearchComponent(
		pagination.setPageNumber,
		setShouldSearch,
		'userInvitationsSearch',
	);
	const userInviteOrganisationFilter = useComplexFilter(
		'userInviteOrganisationNameFilter',
		'userInviteOrganisationIdFilter',
	);
	const userInviteStatusFilter = useBasicFilter('userInviteStatusFilter', 'all');

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const filterItems = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingCategory) && { sortBy: sortingCategory }),
		...(userInviteOrganisationFilter.valueId !== 'all' && {
			organisationId: userInviteOrganisationFilter.valueId,
		}),
		...(userInviteStatusFilter.value !== 'all' && { status: userInviteStatusFilter.value }),
		orderDescending,
	};

	const [selectedInvite, setSelectedInvite] = useState(null);

	const {
		data: invitationsData,
		loading: invitationsLoading,
		error: invitationsError,
	} = invitationsList;
	const invitationsReady = isObject(invitationsData) && !invitationsLoading && !invitationsError;

	const {
		success: deleteInvitationsSuccess,
		loading: deleteInvitationsLoading,
		error: deleteInvitationsError,
	} = deleteInvitations;
	const deleteInvitationsDone =
		deleteInvitationsSuccess && !deleteInvitationsLoading && !deleteInvitationsError;

	const {
		data: invitedUsersData,
		error: invitedUsersError,
		loading: invitedUsersLoading,
	} = invitedUsers;
	const invitingUsersDone =
		isObject(invitedUsersData) && !invitedUsersError && !invitedUsersLoading;

	const deleteInvitationsMessage = useError({
		value: deleteInvitations,
		message: `${t('ui.successfullyDeleted')} ${isObject(selectedInvite) && selectedInvite.email}`,
	});

	const dialogAction = useCloseTimeout(
		isObject(deleteInvitations) ?
			{
				loading: deleteInvitationsLoading,
				error: deleteInvitationsError,
				actionDone: deleteInvitationsDone,
			}
		:	null,
	);

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		if (
			!invitedUsersLoading &&
			(pagination.fetch || shouldSearch || shouldSort || shouldFilter || shouldDoInitialFetch)
		) {
			if ((shouldSearch || shouldSort || shouldFilter) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchInvitations(pagination.page, filterItems);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [
		order,
		shouldSearch,
		shouldSort,
		shouldDoInitialFetch,
		showingInitialResults,
		orderDescending,
		pagination.fetch,
		invitingUsersDone,
		shouldFilter,
	]);

	useEffect(() => {
		if (deleteInvitationsDone) {
			onFetchInvitations(pagination.page, filterItems);
		}
	}, [
		deleteInvitationsDone,
		pagination.pageNumber,
		pagination.pageSize,
		sortingCategory,
		orderDescending,
	]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	// SORTING
	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	const deleteSingleInvite = (data) => {
		setSelectedInvite(data);
		dialogAction.setAction(true);
	};

	const createMenuItems = (invite) => [
		...(invite.status === 'active' ?
			[]
		:	[
				{
					icon: <MailOutlineIcon className={classes.buttonIcon} />,
					text: t('view.usermanagement.invitations.button.inline.resendlink'),
					action: () => handleResendOrCopy(invite),
				},
			]),
		{
			icon: <DeleteIcon />,
			text: t('ui.button.inline.delete'),
			action: () => deleteSingleInvite(invite),
			isRed: true,
		},
	];

	const extraButtons = [
		{
			text: t('ui.button.contained.inviteusers'),
			onClick: () => navigate('/user-management/users/invite'),
			variant: 'contained-primary',
		},
	];

	//FILTERING
	const handleChangeOrganisationFilter = (item) => {
		if (item.id === userInviteOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			userInviteOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			userInviteOrganisationFilter.setValueName('');
		}
		userInviteOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const handleChangeStatusFilter = (event) => {
		const value = event.target.value;
		if (value !== userInviteStatusFilter.value) {
			userInviteStatusFilter.setValue(value);
			setShouldFilter(true);
		}
	};

	const mainFilters = [
		...(isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleChangeOrganisationFilter,
						searchHandle: userInviteOrganisationFilter.setValueName,
					},
					value: userInviteOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: 'All organisations',
					},
				},
			]
		:	[]),
		{
			events: { onChange: (e) => handleChangeStatusFilter(e) },
			value: userInviteStatusFilter.value,
			selectOptions: [
				{
					value: 'all',
					label: t('ui.filter.status.all'),
				},
			].concat(
				['active', 'expired'].map((status) => ({
					value: status,
					label: t(`ui.status.${status}`),
				})),
			),
		},
	];

	const handleClearFilters = () => {
		userInviteOrganisationFilter.setValueName('');
		userInviteOrganisationFilter.setValueId('all');
		userInviteStatusFilter.setValue('all');
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			userInviteOrganisationFilter.valueId !== 'all' ||
			userInviteStatusFilter.value !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const handleResendOrCopy = (invite) => {
		if (invite.status === 'expired') {
			const emails = [invite.email];
			if (emails) {
				onInviteUsers({ emails });
				enqueueSnackbar(`${t('views.tableResults.invitationsUsers.linkWasResend')}`, {
					variant: 'success',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
		} else if (invite.status === 'active') {
			const el = document.createElement('textarea');
			el.innerText = invite.link;
			el.setAttribute('readonly', 'test');
			el.style.position = 'absolute';
			el.style.left = '-9999px';
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			el.remove();
			enqueueSnackbar(`${t('views.tableResults.invitationsUsers.clipboard')}`, {
				variant: 'success',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
			});
		}
	};

	const cultureInfo = {
		'de-DE': t('ui.german'),
		'nl-NL': t('ui.dutch'),
		'en-US': t('ui.english'),
	};

	const tableHeader = [
		{ name: 'email', content: t('ui.label.email'), hasSorting: true },
		{ name: 'expiryDate', content: t('ui.expiryDate'), hasSorting: true },
		...(isSuperAdmin(auth.user?.profile.role) ?
			[{ name: 'organisation', content: t('ui.organisation') }]
		:	[]),
		{ name: 'userGroup', content: t('ui.label.userGroup'), hasSorting: true },
		{ name: 'language', content: t('views.userDetails.summary.language') },
		{ name: 'status', content: t('ui.label.status') },
		{ name: 'actions', content: t('ui.label.actions') },
	];

	const tableBody =
		invitationsReady ?
			invitationsData.results.map((invite) => [
				{ content: <div>{invite.email}</div> },
				{
					content: (
						<Box color={invite.status === 'active' ? null : colors.red[900]}>
							{localizeDateTime(invite.expiryDate, undefined, {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
							})}
						</Box>
					),
				},
				...(isSuperAdmin(auth.user?.profile.role) ?
					[{ content: invite?.organisationReference?.name }]
				:	[]),
				{ content: invite?.userGroupReference?.name },
				{ content: invite?.cultureInfo && cultureInfo[invite.cultureInfo] },
				{
					content: (
						<Label type={invite.status === 'active' ? 'success' : 'error'}>
							{t(`ui.status.${invite.status}`)}
						</Label>
					),
				},
				{
					content: (
						<Box display='flex' justifyContent='space-between'>
							<StyledButton
								className={classes.btnLink}
								onClick={() => {
									handleResendOrCopy(invite);
								}}
								size='small'
								startIcon={
									invite.status !== 'active' ? <MailOutlineIcon /> : <InsertDriveFileOutlinedIcon />
								}
								variant='contained-tertiary'
							>
								{invite.status === 'active' ?
									t('view.usermanagement.invitations.button.contained.copyinvite')
								:	t('view.usermanagement.invitations.button.contained.resendlink')}
							</StyledButton>
							<GenericMoreButton menuItems={createMenuItems(invite)} />
						</Box>
					),
				},
			])
		:	Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	// Dialog
	const handleCancelDialog = () => {
		dialogAction.setAction(false);
	};

	const handleConfirmDialog = () => {
		if (selectedInvite) {
			onDeleteInvitations(selectedInvite.id);
			deleteInvitationsMessage.setStartAction(true);
		}
	};

	const invitationsListEmpty =
		isObject(invitationsData) && !invitationsLoading && isEmptyArray(invitationsData.results) ?
			showingInitialResults ?
				<EmptyState
					callToActionText={t('ui.button.contained.inviteusers')}
					handleAction={() => navigate('/user-management/users/invite')}
					image={'booking'}
					subTitle={t('views.tableResults.invitationsUsers.emptyState.foundSystem')}
					title={t('views.tableResults.invitationsUsers.emptyState.titleSystem')}
				/>
			:	<EmptyState
					callToActionText={t('ui.button.contained.inviteusers')}
					handleAction={() => navigate('/user-management/users/invite')}
					image={'booking'}
					subTitle={t('views.tableResults.invitationsUsers.emptyState.found')}
					title={t('views.tableResults.invitationsUsers.emptyState.title')}
				/>
		:	null;

	return (
		<div className={classes.root}>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons
				hasMainFilter
				hideMoreFilters
				mainFilters={mainFilters}
				placeholder={t('views.tableResults.invitationsUsers.searchBar.placeholder')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{invitationsListEmpty ?
				invitationsListEmpty
			:	<Table
					body={tableBody}
					cellStyle={classes.cellStyle}
					data={invitationsData ? invitationsData.results : []}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeader}
					loading={invitationsLoading}
					order={order}
					orderBy={sortingCategory}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('views.tableResults.invitationsUsers.table.title')}
					total={invitationsData ? invitationsData.total : 0}
				/>
			}
			{dialogAction.action ?
				<AlertDialog
					dialogDescription={`${t('views.tableResults.invitationsUsers.dialogDescription')}, ${selectedInvite.email}`}
					dialogTitle={t('ui.delete')}
					handleClose={handleCancelDialog}
					handleConfirm={handleConfirmDialog}
					loading={deleteInvitationsLoading && !deleteInvitationsDone}
					open={dialogAction.action}
				/>
			:	null}
		</div>
	);
};

InvitationsUsers.propTypes = {
	onFetchInvitations: PropTypes.func,
	invitationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onDeleteInvitations: PropTypes.func,
	deleteInvitations: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onInviteUsers: PropTypes.func,
	invitedUsers: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchOrganisations: PropTypes.func,
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		invitationsList: state.paged.invitations,
		deleteInvitations: state.condition.deleteInvitations,
		invitedUsers: state.details.invitedUsers,
		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchInvitations: (page, filters) => dispatch(actions.fetchInvitations(page, filters)),
		onDeleteInvitations: (id) => dispatch(actions.deleteInvitations(id)),
		onInviteUsers: (bodyData) => dispatch(actions.inviteUsers(bodyData)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsUsers);
