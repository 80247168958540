import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	tipCard: {
		backgroundColor: '#e4eefb',
		color: '#15263e',
		boxShadow: 'none',
		padding: '0.632rem',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		flexShrink: 3,
		width: 'fit-content',
	},
	cellStyle: {
		width: '10.25rem',
		whiteSpace: 'nowrap',
	},
	longCellStyle: {
		width: '26.25rem',
	},
	lastUpdatedCell: {
		width: '12.25rem',
		whiteSpace: 'nowrap',
	},
	batteryLevelCell: {
		width: '4.25rem',
	},
	tip: {
		...theme.typography.pagination,
	},
	icon: {
		marginRight: theme.spacing(1),
	},

	buttonIcon: {
		marginRight: theme.spacing(1),
	},

	itemGroup: {
		...theme.typography.link1,
		// overflowWrap: 'break-word',
		// wordBreak: 'break-all'
	},
	detailTitle: {
		// textDecoration: 'underline',
		...theme.typography.body3,
	},
	allSwitchCard: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	detailTooltip: {
		textDecoration: 'underline',
		...theme.typography.link1,
	},
}));

const useStylesMyTooltip = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.white,
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
	tooltip: {
		backgroundColor: '#ffffff',
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
}));

export function MyTooltip(props) {
	const classes = useStylesMyTooltip();
	return <Tooltip classes={classes} {...props} />;
}
