import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	content: {
		padding: 12,
	},
	actions: {
		flexDirection: 'column-reverse',
		flexGrow: 1,
		alignItems: 'flex-start',
		'& > * + *': {
			marginLeft: 0,
		},
	},
}));
