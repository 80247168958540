import { useTranslation } from 'react-i18next';

import { UsersDataGrid } from '~features/users';
import UsersOverviewLayout from '~features/users/layouts/usersOverviewLayout';

import Page from '../page';

const UsersPage = () => {
	const { t } = useTranslation('general');

	return (
		<Page title={t('users')}>
			{/* <UsersDataGrid /> */}
			<UsersOverviewLayout />
		</Page>
	);
};

export default UsersPage;
