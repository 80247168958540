import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%',
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		justifyContent: 'flex-end',
	},
	saveButton: {
		color: theme.palette.white,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));
