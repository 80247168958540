import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		width: 100,
		height: 100,
	},
	canvas: {
		backgroundColor: 'transparent',
		display: 'none',
	},
	imageContainer: {
		display: 'flex',
		flexDirection: 'column',
		// flexWrap: 'wrap',
		alignItems: 'flex-start',
		height: '290px',
	},
	imageSpacing: {
		objectFit: 'scale-down',
		width: '100%',
		height: '100%',
	},
	button: {
		cursor: 'pointer',
	},
}));
