import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			'&.Mui-selected': {
				backgroundColor: palette.background.default,
			},
			'&.MuiTableRow-hover': {
				backgroundColor: palette.background.default,
			},
		},
	},
};
