import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { InfoLabel } from '../../../components';

const FormFieldLabel = (props) => {
	const { className, label, info, description, variant, children, required } = props;

	return (
		<>
			<Box className={className}>
				{(
					variant === 'info' ||
					variant === 'h1' ||
					variant === 'h2' ||
					variant === 'h3' ||
					variant === 'h4' ||
					variant === 'h5'
				) ?
					<InfoLabel
						info={info}
						isRequired={required}
						name={label}
						variant={variant === 'info' ? 'h5' : variant}
					/>
				:	<Typography variant='h5'>
						{label}
						{required ?
							<sup>*</sup>
						:	null}
					</Typography>
				}
				{description ?
					<Typography>{description}</Typography>
				:	null}
			</Box>
			{children}
		</>
	);
};

FormFieldLabel.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	info: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.object,
	required: PropTypes.bool,
	variant: PropTypes.string,
};

export default FormFieldLabel;
