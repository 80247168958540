import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { AlertDialog } from '../../../../components';
import { useWizardFormField, useError } from '../../../../shared/hooks';
import * as actions from '../../../../store/actions';
import { StopBookingDialog } from '../../../Actions';

const Dialogs = (props) => {
	const {
		open,
		onClose,
		bookingData,
		stopCarBooking,
		setStopCarBooking,
		cancelDialogOpen,
		setCancelDialogOpen,
		canceledBooking,
		onCancelBooking,

		presetData,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const {
		success: cancelledBookingSuccess,
		loading: cancelledBookingLoading,
		error: cancelledBookingError,
	} = canceledBooking;
	const cancelledBookingDone =
		cancelledBookingSuccess && !cancelledBookingLoading && !cancelledBookingError;

	const kilometres = useWizardFormField('', {
		required: true,
		minLength: 1,
		maxLength: 10,
		isNumeric: true,
	});

	const canceledBookingMessage = useError({
		value: canceledBooking,
		message: `${t('ui.successfully')} ${t('ui.canceled')}`,
	});

	useEffect(() => {
		if (!open) {
			kilometres.resetToInitialValue();
		}
	}, [open]);

	useEffect(() => {
		if (!cancelledBookingLoading && cancelledBookingSuccess) {
			onClose();
			onResetStateCondition('canceledBooking', false);
		}
	}, [cancelledBookingSuccess]);

	const handleCloseDialog = () => {
		if (cancelDialogOpen) {
			setCancelDialogOpen(false);
		}
	};

	const handleConfirmDialog = () => {
		if (cancelDialogOpen) {
			onCancelBooking(bookingData.id, presetData?.redirect);
			canceledBookingMessage.setStartAction(true);
		}
	};

	const alertDialog = [
		{
			label: 'cancel',
			open: cancelDialogOpen,
			dialogTitle: 'views.planboard.editBooking.cancelAlert.title',
			dialogDescription: 'views.planboard.editBooking.cancelAlert.description',
			hasOnlyConfirmButton: true,
			loading: canceledBookingMessage.startAction && !cancelledBookingDone,
		},
	];

	return (
		<div>
			{bookingData ?
				<StopBookingDialog
					data={bookingData}
					end={bookingData.periodEnd}
					itemInstance={bookingData.itemInstance}
					kilometres={kilometres}
					onClose={onClose}
					open={stopCarBooking}
					setOpenStopBooking={setStopCarBooking}
					start={bookingData.periodStart}
				/>
			:	null}
			{alertDialog.map((item) => (
				<AlertDialog
					content={item.content}
					dialogDescription={t(item.dialogDescription)}
					dialogTitle={t(item.dialogTitle)}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirmDialog}
					hasOnlyConfirmButton={item.hasOnlyConfirmButton}
					isValid={item.isValid}
					key={item.label}
					loading={item.loading}
					open={item.open}
					variantDescription={item.variantDescription}
				/>
			))}
		</div>
	);
};

Dialogs.propTypes = {
	open: PropTypes.bool,
	bookingData: PropTypes.object,
	onClose: PropTypes.func,
	stopCarBooking: PropTypes.bool,
	setStopCarBooking: PropTypes.func,
	cancelDialogOpen: PropTypes.bool,
	setStartCarBooking: PropTypes.func,
	startCarBooking: PropTypes.func,
	setCancelDialogOpen: PropTypes.func,
	presetData: PropTypes.object,
	onResetStateCondition: PropTypes.func,
	onCancelBooking: PropTypes.func,

	canceledBooking: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	startBooking: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		canceledBooking: state.condition.canceledBooking,
		startBooking: state.details.startBooking,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onCancelBooking: (bookingId, redirect) => dispatch(actions.cancelBooking(bookingId, redirect)),
		onStartBooking: (id, kilometres) => dispatch(actions.startBooking(id, kilometres)),
		onResetStateCondition: (identifier, value) =>
			dispatch(actions.resetStateCondition(identifier, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs);
