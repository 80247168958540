import React, { PropsWithChildren } from 'react';

import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';

import GenericHeader from './headers/genericHeader';

interface GenericLayoutProps extends PropsWithChildren {}

/**
 * A generic layout. E.g. used for as a temporary framework for example
 * for a loading page.
 * Without React-router!!
 */
const GenericLayout = ({
	children,
	// disableOutlet = false
}: GenericLayoutProps) => {
	const theme = useTheme();
	const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box display='flex' flexDirection='column' width={1} height={1}>
			<GenericHeader />
			<Box display='flex' height={1} flexGrow={1} p={2} alignItems='center' justifyContent='center'>
				{largerThanMd ?
					<Paper elevation={1} sx={{ p: 3 }}>
						{children}
					</Paper>
				:	children}
			</Box>
		</Box>
	);
};

export default GenericLayout;
