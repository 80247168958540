import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0.1),
		'& .MuiTableHead-root': {
			backgroundColor: '#fff',
		},
	},
	cellStyle: {
		whiteSpace: 'nowrap',
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));
