import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import { LoadingBar, StyledButton } from '../../../../../components';
import { isFullString } from '../../../../../shared/utility'; // import { useDetailPageBackButton } from '../../../shared/hooks';

const Header = (props) => {
	const { className, title } = props;
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation('general');

	const classes = useStyles();

	const handleBackButtonChange = () => navigate('/financeManagement/expenses');

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{t('ui.expenses')}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				{!isFullString(title) ?
					<LoadingBar />
				:	title}
			</Typography>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	course: PropTypes.string,

	revenueData: PropTypes.object,

	loading: PropTypes.bool,
	month: PropTypes.string,
	route: PropTypes.string,

	organisationName: PropTypes.string,
	date: PropTypes.string,
	title: PropTypes.string,
};

export default Header;
