import { memo } from 'react';

import {
	TableContainer,
	Paper,
	TableBody,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Divider,
	CardHeader,
	Card,
	CardContent,
	CardActions,
	TablePagination,
	TableSortLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Row from './Row';
import { useStyles } from './style';
import { isFullArray } from '../../../shared/utility';
import { TableEditBar } from '../../elements';

const propsAreEqual = (prevProps, nextProps) => {
	return (
		JSON.stringify(
			prevProps.tableBody.map((entry) => ({ ...entry, results: [], instances: [] })),
		) ===
			JSON.stringify(
				nextProps.tableBody.map((entry) => ({ ...entry, results: [], instances: [] })),
			) &&
		prevProps.rowsPerPage === nextProps.rowsPerPage &&
		prevProps.page === nextProps.page &&
		prevProps.orderBy === nextProps.orderBy &&
		prevProps.order === nextProps.order &&
		JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
		JSON.stringify(prevProps.selectionEvents) === JSON.stringify(nextProps.selectionEvents) &&
		JSON.stringify(prevProps.selection) === JSON.stringify(nextProps.selection)
	);
};

const CollapsibleTable = memo((props) => {
	const {
		tableBody,
		title,
		total,

		page,
		rowsPerPage,
		tableHeader,
		handleChangeRowsPerPage,
		handlePageChange,
		handleSorting,
		orderBy,
		order,
		data,
		editActionButtons,
		selectionEvents,
		selection,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	return (
		<Card className={classes.tableContainer}>
			{<CardHeader title={title} />}
			<Divider />
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<TableContainer component={Paper}>
							<Table aria-label='collapsible table'>
								<TableHead>
									<TableRow>
										{(
											isFullArray(tableBody) &&
											isFullArray(tableBody.filter((item) => item.isSelectable))
										) ?
											<TableCell align='right' className={classes.checkboxCell}></TableCell>
										:	null}
										{tableHeader.map((header, index) => (
											<TableCell
												{...(index === 0 && { className: classes.qtyCell })}
												align={header.align}
												key={header.name}
											>
												{header.hasSorting ?
													<TableSortLabel
														active={orderBy === header.name}
														direction={order === 'asc' ? 'desc' : 'asc'}
														onClick={() => handleSorting(header.name)}
													>
														{header.content}
													</TableSortLabel>
												:	header.content}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{tableBody.map((tableData, index) => (
										<Row
											checkedValue={tableData.hasSelectAllSelected}
											collapsData={tableData.instances ? tableData.instances : []}
											data={data}
											expandedValue={tableData.isExpanded}
											handleChecked={(instance, checked) =>
												checked ?
													selectionEvents.onAdd(tableData.item, instance)
												:	selectionEvents.onRemove(tableData.item, instance)
											}
											handleExpanded={() => selectionEvents.onShowInstances(tableData.item)}
											isExpandable={tableData.isExpandable}
											isSelectable={tableData.isSelectable}
											item={tableData.item ? tableData.item : {}}
											key={index}
											page={page}
											row={tableData.results}
											rowsPerPage={rowsPerPage}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<TablePagination
					component='div'
					count={total}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} ${t('ui.of')} ${count !== -1 ? count : '0'}`
					}
					labelRowsPerPage={t('ui.rowsPerPage')}
					onPageChange={(e, p) => handlePageChange(p + 1)}
					onRowsPerPageChange={handleChangeRowsPerPage}
					page={page - 1}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[5, 10, 20, 40, 50]}
				/>
			</CardActions>
			<TableEditBar actionButtons={editActionButtons} selected={selection} />
		</Card>
	);
}, propsAreEqual);

CollapsibleTable.propTypes = {
	open: PropTypes.bool,
	tableBody: PropTypes.any,
	handleSelectRow: PropTypes.func,
	internalRowSelect: PropTypes.bool,
	handleSelectInternalRow: PropTypes.func,
	title: PropTypes.string,
	total: PropTypes.number,

	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	tableHeader: PropTypes.array,
	handlePageChange: PropTypes.func,
	handleChangeRowsPerPage: PropTypes.func,
	orderBy: PropTypes.string,
	order: PropTypes.string,
	handleSorting: PropTypes.func,
	collapsData: PropTypes.array,
	expandedCallback: PropTypes.func,
	data: PropTypes.array,
	isSelectable: PropTypes.bool,
	selectionEvents: PropTypes.object.isRequired,
	selection: PropTypes.array,
	isExpandable: PropTypes.bool,
	editActionButtons: PropTypes.array,
};

export default CollapsibleTable;
