import { useEffect, useRef, useState } from 'react';

import path from 'path';

import { Box, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ConfirmationDialog, Dialog, FormDialog } from '~components';

import selectedOrganisationAtom from '../atoms/selectedOrganisationAtom';
import SkcDeviceGroupsDataGrid from '../components/dataGrids/skcDeviceGroupsDataGrid';
import SkcDeviceGroupForm from '../forms/skcDeviceGroupForm/skcDeviceGroupForm';
import SkcDeviceGroup from '../interfaces/skcDeviceGroup';
import SkcDeviceGroupsService from '../services/skcDeviceGroupsService';


type UserAction =
	| {
			type: 'delete' | 'edit';
			label?: string;
			id: string;
	  }
	| {
			type: 'add';
			label?: never;
			id?: never;
	  };

const service = new SkcDeviceGroupsService();

const SkcDeviceGroupsOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();
	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [userAction, setUserAction] = useState<UserAction | null>(null);
	const dataGridRef = useRef(null);
	const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

	useEffect(() => {
		// Should be temporary
		if (selectedOrganisation) {
			service.organisation = selectedOrganisation;
		}
	}, [selectedOrganisation]);

	const {
		data: deviceGroupData,
		isLoading,
		isValidating,
		error,
	} = useSWR(
		userAction?.type === 'edit' ? path.join(service.basePath, userAction.id) : undefined,
		() => service.getDeviceGroupById(userAction?.id),
	);

	const handleEdit = async (id: string, data: SkcDeviceGroup) => {
		setUserActionProcessing(true);

		const currentDevicesArray = deviceGroupData?.devices.map((el) => el.id) ?? [];
		const newDevicesArray = data.devices.map((el) => el.id);

		const devicesToRemove = deviceGroupData?.devices.filter(
			(el) => !newDevicesArray.includes(el.id),
		);
		const devicesToAdd = data.devices?.filter((el) => !currentDevicesArray.includes(el.id)) ?? [];

		try {
			if (devicesToRemove != null && devicesToRemove?.length > 0) {
				await service.removeDevices(id, devicesToRemove);
			}
			console.log(devicesToAdd);
			if (devicesToAdd.length > 0) {
				await service.assignDevices(id, devicesToAdd);
			}
			enqueueSnackbar(t('ui.success.message.updated'), { variant: 'success' });
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleCreate = async (data: SkcDeviceGroup) => {
		setUserActionProcessing(true);

		try {
			const newDeviceGroup = await service.createDeviceGroup(data);
			if (data.devices?.length > 0) {
				await service.assignDevices(newDeviceGroup.id, data.devices);
			}

			enqueueSnackbar(`${t('ui.successfullyAdded')} ${t('deviceGroup').toLowerCase()}`, {
				variant: 'success',
			});
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleDelete = async (id: string) => {
		setUserActionProcessing(true);

		try {
			await service.deleteDeviceGroup(id);
			enqueueSnackbar(t('deleted'), { variant: 'success' });
			setUserAction(null);
			dataGridRef.current?.mutate();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
		<Stack height={1}>
			<SkcDeviceGroupsDataGrid
				ref={dataGridRef}
				onEdit={(value) => setUserAction({ type: 'edit', ...value })}
				onDelete={(value) => setUserAction({ type: 'delete', ...value })}
				onCreate={() => setUserAction({ type: 'add' })}
			/>
			<FormDialog
				title={t(userAction?.type === 'edit' ? 'editResource' : 'addResource', {
					resource: t('deviceGroup'),
				})}
				open={userAction?.type === 'edit' || userAction?.type === 'add'}
				loading={userActionProcessing}
				onClose={() => setUserAction(null)}
				onSubmit={(data) => {
					if (userAction?.type === 'edit') {
						handleEdit(userAction.id, data);
					} else {
						handleCreate(data);
					}
				}}
				maxWidth='lg'
				fullWidth
			>
				<SkcDeviceGroupForm id={userAction?.id} />
			</FormDialog>
			<ConfirmationDialog
				loading={userActionProcessing}
				open={userAction?.type === 'delete'}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('deviceGroup'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={() => handleDelete(userAction.id)}
				onDecline={() => setUserAction(null)}
				onClose={() => setUserAction(null)}
			/>
		</Stack>
	);
};

export default SkcDeviceGroupsOverviewLayout;
