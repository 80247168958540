import { CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const CustomCardContent = (props) => {
	const classes = useStyles();

	const { children, ...rest } = props;

	return (
		<CardContent className={classes.content} {...rest}>
			{children}
		</CardContent>
	);
};

CustomCardContent.propTypes = {
	children: PropTypes.any,
};

export default CustomCardContent;
