import { Switch, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const BillinToggle = (props) => {
	const { loading, checked, onChange, color, size } = props;

	return (
		<>
			{loading && <CircularProgress color='inherit' disableShrink size={19} />}
			{!loading && <Switch checked={checked} onChange={onChange} color={color} size={size} />}
		</>
	);
};

BillinToggle.propTypes = {
	loading: PropTypes.bool,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	color: PropTypes.string,
	size: PropTypes.string,
};

export default BillinToggle;
