import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Typography, Box } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { InfoPopup } from '../../../components';

const InfoLabel = (props) => {
	const { className, name, info, isRequired, variant, paddingTop, paddingBottom } = props;

	const classes = useStyles();

	return (
		<Box className={clsx(classes.root, className)} pb={paddingBottom} pt={paddingTop}>
			<Typography className={classes.icon} variant={variant}>
				{name}
				{isRequired ?
					<sup>*</sup>
				:	null}
			</Typography>
			<InfoPopup title={info}>
				<HelpOutlineIcon fontSize='small' />
			</InfoPopup>
		</Box>
	);
};

InfoLabel.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	info: PropTypes.string,
	styles: PropTypes.string,
	bColor: PropTypes.string,
	variant: PropTypes.string,
	paddingTop: PropTypes.number,
	paddingBottom: PropTypes.number,
	isRequired: PropTypes.bool,
};

export default InfoLabel;
