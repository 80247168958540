import PropTypes from 'prop-types';

import AdministrativeConfiguration from './AdministrativeConfiguration';
import BookingConfiguration from './BookingConfiguration';
import Header from './Header';
import PaymentConfiguration from './PaymentConfiguration';
import { useStyles } from './style';

const PolicyConfiguration = (props) => {
	const {
		save,
		requireHostConfirmation,
		minimumBookingDuration,
		minimumBookingDurationRequired,
		requireOvertimeValidation,
		hasCancellationPeriod,
		cancelMin,
		cancelDay,
		cancelHour,
		daysBeforeUse,
		hasBufferPeriod,
		hasMinDurationBeforeStart,
		hasMaxDurationBeforeStart,
		minDurationBeforeStartDay,
		minDurationBeforeStartHour,
		minDurationBeforeStartMin,
		maxDurationBeforeStartDay,
		maxDurationBeforeStartHour,
		maxDurationBeforeStartMin,
		hoursBeforeUse,
		minBeforeUse,
		daysAfterUse,
		hoursAfterUse,
		minAfterUse,
		allowExtending,
		requiredLicenseStatus,
		hasGracePeriod,
		depositAmountRequired,
		personalBillingDeposit,
		requireReservationComment,
		editing,
		name,
		requireDutchLicense,
		allowPersonalBilling,
		setAllowPersonalBilling,
		requiredParkingLicensePlateNumber,
		daysGracePeriod,
		hoursGracePeriod,
		minGracePeriod,
		hasMinDuration,
		minDurationDay,
		minDurationHour,
		minDurationMin,
		maxDurationDay,
		maxDurationHour,
		maxDurationMin,
		hasMaxDuration,
		openHoursRestrictionType,
		bookingUpfrontMaxDurationSmallerThanMinDuration,
	} = props;

	const classes = useStyles();

	const bookingDurationProps = {
		requireHostConfirmation,
		openHoursRestrictionType,
		minimumBookingDuration,
		minimumBookingDurationRequired,
		minDurationBeforeStartDay,
		minDurationBeforeStartHour,
		minDurationBeforeStartMin,
		maxDurationBeforeStartDay,
		maxDurationBeforeStartHour,
		maxDurationBeforeStartMin,
		hasCancellationPeriod,
		cancelMin,
		cancelDay,
		requireOvertimeValidation,
		requireDutchLicense,
		cancelHour,
		daysBeforeUse,
		hasBufferPeriod,
		hoursBeforeUse,
		hasGracePeriod,
		hasMinDurationBeforeStart,
		hasMaxDurationBeforeStart,
		minBeforeUse,
		daysAfterUse,
		hoursAfterUse,
		minAfterUse,
		requiredParkingLicensePlateNumber,
		allowExtending,
		requiredLicenseStatus,
		daysGracePeriod,
		hoursGracePeriod,
		minGracePeriod,
		hasMinDuration,
		minDurationDay,
		minDurationHour,
		minDurationMin,
		maxDurationDay,
		maxDurationHour,
		maxDurationMin,
		hasMaxDuration,
		bookingUpfrontMaxDurationSmallerThanMinDuration,
	};
	const paymentConfigurationProps = {
		depositAmountRequired,
		personalBillingDeposit,
		allowPersonalBilling,
		setAllowPersonalBilling,
		editing,
	};
	const administrativeConfigurationProps = { requireReservationComment, editing };
	// const cancellationPoliciesProps = { cancelDay, setCancelDay, cancelHour, setCancelHour, cancelMin, setCancelMin };
	// const cooldownPolicyProps = { daysBeforeUse, setDaysBeforeUse, hoursBeforeUse, setHoursBeforeUse, minBeforeUse, setMinBeforeUse, daysAfterUse, setDaysAfterUse, hoursAfterUse, setHoursAfterUse, minAfterUse, setMinAfterUse };

	return (
		<>
			<Header editing={editing} name={name} />
			<BookingConfiguration
				className={classes.bookingDuration}
				save={save}
				{...bookingDurationProps}
			/>
			<PaymentConfiguration save={save} {...paymentConfigurationProps} />
			<AdministrativeConfiguration save={save} {...administrativeConfigurationProps} />
		</>
	);
};

PolicyConfiguration.propTypes = {
	save: PropTypes.func.isRequired,
	editing: PropTypes.bool,
	requireHostConfirmation: PropTypes.bool,
	minimumBookingDuration: PropTypes.string,
	minimumBookingDurationRequired: PropTypes.bool,
	hasCancellationPeriod: PropTypes.bool,
	cancelDay: PropTypes.number,
	cancelHour: PropTypes.number,
	cancelMin: PropTypes.number,
	hasBufferPeriod: PropTypes.bool,
	hasGracePeriod: PropTypes.bool,
	daysBeforeUse: PropTypes.number,
	hoursBeforeUse: PropTypes.number,
	minBeforeUse: PropTypes.number,
	daysAfterUse: PropTypes.number,
	hoursAfterUse: PropTypes.number,
	minAfterUse: PropTypes.number,
	allowExtending: PropTypes.bool,
	requiredLicenseStatus: PropTypes.string,
	requiredParkingLicensePlateNumber: PropTypes.string,
	personalBillingDeposit: PropTypes.number,
	depositAmountRequired: PropTypes.bool,
	requireReservationComment: PropTypes.bool,
	name: PropTypes.string,
	allowPersonalBilling: PropTypes.bool,
	setAllowPersonalBilling: PropTypes.func,
	daysGracePeriod: PropTypes.number,
	hoursGracePeriod: PropTypes.number,
	minGracePeriod: PropTypes.number,
	maxDurationDay: PropTypes.number,
	maxDurationHour: PropTypes.number,
	maxDurationMin: PropTypes.number,
	hasMaxDuration: PropTypes.bool,
	hasMinDuration: PropTypes.bool,
	requireOvertimeValidation: PropTypes.bool,
	minDurationDay: PropTypes.number,
	minDurationHour: PropTypes.number,
	minDurationMin: PropTypes.number,
	openHoursRestrictionType: PropTypes.string,
	requireDutchLicense: PropTypes.bool,
	hasMinDurationBeforeStart: PropTypes.bool,
	hasMaxDurationBeforeStart: PropTypes.bool,
	minDurationBeforeStartDay: PropTypes.number,
	minDurationBeforeStartHour: PropTypes.number,
	minDurationBeforeStartMin: PropTypes.number,
	maxDurationBeforeStartDay: PropTypes.number,
	maxDurationBeforeStartHour: PropTypes.number,
	maxDurationBeforeStartMin: PropTypes.number,
	bookingUpfrontMaxDurationSmallerThanMinDuration: PropTypes.bool,
};

export default PolicyConfiguration;
