import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import Invitations from './Invitations';
import NfcCards from './NfcCards';
import { useStyles } from './style';
import UserGroups from './UserGroups';
import Users from './Users';
import { Page } from '../../components';
import * as actions from '../../store/actions';

const UserManagement = (props) => {
	const { onUpdatePagedState } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const classes = useStyles();
	const { tab } = useParams();

	const handleTabsChange = (e, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'users', label: t('ui.category.users') },
		{ value: 'user-invitations', label: t('views.users.tab') },
		{ value: 'user-groups', label: t('ui.category.userGroups') },
		{ value: 'nfcTags', label: t('ui.label.nfcTags') },
	];

	useEffect(() => {
		return () => {
			onUpdatePagedState('users', null, false);
		};
	}, []);

	if (!tab) {
		return <Navigate to={'/user-management/users'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const headerButtons = [
		{ key: 'users', buttons: [] },
		{ key: 'user-invitations', buttons: [] },
		{ key: 'user-groups', buttons: [] },
		{ key: 'nfcTags', buttons: [] },
	];

	return (
		<Page className={classes.root} title={t('ui.category.users')}>
			{headerButtons.map((item) => item.key === tab && <Header key={item.key} {...item} />)}
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tabVal) => (
					<Tab key={tabVal.value} label={tabVal.label} value={tabVal.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'users' && <Users />}
				{tab === 'user-invitations' && <Invitations />}
				{tab === 'user-groups' && <UserGroups />}
				{tab === 'nfcTags' && <NfcCards />}
			</div>
		</Page>
	);
};

UserManagement.propTypes = {
	onUpdatePagedState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdatePagedState: (identifier, data) => dispatch(actions.updatePagedState(identifier, data)),
	};
};

export default connect(null, mapDispatchToProps)(UserManagement);
