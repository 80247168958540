import { useEffect, useState } from 'react';

import { Drawer, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import ButtonCard from './DrawerElements/ButtonCard';
import DeviceCard from './DrawerElements/DeviceCard';
import ItemCard from './DrawerElements/ItemCard';
import LatestActivityCard from './DrawerElements/LatestActivityCard';
import DeviceLogo from '../../../assets/images/icons-ic-skopei.png';
import { LoadingBar } from '../../../components';
import { handleLockType, isObject, isArray, isNull } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { useMainContext } from '../FleetManagement';
import { useStyles } from '../style';

const FleetDrawer = (props) => {
	const {
		onFetchInstance,
		fetchInstance,
		onResetState,
		onFetchItemImage,
		fetchItemImage,
		fetchDeviceHeartbeats,
	} = props;
	const { t } = useTranslation('general');

	const { selectedFleet, openFleetDrawer, setSelectedFleet, setSelectedIndex } = useMainContext();

	const { data: fetchInstanceData, loading: fetchInstanceLoading } = fetchInstance;

	const { data: fetchItemImageData, loading: fetchItemImageLoading } = fetchItemImage;

	const { data: fetchDeviceHeartbeatsData } = fetchDeviceHeartbeats;

	const [cardData, setCardData] = useState(null);

	const [requestedResources] = useState(5);

	const classes = useStyles();

	useEffect(() => {
		if (selectedFleet?.itemId && !fetchInstanceLoading) {
			onFetchInstance(selectedFleet.itemId, selectedFleet.instanceId);
			onFetchItemImage(selectedFleet?.itemId);
		}
	}, [selectedFleet]);

	useEffect(() => {
		const description = `${t('ui.label.firmwareVersion')} ${fetchDeviceHeartbeatsData?.firmwareVersion ? fetchDeviceHeartbeatsData.firmwareVersion : '-'}`;
		if (selectedFleet?.type === 'devices') {
			setCardData({
				name: `${selectedFleet.deviceId}`,
				subName: handleLockType(selectedFleet.deviceType),
				description: description,
				image: DeviceLogo,
				type: selectedFleet?.type,
			});
		} else if (
			isObject(fetchInstanceData) &&
			!fetchItemImageLoading &&
			isArray(fetchItemImageData)
		) {
			setCardData({
				name: fetchInstanceData.itemReference.name,
				subName: fetchInstanceData.name,
				description: description,
				image: fetchItemImageData[0],
			});
		}
	}, [selectedFleet, fetchInstanceData, fetchItemImageData]);

	useEffect(() => {
		if (!openFleetDrawer) {
			onResetState('fetchInstance');
			setCardData(null);
			onResetState('fetchItemImage');
			setSelectedFleet(null);
			setSelectedIndex(null);
		}
	}, [openFleetDrawer]);

	const handleLink = () =>
		cardData?.subName && selectedFleet?.itemId && selectedFleet?.instanceId ?
			<Link
				className={classes.itemGroup}
				color='inherit'
				component={RouterLink}
				to={`/item-management/${selectedFleet?.itemId}/instance/${selectedFleet?.instanceId}/summary`}
				state={{ from: location.pathname }}
			>
				{cardData.subName}
			</Link>
		:	<Typography>
				{isNull(cardData) ?
					<LoadingBar />
				:	cardData.subName}
			</Typography>;

	const handleLinkDevice = () =>
		cardData?.subName && selectedFleet ?
			<Link
				className={classes.deviceCardItems}
				color='inherit'
				component={RouterLink}
				to={`/devices/${selectedFleet?.deviceId}/summary`}
				state={{ from: location.pathname }}
			>
				{`${selectedFleet?.deviceId}`}
			</Link>
		:	null;

	const handleCardNameLink = () => (
		<Link
			color='inherit'
			component={RouterLink}
			to={{
				pathname:
					cardData.type === 'devices' ?
						`/devices/${selectedFleet?.deviceId}/summary`
					:	`/item-management/items/${selectedFleet?.itemId}/summary`,
			}}
			state={{ from: location.pathname }}
		>
			{cardData.type === 'devices' ? `${selectedFleet?.deviceId}` : cardData.name}
		</Link>
	);

	const deviceProps = {
		openFleetDrawer,
		selectedFleet,
		cardData,
		setCardData,
		handleLinkDevice,
	};

	const latestActivityProps = {
		itemInstanceId: selectedFleet?.instanceId,
		requestedResources: requestedResources,
		selectedFleet,
	};

	const drawerProps = {
		anchor: 'right',
		classes: { paper: classes.drawer },
		open: openFleetDrawer,
		hideBackdrop: true,
		variant: 'persistent',
	};

	return (
		<Drawer {...drawerProps}>
			<form>
				<ButtonCard />
				{/* <Canvas image={fetchItemImageData && fetchItemImageData[0]} width={100} height={100} /> */}
				<ItemCard
					cardData={cardData}
					handleCardNameLink={handleCardNameLink}
					handleLink={handleLink}
				/>
				{selectedFleet?.deviceId ?
					<DeviceCard {...deviceProps} />
				:	null}
				<LatestActivityCard {...latestActivityProps} />
			</form>
		</Drawer>
	);
};

FleetDrawer.propTypes = {
	openFleetDrawer: PropTypes.bool,
	selectedFleet: PropTypes.object,
	onFetchInstance: PropTypes.func,
	onResetState: PropTypes.func,
	onFetchItemImage: PropTypes.func,
	onFetchPlanboardItemsToAdd: PropTypes.func,
	selectedItem: PropTypes.object,
	setSelectedItem: PropTypes.func,
	onFetchItemInstances: PropTypes.func,
	setSelectedUser: PropTypes.func,
	selectedUser: PropTypes.object,
	onPlanboardBooking: PropTypes.func,
	setHasChangedItem: PropTypes.func,
	isUnavailability: PropTypes.bool,
	onFetchUnavailability: PropTypes.func,
	fetchItemImage: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchInstance: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchDeviceHeartbeats: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		fetchInstance: state.details.fetchInstance,
		fetchItemImage: state.details.fetchItemImage,
		planboardBooking: state.details.planboardBooking,
		planboardItemsToAdd: state.planboard.planboardItemsToAdd,
		itemInstancesList: state.paged.itemInstances,
		fetchUnavailability: state.details.fetchUnavailability,
		fetchDeviceHeartbeats: state.details.fetchDeviceHeartbeats,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchInstance: (itemId, instanceId) => dispatch(actions.fetchInstance(itemId, instanceId)),
		onResetState: (state) => dispatch(actions.resetState(state)),
		onFetchItemImage: (itemId) => dispatch(actions.fetchItemImage(itemId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetDrawer);
