import { useState, useEffect } from 'react';

import { Box, Typography, CircularProgress, Divider, Switch } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { ActionDialog, ReactDatePicker } from '../../../../components';
import {
	modifyDate,
	getDateRoundedToDuration,
	isEarlierThan,
	unsetTime,
	sameDates,
} from '../../../../shared/datetime';
import { useError } from '../../../../shared/hooks';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import palette from '../../../../theme/palette';

const SwitchButton = withStyles({
	switchBase: {
		color: '#586b85',
		'&$checked': {
			color: palette.button.primary,
		},
		'&$checked + $track': {
			backgroundColor: palette.button.primary,
		},
	},
	checked: {},
	track: {},
})(Switch);

const StopUnabailabilitysDialog = (props) => {
	const {
		open,
		setStopUnavailability,
		onStopUnavailability,
		stopUnavailability,
		data,
		onResetState,
		start,
		end,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	const time = new Date();

	const [switchTimeSelect, setSwitchTimeSelect] = useState(false);
	const [selectedEndDate, setSelectedEndDate] = useState(null);

	const [currentTime, setCurrentTime] = useState(null);

	const { data: stopUnavailabilityData, loading: stopUnavailabilityLoading } = stopUnavailability;

	const stopUnavailabilityMessage = useError({
		value: stopUnavailability,
		message: t('ui.alertDialog.description.stopActivUnavailability.message'),
	});

	useEffect(() => {
		if (isObject(data)) {
			setSelectedEndDate(time);
		}
	}, [data]);

	useEffect(() => {
		if (isObject(stopUnavailabilityData)) {
			setStopUnavailability(false);
			setSwitchTimeSelect(false);
			onResetState('stopUnavailability');
		}
	}, [stopUnavailabilityData]);

	useEffect(() => {
		if (sameDates(new Date(start), selectedEndDate, false, true)) {
			setCurrentTime(modifyDate(getDateRoundedToDuration(new Date(start), 'PT15M', true)));
		} else {
			setCurrentTime(unsetTime(time, { hours: 23, minutes: 45 }));
		}
	}, [selectedEndDate]);

	const updateEndDate = (date) => {
		setSelectedEndDate(date);
	};

	const handleConfirmDialog = () => {
		onStopUnavailability(data.id);
		stopUnavailabilityMessage.setStartAction(true);
	};

	const handleCloseDialog = () => {
		setStopUnavailability(false);
		setSelectedEndDate(new Date(end));
		setSwitchTimeSelect(false);
	};

	const handleSwitch = () => {
		setSwitchTimeSelect(!switchTimeSelect);
		if (!switchTimeSelect) {
			setSelectedEndDate(time);
		}
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirmDialog,
				text:
					stopUnavailabilityLoading ?
						<CircularProgress disableShrink size={24} />
					:	t('ui.confirm'),
			}}
			handleClose={handleCloseDialog}
			loading={false}
			noDivider={true}
			open={open}
			title={t('ui.alertDialog.description.stopActivUnavailability.title')}
			variant='contained-secondary'
		>
			<Box className={classes.sideCard}>
				<Box>
					<Typography>
						{t('ui.alertDialog.description.stopActivUnavailability.description')}
					</Typography>
				</Box>
				<div className={classes.switch}>
					<Typography>{t('views.actions.stopBooking.currentTime')}</Typography>
					<SwitchButton checked={!switchTimeSelect} onChange={handleSwitch} />
				</div>
				<Divider className={classes.divider} />
				{switchTimeSelect ?
					<Box mb={3}>
						<ReactDatePicker
							containerClassName={classes.dateTimePicker}
							label={t('views.planboard.addBooking.placeholder.endDate')}
							maxTime={modifyDate(time, { hours: 23, minutes: 45 })}
							minDate={new Date(start)}
							minTime={currentTime}
							onChange={(date) =>
								updateEndDate(
									(
										sameDates(new Date(start), date, false, true) &&
											isEarlierThan(date, new Date(start))
									) ?
										modifyDate(getDateRoundedToDuration(new Date(start), 'PT15M', true))
									:	date,
								)
							}
							selected={selectedEndDate}
							showTimeSelect
							wrapperClassName={classes.fullWidth}
						/>
					</Box>
				:	null}
			</Box>
		</ActionDialog>
	);
};

StopUnabailabilitysDialog.propTypes = {
	open: PropTypes.bool,
	kilometres: PropTypes.object,
	setStopUnavailability: PropTypes.func,
	selectedEndDate: PropTypes.object,
	onStopUnavailability: PropTypes.func,
	setSelectedEndDate: PropTypes.func,
	onResetState: PropTypes.func,
	data: PropTypes.object,
	start: PropTypes.any,
	end: PropTypes.any,
	stopUnavailability: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		stopUnavailability: state.details.stopUnavailability,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStopUnavailability: (id) => dispatch(actions.stopUnavailability(id)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StopUnabailabilitysDialog);
