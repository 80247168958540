import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	switchLabel: {
		width: '100%',
		justifyContent: 'space-between',
		margin: 0,
	},
	termsContainer: {
		padding: theme.spacing(5),
	},
	termsTitleContainer: {
		marginBottom: theme.spacing(3),
		position: 'relative',
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(-2.5),
		right: theme.spacing(-2.5),
	},
	link: {
		...theme.typography.h6,
		textDecoration: 'underline',
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));
