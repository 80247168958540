import React, { useRef, useState } from 'react';

import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';

import { AsyncAutocomplete, AsyncAutocompleteProps } from '~components';
import { useDebounce } from '~hooks';
import { BaseReference, PagedResponse } from '~interfaces';
import i18n from '~lib/i18n';

import Organisation from '../../interfaces/organisation';
import OrganisationsService from '../../services/organisationsService';

const service = new OrganisationsService();

interface OrganisationAutocompleteProps extends Omit<AsyncAutocompleteProps, 'options' | 'label'> {
	label?: string;
	count?: number;
}

const OrganisationAutocomplete = ({
	label = i18n.t('organisation'),
	count = 10,
	enableInfiniteScroll = true,
	disableInstantSearch = false,
	...asyncAutocompleteProps
}: OrganisationAutocompleteProps) => {
	const { t } = useTranslation('general');

	const [inputValue, setInputValue] = useState('');
	const debouncedSearchQuery = useDebounce(inputValue);
	const [initialLoad, setInitialLoad] = useState(false);

	// Let's set the on the first page response. Accept that it may happen
	// if an organisation is added in the mean time
	// Small changes right..?
	const total = useRef<number>(null!);
	const page = useRef(1);

	const getKey = (i: number, previousData: PagedResponse<Organisation[]>) => {
		if (!initialLoad || (previousData && !previousData.results.length)) {
			// reached the end
			return null;
		}

		page.current = i + 1;

		return [
			service.basePath,
			{
				page: page.current,
				pageSize: count,
				searchQuery: debouncedSearchQuery || undefined
			}
		];
	};

	const { data, isLoading, isValidating, size, setSize } = useSWRInfinite(
		getKey,
		([_, args]) => service.getOrganisations(args),
		{
			keepPreviousData: true,
			revalidateFirstPage: false,
			onSuccess: (res) => (total.current = res[0].total),
		},
	);

	/**
	 * Just set the size of the pages
	 */
	const handleOverflow = () => {
		if ((size - 1) * count <= total.current) {
			setSize(size + 1);
		}
	};

	const handleChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: NonNullable<string | BaseReference> | (string | BaseReference)[] | null,
		reason: AutocompleteChangeReason,
	) => {
		if (asyncAutocompleteProps.onChange) {
			asyncAutocompleteProps.onChange(e, value, reason);
		}
	};

	const handleInputChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: string,
		reason: AutocompleteInputChangeReason,
	) => {
		setInputValue(value);

		if (asyncAutocompleteProps.onInputChange) {
			asyncAutocompleteProps.onInputChange(e, value, reason);
		}
	};

	return (
		<AsyncAutocomplete
			{...asyncAutocompleteProps}
			disableInstantSearch={disableInstantSearch}
			label={label}
			loading={isLoading || isValidating}
			noOptionsText={t('noResults')}
			onOpen={() => setInitialLoad(true)}
			options={data ? data.flatMap((el) => el.results) : []}
			onChange={handleChange}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			enableInfiniteScroll={enableInfiniteScroll}
			onOverflow={handleOverflow}
			sx={{
				minWidth: 220,
				...asyncAutocompleteProps.sx
			}}
		/>
	);
};

export default OrganisationAutocomplete;
