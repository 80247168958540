import path from 'path';

import { Tab, Tabs } from '@mui/material';
import { Link, useMatches } from 'react-router-dom';

interface Page {
	label: string;
	path: string;
}

interface PageNavBarProps {
	pages: Page[];
}

const PageNavBar = ({ pages }: PageNavBarProps) => {
	const matches = useMatches();

	/**
	 * Split the last path entry from the path and keep the parent path.
	 * We use the useMatches for this, because if keeps a predictable
	 * trailing slash we can split on
	 */
	const pathElements = matches[matches.length - 1].pathname.split('/');
	const lastPath = pathElements.pop();
	const parentPath = pathElements.join('/');

	return (
		<Tabs value={lastPath}>
			{pages.map((page) => {
				const url = new URL(path.join(parentPath, page.path), window.origin).href;

				return (
					<Tab
						key={page.path}
						component={Link}
						value={page.path}
						to={url}
						label={page.label}
						relative='path'
					/>
				);
			})}
		</Tabs>
	);
};

export default PageNavBar;
