import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	avatar: {
		marginRight: theme.spacing(1),
		width: theme.spacing(4.5),
		height: theme.spacing(4.5),
	},
	confirmButton: {
		marginTop: 'auto',
	},
}));
