import React, { useEffect, useRef, useState } from 'react';

import { Search as SearchIcon } from '@mui/icons-material';
import {
	Autocomplete,
	AutocompleteProps,
	Avatar,
	CircularProgress,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';

import { BaseReference } from '~interfaces';

interface AsyncAutocompleteProps
	extends Omit<
		AutocompleteProps<BaseReference, boolean | undefined, boolean | undefined, boolean | undefined>,
		'renderInput'
	> {
	label: string;
	/**
	 * An onChange is trigger when user presses the search button
	 * TODO: not working
	 */
	disableInstantSearch?: boolean;
	/**
	 * Enable inifinite scroll functionality. Use this with the
	 * onOverflow handler
	 */
	enableInfiniteScroll?: boolean;
	/**
	 * An event raised when the list overflows. E.g. on a scroll
	 */
	onOverflow?: (e: React.WheelEvent<HTMLLIElement>) => void;
	required?: boolean;
}

const AsyncAutocomplete = ({
	label,
	disableInstantSearch = false,
	enableInfiniteScroll = false,
	onOverflow,
	required = false,
	...autocompleteProps
}: AsyncAutocompleteProps) => {
	const [open, setOpen] = useState(false);

	/**
	 * Handle opening of the autocomplete
	 * @param e
	 * @param newValue
	 */
	const handleOpen = (e: React.SyntheticEvent, newValue: boolean) => {
		setOpen(newValue);
		if (autocompleteProps.onOpen && newValue === true) {
			autocompleteProps.onOpen(e);
		}
	};

	/**
	 * Handle scrolling of the wheel
	 * @returns
	 */
	const handleWheel = (e: React.WheelEvent<HTMLLIElement>) => {
		if (!enableInfiniteScroll) {
			return;
		}

		const ulElement = (e.target as HTMLLIElement).parentElement as HTMLUListElement;
		const isBottomList = ulElement.scrollHeight - ulElement.scrollTop - 1 <= ulElement.clientHeight;
		if (isBottomList && !autocompleteProps.loading && onOverflow) {
			onOverflow(e);
		}
	};

	return (
		<Autocomplete
			{...autocompleteProps}
			// freeSolo
			value={autocompleteProps.value ?? null}
			open={open}
			onOpen={(e) => handleOpen(e, true)}
			onClose={(e) => handleOpen(e, false)}
			filterOptions={(options) => options}
			isOptionEqualToValue={(option, value) => option.id == value.id}
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={label}
					slotProps={{
						input: {
							...params.InputProps,
							...(autocompleteProps.size === 'small' && {
								sx: { height: 44 },
							}),
							endAdornment: (
								<InputAdornment position='end'>
									{disableInstantSearch && (
										<IconButton>
											<SearchIcon />
										</IconButton>
									)}
									{autocompleteProps.loading && <CircularProgress color='inherit' size={20} />}
									{params.InputProps.endAdornment}
								</InputAdornment>
							),
						},
						inputLabel: {
							...params.InputLabelProps,
							sx: {
								...(autocompleteProps.size === 'small' && {
									// Arbitrary value, determined by eye to line it out in the middel
									top: 5,
									'&.MuiInputLabel-shrink': { top: 0 },
								}),
							}
						}
					}}
				/>
			)}
			ListboxProps={{
				onWheelCapture: handleWheel,
			}}
		/>
	);
};

export type { AsyncAutocompleteProps };

export default AsyncAutocomplete;
