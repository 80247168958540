import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		margin: theme.spacing(2, 0, 2, 0),
		'& .MuiCardContent-root:last-child': {
			paddingBottom: '12px',
		},
	},
	cardContent: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	commentContent: {
		display: 'flex',
		flexDirection: 'column',
	},
}));
