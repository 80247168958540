import { Box, Typography, Switch } from '@mui/material';
import PropTypes from 'prop-types';

const OnHoldSwitch = (props) => {
	const { checked, actions, label } = props;
	return (
		<div>
			<Box alignItems='center' display='flex'>
				<Typography variant='h6'>{label}</Typography>
				<Switch checked={checked} disabled={false} onChange={actions} size='small' />
			</Box>
		</div>
	);
};

OnHoldSwitch.propTypes = {
	checked: PropTypes.bool,
	actions: PropTypes.func,
	label: PropTypes.string,
};
export default OnHoldSwitch;
