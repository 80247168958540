import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton } from '../../../../components';

const Header = (props) => {
	const { className, deviceData, loading } = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const navigate = useNavigate();

	const classes = useStyles();

	const handleBackButtonChange = () => {
		if (location?.state?.backPathname) {
			navigate(location.state.backPathname);
		} else {
			if (location?.state?.from) {
				navigate(location.state.from);
			} else {
				navigate('/devices/assigned');
			}
		}
		sessionStorage.removeItem('pageNumberHeartbeats');
		sessionStorage.removeItem('pageSizeHeartbeats');
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{t('ui.back')}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				{loading ?
					<AdjustedSkeleton animation='wave' width={200} />
				:	`HW UID: ${deviceData.deviceId}`}
			</Typography>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,

	deviceData: PropTypes.object,
	loading: PropTypes.bool,
};

export default Header;
