import { useTranslation } from 'react-i18next';

import { useAuthorize } from '~features/authentication';
import { UserInvitationsDataGrid } from '~features/users';
import UserInvitationsOverviewLayout from '~features/users/layouts/userInvitationsOverviewLayout';

import Page from '../page';

const UserInvitationsPage = () => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	return (
		<Page title={t('nfcTags')}>
			<UserInvitationsOverviewLayout />
		</Page>
	);
};

export default UserInvitationsPage;
