enum BookingStatusEnum {
	Completed = 'completed',
	Upcoming = 'upcoming',
	Active = 'active',
	Cancelled = 'cancelled',
	// Waiting to be approved or rejected
	Pending = 'pending',
	Overtime = 'overtime',
}

export default BookingStatusEnum;
