import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react';

interface FormContainerProps {
	handlers: {
		onSubmit?: <T>(value: T) => void
	};
	disabled?: boolean;
	setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormContainerContext = createContext<FormContainerProps>({
	handlers: {},
	disabled: true
});

const useFormContainerState = () => {
	const formContainerContext = useContext(FormContainerContext);
	if (formContainerContext === undefined) {
		throw new Error('useOnboardingContext must be inside a OnboardingProvider');
	}

	return formContainerContext;
};

const FormContainerProvider = ({children}: PropsWithChildren) => {
	const state = useRef({});
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = () => {
		const value = state.current.onSubmit();
	};

	const value = useMemo<FormContainerProps>(() => ({
		disabled: disabled,
		setDisabled: setDisabled,
		onSubmit: handleSubmit
	}), [disabled, setDisabled]);

	return (
		<FormContainerContext.Provider value={value}>
			{children}
		</FormContainerContext.Provider>
	);
};

export type { FormContainerProps };
export { useFormContainerState };
export default FormContainerContext;
