import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Card, CardHeader, CardContent, Divider, Typography } from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { ButtonWithAlertDialog } from '../../../../../../components';
import { isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

const OutgoingRequestActions = (props) => {
	const { className, partner, onUpdatePartnershipStatus, updatedPartnership } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const [updatingPartnershipStatus, setUpdatingPartnershipStatus] = useState(false);

	const {
		data: updatedPartnershipData,
		loading: updatedPartnershipLoading,
		error: updatedPartnershipError,
	} = updatedPartnership;
	const updatePartnerShipStatusDone =
		isObject(updatedPartnershipData) && !updatedPartnershipLoading && !updatedPartnershipError;

	const handleCancelRequest = () => {
		setUpdatingPartnershipStatus(true);
		onUpdatePartnershipStatus(partner.partnerOrganisation.id, 'cancel');
	};

	// in case status has been successfully updated
	useEffect(() => {
		if (updatingPartnershipStatus && updatePartnerShipStatusDone) {
			setUpdatingPartnershipStatus(false);
			enqueueSnackbar(
				`${t('views.partnerships.messages.success.cancel')} ${partner.partnerOrganisation.name}`,
				{ variant: 'success' },
			);
			navigate('/partnerships/outgoing-requests');
		}
	}, [updatePartnerShipStatusDone, updatingPartnershipStatus]);

	// in case there's an error with updating the status
	useEffect(() => {
		if (updatingPartnershipStatus && !updatedPartnershipLoading && updatedPartnershipError) {
			setUpdatingPartnershipStatus(false);
			enqueueSnackbar(
				isObject(updatedPartnershipError) ?
					updatedPartnershipError.message
				:	updatedPartnershipError,
				{ variant: 'error' },
			);
		}
	}, [updatedPartnershipError, updatingPartnershipStatus, updatedPartnershipLoading]);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.partnerDetail.summary.cardHeaders.outgoingRequestActions')} />
			<Divider />
			<CardContent>
				<Typography variant='body2'>{t('views.partnerDetail.summary.cancelInfo')}</Typography>
				<ButtonWithAlertDialog
					actionDone={updatePartnerShipStatusDone}
					callback={handleCancelRequest}
					className={classes.cancelButton}
					dialogDescription={t('views.partnerDetail.summary.cancelDialog.description')}
					dialogLoading={updatedPartnershipLoading && !updatePartnerShipStatusDone}
					dialogTitle={t('views.partnerDetail.summary.cancelDialog.title')}
					startIcon={<DeleteIcon />}
					variant='inline-delete'
				>
					{t('view.partnershipmanagement.button.inline.cancelrequest')}
				</ButtonWithAlertDialog>
			</CardContent>
		</Card>
	);
};

OutgoingRequestActions.propTypes = {
	className: PropTypes.string,

	partner: PropTypes.object,
	updatedPartnership: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onUpdatePartnershipStatus: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		fetchPartnerships: state.paged.fetchPartnerships,

		updatedPartnership: state.details.updatedPartnership,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdatePartnershipStatus: (partnerId, state) =>
			dispatch(actions.updatePartnershipStatus(partnerId, state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingRequestActions);
