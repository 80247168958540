import path from 'path';

import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

import { pagePathSegments, pagePaths } from '~constants';

const callbackRedirectExcludes: string[] = [
	path.join(pagePathSegments.Auth, pagePathSegments.Callback),
	path.join(pagePathSegments.Auth, pagePathSegments.Login),
	path.join(pagePathSegments.Auth, pagePathSegments.Logout),
];

const oidcConfig: AuthProviderProps = {
	authority: import.meta.env.VITE_IDENTITY_AUTHORITY,
	client_id: import.meta.env.VITE_IDENTITY_CLIENT_ID,
	automaticSilentRenew: true,
	redirect_uri: new URL(pagePaths.AuthCallback, window.origin).href,
	response_type: 'code',
	scope: 'openid profile email skopei_api offline_access',
	// scope: 'openid profile email skopei_api',
	post_logout_redirect_uri: new URL(pagePaths.AuthLogin, window.origin).href,
	silent_redirect_uri: new URL(pagePaths.AuthSilent, window.origin).href,
	// revokeAccessTokenOnSignout: true
	userStore: new WebStorageStateStore({ store: localStorage }),
	filterProtocolClaims: true,
	loadUserInfo: true,
	monitorSession: false,
	onSigninCallback: (user) => {
		if (user?.url_state) {
			let urlStateRedirect = true;

			for (const callbackRedirectExclude of callbackRedirectExcludes) {
				if (user.url_state?.includes(callbackRedirectExclude)) {
					urlStateRedirect = false;
					break;
				}
			}

			location.href = urlStateRedirect ? user.url_state : window.location.origin;
			return;
		}

		location.href = window.location.origin;
	},
};

export default oidcConfig;
