import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EmptyListIcon from '~components/elements/EmptyState/assets/booking.svg?react';
import i18n from '~lib/i18n';

interface ResourceNotFoundPageProps {
	/**
	 * Specify the type of resource
	 */
	type: string;
	/**
	 * Specify the specific resource, e.g. a name of uid
	 */
	label: string;
}

const ResourceNotFoundPage = ({ type, label }: ResourceNotFoundPageProps) => {
	const { t } = useTranslation('general');

	return (
		<Stack
			width={1}
			height={1}
			display='flex'
			alignItems='center'
			justifyContent='center'
			direction='column'
			spacing={1}
		>
			<EmptyListIcon />
			<Typography variant='h6'>{t('noResourceFound', { resource: `${type} ${label}` })}</Typography>
		</Stack>
	);
};

export default ResourceNotFoundPage;
