import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	tipCard: {
		backgroundColor: '#e4eefb',
		color: '#15263e',
		boxShadow: 'none',
		padding: '0.632rem',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		flexShrink: 3,
		width: 'fit-content',
	},
	tip: {
		...theme.typography.pagination,
	},
	icon: {
		marginRight: theme.spacing(1),
	},
	detailTitle: {
		textDecoration: 'underline',
		...theme.typography.body3,
	},
	tableText: {
		...theme.typography.body3,
	},
}));
const useStylesMyTooltip = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.white,
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
	tooltip: {
		backgroundColor: '#ffffff',
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
}));

export function MyTooltip(props) {
	const classes = useStylesMyTooltip();
	return <Tooltip classes={classes} {...props} />;
}
