import CloseIcon from '@mui/icons-material/Close';
import EastIcon from '@mui/icons-material/East';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../../../components';

const HeaderCard = (props) => {
	const { onClick, itemName, sharedWith } = props;
	const { t } = useTranslation('general');

	return (
		<Box rowGap={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
			<StyledButton onClick={onClick} size='small' startIcon={<CloseIcon />}>
				{t('ui.button.inline.close')}
			</StyledButton>
			<Typography variant='h3'>{t('views.sharing.drawer.header')}</Typography>
			<Box gap={2} sx={{ display: 'flex' }}>
				<Typography variant='h5'>{itemName} </Typography>
				<EastIcon fontSize='small' />
				<Typography variant='h5'>{sharedWith} </Typography>
			</Box>
		</Box>
	);
};

HeaderCard.propTypes = {
	onClick: PropTypes.func,
	itemName: PropTypes.string,
	sharedWith: PropTypes.string,
};

export default HeaderCard;
