import { useEffect, useState } from 'react';

import { Card, CardHeader, CardContent, Divider, Typography, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { AlertDialog, SelectWithLazyLoading } from '../../../../../../components';
import { isObject, isSuperAdmin, isUndefined } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
import { usePoliciesChange } from '../itemHooks';
import { useStyles } from '../style';

const AssignServiceProvider = (props) => {
	const {
		className,
		isOwnItem,
		itemData,
		partners,
		onFetchPartners,
		onItemPatch,
		itemPatch,
		onUpdateDetailsState,

		onResetState,
		currentUser,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const { enqueueSnackbar } = useSnackbar();

	const classes = useStyles();

	const serviceProvider = usePoliciesChange(
		itemData?.serviceProviderReference ?
			{
				id: itemData?.serviceProviderReference.id,
				name: itemData?.serviceProviderReference.name,
			}
		:	{
				id: itemData.hubReference.organisationReference.id,
				name: itemData.hubReference.organisationReference.name,
			},
	);

	const myServiceProvider = {
		...partners,
		data: {
			...partners.data,
			results: partners.data?.results.concat([
				{
					partnerOrganisation: {
						id: itemData.hubReference.organisationReference.id,
						name: itemData.hubReference.organisationReference.name,
					},
				},
			]),
		},
	};

	const [organisationIdFilter] = useState({ name: 'status', value: 'accepted' });

	const { data: patchData, loading: patchLoading, error: patchError } = itemPatch;
	const patchReady = isObject(patchData) && !patchLoading && !patchError;

	const { data: currentUserData } = currentUser;

	useEffect(() => {
		if (
			isObject(patchData) &&
			(itemData?.serviceProviderReference?.id !== patchData?.serviceProviderReference?.id ||
				(isUndefined(itemData?.serviceProviderReference) && patchData?.serviceProviderReference))
		) {
			onUpdateDetailsState('itemDetails', patchData);
			enqueueSnackbar(
				`${t('ui.success.message.updated')} ${isObject(itemPatch.data) ? itemPatch.data.name : '-'}`,
				{ variant: 'success' },
			);
			onResetState('itemPatch');
			serviceProvider.setOpenDialog(false);
		}
	}, [patchData]);

	const handleConfirmDialog = () => {
		const patchItemBody = {
			serviceProviderId:
				serviceProvider.initialValue.id !== currentUserData?.organisationReference?.id ?
					serviceProvider.initialValue.id
				:	null,
		};
		onItemPatch(itemData.id, patchItemBody);
	};

	return (
		<Card className={clsx(classes.card, className)}>
			<CardHeader title={t('views.itemDetail.summary.section.serviceOrganisation.title')} />
			<Divider />
			<CardContent>
				<Typography pb={2} variant='h6'>
					{t('views.itemDetail.summary.subtitle.assignedServiceProvider')}
				</Typography>
				<FormControl fullWidth variant='standard'>
					{isOwnItem && !isSuperAdmin(auth.user?.profile.role) ?
						<>
							<SelectWithLazyLoading
								dataList={myServiceProvider}
								filter={organisationIdFilter}
								listType='partners'
								onFetchData={onFetchPartners}
								placeholder={t('ui.placeholders.search.serviceProvider')}
								setSelected={serviceProvider.change}
								value={serviceProvider.initialValue.name}
							/>
							<AlertDialog
								dialogDescription={t(
									'views.itemDetail.summary.alertDialog.updateServiceOrganisation.description',
								)}
								dialogTitle={t(
									'views.itemDetail.summary.alertDialog.updateServiceOrganisation.title',
								)}
								handleClose={serviceProvider.cancelDialog}
								handleConfirm={handleConfirmDialog}
								loading={patchLoading && !patchReady}
								open={serviceProvider.openDialog}
							/>
						</>
					:	null}
				</FormControl>
			</CardContent>
		</Card>
	);
};

AssignServiceProvider.propTypes = {
	className: PropTypes.string,
	isOwnItem: PropTypes.bool,
	itemData: PropTypes.object,
	onFetchPartners: PropTypes.func,
	onItemPatch: PropTypes.func,
	onUpdateDetailsState: PropTypes.func,

	onResetState: PropTypes.func,
	partners: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	itemPatch: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		partners: state.paged.fetchPartnerships,
		itemPatch: state.details.itemPatch,

		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPartners: (page, filters, concat) =>
			dispatch(actions.fetchPartnerships(page, filters, concat)),
		onFetchPartner: (id) => dispatch(actions.partner(id)),
		onItemPatch: (itemId, properties) => dispatch(actions.itemPatch(itemId, properties)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignServiceProvider);
