import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import Placeholder from '../../../assets/images/ic-placeholder.svg';
import { LoadingBar } from '../../../components';

const SelectedItemCard = (props) => {
	const { name, address, image, hasCloseButton, handleClose, loading } = props;
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			{loading ?
				<LoadingBar
					style={{
						height: 80,
						width: 80,
						marginLeft: 8,
						marginTop: 8,
						marginBottom: 8,
					}}
				/>
			:	<img
					className={clsx({
						[classes.media]: true,
						[classes.placeholder]: !image,
					})}
					src={image ? image : Placeholder}
				/>
			}
			<CardContent>
				<Typography variant='h5'>
					{loading ?
						<>
							<LoadingBar />
							<LoadingBar />
						</>
					:	name}
				</Typography>
				{address ?
					<Typography variant='body2'>{address}</Typography>
				:	null}
			</CardContent>
			{hasCloseButton ?
				<IconButton className={classes.closeButton} onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			:	null}
		</Card>
	);
};

SelectedItemCard.propTypes = {
	name: PropTypes.string,
	address: PropTypes.string,
	image: PropTypes.string,
	hasCloseButton: PropTypes.bool,
	handleClose: PropTypes.func,
	loading: PropTypes.bool,
};

SelectedItemCard.defaultProps = {
	hasCloseButton: true,
};

export default SelectedItemCard;
