import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
	},
	sidebar: {
		marginTop: theme.spacing(9.1),
	},
}));
