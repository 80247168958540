import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserGroupDetails } from './UserGroupDetails';
import { Wizard } from '../../../../components';
import { isObject, isUndefined, isEmptyObject, isEmptyString } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const AddUserGroup = (props) => {
	const { onAddUserGroup } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [userGroupName, setUserGroupName] = useState('');
	const [isValidName, setIsValidName] = useState(false);
	const [organisation, setOrganisation] = useState({});
	const [description, setDescription] = useState('');

	const {
		data: addedUserGroupData,
		loading: addedUserGroupLoading,
		error: addedUserGroupError,
	} = props.addUserGroupList;
	const addUserGroupDone =
		isObject(addedUserGroupData) && !addedUserGroupLoading && !addedUserGroupError;

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);

	//add userGroup
	const handleAddUserGroup = () => {
		onAddUserGroup(
			userGroupName,
			organisation.id,
			!isEmptyString(description) ? description : null,
		);
	};

	// redirect on when userGroup has been added succesfully
	useEffect(() => {
		if (startedFinishWizard && addUserGroupDone) {
			enqueueSnackbar(`${t('views.addUserGroup.messages.success')} ${addedUserGroupData.name}`, {
				variant: 'success',
			});
			navigate('/user-management/user-groups');
		}
	}, [addUserGroupDone, startedFinishWizard]);

	useEffect(() => {
		if (startedFinishWizard && !!addedUserGroupError) {
			setStartedFinishWizard(false);
			enqueueSnackbar(
				isObject(addedUserGroupError) ? addedUserGroupError.message : addedUserGroupError,
				{ variant: 'error' },
			);
		}
	}, [addedUserGroupError, startedFinishWizard]);

	const callback = (data) => {
		const {
			userGroupName: savedUserGroupName,
			userGroupDescription,
			organisation: selectedOrganisation,
			finishedWizard,
		} = data;

		if (!isUndefined(savedUserGroupName)) {
			setUserGroupName(savedUserGroupName.value);
			setIsValidName(savedUserGroupName.valid);
		}

		if (!isUndefined(selectedOrganisation)) {
			setOrganisation(selectedOrganisation.value);
		}

		if (!isUndefined(userGroupDescription)) {
			setDescription(userGroupDescription.value);
		}

		if (finishedWizard && !startedFinishWizard) {
			handleAddUserGroup();
			setStartedFinishWizard(true);
		}
	};
	const addUserSteps = [
		{
			name: 'User Groups',
			content: UserGroupDetails,
			valid: !isEmptyObject(organisation) && userGroupName && isValidName,
			props: {
				userGroupName,
				organisation,
				description,
			},
		},
	];
	return (
		<Wizard
			callback={callback}
			loading={startedFinishWizard}
			stepperType={'steps'}
			steps={addUserSteps}
			title={t('views.addUserGroup.page.title')}
		/>
	);
};
AddUserGroup.propTypes = {
	addUserGroupList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onAddUserGroup: PropTypes.func,
};
const mapStateToProps = (state) => {
	return {
		addUserGroupList: state.details.addUserGroup,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onAddUserGroup: (name, organisationId, description) =>
			dispatch(actions.addUserGroup(name, organisationId, description)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserGroup);
