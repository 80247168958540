import {
	Dialog as MuiDialog,
	DialogProps,
	Stack,
	Typography,
	useTheme,
	useMediaQuery,
	Box,
} from '@mui/material';

const Dialog = ({ children, ...dialogProps }: DialogProps) => {
	const theme = useTheme();
	const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<MuiDialog fullScreen={!largerThanMd} {...dialogProps}>
			<Stack spacing={3} p={3} display='flex' height={1}>
				<Typography variant='h4' width={1}>
					{dialogProps.title}
				</Typography>
				<Box flexGrow={1}>
					{children}
				</Box>
			</Stack>
		</MuiDialog>
	);
};

export default Dialog;
