import { useEffect, useRef } from 'react';

import { Popper } from '@mui/material';
import PropTypes from 'prop-types';

const InputPopper = (props) => {
	const { inputRef, openPopper, children } = props;

	const popperRef = useRef(null);

	useEffect(() => {
		if (popperRef.current) {
			popperRef.current.update();
		}
	}, []);

	return (
		<Popper
			anchorEl={inputRef.current ? inputRef.current : null}
			disablePortal
			modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
			open={openPopper}
			popperRef={popperRef}
			style={{ width: inputRef.current ? inputRef.current.clientWidth : null, zIndex: 2 }}
		>
			{children}
		</Popper>
	);
};

InputPopper.defaultProps = {
	inputRef: {},
	openPopper: false,
	children: null,
};

InputPopper.propTypes = {
	inputRef: PropTypes.object,
	openPopper: PropTypes.bool,
	children: PropTypes.node,
};

export default InputPopper;
