import { useState, useEffect } from 'react';

import {
	Modal,
	Card,
	CardContent,
	CardActions,
	Grid,
	Typography,
	TextField,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import { useStyles } from './style';
import { FormField, StyledButton } from '../../../../../../../components';
import { descriptionMaxLength } from '../../../../../../../constantsOld';
import { useWizardFormField, useError } from '../../../../../../../shared/hooks';
import { isObject } from '../../../../../../../shared/utility';
import { validateInput } from '../../../../../../../shared/validation';
import * as actions from '../../../../../../../store/actions';

const descriptionLimits = {
	maxLength: descriptionMaxLength,
};

const InformationEdit = (props) => {
	const {
		open,
		onClose,
		itemData,
		className,

		onItemPatch,
		itemPatch,
		onValidateItemName,
		validateItemName,
		openEdit,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const [formState, setFormState] = useState({
		...itemData,
	});

	const [patchItemBody, setPatchItemBody] = useState(null);
	const patchItemErrorHandling = useError({
		value: itemPatch,
		message: `${t('ui.success.message.updated')} ${isObject(itemPatch.data) ? itemPatch.data.name : '-'}`,
		variant: 'success',
	});
	const [updatingItem, setUpdatingItem] = useState(false);
	const updatingDone = isObject(itemPatch.data) && !itemPatch.loading && !itemPatch.error;

	useEffect(() => {
		if (updatingItem && updatingDone) {
			setUpdatingItem(false);
			onClose();
			onResetStateCondition('validateItemName');
		}
	}, [updatingItem, updatingDone]);

	const {
		data: validateItemNameData,
		loading: validateItemNameLoading,
		error: validateItemNameError,
	} = validateItemName;
	const validateItemNameDone =
		isObject(validateItemNameData) && !validateItemNameLoading && !validateItemNameError;

	const itemName = useWizardFormField(itemData.name, {
		required: true,
		minLength: 3,
		maxLength: 128,
	});

	const isValidTypingName = itemName.value === itemData.name;
	const isValidTypingDescription = formState.description === itemData.description;

	const [isUniqueItemName, setIsUniqueItemName] = useState(true);

	const debouncedNameValue = useDebounce(itemName.value, 300);

	useEffect(() => {
		if (openEdit && (!isValidTypingName || debouncedNameValue)) {
			onValidateItemName(debouncedNameValue, itemData.hubReference.organisationReference.id);
		}
	}, [debouncedNameValue]);

	const resetData = () => {
		itemName.resetToInitialValue();
	};

	useEffect(() => {
		resetData();
	}, [itemData]);

	useEffect(() => {
		if (isObject(validateItemNameData)) {
			setIsUniqueItemName(validateItemNameData.allowed);
		}
	}, [validateItemNameData, validateItemNameLoading, validateItemNameError]);

	useEffect(() => {
		if (
			isObject(validateItemNameData) &&
			!validateItemNameData.allowed &&
			itemName.value === itemData.name
		) {
			setIsUniqueItemName(true);
		}
	}, [validateItemNameData]);

	const [description, setDescription] = useState({
		value: itemData.description,
		valid: validateInput('', descriptionLimits),
		error: null,
		focus: false,
		touched: false,
	});

	const formValid = !isValidTypingName ? isUniqueItemName && itemName.isValid : false;

	useEffect(() => {
		if (formValid) {
			setPatchItemBody({ ...patchItemBody, name: itemName.value });
		}
	}, [formValid]);

	if (!open) {
		return null;
	}

	const handleDescriptionChange = (event) => {
		const desc = event.target.value;
		event.persist();
		setFormState((descriptionValue) => ({
			...descriptionValue,
			[event.target.name]: event.target.type === 'checkbox' ? event.target.checked : desc,
		}));
		setPatchItemBody({ ...patchItemBody, description: desc });
		setDescription({
			...description,
			...{
				value: desc,
				touched: true,
				valid: validateInput(desc, descriptionLimits),
			},
		});
	};

	const handleEdit = () => {
		onItemPatch(itemData.id, { description: description.value, name: itemName.value });
		patchItemErrorHandling.setStartAction(true);
		setUpdatingItem(true);
		// setNameChange(false);
	};

	const handleCancel = () => {
		setFormState({ ...itemData });
		onClose();
		setIsUniqueItemName(isUniqueItemName);
		resetData();
		onResetStateCondition('validateItemName');
		// setNameChange(false);
	};

	return (
		<Modal onClose={onClose} open={open}>
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<Typography align='center' gutterBottom variant='h3'>
						Edit
					</Typography>
					<Grid className={classes.container} container spacing={3}>
						<Grid item md={12} xs={12}>
							<FormField
								extraValidHelperText={t('views.addItem.itemDetails.itemName')}
								isExtraValid={isUniqueItemName}
								maxLength={25}
								name='itemName'
								variable={itemName}
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<Card>
								<CardContent>
									<div className={classes.description}>
										<Typography variant='h6'>{t('ui.label.description')}</Typography>
										<Typography>{`${description?.value ? description.value.length : 0}/${descriptionLimits.maxLength}${''}`}</Typography>
									</div>
									<TextField
										InputLabelProps={{
											shrink: true,
										}}
										error={description.touched && !description.valid}
										fullWidth
										inputProps={{ ...descriptionLimits }}
										multiline
										name='description'
										onChange={handleDescriptionChange}
										rows={4}
										value={formState.description}
										variant='outlined'
									/>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					<CardActions className={classes.actions}>
						<StyledButton onClick={handleCancel} variant='contained-tertiary'>
							{t('ui.button.contained.cancel')}
						</StyledButton>
						<StyledButton
							disabled={!validateItemNameDone && !formValid && isValidTypingDescription}
							onClick={handleEdit}
							variant='contained-secondary'
						>
							{validateItemNameLoading || updatingItem ?
								<CircularProgress className={classes.progress} size={25} />
							:	t('ui.button.contained.save')}
						</StyledButton>
					</CardActions>
				</CardContent>
			</Card>
		</Modal>
	);
};

InformationEdit.displayName = 'InformationEdit';

InformationEdit.propTypes = {
	className: PropTypes.string,
	itemData: PropTypes.object,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	onItemPatch: PropTypes.func,
	itemPatch: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onValidateItemName: PropTypes.func,
	validateItemName: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onResetStateCondition: PropTypes.func,
	openEdit: PropTypes.bool,
};

InformationEdit.defaultProps = {
	open: false,
	onClose: () => {},
};

const mapStateToProps = (state) => {
	return {
		validateItemName: state.details.validateItemName,
		itemPatch: state.details.itemPatch,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onItemPatch: (itemId, properties) => dispatch(actions.itemPatch(itemId, properties)),
		onValidateItemName: (name, id) => dispatch(actions.validateItemName(name, id)),
		onResetStateCondition: (identifier) => dispatch(actions.resetStateCondition(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationEdit);
