import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Card, CardHeader, CardContent, Divider, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { ButtonWithAlertDialog } from '../../../../../../components';
import { useError } from '../../../../../../shared/hooks';
import * as actions from '../../../../../../store/actions';

const OtherActions = (props) => {
	const { hubId, className, deleteHub, onDeleteHub, hub } = props;
	const { t } = useTranslation('general');

	const { success: deleteHubSuccess, loading: deleteHubLoading, error: deleteHubError } = deleteHub;
	const deleteHubDone = deleteHubSuccess && !deleteHubLoading && !deleteHubError;

	const classes = useStyles();

	const errorHandle = useError(
		deleteHub ?
			{
				value: deleteHub,
				message: `${t('ui.successfully')} ${t('ui.updated')} ${hub.name}`,
			}
		:	null,
	);

	const handleDeleteHub = () => {
		onDeleteHub(hubId);
		errorHandle.setStartAction(true);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('ui.otherActions')} />
			<Divider />
			<CardContent>
				<Typography className={classes.notice} variant='body2'>
					{t('views.locationDetail.summary.deleteWarning')}
				</Typography>
				<ButtonWithAlertDialog
					actionDone={deleteHubDone}
					callback={handleDeleteHub}
					dialogDescription={`${t('ui.pleaseConfirmThatYouWantTo')} ${t('ui.delete.lowerText')}  ${hub.name}`}
					dialogLoading={deleteHubLoading && !deleteHubError}
					dialogTitle={t('ui.delete')}
					startIcon={<NotInterestedIcon />}
					textDialog={{
						title: t('views.locations.textDialog.title'),
						description: `${t('views.locations.textDialog.description')}`,
						notAllowed: hub.hasItems,
					}}
					variant='inline-delete'
				>
					{t('view.locationmanagement.locationdetails.button.inline.deletelocation')}
				</ButtonWithAlertDialog>
			</CardContent>
		</Card>
	);
};

OtherActions.propTypes = {
	className: PropTypes.string,

	onDeleteHub: PropTypes.func,
	deleteHub: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	hubId: PropTypes.number.isRequired,
	hub: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		deleteHub: state.condition.deleteHub,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeleteHub: (hubId) => dispatch(actions.deleteHub(hubId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherActions);
