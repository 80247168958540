import { useState } from 'react';

function useMonthSwitcher() {
	const [currentMonth, setCurrentMonth] = useState(
		new Date(new Date().getFullYear(), new Date().getMonth(), 1),
	);

	const year = currentMonth.getFullYear();

	const month = currentMonth.getMonth();

	const nextMonth = () => {
		if (month === 11) {
			setCurrentMonth(new Date(year + 1, 0, 1));
		} else {
			setCurrentMonth(new Date(year, month + 1, 1));
		}
	};

	const prevMonth = () => {
		if (month === 0) {
			setCurrentMonth(new Date(year - 1, 11, 1));
		} else {
			setCurrentMonth(new Date(year, month - 1, 1));
		}
	};

	return {
		currentMonth,
		prevMonth,
		nextMonth,
	};
}

export { useMonthSwitcher };
