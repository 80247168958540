import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	CircularProgress,
	IconButton,
	Typography,
	Divider,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DialogTitleCustom from './DialogTitleCustom/DialogTitleCustom';
import { useStyles } from './style';
import { StyledButton } from '../../../components';
import { isObject } from '../../../shared/utility';

const AlertDialog = (props) => {
	const {
		className,
		open,
		handleClose,
		handleConfirm,
		dialogTitle,
		dialogDescription,
		hasOnlyConfirmButton,
		loading,
		textDialog,
		loadingState,
		furtherLoading,
		variantDescription,
		content,
		isValid,
		dialogContent,
		titleVariant,
		confirmDisabled,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<Dialog
				aria-describedby='alert-dialog-description'
				aria-labelledby='alert-dialog-title'
				classes={{ paper: classes.dialogContainer }}
				onClose={handleClose}
				open={open}
			>
				{!furtherLoading && loadingState ?
					<Box>
						<Skeleton animation='wave' height={52} width={300} />
					</Box>
				:	<Box
						alignItems='center'
						className={classes.dialogTitle}
						display='flex'
						justifyContent='space-between'
					>
						{isObject(textDialog) && textDialog.notAllowed ?
							<DialogTitleCustom
								icon={<CloseIcon />}
								onClose={handleClose}
								title={textDialog.title}
							/>
						:	<Box pb={2} pl={3} pt={2}>
								<Typography variant={titleVariant ? titleVariant : 'h2'}>{dialogTitle}</Typography>
							</Box>
						}
						{hasOnlyConfirmButton ?
							<IconButton className={classes.closeButton} onClick={handleClose} size='small'>
								<CloseIcon />
							</IconButton>
						:	null}
					</Box>
				}
				{!furtherLoading && loadingState ?
					<Box>
						<Skeleton animation='wave' height={145} width={300} />
					</Box>
				:	<>
						{dialogContent ?
							<DialogContent className={classes.dialog}>{dialogContent}</DialogContent>
						:	<DialogContent
								className={classes.dialog}
								dividers={isObject(textDialog) && textDialog.notAllowed}
							>
								<DialogContentText id='alert-dialog-description'>
									<Typography component='span' variant={variantDescription}>
										{isObject(textDialog) && textDialog.notAllowed ?
											textDialog.description
										:	dialogDescription}
									</Typography>
								</DialogContentText>
							</DialogContent>
						}
						{content ?
							<div>{content}</div>
						:	null}
						{isObject(textDialog) && textDialog.notAllowed ?
							<Box height='50px'></Box>
						:	<>
								<Divider className={classes.actionDivider} />
								<DialogActions
									className={clsx({
										[classes.actions]: true,
										[classes.spaceBetween]: !hasOnlyConfirmButton,
										[classes.alignRight]: hasOnlyConfirmButton,
									})}
								>
									{!hasOnlyConfirmButton ?
										<StyledButton
											className={classes.actionButton}
											onClick={handleClose}
											variant='contained-tertiary'
											// disabled={loading}
										>
											{t('ui.button.contained.cancel')}
										</StyledButton>
									:	null}
									<StyledButton
										autoFocus
										className={classes.actionButton}
										disabled={confirmDisabled || (isValid ? !loading : loading)}
										onClick={handleConfirm}
										variant='contained-secondary'
									>
										{isValid ?
											t('ui.button.contained.confirm')
										: loading ?
											<CircularProgress disableShrink size={24} />
										:	t('ui.button.contained.confirm')}
									</StyledButton>
								</DialogActions>
							</>
						}
					</>
				}
			</Dialog>
		</div>
	);
};

AlertDialog.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	confirmDisabled: PropTypes.bool,
	dialogTitle: PropTypes.string,
	dialogDescription: PropTypes.string,
	hasOnlyConfirmButton: PropTypes.bool,
	loading: PropTypes.bool,
	textDialog: PropTypes.object,
	furtherLoading: PropTypes.bool,
	loadingState: PropTypes.bool,
	variantDescription: PropTypes.string,
	content: PropTypes.object,
	dialogContent: PropTypes.node,
	titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	isValid: PropTypes.bool,
};

export default AlertDialog;
