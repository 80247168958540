import { useState } from 'react';

import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog, Dialog } from '~components';
import { LinkNfcTagToUserForm } from '~features/users';

import { NfcTag, NfcTagsDataGrid } from '..';
import CreateNfcTagForm from '../forms/createNfcTag/createNfcTagForm';
import NfcTagsService from '../services/nfcTagsService';

const service = new NfcTagsService();

type UserAction =
	| {
		type: 'create';
		id?: never;
	}
	| {
		type: 'edit' | 'link' | 'unlink' | 'delete';
		id: string;
	};

const NfcTagsOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();

	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const handleCreate = async (data: NfcTag) => {
		setUserActionProcessing(true);

		try {
			const response = await service.createNfcTag(data);
			enqueueSnackbar('Succesful created', { variant: 'success' });
			setUserAction(null);
		} catch (error) {
			console.error(error);
			enqueueSnackbar('somethingwrong', { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const handleLink = async (id: string) => {

	};

	const handleUnlink = async (id: string) => { };

	const handleDelete = async (id: string) => { };

	return (
		<Stack>
			<NfcTagsDataGrid
				onCreate={() => setUserAction({ type: 'create' })}
				onDelete={(id) => setUserAction({ type: 'delete', id: id })}
				onEdit={(id) => setUserAction({ type: 'edit', id: id })}
				onLink={(id) => setUserAction({ type: 'link', id: id })}
				onUnlink={(id) => setUserAction({ type: 'unlink', id: id })}
			/>
			<Dialog
				title='Add nfc'
				open={
					userAction?.type === 'create' ||
					userAction?.type === 'edit' ||
					userAction?.type === 'link'
				}
				onClose={() => setUserAction(null)}
			>
				{userAction?.type === 'link' ?
					'TODO'
					: (
						<CreateNfcTagForm
							onClose={() => setUserAction(null)}
						/>
					)}
			</Dialog>
			<ConfirmationDialog
				open={userAction?.type === 'delete' || userAction?.type === 'unlink'}
				loading={userActionProcessing}
				onConfirm={() => {
					if (userAction?.type === 'delete') {
						handleDelete(userAction.id);
					} else if (userAction?.type === 'unlink') {
						handleUnlink(userAction.id);
					}
				}}
				onDecline={() => setUserAction(null)}
			/>
		</Stack>
	);
};

export default NfcTagsOverviewLayout;
