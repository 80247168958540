import typography from '../typography';

export default {
	styleOverrides: {
		root: {
			...typography.input,
			lineHeight: 'auto',
		},
		outlined: {
			opacity: 0.4,
			'&.MuiInputLabel-shrink': {
				opacity: 1,
			},
		},
		shrink: {},
	},
};
