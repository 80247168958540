import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: 0,
		paddingRight: 0,
	},
	itemText: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
}));
