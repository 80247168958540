import { useStyles } from './style';
import { AdjustedSkeleton } from '../../../../components';

const LoadingIconBlock = () => {
	const classes = useStyles();
	return (
		<div className={classes.iconPreview}>
			<AdjustedSkeleton height={42} variant='rect' width={42} />
		</div>
	);
};

export default LoadingIconBlock;
