import { Tooltip as MuiTooltip, TooltipProps, useTheme } from '@mui/material';

const boxShadow = '0 1px 24px 0 rgba(0, 0, 0, 0.15)';

/**
 * A wrapper for the Tooltip to apply some custom
 * configurations
 */
const Tooltip = ({ placement = 'top', arrow = true, ...tooltipProps }: TooltipProps) => {
	const theme = useTheme();

	return (
		<MuiTooltip
			{...tooltipProps}
			arrow={arrow}
			placement={placement}
			slotProps={{
				arrow: {
					sx: {
						color: theme.palette.common.white,
						boxShadow: boxShadow,
					},
				},
				tooltip: {
					sx: {
						color: theme.palette.common.black,
						bgcolor: theme.palette.common.white,
						boxShadow: boxShadow,
					},
				},
			}}
		/>
	);
};

export default Tooltip;
