import { useState, useCallback } from 'react';

import ArrowUpIcon from '@mui/icons-material/ExpandLess';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { StyledButton } from '../../../../components';
import { isObject, isUndefined, isSuperAdmin } from '../../../../shared/utility';

const Price = (props) => {
	const {
		price,
		discount,
		showPrice = true,
		applyingDiscount,
		promoCode,
		bookingPrice,

		bookingStatus,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const currency = isObject(price) && price.currencySymbol;

	const [showDetails, setShowDetails] = useState(false);

	const handleClickShowDetails = () => setShowDetails(!showDetails);

	const getTotalPrice = useCallback(() => {
		if (
			isObject(price) &&
			showPrice &&
			!bookingPrice?.duration?.extra > 0 &&
			!bookingPrice?.distance?.extra > 0
		) {
			return `${currency}${price.totalPriceIncludingVat.toFixed(2)}`;
		} else if (
			applyingDiscount &&
			discount > 0 &&
			price?.totalPriceIncludingVat &&
			isSuperAdmin(auth.user?.profile.role)
		) {
			return `${currency}${price.totalPriceIncludingVat.toFixed(2)}`;
		} else if (
			(discount === 0 || isNaN(discount)) &&
			(bookingPrice?.duration?.extra > 0 || bookingPrice?.distance?.extra > 0)
		) {
			return `${currency}${bookingPrice.price.toFixed(2)}`;
		}
		return '-';
	}, [price, bookingPrice, discount]);

	const handleTotalExclVat = useCallback(() => {
		if (
			(discount === 0 || isNaN(discount)) &&
			(bookingPrice?.duration?.extra > 0 || bookingPrice?.distance?.extra > 0)
		) {
			return `${currency}${bookingPrice.total.subtotal.toFixed(2)}`;
		} else if (discount > 0 && bookingPrice?.totalPrice) {
			return `${currency}${bookingPrice.totalPrice.toFixed(2)}`;
		}
		return `${currency}${price.totalPrice.toFixed(2)}`;
	}, [price, discount]);

	const handleVat = useCallback(() => {
		if (
			(discount === 0 || isNaN(discount)) &&
			(bookingPrice?.duration?.extra > 0 || bookingPrice?.distance?.extra > 0)
		) {
			return `${currency}${bookingPrice.total.totalVat.toFixed(2)}`;
		} else if (discount > 0 && bookingPrice?.totalPriceVat) {
			return `${currency}${bookingPrice.totalPriceVat.toFixed(2)}`;
		} else {
			return `${currency}${price.totalPriceVat.toFixed(2)}`;
		}
	}, [price, discount]);

	return (
		<Box mb={1}>
			<Divider />
			{isObject(price) ?
				<Collapse in={showDetails} timeout='auto'>
					<Box mt={1.5}>
						<Box display='flex' justifyContent='space-between' mb={0.9}>
							<Typography variant='body2'>
								{t('views.bookingDetail.invoicesBiling.reservationPricing')}
							</Typography>
							<Typography variant='body2'>
								{bookingStatus === 'completed' ?
									`${currency}${bookingPrice?.duration?.initial.toFixed(2)}`
								:	`${currency}${price.price.toFixed(2)}`}
							</Typography>
						</Box>
						{isObject(bookingPrice) && bookingPrice?.duration?.extra > 0 ?
							<Box display='flex' justifyContent='space-between' mb={0.9}>
								<Typography variant='body2'>{t('ui.label.overTime')}</Typography>
								<Typography variant='body2'>{`${currency}${bookingPrice?.duration?.extra.toFixed(2)}`}</Typography>
							</Box>
						:	null}
						{isObject(bookingPrice) && bookingPrice?.distance?.extra > 0 ?
							<Box display='flex' justifyContent='space-between' mb={0.9}>
								<Typography variant='body2'>{t('ui.label.extraKm')}</Typography>
								<Typography variant='body2'>{`${currency}${bookingPrice?.distance?.extra.toFixed(2)}`}</Typography>
							</Box>
						:	null}
						<Box
							display='flex'
							justifyContent='space-between'
							mb={promoCode && !isUndefined(price.promoCodeDiscount) ? 0 : 2}
						>
							<Typography variant='body2'>
								{t('views.bookingDetail.invoicesBiling.adminDiscount')}
							</Typography>
							<Typography variant='body2'>
								{applyingDiscount && !Number.isNaN(discount) ?
									`${discount > 0 ? '-' : ''}${currency}${discount.toFixed(2)}`
								:	`${currency}${(0).toFixed(2)}`}
							</Typography>
						</Box>
						{promoCode && !isUndefined(price.promoCodeDiscount) ?
							<Box display='flex' justifyContent='space-between' mb={2}>
								<Box display='flex'>
									<Typography variant='body2'>{`${t('ui.label.promoCode')}: `}</Typography>
									<Box pl={1}>
										<Typography variant='body2'>{promoCode}</Typography>
									</Box>
								</Box>
								<Typography variant='body2'>{`${'-'}${currency}${price.promoCodeDiscount.toFixed(2)}`}</Typography>
							</Box>
						:	null}
					</Box>
					<Box>
						<Divider />
					</Box>
					<Box display='flex' justifyContent='space-between' pt={2}>
						<Typography variant='body2'>{`${t('ui.label.total')} (${t('ui.exclVat')})`}</Typography>
						<Typography variant='body2'>{handleTotalExclVat()}</Typography>
					</Box>
					<Box display='flex' justifyContent='space-between'>
						<Typography variant='body2'>{`${t('ui.vat.label')} ${price.vatPercentage}%`}</Typography>
						<Typography variant='body2'>{handleVat()}</Typography>
					</Box>
				</Collapse>
			:	<Box pb={1}></Box>}
			<Box display='flex' justifyContent='space-between' pt={1.5}>
				<Typography variant='h4'>{t('ui.label.total')}</Typography>
				<Typography variant='h4'>{getTotalPrice()}</Typography>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<Typography variant='body2'>{t('ui.inclVat')}</Typography>
			</Box>
			{isObject(price) ?
				<Box display='flex' justifyContent='flex-end'>
					<StyledButton
						endIcon={showDetails ? <ArrowUpIcon /> : <ArrowDownIcon />}
						onClick={handleClickShowDetails}
						variant='inline-default'
					>
						{t('ui.button.inline.pricedetails')}
					</StyledButton>
				</Box>
			:	null}
		</Box>
	);
};

Price.propTypes = {
	className: PropTypes.string,

	price: PropTypes.object,
	discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	applyingDiscount: PropTypes.bool,
	showPrice: PropTypes.bool,
	promoCode: PropTypes.string,
	bookingPrice: PropTypes.object,

	bookingStatus: PropTypes.string,
};

export default Price;
