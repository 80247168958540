import { useState } from 'react';

import path from 'path';

import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, ConfirmationDialog, Dialog, EditableDrawer } from '~components';
import { pagePathSegments } from '~constants';

import UsersDataGrid from '../components/dataGrids/usersDataGrid';
import CreateAnonymousForm from '../forms/createAnonymousUser/createAnonymousUserForm';
import UserIdentitiesService from '../services/userIdentitiesService';
import UsersService from '../services/usersService';
import UserInvitationsService from '../services/userInvitationsService';
import UserInvitationsDataGrid from '../components/dataGrids/userInvitationsDataGrid';

type UserAction =
	| {
			type: 'resend' | 'delete';
			id: string;
	  }
	| {
			type: 'create';
			id?: never;
	  };

const service = new UserInvitationsService();

const UserInvitationsOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [userAction, setUserAction] = useState<UserAction | null>(null);
	const [userActionProcessing, setUserActionProcessing] = useState(false);

	const handleInvite = () => {
		
	}

	const handleDelete = async (id: string) => {
		setUserActionProcessing(true);

		try {
			await service.deleteUserInvitation(id);
			enqueueSnackbar(t('success'), { variant: 'success' });
			setUserAction(null);
		} catch (error) {
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
		<Box height={1}>
			<UserInvitationsDataGrid
				onNewInvite={() =>
					navigate(
						`/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, pagePathSegments.Invite)}`,
					)
				}
				onDelete={(id) => setUserAction({ type: 'delete', id: id })}
			/>
			<ConfirmationDialog
				loading={userActionProcessing}
				open={userAction?.type === 'delete'}
				onConfirm={() => userAction?.id != null && handleDelete(userAction?.id)}
			/>
		</Box>
	);
};

export default UserInvitationsOverviewLayout;
