enum AccessEventEnum {
	Error = 'error',
	CardNotFound = 'accessDeniedCardNotFound',
	HashMissMatch = 'accessDeniedCardHashMissMatch',
	NoRuleFound = 'accessDeniedNoRuleFound',
	NoReservationFound = 'accessDeniedNoReservationFound',
	GrantedRule = 'accessGrantedRule',
	GrantedReservation = 'accessGrantedReservation'
};

export default AccessEventEnum;
