import PropTypes from 'prop-types';

import Header from './Header';
import InvoiceInfo from './InvoiceInfo';
import { useStyles } from './style';
import TermsAndConditions from './TermsAndConditions';
import Subscription from '../InvoiceDetails/Subscription';

const OrganisationDetails = (props) => {
	const classes = useStyles();

	const { save, invoiceName, invoiceEmail, isTermsChecked, chosenSubscription } = props;

	const invoiceProps = { invoiceName, invoiceEmail };
	const subscriptionProps = { chosenSubscription };
	const termsProps = { isTermsChecked };

	return (
		<>
			<Header />
			<InvoiceInfo className={classes.wizardSection} save={save} {...invoiceProps} />
			<Subscription className={classes.wizardSection} save={save} {...subscriptionProps} />
			<TermsAndConditions className={classes.wizardSection} save={save} {...termsProps} />
		</>
	);
};

OrganisationDetails.propTypes = {
	save: PropTypes.func,
	invoiceName: PropTypes.string,
	invoiceEmail: PropTypes.string,
	chosenSubscription: PropTypes.string,
	isTermsChecked: PropTypes.bool,
};

export default OrganisationDetails;
