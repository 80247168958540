import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .fc-timegrid-slot-label': {
			...theme.typography.h6,
		},
		'& .fc-event-main': {
			cursor: 'pointer',
			...theme.typography.h6,
			color: '#ffffff',
		},
		'& .fc-col-header-cell.fc-day ': {
			cursor: 'pointer',
			...theme.typography.h6,
			padding: theme.spacing(0.5, 1),
			background: 'transparent',
		},
		'& .fc-timegrid-col.fc-day.fc-day-today, .fc-daygrid-day.fc-day.fc-day-today ': {
			background: 'transparent',
		},
		'& .fc-timegrid-slot, .fc-daygrid-day': {
			cursor: 'pointer',
		},
	},
	typesComponent: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(2),
		border: '1px solid #ddd',
		borderBottom: 'none',
	},
	typeItems: {
		display: 'flex',
	},
	modelLabel: {
		margin: theme.spacing(4.5, 0),
	},
	amount: {
		marginBottom: theme.spacing(4.5),
	},
	kilometerSwitch: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(4),
	},
	textField: {
		marginBottom: theme.spacing(4),
	},
	allDay: {
		cursor: 'pointer',
		...theme.typography.h6,
		padding: theme.spacing(5),
	},
}));
