import { useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { ActionDialog, FormField, SelectWithLazyLoading } from '../../../../components';
import { useError, useWizardFormField } from '../../../../shared/hooks';
import { isSuperAdmin } from '../../../../shared/utility';
import { createAnonymousUser, fetchOrganisations } from '../../../../store/actions';

const AddNFCTag = (props) => {
	const {
		isOpen,
		toggleAddNFCTagDialog,
		createdAnonymousUser,
		onCreateAnonymousUser,
		currentUser,
		onFetchOrganisations,
		organisations,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const [searchHandle, setSearchHandle] = useState('');
	const [organisation, setOrganisation] = useState({ name: '', id: '' });

	const { loading: addedNfcTagLoading } = createdAnonymousUser;

	const { data: currentUserData } = currentUser;

	const organisationId = currentUserData?.organisationReference?.id;

	const firstName = useWizardFormField('', { minLength: 3, maxLength: 25, required: true });
	const lastName = useWizardFormField('', { minLength: 3, maxLength: 25, required: true });
	const nfcId = useWizardFormField('', { maxLength: 32, required: true });
	const nfcCardNumber = useWizardFormField('', { maxLength: 25, required: true });

	const isValid =
		firstName.isValid &&
		lastName.isValid &&
		nfcId.isValid &&
		nfcCardNumber.isValid &&
		(isSuperAdmin(auth.user?.profile.role) ? organisation?.id : organisationId);

	const addNfcTagMessage = useError({
		value: createdAnonymousUser,
		message: `${t('ui.successfullyAdded')} ${t('ui.label.anonymousUserNFCTag')}`,
	});

	const handleCloseAddNFCTagDialog = () => {
		handleReset();
		toggleAddNFCTagDialog();
		setOrganisation({ name: '', id: '' });
		setSearchHandle('');
	};

	const handleAddNFCTag = () => {
		onCreateAnonymousUser(
			firstName.value,
			lastName.value,
			nfcCardNumber.value,
			nfcId.value,
			isSuperAdmin(auth.user?.profile.role) ? organisation?.id : organisationId,
			null,
		);
		addNfcTagMessage.setStartAction(true);
		handleCloseAddNFCTagDialog();
	};

	const handleReset = () => {
		firstName.resetToInitialValue();
		lastName.resetToInitialValue();
		nfcId.resetToInitialValue();
		nfcCardNumber.resetToInitialValue();
	};

	const selectOrganisation = (org) => {
		setOrganisation({ name: org.name, id: org.id });
		setSearchHandle(org.name);
	};

	const nfcTagData = [
		{
			name: 'firstName',
			label: 'ui.label.firstName',
			value: firstName,
			required: true,
			placeholder: 'ui.label.firstName',
		},
		{
			name: 'lastName',
			label: 'ui.label.lastName',
			value: lastName,
			required: true,
			placeholder: 'views.usersAndOrganisations.nfcCards.lastName.placeholder',
		},
		{
			name: 'nfcCardNumber',
			label: 'views.userDetails.summary.nfcCardNumber',
			value: nfcCardNumber,
			required: true,
			placeholder: 'views.userDetails.summary.nfcCardNumberPlaceholder',
		},
		{
			name: 'nfcId',
			label: 'views.usersAndOrganisations.nfcCards.nfcId',
			value: nfcId,
			required: true,
			placeholder: 'views.userDetails.summary.nfcCard.placeholder',
		},
	];

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleAddNFCTag,
				text: addedNfcTagLoading ? <CircularProgress disableShrink size={24} /> : t('ui.add'),
			}}
			handleClose={handleCloseAddNFCTagDialog}
			loading={addedNfcTagLoading || !isValid}
			open={isOpen}
			title={t('views.userDetails.summary.addNfcCard')}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
				{nfcTagData.map((field) => (
					<FormField
						hideCharacterCounter={!field.value.hasFocus}
						key={field.name}
						label={t(field.label)}
						maxLength={field.value.validationOptions.maxLength}
						name={field.name}
						placeholder={t(field.placeholder)}
						required={field.required}
						variable={field.value}
					/>
				))}
				{isSuperAdmin(auth.user?.profile.role) ?
					<SelectWithLazyLoading
						dataList={organisations}
						label={t('ui.organisation')}
						onFetchData={onFetchOrganisations}
						required={true}
						searchHandle={setSearchHandle}
						setSelected={selectOrganisation}
						value={searchHandle}
					/>
				:	null}
			</Box>
		</ActionDialog>
	);
};

AddNFCTag.propTypes = {
	isOpen: PropTypes.bool,
	toggleAddNFCTagDialog: PropTypes.func,
	onCreateAnonymousUser: PropTypes.func,
	onFetchOrganisations: PropTypes.func,

	organisations: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	createdAnonymousUser: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		createdAnonymousUser: state.condition.createAnonymousUser,
		currentUser: state.details.currentUser,
		organisations: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onCreateAnonymousUser: (firstName, lastName, cardNumber, nfcId, organisationId, userGroupId) =>
			dispatch(
				createAnonymousUser(firstName, lastName, cardNumber, nfcId, organisationId, userGroupId),
			),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNFCTag);
