import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

const LinkUnlinkButton = (props) => {
	const { link, onClick, data } = props;

	return (
		<IconButton onClick={() => onClick(data)} size='small'>
			{link ?
				<LinkOffIcon />
			:	<LinkIcon />}
		</IconButton>
	);
};

LinkUnlinkButton.defaultProps = {
	onClick: () => null,
};

LinkUnlinkButton.propTypes = {
	link: PropTypes.bool,
	onClick: PropTypes.func,
	data: PropTypes.object,
};

export default LinkUnlinkButton;
