import { useEffect } from 'react';

import { Typography, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../../../components';
import * as actions from '../../../../../../store/actions';

const VerifyEmailDialog = (props) => {
	const {
		openVerifyEmailDialog,
		setOpenVerifyEmailDialog,
		email,

		onVerifyEmail,
		verifyEmail,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const {
		success: verifyEmailSuccess,
		loading: verifyEmailLoading,
		error: verifyEmailError,
	} = verifyEmail;

	const { enqueueSnackbar } = useSnackbar();

	const handleCloseVerifyEmailDialog = () => setOpenVerifyEmailDialog(false);

	useEffect(() => {
		if (verifyEmailSuccess) {
			enqueueSnackbar(t('views.usermanagement.users.label.verifyEmail.successMessage'), {
				variant: 'success',
			});
			handleCloseVerifyEmailDialog();
		} else if (verifyEmailError) {
			enqueueSnackbar(verifyEmailError?.message, { variant: 'error' });
			onResetStateCondition('verifyEmail', false);
		}
	}, [verifyEmail]);

	const handleConfirm = () => {
		const body = {
			email: email,
		};
		onVerifyEmail(body);
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text: verifyEmailLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleCloseVerifyEmailDialog}
			loading={verifyEmailLoading}
			open={openVerifyEmailDialog}
			title={t('views.usermanagement.users.title.verifyEmail')}
		>
			<Typography variant='body2'>{t('views.usermanagement.users.label.description')}</Typography>
		</ActionDialog>
	);
};

VerifyEmailDialog.propTypes = {
	openVerifyEmailDialog: PropTypes.bool,
	setOpenVerifyEmailDialog: PropTypes.func,
	email: PropTypes.string,
	onVerifyEmail: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	verifyEmail: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		verifyEmail: state.condition.verifyEmail,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onVerifyEmail: (body) => dispatch(actions.verifyEmail(body)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailDialog);
