import { useState, useEffect } from 'react';

import {
	Card,
	CardHeader,
	CardContent,
	Collapse,
	Divider,
	FormControlLabel,
	Switch,
	TextField,
	Typography,
	Autocomplete,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

const BookingConfiguration = (props) => {
	const {
		save,
		openHoursRestrictionType,
		requireOvertimeValidation: savedRequireOvertimeValidation,
		requireHostConfirmation: savedRequireHostConfirmation,
		hasBufferPeriod,
		hasGracePeriod,
		hasCancellationPeriod,
		cancelDay,
		cancelHour,
		cancelMin,
		allowExtending: savedAllowExtending,
		requiredLicenseStatus,

		daysAfterUse,
		hoursAfterUse,
		minAfterUse,
		requiredParkingLicensePlateNumber,
		daysGracePeriod,
		hoursGracePeriod,
		minGracePeriod,
		hasMinDuration,
		minDurationDay,
		minDurationHour,
		minDurationMin,
		maxDurationDay,
		maxDurationHour,
		maxDurationMin,
		hasMaxDuration,
		requireDutchLicense: savedRequireDutchLicense,
		hasMinDurationBeforeStart,
		hasMaxDurationBeforeStart,
		minDurationBeforeStartDay,
		minDurationBeforeStartHour,
		minDurationBeforeStartMin,
		maxDurationBeforeStartDay,
		maxDurationBeforeStartHour,
		maxDurationBeforeStartMin,
		bookingUpfrontMaxDurationSmallerThanMinDuration,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	// Host Confirmation
	const [requireHostConfirmation, setRequireHostConfirmation] = useState(
		savedRequireHostConfirmation || false,
	);

	const [requireOvertimeValidation, setRequireOvertimeValidation] = useState(
		savedRequireOvertimeValidation || false,
	);

	useEffect(() => {
		save({ updatedHostConfirmation: requireHostConfirmation });
	}, [requireHostConfirmation]);

	useEffect(() => {
		save({ updatedOvertimeValidation: requireOvertimeValidation });
	}, [requireOvertimeValidation]);

	// Duration
	const [openMinDuration, setOpenMinDuration] = useState(hasMinDuration);

	useEffect(() => {
		save({ updatedMinDuration: openMinDuration });
	}, [openMinDuration]);

	const [openMaxDuration, setOpenMaxDuration] = useState(hasMaxDuration);

	useEffect(() => {
		save({ updatedMaxDuration: openMaxDuration });
	}, [openMaxDuration]);

	const [openBufferPeriod, setOpenBufferPeriod] = useState(hasBufferPeriod);

	useEffect(() => {
		save({ hasBufferPeriod: openBufferPeriod });
	}, [openBufferPeriod]);

	// Booking Upfront
	const [openMinDurationBeforeStart, setOpenMinDurationBeforeStart] =
		useState(hasMinDurationBeforeStart);

	useEffect(() => {
		save({ updatedMinDurationBeforeStart: openMinDurationBeforeStart });
	}, [openMinDurationBeforeStart]);

	const [openMaxDurationBeforeStart, setOpenMaxDurationBeforeStart] =
		useState(hasMaxDurationBeforeStart);

	useEffect(() => {
		save({ updatedMaxDurationBeforeStart: openMaxDurationBeforeStart });
	}, [openMaxDurationBeforeStart]);

	const [openGracePeriod, setOpenGracePeriod] = useState(hasGracePeriod);

	useEffect(() => {
		save({ hasGracePeriod: openGracePeriod });
	}, [openGracePeriod]);

	const bookingHours = {
		NONE: 'none',
		CREATE: 'create',
		USAGE: 'usage',
	};

	const [openHoursRestriction, setOpenHoursRestriction] = useState(openHoursRestrictionType);
	const [openHoursCreate, setOpenHoursCreate] = useState(
		openHoursRestrictionType !== bookingHours.NONE,
	);
	const [openHoursUsage, setOpenHoursUsage] = useState(
		openHoursRestrictionType === bookingHours.USAGE,
	);

	useEffect(() => {
		if (openHoursCreate && openHoursUsage) {
			setOpenHoursRestriction(bookingHours.USAGE);
		} else if (openHoursCreate && !openHoursUsage) {
			setOpenHoursRestriction(bookingHours.CREATE);
		} else {
			setOpenHoursRestriction(bookingHours.NONE);
		}
		save({
			updatedOpenHoursRestrictionType: openHoursRestriction,
		});
	}, [openHoursRestriction, openHoursCreate, openHoursUsage]);

	const maxDaysAllowedBeforeStart = 62;
	const maxDaysAllowed = 7;
	const maxDaysAllowedBookingDuratio = 31;
	const hoursInDay = 24;
	const minutesInHours = 60;

	const days = Array.from(Array(maxDaysAllowed).keys()).map(String);
	const maxDaysBookingDuration = Array.from(Array(maxDaysAllowedBookingDuratio).keys()).map(String);
	const maxDaysSlotsBeforeStart = Array.from(Array(maxDaysAllowedBeforeStart).keys()).map(String);
	const hoursSlots = Array.from(Array(hoursInDay).keys()).map(String);

	const minSlot = ['0', '15', '30', '45'];

	const graceSlots = Array.from(Array(minutesInHours).keys()).map((x) => `${x}`);

	// Min Booking Duration
	const handleMinBookingDurationDay = (event, value) =>
		save({ updatedMinDurationDay: parseInt(value) });

	const handleMinBookingDurationHours = (event, value) =>
		save({ updatedMinDurationHour: parseInt(value) });

	const handleMinBookingDurationMin = (event, value) =>
		save({ updatedMinDurationMin: parseInt(value) });

	const minBookingDuration = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: minDurationDay,
			options: days,
			action: handleMinBookingDurationDay,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: minDurationHour,
			options: hoursSlots,
			action: handleMinBookingDurationHours,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: minDurationMin,
			options: graceSlots,
			action: handleMinBookingDurationMin,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// Max Booking Duration
	const handleMaxBookingDurationDay = (event, value) =>
		save({ updatedMaxDurationDay: parseInt(value) });

	const handleMaxBookingDurationHours = (event, value) =>
		save({ updatedMaxDurationHour: parseInt(value) });

	const handleMaxBookingDurationMin = (event, value) =>
		save({ updatedMaxDurationMin: parseInt(value) });

	const maxBookingDuration = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: maxDurationDay,
			options: maxDaysBookingDuration,
			action: handleMaxBookingDurationDay,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: maxDurationHour,
			options: hoursSlots,
			action: handleMaxBookingDurationHours,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: maxDurationMin,
			options: graceSlots,
			action: handleMaxBookingDurationMin,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// Min Duration Before Start
	const handleMinDurationBeforeStartDay = (event, value) =>
		save({ updatedMinDurationBeforeStartDay: parseInt(value) });

	const handleMinDurationBeforeStartHours = (event, value) =>
		save({ updatedMinDurationBeforeStartHour: parseInt(value) });

	const handleMinDurationBeforeStartMin = (event, value) =>
		save({ updatedMinDurationBeforeStartMin: parseInt(value) });

	const minDurationBeforeStart = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: minDurationBeforeStartDay,
			options: maxDaysSlotsBeforeStart,
			action: handleMinDurationBeforeStartDay,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: minDurationBeforeStartHour,
			options: hoursSlots,
			action: handleMinDurationBeforeStartHours,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: minDurationBeforeStartMin,
			options: graceSlots,
			action: handleMinDurationBeforeStartMin,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// Max Duration Before Start
	const handleMaxDurationBeforeStartDay = (event, value) =>
		save({ updatedMaxDurationBeforeStartDay: parseInt(value) });

	const handleMaxDurationBeforeStartHours = (event, value) =>
		save({ updatedMaxDurationBeforeStartHour: parseInt(value) });

	const handleMaxDurationBeforeStartMin = (event, value) =>
		save({ updatedMaxDurationBeforeStartMin: parseInt(value) });

	const maxDurationBeforeStart = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: maxDurationBeforeStartDay,
			options: maxDaysSlotsBeforeStart,
			action: handleMaxDurationBeforeStartDay,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: maxDurationBeforeStartHour,
			options: hoursSlots,
			action: handleMaxDurationBeforeStartHours,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: maxDurationBeforeStartMin,
			options: graceSlots,
			action: handleMaxDurationBeforeStartMin,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// After Use
	const handleDayAfterUse = (event, value) => save({ daysAfterUse: parseInt(value) });

	const handleHourAfterUse = (event, value) => save({ hoursAfterUse: parseInt(value) });

	const handleMinAfterUse = (event, value) => save({ minAfterUse: parseInt(value) });

	const afterUseTime = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: daysAfterUse,
			options: days,
			action: handleDayAfterUse,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: hoursAfterUse,
			options: hoursSlots,
			action: handleHourAfterUse,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: minAfterUse,
			options: minSlot,
			action: handleMinAfterUse,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// Grace Period
	const handleDayGracePeriod = (event, value) => save({ daysGracePeriod: parseInt(value) });

	const handleHourGracePeriod = (event, value) => save({ hoursGracePeriod: parseInt(value) });

	const handleMinGracePeriod = (event, value) => save({ minGracePeriod: parseInt(value) });

	const gracePeriod = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: daysGracePeriod,
			options: days,
			action: handleDayGracePeriod,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: hoursGracePeriod,
			options: hoursSlots,
			action: handleHourGracePeriod,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: minGracePeriod,
			options: graceSlots,
			action: handleMinGracePeriod,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];
	// Cancellation
	const [openCancellationPeriod, setOpenCancellationPeriod] = useState(hasCancellationPeriod);

	useEffect(() => {
		save({
			hasCancellationPeriod: openCancellationPeriod,
		});
	}, [openCancellationPeriod]);

	const handleDayChange = (event, value) => {
		save({ cancelDays: parseInt(value) });
	};

	const handleHourChange = (event, value) => {
		save({ cancelHours: parseInt(value) });
	};

	const handleMinChange = (event, value) => {
		save({ cancelMins: parseInt(value) });
	};

	const cancelTime = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: cancelDay,
			option: days,
			action: handleDayChange,
			className: classes.dayInputCancel,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: cancelHour,
			option: hoursSlots,
			action: handleHourChange,
			className: classes.hourInputCancel,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: cancelMin,
			option: minSlot,
			action: handleMinChange,
			className: classes.minInputCancel,
			defaultValue: '00',
		},
	];

	// Extension
	const [allowExtending, setAllowExtending] = useState(savedAllowExtending);

	useEffect(() => {
		save({ allowExtending });
	}, [allowExtending]);

	// Dutch license
	const [requireDutchLicense, setRequireDutchLicense] = useState(savedRequireDutchLicense);

	useEffect(() => {
		if (requireDutchLicense && requiredLicenseStatus === licenseStatus.NOT_APPLICABLE) {
			return setRequireDutchLicense(false);
		}
		save({ requireDutchLicense });
	}, [requireDutchLicense, requiredLicenseStatus]);

	// Verification
	const licenseStatus = {
		NOT_APPLICABLE: 'notApplicable',
		VALIDATION: 'validation', // Automatically verified by RDW
		VERIFICATION: 'verification', //Manual verification by super admin, use now verify later is enabled
	};
	const [requireLicenseStatus, setRequireLicenseStatus] = useState(requiredLicenseStatus);
	const [requireLicenseVerification, setRequireLicenseVerification] = useState(
		requiredLicenseStatus !== licenseStatus.NOT_APPLICABLE,
	);
	const [useNowVerifyLater, setUseNowVerifyLater] = useState(
		requiredLicenseStatus === licenseStatus.VALIDATION,
	);

	useEffect(() => {
		if (requireLicenseVerification && useNowVerifyLater) {
			setRequireLicenseStatus(licenseStatus.VALIDATION);
		} else if (requireLicenseVerification && !useNowVerifyLater) {
			setRequireLicenseStatus(licenseStatus.VERIFICATION);
		} else {
			setRequireLicenseStatus(licenseStatus.NOT_APPLICABLE);
		}
		save({
			requiredLicenseStatus: requireLicenseStatus,
		});
	}, [requireLicenseStatus, requireLicenseVerification, useNowVerifyLater]);

	const parkingLicensePlate = {
		notApplicable: 'notApplicable',
		optional: 'optional',
		required: 'required',
	};

	const [requireParkingLicensePlateNumber, setRequireParkingLicensePlateNumber] = useState(
		requiredParkingLicensePlateNumber,
	);
	const [parkingLicensePlateOptional, setParkingLicensePlateOptional] = useState(
		requiredParkingLicensePlateNumber !== parkingLicensePlate.notApplicable,
	);
	const [requiredParkingLicensePlate, setRequiredParkingLicensePlate] = useState(
		requiredParkingLicensePlateNumber === parkingLicensePlate.required,
	);

	useEffect(() => {
		if (parkingLicensePlateOptional && requiredParkingLicensePlate) {
			setRequireParkingLicensePlateNumber(parkingLicensePlate.required);
		} else if (parkingLicensePlateOptional && !requiredParkingLicensePlate) {
			setRequireParkingLicensePlateNumber(parkingLicensePlate.optional);
		} else {
			setRequireParkingLicensePlateNumber(parkingLicensePlate.notApplicable);
		}
		save({ requiredParkingLicensePlateNumber: requireParkingLicensePlateNumber });
	}, [requireParkingLicensePlateNumber, parkingLicensePlateOptional, requiredParkingLicensePlate]);

	return (
		<Card className={classes.root}>
			<CardHeader
				subheader={t('views.addPolicy.policyConfiguration.category.bookingPolicies.description')}
				title={t('views.addPolicy.policyConfiguration.category.bookingPolicies.title')}
				titleTypographyProps={{ variant: 'h3' }}
			/>
			<CardContent>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={requireHostConfirmation}
								onChange={() => setRequireHostConfirmation(!requireHostConfirmation)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.hostConfirmation.title')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.hostConfirmation.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Typography className={classes.subheader} variant='h5'>
						{t('ui.label.bookingHours')}
					</Typography>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={openHoursCreate}
								color='primary'
								onChange={() => setOpenHoursCreate(!openHoursCreate)}
							/>
						}
						label={
							<div>
								<Typography variant='h6'>
									{t('views.addPolicy.bookingConfiguration.section.bookingHours.subheader')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.bookingHours.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Collapse in={openHoursCreate}>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<FormControlLabel
									className={classes.switchLabel}
									classes={{ label: classes.useNowVerifyLaterLabel }}
									control={
										<Switch
											checked={openHoursUsage}
											color='primary'
											onChange={() => setOpenHoursUsage(!openHoursUsage)}
										/>
									}
									key={'openHoursUsage'}
									label={
										<>
											<Typography variant='h5'>
												{t('views.addPolicy.bookingConfiguration.bookingHours.title')}
											</Typography>
											<Typography variant='body1'>
												{t('views.addPolicy.bookingConfiguration.bookingHours.description')}
											</Typography>
										</>
									}
									labelPlacement='start'
									value={openHoursUsage}
								/>
							</CardContent>
						</Card>
					</Collapse>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.subcategory.duration')}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={openMinDuration}
								color='primary'
								onChange={() => setOpenMinDuration(!openMinDuration)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.minimumTime.title')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.minimumTime.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Collapse in={openMinDuration}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.minDuration')}:
							</Typography>
							{minBookingDuration.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={openMaxDuration}
								color='primary'
								onChange={() => setOpenMaxDuration(!openMaxDuration)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.maximumTime.title')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.maximumTime.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Collapse in={openMaxDuration}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.maxDuration')}:
							</Typography>
							{maxBookingDuration.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.section.header.inUse')}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={requireOvertimeValidation}
								color='primary'
								onChange={() => setRequireOvertimeValidation(!requireOvertimeValidation)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.title')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.section.header.bookingUpfront')}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={openMaxDurationBeforeStart}
								color='primary'
								onChange={() => setOpenMaxDurationBeforeStart(!openMaxDurationBeforeStart)}
							/>
						}
						label={
							<Typography variant='h5'>
								{t('views.addPolicy.minDurationBeforeStart.section.title')}
							</Typography>
						}
						labelPlacement='start'
					/>
					<Collapse in={openMaxDurationBeforeStart}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.futureLimit')}:
							</Typography>
							{maxDurationBeforeStart.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				{/* 20240312 Dennis: commented out this field. Not remove because it is expected this is going
				to be reactivated later on */}
				{/* <div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={<Switch checked={openMinDurationBeforeStart} color='primary' onChange={() => setOpenMinDurationBeforeStart(!openMinDurationBeforeStart)} />}
						label={<Typography variant='h5'>{t('views.addPolicy.maxDurationBeforeStart.section.title')}</Typography>}
						labelPlacement='start'
					/>
					<Collapse in={openMinDurationBeforeStart}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>{t('ui.label.inAdvance')}:</Typography>
							{minDurationBeforeStart.map(item => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={params => (<TextField {...params} InputLabelProps={{ shrink: false, }} label={item.name} size='medium' variant="outlined" />)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
					{bookingUpfrontMaxDurationSmallerThanMinDuration && 
						<Typography pt='10px' color='error'>
							{t('views.addPolicy.bookingConfiguration.bookingUpfront.maxIsSmallerThanMinDuration.error')}
						</Typography>
					}
				</div> */}
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.subcategory.bufferPeriod')}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={openBufferPeriod}
								color='primary'
								onChange={() => setOpenBufferPeriod(!openBufferPeriod)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.bufferPeriod.title')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.bufferPeriod.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Collapse in={openBufferPeriod}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.afterUse')}:
							</Typography>
							{afterUseTime.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.section.graceiPariod.title')}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={openGracePeriod}
								color='primary'
								onChange={() => setOpenGracePeriod(!openGracePeriod)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.graceiPariod.subcategory')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.graceiPariod.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Collapse in={openGracePeriod}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.graceiPariod.pariod')}:
							</Typography>
							{gracePeriod.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.subcategory.cancellation')}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={openCancellationPeriod}
								color='primary'
								onChange={() => setOpenCancellationPeriod(!openCancellationPeriod)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.cancellationPeriod.title')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.cancellationPeriod.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Collapse in={openCancellationPeriod}>
						<div className={classes.inputCancellation}>
							<Typography variant='h5'>{t('ui.label.cancelUpUntilTimePartOne')}</Typography>
							{cancelTime.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.option}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
							<Typography className={classes.endLabel} variant='h5'>
								{t('ui.label.cancelUpUntilTimePartTwo')}
							</Typography>
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.subcategory.extension')}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={allowExtending}
								color='primary'
								onChange={() => setAllowExtending(!allowExtending)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.allowExtension.title')}
								</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.section.allowExtension.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{t('ui.drivingLicense')}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={requireLicenseVerification}
								color='primary'
								onChange={() => setRequireLicenseVerification(!requireLicenseVerification)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>
									{t('views.addPolicy.bookingConfiguration.section.requireVerification.title')}
								</Typography>
								<Typography color='textSecondary'>
									{t(
										'views.addPolicy.bookingConfiguration.section.requireVerification.description',
									)}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Collapse in={requireLicenseVerification}>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<FormControlLabel
									className={classes.switchLabel}
									classes={{ label: classes.useNowVerifyLaterLabel }}
									control={
										<Switch
											checked={useNowVerifyLater}
											color='primary'
											onChange={() => setUseNowVerifyLater(!useNowVerifyLater)}
										/>
									}
									key={'useNowVerifyLater'}
									label={
										<>
											<Typography variant='h5'>
												{t('views.addPolicy.bookingConfiguration.useNowVerifyLater.title')}
											</Typography>
											<Typography variant='body1'>
												{t('views.addPolicy.bookingConfiguration.useNowVerifyLater.description')}
											</Typography>
										</>
									}
									labelPlacement='start'
									value={useNowVerifyLater}
								/>
							</CardContent>
						</Card>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<FormControlLabel
									className={classes.switchLabel}
									control={
										<Switch
											checked={requireDutchLicense}
											color='primary'
											onChange={() => setRequireDutchLicense(!requireDutchLicense)}
										/>
									}
									label={
										<div>
											<Typography variant='h5'>
												{t('views.addPolicy.bookingConfiguration.section.dutchLicense.title')}
											</Typography>
											<Typography color='textSecondary'>
												{t('views.addPolicy.bookingConfiguration.section.dutchLicense.description')}
											</Typography>
										</div>
									}
									labelPlacement='start'
								/>
							</CardContent>
						</Card>
					</Collapse>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{''}
				</Typography>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={parkingLicensePlateOptional}
								color='primary'
								onChange={() => setParkingLicensePlateOptional(!parkingLicensePlateOptional)}
							/>
						}
						label={
							<div>
								<Typography variant='h5'>{t('ui.label.licensePlate')}</Typography>
								<Typography color='textSecondary'>
									{t('views.addPolicy.bookingConfiguration.licensePlate.description')}
								</Typography>
							</div>
						}
						labelPlacement='start'
					/>
					<Collapse in={parkingLicensePlateOptional}>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<FormControlLabel
									className={classes.switchLabel}
									classes={{ label: classes.useNowVerifyLaterLabel }}
									control={
										<Switch
											checked={requiredParkingLicensePlate}
											color='primary'
											onChange={() => setRequiredParkingLicensePlate(!requiredParkingLicensePlate)}
										/>
									}
									key={'requiredParkingLicensePlate'}
									label={
										<>
											<Typography variant='h5'>
												{t('views.addPolicy.bookingConfiguration.licensePlate.required.title')}
											</Typography>
											<Typography variant='body1'>
												{t(
													'views.addPolicy.bookingConfiguration.licensePlate.required.description',
												)}
											</Typography>
										</>
									}
									labelPlacement='start'
									value={requiredParkingLicensePlate}
								/>
							</CardContent>
						</Card>
					</Collapse>
				</div>
			</CardContent>
		</Card>
	);
};

BookingConfiguration.propTypes = {
	className: PropTypes.string,
	save: PropTypes.func.isRequired,
	requireHostConfirmation: PropTypes.bool,
	minimumBookingDuration: PropTypes.string,
	minimumBookingDurationRequired: PropTypes.bool,
	maximumBookingDuration: PropTypes.string,
	maximumBookingDurationRequired: PropTypes.bool,
	hasBufferPeriod: PropTypes.bool,
	daysBeforeUse: PropTypes.number,
	hoursBeforeUse: PropTypes.number,
	minBeforeUse: PropTypes.number,
	daysAfterUse: PropTypes.number,
	hoursAfterUse: PropTypes.number,
	minAfterUse: PropTypes.number,
	hasCancellationPeriod: PropTypes.bool,
	cancelDay: PropTypes.number,
	cancelHour: PropTypes.number,
	cancelMin: PropTypes.number,
	allowExtending: PropTypes.bool,
	requiredLicenseStatus: PropTypes.string,

	editing: PropTypes.bool,
	editPolicyData: PropTypes.object,
	hasGracePeriod: PropTypes.bool,
	openHoursRestrictionType: PropTypes.string,
	hasMinDuration: PropTypes.bool,
	minDurationDay: PropTypes.any,
	minDurationHour: PropTypes.any,
	minDurationMin: PropTypes.any,
	maxDurationDay: PropTypes.any,
	maxDurationHour: PropTypes.any,
	maxDurationMin: PropTypes.any,
	hasMaxDuration: PropTypes.bool,
	requireOvertimeValidation: PropTypes.any,
	requiredParkingLicensePlateNumber: PropTypes.any,
	daysGracePeriod: PropTypes.any,
	hoursGracePeriod: PropTypes.any,
	minGracePeriod: PropTypes.any,
	requireDutchLicense: PropTypes.bool,
	hasMinDurationBeforeStart: PropTypes.bool,
	hasMaxDurationBeforeStart: PropTypes.bool,
	minDurationBeforeStartDay: PropTypes.number,
	minDurationBeforeStartHour: PropTypes.number,
	minDurationBeforeStartMin: PropTypes.number,
	maxDurationBeforeStartDay: PropTypes.number,
	maxDurationBeforeStartHour: PropTypes.number,
	maxDurationBeforeStartMin: PropTypes.number,
	bookingUpfrontMaxDurationSmallerThanMinDuration: PropTypes.bool,
};

export default BookingConfiguration;
