import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { StyledButton } from '../../../components';

const Header = (props) => {
	const { buttons, href } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	return (
		<div>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography component='h1' variant='h3'>
						{t('nav.category.devices')}
					</Typography>
				</Grid>
				<Grid item>
					{buttons.map((button, index) => (
						<StyledButton
							className={classes.cta}
							href={href}
							key={`header-button-${index}`}
							onClick={button.action}
							variant='contained-primary'
						>
							{button.buttonPlaceholder}
						</StyledButton>
					))}
				</Grid>
			</Grid>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			buttonPlaceholder: PropTypes.string,
			action: PropTypes.func,
		}),
	),

	href: PropTypes.string,
};

export default Header;
