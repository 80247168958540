import { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, Divider, List, ListItem, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import SelectUserGroup from './SelectUserGroup';
import { useStyles } from './style';
import {
	Wizard,
	SidepanelContainer,
	SidePanel,
	AlertDialog,
	LoadingBar,
} from '../../../../components';
import {
	isUndefined,
	isEmptyObject,
	isEmptyArray,
	isFullString,
	isObject,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const AssignUsersToUserGroup = (props) => {
	const { onAssignUsersToUserGroup, assignedUsersToUserGroup } = props;
	const location = useLocation();
	const auth = useAuth();
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const role = auth.user?.profile.role.toLowerCase();
	const superAdmin = isFullString(role) && role === 'superadmin';

	const [openUserBookingAlert, setOpenUserBookingAlert] = useState(false);

	const {
		data: assignedUsersData,
		loading: assignedUsersLoading,
		error: assignedUsersError,
	} = assignedUsersToUserGroup;
	const assignUsersDone =
		isObject(assignedUsersData) && !assignedUsersLoading && !assignedUsersError;

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);
	const [selectedUserGroup, setSelectedUserGroup] = useState(
		location.state && location.state.selectedUserGroup ? location.state.selectedUserGroup : {},
	);
	const [selectedOrganisation, setSelectedOrganisation] = useState(
		location.state && location.state.selectedUserGroup ?
			location.state.selectedUserGroup.organisationReference
		:	{},
	);

	const [selectedUsers, setSelectedUsers] = useState(
		location.state && location.state.selectedUsers ? location.state.selectedUsers : [],
	);

	const [shouldAssignUsers, setShouldAssignUsers] = useState(false);
	const [forceMove, setForceMove] = useState(false);
	const [assigningUsers, setAssigningUsers] = useState(false);

	useEffect(() => {
		if (shouldAssignUsers && !assigningUsers) {
			setShouldAssignUsers(false);
			setAssigningUsers(true);

			const userIds = selectedUsers.map((user) => user.id);
			onAssignUsersToUserGroup(selectedUserGroup.id, userIds, forceMove);
		}
	}, [shouldAssignUsers, assigningUsers]);

	useEffect(() => {
		if (assigningUsers && assignUsersDone) {
			setAssigningUsers(false);
			setForceMove(false);
			//success notifications
			if (!isEmptyArray(assignedUsersData.accepted)) {
				enqueueSnackbar(
					`${assignedUsersData.accepted.length} ${t('views.assignUsersToUserGroup.messages.success.accepted')} ${!isEmptyObject(selectedUserGroup) ? selectedUserGroup.name : ''}`,
					{ variant: 'success' },
				);
			}

			if (!isEmptyArray(assignedUsersData.rejected)) {
				enqueueSnackbar(
					`${assignedUsersData.rejected.length} ${t('views.assignUsersToUserGroup.messages.success.rejected')} ${!isEmptyObject(selectedUserGroup) ? selectedUserGroup.name : ''}`,
				);
			}
			//redirect
			if (window.history.length > 2) {
				navigate(-1);
			} else {
				navigate('/user-management/users');
			}
		}
	}, [assigningUsers, assignUsersDone]);

	useEffect(() => {
		if (assigningUsers && !assignedUsersLoading && assignedUsersError) {
			setStartedFinishWizard(false);
			setAssigningUsers(false);
			if (isObject(assignedUsersError) && assignedUsersError.key === 'RestrictUserMove') {
				setOpenUserBookingAlert(true);
			} else {
				enqueueSnackbar(
					isObject(assignedUsersError) ? assignedUsersError.message : assignedUsersError,
					{ variant: 'error' },
				);
				setForceMove(false);
			}
		}
	}, [assigningUsers, assignedUsersError, assignedUsersLoading]);

	const handleClickCancel = () => {
		navigate('/user-management/users');
	};

	const callback = (callbackProps) => {
		const {
			userGroup,
			selectedOrganisation: organisationToSave,
			selectedUsers: usersToSave,
			finishedWizard,
		} = callbackProps;

		if (!isUndefined(userGroup) && userGroup.valid) {
			setSelectedUserGroup(userGroup.value);
		}

		if (!isUndefined(organisationToSave)) {
			setSelectedOrganisation(organisationToSave);
		}

		if (!isUndefined(usersToSave)) {
			setSelectedUsers(usersToSave);
		}

		if (finishedWizard && !startedFinishWizard) {
			setStartedFinishWizard(true);
			setShouldAssignUsers(true);
		}
	};

	const assignUsersSteps = [
		{
			name: 'Assign Users Step 1',
			content: SelectUserGroup,
			valid: !isEmptyArray(selectedUsers) && !isEmptyObject(selectedUserGroup),
			props: {
				selectedUsers,
				selectedUserGroup,
				selectedOrganisation,
			},
		},
	];

	const handleRemoveUser = (user) => {
		const selectedIndex = selectedUsers.map((user) => user.id).indexOf(user.id);
		let newSelected = [];
		newSelected = newSelected.concat(
			selectedUsers.slice(0, selectedIndex),
			selectedUsers.slice(selectedIndex + 1),
		);

		setSelectedUsers(newSelected);
	};

	const handleCloseUserBookingDialog = () => {
		setOpenUserBookingAlert(false);
	};

	const handleConfirmUserBookingAlert = () => {
		setForceMove(true);
		setStartedFinishWizard(true);
		setShouldAssignUsers(true);
	};

	const userBookingAlertContent = (
		<Box>
			<Typography variant='body2'>
				{t('views.assignUsersToUserGroup.alertDialog.description.userHasBookings')}
			</Typography>
			<List>
				{(
					isObject(assignedUsersError) &&
					assignedUsersError.key === 'RestrictUserMove' &&
					!assignedUsersLoading
				) ?
					assignedUsersError.additionalData.map((booking) => (
						<ListItem key={`user-booking-${booking.id}`}>
							<Typography>{`${booking.id} - ${booking.userReference.name}`}</Typography>
						</ListItem>
					))
				:	<>
						<ListItem>{<LoadingBar />}</ListItem>
					</>
				}
			</List>
		</Box>
	);

	const sidepanel = (
		<SidepanelContainer>
			<SidePanel title={<Typography variant='h4'>{t('ui.summary')}</Typography>}>
				<div>
					<Typography variant='h5'>
						{!isEmptyObject(selectedUserGroup) ?
							selectedUserGroup.name
						:	t('views.assignUsersToUserGroup.placeholder.selectedUserGroup')}
					</Typography>
					{superAdmin ?
						<Typography>
							{!isEmptyObject(selectedOrganisation) ?
								selectedOrganisation.name
							:	t('views.assignUsersToUserGroup.placeholder.selectedOrganisation')}
						</Typography>
					:	null}
				</div>
				<Divider className={classes.divider} />
				{!isEmptyArray(selectedUsers) ?
					<>
						<Typography variant='caption'>
							{selectedUsers.length} {t('views.assignUsersToUserGroup.userlist.selected')}
						</Typography>
						<List>
							{selectedUsers.map((item) => (
								<ListItem className={classes.item} key={`selected-user-${item.id}`}>
									<div className={classes.itemText}>
										<Typography variant='h5'>{item.name}</Typography>
										<Typography>{item.emailAddress}</Typography>
									</div>
									<IconButton onClick={() => handleRemoveUser(item)} size='small'>
										<CloseIcon />
									</IconButton>
								</ListItem>
							))}
						</List>
					</>
				:	<Typography>{t('views.assignUsersToUserGroup.userlist.empty.title')}</Typography>}
			</SidePanel>
		</SidepanelContainer>
	);

	return (
		<>
			<Wizard
				callback={callback}
				finishButtonLabel={t('view.usermanagement.assigntousergroup.button.contained.assign')}
				handleCancel={handleClickCancel}
				hasCancelButton={true}
				loading={startedFinishWizard}
				sidebar={sidepanel}
				steps={assignUsersSteps}
				title={t('views.assignUsersToUserGroup.page.title')}
			/>
			<AlertDialog
				dialogContent={userBookingAlertContent}
				dialogTitle={t('views.assignUsersToUserGroup.alertDialog.title.userHasBookings')}
				handleClose={handleCloseUserBookingDialog}
				handleConfirm={handleConfirmUserBookingAlert}
				open={openUserBookingAlert}
			/>
		</>
	);
};

AssignUsersToUserGroup.propTypes = {
	assignedUsersToUserGroup: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	onAssignUsersToUserGroup: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		assignedUsersToUserGroup: state.details.assignedUsersToUserGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAssignUsersToUserGroup: (userGroupId, userIds, forceMove) =>
			dispatch(actions.assignUsersToUserGroup(userGroupId, userIds, forceMove)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignUsersToUserGroup);
