import { EmissionsChart } from '~features/trips';

import Page from '../page';

const EmissionsPage = () => {
	return (
		<Page>
			<EmissionsChart />
		</Page>
	);
};

export default EmissionsPage;
