import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Link, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
	EmptyState,
	SearchBar,
	Table,
	GenericMoreButton,
	AlertDialog,
} from '../../../../../components';
import { useSearchComponent } from '../../../../../shared/hooks';
import { isObject, isEmptyArray, isEmptyString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const AssignedUsers = (props) => {
	const {
		assignedUsersList,
		movedUser,
		loading,
		userGroup,
		onFetchAssignedUsers,
		onMoveUserToDefaultUserGroup,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const { enqueueSnackbar } = useSnackbar();

	const [shouldSearch, setShouldSearch] = useState(false);

	const {
		data: assignedUsersData,
		loading: assignedUsersLoading,
		error: assignedUsersError,
	} = assignedUsersList;
	const assignedUsersReady =
		!loading && isObject(assignedUsersData) && !assignedUsersLoading && !assignedUsersError;

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(20);

	// Dialog
	const [dialogOpen, setDialogOpen] = useState(false);
	const [userToRemove, setUserToRemove] = useState();
	const [removingUser, setRemovingUser] = useState();

	const { success: movedUserSuccess, loading: movedUserLoading, error: movedUserError } = movedUser;
	const movingUserDone = movedUserSuccess && !movedUserLoading && !movedUserError;

	const search = useSearchComponent(setPageNumber, setShouldSearch);

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		const page = { number: pageNumber, size: pageSize };

		const filters = {
			...(!isEmptyString(search.value) && { searchTerm: search.value }),
		};

		if (!loading) {
			if (shouldSearch || shouldDoInitialFetch) {
				if (shouldSearch && showingInitialResults) {
					setShowingInitialResults(false);
				}
				onFetchAssignedUsers(userGroup.id, page, filters);
			}

			if (shouldSearch) {
				setShouldSearch(false);
			} else if (shouldDoInitialFetch) {
				setShouldDoInitialFetch(false);
			}
		}
	}, [shouldSearch, shouldDoInitialFetch, showingInitialResults, pageNumber, pageSize, loading]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	/* * * * * * * * *
	 * NOTIFICATIONS *
	 * * * * * * * * */
	useEffect(() => {
		if (removingUser && movingUserDone) {
			enqueueSnackbar(
				`${t('views.userGroupDetail.users.messages.removingUser.success')} ${userToRemove.fullName}`,
				{ variant: 'success' },
			);
			setDialogOpen(false);
			setShouldDoInitialFetch(true);
		}

		if (removingUser && !!movedUserError) {
			enqueueSnackbar(isObject(movedUserError) ? movedUserError.message : movedUserError, {
				variant: 'error',
			});
		}
	}, [removingUser, movingUserDone, movedUserError]);

	/* * * * * * * * *
	 * TABLE ACTIONS *
	 * * * * * * * * */
	const handleClickDelete = (user) => {
		setUserToRemove(user);
		setDialogOpen(true);
	};

	const removeSingleUser = () => {
		onMoveUserToDefaultUserGroup(userToRemove.id);
	};

	const handleCancelDialog = () => {
		setDialogOpen(false);
	};

	const handleConfirmDialog = () => {
		removeSingleUser();
		setRemovingUser(true);
	};

	const createMenuItems = (data) => [
		{
			icon: <DeleteIcon />,
			text: t('view.usermanagement.usergroupdetails.button.inline.removefromgroup'),
			action: () => handleClickDelete(data),
			isRed: true,
		},
	];

	const extraButtons = [
		{
			text: t('view.usermanagement.usergroupdetails.button.contained.assignusers'),
			onClick: () =>
				navigate('/user-management/users/assign/user-group', {
					state: { selectedUserGroup: userGroup },
				}),
			variant: 'contained-primary',
		},
	];

	/* * * * * * * * *
	 * TABLE CONTENT *
	 * * * * * * * * */
	const tableHeader = [
		{
			name: 'name',
			content: t('ui.label.name'),
		},
		{
			name: 'email',
			content: t('ui.label.email'),
		},
		...(isObject(userGroup) && !userGroup.isDefault ?
			[
				{
					name: 'actions',
				},
			]
		:	[]),
	];

	const tableBody =
		assignedUsersReady ?
			assignedUsersData.results.map((user) => [
				//user name
				{
					content: (
						<Link
							color='inherit'
							component={RouterLink}
							to={`/user-management/users/${user.id}/summary`}
							state={{ from: location.pathname }}
							variant='h6'
						>
							{user.firstName + ' ' + user.lastName}
						</Link>
					),
				},
				//email
				{ content: user.emailAddress },
				...(isObject(userGroup) && !userGroup.isDefault ?
					[
						{
							content: (
								<Box display='flex' flexDirection='row-reverse'>
									<GenericMoreButton menuItems={createMenuItems(user)} />
								</Box>
							),
						},
					]
				:	[]),
			])
		:	Array(4)
				.fill(Array(3).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
		setShouldDoInitialFetch(true);
	};

	const handlePageChange = (page) => {
		setPageNumber(page);
		setShouldDoInitialFetch(true);
	};

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const assignedUsersListEmpty =
		(
			isObject(assignedUsersData) &&
			!assignedUsersLoading &&
			isEmptyArray(assignedUsersData.results)
		) ?
			showingInitialResults ?
				<EmptyState
					image={'booking'}
					subTitle={t('views.userGroupDetail.users.empty.description.zeroInSystem')}
					title={t('views.userGroupDetail.users.empty.title')}
				/>
			:	<EmptyState
					image={'booking'}
					subTitle={t('views.userGroupDetail.users.empty.description.zeroMatching')}
					title={t('views.userGroupDetail.users.empty.title')}
				/>
		:	null;

	return (
		<div>
			{!assignedUsersListEmpty || (assignedUsersListEmpty && !showingInitialResults) ?
				<SearchBar
					extraButtons={extraButtons}
					hasExtraButtons
					hideMoreFilters={true}
					placeholder={t('views.userGroupDetail.items.searchPlaceholder')}
					searchEvents={search.events}
					searchValue={search.value}
				/>
			:	null}
			{assignedUsersListEmpty ?
				assignedUsersListEmpty
			:	<Table
					body={tableBody}
					handlePageChange={handlePageChange}
					header={tableHeader}
					loading={assignedUsersLoading}
					page={pageNumber}
					rowsPerPage={pageSize}
					setRowsPerPage={handlePageSizeChange}
					title={t('views.userGroupDetail.users.table.title')}
					total={assignedUsersData ? assignedUsersData.total : 0}
				/>
			}
			<AlertDialog
				dialogDescription={t('views.userGroupDetail.users.removeAlert.description')}
				dialogTitle={t('views.userGroupDetail.users.removeAlert.title')}
				handleClose={handleCancelDialog}
				handleConfirm={handleConfirmDialog}
				loading={movedUserLoading && !movingUserDone}
				open={dialogOpen}
			/>
		</div>
	);
};

AssignedUsers.propTypes = {
	assignedUsersList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	movedUser: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	loading: PropTypes.bool,
	userGroup: PropTypes.object,
	onFetchAssignedUsers: PropTypes.func,
	onMoveUserToDefaultUserGroup: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		assignedUsersList: state.paged.assignedUsers,
		movedUser: state.condition.movedUserToDefaultGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchAssignedUsers: (userGroupId, page, filters) =>
			dispatch(actions.fetchAssignedUsers(userGroupId, page, filters)),
		onMoveUserToDefaultUserGroup: (userId) => dispatch(actions.moveUserToDefaultUserGroup(userId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignedUsers);
