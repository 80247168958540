import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'&.full-width': {
			width: '100%',
		},
	},
	image: {
		width: 130,
	},
	errorStyle: {
		color: colors.red[500],
	},
	info: {
		marginTop: theme.spacing(1),
	},
	list: {
		maxHeight: 320,
	},
	actions: {
		marginTop: theme.spacing(2),
		display: 'flex',
		justifyContent: 'flex-end',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	upload: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'start',
	},
	uploadWrapper: {
		'&.full-width': {
			width: '100%',
			'& + &': {
				marginTop: theme.spacing(2),
			},
		},

		'&.small': {
			flex: '0 0 auto',
			margin: theme.spacing(0, 4, 4, 0),
		},
	},
	uploadInput: {
		display: 'none',
	},
	uploadButton: {
		position: 'relative',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',

		fontSize: '1rem',
		fontWeight: '300',
		textAlign: 'center',

		height: '13.5rem',
		padding: theme.spacing(3),

		cursor: 'pointer',

		border: `1px dashed ${colors.grey[200]}`,

		'&:hover': {
			borderColor: colors.grey[400],
		},

		'&.full-width': {
			height: '13rem',
		},

		'&.ratio11': {
			width: '13.5rem',
		},

		'&.ratio32': {
			width: '20.25rem',
		},

		'&.ratio169': {
			width: '24rem',
		},
		'&.ratio1616': {
			height: '10rem',
		},
	},
	uploadButtonWarning: {
		borderColor: colors.red[500],
	},
	uploadButtonPreview: {
		border: 0,
		cursor: 'default',
		position: 'relative',

		'&.full-width': {
			margin: '0 auto',
		},
	},
	uploadButtonLabel: {
		whiteSpace: 'nowrap',
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	uploadButtonLabelConstraints: {
		display: 'block',
	},
	uploadPreview: {
		display: 'block',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		objectFit: 'contain',
	},
	uploadPreviewRemoveButton: {
		position: 'absolute',
		top: 0,
		right: 0,
		transform: 'translate(40%, -40%)',
		backgroundColor: colors.grey[100],
		'&:hover': {
			backgroundColor: colors.grey[300],
		},
	},
	iconArrow: {
		transform: 'rotate(.75turn)',
		color: colors.grey[400],
	},
	iconBase: {
		transform: 'rotate(.5turn)',
		marginTop: theme.spacing(-3),
		color: colors.grey[400],
	},
}));
