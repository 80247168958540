import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	expansionPanel: {
		'&.Mui-expanded': {
			margin: 0,
		},
	},
}));
