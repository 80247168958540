import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	attentionCard: {
		display: 'flex',
		height: '19px',
		alignItems: 'center',
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	divider: {
		backgroundColor: colors.grey[300],
		marginTop: theme.spacing(5),
	},
	detailsDivider: {
		backgroundColor: colors.grey[300],
	},
	content: {
		marginTop: theme.spacing(3),
	},
	noteContent: {
		padding: 12,
		height: '100%',
	},
	cardConten: {
		padding: 12,
		paddingBottom: '0px',
	},
	noteCard: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	commentContainer: {
		padding: theme.spacing(3, 2),
	},
	itemGroup: {
		...theme.typography.link1,
	},
	cellStyle: {
		whiteSpace: 'nowrap',
	},
	listWrap: {
		padding: theme.spacing(0.1),
		'& .MuiTableHead-root': {
			backgroundColor: '#fff',
		},
	},
	emptyComment: {
		fontStyle: 'italic',
	},
	mapLink: {
		...theme.link.body3,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	attention: {
		margin: theme.spacing(0, 1),
	},
	actions: {
		flexDirection: 'column',
		marginTop: 'auto',
		alignItems: 'flex-start',
		'& > * + *': {
			marginLeft: 0,
		},
	},
	iconInfoLabel: {
		marginRight: theme.spacing(1),
	},
	circularLoading: {
		margin: '8px',
	},
}));
