import {
	FETCH_CONDITION_START,
	FETCH_CONDITION_SUCCESS,
	FETCH_CONDITION_FAIL,
} from '../actions/actionTypes';
import { conditionStates } from '../states';

const initialState = conditionStates.reduce(
	(state, stateName) => ({
		...state,
		[stateName]: { success: false, loading: false, error: null },
	}),
	{},
);

/* * * * * * * * * * * * * * * * * * * * * * * * *
 * METHODS THAT UPDATE STATES WITH CORRECT VALUES *
 * * * * * * * * * * * * * * * * * * * * * * * *  */
const fetchConditionStart = (state, action) => ({
	...state,
	...{ [action.identifier]: { success: false, loading: true, error: null } },
});

const fetchConditionSuccess = (state, action) => ({
	...state,
	...{ [action.identifier]: { success: action.value, loading: false, error: null } },
});

const fetchConditionFail = (state, action) => ({
	...state,
	...{ [action.identifier]: { success: false, loading: false, error: action.error } },
});

/* * * * * * * * *
 * REDUCER METHOD *
 * * * * * * * *  */
// Depending on the active action type, execute the correct function to update the correct states with the correct values
// Pass two parameters, first is the old state values, second is the new state values, these are passed from actions
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CONDITION_START:
			return fetchConditionStart(state, action);
		case FETCH_CONDITION_SUCCESS:
			return fetchConditionSuccess(state, action);
		case FETCH_CONDITION_FAIL:
			return fetchConditionFail(state, action);
		default:
			return state;
	}
};

export default reducer;
