import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		'& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
			maxHeight: '200px',
		},
		overflow: 'visible',
	},
	actionDialog: {
		padding: '40px 0 0 !important',
	},
	content: {
		padding: 12,
		marginBottom: 'auto',
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 2, 2, 2),
		alignSelf: 'flex-end',
		width: '100%',
	},
	pushRight: {
		marginLeft: 'auto',
	},
	switchLabel: {
		width: '100%',
		justifyContent: 'space-between',
		margin: 0,
	},
	useNowVerifyLaterLabel: {
		'& .MuiTypography-root': {
			// margin: theme.spacing(1, 0),
			marginBottom: theme.spacing(0),
		},
	},
	licenseVerification: {
		// marginTop: theme.spacing(1),
		padding: theme.spacing(1),
	},
	collapse: {
		marginBottom: theme.spacing(2),
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));
