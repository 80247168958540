import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	content: {
		backgroundColor: theme.palette.common.white,
		height: '100%',
		paddingTop: 56,
		[theme.breakpoints.up('sm')]: {
			paddingTop: 64,
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));
