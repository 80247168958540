import { useState, useEffect } from 'react';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Details from './Details/Details';
import Settings from './Settings/Settings';
import { Wizard } from '../../../../components';
import { isFullString, isSuperAdmin, isInteger, isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

const AddContract = (props) => {
	const { onAddContract, addContract, onRresetState } = props;

	const auth = useAuth();

	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { data: addContractData, error: addContractError } = addContract;

	const [contractType, setContractType] = useState('');

	const [selectedOrganisation, setSelectedOrganisation] = useState('');
	const [organisationFilter, setOrganisationFilter] = useState('all');

	const [selectedUser, setSelectedUser] = useState('');
	const [userFilter, setUserFilter] = useState('all');

	const [selectedIteminstances, setSelectedIteminstances] = useState('');
	const [iteminstancesFilter, setIteminstancesFilter] = useState('all');

	const [selectedTerms, setSelectedTerms] = useState('');
	const [termsFilter, setTermsFilter] = useState('all');

	const [selectedPartner, setSelectedPartner] = useState('');
	const [partnerFilter, setPartnerFilter] = useState('all');

	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);

	const validSelections =
		isInteger(iteminstancesFilter) &&
		isInteger(termsFilter) &&
		(isInteger(userFilter) || isInteger(partnerFilter));

	const [contractDetails, setContractDetails] = useState({
		name: '',
		description: '',
	});

	const [validDetails, setValidDetails] = useState(false);

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isObject(addContractError)) {
			enqueueSnackbar(addContractError.message, { variant: 'error' });
			setStartedFinishWizard(false);
			onRresetState('addContract');
		}
	}, [addContract]);

	useEffect(() => {
		if (isObject(addContractData)) {
			enqueueSnackbar(`${t('views.contract.add.success.message')} ${contractDetails.name}`, {
				variant: 'success',
			});
			setStartedFinishWizard(false);
			onRresetState('addContract');
			navigate('/contracts/contracts');
		}
	}, [addContract]);

	useEffect(() => {
		if (contractType === 'internal' && isInteger(partnerFilter)) {
			setSelectedPartner('');
			setPartnerFilter('all');
		} else if (contractType === 'external' && isInteger(userFilter)) {
			setSelectedUser('');
			setUserFilter('all');
		}
	}, [contractType]);

	const validate = (value, validation, update, negative = false) => {
		if (!negative ? validation(value) : !validation(value)) {
			update(value);
		}
	};

	const callback = (data) => {
		const {
			updatedContractType,
			updatedName,
			updatedDescription,
			updatedStartDate,
			updatedEndDate,
			finishedWizard,
		} = data;

		validate(updatedContractType, isFullString, setContractType);
		if (isFullString(updatedName?.value) || updatedDescription) {
			setContractDetails({
				...contractDetails,
				name: updatedName.value,
				description: updatedDescription.value,
			});
		}
		if (updatedName?.valid && updatedDescription?.valid) {
			setValidDetails(true);
		}
		if (updatedStartDate) {
			setSelectedStartDate(updatedStartDate);
		}
		if (updatedEndDate) {
			setSelectedEndDate(updatedEndDate);
		}
		if (finishedWizard) {
			onAddContract({
				name: contractDetails.name,
				itemInstanceId: iteminstancesFilter,
				termsId: termsFilter,
				...(isFullString(contractDetails.description) && { comment: contractDetails.description }),
				...(isObject(selectedStartDate) && { start: selectedStartDate }),
				...(isObject(selectedEndDate) && { end: selectedEndDate }),
				...(isInteger(userFilter) && { userId: userFilter }),
				...(isInteger(partnerFilter) && { organisationId: partnerFilter }),
			});
			setStartedFinishWizard(true);
		}
	};

	const addContractSteps = [
		{
			name: 'Details',
			content: Details,
			valid:
				isSuperAdmin(auth.user?.profile.role) ?
					isInteger(organisationFilter) && isFullString(contractType)
				:	isFullString(contractType),
			props: {
				contractType,
				selectedOrganisation,
				setSelectedOrganisation,
				organisationFilter,
				setOrganisationFilter,
			},
		},
		{
			name: 'Settings',
			content: Settings,
			valid: validDetails && validSelections,
			props: {
				contractType,
				contractDetails,
				organisationFilter,
				selectedUser,
				setSelectedUser,
				userFilter,
				setUserFilter,
				selectedIteminstances,
				setSelectedIteminstances,
				iteminstancesFilter,
				setIteminstancesFilter,
				selectedTerms,
				setSelectedTerms,
				termsFilter,
				setTermsFilter,
				selectedPartner,
				setSelectedPartner,
				partnerFilter,
				setPartnerFilter,
				selectedStartDate,
				selectedEndDate,
			},
		},
	];

	return (
		<Wizard
			callback={callback}
			finishButtonLabel={t('views.contract.add.button.addContract')}
			loading={startedFinishWizard}
			stepperType={'progress'}
			steps={addContractSteps}
		/>
	);
};

AddContract.propTypes = {
	onAddContract: PropTypes.func,
	onRresetState: PropTypes.func,

	addContract: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		addContract: state.details.addContract,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddContract: (bodyData) => dispatch(actions.addContract(bodyData)),
		onRresetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContract);
