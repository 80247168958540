import typography from '../typography';

export default {
	styleOverrides: {
		root: {
			borderRadius: 50,
			...typography.input,
		},
		notchedOutline: {
			borderColor: 'rgba(0,0,0,0.15)',
		},
		multiline: {
			borderRadius: 10,
		},
	},
};
