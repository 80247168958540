import path from 'path';

import { PagedResponse } from '~interfaces';
import { BaseReferenceResponse } from '~interfaces/responses';
import { BaseServiceMapper, TopologyService } from '~services';

import Organisation from '../interfaces/organisation';
import { string } from 'prop-types';

class OrganisationsService extends TopologyService {
	public readonly path = 'organisations';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of organisations
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getOrganisations({
		page = 1,
		pageSize = 10,
		...args
	}: {
		page: number;
		pageSize: number;
		searchQuery?: string;
	}): Promise<PagedResponse<Organisation>> {
		const { data } = await this._client.get<PagedResponse<OrganisationResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				searchTerm: args.searchQuery,
				sortBy: 'name',
			},
		});

		return {
			...data,
			results: data.results.map((el) => OrganisationsServiceMapper.fromResponse(el)),
		};
	}

	/**
	 * Get the details of a single organisation
	 * @param id
	 * @returns
	 */
	async getOrganisationById(id: string): Promise<Organisation> {
		const { data } = await this._client.get<OrganisationResponse>(path.join(this.path, id));

		return OrganisationsServiceMapper.fromResponse(data);
	}
}

class OrganisationsServiceMapper {
	static fromResponse(data: OrganisationResponse): Organisation {
		const { id, name, logo, ...rest } = data;

		return {
			...rest,
			...BaseServiceMapper.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			logoUri: logo,
		};
	}
}

interface OrganisationResponse extends BaseReferenceResponse {
	logo?: string;
	description?: string;
}

export default OrganisationsService;
