import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	mainActions: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	cancelButton: {
		marginTop: theme.spacing(1),
	},
	contact: {
		margin: theme.spacing(2, 2),
	},
}));
