import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	content: {
		padding: 0,
	},
	actions: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		'& > * + *': {
			marginLeft: 0,
		},
	},
	resetButton: {
		textAlign: 'left',
	},
	mainActions: {
		marginBottom: '1rem',
	},
	// deleteButton: {
	//   marginTop: theme.spacing(1),
	//   color: theme.palette.white,
	//   backgroundColor: theme.palette.error.main,
	//   '&:hover': {
	//     backgroundColor: theme.palette.error.dark
	//   }
	// },
}));
