import { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { /*Maps, */ createGoogleMap, createMarker, createDragEvent } from './Google';

// use default algorithm and renderer

import { isNull, isObject, isUndefined } from '../../../shared/utility';

const InteractiveMap = (props) => {
	const { callback, myLocation, errorMessage, height, radius } = props;
	const mapStyles = {
		width: '100%',
		height: height ? height : '300px',
		borderRadius: radius ? radius : '12px',
	};

	const [map, setMap] = useState(null);
	const [marker, setMarker] = useState(null);
	const [position, setPosition] = useState(myLocation);

	const googleMapRef = useRef();

	const updatePosition = (pos) => {
		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ location: pos }, (results, status) => {
			if (status === window.google.maps.GeocoderStatus.OK) {
				const result = results.filter((item) => item.types.includes('street_address'))[0];

				if (!isUndefined(result)) {
					// const address = result.address_components.reduce((acc, cmp) => ({
					//   ...acc,
					//   ...(cmp.types.includes('street_number') && {number: cmp.long_name}),
					//   ...(cmp.types.includes('route') && {street: cmp.long_name}),
					//   ...(cmp.types.includes('postal_code') && {postalCode: cmp.long_name}),
					//   ...(cmp.types.includes('locality') && {city: cmp.long_name}),
					//   ...(cmp.types.includes('country') && {country: cmp.short_name})
					// }), {});
					setPosition({
						lat: result.geometry.location.lat((l) => l),
						lng: result.geometry.location.lng((l) => l),
						address: result.formatted_address,
						placeId: result.place_id,
					});
				} else {
					setPosition({
						address: errorMessage,
					});
				}
			} else {
				console.log('Geocoder status not OK!');
			}
		});
	};

	useEffect(() => {
		const createMap = createGoogleMap(googleMapRef.current, myLocation);
		setMap(createMap);
	}, []);

	useEffect(() => {
		if (!isNull(map)) {
			addMarker();
		}
	}, [map]);

	const addMarker = () => {
		map.setCenter(myLocation);
		const marker = createMarker(myLocation);
		marker.setMap(map);
		setMarker(marker);
		createDragEvent(marker, updatePosition);
	};

	const removeMarker = () => {
		marker.setMap(null);
		setMarker(null);
	};

	useEffect(() => {
		if (!isNull(position.lat) && !isNull(position.lng)) {
			callback(position);
		}
	}, [position.lat, position.lng]);

	useEffect(() => {
		if (isObject(myLocation) && !isNull(myLocation.lat) && !isNull(myLocation.lng)) {
			if (!isNull(marker)) {
				removeMarker();
				addMarker();
			}
			setPosition(myLocation);
		}
	}, [myLocation]);

	return <div id='google-map' ref={googleMapRef} style={mapStyles} />;
};

InteractiveMap.propTypes = {
	callback: PropTypes.func,
	myLocation: PropTypes.object,
	errorMessage: PropTypes.string.isRequired,
	height: PropTypes.string,
	radius: PropTypes.string,
};

export default InteractiveMap;
