import { useState, useEffect } from 'react';

import { Card, CardContent, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { FormField, SearchAutocomplete, StyledButton } from '../../../../../components';
import { categories, licensePlateLength } from '../../../../../constantsOld';
import {
	useWizardFormField,
	useDebouncedWizardSave,
	useDebouncedBackendFieldCheck,
	useDebouncedLocalFieldCheck,
} from '../../../../../shared/hooks';
import { isFullArray, isUndefined, isObject, isEmptyObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import SelectedHubCard from '../SettingsCard/SelectedHubCard/SelectedHubCard';

const AddInstance = (props) => {
	const {
		className,
		selectedCategory,
		save,
		addItemInstance,

		itemId,
		validatedRoomNumber,
		validatedBikeNumber,
		validatedFrameNumber,
		validatedLicensePlate,
		validatedVinNumber,
		onValidateInstanceRoomNumber,
		onValidateInstanceBikeNumber,
		onValidateInstanceFrameNumber,
		onValidateInstanceLicensePlate,
		onValidateInstanceVinNumber,
		handleCheckFieldUniqueLocally,
		isAbleToAddItemInstances,
		selectedOrganisationId,
		onFetchHubs,
		selectHub: selectedHub,
		hubsList,
		onValidateInstanceParkingNumber,
		onValidateInstanceAssetNumber,
		validatedParkingNumber,
		validatedAssetNumber,
		reservationType,
		onValidateInstanceTrailerVinNumber,
		validatedTrailerVinNumber,
		onValidateInstanceTrailerNumber,
		validatedTrailerNumber,
		validateBoatNumber,
		onValidateInstanceBoatNumber,
	} = props;
	const { t } = useTranslation('general');

	const [focus, setFocus] = useState(false);
	const classes = useStyles();

	//Item instance Hub
	const [selectInstanceHub, setSelectInstanceHub] = useState(selectedHub || {});

	//BIKE fields
	const bikeNumber = useWizardFormField('', { required: true, maxLength: 16 });
	useDebouncedWizardSave('bikeNumber', bikeNumber.value, bikeNumber.isValid, save, 300);
	const checkedBikeNumber = useDebouncedBackendFieldCheck(
		bikeNumber.value,
		onValidateInstanceBikeNumber,
		validatedBikeNumber,
		{ itemId },
	);
	const isLocallyUniqueBikeNumber = useDebouncedLocalFieldCheck(
		bikeNumber.value,
		'bikeNumber',
		handleCheckFieldUniqueLocally,
	);

	// const bikeLockId = useWizardFormField('', { required: true, minLength: 36, maxLength: 36, isUUID: true });
	// useDebouncedWizardSave('bikeLock', bikeLockId.value, bikeLockId.isValid, save, 300);
	// const checkedBikeLockId = useDebouncedBackendFieldCheck(bikeLockId.value, onValidateInstanceBikeLockId, validatedBikeLockId, { itemId });
	// const isLocallyUniqueBikeLockId = useDebouncedLocalFieldCheck(bikeLockId.value, 'bikeLockId', handleCheckFieldUniqueLocally);

	const frameNumber = useWizardFormField('', { required: true, minLength: 6, maxLength: 32 });
	useDebouncedWizardSave('frameNumber', frameNumber.value, frameNumber.isValid, save, 300);
	const checkedFrameNumber = useDebouncedBackendFieldCheck(
		frameNumber.value,
		onValidateInstanceFrameNumber,
		validatedFrameNumber,
		{ itemId },
	);
	const isLocallyUniqueFrameNumber = useDebouncedLocalFieldCheck(
		frameNumber.value,
		'frameNumber',
		handleCheckFieldUniqueLocally,
	);

	//OFFICE fields
	const roomNumber = useWizardFormField('', { required: true, maxLength: 16 });
	useDebouncedWizardSave('roomNumber', roomNumber.value, roomNumber.isValid, save, 300);
	const checkedRoomNumber = useDebouncedBackendFieldCheck(
		roomNumber.value,
		onValidateInstanceRoomNumber,
		validatedRoomNumber,
		{ itemId },
	);
	const isLocallyUniqueRoomNumber = useDebouncedLocalFieldCheck(
		roomNumber.value,
		'roomNumber',
		handleCheckFieldUniqueLocally,
	);

	//CAR fields
	const carBrand = useWizardFormField('', { required: true, maxLength: 16 });
	useDebouncedWizardSave('carBrand', carBrand.value, carBrand.isValid, save, 300);

	const model = useWizardFormField('', { required: true, maxLength: 32 });
	useDebouncedWizardSave('model', model.value, model.isValid, save, 300);

	const licensePlate = useWizardFormField('', {
		required: true,
		minLength: 1,
		maxLength: licensePlateLength,
	});
	useDebouncedWizardSave('licensePlate', licensePlate.value, licensePlate.isValid, save, 300);
	const checkedLicensePlate = useDebouncedBackendFieldCheck(
		licensePlate.value,
		onValidateInstanceLicensePlate,
		validatedLicensePlate,
		{ itemId },
	);
	const isLocallyUniqueLicensePlate = useDebouncedLocalFieldCheck(
		licensePlate.value,
		'licensePlate',
		handleCheckFieldUniqueLocally,
	);

	// const carLockId = useWizardFormField('', { required: true, minLength: 36, maxLength: 36, isUUID: true });
	// useDebouncedWizardSave('carLockId', carLockId.value, carLockId.isValid, save, 300);

	const vinNumber = useWizardFormField('', { required: true, minLength: 17, maxLength: 17 });
	useDebouncedWizardSave('vinNumber', vinNumber.value, vinNumber.isValid, save, 300);

	const checkedVinNumber = useDebouncedBackendFieldCheck(
		vinNumber.value,
		onValidateInstanceVinNumber,
		validatedVinNumber,
		{ itemId },
	);
	const isLocallyUniqueVinNumber = useDebouncedLocalFieldCheck(
		vinNumber.value,
		'vinNumber',
		handleCheckFieldUniqueLocally,
	);

	// TRAILER fields
	const trailerNumber = useWizardFormField('', { required: true, maxLength: 32 });
	useDebouncedWizardSave('trailerNumber', trailerNumber.value, trailerNumber.isValid, save, 300);
	const checkedTrailerNumber = useDebouncedBackendFieldCheck(
		trailerNumber.value,
		onValidateInstanceTrailerNumber,
		validatedTrailerNumber,
		{ itemId },
	);
	const isLocallyUniqueTrailerNumber = useDebouncedLocalFieldCheck(
		trailerNumber.value,
		'trailerNumber',
		handleCheckFieldUniqueLocally,
	);

	const trailerVinNumber = useWizardFormField('', { required: true, minLength: 17, maxLength: 17 });
	useDebouncedWizardSave('vinNumber', trailerVinNumber.value, trailerVinNumber.isValid, save, 300);
	const checkedTrailerVinNumber = useDebouncedBackendFieldCheck(
		trailerVinNumber.value,
		onValidateInstanceTrailerVinNumber,
		validatedTrailerVinNumber,
		{ itemId },
	);
	const isLocallyUniqueTrailerVinNumber = useDebouncedLocalFieldCheck(
		trailerVinNumber.value,
		'trailerVinNumber',
		handleCheckFieldUniqueLocally,
	);

	// PARKING LOT fields
	const parkingNumber = useWizardFormField('', { required: true, maxLength: 16 });
	useDebouncedWizardSave('parkingNumber', parkingNumber.value, parkingNumber.isValid, save, 300);

	const checkedParkingNumber = useDebouncedBackendFieldCheck(
		parkingNumber.value,
		onValidateInstanceParkingNumber,
		validatedParkingNumber,
		{ itemId },
	);
	const isLocallyUniqueParkingNumber = useDebouncedLocalFieldCheck(
		parkingNumber.value,
		'parkingNumber',
		handleCheckFieldUniqueLocally,
	);
	// ASSET fields
	const assetNumber = useWizardFormField('', { required: true, maxLength: 16 });
	useDebouncedWizardSave('assetNumber', assetNumber.value, assetNumber.isValid, save, 300);

	const checkedAssetNumber = useDebouncedBackendFieldCheck(
		assetNumber.value,
		onValidateInstanceAssetNumber,
		validatedAssetNumber,
		{ itemId },
	);
	const isLocallyUniqueAssetNumber = useDebouncedLocalFieldCheck(
		assetNumber.value,
		'assetNumber',
		handleCheckFieldUniqueLocally,
	);

	const mileage = useWizardFormField('', {
		required: true,
		isNumeric: true,
		minAmount: 0,
		maxAmount: 2147483647,
	});
	const co2GramsPerKilometer = useWizardFormField(0, { isNumeric: true, maxAmount: 2147483647 });

	const taxRegistrationType = useWizardFormField('', { required: true });
	useDebouncedWizardSave(
		'taxRegistrationType',
		taxRegistrationType.value,
		taxRegistrationType.isValid,
		save,
		300,
	);

	// BOAT
	const boatNumber = useWizardFormField('', { required: true, minLength: 1, maxLength: 32 });
	useDebouncedWizardSave('boatNumber', boatNumber.value, boatNumber.isValid, save, 300);
	const checkedBoatNumber = useDebouncedBackendFieldCheck(
		boatNumber.value,
		onValidateInstanceBoatNumber,
		validateBoatNumber,
		{ itemId },
	);
	const isLocallyUniqueBoatNumber = useDebouncedLocalFieldCheck(
		boatNumber.value,
		'boatNumber',
		handleCheckFieldUniqueLocally,
	);

	const itemInstanceValid =
		selectedCategory === categories.OFFICES ? roomNumber.isValid
		: selectedCategory === categories.BIKES ? bikeNumber.isValid && frameNumber.isValid
		: selectedCategory === categories.CARS ?
			carBrand.isValid &&
			model.isValid &&
			licensePlate.isValid &&
			vinNumber.isValid &&
			taxRegistrationType.isValid
		: selectedCategory === categories.TRAILERS ? isObject(trailerNumber)
		: selectedCategory === categories.PARKINGLOTS ? parkingNumber.isValid
		: selectedCategory === categories.ASSETS ? assetNumber.isValid
		: selectedCategory === categories.BOATS ? boatNumber.isValid
		: null;

	useEffect(() => {
		save({ itemInstanceValid });
	}, [itemInstanceValid]);

	//TODO: Maybe this information shoud come from the backend
	const itemInstanceFields = [
		...(selectedCategory === categories.BIKES ?
			[
				{
					name: 'bikeNumber',
					label: t('ui.label.bikeNumber'),
					value: bikeNumber,
					isExtraValid: checkedBikeNumber.isUnique && isLocallyUniqueBikeNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.bikeNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.bikeNumber'),
				},
				// { name: 'bikeLockId', label: t('ui.label.bikeLockID'), value: bikeLockId, maxLength: 36, isExtraValid: checkedBikeLockId.isUnique && isLocallyUniqueBikeLockId, extraValidHelperText: t('views.addItem.addInstance.error.bikeLockIdAlreadyInUse'), placeholder: t('ui.placeholder.bikeLockId') },
				{
					name: 'frameNumber',
					label: t('ui.label.frameNumber'),
					value: frameNumber,
					maxLength: 32,
					isExtraValid: checkedFrameNumber.isUnique && isLocallyUniqueFrameNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.frameNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.frameNumber'),
				},
			]
		:	[]),
		...(selectedCategory === categories.OFFICES ?
			[
				{
					name: 'roomNumber',
					label: t('ui.label.roomNumberInstance'),
					value: roomNumber,
					isExtraValid: checkedRoomNumber.isUnique && isLocallyUniqueRoomNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.roomNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.roomNumber'),
				},
			]
		:	[]),
		...(selectedCategory === categories.CARS ?
			[
				{
					name: 'licensePlate',
					label: t('ui.label.licensePlate'),
					value: licensePlate,
					isExtraValid: checkedLicensePlate.isUnique && isLocallyUniqueLicensePlate,
					extraValidHelperText: t('views.addItem.addInstance.error.licensePlateAlreadyInUse'),
					placeholder: t('ui.placeholder.licensePlate'),
					maxLength: licensePlateLength,
				},
				{
					name: 'brand',
					label: t('ui.label.brand'),
					value: carBrand,
					placeholder: t('ui.placeholder.brand'),
				},
				{
					name: 'model',
					label: t('ui.label.model'),
					value: model,
					placeholder: t('ui.placeholder.model'),
				},
				{
					name: 'vinNumber',
					label: t('ui.label.vinNumber'),
					value: vinNumber,
					isExtraValid: checkedVinNumber.isUnique && isLocallyUniqueVinNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.vinNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.vinNumber'),
					maxLength: 17,
				},
				// { name: 'carLockId', label: t('ui.label.carLockID'), value: carLockId, isExtraValid: checkedCarLockId.isUnique && isLocallyUniqueCarLockId, extraValidHelperText: t('views.addItem.addInstance.error.carLockIdAlreadyInUse'), placeholder: t('ui.placeholder.carLockId'), maxLength: 36},
				{
					name: 'currentHectometers',
					label: t('ui.label.mileage'),
					value: mileage,
					placeholder: t('ui.placeholder.mileage'),
				},
				{
					name: 'gramsPerKilometers',
					label: t('ui.label.co2GramsPerKilometer'),
					value: co2GramsPerKilometer,
				},
			]
		:	[]),
		...(selectedCategory === categories.TRAILERS ?
			[
				{
					name: 'trailerNumber',
					label: t('ui.label.trailerNumber'),
					maxLength: 32,
					value: trailerNumber,
					isExtraValid: checkedTrailerNumber.isUnique && isLocallyUniqueTrailerNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.trailerNumberAlreadyInUse'),
					placeholder: t('ui.label.trailerNumber'),
				},
				{
					name: 'vinNumber',
					label: t('ui.label.vinNumber'),
					value: trailerVinNumber,
					maxLength: 17,
					isExtraValid: checkedTrailerVinNumber.isUnique && isLocallyUniqueTrailerVinNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.vinNumberAlreadyInUse'),
					placeholder: t('ui.placeholder.vinNumber'),
				},
			]
		:	[]),
		...(selectedCategory === categories.PARKINGLOTS ?
			[
				{
					name: 'parkingNumber',
					label: t('ui.label.parking.parkingNumber'),
					maxLength: 16,
					isExtraValid: checkedParkingNumber.isUnique && isLocallyUniqueParkingNumber,
					value: parkingNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.parkingNumberAlreadyInUse'),
					placeholder: t('ui.label.parkingNumber.placeholder'),
				},
			]
		:	[]),
		...(selectedCategory === categories.ASSETS ?
			[
				{
					name: 'assetNumber',
					label: t('ui.label.parking.assetNumber'),
					maxLength: 16,
					isExtraValid: checkedAssetNumber.isUnique && isLocallyUniqueAssetNumber,
					value: assetNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.assetNumberAlreadyInUse'),
					placeholder: t('ui.label.assetNumber.placeholder'),
				},
			]
		:	[]),
		...(selectedCategory === categories.BOATS ?
			[
				{
					name: 'boatNumber',
					label: t('ui.label.registrationNumber'),
					maxLength: 16,
					isExtraValid: checkedBoatNumber.isUnique && isLocallyUniqueBoatNumber,
					value: boatNumber,
					extraValidHelperText: t('views.addItem.addInstance.error.registrationNumberAlreadyInUse'),
					placeholder: t('ui.label.assetNumber.placeholder'),
				},
				{
					name: 'brand',
					label: t('ui.label.brand'),
					value: carBrand,
					placeholder: t('ui.placeholder.brand'),
				},
				{
					name: 'model',
					label: t('ui.label.model'),
					value: model,
					placeholder: t('ui.placeholder.model'),
				},
			]
		:	[]),
	];

	//Hubs
	const [categoryIdFilter] = useState({
		name: 'categoryId',
		value: selectedCategory,
	});

	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value: selectedOrganisationId,
	});

	const [sortingFilter] = useState({
		name: 'filters',
		value: 'none',
	});

	const resetSelectedHub = () => {
		setSelectInstanceHub({});
	};

	const formInvalid = isFullArray(
		itemInstanceFields.filter(
			(field) => !field.value.isValid || (!isUndefined(field.isExtraValid) && !field.isExtraValid),
		),
	);
	const formUntouched = isFullArray(itemInstanceFields.filter((field) => !field.value.isTouched));

	const handleAddInstance = () => {
		setFocus(true);
		if (!formInvalid && !formUntouched) {
			addItemInstance([
				...itemInstanceFields,
				{
					name: 'itemInstanceLocation',
					label: t('ui.label.location'),
					value: {
						id: selectInstanceHub.id,
						value: `${!isUndefined(selectInstanceHub.address.street) ? selectInstanceHub.address.street : ''} ${!isUndefined(selectInstanceHub.address.number) ? selectInstanceHub.address.number : ''}, ${selectInstanceHub.address.city}`,
					},
				},
			]);
			itemInstanceFields.map((field) => {
				field.value.onChange('');
				field.value.setTouched(false);
			});
		}
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Card>
				<CardContent>
					<Typography className={classes.paragraph} variant='body2'>
						{t('views.addItem.instances.info')}
					</Typography>
					<Card className={classes.innerCard}>
						<CardContent className={classes.cardContent}>
							<Typography className={classes.fillInHeader} variant='h3'>
								{t('views.addItem.instances.subsection.title')}
							</Typography>
							{itemInstanceFields.map((item) => (
								<FormField
									autoFocus={focus}
									className={classes.formSpacing}
									hideCharacterCounter={!item.value.hasFocus}
									key={item.name}
									label={item.label}
									name={item.name}
									required={item.value?.isRequired}
									variable={item.value}
									{...(!isUndefined(item.isExtraValid) && {
										isExtraValid: item.isExtraValid,
										extraValidHelperText: item.extraValidHelperText,
									})}
									{...(!isUndefined(item.maxLength) && { maxLength: item.maxLength })}
									{...(!isUndefined(item.placeholder) && { placeholder: item.placeholder })}
								/>
							))}
							{!isEmptyObject(selectInstanceHub) ?
								<SelectedHubCard
									handleClose={resetSelectedHub}
									hasCloseButton={true}
									hubData={selectInstanceHub}
								/>
							:	<SearchAutocomplete
									dataList={hubsList}
									extraFilter={categoryIdFilter}
									filter={organisationIdFilter}
									listType={'hubs'}
									onFetchData={onFetchHubs}
									placeholder={t('views.addItem.settingsCard.locationCard.searchLocation')}
									setSelected={setSelectInstanceHub}
									sortingFilter={sortingFilter}
								/>
							}
							<div className={classes.buttons}>
								<StyledButton
									className={classes.addButton}
									disabled={
										formInvalid ||
										formUntouched ||
										(reservationType !== 'nonBookable' && !isAbleToAddItemInstances)
									}
									onClick={handleAddInstance}
									size='large'
									variant='contained-secondary'
								>
									{t('ui.button.contained.add')}
								</StyledButton>
							</div>
						</CardContent>
					</Card>
					{reservationType !== 'nonBookable' && !isAbleToAddItemInstances ?
						<Card className={classes.alertCard}>
							<CardContent className={classes.alertCardContent}>
								<Typography className={classes.alertCardText} variant='body2'>
									{t('views.addItem.addInstance.alertMessage.limitReached')}
								</Typography>
							</CardContent>
						</Card>
					:	null}
				</CardContent>
			</Card>
		</div>
	);
};

AddInstance.propTypes = {
	className: PropTypes.string,
	selectedCategory: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	save: PropTypes.func,
	addItemInstance: PropTypes.func,

	itemStep: PropTypes.number,
	isItemDetailPage: PropTypes.bool,
	isAbleToAddItemInstances: PropTypes.bool,
	itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	selectHub: PropTypes.object,
	selectedOrganisationId: PropTypes.number,
	reservationType: PropTypes.string,
	hubsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedBikeLockId: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedBikeNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedFrameNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedCarLockId: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedLicensePlate: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedVinNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedRoomNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedParkingNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedAssetNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedTrailerVinNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validatedTrailerNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	validateBoatNumber: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchHubs: PropTypes.func,
	onValidateInstanceRoomNumber: PropTypes.func,
	onValidateInstanceBikeLockId: PropTypes.func,
	onValidateInstanceBikeNumber: PropTypes.func,
	onValidateInstanceFrameNumber: PropTypes.func,
	onValidateInstanceLicensePlate: PropTypes.func,
	onValidateInstanceCarLockId: PropTypes.func,
	onValidateInstanceVinNumber: PropTypes.func,
	onValidateInstanceParkingNumber: PropTypes.func,
	onValidateInstanceAssetNumber: PropTypes.func,
	onValidateInstanceTrailerVinNumber: PropTypes.func,
	handleCheckFieldUniqueLocally: PropTypes.func.isRequired,
	onValidateInstanceTrailerNumber: PropTypes.func,
	onValidateInstanceBoatNumber: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		hubsList: state.paged.hubs,

		validatedRoomNumber: state.condition.validatedRoomNumber,
		validatedBikeLockId: state.condition.validatedBikeLockId,
		validatedBikeNumber: state.condition.validatedBikeNumber,
		validatedFrameNumber: state.condition.validatedFrameNumber,
		validatedCarLockId: state.condition.validatedCarLockId,
		validatedLicensePlate: state.condition.validatedLicensePlate,
		validatedVinNumber: state.condition.validatedVinNumber,
		validatedParkingNumber: state.condition.validatedParkingNumber,
		validatedAssetNumber: state.condition.validatedAssetNumber,
		validatedTrailerVinNumber: state.condition.validatedTrailerVinNumber,
		validatedTrailerNumber: state.condition.validatedTrailerNumber,
		validateBoatNumber: state.condition.validateBoatNumber,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
		onValidateInstanceRoomNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceRoomNumber(itemId, value)),
		onValidateInstanceBikeLockId: ({ itemId, value }) =>
			dispatch(actions.validateInstanceBikeLockId(itemId, value)),
		onValidateInstanceBikeNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceBikeNumber(itemId, value)),
		onValidateInstanceFrameNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceFrameNumber(itemId, value)),
		onValidateInstanceLicensePlate: ({ itemId, value }) =>
			dispatch(actions.validateInstanceLicensePlate(itemId, value)),
		onValidateInstanceCarLockId: ({ itemId, value }) =>
			dispatch(actions.validateInstanceCarLockId(itemId, value)),
		onValidateInstanceVinNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceVinNumber(itemId, value)),
		onValidateInstanceParkingNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceParkingNumber(itemId, value)),
		onValidateInstanceAssetNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceAssetNumber(itemId, value)),
		onValidateInstanceTrailerVinNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceTrailerVinNumber(itemId, value)),
		onValidateInstanceTrailerNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceTrailerNumber(itemId, value)),
		onValidateInstanceBoatNumber: ({ itemId, value }) =>
			dispatch(actions.validateInstanceBoatNumber(itemId, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInstance);
