import { useEffect, useState } from 'react';

import { Card, CardHeader, CardContent } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import { useStyles } from './style';
import { FormField } from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';
import { isObject, isEmptyString, isFullString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const BillingInfo = (props) => {
	const {
		className,
		vatNumber,
		onValidateOrganisationVATField,
		checkedVATField,
		chamberOfCommerceNumber,
		enterpriseNumber: savedEnterpriseNumber,
		onValidateOrganisationCOCField,
		checkedCOCField,
		ibanNumber,
		onValidateOrganisationIBANField,
		checkedIBANField,
		onValidateOrganisationEnterpriseField,
		checkedEnterpriseField,
		country,
		save,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const organisationVatNumber = useWizardFormField(vatNumber || '', {
		minLength: 8,
		maxLength: 16,
	});
	const [isUniqueVatNumber, setIsUniqueVatNumber] = useState(true);

	useDebouncedWizardSave(
		'organisationVatNumber',
		organisationVatNumber.value,
		organisationVatNumber.isValid && isUniqueVatNumber,
		save,
		300,
	);

	const debouncedVatValue = useDebounce(organisationVatNumber.value, 300);

	useEffect(() => {
		if (!isEmptyString(debouncedVatValue)) {
			onValidateOrganisationVATField(debouncedVatValue);
		}
	}, [debouncedVatValue]);

	const {
		success: checkedVATSuccess,
		loading: checkedVATLoading,
		error: checkedVATError,
	} = checkedVATField;

	useEffect(() => {
		if (!checkedVATLoading && !isObject(checkedVATError)) {
			setIsUniqueVatNumber(true);
		} else if (!checkedVATLoading) {
			if (checkedVATError.key === 'DuplicateVatNumber') {
				setIsUniqueVatNumber(false);
			}
		}
	}, [checkedVATSuccess, checkedVATLoading, checkedVATError]);

	const [showEnterpriseNumber, setShowEnterpriseNumber] = useState(country === 'BE');

	useEffect(() => {
		setShowEnterpriseNumber(country === 'BE');
	}, [country]);

	const enterpriseNumber = useWizardFormField(savedEnterpriseNumber || '', {
		minLength: 10,
		maxLength: 12,
	});
	const [isUniqueEnterpriseNumber, setIsUniqueEnterpriseNumber] = useState(true);
	useDebouncedWizardSave(
		'enterpriseNumber',
		enterpriseNumber.value,
		enterpriseNumber.isValid && isUniqueEnterpriseNumber,
		save,
		300,
	);

	const debouncedEnterpriseValue = useDebounce(enterpriseNumber.value, 300);

	useEffect(() => {
		if (!isEmptyString(debouncedEnterpriseValue)) {
			onValidateOrganisationEnterpriseField(debouncedEnterpriseValue);
		}
	}, [debouncedEnterpriseValue]);

	const {
		success: checkedEnterpriseSuccess,
		loading: checkedEnterpriseLoading,
		error: checkedEnterpriseError,
	} = checkedEnterpriseField;

	useEffect(() => {
		if (!checkedEnterpriseLoading && !isObject(checkedEnterpriseError)) {
			setIsUniqueVatNumber(true);
		} else if (!checkedEnterpriseLoading) {
			if (checkedEnterpriseError.key === 'DuplicateEnterpriseNumber') {
				setIsUniqueEnterpriseNumber(false);
			}
		}
	}, [checkedEnterpriseSuccess, checkedEnterpriseLoading, checkedEnterpriseError]);

	const cocNumber = useWizardFormField(chamberOfCommerceNumber || '', {
		minLength: 8,
		maxLength: 16,
		isNumeric: true,
	});
	const [isUniqueCocNumber, setIsUniqueCocNumber] = useState(true);
	useDebouncedWizardSave(
		'cocNumber',
		cocNumber.value,
		cocNumber.isValid && isUniqueCocNumber,
		save,
		300,
	);

	const debouncedCocValue = useDebounce(cocNumber.value, 300);

	useEffect(() => {
		if (!isEmptyString(debouncedCocValue)) {
			onValidateOrganisationCOCField(debouncedCocValue);
		}
	}, [debouncedCocValue]);

	const {
		success: checkedCOCSuccess,
		loading: checkedCOCLoading,
		error: checkedCOCError,
	} = checkedCOCField;

	useEffect(() => {
		if (!checkedCOCLoading && !isObject(checkedCOCError)) {
			setIsUniqueCocNumber(true);
		} else if (!checkedCOCLoading) {
			if (checkedCOCError.key === 'DuplicateChamberOfCommerceNumber') {
				setIsUniqueCocNumber(false);
			}
		}
	}, [checkedCOCSuccess, checkedCOCLoading, checkedCOCError]);

	const organisationIbanNumber = useWizardFormField(ibanNumber || '', {
		minLength: 10,
		maxLength: 32,
	});
	const [isUniqueIbanNumber, setIsUniqueIbanNumber] = useState(true);
	useDebouncedWizardSave(
		'organisationIbanNumber',
		organisationIbanNumber.value,
		organisationIbanNumber.isValid && isUniqueIbanNumber,
		save,
		300,
	);

	const debouncedIbanValue = useDebounce(organisationIbanNumber.value, 300);

	useEffect(() => {
		if (!isEmptyString(debouncedIbanValue)) {
			onValidateOrganisationIBANField(debouncedIbanValue);
		}
	}, [debouncedIbanValue]);

	const {
		success: checkedIBANSuccess,
		loading: checkedIBANLoading,
		error: checkedIBANError,
	} = checkedIBANField;

	useEffect(() => {
		if (!checkedIBANLoading && !isObject(checkedIBANError)) {
			setIsUniqueIbanNumber(true);
		} else if (!checkedIBANLoading) {
			if (checkedIBANError.key === 'DuplicateIban') {
				setIsUniqueIbanNumber(false);
			}
		}
	}, [checkedIBANSuccess, checkedIBANLoading, checkedIBANError]);

	const billingInfoChecksReady =
		(isFullString(organisationVatNumber.value) ?
			checkedVATSuccess && !checkedVATLoading && !checkedVATError
		:	true) &&
		(isFullString(cocNumber.value) ?
			checkedCOCSuccess && !checkedCOCLoading && !checkedCOCError
		:	true) &&
		(isFullString(organisationIbanNumber.value) ?
			checkedIBANSuccess && !checkedIBANLoading && !checkedIBANLoading
		:	true);

	useEffect(() => {
		save({ billingInfoChecksReady });
	}, [billingInfoChecksReady]);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader
				subheader={t('views.register.organisation.billingAndAdministration.subheader')}
				title={t('views.register.organisation.billingAndAdministration.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					<div className={classes.formGroup}>
						<FormField
							extraValidHelperText={t(
								'views.register.organisation.billingAndAdministration.error.vatAlreadyInUse',
							)}
							hideCharacterCounter={!organisationVatNumber.hasFocus}
							isExtraValid={isUniqueVatNumber}
							label={t('ui.label.vatNumber')}
							maxLength={16}
							name='vatNumber'
							placeholder={t('views.register.organisation.placeholder.vatNumber')}
							variable={organisationVatNumber}
						/>
					</div>
					{showEnterpriseNumber ?
						<div className={classes.formGroup}>
							<FormField
								extraValidHelperText={t(
									'views.register.organisation.billingAndAdministration.error.enterpriseNumberAlreadyInUse',
								)}
								hideCharacterCounter={!enterpriseNumber.hasFocus}
								isExtraValid={isUniqueEnterpriseNumber}
								label={t('ui.label.enterpriseNumber')}
								maxLength={12}
								name='enterpriseNumber'
								placeholder={t('views.register.organisation.placeholder.enterpriseNumber')}
								variable={enterpriseNumber}
							/>
						</div>
					:	null}
					<div className={classes.formGroup}>
						<FormField
							extraValidHelperText={t(
								'views.register.organisation.billingAndAdministration.error.cocAlreadyInUse',
							)}
							hideCharacterCounter={!cocNumber.hasFocus}
							isExtraValid={isUniqueCocNumber}
							label={t('ui.label.cocNumber')}
							maxLength={16}
							name='cocNumber'
							placeholder={t('views.register.organisation.placeholder.cocNumber')}
							variable={cocNumber}
						/>
					</div>
					<div className={classes.formGroup}>
						<FormField
							extraValidHelperText={t(
								'views.register.organisation.billingAndAdministration.error.ibanAlreadyInUse',
							)}
							hideCharacterCounter={!organisationIbanNumber.hasFocus}
							isExtraValid={isUniqueIbanNumber}
							label={t('ui.label.ibanNumber')}
							maxLength={32}
							name='ibanNumber'
							placeholder={t('views.register.organisation.placeholder.ibanNumber')}
							variable={organisationIbanNumber}
						/>
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

BillingInfo.propTypes = {
	className: PropTypes.string,
	save: PropTypes.func,
	country: PropTypes.string,
	vatNumber: PropTypes.string,
	chamberOfCommerceNumber: PropTypes.string,
	enterpriseNumber: PropTypes.string,
	ibanNumber: PropTypes.string,
	checkedVATField: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	checkedCOCField: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	checkedIBANField: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	checkedEnterpriseField: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	onValidateOrganisationVATField: PropTypes.func.isRequired,
	onValidateOrganisationCOCField: PropTypes.func.isRequired,
	onValidateOrganisationIBANField: PropTypes.func.isRequired,
	onValidateOrganisationEnterpriseField: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		checkedVATField: state.condition.checkedOrganisationVATField,
		checkedCOCField: state.condition.checkedOrganisationCOCField,
		checkedIBANField: state.condition.checkedOrganisationIBANField,
		checkedEnterpriseField: state.condition.checkedOrganisationEnterpriseField,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onValidateOrganisationVATField: (value) =>
			dispatch(actions.validateOrganisationVATField(value)),
		onValidateOrganisationCOCField: (value) =>
			dispatch(actions.validateOrganisationCOCField(value)),
		onValidateOrganisationIBANField: (value) =>
			dispatch(actions.validateOrganisationIBANField(value)),
		onValidateOrganisationEnterpriseField: (value) =>
			dispatch(actions.validateOrganisationEnterpriseField(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
