import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

const GeneralInfo = (props) => {
	const { policy, className } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.policyDetail.summary.cardHeaders.general')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.name')}</TableCell>
							<TableCell>{policy.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.organisation')}</TableCell>
							<TableCell>{policy.organisationReference.name}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

GeneralInfo.propTypes = {
	className: PropTypes.string,
	policy: PropTypes.object.isRequired,
};

export default GeneralInfo;
