import { Person as PersonIcon } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { UsersService } from '~features/users';

import LoadingPage from '../feedbacks/loadingPage';
import Page from '../page';

const service = new UsersService();

const LoginCallbackPage = () => {
	const { t } = useTranslation('general');

	// This may seem weird, because the atom is never used. But this atom
	// initializes on the first call (aka get its data from the server).
	// We prefer this to do this rather sooner than later.
	// Determine if this is necessary in any way. Normally the login callback
	// is in part meant to retrieve user information
	// const [_] = useAtom(userInfoAtom);

	return (
		<Page title={t('loggingIn')}>
			<LoadingPage
				title={t('views.callback.title')}
				description={t('views.callback.subtitle')}
				icon={PersonIcon}
			/>
		</Page>
	);
};

export default LoginCallbackPage;
