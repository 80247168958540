import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(5, 10),
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: theme.spacing(70),
	},
	icon: {
		marginBottom: theme.spacing(2),
	},
	divider: {
		width: '100%',
		margin: theme.spacing(6, 0),
	},
	title: {
		textAlign: 'center',
	},
	paragraph: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
	},
	contact: {
		marginTop: theme.spacing(6),
		minWidth: theme.spacing(53),
		display: 'flex',
		justifyContent: 'space-between',
	},
	button: {
		minWidth: theme.spacing(25),
	},
}));
