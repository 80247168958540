import { useState, useReducer, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import UploadIconArrow from '@mui/icons-material/ForwardSharp';
import UploadIconBase from '@mui/icons-material/MaximizeSharp';
import { IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FileReaderInput from 'react-file-reader-input';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import {
	isUndefined,
	isInteger,
	isFunction,
	isEmptyArray,
	isFullString,
} from '../../../shared/utility';

const handleImageReducer = (state, action) => {
	switch (action.type) {
		case 'add': {
			return state.concat(action.image);
		}
		case 'delete': {
			return state.filter((_, index) => index !== action.index);
		}
		default: {
			return state;
		}
	}
};

const ImageDropzone = (props) => {
	const {
		className,
		maxImageSize,
		maxImages,
		dropzoneSize,
		imageRatio,
		callback,
		imageText,
		setFileName,
		cropImage,
		imageType,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [images, dispatchImages] = useReducer(handleImageReducer, []);
	const [sizeWarning, setSizeWarning] = useState(false);

	useEffect(() => {
		if (isFunction(callback)) {
			callback(images);
		}
	}, [images]);

	useEffect(() => {
		if (sizeWarning) {
			setTimeout(() => {
				setSizeWarning(false);
			}, 2500);
		}
	}, [sizeWarning]);

	useEffect(() => {
		const { images: savedImages } = props;
		if (savedImages && !isEmptyArray(savedImages) && savedImages.length !== images.length) {
			savedImages.forEach((savedImage) => {
				dispatchImages({ type: 'add', image: savedImage });
			});
		}
	}, [props, cropImage]);

	return (
		<div
			className={clsx({
				[classes.root]: true,
				[className]: className,
				['full-width']: dropzoneSize === 'full-width',
			})}
		>
			<div className={classes.upload}>
				{new Array(Math.min(Math.max(maxImages || 1, 1), images.length + 1))
					.fill()
					.map((_, index) => (
						<div
							className={clsx({
								[classes.uploadWrapper]: true,
								['small']: dropzoneSize === 'small',
								['full-width']: dropzoneSize === 'full-width',
							})}
							key={index}
						>
							{isUndefined(images[index]) ?
								<FileReaderInput
									accept={isFullString(imageType) ? imageType : 'image/jpeg, image/png'}
									className={classes.uploadInput}
									multiple={false}
									onChange={(ev, results) => {
										setLoading(false);
										const [progressEvent, file] = results[0];
										setFileName(file.name);
										if (isInteger(maxImageSize) && progressEvent.total / 1000 > maxImageSize) {
											setSizeWarning(true);
											return;
										}
										dispatchImages({
											type: 'add',
											image: { uri: progressEvent.target.result, name: file.name },
										});
									}}
									onInput={() => {
										setLoading(true);
									}}
								>
									<label
										className={clsx({
											[classes.uploadButton]: true,
											[classes.uploadButtonWarning]: sizeWarning,
											['full-width']: dropzoneSize === 'full-width',
											['ratio11']: dropzoneSize === 'small' && imageRatio === '1:1',
											['ratio32']: dropzoneSize === 'small' && imageRatio === '3:2',
											['ratio169']: dropzoneSize === 'small' && imageRatio === '16:9',
										})}
									>
										<UploadIconArrow className={classes.iconArrow} fontSize='large' />
										<UploadIconBase className={classes.iconBase} fontSize='large' />
										<span className={classes.uploadButtonLabel}>
											{loading ?
												<span>Loading...</span>
											:	<>
													<Typography gutterBottom variant='h5'>
														{!imageText ? t('ui.uploadImage') : imageText}
													</Typography>
													{isInteger(maxImageSize) ?
														<Typography
															className={clsx({
																[classes.errorStyle]: sizeWarning,
															})}
															variant='body2'
														>
															{`${t('ui.maxFileSize')}: `}
															{maxImageSize >= 1024 ?
																`${(maxImageSize / 1024).toFixed(1)}MB`
															:	`${maxImageSize}KB`}
														</Typography>
													:	null}
												</>
											}
										</span>
									</label>
								</FileReaderInput>
							:	<div
									className={clsx({
										[classes.uploadButton]: true,
										[classes.uploadButtonPreview]: true,
										['full-width']: dropzoneSize === 'full-width',
										['ratio11']: dropzoneSize === 'small' && imageRatio === '1:1',
										['ratio32']: dropzoneSize === 'small' && imageRatio === '3:2',
										['ratio169']: dropzoneSize === 'small' && imageRatio === '16:9',
									})}
								>
									<img
										alt={''}
										className={classes.uploadPreview}
										src={
											!isUndefined(cropImage) && !isEmptyArray(cropImage) ?
												cropImage[index].uri
											:	images[index].uri
										}
									/>
									<IconButton
										className={classes.uploadPreviewRemoveButton}
										onClick={() => {
											dispatchImages({ type: 'delete', index });
										}}
										size='large'
									>
										<CloseIcon />
									</IconButton>
								</div>
							}
						</div>
					))}
			</div>
		</div>
	);
};

ImageDropzone.propTypes = {
	className: PropTypes.string,
	maxImages: PropTypes.number,
	maxImageSize: PropTypes.number,
	dropzoneSize: PropTypes.oneOf(['full-width', 'small']),
	imageRatio: PropTypes.oneOf(['1:1', '3:2', '16:9']),
	callback: PropTypes.func.isRequired,
	images: PropTypes.array,

	imageText: PropTypes.string,
	setFileName: PropTypes.func,
	cropImage: PropTypes.array,
	imageType: PropTypes.any,
};

ImageDropzone.defaultProps = {
	imageRatio: '3:2',
	dropzoneSize: 'small',
	maxImages: 1,
	maxImageSize: 4096,
	images: [],
};

export default ImageDropzone;
