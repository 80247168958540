import { useContext, useState, createContext } from 'react';

import { Wrapper } from '@googlemaps/react-wrapper';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import TripMap from './TripMap';
import { Table, Label } from '../../../../../../components';
import { commaTimeStrings } from '../../../../../../shared/datetime';
import { isArray, isDefined, isObject, isUndefined } from '../../../../../../shared/utility';

const mainContext = createContext();

export function useTripContext() {
	return useContext(mainContext);
}

const render = (status) => {
	return <h1>{status}</h1>;
};

const Summary = (props) => {
	const { data, loading, coordinates, coordinatesLoading, tab } = props;
	const { t } = useTranslation('general');

	const [zoom] = useState(5);

	const [center] = useState({ lat: 52.00717, lng: 4.3673422 });

	const value = { zoom, center };

	const classes = useStyles();

	const handleEngine = (engine) => (
		<Label type={engine ? 'success' : 'error'}>{engine ? t('ui.enabled') : t('ui.disabled')}</Label>
	);

	const handleCo2Trip = (co2) => {
		if (co2 > 0) {
			// Convert grams to kilograms and round to 2 decimal places
			return `${(Math.round(co2) / 1000).toFixed(2)}kg`;
		} else if (co2 === 0) {
			// If CO2 is exactly 0
			return '0 kg';
		} else {
			// If CO2 is less than 0 or not a valid number
			return '';
		}
	};

	const tableHeader = [
		{ name: 'id', content: 'ID', hasSorting: true },
		{ name: 'startTime', content: t('ui.label.startTime') },
		{ name: 'endTime', content: t('ui.label.endTime') },
		{ name: 'locationStart', content: t('ui.label.locationStart') },
		{ name: 'locationEnd', content: t('ui.label.locationEnd') },
		{ name: 'carDistance', content: t('ui.label.carDistance') },
		{ name: 'privateDistance', content: t('ui.label.privateDistance') },
		{ name: 'co2', content: t('ui.header.co2') },
		{ name: 'maxSpeed', content: t('ui.label.maxSpeed') },
		{ name: 'avgSpeed', content: t('ui.label.avgSpeed') },
		{ name: 'instanceName', content: t('views.ItemDetail.activities.instance') },
		{ name: 'userName', content: t('ui.label.user'), hasSorting: true },
		{ name: 'booking', content: t('ui.label.booking') },
		{ name: 'usage', content: t('views.planboard.addBooking.usage.sectionTitle') },
		// { name: 'score', content: t('ui.label.score'), hasSorting: false },
	];

	const tableBody =
		isObject(data) ?
			[
				[
					{ content: data?.sequenceNumber },
					{ content: data?.start && commaTimeStrings(data.start) },
					{ content: data?.end && commaTimeStrings(data.end) },
					{ content: data?.startAddress },
					{ content: data?.endAddress },
					{ content: isDefined(data.distance) && `${data.distance}km` },
					{ content: data?.personalDistance && `${data.personalDistance}km` },
					// Round up or down to 2 decimals
					{ content: handleCo2Trip(data?.co2) },
					{ content: isDefined(data.maxSpeed) && `${data.maxSpeed}km/h` },
					{ content: isDefined(data.avgSpeed) && `${data.avgSpeed}km/h` },
					{ content: data.itemInstanceName },
					{ content: data?.userFullName },
					{ content: data?.bookingId },
					{ content: data?.usageType && t(`ui.label.${data.usageType}.${data.isMixed}`) },
				],
			]
		:	Array(2)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const coordinatesTableHeader = [
		{ name: 'lastUpdated', content: t('views.devices.heartbeatsList.lastUpdated') },
		{ name: 'GPS', content: 'GPS' },
		{ name: 'numberOfSatellites', content: t('ui.label.numberOfSatellites') },
		{ name: 'mileage', content: t('ui.label.mileage') },
		{ name: 'speed', content: t('ui.label.speed') },
		{ name: 'engineState', content: t('views.devices.trip.table.engineState') },
	];

	const coordinatesBody =
		isArray(coordinates) ?
			coordinates.map((coordinat) => [
				{ content: coordinat?.timestamp && commaTimeStrings(coordinat.timestamp) },
				{ content: `${coordinat.latitude}, ${coordinat.longitude}` },
				{ content: coordinat.numberOfSatellites },
				{ content: coordinat?.mileage },
				{ content: isDefined(coordinat.speed) && `${coordinat.speed}km/h` },
				{ content: !isUndefined(coordinat.isEngineOn) ? handleEngine(coordinat.isEngineOn) : '-' },
			])
		:	Array(2)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const mapTripProps = {
		data:
			coordinates &&
			coordinates
				.map((coordinate) => ({
					...coordinate,
					lat: coordinate.latitude,
					lng: coordinate.longitude,
				}))
				.reverse(),
		maxZoom: 5,
		tripData: data,
	};

	return (
		<div className={classes.root}>
			<Table
				body={tableBody}
				cellStyle={classes.cellStyle}
				header={tableHeader}
				isNotPaginate={true}
				loading={loading}
				removeMrgin={true}
				title={t('views.devices.trip.table.title.tripInfo')}
				withoutPages={true}
			/>
			{tab === 'summary' && (
				<Box mt={4}>
					<Table
						body={coordinatesBody}
						cellStyle={classes.cellStyle}
						header={coordinatesTableHeader}
						isNotPaginate={true}
						loading={coordinatesLoading}
						title={t('views.devices.trip.table.title.tripDetails')}
						withoutPages={true}
					/>
				</Box>
			)}
			{tab === 'map' && (
				<mainContext.Provider value={value}>
					<Box height='100%' mt={4} width='100%'>
						<Wrapper apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY} render={render}>
							<TripMap {...mapTripProps} />
						</Wrapper>
					</Box>
				</mainContext.Provider>
			)}
		</div>
	);
};

Summary.propTypes = {
	data: PropTypes.object,

	loading: PropTypes.bool,
	coordinates: PropTypes.array,
	coordinatesLoading: PropTypes.bool,
	tab: PropTypes.string,
};

export default Summary;
