import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1.5),
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(1.5),
		},
	},
	content: {
		padding: theme.spacing(1.5, 1, 1.5, 1.5),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	label: {
		paddingLeft: theme.spacing(1),
	},
	iconButton: {
		padding: 0,
	},
}));
