import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		maxWidth: '60rem',
	},
	formGroup: {
		marginBottom: theme.spacing(5),
	},
}));
