import { useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';

import { UserRoleEnum } from '~enums';

/**
 * A hook to easily determine if authorized or not within Topology
 * @returns
 */
const useAuthorize = () => {
	const auth = useAuth();

	const isSuperAdmin = () => {
		return (auth.user?.profile.role.toLowerCase() as UserRoleEnum) === UserRoleEnum.SuperAdmin;
	};

	/**
	 * 
	 * @param exclusive A boolean to set if the user must be exactly an admin
	 * If false, it will check an admin or higher
	 * @returns 
	 */
	const isAdmin = (exclusive = false) => {
		const role = auth.user?.profile.role.toLowerCase() as UserRoleEnum;
		if (exclusive) {
			return role === UserRoleEnum.Admin;
		}

		return [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin].includes(role);
	};

	/**
	 *
	 * @param roles The roles that have access. Based on a hierarchie
	 * SuperAdmin > Admin > User
	 * @param exclusive TODO: is this needed, maybe handy if we want to give authorization
	 * to an admin but a superadmin
	 */
	const authorize = (roles: UserRoleEnum[], exclusive = false) => {
		const role = auth.user?.profile.role.toLowerCase() as UserRoleEnum;
		if (role === UserRoleEnum.SuperAdmin || role === UserRoleEnum.DevAdmin) {
			// Superadmin can do everything
			return true;
		} else if (roles.includes(UserRoleEnum.User) && role === UserRoleEnum.Admin) {
			return true;
		}

		return roles.includes(role);
	};

	return {
		isAdmin: isAdmin,
		isSuperAdmin: isSuperAdmin,
		authorize: authorize,
	};
};

export default useAuthorize;
