import { Box, Divider, Typography, Collapse, Card, CardContent, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';

import { SwitchButton } from '../../../../../../components';
import { useStyles } from '../style';

const PoliciesToggle = (props) => {
	const classes = useStyles();

	const { onClick, overrideAll, onChange, override, sharings, type } = props;
	const { t } = useTranslation('general');

	return (
		<Box>
			<Box sx={{ marginBottom: '0px' }}>
				<Divider />
				<Box display='flex' sx={{ padding: '16px 0' }}>
					<Box display='flex' flexDirection='column'>
						<Box alignItems='center' display='flex'>
							<Typography sx={{ marginRight: 1 }} variant='h6'>
								{t(`views.itemDetail.summary.dialog.switch.title.${type}`)}
							</Typography>
							<Link color='primary' component={RouterLink} to={'/item-management/management'}>
								{
									<Typography variant='body2'>{`${sharings.equal} ${t('views.itemDetail.summary.dialog.switch.label.sharings.affected')}`}</Typography>
								}
							</Link>
						</Box>
						<Typography sx={{ flexBasis: '100%' }} variant='body2'>
							{t(`views.itemDetail.summary.dialog.switch.description.${type}`)}
						</Typography>
					</Box>
					<SwitchButton color='primary' onClick={onClick} />
				</Box>
				{overrideAll ? null : <Divider />}
			</Box>
			<Collapse className={classes.collapse} easing='easing' in={overrideAll}>
				<Card className={classes.licenseVerification} variant='outlined'>
					<CardContent>
						<Box display='flex'>
							<Box display='flex' flexDirection='column'>
								<Box alignItems='center' display='flex'>
									<Typography sx={{ marginRight: 1 }} variant='h6'>
										{t('views.itemDetail.summary.dialog.overrideSwitch.title.override')}
									</Typography>
									<Link color='primary' component={RouterLink} to={'/item-management/management'}>
										<Typography variant='body2'>{`${sharings.total} ${t('views.itemDetail.summary.dialog.switch.label.sharings.affected')}`}</Typography>
									</Link>
								</Box>
								<Typography sx={{ flexBasis: '100%' }} variant='body2'>
									{t(`views.itemDetail.summary.dialog.overrideSwitch.title.${type}`)}
								</Typography>
							</Box>
							<SwitchButton checked={override} color='primary' onChange={onChange} />
						</Box>
					</CardContent>
				</Card>
			</Collapse>
			<Typography variant='body2'>
				{t(`views.itemDetail.summary.dialog.description.${type}`)}
			</Typography>
		</Box>
	);
};

PoliciesToggle.propTypes = {
	switchDescription: PropTypes.string,
	dialogDescription: PropTypes.string,
	hide: PropTypes.bool,
	overrideAll: PropTypes.bool,
	onChange: PropTypes.func,
	override: PropTypes.bool,
	sharings: PropTypes.object,
	type: PropTypes.string,
	onClick: PropTypes.func,
};

export default PoliciesToggle;
