import { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Link, Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import {
	SearchBar,
	EmptyState,
	Table,
	GenericMoreButton,
	AlertDialog,
	InfoDialog,
} from '../../../components';
import { useSearchComponent, usePagination, useComplexFilter } from '../../../shared/hooks';
import {
	isFullString,
	isEmptyArray,
	isObject,
	isEmptyObject,
	isSuperAdmin,
	isInteger,
	isEmptyString,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

const UserGroups = (props) => {
	const {
		className,
		userGroupsObject,
		onFetchUserGroups,
		onFetchOrganisations,
		organisationsList,
		onDeleteUserGroup,
		deletedUserGroup,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	const {
		data: userGroupsData,
		loading: userGroupsLoading,
		error: userGroupsError,
	} = userGroupsObject;
	const userGroupsReady = isObject(userGroupsData) && !userGroupsLoading && !userGroupsError;

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [shouldSearch, setShouldSearch] = useState(false);

	const [shouldSort, setShouldSort] = useState(false);
	const [shouldFilter, setShouldFilter] = useState(false);

	// Dialog
	const [openDialog, setOpenDialog] = useState(false);
	const [infoDialogOpen, setInfoDialogOpen] = useState(false);

	const [userGroupToDelete, setUserGroupToDelete] = useState(null);
	const [deletingUserGroup, setDeletingUserGroup] = useState(false);

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deletedUserGroup;
	const deleteUserGroupDone = deleteSuccess && !deleteLoading && !deleteError;

	const pagination = usePagination('pageNumberUserGroups', 'pageSizeUserGroups');

	const userGroupsOrganisationFilter = useComplexFilter(
		'userGroupsOrganisationNameFilter',
		'userGroupsOrganisationIdFilter',
	);

	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch, 'userGroupSearch');

	const filters = {
		...(isFullString(search.value) && { searchTerm: search.value }),
		...(userGroupsOrganisationFilter.valueId !== 'all' && {
			organisationId: userGroupsOrganisationFilter.valueId,
		}),
		sortBy: 'id',
		orderDescending: true,
	};

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		if (shouldDoInitialFetch || shouldSearch || shouldSort || shouldFilter || pagination.fetch) {
			if ((shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchUserGroups(pagination.page, filters);
		}

		if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [shouldDoInitialFetch, shouldSearch, shouldSort, pagination.fetch, shouldFilter]);

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const userGroupsListEmpty =
		isObject(userGroupsData) && !userGroupsLoading && isEmptyArray(userGroupsData.results) ?
			showingInitialResults ?
				<EmptyState
					callToAction={'/user-management/user-groups/add'}
					callToActionText={t('view.usermanagement.usergroups.button.contained.createusergroup')}
					image={'booking'}
					subTitle={t('views.users.userGroups.empty.description.zeroInSystem')}
					title={t('views.users.userGroups.empty.title')}
				/>
			:	<EmptyState
					callToAction={'/user-management/user-groups/add'}
					callToActionText={t('view.usermanagement.usergroups.button.contained.createusergroup')}
					image={'booking'}
					subTitle={t('views.users.userGroups.empty.description.zeroMatching')}
					title={t('views.users.userGroups.empty.title')}
				/>
		:	null;

	/* * * * * *
	 * FILTER  *
	 * * * * * */
	const handleChangeOrganisationFilterSelect = (item) => {
		if (item.id === userGroupsOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			userGroupsOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			userGroupsOrganisationFilter.setValueName('');
		}
		userGroupsOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const mainFilters = [
		...(isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleChangeOrganisationFilterSelect,
						searchHandle: userGroupsOrganisationFilter.setValueName,
					},
					value: userGroupsOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: 'All organisations',
					},
				},
			]
		:	[]),
	];

	/* * * * * * * * *
	 * NOTIFICATIONS *
	 * * * * * * * * */
	useEffect(() => {
		if (deletingUserGroup && deleteUserGroupDone) {
			setDeletingUserGroup(false);
			enqueueSnackbar(
				`${t('views.userGroupDetail.messages.success.delete')} ${userGroupToDelete.name}`,
			);
			setOpenDialog(false);
			pagination.setPageNumber(1);
			setShouldDoInitialFetch(true);
		}
	}, [deleteUserGroupDone, deletingUserGroup]);

	useEffect(() => {
		if (deletingUserGroup && !!deleteError) {
			setDeletingUserGroup(false);
			setOpenDialog(false);
			enqueueSnackbar(isObject(deleteError) ? deleteError.message : deleteError, {
				variant: 'error',
			});
		}
	}, [deleteError, deletingUserGroup]);

	const handleClickDelete = (userGroup) => {
		if (userGroup.isDefault) {
			setInfoDialogOpen(true);
		} else {
			setUserGroupToDelete(userGroup);
			setOpenDialog(true);
		}
	};

	const createMenuItems = (userGroup) => [
		{
			icon: <DeleteIcon />,
			text: t('ui.button.inline.delete'),
			action: () => handleClickDelete(userGroup),
			isRed: true,
		},
	];

	const extraButtons = [
		{
			text: t('view.usermanagement.usergroups.button.contained.createusergroup'),
			onClick: () => navigate('/user-management/user-groups/add'),
			variant: 'contained-primary',
		},
	];

	const handleCancelDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = () => {
		setDeletingUserGroup(true);
		onDeleteUserGroup(userGroupToDelete.id);
	};

	const handleCloseInfoDialog = () => {
		setInfoDialogOpen(false);
	};

	const handleClearFilters = () => {
		userGroupsOrganisationFilter.setValueName('');
		userGroupsOrganisationFilter.setValueId('all');
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			userGroupsOrganisationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	/* * * * * * * * *
	 * TABLE CONTENT *
	 * * * * * * * * */
	const tableHeader = [
		{ name: 'name', content: t('ui.label.name') },
		{ name: 'users', content: t('ui.label.users') },
		{ name: 'itemGroup', content: t('views.userManagement.userGroup.details.label.itemGroup') },
		{ name: 'items', content: t('ui.category.items') },
		...(isSuperAdmin(auth.user?.profile.role) ?
			[{ name: 'organisation', content: t('ui.label.organisation') }]
		:	[]),
		{ name: 'actions' },
	];

	const tableBody =
		userGroupsReady ?
			userGroupsData.results.map((item) => [
				{
					content: (
						<div>
							<Link
								className={classes.itemGroup}
								color='inherit'
								component={RouterLink}
								to={`/user-management/user-groups/${item.id}/summary`}
								state={{ from: location.pathname }}
							>
								{item.name}
							</Link>
						</div>
					),
				},
				{ content: item.totalUsers },
				{ content: item.totalItems },
				{ content: item.totalItemInstances },
				...(isSuperAdmin(auth.user?.profile.role) ?
					[
						{
							content: (
								<Link
									color='inherit'
									component={RouterLink}
									to={`/organisations/${item.organisationReference.id}/summary`}
									state={{ from: location.pathname }}
								>
									{item.organisationReference.name}
								</Link>
							),
						},
					]
				:	[]),
				{
					content: (
						<Box display='flex' flexDirection='row-reverse'>
							{' '}
							<GenericMoreButton menuItems={createMenuItems(item)} />
						</Box>
					),
				},
			])
		:	Array(4)
				.fill(Array(tableHeader?.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	return (
		<div className={className}>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons
				hasMainFilter={isSuperAdmin(auth.user?.profile.role)} //should only be true if user is super admin
				mainFilters={mainFilters}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{userGroupsListEmpty ?
				userGroupsListEmpty
			:	<Table
					body={tableBody}
					handlePageChange={pagination.pageNumberChange}
					header={tableHeader}
					loading={userGroupsLoading}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('ui.category.userGroups')}
					total={userGroupsData ? userGroupsData.total : 0}
				/>
			}
			<AlertDialog
				dialogDescription={
					(
						isObject(userGroupToDelete) &&
						!isEmptyObject(userGroupToDelete) &&
						userGroupToDelete.totalUsers > 0
					) ?
						t('views.userGroupDetail.summary.deleteDialog.description.hasUsers')
					:	t('views.userGroupDetail.summary.deleteDialog.description.hasNoUsers')
				}
				dialogTitle={t('views.userGroupDetail.summary.deleteDialog.title')}
				handleClose={handleCancelDialog}
				handleConfirm={handleConfirmDialog}
				loading={deleteLoading && !deleteUserGroupDone}
				open={openDialog}
			/>
			<InfoDialog
				dialogTitle={t('views.users.userGroups.deleteNotPossibleInfo.title')}
				onClose={handleCloseInfoDialog}
				open={infoDialogOpen}
			>
				<Typography>{t('views.users.userGroups.deleteNotPossibleInfo.description')}</Typography>
			</InfoDialog>
		</div>
	);
};

UserGroups.propTypes = {
	className: PropTypes.string,

	onSearchFilterSortUseGroups: PropTypes.func,
	userGroupsObject: PropTypes.object,
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	deletedUserGroup: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	onFetchUserGroups: PropTypes.func,
	onFetchOrganisations: PropTypes.func,
	onDeleteUserGroup: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		userGroupsObject: state.paged.userGroups,
		organisationsList: state.paged.organisations,
		deletedUserGroup: state.condition.deletedUserGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserGroups: (page, filters) => dispatch(actions.fetchUserGroups(page, filters)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onDeleteUserGroup: (id) => dispatch(actions.deleteUserGroup(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
