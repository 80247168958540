import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	buttonCard: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(1.5),
	},
	drawerForm: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100vh',
	},
	card: {
		display: 'flex',
		flexDirection: 'row',
	},
	closeButton: {
		marginLeft: 'auto',
		alignSelf: 'flex-start',
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));
