import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { TextField, TransferList } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { Device, SkcDevicesService } from '~features/devices';
import { OrganisationAutocomplete } from '~features/organisations';

import schema from './skcDeviceGroupSchema.json';
import SkcDeviceGroupsService from '../../services/skcDeviceGroupsService';

const deviceGroupsService = new SkcDeviceGroupsService();
const devicesService = new SkcDevicesService();

interface SkcDeviceGroupFormProps {
	id?: string;
}

const SkcDeviceGroupForm = ({ id }: SkcDeviceGroupFormProps) => {
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const [totalLeftCount, setTotalLeftCount] = useState<number>(0);
	const [rightPage, setRightPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const [availableLeftList, setAvailableLeftList] = useState([]);

	const {
		data: deviceGroupData,
		isLoading: isDeviceGroupLoading,
		error: deviceGroupError,
	} = useSWRImmutable(
		id ? path.join(deviceGroupsService.basePath, id) : null,
		() => deviceGroupsService.getDeviceGroupById(id!),
		{
			revalidateOnMount: true,
		},
	);

	const { handlers, setDisabled } = useFormContainerState();
	const { getValues, control, formState, reset, watch } = useForm({
		defaultValues: useMemo(
			() => deviceGroupData ?? { organisation: isSuperAdmin() ? undefined : userInfo.organisation },
			[deviceGroupData],
		),
		mode: 'onChange',
		resolver: ajvResolver(schema),
	});

	const watchOrganisation = watch('organisation');

	const [devicesPagination, setDevicePagination] = useState({
		page: 1,
		pageSize: rowsPerPage,
	});
	const fetchParameters = useMemo(
		() => ({
			...devicesPagination,
			organisationId: watchOrganisation?.id,
		}),
		[watchOrganisation, devicesPagination],
	);
	const {
		data: devicesData,
		isLoading: isDevicesLoading,
		isValidating: isDevicesValidating,
		error: devicesError,
	} = useSWR(
		[devicesService.basePath, fetchParameters],
		([_, args]) => devicesService.getDevices(args),
		{
			onSuccess: (res) => setTotalLeftCount(res.total),
		},
	);

	useEffect(() => {
		if (deviceGroupData) {
			// Used to reset the useform, otherwise the page won't properly reload
			reset(deviceGroupData);
		}
	}, [deviceGroupData]);

	useEffect(() => {
		if (!handlers) {
			return;
		}

		handlers.onSubmit = () => getValues();
	}, [handlers]);

	useEffect(() => {
		console.log(formState.isDirty, formState.dirtyFields);
		if (setDisabled) {
			setDisabled(!(formState.isValid && formState.isDirty));
		}
	}, [formState.isValid, setDisabled, formState.isDirty]);

	useEffect(() => {
		if (!devicesData) {
			setAvailableLeftList([]);
			return;
		}

		determineAvailableLeftItems();
	}, [devicesData]);

	const mapToTransferListItem = (element: Device) => ({
		key: element.id,
		primary: element.skopeiNumber,
		secondary: `${element.id} - ${element.hardwareId}`,
		value: element,
	});

	const handleRowsPerPageChange = (val) => {
		setRowsPerPage(val);
		setDevicePagination((prev) => ({ ...prev, pageSize: val }));
	};

	const handleTransferListChange = (val, onChange: () => void) => {
		determineAvailableLeftItems();

		const unique: Device[] = [...new Map(val.rightItems.map((el) => [el?.key, el.value])).values()];

		onChange(unique);
	};

	const determineAvailableLeftItems = () => {
		if (!devicesData) {
			return;
		}

		const chosenArray = getValues().devices?.map((el) => el.id) ?? [];
		const mapped = devicesData?.results.map((el) => ({
			...mapToTransferListItem(el),
			disabled: chosenArray.includes(el.id),
		}));
		setAvailableLeftList(mapped);
	};

	const getRightList = (value) => {
		const items = value?.map((el) => mapToTransferListItem(el)) ?? [];
		return items;
	};

	if (id && isDeviceGroupLoading) {
		return <>Loading...</>;
	}

	return (
		<Stack direction='column' spacing={2} my={1}>
			{isSuperAdmin() && (
				<Controller
					name='organisation'
					control={control}
					render={({ field }) => (
						<OrganisationAutocomplete
							value={field.value}
							onChange={(e, newValue) => field.onChange(newValue)}
							required
						/>
					)}
				/>
			)}
			<Controller
				name='label'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.name')}
						required
						slotProps={{
							htmlInput: {
								minLength: schema.properties.label.minLength,
								maxLength: schema.properties.label.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='description'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.description')}
						slotProps={{
							htmlInput: {
								maxLength: schema.properties.description.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='devices'
				control={control}
				render={({ field }) => (
					<TransferList
						leftTitle={t('availableResource', { resource: t('devices') })}
						leftItems={availableLeftList}
						leftItemsCount={totalLeftCount}
						leftLoading={isDevicesLoading || isDevicesValidating}
						leftPage={devicesPagination.page}
						onLeftPageChange={(val) => setDevicePagination((prev) => ({ ...prev, page: val }))}
						rowsPerPage={rowsPerPage}
						rightTitle={t('selectedResource', { resource: t(`devices`) })}
						rightItems={getRightList(field.value)}
						rightLoading={isDeviceGroupLoading}
						rightPage={rightPage}
						onRightPageChange={(val) => setRightPage(val)}
						onChange={(val) => handleTransferListChange(val, field.onChange)}
						onRowsPerPageChange={handleRowsPerPageChange}
					/>
				)}
			/>
		</Stack>
	);
};

export default SkcDeviceGroupForm;
