import { Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';
import { isFullString } from '../../../shared/utility';

const Notification = (props) => {
	const { className, startIcon, message, variant } = props;
	const classes = useStyles();

	return (
		<div
			className={clsx({
				[className]: isFullString(className),
				[classes.root]: true,
				[classes.error]: variant === 'error',
			})}
		>
			{startIcon}
			<Typography
				className={clsx({
					[classes.text]: true,
					[classes.error]: variant === 'error',
				})}
			>
				{message}
			</Typography>
		</div>
	);
};

Notification.propTypes = {
	className: PropTypes.string,
	startIcon: PropTypes.node,
	message: PropTypes.string,
	variant: PropTypes.oneOf(['error']),
};

export default Notification;
