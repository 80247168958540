import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '64px',
		zIndex: 2,
		padding: theme.spacing(2, 2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	button: {
		textTransform: 'none',
	},
	navigateNext: {
		marginLeft: theme.spacing(1),
	},
	search: {
		width: theme.spacing(35),
		height: '44px',
	},
	'@media (min-width: 800px)': {
		root: {
			flexDirection: 'row',
		},
		filters: {
			marginTop: 0,
			marginLeft: theme.spacing(1),
		},
	},
}));
