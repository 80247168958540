import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles, withStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	sidebarButton: {
		marginTop: theme.spacing(3),
	},
	list: {
		padding: 0,
	},
	listItem: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 0,
		paddingRight: 0,
		'& + &': {
			borderTop: `1px solid ${theme.palette.divider}`,
		},
	},
	sidebarEmptyState: {
		marginBottom: theme.spacing(2),
	},
	expansionDetails: {
		padding: theme.spacing(1, 0),
	},
	removeButton: {
		margin: theme.spacing(2, 0),
	},
	emails: {
		display: 'flex',
		flexWrap: 'wrap',
	},
}));

export const AccordionSummary = withStyles({
	root: {
		padding: '0',
		backgroundColor: 'rgba(255, 255, 255, .03)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

export const Accordion = withStyles({
	root: {
		borderBottom: '1px dotted #e4e7eb',
		boxShadow: 'none',
		'&:last-child': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);
