import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
	return {
		root: {},
		popper: {
			zIndex: 99,
			'& .react-datepicker': {
				// border: `1px solid ${theme.palette.primary.main}`,
				...theme.typography.body3,
			},
			'&[data-placement="bottom-start"] .react-datepicker__triangle': {
				borderBottomColor: theme.palette.primary.main,
				'&::after': {
					borderBottomColor: theme.palette.primary.main,
				},
			},
			'& .react-datepicker__header': {
				borderBottom: `1px solid ${theme.palette.primary.main}`,
				backgroundColor: theme.palette.primary.main,
				'&--time': {
					display: 'flex',
					justifyContent: 'center',
					height: 57.91,
				},
			},
			'& .react-datepicker-time__header': {
				color: theme.palette.primary.contrastText,
			},
			'& .react-datepicker__current-month': {
				color: theme.palette.primary.contrastText,
			},
			'& .react-datepicker__navigation--previous': {
				borderRightColor: theme.palette.primary.contrastText,
			},
			'& .react-datepicker__navigation--next': {
				borderLeftColor: theme.palette.primary.contrastText,
			},
			'& .react-datepicker__day-name': {
				color: theme.palette.primary.contrastText,
			},
			'& .react-datepicker__time-container': {
				borderLeft: `1px solid ${theme.palette.primary.main}`,
				'& li.react-datepicker__time-list-item': {
					marginRight: 1,
					'&:focus': {
						outline: 'none',
					},
				},
				'& > .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected':
					{
						backgroundColor: theme.palette.secondary.main,
						color: theme.palette.secondary.contrastText,
						//borderRight: `.1px solid ${theme.palette.primary.main}`
					},
				'& > .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list li.react-datepicker__time-list-item:hover':
					{
						backgroundColor: theme.palette.lightGrey,
						//borderRight: `.1px solid ${theme.palette.primary.main}`
					},
			},
			'& .react-datepicker__day': {
				outline: 'none',
				'&:hover': {
					backgroundColor: theme.palette.lightGrey,
				},
			},
			'& .react-datepicker__day--keyboard-selected': {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
			},
			'& .react-datepicker__day--selected': {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
			},
			'& .react-datepicker__navigation': {
				'&:focus': {
					outline: 'none',
				},
			},
		},
		date: {
			display: 'flex',
			alignItems: 'center',
			'&:hover': {
				cursor: 'pointer',
			},
		},
	};
});
