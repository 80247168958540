// TODO: notice, these are case sensitive!!
// If changed, at the moment of writing (2024-06-13) these
// should be camel cased
enum UserRoleEnum {
	User = 'user',
	Admin = 'admin',
	SuperAdmin = 'superadmin',

	/** This one exists, but do we want to use it */
	DevAdmin = 'devadmin',
}

export default UserRoleEnum;
