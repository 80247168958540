import path from 'path';

import pagePathSegments from './pagePathSegments';

/**
 * Specify the paths here that are "static". A path with a variable in
 * like bookings/:id could be difficult to properly define. Maybe...
 * For now we know there is nothing wrong with defining /auth/login
 */
const pagePaths = {
	AuthLogin: path.join(pagePathSegments.Auth, pagePathSegments.Login),
	AuthLogout: path.join(pagePathSegments.Auth, pagePathSegments.Logout),
	AuthCallback: path.join(pagePathSegments.Auth, pagePathSegments.Callback),
	AuthSilent: path.join(pagePathSegments.Auth, pagePathSegments.Silent),
	AuthUserRedirect: path.join(pagePathSegments.Auth, pagePathSegments.UserRedirect),
	RegisterOrganisation: path.join(pagePathSegments.Register, pagePathSegments.Organisation),
	RegisterStart: path.join(pagePathSegments.Register, pagePathSegments.Start),
	RegisterVerificationStatus: path.join(
		pagePathSegments.Register,
		pagePathSegments.VerificationStatus,
	),
};

export default pagePaths;
