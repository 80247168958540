import { Card, CardHeader, CardContent, MenuItem } from '@mui/material';
import clsx from 'clsx';
import countries from 'i18n-iso-countries';
import isoCountriesDe from 'i18n-iso-countries/langs/de.json';
import isoCountriesEn from 'i18n-iso-countries/langs/en.json';
import isoCountriesNl from 'i18n-iso-countries/langs/nl.json';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { FormField, FormFieldSelect } from '../../../../../components';
import { countryList } from '../../../../../constantsOld';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';

countries.registerLocale(isoCountriesEn);
countries.registerLocale(isoCountriesNl);
countries.registerLocale(isoCountriesDe);

const ContactInfo = (props) => {
	const { className, save, organisationEmail, organisationPhoneNumber, address } = props;
	const { t, i18n } = useTranslation('general');
	const classes = useStyles();

	const street = useWizardFormField(address.street || '', { required: true, maxLength: 128 });
	useDebouncedWizardSave('street', street.value, street.isValid, save, 300);

	const postalCode = useWizardFormField(address.postalCode || '', {
		required: true,
		minLength: 4,
		maxLength: 32,
	});
	useDebouncedWizardSave('postalCode', postalCode.value, postalCode.isValid, save, 300);

	const houseNumber = useWizardFormField(address.number || '', {
		required: true,
		isNumeric: true,
		maxLength: 16,
	});
	useDebouncedWizardSave('houseNumber', houseNumber.value, houseNumber.isValid, save, 300);

	const addressAddition = useWizardFormField(address.numberAddition || '', { maxLength: 16 });
	useDebouncedWizardSave(
		'addressAddition',
		addressAddition.value,
		addressAddition.isValid,
		save,
		300,
	);

	const city = useWizardFormField(address.city || '', { required: true, maxLength: 128 });
	useDebouncedWizardSave('city', city.value, city.isValid, save, 300);

	const country = useWizardFormField(address.country || '', { required: true });
	useDebouncedWizardSave('country', country.value, country.isValid, save, 300);

	const email = useWizardFormField(organisationEmail || '', {
		required: true,
		isEmail: true,
		maxLength: 64,
	});
	useDebouncedWizardSave('companyEmail', email.value, email.isValid, save, 300);

	const phoneNumber = useWizardFormField(organisationPhoneNumber || '', { required: true });
	useDebouncedWizardSave('companyPhoneNumber', phoneNumber.value, phoneNumber.isValid, save, 300);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader
				title={t('views.register.organisation.contactInfo.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					<div className={classes.formGroup}>
						<FormField
							label={t('ui.label.email')}
							name='email'
							placeholder={t('views.register.organisation.placeholder.email')}
							required
							variable={email}
						/>
					</div>
					<div className={classes.formGroup}>
						<FormField
							label={t('ui.label.phoneNumber')}
							name='phoneNumber'
							placeholder={t('views.register.organisation.placeholder.phoneNumber')}
							required
							variable={phoneNumber}
						/>
					</div>
					<div className={classes.formGroup}>
						<FormField
							label={t('ui.label.address')}
							name='street'
							placeholder={t('views.register.organisation.placeholder.street')}
							required
							variable={street}
						/>
					</div>
					<div className={classes.formGroup}>
						<div className={classes.fieldGroup}>
							<FormField
								label={t('ui.label.postalCode')}
								name='postalCode'
								placeholder={t('views.register.organisation.placeholder.postalCode')}
								required
								variable={postalCode}
							/>
							<FormField
								className={classes.fieldGroupItem}
								label={t('ui.label.houseNumber')}
								name='houseNumber'
								placeholder={t('views.register.organisation.placeholder.houseNumber')}
								required
								variable={houseNumber}
							/>
							<FormField
								className={classes.fieldGroupItem}
								label={t('ui.label.addition')}
								name='addressAddition'
								placeholder={t('views.register.organisation.placeholder.numberAddition')}
								variable={addressAddition}
							/>
						</div>
					</div>
					<div className={classes.formGroup}>
						<FormField
							label={t('ui.label.city')}
							name='city'
							placeholder={t('views.register.organisation.placeholder.city')}
							required
							variable={city}
						/>
					</div>
					<div className={classes.formGroup}>
						<FormFieldSelect
							displayEmpty
							label={t('ui.label.country')}
							name='country'
							required
							variable={country}
						>
							<MenuItem disabled value={''}>
								<em>{t('views.addOrganisation.contactDetails.firstSelectOption.country')}</em>
							</MenuItem>
							{countryList.map((country) => (
								//Get ISO country code in English, use it to fetch translated country name
								<MenuItem key={country} value={countries.getAlpha2Code(country, 'en')}>
									{countries.getName(countries.getAlpha2Code(country, 'en'), i18n.language)}
								</MenuItem>
							))}
						</FormFieldSelect>
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

ContactInfo.propTypes = {
	className: PropTypes.string,

	save: PropTypes.func,
	organisationEmail: PropTypes.string,
	organisationPhoneNumber: PropTypes.string,
	address: PropTypes.object,
};

export default ContactInfo;
