import { useEffect, useState } from 'react';

import { TextField, MenuItem, Stack, CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog, SwitchButton } from '../../../../components';
import * as actions from '../../../../store/actions';
import { useStyles } from '../style';

const UnlinkDialog = (props) => {
	const {
		open,
		onClose,
		data,

		onUnlinkItemGroup,
		unlinkItemGroup,
		onUnlinkAllItemGroup,
		unlinkAllItemGroup,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const {
		success: unlinkItemGroupSuccess,
		loading: unlinkItemGroupLoading,
		error: unlinkItemGroupError,
	} = unlinkItemGroup;

	const {
		success: unlinkAllItemGroupSuccess,
		loading: unlinkAllItemGroupLoading,
		error: unlinkAllItemGroupError,
	} = unlinkAllItemGroup;

	const [selectItem, setSelectItem] = useState('');

	const [checkedAll, setCheckedAll] = useState(false);

	const handleCloseUnlinkDialog = () => {
		onClose();
		setSelectItem('');
		setCheckedAll(false);
	};

	const handleUnlinkAll = () => {
		setCheckedAll(!checkedAll);
		if (checkedAll) {
			setSelectItem(() => '');
		}
	};

	useEffect(() => {
		if (checkedAll) {
			return setSelectItem(() => '');
		}
		setSelectItem(() => data?.items?.[0].id);
	}, [data, checkedAll]);

	useEffect(() => {
		if (unlinkItemGroupSuccess || unlinkAllItemGroupSuccess) {
			enqueueSnackbar(t('views.unlinkDialog.success.message'), { variant: 'success' });
			onResetStateCondition('unlinkItemGroup', false);
			onResetStateCondition('unlinkAllItemGroup', false);
			handleCloseUnlinkDialog();
		} else if (unlinkItemGroupError || unlinkAllItemGroupError) {
			enqueueSnackbar(t('views.unlinkDialog.error.message'), { variant: 'error' });
			onResetStateCondition('unlinkItemGroup', false);
			onResetStateCondition('unlinkAllItemGroup', false);
		}
	}, [unlinkItemGroup, unlinkAllItemGroup]);

	const handleConfirm = () => {
		if (checkedAll) {
			return onUnlinkAllItemGroup(data.deviceId, data.organisationReference.id);
		}
		onUnlinkItemGroup(data.deviceId, data.organisationReference.id, selectItem);
	};

	const handleSelect = (event) => {
		const id = event.target.value;
		setSelectItem(id);
	};

	const selectProps = {
		name: 'tagType',
		onChange: handleSelect,
		value: selectItem || '',
		InputLabelProps: { shrink: true },
		label: t('views.devices.linkDevices.label.select'),
		disabled: checkedAll,
		select: true,
		size: 'medium',
		fullWidth: true,
		variant: 'outlined',
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text:
					unlinkItemGroupLoading || unlinkAllItemGroupLoading ?
						<CircularProgress disableShrink size={24} />
					:	t('ui.confirm'),
			}}
			handleClose={handleCloseUnlinkDialog}
			loading={false}
			open={open}
			title={t('ui.header.unlink.itemGroup')}
		>
			<Stack mt={2} gap={2}>
				<div className={classes.allSwitchCard}>
					<Typography>{t('views.devices.linkDevices.button.unlinkAll')}</Typography>
					<SwitchButton checked={checkedAll} onChange={handleUnlinkAll} />
				</div>
				<TextField {...selectProps}>
					{data?.items ?
						data?.items.map((item) => (
							<MenuItem key={item.id} value={item.id}>
								{item.name}
							</MenuItem>
						))
					:	<MenuItem></MenuItem>}
				</TextField>
			</Stack>
		</ActionDialog>
	);
};

UnlinkDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	data: PropTypes.object,

	onUnlinkItemGroup: PropTypes.func,
	onUnlinkAllItemGroup: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	unlinkItemGroup: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	unlinkAllItemGroup: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		unlinkItemGroup: state.condition.unlinkItemGroup,
		unlinkAllItemGroup: state.condition.unlinkAllItemGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUnlinkItemGroup: (deviceId, organisationId, itemId) =>
			dispatch(actions.unlinkItemGroup(deviceId, organisationId, itemId)),
		onUnlinkAllItemGroup: (deviceId, organisationId) =>
			dispatch(actions.unlinkAllItemGroup(deviceId, organisationId)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkDialog);
