import { useEffect } from 'react';

import { Card, CardContent, List, ListItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DirectIconUpload from './DirectIconUpload';
import LoadingIconBlock from './LoadingIconBlock';
import { useStyles } from './style';
import { LoadingBar } from '../../../components';
import { isArray, isFullArray } from '../../../shared/utility';
import * as actions from '../../../store/actions';

const Categories = (props) => {
	const { onFetchCategories, categoriesList } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = categoriesList;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	useEffect(() => {
		if (!categoriesLoading && !isFullArray(categoriesData)) {
			onFetchCategories('none');
		}
	}, [onFetchCategories, categoriesData]);

	return (
		<Card className={classes.root}>
			<CardContent>
				<List>
					{categoriesReady ?
						categoriesData.map((category) => (
							<ListItem className={classes.categoryListItem} key={`category-${category.id}`}>
								<Typography variant='h5'>{category.name}</Typography>
								<div className={classes.iconBlock}>
									<Typography className={classes.iconBlockText}>{t('ui.label.icon')}:</Typography>
									<DirectIconUpload categoriesData={categoriesData} category={category} />
								</div>
							</ListItem>
						))
					:	Array(3)
							.fill()
							.map((_, index) => (
								<ListItem className={classes.categoryListItem} key={`loading-category-${index}`}>
									<Typography variant='h5'>
										<LoadingBar />
									</Typography>
									<div className={classes.iconBlock}>
										<Typography className={classes.iconBlockText}>{t('ui.label.icon')}:</Typography>
										<LoadingIconBlock />
									</div>
								</ListItem>
							))
					}
				</List>
			</CardContent>
		</Card>
	);
};

Categories.propTypes = {
	categoriesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),

	//functions
	onFetchCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		categoriesList: state.list.categories,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCategories: (excludes) => dispatch(actions.fetchCategories(excludes)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
