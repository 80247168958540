import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CustomTypography = (props) => {
	const { children, ...rest } = props;

	return <Typography {...rest}>{children}</Typography>;
};

CustomTypography.propTypes = {
	children: PropTypes.any,
};

export default CustomTypography;
