import { Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InfoLabel } from '../../../../../../components';
import { useStyles } from '../style';

const CharacteristicsCard = (props) => {
	const { isEdit, children } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Card className={isEdit ? classes.cardTop : null}>
			<CardContent className={classes.cardCharacteristics}>
				<InfoLabel
					info={t('views.itemManagement.characteristicsDescription')}
					name={
						isEdit ? t('views.itemManagement.characteristicsEdit') : t('ui.characteristics.title')
					}
					paddingTop={2}
					variant='h4'
				/>
				{children}
			</CardContent>
		</Card>
	);
};

CharacteristicsCard.propTypes = {
	itemStep: PropTypes.number,

	isEdit: PropTypes.bool,
	children: PropTypes.array,
};

export default CharacteristicsCard;
