import path from 'path';

import { Input as InputIcon } from '@mui/icons-material';
import { AppBar, Box, Button, Toolbar, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link, useLocation } from 'react-router-dom';

import TopologyLogo from '~assets/images/topology_logo.svg?react';
import { pagePaths } from '~constants';

const AuthenticationHeader = (props) => {
	const { t } = useTranslation('general');
	const location = useLocation();
	const auth = useAuth();
	const theme = useTheme();

	return (
		<AppBar elevation={2} position='static' sx={{ bgcolor: theme.palette.common.white }}>
			<Toolbar>
				<Link to={`/${pagePaths.AuthLogin}`}>
					<TopologyLogo width='140px' />
				</Link>

				<Box flexGrow={1} />

				{location.pathname.includes(pagePaths.AuthLogin) && (
					<Button
						component={Link}
						to={`/${pagePaths.RegisterStart}`}
						variant='contained'
						sx={{ bgcolor: theme.palette.primary.dark }}
					>
						{t('view.login.button.contained.addorganisation')}
					</Button>
				)}

				{auth.isAuthenticated && (
					<Button component={Link} to={`/${pagePaths.AuthLogout}`} startIcon={<InputIcon />}>
						{t('ui.button.inline.signout')}
					</Button>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default AuthenticationHeader;
