import { useState, Fragment, useCallback, useEffect } from 'react';

import {
	Card,
	CardContent,
	Box,
	Typography,
	FormControl,
	FormGroup,
	FormControlLabel,
	TextField,
	Divider,
	MenuItem,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { SwitchButton, InfoLabel } from '../..';
import { getTimes, getLocalTime, stringifyTime, parseTime } from '../../../shared/datetime';
import { isString } from '../../../shared/utility';

const timeClose = 17;

const OpeningHoursCard = (props) => {
	const { className, save, openingHours } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [state, setState] = useState({
		weekdays: [
			{
				id: 1,
				value: 'monday',
				name: t('ui.days.monday'),
				open: getLocalTime(),
				close: getLocalTime(timeClose),
				isOpen: true,
				openAllDay: false,
			},
			{
				id: 2,
				value: 'tuesday',
				name: t('ui.days.tuesday'),
				open: getLocalTime(),
				close: getLocalTime(timeClose),
				isOpen: true,
				openAllDay: false,
			},
			{
				id: 3,
				value: 'wednesday',
				name: t('ui.days.wednesday'),
				open: getLocalTime(),
				close: getLocalTime(timeClose),
				isOpen: true,
				openAllDay: false,
			},
			{
				id: 4,
				value: 'thursday',
				name: t('ui.days.thursday'),
				open: getLocalTime(),
				close: getLocalTime(timeClose),
				isOpen: true,
				openAllDay: false,
			},
			{
				id: 5,
				value: 'friday',
				name: t('ui.days.friday'),
				open: getLocalTime(),
				close: getLocalTime(timeClose),
				isOpen: true,
				openAllDay: false,
			},
			{
				id: 6,
				value: 'saturday',
				name: t('ui.days.saturday'),
				open: getLocalTime(),
				close: getLocalTime(timeClose),
				isOpen: false,
				openAllDay: false,
			},
			{
				id: 7,
				value: 'sunday',
				name: t('ui.days.sunday'),
				open: getLocalTime(),
				close: getLocalTime(timeClose),
				isOpen: false,
				openAllDay: false,
			},
		],
		times: getTimes(),
	});

	useEffect(() => {
		save({ weekdays: state.weekdays });
	}, [openingHours]);

	const handleOpenChange = (event) => {
		event.persist();
		const updatedWeekdays = state.weekdays.map((weekday) =>
			weekday.id === parseInt(event.target.value, 10) ?
				{
					...weekday,
					isOpen: !weekday.isOpen,
					...(weekday.isOpen && { open: getLocalTime(), close: getLocalTime(timeClose) }),
				}
			:	weekday,
		);
		setState({ ...state, weekdays: updatedWeekdays });
		finishStep({ weekdays: updatedWeekdays });
	};

	const handleOpenAllDayChange = (event) => {
		event.persist();
		const updatedWeekdays = state.weekdays.map((weekday) =>
			weekday.id === parseInt(event.target.value, 10) ?
				{
					...weekday,
					openAllDay: !weekday.openAllDay,
					...(!weekday.openAllDay && { open: getLocalTime(0), close: getLocalTime(0) }),
				}
			:	weekday,
		);
		setState({ ...state, weekdays: updatedWeekdays });
		finishStep({ weekdays: updatedWeekdays });
	};

	const handleOpenTimeChange = (event) => {
		const value = event.target.value;
		event.preventDefault();
		if (isString(value)) {
			const updatedWeekdays = state.weekdays.map((weekday) =>
				weekday.name === event.target.name ?
					{
						...weekday,
						open: parseTime(value, weekday.open),
					}
				:	weekday,
			);
			setState({ ...state, weekdays: updatedWeekdays });
			finishStep({ weekdays: updatedWeekdays });
		}
	};

	const handleCloseTimeChange = (event) => {
		const value = event.target.value;
		event.preventDefault();
		if (isString(value)) {
			const updatedWeekdays = state.weekdays.map((weekday) =>
				weekday.name === event.target.name ?
					{
						...weekday,
						close: parseTime(value, weekday.close),
					}
				:	weekday,
			);
			setState({ ...state, weekdays: updatedWeekdays });
			finishStep({ weekdays: updatedWeekdays });
		}
	};

	// const initialWeekdaysCall = useEffect(() => {
	//   finishStep({ weekdays: state.weekdays });
	// }, []);

	const finishStep = useCallback((data) => {
		save(data);
	}, []);

	const inputPropsOpenAllDay = {
		style: { width: '100px', maxHeight: '46px', opacity: '0.5', color: '#9e9e9e' },
		disabled: true,
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Card className={classes.cardControl}>
				<CardContent>
					<InfoLabel
						info={t('views.addLocation.locationDetails.openingHoursSection.description')}
						isRequired
						name={t('views.addLocation.locationDetails.openingHoursSection.title')}
						paddingBottom={2}
						variant={'h3'}
					/>
					{state.weekdays.map((weekday) => (
						<Fragment key={weekday.id}>
							<div className={classes.switchButtons}>
								<Typography variant='h6'>{weekday.name}</Typography>
								<FormControl component='fieldset' variant='standard'>
									<FormGroup>
										<FormControlLabel
											control={
												<SwitchButton
													checked={weekday.isOpen}
													name={weekday.name}
													onChange={handleOpenChange}
													value={weekday.id}
												/>
											}
											label={
												<Typography variant='subtitle2'>
													{!weekday.isOpen ? t('ui.closed') : t('ui.open')}
												</Typography>
											}
											labelPlacement='start'
										/>
									</FormGroup>
								</FormControl>
								{weekday.isOpen ?
									<Box className={classes.expansionPanel}>
										<Box alignItems='center' display='flex'>
											<Typography variant='body1'>{t('ui.label.openingAt')}:</Typography>
											<Box pl={1} pr={6}>
												<form>
													<TextField
														InputProps={
															weekday.openAllDay ? inputPropsOpenAllDay : (
																{ style: { width: '100px', maxHeight: '46px' } }
															)
														}
														SelectProps={{
															MenuProps: { PaperProps: { style: { maxHeight: 250 } } },
														}}
														fullWidth
														name={weekday.name}
														onChange={handleOpenTimeChange}
														select={true}
														value={stringifyTime(weekday.open)}
														variant='outlined'
													>
														{state.times.map((time) => (
															<MenuItem
																value={time}
																key={time}
																disabled={
																	stringifyTime(weekday.close) <= time &&
																	stringifyTime(weekday.open) !== stringifyTime(weekday.close)
																}
															>
																{time}
															</MenuItem>
														))}
													</TextField>
												</form>
											</Box>
											<Typography variant='body1'>{t('ui.label.closingAt')}:</Typography>
											<Box pl={1}>
												<form>
													<TextField
														InputProps={
															weekday.openAllDay ? inputPropsOpenAllDay : (
																{ style: { width: '100px', maxHeight: '46px' } }
															)
														}
														SelectProps={{
															MenuProps: { PaperProps: { style: { maxHeight: 250 } } },
														}}
														fullWidth
														name={weekday.name}
														onChange={handleCloseTimeChange}
														select={true}
														value={stringifyTime(weekday.close)}
														variant='outlined'
													>
														{state.times.map((time) => (
															<MenuItem
																key={time}
																value={time}
																disabled={stringifyTime(weekday.open) >= time}
															>
																{time}
															</MenuItem>
														))}
													</TextField>
												</form>
											</Box>
											<Box flexGrow='1'>
												<FormGroup>
													<FormControlLabel
														control={
															<SwitchButton
																checked={weekday.openAllDay}
																name={weekday.name}
																onChange={handleOpenAllDayChange}
																value={weekday.id}
															/>
														}
														label={<Typography variant='subtitle2'>{t('ui.allDay')}</Typography>}
														labelPlacement='start'
													/>
												</FormGroup>
											</Box>
										</Box>
									</Box>
								:	null}
							</div>
							{weekday.id !== state.weekdays.length ?
								<Divider className={classes.dividers} variant='fullWidth' />
							:	null}
						</Fragment>
					))}
				</CardContent>
			</Card>
		</div>
	);
};

OpeningHoursCard.propTypes = {
	className: PropTypes.string,
	save: PropTypes.func,

	openingHours: PropTypes.array,
};

export default OpeningHoursCard;
