import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import path from 'path';

import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Link as MuiLink, Portal, Stack, useTheme } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
	GridRenderCellParams,
	GridRowParams,
	GridToolbarContainer,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { DataGrid, DataGridToolbar as BaseDataGridToolbar, GridActionsCellItem } from '~components';
import { pagePathSegments } from '~constants';
import { BaseReference } from '~interfaces';

import UserGroup from '../../interfaces/userGroup';
import UserGroupsService from '../../services/userGroupsService';

const toolbarIdString = 'topology-usergroup-datagrid';

const service = new UserGroupsService();

interface RefProps {
	mutate: () => void;
}

interface UserGroupsDataGridProps {
	onCreate?: () => void;
	onDelete?: (id: string) => void;
}

const UserGroupsDataGrid = forwardRef<RefProps, UserGroupsDataGridProps>(
	({ onCreate, onDelete }, ref) => {
		const { t } = useTranslation('general');
		const theme = useTheme();

		const [fetchParameters, setFetchParameters] = useState<object | null>(null);
		const [filterModel, setFilterModel] = useState<GridFilterModel>(null!);

		const columns: GridColDef<UserGroup>[] = [
			{
				field: 'label',
				headerName: t('name'),
				flex: 1,
			},
			{
				field: 'totalUsers',
				headerName: t('totalUsers'),
				type: 'number',
			},
			{
				field: 'totalItemGroups',
				headerName: t('totalItemGroups'),
				type: 'number',
			},
			{
				field: 'totalItems',
				headerName: t('totalItems'),
				type: 'number',
			},
			{
				field: 'organisation',
				headerName: t('organisation'),
				renderCell: (params: GridRenderCellParams<UserGroup>) => (
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(pagePathSegments.Organisations, params.value.id, pagePathSegments.Summary)}`}
					>
						{params.value.label}
					</MuiLink>
				),
				flex: 1,
			},
			{
				field: 'actions',
				type: 'actions',
				width: 50,
				resizable: false,
				hideable: false,
				align: 'right',
				getActions: (params: GridRowParams) => [
					<GridActionsCellItem
						key={`${params.id}-delete`}
						label={t('delete')}
						icon={<DeleteOutlinedIcon color='error' />}
						showInMenu
						onClick={() => onDelete != null && onDelete(params.id.toString())}
						sx={{ color: theme.palette.error.main }}
					/>,
				],
			},
		];

		const { data, isLoading, isValidating, error, mutate } = useSWR(
			fetchParameters ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getUserGroups(args),
			{
				onSuccess: (res) => res?.total != null && setTotalCount(res.total),
				keepPreviousData: true,
			},
		);

		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useImperativeHandle(ref, () => ({
			mutate: mutate,
		}));

		const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
			setFilterModel(model);
		};

		const Toolbar = (props: any) =>
			useMemo(
				() => (
					<Portal container={() => document.getElementById(toolbarIdString)}>
						<BaseDataGridToolbar {...props}>
							<Button variant='contained' onClick={() => onCreate != null && onCreate()}>
								{t('addResource', { resource: t('userGroup') })}
							</Button>
						</BaseDataGridToolbar>
					</Portal>
				),
				[],
			);

		return (
			<Stack flexGrow={1} spacing={2}>
				<Box id={toolbarIdString} />
				<DataGrid
					columns={columns}
					error={error}
					loading={isLoading || isValidating}
					rows={data?.results ?? []}
					rowCount={totalCount}
					onPaginationModelChange={handlePaginationChange}
					onFilterModelChange={handleFilterModelChange}
					slots={{ toolbar: Toolbar }}
					sx={{ flexGrow: 1, flexShrink: 1, minHeight: 400 }}
				/>
			</Stack>
		);
	},
);
UserGroupsDataGrid.displayName = 'UserGroupsDataGrid';

export default UserGroupsDataGrid;
