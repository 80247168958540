import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	cell: {
		width: '250px',
	},
	cellStyle: {
		width: '8.25rem',
	},
}));
