import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import BookingsFinance from './BookingsFinance';
import Header from './Header';
import OrganisationalBookings from './OrganisationalBookings';
import { useStyles } from './style';
import { Page } from '../../../../components';

const Bookings = (props) => {
	const location = useLocation();
	const { t } = useTranslation('general');

	const { date, title, id, name } = useParams();

	const classes = useStyles();
	return (
		<Page className={classes.root} title={t('ui.finance')}>
			<Header date={date} id={id} name={name} title={title} />
			<Box pt={3}>
				<BookingsFinance date={date} id={id} />
			</Box>
			<OrganisationalBookings
				date={date}
				id={id}
				name={name}
				partnerId={location.state.organisationId}
				title={title}
			/>
		</Page>
	);
};

Bookings.propTypes = {};

export default Bookings;
