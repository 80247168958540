import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import InvitePartner from './InvitePartner';
import { Wizard } from '../../../../components';

const AssignItemsToUserGroups = (props) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);

	const callback = (saved) => {
		const { finishedWizard } = saved;

		if (finishedWizard && !startedFinishWizard) {
			setStartedFinishWizard(true);
		}
	};

	const handleCancel = () => {
		navigate('/partnerships/partners');
	};

	const addPartnerSteps = [
		{
			name: 'Invite Partner',
			content: InvitePartner,
			valid: true,
		},
	];

	return (
		<Wizard
			callback={callback}
			handleCancel={handleCancel}
			hasCancelButton={true}
			loading={startedFinishWizard}
			shouldNotShowStepActionFooter
			steps={addPartnerSteps}
			title={t('views.addPartner.page.title')}
		/>
	);
};

export default AssignItemsToUserGroups;
