import { Box, BoxProps } from '@mui/material';
import { Helmet } from 'react-helmet';

interface PageProps extends BoxProps {
	title?: string;
}

/**
 * A base component to define a page. Defines a default layout and
 * uses react-helmet to add page characteristics
 */
const Page = ({ title = 'Admin Panel', children, ...boxProps }: PageProps) => {
	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Box width={1} height={1} {...boxProps}>
				{children}
			</Box>
		</>
	);
};

export default Page;
