import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	deletLoc: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '0',
	},
	iconCell: {
		width: 73,
	},
	subtitle: {
		margin: theme.spacing(2, 0),
	},
	organisationContainer: {
		margin: theme.spacing(5, 0),
	},
	subscriptionSelect: {
		paddingBottom: theme.spacing(2),
	},
	alertOrganisationName: {
		marginLeft: theme.spacing(2),
	},
	counterText: {
		color: theme.palette.grey[400],
	},
	rejectionAlertContent: {
		padding: theme.spacing(0, 0, 3),
	},
	avatar: {
		'& img': {
			objectFit: 'scale-down',
		},
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));
