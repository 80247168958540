import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	DialogProps,
	Divider,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from '~lib/i18n';

interface ConfirmationDialogProps extends DialogProps {
	/**
	 * The title of the dialog
	 */
	title?: string;
	/**
	 * A helper text to provide more information about
	 * the action.
	 */
	subTitle?: string | null;
	onConfirm: () => void;
	onDecline?: () => void;
	loading?: boolean;
	enableWriteConfirmation?: boolean;
	confirmationText?: string;
}

/**
 * Use a dialog to confirm a certain action
 * @returns
 */
const ConfirmationDialog = ({
	title = i18n.t('ui.delete'),
	subTitle,
	loading = false,
	enableWriteConfirmation = false,
	confirmationText = i18n.t('ui.confirm'),
	onDecline = () => null,
	onConfirm,
	...dialogProps
}: ConfirmationDialogProps) => {
	const { t } = useTranslation('general');

	const [confirmTextValue, setConfirmTextValue] = useState('');

	const handleConfirmClick = () => {
		setConfirmTextValue('');
		if (onConfirm) {
			onConfirm();
		}
	};

	const handleDeclineClick = () => {
		setConfirmTextValue('');
		onDecline();
	};

	return (
		<Dialog onClose={handleDeclineClick} {...dialogProps}>
			<Stack spacing={2} p={4} minWidth={500}>
				<Typography variant='h2'>{title}</Typography>
				{!subTitle ? null : (
					<Stack spacing={1}>
						<Typography>{subTitle}</Typography>
						{enableWriteConfirmation && (
							<>
								<Typography>{`Type "${confirmationText}" to confirm this action`}</Typography>
								<TextField
									value={confirmTextValue}
									onChange={(e) => setConfirmTextValue(e.target.value)}
									fullWidth
								/>
							</>
						)}
					</Stack>
				)}
				<Divider orientation='horizontal' />
				<Stack direction='row' spacing={3} justifyContent='space-evenly' px={1} pt={2}>
					<Button
						variant='outlined'
						onClick={handleDeclineClick}
						disabled={loading}
						sx={{ width: 1 }}
					>
						{t('ui.cancel')}
					</Button>
					<LoadingButton
						variant='contained'
						loading={loading}
						onClick={handleConfirmClick}
						disabled={enableWriteConfirmation && confirmTextValue !== confirmationText}
						color='secondary'
						sx={{ width: 1 }}
					>
						{confirmationText}
					</LoadingButton>
				</Stack>
			</Stack>
		</Dialog>
	);
};

export default ConfirmationDialog;
