import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(2),
	},
	search: {
		flexGrow: 1,
		height: 42,
		padding: theme.spacing(0, 2),
		display: 'flex',
		alignItems: 'center',
	},
	searchIcon: {
		marginRight: theme.spacing(1),
		color: theme.palette.icon,
	},
	searchInput: {
		width: '100%',
	},
	searchButton: {
		marginLeft: theme.spacing(2),
	},
}));
