export const styles = (theme) => ({
	root: {
		display: 'block',
		backgroundColor: theme.palette.action.hover,
		height: '1.2em',
	},
	text: {
		marginTop: 0,
		marginBottom: 0,
		height: 'auto',
		transformOrigin: '0 60%',
		transform: 'scale(1, 0.60)',
		borderRadius: theme.shape.borderRadius,
		'&:empty:before': {
			content: '"\\00a0"',
		},
	},
	rect: {},
	circle: {
		borderRadius: '50%',
	},
	pulse: {
		animation: '$pulse 2.5s ease-in-out 0.5s infinite',
	},
	'@keyframes pulse': {
		'0%': {
			opacity: 1,
		},
		'50%': {
			opacity: 0.6,
		},
		'100%': {
			opacity: 1,
		},
	},
	wave: {
		position: 'relative',
		overflow: 'hidden',
		'&::after': {
			animation: '$wave 2.5s linear 0.5s infinite',
			background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent)',
			content: '""',
			position: 'absolute',
			transform: 'translateX(-100%)', // Avoid flash during server-side hydration
			bottom: 0,
			left: 0,
			right: 0,
			top: 0,
			zIndex: 1,
		},
	},
	'@keyframes wave': {
		'0%': {
			transform: 'translateX(-100%)',
		},
		'60%': {
			// +0.5s of delay between each loop
			transform: 'translateX(100%)',
		},
		'100%': {
			transform: 'translateX(100%)',
		},
	},
});
