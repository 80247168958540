import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import {
	Block as BlockIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Link as MuiLink, Portal, Stack, TextField, useTheme } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
	GridRenderCellParams,
	GridRowParams,
	GridSortModel,
} from '@mui/x-data-grid';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { userInfoAtom } from '~atoms';
import {
	AsyncAutocomplete,
	DataGrid,
	DataGridToolbar,
	GridActionsCellItem,
} from '~components';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';
import { useDownloadFile } from '~hooks';
import { BaseReference, IdReference } from '~interfaces';

import User from '../../interfaces/user';
import UserIdentitiesService from '../../services/userIdentitiesService';
import UsersService from '../../services/usersService';
import { getInitials } from '../../utils/userUtils';

const toolbarIdString = 'topology-users-toolbar';

const usersService = new UsersService();
const userIdentitiesService = new UserIdentitiesService();

interface UsersDataGridProps {
	enableEdit?: boolean;
	enableDelete?: boolean;
	onCreate?: (type: 'anonymous') => void;
	onInvite?: () => void;
	onEdit?: (id: string) => void;
	onDeactivate?: (id: string) => void;
	onDelete?: (id: string) => void;
}

const UsersDataGrid = ({
	onCreate,
	onInvite,
	onEdit,
	onDeactivate,
	onDelete,
}: UsersDataGridProps) => {
	const { t } = useTranslation('general');
	const theme = useTheme();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);
	const { isLoading: isFileDownloading, downloadFile } = useDownloadFile();

	const [fetchParameters, setFetchParameters] = useState<object | null>(null);

	const columns: GridColDef<User>[] = [
		{
			field: 'imageUrl',
			headerName: '',
			sortable: false,
			filterable: false,
			width: 60,
			renderCell: (params) => (
				<Box alignContent='center' height={1}>
					<Avatar src={params.row.imageUrl}>{getInitials(params.row)}</Avatar>
				</Box>
			),
		},
		{
			field: 'label',
			headerName: t('name'),
			flex: 1,
			renderCell: (params: GridRenderCellParams<User>) => (
				<MuiLink
					component={ReactRouterLink}
					to={`/${path.join(`${pagePathSegments.UserManagement}2`, pagePathSegments.Users, params.row.id)}`}
					// to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, params.row.id, pagePathSegments.Summary)}`}
					fontStyle={params.value && params.value !== ' ' ? 'inherit' : 'italic'}
				>
					{params.value && params.value !== ' ' ? params.value : t('noName')}
				</MuiLink>
			),
		},
		{
			field: 'id',
			headerName: 'ID',
			renderCell: (params: GridRenderCellParams<User>) => (
				<MuiLink
					component={ReactRouterLink}
					to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, params.value, pagePathSegments.Summary)}`}
				>
					{params.value}
				</MuiLink>
			),
		},
		{
			field: 'email',
			headerName: t('ui.label.email'),
			renderCell: (params: GridRenderCellParams<User>) => (
				<MuiLink href={`mailto:${params.value}`}>{params.value}</MuiLink>
			),
			flex: 1,
		},
		...(isSuperAdmin() ?
			[
				{
					field: 'organisation',
					headerName: t('organisation'),
					// valueFormatter: (value: BaseReference) => value.label,
					flex: 1,
					renderCell: (params: GridRenderCellParams<User>) => (
						<MuiLink
							component={ReactRouterLink}
							to={`/${path.join(pagePathSegments.Organisations, params.value.id, pagePathSegments.Summary)}`}
						>
							{params.value.label}
						</MuiLink>
					),
				},
			]
			: []),
		{
			field: 'cards',
			headerName: t('nfcId'),
			sortable: false,
			valueFormatter: (value?: IdReference[]) =>
				value != null && value?.length > 0 ? value[0].id : '-',
			flex: 1,
		},
		{
			field: 'userGroup',
			headerName: t('userGroup'),
			valueFormatter: (value: BaseReference) => value.label,
			flex: 1,
			renderCell: (params: GridRenderCellParams<BaseReference>) => (
				<MuiLink
					component={ReactRouterLink}
					to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.UserGroups, params.value.id, pagePathSegments.Summary)}`}
				>
					{params.value.label}
				</MuiLink>
			),
		},
		{
			field: 'actions',
			type: 'actions',
			width: 50,
			align: 'right',
			resizable: false,
			hideable: false,
			getActions: (params: GridRowParams) => [
				<GridActionsCellItem
					showInMenu
					key={`${params.id}-edit`}
					label={t('ui.edit')}
					icon={<EditIcon />}
					onClick={() => onEdit != null && onEdit(params.id.toString())}
				/>,
				<GridActionsCellItem
					showInMenu
					key={`${params.id}-deactivate`}
					label={t('ui.deactivate')}
					icon={<BlockIcon color='error' />}
					onClick={() => onDeactivate != null && onDeactivate(params.id.toString())}
					sx={{ color: theme.palette.error.main }}
				/>,
			],
		},
	];

	const { data, isLoading, isValidating, error } = useSWR(
		[usersService.basePath, fetchParameters],
		([_, args]) => usersService.getUsers(args),
		{
			onSuccess: (res) => res?.total != null && setTotalCount(res.total),
			keepPreviousData: true,
		},
	);

	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	const handlePaginationModelChange = (
		value: GridPaginationModel,
		details: GridCallbackDetails,
	) => {
		if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
			usersService.abortCurrentRequest('pagination change');
		}

		setFetchParameters((prev) => ({
			...prev,
			page: value.page + 1,
			pageSize: value.pageSize,
		}));
	};

	const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
		if (isLoading || isValidating) {
			usersService.abortCurrentRequest('Sort model change');
		}

		setFetchParameters((prev) => ({
			...prev,
			sortBy: model ? model[0]?.field : undefined,
			descending: model ? model[0]?.sort === 'desc' : undefined,
		}));
	};

	const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
		const organisation =
			model.items && model.items[0]?.field === 'organisation' ? model.items[0].value : undefined;

		console.log(model);
		setFetchParameters((prev) => ({
			...prev,
			searchQuery: model.quickFilterValues ? model.quickFilterValues[0] : undefined,
			organisationId: organisation?.id,
		}));
	};

	const handleExportUsers = async () => {
		downloadFile(
			() => usersService.getUsersForExport(organisationFilter?.id ?? userInfo.organisation.id),
			'User details',
		);
	};

	const ToolbarBup = () => useMemo(() => (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar>
				<LoadingButton
					loading={isFileDownloading}
					variant='outlined'
					onClick={handleExportUsers}
				>
					{t('views.userDetails.summary.exportUsers')}
				</LoadingButton>
				<Button variant='contained' onClick={() => onCreate != null && onCreate('anonymous')}>
					{t('addAnonymousUser')}
				</Button>
				<Button variant='contained' onClick={() => onInvite != null && onInvite()}>
					{t('ui.button.contained.inviteusers')}
				</Button>
			</DataGridToolbar>
		</Portal>
	), []);

	return (
		<Stack spacing={2} height={1}>
			<Box id={toolbarIdString} />
			<DataGrid
				checkboxSelection
				columns={columns}
				error={error}
				loading={isLoading || isValidating}
				rows={data?.results ?? []}
				rowCount={totalCount}
				onPaginationModelChange={handlePaginationModelChange}
				onSortModelChange={handleSortModelChange}
				onFilterModelChange={handleFilterModelChange}
				slots={{
					toolbar: Toolbar,
				}}
				slotProps={{
					toolbar: {
						isFileDownloading: isFileDownloading,
						handleExportUsers: handleExportUsers,
						onCreate: onCreate,
						onInvite: onInvite
					}
				}}
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'id',
								sort: 'desc',
							},
						],
					},
				}}
				sx={{ flexGrow: 1 }}
			/>
		</Stack>
	);
};

const Toolbar = (props) => {
	const { t } = useTranslation('general');

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar>
				<LoadingButton
					loading={props.isFileDownloading}
					variant='outlined'
					onClick={props.handleExportUsers}
				>
					{t('views.userDetails.summary.exportUsers')}
				</LoadingButton>
				<Button variant='contained' onClick={() => props.onCreate != null && props.onCreate('anonymous')}>
					{'addAnonymousUser'}
				</Button>
				<Button variant='contained' onClick={() => props.onInvite != null && props.onInvite()}>
					{'ui.button.contained.inviteusers'}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default UsersDataGrid;
