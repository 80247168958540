import { useEffect, useState } from 'react';

import { Edit as EditIcon, Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';

import { InfoCard } from '~components';
import { datetimeWithoutSecondsFormat } from '~utils/dateUtils';

import NfcTagsService from '../../services/nfcTagsService';

const service = new NfcTagsService();

interface NfcGeneralInfoCardProps {
	id?: string;
	onLinkClick?: () => void;
	onUnlinkClick?: (nfcId: string) => void;
}

const NfcGeneralInfoCard = ({ id, onLinkClick, onUnlinkClick }: NfcGeneralInfoCardProps) => {
	const { t } = useTranslation('general');

	const { data, isLoading, error, mutate } = useSWR(id != null && [service.basePath, id], ([_, id]) =>
		service.getNfcTagById(id),
	);

	const rows =
		!data || !id ?
			[]
		:	[
				{
					headerName: t('views.userDetails.summary.nfcCardNumber'),
					value: data.tagNumber,
				},
				{
					headerName: t('views.userDetails.summary.nfcid'),
					value: data.id,
				},
				{
					headerName: t('views.userDetails.summary.nfcCardLinked'),
					value: data.dateLinked,
					valueFormatter: (value: Date) => datetimeWithoutSecondsFormat.format(value),
				},
			];

	const isLinked = data?.user != null;

	return (
		<InfoCard
			title={t('ui.label.nfcTags')}
			rows={rows}
			actions={[
				// {
				// 	label: 'edit',
				// 	icon: <EditIcon />,
				// 	showInMenu: true,
				// },
				...(isLinked ?
					[
						{
							label: 'unlink',
							icon: <LinkOffIcon />,
							showInMenu: false,
							// onClick: () => onUnlinkClick && onUnlinkClick(),
							onClick: () => {
								if (id && onUnlinkClick) {
									onUnlinkClick(id);
									// handleUnlinkClick(id);
								}
							},
						},
					]
				:	[
						{
							label: 'link',
							icon: <LinkIcon />,
							showInMenu: false,
							onClick: () => onLinkClick && onLinkClick(),
						},
					]),
			]}
			error={error != null}
			loading={isLoading}
			noResultsLabel={t('noNfcLinked')}
		/>
	);
};

export default NfcGeneralInfoCard;
