import path from 'path';

import { PagedResponse } from '~interfaces';
import { BaseReferenceResponse } from '~interfaces/responses';
import { BaseServiceMapper, TopologyService } from '~services';

import DeviceCommandEnum from '../enums/deviceCommandEnum';
import DeviceTypeEnum from '../enums/deviceTypeEnum';
import Device from '../interfaces/device';

class DevicesService extends TopologyService {
	public readonly path = 'devices';

	constructor() {
		super('v1');
	}

	/**
	 * Get a list of access rules
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getDevices(
		page = 1,
		pageSize = 10,
		organisationId?: string,
		deviceType?: DeviceTypeEnum,
		searchQuery?: string,
	): Promise<PagedResponse<Device>> {
		const { data } = await this._client.get<PagedResponse<DeviceResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: organisationId,
				...(deviceType && { deviceType: deviceType }),
				...(searchQuery && { searchTerm: searchQuery }),
			},
		});

		return {
			...data,
			results: data.results.map((el) => DeviceServiceMapper.fromResponse(el)),
		};
	}

	/**
	 * Get the details for a single device
	 * @param id The deviceId of the device
	 * @returns
	 */
	async getDeviceById(id: string): Promise<Device> {
		const { data } = await this._client.get<DeviceResponse>(path.join(this.path, id));

		return DeviceServiceMapper.fromResponse(data);
	}

	/**
	 *
	 * @param id The deviceId
	 * @param command The command to send to the device
	 * @returns
	 */
	async sendCommands(id: string, commands: DeviceCommandEnum[]): Promise<boolean> {
		const { data } = await this._client.post(path.join(this.path, id, 'commands'), {
			command: commands,
		});

		// Backend doesn't really give a response. But if something goes wrong, an exception is raised
		// right? Validate
		return true;
	}
}

class DeviceServiceMapper {
	static fromResponse(data: DeviceResponse): Device {
		const { deviceId, deviceName, organisationReference, ...rest } = data;

		return {
			...rest,
			id: deviceId,
			label: deviceName,
			organisation: BaseServiceMapper.fromBaseReferenceResponse(organisationReference),
		};
	}
}

interface DeviceResponse {
	deviceId: string;
	deviceName: string;
	organisationReference: BaseReferenceResponse;
}

export default DevicesService;
