import { Card, CardContent, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import itemsIcon from '../../../../assets/icons/items-24-px@2x.webp';
import { Tooltip, LoadingBar } from '../../../../components';
import { useStyles } from '../../style';

const Items = (props) => {
	const classes = useStyles();
	const { dashboardItemsOverview } = props;
	const { t } = useTranslation('general');

	const { data: dashboardItemsOverviewData, loading: dashboardItemsOverviewLoading } =
		dashboardItemsOverview;

	const handleItems = (val) => (val ? val : 0);

	return (
		<Card className={classes.card}>
			<CardContent
				sx={{
					padding: '24px 16px',
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
				}}
			>
				<Box display='flex'>
					<Box className={classes.tooltipContainer}>
						<Tooltip
							content={<img alt={'items-icon'} height='25' src={itemsIcon} width='25' />}
							infoTitle={
								<Box pl={2}>
									{t('views.dashboard.items.tooltip.overview')}
									<ul>
										<li>{t('views.dashboard.items.tooltip.itemsInUse')}</li>
										<li>{t('views.dashboard.items.tooltip.serviceItems')}</li>
										<li>{t('views.dashboard.items.tooltip.categoriesInUse')}</li>
										<li>{t('views.dashboard.items.tooltip.sharedItems')}</li>
									</ul>
								</Box>
							}
						/>
					</Box>
					<Box display='flex' flexDirection='column'>
						{dashboardItemsOverviewLoading ?
							<LoadingBar />
						:	<Typography>{`${t('views.dashboard.label.itemsInUse')}: ${handleItems(dashboardItemsOverviewData?.itemsInUse)}/${handleItems(dashboardItemsOverviewData?.itemsTotal)}`}</Typography>
						}
						{dashboardItemsOverviewLoading ?
							<LoadingBar />
						:	<Typography>{`${t('views.dashboard.label.serviceItems')}: ${handleItems(dashboardItemsOverviewData?.serviceItems)}`}</Typography>
						}
					</Box>
				</Box>
				<Box display='flex' flexDirection='column' pl={7}>
					{dashboardItemsOverviewLoading ?
						<LoadingBar />
					:	<Typography>{`${t('ui.category.categories')}: ${handleItems(dashboardItemsOverviewData?.categoryInUse)}/${handleItems(dashboardItemsOverviewData?.categoryTotal)}`}</Typography>
					}
					{dashboardItemsOverviewLoading ?
						<LoadingBar />
					:	<Typography>{`${t('views.dashboard.label.sharedItems')}: ${handleItems(dashboardItemsOverviewData?.sharedItems)}`}</Typography>
					}
				</Box>
			</CardContent>
		</Card>
	);
};

Items.propTypes = {
	dashboardItemsOverview: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		dashboardItemsOverview: state.details.dashboardItemsOverview,
	};
};

export default connect(mapStateToProps, null)(Items);
