import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	stepperHeaderContainer: {
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		// padding: theme.spacing(2)
	},
	bottomHeader: {
		marginTop: theme.spacing(-2),
	},
	stepperHeader: {
		maxWidth: '60rem',
		flex: 1,
	},
	mobileStepperHeader: {
		flex: 1,
		backgroundColor: 'white',
		padding: '20px',
		justifyContent: 'space-between',
	},
	stepperBackButton: {
		marginRight: theme.spacing(2),
	},
	stepperNextButton: {
		marginLeft: theme.spacing(2),
	},
	stepActionFooterContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	stepActionFooter: {
		maxWidth: '60rem',
		flex: 1,
		marginBottom: '4rem',
		marginTop: '2rem',
		display: 'flex',
		justifyContent: 'space-between',

		// borderTop: `.05rem solid ${colors.grey[300]}`,
		paddingTop: theme.spacing(3),
	},
	pushRight: {
		marginLeft: 'auto',
	},
	columns: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: 'auto',
		maxWidth: '80rem',
	},
	mainColumn: {
		flex: '1 1 53rem',
		maxWidth: '53rem',
		marginRight: '2rem',
		'&:only-child': {
			marginRight: '27rem',
			marginLeft: 'auto',
		},
	},
	sidebarColumn: {
		position: 'relative',
		flex: '0 0 25rem',
	},
	singleColumn: {
		width: '100%',
		maxWidth: '60rem',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	wizardContentWrapper: {
		padding: theme.spacing(6, 3, 3),
		backgroundColor: 'white',
	},
	progress: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	header: {
		position: 'sticky',
		top: '64px',
	},
	button: {
		minWidth: '12.5rem',
		minHeight: '2.5rem',
	},
}));
