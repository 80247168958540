import { forwardRef, useState, useEffect } from 'react';

import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { TextField, Typography, InputAdornment } from '@mui/material';
import { enGB, nl } from 'date-fns/locale';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

import 'react-datepicker/dist/react-datepicker.css';

const ReactDatePicker = (props) => {
	const { containerClassName, error, variant, label, disabled, ...rest } = props;
	const { i18n, t } = useTranslation('general');
	const classes = useStyles();
	const [showErrorState, setShowErrorState] = useState(false);

	registerLocale('nl', nl);
	registerLocale('en', enGB);

	useEffect(() => {
		if (disabled) {
			setShowErrorState(false);
		} else {
			setShowErrorState(error);
		}
	}, [error, disabled]);

	const CustomTextInput = forwardRef(
		(
			{ value, onClick },
			ref, //eslint-disable-line
		) => (
			<Typography className={classes.date} component='p' onClick={onClick} ref={ref} variant='h5'>
				{value}
				<ArrowDownIcon />
			</Typography>
		),
	);

	return (
		<div className={containerClassName}>
			<DatePicker
				{...rest}
				calendarStartDay={1}
				customInput={
					variant === 'input' ?
						<TextField
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<CalendarTodayIcon />
									</InputAdornment>
								),
							}}
							error={showErrorState}
							fullWidth
							label={label}
							variant='outlined'
						/>
					:	<CustomTextInput />
				}
				disabled={disabled}
				locale={i18n.language}
				popperClassName={classes.popper}
				popperModifiers={[
					{
						name: 'preventOverflow',
						options: {
							enabled: true,
							escapeWithReference: false,
							boundariesElement: 'viewport',
						},
					},
				]}
				timeCaption={t('ui.time')}
			/>
		</div>
	);
};

ReactDatePicker.propTypes = {
	containerClassName: PropTypes.string,
	error: PropTypes.bool,
	variant: PropTypes.oneOf(['input', 'text']),
	label: PropTypes.string,
	disabled: PropTypes.bool,
};

ReactDatePicker.defaultProps = {
	dateFormat: 'd MMMM, H:mm',
	timeFormat: 'HH:mm',
	timeIntervals: 15,
	variant: 'input',
};

export default ReactDatePicker;
