import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	mainCard: {
		padding: theme.spacing(4.5),
	},
	option: {
		border: `2px solid ${theme.palette.divider}`,
		borderRadius: theme.spacing(1),
		display: 'flex',
		alignItems: 'flex-start',
		padding: theme.spacing(2),
		'& + &': {
			marginTop: theme.spacing(3),
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: theme.palette.primary.main,
		},
	},
	optionRadio: {
		margin: -10,
	},
	optionDetails: {
		marginLeft: theme.spacing(2),
	},
	selectedOption: {
		borderColor: theme.palette.primary.main,
	},
	itemSpacing: {
		paddingBottom: theme.spacing(3),
	},
	inputLabelSpacing: {
		paddingBottom: theme.spacing(3),
	},
	tip: {
		paddingTop: theme.spacing(3),
	},
	dateTimePicker: {
		width: '100%',
		'& + &': {
			marginLeft: 28,
		},
	},
}));
