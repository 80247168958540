import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	periodContent: {
		minWidth: '25vw',
	},
	periodContentExpenses: {
		minWidth: '40vw',
	},
	link: {
		fontWeight: theme.link.body2.fontWeight,
		fontSize: theme.link.body2.fontSize,
		letterSpacing: theme.link.body2.letterSpacing,
		lineHeight: theme.link.body2.lineHeight,
	},
	open: {
		minWidth: '10vw',
	},
}));
