import { useState } from 'react';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Typography,
	Box,
	IconButton,
	CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { BasicSelectedCard } from '../../../../../components';
import { isSuperAdmin } from '../../../../../shared/utility';
import { useStyles } from '../style';

const API_BASE = import.meta.env.VITE_TOPOLOGY_API_URL;

function download(pdfUrl, fileName, setLoading) {
	fetch(pdfUrl, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/zip',
		},
	})
		.then((resp) => resp.arrayBuffer())
		.then((resp) => {
			// set the blog type to final pdf
			const file = new Blob([resp], { type: 'application/zip' });
			// process to auto download it
			const fileURL = URL.createObjectURL(file);
			const link = document.createElement('a');
			link.href = fileURL;
			link.download = `${fileName}.zip`;
			link.click();
			setLoading(false);
		});
}

const TermsAndConditions = (props) => {
	const { contractData, currentUser } = props;
	const auth = useAuth();
	const { t } = useTranslation('general');

	const { data: currentUserData } = currentUser;

	const [loading, setLoading] = useState(false);

	const isProvider =
		currentUserData?.organisationReference?.id === contractData?.itemInstance.hub.organisation.id;

	const classes = useStyles();

	const handlePdfVisibility = (e) => {
		e.preventDefault();
		setLoading(true);
		download(
			`${API_BASE}api/v1/terms/${contractData.terms.id}/files`,
			`${contractData.name}_${contractData.terms.name}_${t('view.policymanagement.termdetails.button.inline.returntotermslist')}`,
			setLoading,
		);
	};

	return (
		<Card className={classes.cardRoot}>
			<CardHeader title={t('ui.category.termsAndConditions')} />
			<Divider />
			<CardContent className={classes.content}>
				{isProvider && !isSuperAdmin(auth.user?.profile.role) ?
					<>
						<Typography className={classes.subtitle} variant='h6'>
							{t('views.itemDetail.summary.subtitle.assignedTerms')}
						</Typography>
						<Box pb={1} pt={1}>
							<BasicSelectedCard
								className={classes.selectedTerms}
								hasCloseButton={false}
								name={contractData.terms.name}
							/>
						</Box>
					</>
				:	<Card className={classes.fileCard}>
						<CardContent className={classes.uploadedFile}>
							<Box className={classes.fileName}>
								<DescriptionOutlinedIcon className={classes.description} fontSize='large' />
								<Box>
									<Typography variant='h6'>{`${contractData.terms.name}.zip`}</Typography>
									<Typography variant='body2'>{/* {isFullString(size) && size} */}</Typography>
								</Box>
							</Box>
							{loading ?
								<CircularProgress
									className={classes.progress}
									color='info'
									disableShrink
									size={25}
								/>
							:	<IconButton className={classes.description} onClick={handlePdfVisibility}>
									<DownloadIcon fontSize='medium' />
								</IconButton>
							}
						</CardContent>
					</Card>
				}
			</CardContent>
		</Card>
	);
};

TermsAndConditions.propTypes = {
	contractData: PropTypes.object,

	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
	};
};

export default connect(mapStateToProps)(TermsAndConditions);
