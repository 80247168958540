import { useEffect, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ActionDialog, FormField } from '../../../../../../components';
import { modifyDate, changeZone, localToUTCTime } from '../../../../../../shared/datetime';
import { useWizardFormField } from '../../../../../../shared/hooks';
import { isFullString, isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

const LocationEdit = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	const { open, onClose, onUpdateHub, updateHubDetails } = props;
	const { t } = useTranslation('general');

	const {
		success: updateLocationSuccess,
		loading: updateLocationLoading,
		error: updateLocationError,
	} = updateHubDetails;

	const locationName = useWizardFormField('', { minLength: 3, maxLength: 25 });

	const locationDescription = useWizardFormField('', { maxLength: 1000 });

	const [state] = useState({
		weekdays: props.location.openHours.map((openHour, index) => ({
			id: index,
			name: openHour.dayOfWeek,
			open:
				openHour.openStatus === 'open' ?
					changeZone(openHour.openHour)
				:	modifyDate(new Date(), { hours: 0, minutes: 0 }),
			close:
				openHour.openStatus === 'open' ?
					changeZone(openHour.closeHour)
				:	modifyDate(new Date(), { hours: 0, minutes: 0 }),
			isOpen: openHour.openStatus === 'open' || openHour.openStatus === 'openAllDay',
			openAllDay: openHour.openStatus === 'openAllDay',
		})),
	});

	useEffect(() => {
		if (props.location) {
			locationName.setValue(props.location.name);
			locationDescription.setValue(props.location?.description ? props.location?.description : '');
		}
	}, [props.location]);

	useEffect(() => {
		if (updateLocationSuccess) {
			onClose();
			handleReset();
		} else if (isObject(updateLocationError)) {
			enqueueSnackbar(updateLocationError?.message, { variant: 'error' });
		}
	}, [updateLocationLoading, updateLocationError]);

	const handleReset = () => {
		locationName.resetToInitialValue();
		locationDescription.resetToInitialValue();
	};

	const handleConfirm = () => {
		const newOpenHours = state.weekdays.map((weekday) => ({
			dayOfWeek: weekday.name.toLowerCase(),
			openStatus:
				weekday.openAllDay && weekday.isOpen ? 'openAllDay'
				: weekday.isOpen ? 'open'
				: 'closed',
			...(!weekday.openAllDay &&
				weekday.isOpen && {
					openTime: localToUTCTime(weekday.open).toISOString(),
					closeTime: localToUTCTime(weekday.close).toISOString(),
				}),
		}));
		const body = {
			name: locationName.value,
			newOpenHours: newOpenHours,
			latitude: props.location.latitude,
			longitude: props.location.longitude,
			address: props.location.address,
			description: locationDescription.value,
		};
		onUpdateHub(props.location.id, body);
	};

	const handleCloseDialog = () => {
		onClose();
		handleReset();
	};

	const isValid =
		!isFullString(locationName.value) ||
		((locationName.value === props.location.name || !isFullString(locationName.value)) &&
			locationDescription.value === props.location.description);

	return (
		<ActionDialog
			actionButtonProps={{
				text: updateLocationLoading ? <CircularProgress disableShrink size={24} /> : t('ui.update'),
				action: handleConfirm,
			}}
			handleClose={handleCloseDialog}
			loading={!locationName.isValid || isValid}
			open={open}
			title={t('views.locationDetail.locationInfo.edit.title')}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
				<FormField
					hideCharacterCounter={!locationName.hasFocus}
					label={t('views.locationDetail.locationInfo.locationName')}
					maxLength={25}
					name={'locationName'}
					required
					variable={locationName}
				/>
				<FormField
					maxLength={locationDescription.validationOptions.maxLength}
					maxRows={4}
					minRows={4}
					multiline
					name={'locationDescription'}
					variable={locationDescription}
					hideCharacterCounter={!locationDescription.hasFocus}
					// disabled={hasNfcTag}
					label={t('views.locationDetail.locationInfo.locationDescription')}
				/>
			</Box>
		</ActionDialog>
	);
};

LocationEdit.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onUpdateHub: PropTypes.func,

	updateHubDetails: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

LocationEdit.defaultProps = {
	open: false,
};

const mapStateToProps = (state) => {
	return {
		updateUser: state.details.updateUser,

		updateHubDetails: state.condition.updateHubDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateHub: (hubId, body) => dispatch(actions.updateHub(hubId, body)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationEdit);
