import { Card, CardHeader, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const SidePanel = (props) => {
	const { title, children, className } = props;
	const classes = useStyles();

	return (
		<Card className={className}>
			<CardHeader className={classes.cardHeader} title={title} />
			<CardContent>{children}</CardContent>
		</Card>
	);
};

SidePanel.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	children: PropTypes.node,
	// GLOBAL PROPS

	className: PropTypes.string,
};

export default SidePanel;
