import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
	root: {},
	arrowLeft: {
		cursor: 'pointer',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		},
	},
	editButton: {
		marginLeft: '8px',
	},
}));
