import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	sideCard: {
		'& .MuiBox-root ': {
			display: 'flex',
			flexDirection: 'column',
		},
		fullWidth: {
			display: 'inline',
			position: 'absolute',
		},
	},
	switch: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: '0.875rem',
	},
	divider: {
		margin: theme.spacing(2, 0, 0, 0),
	},
}));
