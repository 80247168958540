import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import { LoadingBar, StyledButton } from '../../../../../components';
import { isFullString } from '../../../../../shared/utility';

const Header = (props) => {
	const { className, title, date, name } = props;
	const location = useLocation();
	const navigate = useNavigate();

	const classes = useStyles();

	const handleBackButtonChange = () => {
		if (location?.state?.data?.userDetails) {
			navigate(-1);
		} else {
			navigate(`/financeManagement/personal/${date}/${title}/billing`);
		}
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{title}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				{!isFullString(name) ?
					<LoadingBar />
				:	name}
			</Typography>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,

	title: PropTypes.string,
	date: PropTypes.string,
	name: PropTypes.string,
};

export default Header;
