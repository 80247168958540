import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { AccessRulesOverviewLayout } from '~features/access';

import Page from '../page';

const AccessRulesPage = () => {
	const { t } = useTranslation('general');

	const [userInfo] = useAtom(userInfoAtom);

	return (
		<Page title={t('rules')}>
			<AccessRulesOverviewLayout />
		</Page>
	);
};

export default AccessRulesPage;
