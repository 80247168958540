import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	option: {
		border: `2px solid ${theme.palette.divider}`,
		borderRadius: theme.spacing(1),
		display: 'flex',
		alignItems: 'flex-start',
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: theme.palette.primary.main,
		},
	},
	optionRadio: {
		margin: -10,
	},
	optionDetails: {
		marginLeft: theme.spacing(2),
	},
	selectedOption: {
		borderColor: theme.palette.primary.main,
	},
}));
