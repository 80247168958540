import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../../components';
import { useMainContext } from '../../FleetManagement';
import { useStyles } from '../../style';

const ButtonCard = () => {
	const { onClose } = useMainContext();
	const classes = useStyles();
	const { t } = useTranslation('general');

	const buttons = [
		{
			name: 'closeDrawer',
			label: 'ui.button.inline.close',
			action: () => onClose(),
			visible: true,
			variant: 'inline-default',
			icon: <CloseIcon />,
		},
	];

	return (
		<div className={classes.buttonCard}>
			{buttons.map((button) =>
				button.visible ?
					<StyledButton
						key={button.label}
						onClick={button.action}
						size='small'
						startIcon={button.icon}
						variant={button.variant}
					>
						{t(button.label)}
					</StyledButton>
				:	null,
			)}
		</div>
	);
};

export default ButtonCard;
