import { useState } from 'react';

import { Avatar, ListItem, ListItemAvatar, ListItemText, Paper, Stack } from '@mui/material';
import Ajv from 'ajv';

import { FormContainer, FormContainerProps } from '~components';
import { NfcAutocomplete, NfcTag } from '~features/nfc';
import { BaseReference, IdReference } from '~interfaces';

import schema from './linkNfcTagToUserSchema.json';
import useUserData from '../../hooks/useUserData';
import { getInitials } from '../../utils/userUtils';

const ajv = new Ajv();
const validate = ajv.compile(schema);

interface LinkNfcTagToUserData {
	user: IdReference;
	nfcTag: NfcTag;
}

interface LinkNfcTagToUserForm extends Omit<FormContainerProps, 'onSubmit'> {
	userId: string;
	onSubmit?: (value: LinkNfcTagToUserData) => void;
}

const LinkNfcTagToUserForm = ({
	userId,
	onClose,
	onSubmit,
	...formContainerProps
}: LinkNfcTagToUserForm) => {
	const { data, isLoading, error } = useUserData(userId);

	const [valid, setValid] = useState(false);
	const [value, setValue] = useState<Partial<LinkNfcTagToUserData>>({
		user: {
			id: userId,
		},
	});

	const handleCloseClick = () => {
		setValue({});
		if (onClose) {
			onClose();
		}
	};

	const handleChange = (newValue: Partial<LinkNfcTagToUserData>) => {
		setValid(validate(newValue));
		setValue(newValue);
		console.debug(newValue, validate.errors);
	};

	return (
		<FormContainer
			{...formContainerProps}
			loading={isLoading}
			submitDisabled={!valid}
			onClose={handleCloseClick}
			onSubmit={() => onSubmit && onSubmit(value as LinkNfcTagToUserData)}
		>
			<Paper>
				<ListItem>
					<ListItemAvatar>
						<Avatar src={data.imageUrl}>{getInitials(data)}</Avatar>
					</ListItemAvatar>
					<ListItemText primary={data.label} secondary={data.email} />
				</ListItem>
			</Paper>
			<NfcAutocomplete
				value={value.nfcTag}
				organisation={data.organisation}
				enableInfiniteScroll
				onChange={(_, newValue) => handleChange({ ...value, nfcTag: newValue })}
				linkedFilter={false}
			/>
		</FormContainer>
	);
};

export default LinkNfcTagToUserForm;
