import { useState, useEffect } from 'react';

import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';

import { useStyles } from './style';
import { Table, DatePicker, SearchBar, EmptyState } from '../../../../../components';
import { modifyDate, commaTimeStrings } from '../../../../../shared/datetime';
import { useSearchComponent, usePagination, useBasicFilter } from '../../../../../shared/hooks';
import {
	isObject,
	isNull,
	isEmptyString,
	isEmptyArray,
	instanceLabel,
	capitalizeFirstCharacter,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const Activities = (props) => {
	const { id, onFetchItemActivities, itemActivities, itemDetails } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const [dates, setDates] = useState({ startDate: null, endDate: null });

	const [shouldSearch, setShouldSearch] = useState(false);

	const [shouldSort, setShouldSort] = useState(false);
	const [shouldFilter, setShouldFilter] = useState(false);
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const periodStart =
		isObject(dates.startDate) ?
			modifyDate(dates.startDate._d, { hours: 0, minutes: 0 }).toISOString()
		:	null;
	const periodEnd =
		isObject(dates.endDate) ?
			modifyDate(dates.endDate._d, { date: '+1', hours: 0, minutes: 0 }).toISOString()
		:	null;

	const pagination = usePagination('pageNumberActivities', 'pageSizeActivities');
	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch);

	const { data: itemDetailsData } = itemDetails;

	const activitiesTypeFilter = useBasicFilter('activitiesTypeFilter', 'all');

	const filterTypes = ['booking', 'unavailability', 'servicing'];

	const filters = {
		...(activitiesTypeFilter.value !== 'all' && { type: activitiesTypeFilter.value }),
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isNull(periodStart) && { dateAfter: periodStart }),
		...(!isNull(periodEnd) && { dateBefore: periodEnd }),
	};

	useEffect(() => {
		if (shouldSearch || pagination.fetch || shouldSort || shouldFilter || shouldDoInitialFetch) {
			if ((shouldFilter || shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			const idNumber = parseInt(id, 10);
			onFetchItemActivities(idNumber, pagination.page, filters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [shouldSearch, shouldFilter, periodStart, periodEnd, pagination.fetch, shouldDoInitialFetch]);

	const handleIDLink = (item) => {
		let pathName = '';
		if (item.activityType === 'booking') {
			pathName = `/booking/booking-detail/${item.id}/summary`;
		} else if (item.activityType === 'unavailability') {
			pathName = `/unavailability/${item.id}/summary`;
		} else if (item.activityType === 'servicing') {
			pathName = `/ticket-details/${item.id}`;
		} else {
			pathName = '#';
		}
		return (
			<Link
				className={classes.itemGroup}
				color='primary'
				component={RouterLink}
				to={pathName}
				state={{ from: location.pathname, label: t('ui.activity') }}
			>
				{item.id}
			</Link>
		);
	};

	const handleLink = (pathe, label) => {
		return (
			<Link
				className={classes.itemGroup}
				color='primary'
				component={RouterLink}
				to={pathe}
				state={{ from: location.pathname, label: t('ui.activity') }}
			>
				{label}
			</Link>
		);
	};

	const {
		data: activitiesData,
		loading: activitiesLoading,
		error: activitiesError,
	} = itemActivities;
	const activitiesReady = isObject(activitiesData) && !activitiesLoading && !activitiesError;

	const category = isObject(itemDetailsData) ? itemDetailsData.categoryReference.type : '';

	const tableHeaders = [
		{ name: 'id', content: 'ID' },
		{ name: 'itemInstance', content: instanceLabel(category) },
		{ name: 'user', content: t('views.ItemDetail.activities.user') },
		{ name: 'organisation', content: t('ui.organisation') },
		{ name: 'activity', content: t('ui.activity') },
		{ name: 'start', content: t('views.ItemDetail.activities.start') },
		{ name: 'end', content: t('views.ItemDetail.activities.end') },
	];

	const tableBody =
		activitiesReady && !activitiesLoading ?
			activitiesData.results.map((item) => [
				{ content: handleIDLink(item) },
				{
					content: handleLink(
						`/item-management/${id}/instance/${item.itemInstanceReference.id}/summary`,
						item.itemInstanceReference.name,
					),
				},
				{
					content: handleLink(
						`/user-management/users/${item.userReference.id}/summary`,
						item.userReference.name,
					),
				},
				{ content: item.userReference.organisationReference.name },
				{ content: capitalizeFirstCharacter(item.activityType) },
				{ content: commaTimeStrings(item.start) },
				{ content: item?.end && commaTimeStrings(item.end) },
			])
		:	Array(4)
				.fill(Array(tableHeaders.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const handleDatesChange = ({ startDate, endDate }) => {
		setDates({
			...dates,
			...(!isNull(startDate) && { startDate }),
			...(!isNull(endDate) && { endDate }),
		});
		if (startDate === null && endDate === null) {
			setDates({ startDate, endDate });
		}
		setShouldSort(true);
	};

	const [focused, setFocused] = useState(undefined);

	const handleFocusChange = (focusedInput) => {
		setFocused(focusedInput);
	};

	const handleFilterChange = (event, filterValue, setFilterValue) => {
		const value = event.target.value;
		if (value !== filterValue) {
			setFilterValue(value);
			setShouldFilter(true);
		}
	};

	const mainFilters = [
		{
			events: {
				onChange: (e) =>
					handleFilterChange(e, activitiesTypeFilter.value, activitiesTypeFilter.setValue),
			},
			value: activitiesTypeFilter.value,
			selectOptions: [
				{
					value: 'all',
					label: t('views.itemdetail.activities.filter.all'),
				},
			].concat(
				filterTypes.map((type) => ({
					value: type,
					label: t(`views.itemdetail.activities.filter.${type}`),
				})),
			),
		},
	];

	const handleClearFilters = () => {
		activitiesTypeFilter.setValue('all');
		setShouldFilter(true);
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			activitiesTypeFilter.value !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const itemActivityEmpty =
		isObject(activitiesData) && !activitiesLoading && isEmptyArray(activitiesData.results) ?
			showingInitialResults ?
				<EmptyState
					image={'booking'}
					subTitle={t('views.itemDetail.activities.table.empty.description.zeroInSystem')}
					title={t('views.itemDetail.activities.table.empty.title')}
				/>
			:	<EmptyState
					image={'booking'}
					subTitle={t('views.itemDetail.activities.table.empty.description.zeroMatching')}
					title={t('views.itemDetail.activities.table.empty.title')}
				/>
		:	null;

	return (
		<div className={classes.root}>
			{itemActivityEmpty && showingInitialResults ? null : (
				<SearchBar
					clearFilters={clearFilters}
					hasMainFilter
					hideMoreFilters
					mainFilters={mainFilters}
					periodFilter={
						<DatePicker
							end={dates.endDate}
							focused={focused}
							handleDatesChange={handleDatesChange}
							handleFocusChange={handleFocusChange}
							start={dates.startDate}
						/>
					}
					placeholder={t('ui.placeholders.search.generic')}
					searchEvents={search.events}
					searchValue={search.value}
				/>
			)}
			{itemActivityEmpty ?
				itemActivityEmpty
			:	<Table
					body={tableBody}
					cellStyle={classes.cellStyle}
					handlePageChange={pagination.pageNumberChange}
					header={tableHeaders}
					loading={activitiesLoading}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('ui.activity')}
					total={activitiesData ? activitiesData.total : 0}
				/>
			}
		</div>
	);
};

Activities.propTypes = {
	className: PropTypes.string,

	id: PropTypes.string,
	onFetchItemActivities: PropTypes.func,
	itemActivities: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchOrganisations: PropTypes.func,
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	itemDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		itemActivities: state.paged.itemActivities,
		organisationsList: state.paged.organisations,
		itemDetails: state.details.itemDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchItemActivities: (itemId, page, filters) =>
			dispatch(actions.fetchItemActivities(itemId, page, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
