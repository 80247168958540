import { useState } from 'react';

import { Card, Box, CardHeader, Divider, Tabs, Tab, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import TicketsList from './TicketsList';
import { isNumber } from '../../../../shared/utility';
import { useStyles } from '../../style';

const Tickets = (props) => {
	const { dashboardTicketsStatus, dashboardOrganisationFilter } = props;
	const { t } = useTranslation('general');
	const location = useLocation();

	const { data: dashboardTicketsStatusData } = dashboardTicketsStatus;

	const [tab, setTab] = useState(
		location?.state?.ticketStatus ? location?.state?.ticketStatus : 'open',
	);

	const classes = useStyles();

	const handleTabsChange = (event, value) => setTab(value);

	const tabs = [
		{ value: 'open', label: 'ui.status.open', status: dashboardTicketsStatusData?.open },
		{ value: 'pending', label: 'ui.status.pending', status: dashboardTicketsStatusData?.pending },
		{ value: 'planned', label: 'ui.status.planned', status: dashboardTicketsStatusData?.planned },
		{ value: 'ready', label: 'ui.status.ready', status: dashboardTicketsStatusData?.ready },
	];

	return (
		<Card className={classes.card}>
			<CardHeader title={t('ui.ticketing')} />
			<Divider />
			<Tabs
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab
						icon={
							isNumber(tab?.status) && tab?.status > 0 ?
								<Box className={classes.buble}>
									<Typography color='error'>{tab.status}</Typography>
								</Box>
							:	<Box minHeight='20px'></Box>
						}
						iconPosition='end'
						key={tab.value}
						label={t(tab.label)}
						value={tab.value}
					/>
				))}
			</Tabs>
			<Divider />
			{tab === 'open' && (
				<TicketsList dashboardOrganisationFilter={dashboardOrganisationFilter} status='open' />
			)}
			{tab === 'pending' && (
				<TicketsList dashboardOrganisationFilter={dashboardOrganisationFilter} status='pending' />
			)}
			{tab === 'planned' && (
				<TicketsList dashboardOrganisationFilter={dashboardOrganisationFilter} status='planned' />
			)}
			{tab === 'ready' && (
				<TicketsList dashboardOrganisationFilter={dashboardOrganisationFilter} status='ready' />
			)}
		</Card>
	);
};

Tickets.propTypes = {
	onDashboardTicketsStatus: PropTypes.func,
	dashboardOrganisationFilter: PropTypes.object,
	onResetState: PropTypes.func,
	dashboardTicketsStatus: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	dashboardTickets: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		dashboardTicketsStatus: state.details.dashboardTicketsStatus,
		dashboardTickets: state.paged.dashboardTickets,
	};
};

export default connect(mapStateToProps)(Tickets);
