import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './style';

const SelectedOrganisationCard = (props) => {
	const { className, name, handleClose, hasCloseButton } = props;
	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent className={classes.content}>
				<Typography variant='h5'>{name}</Typography>
			</CardContent>
			{hasCloseButton ?
				<span></span>
			:	<IconButton onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			}
		</Card>
	);
};

SelectedOrganisationCard.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	handleClose: PropTypes.func,
	hasCloseButton: PropTypes.bool,
};

export default SelectedOrganisationCard;
