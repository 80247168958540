import { Card, CardHeader, CardContent } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import { useStyles } from './style';
import { FormField, FormFieldLabel } from '../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../shared/hooks';

const BillingDetails = (props) => {
	const classes = useStyles();

	const {
		save,

		firstName: savedFirstName,
		lastName: savedLastName,
		contactPhoneNumber,
		contactEmail,
	} = props;
	const { t } = useTranslation('general');

	const firstName = useWizardFormField(savedFirstName || '', {
		required: true,
		minLength: 2,
		maxLength: 32,
	});
	useDebouncedWizardSave('firstName', firstName.value, firstName.isValid, save, 300);

	const lastName = useWizardFormField(savedLastName || '', {
		required: true,
		minLength: 2,
		maxLength: 32,
	});
	useDebouncedWizardSave('lastName', lastName.value, lastName.isValid, save, 300);

	const phoneNumber = useWizardFormField(contactPhoneNumber || '', { required: true });
	useDebouncedWizardSave('contactPhoneNumber', phoneNumber.value, phoneNumber.isValid, save, 300);

	const email = useWizardFormField(contactEmail || '', {
		required: true,
		isEmail: true,
		maxLength: 64,
	});
	useDebouncedWizardSave('contactEmail', email.value, email.isValid, save, 300);

	return (
		<>
			<Header />
			<Card className={clsx(classes.root, classes.wizardSection)}>
				<CardHeader
					title={t('views.register.organisation.contactInfo.title')}
					titleTypographyProps={{
						variant: 'h3',
					}}
				/>
				<CardContent>
					<form>
						<div className={classes.formGroup}>
							<div className={classes.fieldGroup}>
								<div className={classes.fieldGroupItem}>
									<FormFieldLabel label={t('ui.label.firstName')} required>
										<FormField maxLength={32} name='firstName' variable={firstName} />
									</FormFieldLabel>
								</div>
								<div className={classes.fieldGroupItem}>
									<FormFieldLabel label={t('ui.label.lastName')} required>
										<FormField maxLength={32} name='lastName' variable={lastName} />
									</FormFieldLabel>
								</div>
							</div>
						</div>
						<div className={classes.formGroup}>
							<FormFieldLabel label={t('ui.label.phoneNumber')} required>
								<FormField name='phoneNumber' variable={phoneNumber} />
							</FormFieldLabel>
						</div>
						<div className={classes.formGroup}>
							<FormFieldLabel label={t('ui.label.email')} required>
								<FormField maxLength={64} name='contactEmail' variable={email} />
							</FormFieldLabel>
						</div>
					</form>
				</CardContent>
			</Card>
		</>
	);
};

BillingDetails.propTypes = {
	save: PropTypes.func,

	firstName: PropTypes.string,
	lastName: PropTypes.string,
	contactPhoneNumber: PropTypes.string,
	contactEmail: PropTypes.string,
};

export default BillingDetails;
