import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		paddingLeft: theme.spacing(16),
	},
	cardContainer: {
		display: 'flex',
		marginRight: theme.spacing(3),
		width: '100%',
	},
	cardContent: {
		padding: theme.spacing(4, 4.5),
		width: '100%',
	},
	languageSelector: {
		padding: theme.spacing(4, 0),
	},
	fileHeading: {
		padding: theme.spacing(2, 0, 4, 0),
	},
	uploadButton: {
		position: 'relative',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',

		fontSize: '1rem',
		fontWeight: '300',
		textAlign: 'center',

		height: '13.5rem',
		padding: theme.spacing(3),

		cursor: 'pointer',

		border: `1px dashed ${colors.grey[200]}`,

		'&:hover': {
			borderColor: colors.grey[400],
		},

		'&.full-width': {
			height: '13rem',
		},
	},
	uploadButtonWarning: {
		borderColor: colors.red[500],
	},
	iconArrow: {
		fontSize: '60px',
	},
	uploadedFile: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	fileName: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	description: {
		marginRight: theme.spacing(1),
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		padding: theme.spacing(4, 0),
	},
	addButton: {
		alignContent: 'flex-end',
		padding: theme.spacing(1, 8),
	},
}));
