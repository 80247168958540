import axios from 'axios';
import { User } from 'oidc-client-ts';

// Make sure there are no trailing slashes
const identityAuthority = import.meta.env.VITE_IDENTITY_AUTHORITY.replace(/\/$/, '');
const oidcStorageString = `oidc.user:${identityAuthority}:${import.meta.env.VITE_IDENTITY_CLIENT_ID}`;

// Add a request interceptor
axios.interceptors.request.use(
	(config) => {
		if (config.url?.includes(import.meta.env.VITE_TOPOLOGY_API_URL)) {
			// Add the access token if the destination is the Topology backend
			const oidcStorage = localStorage.getItem(oidcStorageString);
			if (oidcStorage) {
				const user = User.fromStorageString(oidcStorage);
				config.headers['Authorization'] = `Bearer ${user.access_token}`;
			}
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

// Add a response interceptor
axios.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	(error) => {
		if (axios.isCancel(error)) {
			console.debug('Request cancelled', error);
		}

		return Promise.reject(error);
	},
);
