import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	iconPreview: {
		display: 'flex',
		justifyContent: 'center',
		minWidth: 125,
		border: '1px solid rgba(0, 0, 0, .23)',
		borderRadius: 4,
		padding: '11.5px 38.42px',
		color: colors.grey[400],
	},
}));
