import React, { Fragment, Suspense } from 'react';

import { Box, LinearProgress, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { LoadingPage } from '~pages';

import AuthenticationHeader from './headers/authenticationHeader';

interface AuthenticationLayoutProps {
	disablePaper?: boolean;
}

const AuthenticationLayout = ({ disablePaper = false }: AuthenticationLayoutProps) => {
	const theme = useTheme();
	const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));

	const content = (
		<Suspense fallback={<LoadingPage />}>
			<Outlet />
		</Suspense>
	);

	return (
		<Box display='flex' flexDirection='column' width={1} height={1}>
			<AuthenticationHeader />
			<Box display='flex' height={1} flexGrow={1} p={2} alignItems='center' justifyContent='center'>
				{largerThanMd && !disablePaper ?
					<Paper elevation={1} sx={{ p: 3 }}>
						{content}
					</Paper>
				:	content}
			</Box>
		</Box>
	);

	return (
		<Box display='flex' flexDirection='column' width={1} height={1}>
			<AuthenticationHeader />
			<Box
				display='flex'
				flexGrow={1}
				p={2}
				alignItems='center'
				justifyContent='center'
				overflow='hidden'
				zIndex={100}
			>
				<Box display='flex' flexGrow={1} zIndex={90} flexDirection='column'>
					{largerThanMd && !disablePaper ?
						<Paper elevation={1} sx={{ minHeight: 180, minWidth: 250, p: 3 }}>
							{content}
						</Paper>
					:	content}
				</Box>
			</Box>
		</Box>
	);
};

export default AuthenticationLayout;
