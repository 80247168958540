import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	buttonSpacing: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	itemGroup: {
		...theme.typography.link1,
	},
	drawer: {
		width: 450,
		maxWidth: '100%',
		padding: theme.spacing(2),
	},
	header: {
		margin: theme.spacing(4, 0, 3, 0),
	},
	itemInstancesBox: {
		border: 'solid 1px #dedede',
		borderRadius: '8px',
	},
	cellStyle: {
		whiteSpace: 'nowrap',
	},
	notAvailable: {
		color: theme.palette.error.main,
	},
}));
