import { useState, useEffect } from 'react';

import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StopIcon from '@mui/icons-material/Stop';
import { Box, Paper, Typography, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useParams } from 'react-router-dom';

import { useStyles } from './style';
import { Table, SearchBar, Label, GenericMoreButton, DatePicker } from '../../../components';
import { commaTimeStrings } from '../../../shared/datetime';
import {
	useSearchComponent,
	useBasicFilter,
	useComplexFilter,
	usePeriodFilter,
	usePagination,
} from '../../../shared/hooks';
import {
	isObject,
	isEmptyArray,
	isEmptyString,
	isFullArray,
	isUndefined,
	isNull,
	isInteger,
	isArray,
	isFullString,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import CancelUnabailabilitysDialog from '../../Actions/Dialogs/CancelUnabailabilitysDialog';
import StopUnabailabilitysDialog from '../../Actions/Dialogs/StopUnabailabilitysDialog';
import SideDrawer from '../../Planboard/SideDrawer';

const Unavailabilitys = (props) => {
	const {
		match,
		onFetchUnavailabilities,
		fetchUnavailabilities,
		cancelUnavailabilities,
		addUnavailability,
		updateUnavailability,
		stopUnavailabilityNow,
		organisationsList,
		onFetchOrganisations,
		onFetchCategories,
		categoriesList,
		onResetState,
	} = props;
	const { t } = useTranslation('general');

	const { id } = useParams();

	const { data: fetchUnavailabilitiesData, loading: fetchUnavailabilitiesLoading } =
		fetchUnavailabilities;

	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = categoriesList;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	const { data: cancelUnavailabilityData } = cancelUnavailabilities;

	const { data: addUnavailabilityData } = addUnavailability;

	const { data: updateUnavailabilityData } = updateUnavailability;

	const { data: stopUnavailabilityNowData } = stopUnavailabilityNow;

	const isPageEmpty =
		isObject(fetchUnavailabilitiesData) &&
		(isEmptyArray(fetchUnavailabilitiesData.results) ||
			isUndefined(fetchUnavailabilitiesData.results));
	const isPageFull =
		isObject(fetchUnavailabilitiesData) && isFullArray(fetchUnavailabilitiesData.results);

	const classes = useStyles();

	const [openAddUnavailability, setOpenAddUnavailability] = useState(false);
	const [presetUnavailability, setPresetUnavailability] = useState(null);

	const [openCancelUnavailability, setOpenCancelUnavailability] = useState(false);

	const [stopUnavailability, setStopUnavailability] = useState(false);
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [unavailabilityId, setUnavailabilityId] = useState(null);
	const [unavailabilityData, setUnavailabilityData] = useState(null);
	const [shouldFilter, setShouldFilter] = useState(false);
	const [shouldSort, setShouldSort] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);
	const [order, setOrder] = useState('asc');
	const [loading, setLoading] = useState(true);
	const [orderDescending, setOrderDescending] = useState(true);
	const [sortByProperty, setSortByProperty] = useState('');

	const unavailabilityCategoryFilter = useBasicFilter('unavailabilityCategoryFilter');

	const unavailabilityStatusFilter = useBasicFilter('unavailabilityStatusFilter');

	const unavailabilityOrganisationFilter = useComplexFilter(
		'unavailabilityOrganisationNameFilter',
		'unavailabilityOrganisationIdFilter',
	);

	const unavailabilityPerioFilter = usePeriodFilter();

	const pagination = usePagination('pageNumberMaintenance', 'pageSizeMaintenance');

	const search = useSearchComponent(
		pagination.setPageNumber,
		setShouldSearch,
		'searchUnavailabilitys',
	);

	const periodStart =
		isObject(unavailabilityPerioFilter.dates.startDate) ?
			unavailabilityPerioFilter.dates.startDate.toISOString()
		:	null;
	const periodEnd =
		isObject(unavailabilityPerioFilter.dates.endDate) ?
			unavailabilityPerioFilter.dates.endDate.toISOString()
		:	null;

	const filters = {
		...(isFullString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortByProperty) && { sortBy: sortByProperty }),
		...(!isNull(periodStart) && { dateAfter: periodStart }),
		...(!isNull(periodEnd) && { dateBefore: periodEnd }),
		...(unavailabilityOrganisationFilter.valueId !== 'all' && {
			organisationId: unavailabilityOrganisationFilter.valueId,
		}),
		...(unavailabilityCategoryFilter.value !== 'all' && {
			categoryId: unavailabilityCategoryFilter.value,
		}),
		...(unavailabilityStatusFilter.value !== 'all' && { status: unavailabilityStatusFilter.value }),
		orderDescending,
	};

	useEffect(() => {
		if (
			!fetchUnavailabilitiesLoading &&
			(pagination.fetch ||
				shouldDoInitialFetch ||
				shouldSearch ||
				shouldSort ||
				shouldFilter ||
				isObject(stopUnavailabilityNowData) ||
				isObject(cancelUnavailabilityData) ||
				isObject(addUnavailabilityData) ||
				isObject(updateUnavailabilityData) ||
				periodStart ||
				periodEnd)
		) {
			onFetchUnavailabilities(pagination.page, filters, id);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (isObject(updateUnavailabilityData)) {
			onResetState('updateUnavailability');
		}
	}, [
		id,
		shouldSearch,
		pagination,
		order,
		shouldFilter,
		cancelUnavailabilityData,
		addUnavailabilityData,
		updateUnavailabilityData,
		stopUnavailabilityNowData,
		periodStart,
		periodEnd,
	]);

	useEffect(() => {
		if (!categoriesLoading && isNull(categoriesData)) {
			onFetchCategories();
		}
	}, [categoriesData]);

	useEffect(() => {
		if (isObject(fetchUnavailabilitiesData) && !fetchUnavailabilitiesLoading) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [fetchUnavailabilitiesLoading]);

	const handleRequestSort = (property) => {
		const isDesc = sortByProperty === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortByProperty(property);
		setShouldSort(true);
	};

	const unavailabilityStatus = {
		upcoming: 'default',
		cancelled: 'error',
		active: 'success',
		completed: 'disabled',
	};

	const handleLinks = (path, label) => (
		<Link
			color='primary'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname }}
			variant='h6'
		>
			{label}
		</Link>
	);

	const handleStatus = (status) => (
		<Label shape='rounded' type={unavailabilityStatus[status]}>
			{t(`ui.status.${status}`)}
		</Label>
	);

	const handleCancelUnavailability = (item) => {
		setOpenCancelUnavailability(true);
		setUnavailabilityId(item.id);
	};

	const handleEditUnavailability = (unavailability) => {
		setOpenAddUnavailability(true);
		setPresetUnavailability({
			unavailabilityId: unavailability.id,
			itemId: unavailability.itemInstance.itemReference.id,
			itemSelected: true,
		});
	};

	const handleStopUnavailability = (unavailability) => {
		setUnavailabilityData(unavailability);
		setStopUnavailability(true);
	};

	const createMenuItems = (item) => [
		{
			action: () => handleEditUnavailability(item),
			icon: <EditIcon />,
			text: t('ui.edit'),
		},
		...(item.status === 'upcoming' ?
			[
				{
					action: () => handleCancelUnavailability(item),
					icon: <DoDisturbOnOutlinedIcon />,
					text: t('ui.button.cancelUnavailability'),
					isRed: true,
				},
			]
		:	[]),
		...(item.status === 'active' ?
			[
				{
					action: () => handleStopUnavailability(item),
					icon: <StopIcon />,
					text: t('ui.button.stopUnavailability'),
					isRed: true,
				},
			]
		:	[]),
	];

	const handleMoreButton = (item) =>
		item.status === 'active' || item.status === 'upcoming' ?
			<div>
				{' '}
				<GenericMoreButton menuItems={createMenuItems(item)} />
			</div>
		:	<div></div>;

	const handleAddUnavailability = () => {
		setOpenAddUnavailability(true);
		setPresetUnavailability(undefined);
	};

	const handleCloseDrawer = () => {
		setOpenAddUnavailability(false);
	};

	const handleOrganisationFilter = (item) => {
		if (item.id === unavailabilityOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			unavailabilityOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			unavailabilityOrganisationFilter.setValueName('');
		}
		unavailabilityOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const handleFilters = (event, filter) => {
		const value = event.target.value;
		if (value !== filter.value) {
			filter.setValue(value);
			setShouldFilter(true);
		}
	};

	const statusFilterValue = [
		{ value: 'all', label: t('ui.filter.status.all') },
		{ value: 'active', label: t('ui.status.active') },
		{ value: 'upcoming', label: t('ui.status.upcoming') },
		{ value: 'cancelled', label: t('ui.status.cancelled') },
		{ value: 'completed', label: t('ui.status.completed') },
	];

	const mainFilters = [
		{
			isSelectWithLazyLoading: true,
			events: {
				onChange: handleOrganisationFilter,
				searchHandle: unavailabilityOrganisationFilter.setValueName,
				filter: 'verified',
			},
			value: unavailabilityOrganisationFilter.valueName,
			dataList: organisationsList,
			placeholder: t('ui.filter.organisations.all'),
			onFetchData: onFetchOrganisations,
			listType: 'organisations',
			defaultListItem: {
				id: '',
				name: t('ui.filter.organisations.all'),
			},
		},
		{
			events: { onChange: (e) => handleFilters(e, unavailabilityStatusFilter) },
			value: unavailabilityStatusFilter.value,
			selectOptions: [].concat(statusFilterValue),
		},
		{
			events: { onChange: (e) => handleFilters(e, unavailabilityCategoryFilter) },
			value: unavailabilityCategoryFilter.value,
			selectOptions: [
				{ value: 'all', label: t('views.assets.items.mainFilters.category.all') },
			].concat(
				categoriesReady ?
					categoriesData.map((category) => ({
						value: category.id.toString(),
						label: category.name,
					}))
				:	[],
			),
		},
	];

	const handleClearFilters = () => {
		unavailabilityCategoryFilter.setValue('all');
		unavailabilityStatusFilter.setValue('all');
		unavailabilityOrganisationFilter.setValueName('');
		unavailabilityOrganisationFilter.setValueId('all');
		unavailabilityPerioFilter.setDates({ startDate: null, endDate: null });
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			unavailabilityCategoryFilter.value !== 'all' ||
			unavailabilityStatusFilter.value !== 'all' ||
			periodStart ||
			periodEnd ||
			unavailabilityOrganisationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const extraButtons = [
		{
			text: t('ui.button.addUnavailability'),
			onClick: handleAddUnavailability,
			variant: 'contained-primary',
		},
	];

	const cancelUnavailabilityProps = {
		openCancelUnavailability,
		setOpenCancelUnavailability,
		unavailabilityId,
	};

	const stopUnabailabilitysProps = {
		end: unavailabilityData?.end,
		open: stopUnavailability,
		data: unavailabilityData,
		setStopUnavailability: setStopUnavailability,
		start: unavailabilityData?.start,
	};

	const tableHeaders =
		!loading && isPageEmpty ?
			[]
		:	[
				{ name: 'id', content: 'ID', hasSorting: true },
				{ name: 'startDate', content: t('views.ItemDetail.activities.start'), hasSorting: true },
				{ name: 'endDate', content: t('views.ItemDetail.activities.end'), hasSorting: true },
				{ name: 'category', content: t('ui.label.category') },
				{ name: 'itemName', content: t('ui.label.itemGroup') },
				{ name: 'item', content: t('ui.label.item') },
				{ name: 'provider', content: t('ui.provider') },
				{ name: 'serviceCompany', content: t('ui.serviceCompany.label') },
				{ name: 'status', content: t('ui.label.status') },
				{ name: '', content: '' },
			];

	const loadingBody =
		loading ?
			Array(6)
				.fill(Array(10).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const tableBody =
		!loading && isPageFull ?
			fetchUnavailabilitiesData.results.map((unavailability) => [
				{
					content: handleLinks(`/unavailability/${unavailability.id}/summary`, unavailability.id),
				},
				{ content: commaTimeStrings(unavailability.start) },
				{ content: commaTimeStrings(unavailability.end) },
				{ content: unavailability.itemInstance.itemReference.categoryReference.name },
				{ content: unavailability.itemInstance.itemReference.name },
				{ content: unavailability.itemInstance.name },
				{ content: unavailability.organisationReference.name },
				{ content: unavailability.itemInstance.itemReference?.serviceProviderReference?.name },
				{ content: handleStatus(unavailability.status) },
				{ content: handleMoreButton(unavailability) },
			])
		:	null;

	const emptyBody =
		!loading && isPageEmpty ?
			[
				[
					{
						content: (
							<Paper className={classes.tipCard}>
								<InfoOutlinedIcon className={classes.icon} fontSize='small' />
								<Typography className={classes.tip}>
									{' '}
									{t('views.maintenance.unavailabilitys.emptyTable')}
								</Typography>
							</Paper>
						),
					},
				],
			]
		:	null;

	const handlePFilter = () => (
		<DatePicker
			end={unavailabilityPerioFilter.dates.endDate}
			focused={unavailabilityPerioFilter.focused}
			handleDatesChange={unavailabilityPerioFilter.datesChange}
			handleFocusChange={unavailabilityPerioFilter.focusAction}
			start={unavailabilityPerioFilter.dates.startDate}
		/>
	);

	return (
		<>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons
				hasMainFilter
				mainFilters={mainFilters}
				periodFilter={handlePFilter()}
				placeholder={t('ui.placeholders.search.generic')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			<Box pb={3}>
				<Table
					body={loadingBody || emptyBody || tableBody}
					cellStyle={classes.cellStyle}
					data={isPageFull ? fetchUnavailabilitiesData.results : []}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeaders}
					isNotPaginate={loading || isPageEmpty || !isPageFull}
					loading={fetchUnavailabilitiesLoading}
					order={order}
					orderBy={sortByProperty}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('views.maintenance.unavailabilitys.table.title')}
					total={fetchUnavailabilitiesData ? fetchUnavailabilitiesData.total : 0}
				/>
			</Box>
			<SideDrawer
				isUnavailability={true}
				onClose={handleCloseDrawer}
				open={openAddUnavailability}
				presetData={presetUnavailability}
				setPresetBookingData={setPresetUnavailability}
			/>
			{openCancelUnavailability ?
				<CancelUnabailabilitysDialog {...cancelUnavailabilityProps} />
			:	null}
			{stopUnavailability ?
				<StopUnabailabilitysDialog {...stopUnabailabilitysProps} />
			:	null}
		</>
	);
};

Unavailabilitys.propTypes = {
	onFetchUnavailabilities: PropTypes.func,
	onFetchOrganisations: PropTypes.func,
	onFetchCategories: PropTypes.func,
	onResetState: PropTypes.func,
	fetchUnavailabilities: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	cancelUnavailabilities: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	addUnavailability: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updateUnavailability: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	stopUnavailabilityNow: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	categoriesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		fetchUnavailabilities: state.paged.fetchUnavailabilities,
		cancelUnavailabilities: state.details.cancelUnavailabilities,
		addUnavailability: state.details.addUnavailability,
		updateUnavailability: state.details.updateUnavailability,
		stopUnavailabilityNow: state.details.stopUnavailability,
		organisationsList: state.paged.organisations,
		categoriesList: state.list.categories,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUnavailabilities: (page, filters, concat) =>
			dispatch(actions.fetchUnavailabilities(page, filters, concat)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchCategories: () => dispatch(actions.fetchCategories()),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Unavailabilitys);
