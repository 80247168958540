import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
	return {
		root: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',

			'& .fc-event': {
				height: 40,
				borderRadius: 4,
				marginTop: 8,
				backgroundColor: theme.palette.info.light,
				color: theme.palette.info.main,
				border: 'none',

				'& .fc-event-time': {
					...theme.typography.h6,
					fontSize: 11,
					fontWeight: 500,
				},

				'&$obscuredBooking': {
					backgroundColor: colors.grey[200],
					color: colors.grey[700],
					padding: 0,

					'&:hover': {
						cursor: 'default',
					},
				},

				'&$overtimeBooking': {
					backgroundColor: theme.palette.error.light,
					color: theme.palette.error.dark,
					padding: 0,
				},

				'&$obscuredOvertimeBooking': {
					backgroundColor: theme.palette.error.light,
					color: theme.palette.error.dark,
					padding: 0,

					'&:hover': {
						cursor: 'default',
					},
				},

				'&$bufferPeriod': {
					padding: 0,
					backgroundImage:
						'/* tint image */ linear-gradient(to right, rgba(228, 238, 251, 0.95), rgba(228, 238, 251, 0.95)), /* checkered effect */ linear-gradient(to right, black 50%, white 50%), linear-gradient(to bottom, black 50%, white 50%)',
					backgroundSize: theme.spacing(0.75, 0.75),
					backgroundBlendMode: 'normal, difference, normal',
					zIndex: -1,
					top: '0 !important',
					'&:hover': {
						cursor: 'default',
					},
				},

				'&$obscuredBufferPeriod': {
					padding: 0,
					backgroundImage:
						'/* tint image */ linear-gradient(to right, rgba(238, 238, 238, 0.95), rgba(238, 238, 238, 0.95)), /* checkered effect */ linear-gradient(to right, black 50%, white 50%), linear-gradient(to bottom, black 50%, white 50%)',
					backgroundSize: theme.spacing(0.75, 0.75),
					backgroundBlendMode: 'normal, difference, normal',
					zIndex: -1,
					top: '0 !important',
					'&:hover': {
						cursor: 'default',
					},
				},

				'&$overtimeBufferPeriod': {
					padding: 0,
					backgroundImage:
						'/* tint image */ linear-gradient(to right, rgba(255, 247, 239, 0.95), rgba(255, 247, 239, 0.95)), /* checkered effect */ linear-gradient(to right, black 50%, white 50%), linear-gradient(to bottom, black 50%, white 50%)',
					backgroundSize: theme.spacing(0.75, 0.75),
					backgroundBlendMode: 'normal, difference, normal',
					zIndex: -1,
					top: '0 !important',
					'&:hover': {
						cursor: 'default',
					},
				},

				'&$obscuredOvertimeBufferPeriod': {
					padding: 0,
					backgroundImage:
						'/* tint image */ linear-gradient(to right, rgba(255, 247, 239, 0.95), rgba(255, 247, 239, 0.95)), /* checkered effect */ linear-gradient(to right, black 50%, white 50%), linear-gradient(to bottom, black 50%, white 50%)',
					backgroundSize: theme.spacing(0.75, 0.75),
					backgroundBlendMode: 'normal, difference, normal',
					zIndex: -1,
					top: '0 !important',
					'&:hover': {
						cursor: 'default',
					},
				},
				'&$unavailability': {
					backgroundColor: '#f9edff',
					color: theme.palette.error.dark,
					padding: 0,
				},
			},

			'& .fc-timeline-event.fc-not-end:after': {
				position: 'absolute',
				top: 4,
				right: 4,
			},

			'& .fc-timeline-event.fc-not-start:before': {
				position: 'absolute',
				top: 4,
				right: 10,
			},

			'& .active': {
				'& .fc-cell-text': {
					color: theme.palette.primary.main,
				},
			},
		},
		pageContent: {
			flex: 1,
			position: 'relative',
			//overflow: 'auto',
		},
		fullCalendarContainer: {
			//overflow: 'auto',
			position: 'absolute',
			top: 0,
			bottom: 0,
		},
		emptyState: {
			height: '100%',
		},
		chart: {
			height: '59px',
			marginRight: '-11px',
			marginLeft: '-6px',
		},
		obscuredBooking: {},
		overtimeBooking: {},
		obscuredOvertimeBooking: {},
		bufferPeriod: {},
		obscuredBufferPeriod: {},
		overtimeBufferPeriod: {},
		obscuredOvertimeBufferPeriod: {},
		unavailability: {},
	};
});
