import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import BookingsFinance from './BookingsFinance';
import Header from './Header';
import { useStyles } from './style';
import UserBookings from './UserBookings';
import { Page } from '../../../../components';

const Bookings = (props) => {
	const { t } = useTranslation('general');
	const location = useLocation();

	const { date, title, name, id } = useParams();
	const classes = useStyles();

	return (
		<Page className={classes.root} title={t('ui.finance')}>
			<Header date={date} id={id} name={name} title={title} />
			<Box pt={3}>
				<BookingsFinance data={location.state.data} date={date} id={id} title={title} />
			</Box>
			<UserBookings date={date} id={id} name={name} title={title} />
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		revenues: state.paged.revenues,
	};
};

export default connect(mapStateToProps, null)(Bookings);
