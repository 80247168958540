import path from 'path';

import { Card, CardContent, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { pagePathSegments, pagePaths } from '~constants';

import { useStyles } from './style';
import OrganisationIcon from '../../../assets/icons/ic-create-organisation.svg';
import { StyledButton } from '../../../components';

/**
 * This is to show that an admin already has an organisation
 * and doesn't need to create another one
 * Seems a bit overkill, when this page is hit, we can just
 * redirect to the home page
 * @param props
 * @returns
 * @deprecated
 */
const AdminRedirect = (props) => {
	const { t } = useTranslation('general');
	const auth = useAuth();
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent className={classes.content}>
				<img
					alt='organisation icon'
					className={classes.icon}
					height='91'
					src={OrganisationIcon}
					width='91'
				/>
				<Typography className={classes.title} variant='h4'>
					{t('views.register.organisation.adminRedirect.title')}
				</Typography>
				<Typography className={classes.paragraph}>
					{t('views.register.organisation.adminRedirect.subtitle')}
				</Typography>
				<Typography className={classes.paragraph}>
					{t('views.register.organisation.adminRedirect.signOutOrContinue')}
				</Typography>
				<Box className={classes.contact}>
					<StyledButton
						className={classes.button}
						onClick={() => navigate(`/${pagePaths.AuthLogout}`)}
						variant='contained-tertiary'
					>
						{t('ui.button.contained.signout')}
					</StyledButton>
					<StyledButton className={classes.button} href={'/'} variant='contained-primary'>
						{t('ui.button.contained.continue')}
					</StyledButton>
				</Box>
			</CardContent>
		</Card>
	);
};

AdminRedirect.propTypes = {};

export default AdminRedirect;
