import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		width: '100%',

		'& .isUnavailable': {
			color: theme.palette.error.main,
		},

		'& .fc-view-harness *': {
			boxSizing: 'border-box',
		},

		'& .fc-view-harness > .fc-resource-timeline': {
			overflow: 'auto',
			height: 'calc(100% + 1px)',
		},
		'& .fc-resource-timeline-divider': {
			border: 0,
		},

		'& .fc-theme-standard td': {
			borderColor: theme.palette.divider,
			overflow: 'hidden',
		},

		'& .unavailable-icon': {
			display: 'flex',
			alignItems: 'center',
			color: theme.palette.error.main,
		},

		'& .fc-direction-ltr .fc-timeline-slot-lane': {
			border: 0,
			'& div': {
				height: '40.9943px !important',
			},
		},

		'&.fc-timeline-slot.fc-timeline-slot-label': {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},

		'& .fc-scrollgrid-section-body tr': {
			height: 60,
		},

		'& .fc-datagrid-header': {
			'& .fc-datagrid-cell': {
				...theme.typography.h6,
				border: 'none',
			},
		},

		'& .fc .fc-scrollgrid-section-liquid > td': {
			...theme.typography.h6,
			border: 'none',
			width: '100%',
		},

		'& .fc-time-area.fc-widget-header': {
			position: 'sticky',
			top: -1,
			zIndex: 9999,

			'& .fc-scroller-clip': {
				backgroundColor: theme.palette.common.white,
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
		},

		'& .fc-ltr .fc-timeline-event': {
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
		},

		'& .fc-timeline-event.fc-not-end:after': {
			position: 'absolute',
			top: 4,
			right: 4,
		},

		'& .fc-timeline-event.fc-not-start:before': {
			position: 'absolute',
			top: 4,
			right: 10,
		},

		'& .active': {
			'& .fc-cell-text': {
				color: theme.palette.primary.main,
			},
		},

		//Resources Styling

		'&.fc-datagrid-cell.fc-resource': {
			paddingTop: 0,
		},

		'&.fc-datagrid-cell-frame': {
			display: 'flex',
			alignItems: 'center',
		},

		'& .fc-datagrid-cell-main': {
			...theme.typography.h6,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
			padding: theme.spacing(0.5, 1),
		},
		'& .fc-datagrid-cell-cushion': {
			display: 'flex',
			flexDirection: 'row-reverse',
			justifyContent: 'space-between',
			width: '100%',
		},

		'& .fc-datagrid-expander-placeholder': {
			display: 'none !important',
		},

		'& .fc-direction-ltr .fc-datagrid-expander': {
			display: 'flex',
			flexDirection: 'row-reverse',
			alignItems: 'center',
			opacity: 1,

			'& .fc-icon': {
				color: theme.palette.primary.main,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				margin: 0,
				padding: 0,
			},

			'& .fc-icon-minus-square': {
				'&:before': {
					fontFamily: 'Material Icons !important',
					content: '"expand_more"',
					fontSize: 28,
					opacity: 0.65,
					transition: 'all 0.2s ease-out',
					transform: 'rotate(180deg)',
				},
			},

			'& .fc-icon-plus-square': {
				'&:before': {
					fontFamily: 'Material Icons !important',
					content: '"expand_more"',
					fontSize: 28,
					opacity: 0.65,
					transition: 'all 0.2s ease-out',
					transform: 'rotate(0)',
				},
			},
		},
	},
	events: {
		'& .fc-event-main': {
			color: theme.palette.info.main,
			flexDirection: 'column',
			padding: theme.spacing(0, 1),
			textWrap: 'wrap',

			'& .infoPopupHoverSpan': {
				position: 'absolute',
				width: '100%',
				height: '100%',
				top: 0,
				left: 0,
			},
		},

		'&:hover': {
			color: theme.palette.info.main,
			cursor: 'pointer',
		},

		'& .fc-event-title-container': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			overflow: 'hidden',
		},

		'& .fc-event-title': {
			...theme.typography.body1,
			fontSize: 11,
			marginTop: -3,
			minWidth: 'fit-content',
		},
	},
	resourceText: {
		display: 'flex',
		flexDirection: 'column',

		'& span': {
			...theme.typography.body2,
		},
	},
	tableLink: {
		...theme.typography.h6,
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	booking: {
		overflow: 'hidden',
	},
	loadingSpan: {
		width: '100%',
	},
	unclickable: {
		cursor: 'default !important',
	},
	overlap: {
		top: '0px !important',
	},
	slotLabel: {
		...theme.typography.h6,
		border: 'none',
	},
	chart: {
		height: '59px',
		marginRight: '-11px',
		marginLeft: '-6px',
	},

	//icons
	bookingIcon: {
		...theme.typography.h6,
		fontSize: '1rem',
	},
	warningIcon: {
		color: theme.palette.error.main,
	},
	unavailability: {
		fontWeight: 400,
		fontSize: '11px',
		letterSpacing: '-0.04px',
		lineHeight: '18px',
	},
}));
