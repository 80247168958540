import {
	ArrowDropUp as ArrowDropUpIcon,
	ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import { TextField, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyles } from '../style';

const Input = (props) => {
	const { openPopper, inputRef, onChange, onClick, loading, value, placeholder, onKeyUp } = props;

	const classes = useStyles();

	const handleAdornment = () => {
		if (loading) {
			return <CircularProgress className={classes.endAdornment} color='inherit' size={20} />;
		}
		if (openPopper) {
			return <ArrowDropUpIcon className={classes.endAdornment} />;
		}
		return <ArrowDropDownIcon className={classes.endAdornment} />;
	};

	return (
		<form autoComplete='off'>
			<TextField
				InputLabelProps={{ shrink: true }}
				fullWidth
				inputRef={inputRef}
				onChange={onChange}
				placeholder={placeholder}
				onClick={onClick}
				onKeyUp={onKeyUp}
				style={{ zIndex: 1 }}
				value={value || ''}
				variant='outlined'
				InputProps={{
					endAdornment: handleAdornment(),
					classes: { root: classes.inputContainer, input: classes.input },
				}}
			/>
		</form>
	);
};

Input.defaultProps = {
	openPopper: false,
};

Input.propTypes = {
	openPopper: PropTypes.bool,
	inputRef: PropTypes.object,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onKeyUp: PropTypes.func,
};

export default Input;
