import { makeStyles } from '@mui/styles';
export const useStylesEdit = makeStyles((theme) => ({
	root: {},
	editHeader: {
		margin: theme.spacing(4, 10, 4, 10),
	},
	editCard: {
		margin: theme.spacing(0, 10, 5, 10),
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: theme.palette.button.primary,
		},
	},
	editOfficeHeader: {
		margin: theme.spacing(4, 0, 4, 0),
	},
}));
