import { useState, useEffect } from 'react';

import {
	Box,
	Card,
	CardHeader,
	CardContent,
	Checkbox,
	List,
	ListItem,
	Typography,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { useStyles } from './style';
import {
	EmptyState,
	Paginate,
	LoadingBar,
	Search,
	SearchAutocomplete,
	SelectedOrganisationCard,
} from '../../../../../components';
import { useSearchComponent, useWizardFormField } from '../../../../../shared/hooks';
import {
	isFullString,
	isObject,
	isEmptyString,
	isEmptyArray,
	isEmptyObject,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const SelectUserGroups = (props) => {
	const {
		save,
		userGroups,
		onFetchUserGroups,
		selectedUserGroups: savedSelectedUserGroups,
		selectedOrganisation: savedOrganisation,

		organisationList,
		onFetchOrganisations,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();
	const classes = useStyles();

	const role = auth.user?.profile.role.toLowerCase();
	const superAdmin = isFullString(role) && role === 'superadmin';

	const [selectedUserGroups, setSelectedUserGroups] = useState(savedSelectedUserGroups);
	const selectedOrganisation = useWizardFormField(savedOrganisation, { required: true });
	useEffect(() => {
		setSelectedUserGroups(savedSelectedUserGroups);
	}, [savedSelectedUserGroups]);

	useEffect(() => {
		save({ selectedUserGroups });
	}, [selectedUserGroups]);

	useEffect(() => {
		save({ savedOrganisation: selectedOrganisation.value });
	}, [selectedOrganisation.value]);

	const { data: userGroupsData, loading: userGroupsLoading, error: userGroupsError } = userGroups;
	const userGroupsReady = isObject(userGroupsData) && !userGroupsLoading && !userGroupsError;

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [shouldSearch, setShouldSearch] = useState(false);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(5);

	const search = useSearchComponent(setPageNumber, setShouldSearch, true);

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		const page = { size: pageSize, number: pageNumber };

		const filters = {
			...(!isEmptyString(search.value) && { searchTerm: search.value }),
			...(!isEmptyObject(selectedOrganisation.value) && {
				organisationId: selectedOrganisation.value.id,
			}),
		};
		if (shouldSearch || shouldDoInitialFetch || selectedOrganisation.value) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchUserGroups(page, filters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [
		onFetchUserGroups,
		shouldSearch,
		shouldDoInitialFetch,
		showingInitialResults,
		pageNumber,
		pageSize,
		selectedOrganisation.value,
	]);

	const handleSelectOne = (userGroup) => {
		const selectedIndex = selectedUserGroups.map((userGroup) => userGroup.id).indexOf(userGroup.id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedUserGroups, userGroup);
		} else {
			newSelected = newSelected.concat(
				selectedUserGroups.slice(0, selectedIndex),
				selectedUserGroups.slice(selectedIndex + 1),
			);
		}

		setSelectedUserGroups(newSelected);
	};

	const resetSelectedOrganisation = () => {
		// selectedUserGroup.onChange({});
		selectedOrganisation.onChange({});
	};

	const handlePageChange = (page) => {
		setPageNumber(page.selected + 1);
		setShouldDoInitialFetch(true);
	};

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const userGroupsListEmpty =
		isObject(userGroupsData) && !userGroupsLoading && isEmptyArray(userGroupsData.results) ?
			showingInitialResults ?
				<EmptyState
					callToAction={'/user-management/users/add'}
					callToActionText={t('view.usermanagement.usergroups.button.contained.createusergroup')}
					image={'booking'}
					subTitle={t('views.users.userGroups.empty.description.zeroInSystem')}
					title={t('views.users.userGroups.empty.title')}
				/>
			:	<EmptyState
					callToAction={'/user-management/users/add'}
					callToActionText={t('view.usermanagement.usergroups.button.contained.createusergroup')}
					image={'booking'}
					subTitle={t('views.users.userGroups.empty.description.zeroMatching')}
					title={t('views.users.userGroups.empty.title')}
				/>
		:	null;

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant='h4'>
						{t('views.assignItemsToUserGroup.blockHeader.selectUserGroups')}
					</Typography>
				}
			/>
			<CardContent>
				{superAdmin ?
					<>
						<Typography variant='h5'>
							{t('views.assignUsersToUserGroup.subheader.chooseOrganisation')}
						</Typography>
						<div className={classes.formGroup}>
							{!isEmptyObject(selectedOrganisation.value) ?
								<SelectedOrganisationCard
									handleClose={resetSelectedOrganisation}
									name={selectedOrganisation.value.name}
								/>
							:	<SearchAutocomplete
									dataList={organisationList}
									listType={'organisations'}
									onFetchData={onFetchOrganisations}
									placeholder={t(
										'views.assignUsersToUserGroup.placeholder.organisationSearchAutocomplete',
									)}
									setSelected={selectedOrganisation.onChange}
								/>
							}
						</div>
					</>
				:	null}
				<Typography variant='h5'>{t('ui.category.userGroups')}</Typography>
				<div className={classes.formGroup}>
					<Search
						className={classes.search}
						events={search.events}
						placeholder={t('views.assignItemsToUserGroup.search.placeholder')}
						styledInput={classes.searchInput}
						value={search.value}
					/>
				</div>
				<Box className={classes.listContainer}>
					{userGroupsListEmpty ?
						userGroupsListEmpty
					:	<>
							<List>
								{userGroupsReady ?
									userGroupsData.results.map((userGroup) => (
										<ListItem className={classes.listItem} key={`user-group-${userGroup.id}`}>
											<Box className={classes.userDetails} flex={1}>
												<Typography variant='h5'>{userGroup.name}</Typography>
											</Box>
											{superAdmin ?
												<Box flex={1}>
													<Typography>{userGroup.organisationReference.name}</Typography>
												</Box>
											:	null}
											<Box>
												<Checkbox
													checked={
														selectedUserGroups
															.map((userGroup) => userGroup.id)
															.indexOf(userGroup.id) !== -1
													}
													color='primary'
													onChange={() => handleSelectOne(userGroup)}
												/>
											</Box>
										</ListItem>
									))
								:	Array(pageSize)
										.fill(Array(superAdmin ? 3 : 2).fill())
										.map((row, index) => (
											<ListItem
												className={clsx(classes.loadingListItem, classes.listItem)}
												key={`loading-item-${index}`}
											>
												{row.map((_, index) => (
													<LoadingBar key={`loading-bar-${index}`} />
												))}
											</ListItem>
										))
								}
							</List>
							{userGroupsReady ?
								userGroupsData.total / pageSize > 1 ?
									<div className={classes.paginationContainer}>
										<Paginate
											forcePage={pageNumber - 1}
											onPageChange={handlePageChange}
											pageCount={userGroupsData.total / pageSize}
										/>
									</div>
								:	null
							:	null}
						</>
					}
				</Box>
			</CardContent>
		</Card>
	);
};

SelectUserGroups.propTypes = {
	selectedUserGroups: PropTypes.array,
	userGroups: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchUserGroups: PropTypes.func,
	save: PropTypes.func,

	onFetchOrganisations: PropTypes.func,
	selectedOrganisation: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		userGroups: state.paged.userGroups,

		organisationList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserGroups: (page, filters, concat) =>
			dispatch(actions.fetchUserGroups(page, filters, concat)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		// onFetchOrganisation: id => dispatch(actions.fetchOrganisation(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectUserGroups);
