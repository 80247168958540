import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ItemHeader = (props) => {
	const { padding, variant, header, subheader, variantText } = props;

	return (
		<Box display='flex' flexDirection='column' pb={padding}>
			<Box pb={0.5}>
				<Typography variant={variant}>{header}</Typography>
			</Box>
			<Typography variant={variantText}>{subheader}</Typography>
		</Box>
	);
};

ItemHeader.propTypes = {
	padding: PropTypes.number,
	variant: PropTypes.string,
	subheader: PropTypes.string,
	header: PropTypes.string,
	variantText: PropTypes.string,
};

export default ItemHeader;
