import { Box, Dialog, DialogContent, TextField, Typography, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { HeaderWithStatus, StyledButton } from '../../../components';
import { isFullString } from '../../../shared/utility';

const TextDialog = (props) => {
	const {
		open,
		handleClose,
		title,
		handleConfirm,
		maxLength,

		required,
		label,
		comment,
		setComment,
		commentLength,
		setCommentLength,
		disabled,
		loading,
		isText,
		content,
		subTitle,
		isLoading,
	} = props;
	const { t } = useTranslation('general');

	const handleComment = (event) => {
		setCommentLength(event.target.value.length);
		setComment(event.target.value);
	};

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Dialog onClose={handleClose} open={open}>
				<Box display='flex' flexDirection='column' mt={1} pb={2} pl={3} pr={3} pt={2}>
					<Typography variant='h3'>{title}</Typography>
					{isFullString(subTitle) ?
						<Box pt={0.5}>
							<Typography variant='h3'>{subTitle}</Typography>
						</Box>
					:	null}
				</Box>
				<DialogContent className={classes.dialog}>
					{isText ?
						<Box width='100%'>{content}</Box>
					:	<>
							<Box pb={2}>
								<HeaderWithStatus
									header={`${label}${required ? '*' : ''}`}
									headerSize='h5'
									justifyContent='space-between'
									text={`${commentLength}/${maxLength}`}
									variantText='body2'
								/>
							</Box>
							<TextField
								className={classes.textField}
								fullWidth
								inputProps={{ maxLength: maxLength }}
								maxRows={3}
								multiline
								onChange={handleComment}
								rows={3}
								value={comment}
								variant='outlined'
							/>
						</>
					}
				</DialogContent>
				<Box className={classes.actions}>
					<StyledButton
						className={classes.closeButton}
						fullWidth
						onClick={handleClose}
						variant='contained-tertiary'
					>
						{t('ui.button.contained.cancel')}
					</StyledButton>
					<StyledButton
						autoFocus
						className={classes.button}
						disabled={disabled}
						fullWidth
						onClick={handleConfirm}
						variant='contained-primary'
					>
						{isLoading ?
							loading ?
								<CircularProgress className={classes.confirmLoading} disableShrink size={24} />
							:	t('ui.confirm')
						:	t('ui.button.contained.confirm')}
					</StyledButton>
				</Box>
			</Dialog>
		</div>
	);
};

TextDialog.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	title: PropTypes.string,
	handleConfirm: PropTypes.func,

	maxLength: PropTypes.number,
	required: PropTypes.bool,
	label: PropTypes.string,
	comment: PropTypes.string,
	setComment: PropTypes.func,
	commentLength: PropTypes.number,
	setCommentLength: PropTypes.func,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	isText: PropTypes.bool,
	content: PropTypes.object,
	subTitle: PropTypes.string,
	isLoading: PropTypes.bool,
};

export default TextDialog;
