import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	content: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		'&:last-child': {
			paddingBottom: theme.spacing(2),
		},
	},
}));
