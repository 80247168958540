import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	actions: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		marginTop: 'auto',
		'& > * + *': {
			marginLeft: 0,
		},
	},
}));
