import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
	},
	table: {
		width: '100%',
		tableLayout: 'fixed',
		borderCollapse: 'collapse',
		borderSpacing: 0,

		'& tr + tr': {
			borderTop: '1px solid #eee',
		},
	},
	loadingTable: {
		height: '100%',
		marginTop: -1,
		marginLeft: -3,
	},
	tableHeader: {
		border: '1px solid #eee',
	},
	headerArea: {
		top: -1,
		zIndex: 9999,
		position: 'sticky',
	},
	resourceArea: {
		width: '20%',
		verticalAlign: 'top',
	},
	eventArea: {
		verticalAlign: 'top',
	},
	divider: {
		background: '#eee',
		width: 3,
	},
	headerDivider: {
		borderBottom: 0,
	},
	bodyDivider: {
		borderTop: 0,
	},
	time: {
		...theme.typography.body2,
		height: 24,
		width: 51,
		'& + &': {
			borderLeft: '1px solid #eee',
		},
	},
	timeTextContainer: {
		display: 'flex',
		padding: '3px 0',
	},
	timeText: {
		padding: '0 4px',
	},
	resourceRow: {
		height: 60,
		display: 'flex',
		alignItems: 'center',
	},
	resourceData: {
		width: '100%',
		padding: theme.spacing(0, 2),
	},
	eventRow: {
		height: 60,
		display: 'flex',
		alignItems: 'center',
	},
	scrollerClip: {
		overflow: 'hidden',
		position: 'relative',
		height: 24,
	},
	scroller: {
		overflow: 'scroll hidden',
		margin: '0px 0px -15px -625px',
	},
	scrollerCanvas: {
		width: 2496,
	},
	scrollerContent: {},
}));
