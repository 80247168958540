import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {},
	mainActions: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
}));
