import { useState, useEffect } from 'react';

import { TextField, MenuItem, Stack, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog, SelectWithLazyLoading } from '../../../../../components';
import { nfcInitialState } from '../../../../../constantsOld';
import { isInteger, isEmptyString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const AddNfcDialog = (props) => {
	const {
		open,

		onClose,
		onOrganisationsSlim,
		organisationsSlim,
		onAddNfc,
		addNfc,
		onResetState,
		getNfcData,
	} = props;
	const { t } = useTranslation('general');

	const { enqueueSnackbar } = useSnackbar();

	const [searchValue, setSearchValue] = useState('');

	const [cardDetails, setCardDetails] = useState(nfcInitialState);

	const { data: addNfcData, loading: addNfcLoading, error: addNfcError } = addNfc;

	const isNotValid =
		isEmptyString(cardDetails.nfcId) ||
		isEmptyString(cardDetails.card) ||
		!isInteger(cardDetails.organisationId);

	useEffect(() => {
		if (addNfcData) {
			enqueueSnackbar(`${t('ui.successfullyAdded')} ${t('ui.label.NFCcard')}`, {
				variant: 'success',
			});
			handleCloseAddNfcTag();
			onResetState('addNfc');
			getNfcData();
		} else if (addNfcError) {
			enqueueSnackbar(addNfcError?.message ? addNfcError.message : 'Error', { variant: 'error' });
			onResetState('addNfc');
		}
	}, [addNfc]);

	const handleAddNfcTag = () => {
		onAddNfc({ ...cardDetails, cardNumber: cardDetails.card });
	};

	const handleCloseAddNfcTag = () => {
		onClose();
		setCardDetails({ ...nfcInitialState });
		setSearchValue('');
	};

	const onOrganisationSelected = (value) => {
		setSearchValue(isInteger(value.id) ? value.name : '');
		setCardDetails((card) => ({ ...card, organisationId: isInteger(value.id) ? value.id : 'all' }));
	};

	const handleChangeCardData = (event) =>
		setCardDetails((card) => ({ ...card, [event.target.name]: event.target.value }));

	const options = ['none', 'skopei', 'skopeiEncrypted'];

	const selectProps = {
		name: 'tagType',
		onChange: handleChangeCardData,
		value: cardDetails.tagType,
		InputLabelProps: { shrink: true },
		label: t('views.usersAndOrganisations.nfcCards.tagType'),
		select: true,
		size: 'medium',
		fullWidth: true,
		variant: 'outlined',
	};

	const cardOptions = [
		{
			name: 'card',
			placeholder: t('views.userDetails.summary.nfcCardNumberPlaceholder'),
			value: cardDetails.card,
			label: t('views.userDetails.summary.nfcCardNumber'),
		},
		{
			name: 'nfcId',
			placeholder: 'Ex. 123123123123123123123123',
			value: cardDetails.nfcId,
			label: t('views.userDetails.summary.nfcid'),
		},
	];

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleAddNfcTag,
				text: addNfcLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleCloseAddNfcTag}
			loading={addNfcLoading || isNotValid}
			open={open}
			title={t('views.userDetails.summary.addNfcCard')}
		>
			<Stack mt={2} gap={2}>
				<SelectWithLazyLoading
					dataList={organisationsSlim}
					label={t('ui.organisation')}
					required={true}
					searchHandle={setSearchValue}
					defaultListItem={{ id: '', name: t('ui.filter.organisations.all') }}
					events={{ filter: 'verified' }}
					listType='organisations'
					onFetchData={onOrganisationsSlim}
					placeholder={t('ui.filter.organisations.all')}
					setSelected={onOrganisationSelected}
					value={searchValue}
				/>
				<TextField {...selectProps}>
					{options.map((item) => (
						<MenuItem key={item} value={item}>
							{t(`views.usersAndOrganisations.nfcCards.${item}`)}
						</MenuItem>
					))}
				</TextField>
				{cardOptions.map((card) => (
					<TextField
						key={card.name}
						InputLabelProps={{ shrink: true }}
						required={true}
						name={card.name}
						placeholder={card.placeholder}
						value={card.value}
						label={card.label}
						onChange={handleChangeCardData}
					/>
				))}
			</Stack>
		</ActionDialog>
	);
};

AddNfcDialog.propTypes = {
	open: PropTypes.bool,

	onClose: PropTypes.func,
	onOrganisationsSlim: PropTypes.func,
	onAddNfc: PropTypes.func,
	onResetState: PropTypes.func,
	getNfcData: PropTypes.func,
	organisationsSlim: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	addNfc: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		organisationsSlim: state.paged.organisationsSlim,
		addNfc: state.details.addNfc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onOrganisationsSlim: (page, filters, concat) =>
			dispatch(actions.organisationsSlim(page, filters, concat)),
		onAddNfc: (bodyData) => dispatch(actions.addNfc(bodyData)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNfcDialog);
