import { useEffect, useState } from 'react';

import path from 'path';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConfirmationDialog, SummaryContainer } from '~components';
import { pagePathSegments } from '~constants';
import { usePageButtons } from '~layouts';

import AccessRulesService from '../../accessOld/services/accessRulesService';
import AccessRuleGeneralInfoCard from '../components/cards/accessRuleGeneralInfoCard';
import DayOfWeekSchedulesCard from '../components/cards/dayOfWeekSchedulesCard';

const service = new AccessRulesService();

interface AccessRuleDetailsLayoutProps {
	id: string;
}

const AccessRuleDetailsLayout = ({ id }: AccessRuleDetailsLayoutProps) => {
	const { t } = useTranslation('general');
	const pageButtons = usePageButtons();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [processing, setProcessing] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	useEffect(() => {
		pageButtons.set([
			{
				children: t('ui.edit'),
				startIcon: <EditIcon />,
				onClick: handleEdit,
			},
			{
				children: t('delete'),
				color: 'error',
				startIcon: <DeleteOutlinedIcon />,
				onClick: () => setOpenConfirmDialog(true),
			},
		]);
	}, []);

	const handleEdit = () => {
		navigate(pagePathSegments.Edit);
	};

	const handleDelete = async () => {
		setProcessing(true);
		try {
			const response = await service.deleteAccessRule(id);
			enqueueSnackbar(`${t('ui.successfullyDeleted')} ${id}`, {
				variant: 'success',
			});
			navigate(`/${path.join(pagePathSegments.Access, pagePathSegments.Rules)}`);
		} catch (error) {
			enqueueSnackbar(t('somethingWentWrong'), {
				variant: 'error',
			});
		} finally {
			setProcessing(false);
			setOpenConfirmDialog(false);
		}
	};

	return (
		<>
			<SummaryContainer>
				<AccessRuleGeneralInfoCard id={id} />
				<DayOfWeekSchedulesCard id={id} />
			</SummaryContainer>
			<ConfirmationDialog
				open={openConfirmDialog}
				subTitle={t('thisWillDeleteResourceWithName', { resource: t('accessRule'), name: id })}
				loading={processing}
				onConfirm={handleDelete}
				onDecline={() => setOpenConfirmDialog(false)}
			/>
		</>
	);
};

export default AccessRuleDetailsLayout;
