import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	listContainer: {
		height: 450,
		marginTop: theme.spacing(2),
	},
	loadingListItem: {
		height: 74,
	},
	listItem: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 0),
		'& + &': {
			borderTop: `1px solid ${theme.palette.divider}`,
		},
	},
	paginationContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	formGroup: {
		margin: theme.spacing(2, 0),
	},
	searchInput: {
		width: '100%',
		minHeight: '51.25px',
	},
	search: {
		margin: '0',
	},
}));
