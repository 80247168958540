import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '64px',
		zIndex: 3,
		padding: theme.spacing(1, 2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: `1px solid ${theme.palette.divider}`,
		flexWrap: 'wrap',
	},
	button: {
		textTransform: 'none',
		margin: theme.spacing(0.5, 0),
	},
	navigateNext: {
		marginLeft: theme.spacing(1),
	},
	date: {
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	dateInput: {
		border: 0,
		clip: 'rect(0, 0, 0, 0)',
		height: '1px',
		margin: '-1px',
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		whiteSpace: 'nowrap',
		width: '1px',
	},
}));
