import { useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import StopIcon from '@mui/icons-material/Stop';
import { Typography, Hidden, Box } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';
import CancelUnabailabilitysDialog from '../../../../Actions/Dialogs/CancelUnabailabilitysDialog';
import StopUnabailabilitysDialog from '../../../../Actions/Dialogs/StopUnabailabilitysDialog';
import SideDrawer from '../../../../Planboard/SideDrawer';

const Header = (props) => {
	const { className, fetchUnavailabilityData, loading } = props;
	const location = useLocation();
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const [openAddUnavailability, setOpenAddUnavailability] = useState(false);
	const [presetUnavailability, setPresetUnavailability] = useState(null);

	const classes = useStyles();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => navigate('/ticketing/unavailability');

	const [openCancelUnavailability, setOpenCancelUnavailability] = useState(false);

	const [stopUnavailability, setStopUnavailability] = useState(false);

	const handleBackButton = () => {
		if (returnButton.isBackButton) {
			return t('ui.button.inline.back');
		} else if (location?.state?.label) {
			return location?.state?.label;
		} else {
			return t('ui.label.tab.unavailability');
		}
	};

	const cancelUnavailabilityProps = {
		openCancelUnavailability,
		setOpenCancelUnavailability,
		unavailabilityId: fetchUnavailabilityData?.id,
	};

	const stopUnabailabilitysProps = {
		end: fetchUnavailabilityData?.end,
		open: stopUnavailability,
		data: fetchUnavailabilityData,
		setStopUnavailability,
		start: fetchUnavailabilityData?.start,
	};

	const handleCloseDrawer = () => {
		setOpenAddUnavailability(false);
	};

	const handleOpenCancelUnavailability = () => {
		setOpenCancelUnavailability(true);
	};

	const handleEditUnavailability = () => {
		setOpenAddUnavailability(true);
		setPresetUnavailability({
			unavailabilityId: fetchUnavailabilityData.id,
			itemId: fetchUnavailabilityData.itemInstance.itemReference.id,
			itemSelected: true,
		});
	};

	const handleStopUnavailability = () => {
		setStopUnavailability(true);
	};

	const buttons = [
		{
			name: 'cancel',
			icon: <CancelIcon />,
			label: 'ui.button.cancelUnavailability',
			hide: fetchUnavailabilityData?.status === 'upcoming',
			className: classes.buttonsCancel,
			onClick: handleOpenCancelUnavailability,
			variant: 'inline-default',
		},
		{
			name: 'stop',
			icon: <StopIcon />,
			label: 'ui.button.stopUnavailability',
			hide: fetchUnavailabilityData?.status === 'active',
			className: classes.buttonsCancel,
			onClick: handleStopUnavailability,
			variant: 'inline-default',
		},
		{
			name: 'edit',
			icon: <EditIcon />,
			label: 'ui.edit',
			hide:
				fetchUnavailabilityData?.status === 'upcoming' ||
				fetchUnavailabilityData?.status === 'active',
			onClick: handleEditUnavailability,
			variant: 'inline-default',
		},
	];

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{handleBackButton()}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				<Box display='flex' justifyContent='space-between'>
					{loading ?
						<AdjustedSkeleton animation='wave' width={200} />
					:	`${t('ui.label.unavailability')} id: ${fetchUnavailabilityData.id}`}
					{loading ?
						<AdjustedSkeleton animation='wave' width={360} />
					:	<Box display='flex'>
							{buttons.map((button) =>
								button.hide ?
									<StyledButton
										className={button.className}
										key={button.name}
										onClick={button.onClick}
										size='small'
										startIcon={button.icon}
										variant={button.variant}
									>
										{t(button.label)}
									</StyledButton>
								:	null,
							)}
						</Box>
					}
				</Box>
			</Typography>
			<CancelUnabailabilitysDialog {...cancelUnavailabilityProps} />
			<StopUnabailabilitysDialog {...stopUnabailabilitysProps} />
			<SideDrawer
				isDetails={true}
				isUnavailability={true}
				onClose={handleCloseDrawer}
				open={openAddUnavailability}
				presetData={presetUnavailability}
				setPresetBookingData={setPresetUnavailability}
			/>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,

	customer: PropTypes.object,

	loading: PropTypes.bool,
	fetchUnavailabilityData: PropTypes.any,
};

export default Header;
