import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useDebounce } from '~hooks';

import { isFullString } from '../../shared/utility';
import { updatePagedState } from '../../store/actions';

/**
 * A custom hook for making paged calls and managing state in a paged context.
 *
 * @param {Function} onAction - The callback function to be invoked for the paged call.
 * @param {string} stateName - The name of the state used for managing the paged context.
 * @param {Object} filters - Additional filters for the paged call.
 *
 * @returns {Object} An object containing paged data, state management functions, and state information.
 * @property {Array} data - The paged data retrieved from the paged call.
 * @property {number} totalPages - The total number of pages available.
 * @property {Function} onResetState - A function to reset the paged state and trigger a new call.
 * @property {Object} state - The current state object containing page information and loading status.
 * @property {Function} setState - A function to update the internal state.
 * @property {Function} resetInitialState - A function to reset the internal state to its initial values.
 */

function usePagedCall(onAction, stateName, filters) {
	const initialState = {
		pageNumber: 1,
		fetch: false,
		id: undefined,
		name: '',
		loading: false,
		openPopper: false,
		search: false,
	};

	const [state, setState] = useState(initialState);

	const debounceValue = useDebounce(state.name, 1000);

	const slimData = useSelector((state) => state.paged[stateName]);

	const isSearch = state.search && isFullString(debounceValue);

	const dispatch = useDispatch();

	const updatedFilters = { ...filters, ...(isSearch && { searchTerm: state.name }) };

	const handleResetState = () => {
		dispatch(updatePagedState(stateName, null, false));
		setState((prevState) => ({ ...prevState, pageNumber: 1 }));
	};

	const resetInitialState = () => {
		setState(initialState);
		sessionStorage.removeItem(stateName);
	};

	useEffect(() => {
		if (sessionStorage.getItem(stateName)) {
			const value = JSON.parse(sessionStorage.getItem(stateName));
			setState((prevState) => ({ ...prevState, ...value }));
		}
	}, []);

	useEffect(() => {
		if (state.fetch || isSearch) {
			onAction({ number: state.pageNumber, size: 10 }, updatedFilters, true);
			setState((prevState) => ({ ...prevState, fetch: false, search: false, loading: true }));
		}
	}, [state.fetch, debounceValue, state.loading]);

	return {
		...slimData,
		onResetState: handleResetState,
		state,
		setState,
		resetInitialState,
	};
}

/**
 * A custom hook for making paged calls and managing state in a terms-specific paged context.
 *
 * @param {Function} onAction - The callback function to be invoked for the paged call.
 * @param {string} stateName - The name of the state used for managing the terms-specific paged context.
 * @param {Object} filters - Additional filters for the paged call.
 *
 * @returns {Object} An object containing paged data, state management functions, and state information.
 * @property {Array} data - The paged data retrieved from the terms-specific paged call.
 * @property {number} totalPages - The total number of pages available.
 * @property {boolean} loading - A flag indicating whether the data is currently being loaded.
 * @property {boolean} openPopper - A flag indicating whether a Popper should be open.
 * @property {Function} onResetState - A function to reset the terms-specific paged state and trigger a new call.
 * @property {Object} state - The current state object containing page information and loading status.
 * @property {Function} setState - A function to update the internal state.
 * @property {Function} resetInitialState - A function to reset the internal state to its initial values.
 */

function usePagedCallTerms(onAction, stateName, filters) {
	const initialState = {
		pageNumber: 1,
		fetch: false,
		id: undefined,
		name: '',
		loading: false,
		openPopper: false,
		search: false,
	};

	const [state, setState] = useState(initialState);

	const debounceValue = useDebounce(state.name, 500);

	const slimData = useSelector((state) => state.paged.termsSlim);

	const newData =
		slimData?.data?.results ?
			{
				...slimData,
				loading: false,
				data: {
					...slimData?.data,
					results: slimData.data.results.map((term) => ({ ...term, name: term.title })),
				},
			}
		:	slimData;

	const isSearch = state.search && isFullString(debounceValue);

	const dispatch = useDispatch();

	const updatedFilters = { ...filters, ...(isSearch && { searchTerm: state.name }) };

	const handleResetState = () => {
		dispatch(updatePagedState('termsSlim', null, false));
		setState((prevState) => ({ ...prevState, pageNumber: 1 }));
	};

	const resetInitialState = () => {
		setState(initialState);
		sessionStorage.removeItem(stateName);
	};

	useEffect(() => {
		if (sessionStorage.getItem(stateName)) {
			const value = JSON.parse(sessionStorage.getItem(stateName));
			setState((prevState) => ({ ...prevState, ...value }));
		}
	}, []);

	useEffect(() => {
		if (state.fetch || isSearch) {
			onAction({ number: state.pageNumber, size: 10 }, updatedFilters, true);
			setState((prevState) => ({ ...prevState, fetch: false, search: false, loading: true }));
		}
	}, [state.fetch, debounceValue]);

	return {
		...newData,
		onResetState: handleResetState,
		state,
		setState,
		resetInitialState,
	};
}

export { usePagedCall, usePagedCallTerms };

/**
 * Represents the types of states used in the application.
 * @typedef {Object} StateType
 * @property {string} ORGANISATIONSSLIM - Represents the state for organisationsSlim.
 * @property {string} ITEMSSLIM - Represents the state for itemsSlim.
 * @property {string} USERGROUPSSLIM - Represents the state for userGroupsSlim.
 * @property {string} PRICINGMODELSSLIM - Represents the state for pricingModelsSlim.
 * @property {string} TERMSSLIM - Represents the state for termsSlim.
 * @property {string} POLICIESSLIM - Represents the state for policiesSlim.
 */

/**
 * Represents the application state type.
 * @type {StateType}
 * @const
 */

export const stateType = {
	ORGANISATIONSSLIM: 'organisationsSlim',
	ITEMSSLIM: 'itemsSlim',
	USERGROUPSSLIM: 'userGroupsSlim',
	PRICINGMODELSSLIM: 'pricingModelsSlim',
	TERMSSLIM: 'termsSlim',
	POLICIESSLIM: 'policiesSlim',
};
