export const dispatchTypes = {
	SELECTONE: 'selectOne',
	SELECTALL: 'selectAll',
	ADD: 'add',
};
export const availableSharingsReducer = (state, action) => {
	const { items, selectedItem } = action;

	switch (action.type) {
		case dispatchTypes.ADD:
			return state.concat(
				items.map((item) => {
					return {
						...item,
						select: false,
					};
				}),
			);
		case dispatchTypes.SELECTONE:
			return state.map((item) =>
				item.name === selectedItem.name ?
					{
						...item,
						select: selectedItem.select,
					}
				:	item,
			);
		case dispatchTypes.SELECTALL:
			return state.map((item) => ({
				...item,
				select: selectedItem,
			}));
		default:
			return [];
	}
};
