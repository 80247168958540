import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { Table, Label } from '../../../../../components';
import { isObject, decimalBase, getFinanceTableHeaders } from '../../../../../shared/utility';

const FinanceCurrent = (props) => {
	const { title, businessExpensesData, loading } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const tableHeader = getFinanceTableHeaders();

	const tableBody =
		isObject(businessExpensesData) ?
			[
				[
					{
						content: (
							<Box className={classes.cell}>
								<Typography className={classes.link}>{title}</Typography>
							</Box>
						),
					},
					{
						content: decimalBase(
							businessExpensesData.amount.subtotal,
							businessExpensesData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							businessExpensesData.amount.discount,
							businessExpensesData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							businessExpensesData.amount.subtotal - businessExpensesData.amount.discount,
							businessExpensesData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							businessExpensesData.amount.priceVat,
							businessExpensesData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							businessExpensesData.amount.refund,
							businessExpensesData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							businessExpensesData.amount.totalPrice,
							businessExpensesData.amount.currencySymbol,
						),
					},
					{
						content: decimalBase(
							businessExpensesData.open,
							businessExpensesData.amount.currencySymbol,
						),
					},
					{
						content: (
							<Label type={statusColor[businessExpensesData.status]}>
								{statusText[businessExpensesData.status]}
							</Label>
						),
					},
				],
			]
		:	Array(2)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation =
		isObject(businessExpensesData) ?
			{
				header: t('ui.label.personalFinance'),
				sideHeader: decimalBase(
					businessExpensesData.amount.subtotal,
					businessExpensesData.amount.currencySymbol,
				),
			}
		:	null;

	return (
		<div className={classes.root}>
			<Table
				body={tableBody}
				cellStyle={classes.cellStyle}
				header={tableHeader}
				isNotPaginate={true}
				loading={loading}
				noTitle={true}
				titleWithInformation={titleWithInformation}
				withoutPages={true}
			/>
		</div>
	);
};

FinanceCurrent.propTypes = {
	financeType: PropTypes.string,
	title: PropTypes.string,
	name: PropTypes.string,

	businessExpensesData: PropTypes.object,
	loading: PropTypes.bool,
};

export default FinanceCurrent;
