import { memo } from 'react';

import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { Typography, Box, TextField, InputAdornment } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PricingInfo from './PricingInfo/PricingInfo';
import { useStyles } from './style';
import { CustomNumberField } from '../../../../../components';
import { ignoreKeyClick } from '../../../../../shared/utility';
import palette from '../../../../../theme/palette';

const propsAreEqual = (prevProps, nextProps) => {
	return (
		prevProps.className === nextProps.className &&
		JSON.stringify(prevProps.resourceArray) === JSON.stringify(nextProps.resourceArray) &&
		JSON.stringify(prevProps.eventArray) === JSON.stringify(nextProps.eventArray) &&
		JSON.stringify(prevProps.events) === JSON.stringify(nextProps.events)
	);
};

const CalendarDetails = (props) => {
	const {
		className,

		periodLabel,
		allDayText,
		events,
		eventArray,
		constraint,
		pricingPerWeek,
		setPricingPerWeek,
		perWeekValue,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const styles = {
		fixed: palette.primary.main,
		free: palette.info.light,
	};

	const pricingOptionColor = [
		{ styles: styles.fixed, name: t('ui.label.paid'), label: 'paid' },
		{ styles: styles.free, name: t('ui.free'), label: 'free' },
	];

	const handleSelectAllow = (selectInfo) => selectInfo;

	const handleAmountPerWeek = (event) => {
		const price = parseFloat(event.target.value);
		setPricingPerWeek({ ...pricingPerWeek, price: price });
	};

	const handleKeyUp = (event) => {
		ignoreKeyClick(event, 'Enter');
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Typography className={classes.modelLabel} variant='h4'>
				Model
			</Typography>
			{perWeekValue ?
				<form className={classes.amount}>
					<TextField
						InputLabelProps={{ shrink: true }}
						InputProps={{
							inputComponent: CustomNumberField,
							startAdornment: <InputAdornment position='start'>{'€'}</InputAdornment>,
						}}
						fullWidth
						label={t('ui.amount')}
						name='amount'
						onChange={handleAmountPerWeek}
						onKeyPress={handleKeyUp}
						value={pricingPerWeek.price}
						variant='outlined'
					/>
				</form>
			:	null}
			<div className={classes.typesComponent}>
				<Typography variant='h5'>{periodLabel} </Typography>
				<Box className={classes.typeItems}>
					{pricingOptionColor.map((item) => (
						<PricingInfo bColor={item.styles} key={item.label} pricingName={item.name} />
					))}
				</Box>
			</div>
			<FullCalendar
				allDayClassNames={classes.allDay}
				allDayContent={allDayText}
				allDaySlot={true}
				dayHeaderFormat={{ weekday: 'short' }}
				defaultAllDay={true}
				defaultAllDayEventDuration={{ days: 1 }}
				eventClick={events.clickEvent}
				eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
				events={eventArray}
				headerToolbar={false}
				plugins={[resourceTimeGridPlugin, interactionPlugin]}
				schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
				select={events.select}
				selectAllow={handleSelectAllow}
				selectConstraint={constraint}
				selectOverlap={false}
				selectable={true}
				slotLabelFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
			/>
		</div>
	);
};

CalendarDetails.propTypes = {
	className: PropTypes.string,

	periodLabel: PropTypes.string,
	allDayText: PropTypes.string,
	events: PropTypes.object,
	eventArray: PropTypes.array,
	constraint: PropTypes.object,
	calendarRef: PropTypes.object,
	resourceArray: PropTypes.array,
	perWeekValue: PropTypes.object,
	pricingPerWeek: PropTypes.object,
	setPricingPerWeek: PropTypes.func,
};

export default memo(CalendarDetails, propsAreEqual);
