import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		padding: 0,
	},
	inner: {
		marginRight: 0,
	},
	nameCell: {
		display: 'flex',
		alignItems: 'center',
	},
	deletTac: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '0',
	},
	tooltip: {
		...theme.typography.tooltip,
	},
	itemGroup: {
		...theme.typography.link1,
	},
}));
