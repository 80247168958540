import { Delete as DeleteIcon, Close as CloseIcon, Stop as StopIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import { useStyles } from '../style';

const ButtonCard = (props) => {
	const classes = useStyles();

	const {
		handleCloseDrawer,

		bookingData,
		handleOpenStopCarBooking,
		handleOpenCancelBooking,
		unavailabilityData,
		cancelUnavailability,
		stopUnavailability,
		onResetCooldown,
		resetLoading,
		isAllowedCooldownReset,
	} = props;
	const { t } = useTranslation('general');

	const buttons = [
		{
			name: 'closeDrawer',
			label: 'ui.button.inline.close',
			action: () => handleCloseDrawer(),
			visible: true,
			variant: 'inline-default',
			icon: <CloseIcon />,
		},
		...(isAllowedCooldownReset ?
			[
				{
					name: 'cooldown',
					label: 'views.planboard.button.resetCooldown',
					action: () => onResetCooldown(),
					visible: isAllowedCooldownReset,
					disabled: resetLoading,
					variant: 'inline-default',
					icon: <span className='material-symbols-outlined'>delete_history</span>,
					className: classes.allowedCooldow,
				},
			]
		:	[]),
		...(isObject(bookingData) ?
			[
				{
					name: 'cancelBooking',
					label: 'view.planboard.addoreditbooking.button.inline.cancelbooking',
					action: () => handleOpenCancelBooking(),
					visible:
						bookingData?.isCancellable &&
						(bookingData.status === 'upcoming' ||
							(bookingData.status === 'active' && bookingData.usageState === 'ready')),
					variant: 'inline-delete',
					icon: <DeleteIcon />,
					className: classes.cancelBooking,
				},
				{
					name: 'stopBooking',
					label: 'view.planboard.addoreditbooking.button.inline.stopbooking',
					action: () => handleOpenStopCarBooking(),
					visible: ['active', 'overtime'].includes(bookingData.status),
					variant: 'inline-delete',
					icon: <StopIcon />,
				},
			]
		:	[]),
		...(isObject(unavailabilityData) ?
			[
				{
					name: 'cancelUnavailability',
					label: 'view.planboard.addoreditbooking.button.inline.cancelbooking',
					action: () => cancelUnavailability(),
					visible: unavailabilityData.status === 'upcoming',
					variant: 'inline-delete',
					icon: <DeleteIcon />,
					className: classes.cancelBooking,
				},
				{
					name: 'stopUnavailability',
					label: 'view.planboard.addoreditbooking.button.inline.stopbooking',
					action: () => stopUnavailability(),
					visible: unavailabilityData.status === 'active',
					variant: 'inline-delete',
					icon: <StopIcon />,
				},
			]
		:	[]),
	];

	return (
		<div className={classes.buttonCard}>
			{buttons.map((button) =>
				button.visible ?
					<StyledButton
						disabled={button?.disabled}
						className={button.className}
						key={button.label}
						onClick={button.action}
						size='small'
						startIcon={button.icon}
						variant={button.variant}
					>
						{t(button.label)}
					</StyledButton>
				:	null,
			)}
		</div>
	);
};

ButtonCard.propTypes = {
	onResetCooldown: PropTypes.func,

	bookingData: PropTypes.object,
	handleOpenStopCarBooking: PropTypes.func,
	handleOpenCancelBooking: PropTypes.func,
	cancelUnavailability: PropTypes.func,
	stopUnavailability: PropTypes.func,
	handleCloseDrawer: PropTypes.func,
	unavailabilityData: PropTypes.object,
	resetLoading: PropTypes.bool,
	isAllowedCooldownReset: PropTypes.bool,
};

export default ButtonCard;
