import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 700,
	},
	nameCell: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1),
	},
	actions: {
		padding: theme.spacing(1),
		justifyContent: 'flex-end',
	},
	buttonIcon: {
		marginRight: theme.spacing(0.5),
	},
	itemGroup: {
		...theme.typography.link1,
	},
	cellStyle: {
		whiteSpace: 'nowrap',
	},
	tooltipText: {
		...theme.typography.body3,
	},
	outerTooltipText: {
		textDecoration: 'underline',
		...theme.typography.body3,
	},
}));

const useStylesMyTooltip = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.white,
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
	tooltip: {
		backgroundColor: '#ffffff',
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
}));

export function MyTooltip(props) {
	const classes = useStylesMyTooltip();
	return <Tooltip classes={classes} {...props} />;
}
