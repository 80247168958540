import { useMemo, useState } from 'react';

import path from 'path';

import {
	Block as BlockIcon,
	ContentCopy as ContentCopyIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
	EmailOutlined as EmailOutlinedIcon,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Chip,
	Link as MuiLink,
	Portal,
	Stack,
	useTheme,
} from '@mui/material';
import {
	GridCallbackDetails,
	GridCellParams,
	GridColDef,
	GridColumnVisibilityModel,
	GridPaginationModel,
	GridRenderCellParams,
	GridRowParams,
	GridSortModel,
	GridToolbarContainer,
} from '@mui/x-data-grid';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { userInfoAtom } from '~atoms';
import {
	DataGrid,
	GridActionsCellItem,
} from '~components';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';
import { BaseReference } from '~interfaces';
import { datetimeWithoutSecondsFormat } from '~utils/dateUtils';

import UserInvitation from '../../interfaces/userInvitation';
import UserInvitationsService from '../../services/userInvitationsService';

const toolbarIdString = 'topology-userinvitations-datagrid';

const service = new UserInvitationsService();

interface UserInvitationsDataGridProps {
	enableDelete?: boolean;
	onDelete?: (id: string) => void;
	onResendInvite?: (id: string) => void;
	onNewInvite?: () => void;
}

const UserInvitationsDataGrid = ({
	enableDelete,
	onDelete,
	onResendInvite,
	onNewInvite,
}: UserInvitationsDataGridProps) => {
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const [fetchParameters, setFetchParameters] = useState<object | null>(null);
	const [organisationFilter, setOrganisationFilter] = useState<BaseReference | null>(
		!isSuperAdmin() ? userInfo.organisation : null,
	);
	const [sortModel, setSortModel] = useState<GridSortModel>([
		{
			field: 'expiryDate',
			sort: 'desc',
		},
	]);
	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
		organisation: isSuperAdmin(),
	});

	const columns: GridColDef<UserInvitation>[] = [
		{
			field: 'email',
			headerName: t('email'),
			renderCell: (params: GridRenderCellParams<UserInvitation>) => (
				<MuiLink href={`mailto:${params.value}`}>{params.value}</MuiLink>
			),
			flex: 1,
		},
		{
			field: 'expiryDate',
			headerName: t('expiryDate'),
			valueFormatter: (value: Date) => datetimeWithoutSecondsFormat.format(value),
			cellClassName: (params: GridCellParams<UserInvitation>) =>
				params.row.status === 'expired' ? 'error-text-color' : '',
			flex: 1,
		},
		{
			field: 'organisation',
			headerName: t('organisation'),
			renderCell: (params) => (
				<MuiLink
					component={ReactRouterLink}
					to={`/${path.join(pagePathSegments.Organisations, params.value.id, pagePathSegments.Summary)}`}
				>
					{params.value.label}
				</MuiLink>
			),
			flex: 1,
		},
		{
			field: 'userGroup',
			headerName: t('userGroup'),
			flex: 1,
			renderCell: (params: GridRenderCellParams<UserInvitation>) =>
				!params.value ? '-' : (
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.UserGroups, params.value.id, pagePathSegments.Summary)}`}
					>
						{params.value.label}
					</MuiLink>
				),
		},
		{
			field: 'culture',
			headerName: t('language'),
			valueFormatter: (value: string) => value ?? '-',
		},
		{
			field: 'status',
			headerName: t('status'),
			renderCell: (params: GridRenderCellParams<UserInvitation>) => (
				<Chip label={params.value} color={params.row.status === 'active' ? 'success' : 'error'} />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			width: 80,
			align: 'right',
			resizable: false,
			hideable: false,
			getActions: (params: GridRowParams<UserInvitation>) => [
				<GridActionsCellItem
					key={`${params.id}-mail`}
					{...(params.row.status === 'active' ?
						{
							icon: <ContentCopyIcon />,
							label: t('view.usermanagement.invitations.button.contained.copyinvite'),
							onClick: () => copyToClipboard(params.row.link),
						}
					:	{
							icon: <EmailOutlinedIcon />,
							label: t('view.usermanagement.invitations.button.contained.resendlink'),
							onClick: () => onResendInvite != null && onResendInvite(params.id.toString()),
						})}
				/>,
				<GridActionsCellItem
					key={`${params.id}-delete`}
					icon={<DeleteOutlinedIcon color='error' />}
					label={t('ui.delete')}
					showInMenu
					onClick={() => onResendInvite != null && onResendInvite(params.id.toString())}
					sx={{ color: theme.palette.error.main }}
				/>,
			],
		},
	];

	const { data, isLoading, isValidating, error } = useSWR(
		fetchParameters ? [service.basePath, fetchParameters] : null,
		([_, args]) => service.getUserInvitations(args),
		{
			onSuccess: (res) => res?.total != null && setTotalCount(res.total),
			keepPreviousData: true,
		},
	);

	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
		if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
			service.abortCurrentRequest('pagination change');
		}

		setFetchParameters((prev) => ({
			...prev,
			page: value.page + 1,
			pageSize: value.pageSize,
		}));
	};

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		enqueueSnackbar(t('views.tableResults.invitationsUsers.clipboard'));
	};

	const Toolbar = () =>
		useMemo(
			() => (
				<Portal container={() => document.getElementById(toolbarIdString)}>
					<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
						<Button variant='contained' onClick={() => onNewInvite != null && onNewInvite()}>
							{t('ui.button.contained.inviteusers')}
						</Button>
					</GridToolbarContainer>
				</Portal>
			),
			[],
		);

	return (
		<Stack height={1} spacing={2}>
			<Box id={toolbarIdString} />
			<DataGrid
				checkboxSelection
				columns={columns}
				error={error}
				loading={isLoading || isValidating}
				rows={data?.results ?? []}
				rowCount={totalCount}
				onPaginationModelChange={handlePaginationChange}
				sortModel={sortModel}
				onSortModelChange={(newModel) => setSortModel(newModel)}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
				slots={{
					toolbar: Toolbar,
				}}
				sx={{
					flexGrow: 1,
					'& .error-text-color': {
						color: theme.palette.error.main,
					},
				}}
			/>
		</Stack>
	);
};

export default UserInvitationsDataGrid;
