import { useRef, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConfirmationDialog, Dialog } from '~components';
import { pagePathSegments } from '~constants';

import { UserGroupsDataGrid, UserGroupsService } from '..';

const service = new UserGroupsService();

type UserAction =
	| {
			type: 'create';
			id?: never;
	  }
	| {
			type: 'delete';
			id: string;
	  };

const UserGroupsOverviewLayout = () => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const dataGridRef = useRef();

	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const handleDelete = async (id: string) => {
		setUserActionProcessing(true);

		try {
			await service.deleteUserGroup(id);
			enqueueSnackbar(t('success'), { variant: 'success' });
		} catch (error) {
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
		<Stack>
			<UserGroupsDataGrid
				ref={dataGridRef}
				onCreate={() => navigate(pagePathSegments.Add)}
				onDelete={(id) => setUserAction({ type: 'delete', id: id })}
			/>
			<ConfirmationDialog
				open={userAction?.type === 'delete'}
				loading={userActionProcessing}
				onConfirm={() => handleDelete(userAction.id)}
				onDecline={() => setUserAction(null)}
			/>
		</Stack>
	);
};

export default UserGroupsOverviewLayout;
