import i18n from '~lib/i18n';

export const handleReservationType = (value) => {
	const [bookingTypes] = value.bookingTypes;
	const reservationType = value.reservationType;

	if (bookingTypes === 'regular' && reservationType === 'fixed') {
		return i18n.t('views.addItem.bookingType.options.fixed.title');
	} else if (bookingTypes === 'scanQr' && reservationType === 'fixed') {
		return i18n.t('views.addItem.bookingType.options.flexible.title');
	} else if (bookingTypes === 'scanNfc' && reservationType === 'flexible') {
		return i18n.t('views.addItem.bookingType.options.scanOnly.title');
	} else if (bookingTypes === 'scanQr' && reservationType === 'nonBookable') {
		return i18n.t('views.addItem.bookingType.options.nonBookable.title');
	} else {
		return 'UPDATE Usage type';
	}
};

export const handleBookingState = (value) => {
	const status = value.status;
	const approval = value.approvalState;
	const usageState = value.usageState;

	if (status === 'completed' || status === 'overtime') {
		return i18n.t(`ui.status.${status}`);
	} else if (status === 'cancelled') {
		if (approval === 'declined') {
			return i18n.t('ui.status.rejected');
		} else {
			return i18n.t('ui.status.cancelled');
		}
	} else if (status === 'upcoming') {
		if (approval === 'pending') {
			return i18n.t('ui.status.pending');
		} else {
			return i18n.t('ui.status.upcoming');
		}
	} else if (status === 'active') {
		if (usageState === 'ready') {
			return i18n.t('ui.status.readyToStart');
		} else if (usageState === 'started') {
			return i18n.t('ui.status.inUse');
		} else {
			return i18n.t('ui.label.active');
		}
	} else {
		return 'UPDATE this function';
	}
};
