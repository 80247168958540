import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	mainActions: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	notice: {
		marginTop: theme.spacing(1),
	},
	uploadedFile: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingTop: 0,
	},
	description: {
		marginRight: theme.spacing(1),
	},
	fileName: {
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	fileCard: {
		margin: 16,
		'& .MuiCardContent-root': {
			paddingBottom: '0px',
		},
		paddingBottom: '0px',
	},
}));
