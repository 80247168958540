import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../style';

const BookingType = (props) => {
	const { selectType, onChange } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const sx = { textTransform: 'capitalize', backgroundColor: 'rgba(21, 38, 62, 0.08)' };
	return (
		<Box mb={2.5}>
			<ToggleButtonGroup
				exclusive={true}
				fullWidth={true}
				onChange={onChange}
				size='small'
				value={selectType}
			>
				<ToggleButton className={classes.test} sx={sx} value='booking'>
					{t('ui.category.bookings')}
				</ToggleButton>
				<ToggleButton className={classes.test} sx={sx} value='maintenance'>
					{t('nav.category.maintenance')}
				</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	);
};

BookingType.propTypes = {
	onChange: PropTypes.func,
	selectType: PropTypes.string,
};

export default BookingType;
