import { useState, useEffect } from 'react';

import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Typography,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { TextDialog, StyledButton } from '../../../../../components';
import { localizeDateTime } from '../../../../../shared/datetime';
import { useError } from '../../../../../shared/hooks';
import { isFullString, isObject, isUndefined } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { useStyles } from '../../../style';

const UserComment = (props) => {
	const { bookingData, onUpdatedBookingNote, updatedBookingNote } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [adminNote, setAdminNote] = useState('');
	const [commentLength, setCommentLength] = useState(0);
	const [isValid, setIsValid] = useState(true);
	const [loading, setLoading] = useState(false);

	const { data: updatedData, loading: updatedLoading, error: updatedError } = updatedBookingNote;
	const updatedDataReady = isObject(updatedData) && !updatedLoading && !updatedError;

	const updatedDataError = useError({
		value: updatedBookingNote,
		message: `${t('ui.success.message.updated')} ${t('ui.note')}`,
		variant: 'success',
	});

	const resetState = () => {
		setIsValid(true);
		if (bookingData.note) {
			setAdminNote(bookingData.note);
			setCommentLength(bookingData.note.length);
		}
		setOpen(false);
	};

	useEffect(() => {
		if (updatedLoading) {
			setLoading(true);
		} else if (updatedDataReady && !updatedLoading) {
			setLoading(false);
		}
	}, [updatedLoading]);

	useEffect(() => {
		if (isFullString(bookingData.note)) {
			setAdminNote(bookingData.note);
			if (bookingData.note) {
				setCommentLength(bookingData.note.length);
			}
		}
	}, [bookingData]);

	useEffect(() => {
		if (bookingData.note && adminNote !== bookingData.note) {
			setIsValid(false);
		} else if (
			(isUndefined(bookingData.note) && !isFullString(adminNote)) ||
			(bookingData.note && adminNote === bookingData.note)
		) {
			setIsValid(true);
		} else if (isUndefined(bookingData.note) && isFullString(adminNote)) {
			setIsValid(false);
		}
	}, [bookingData, adminNote]);

	const handleNoteOpen = () => setOpen(true);

	const handleClose = () => {
		setOpen(false);
		resetState();
	};

	const handleConfirm = () => {
		resetState();
		onUpdatedBookingNote(bookingData.id, adminNote, bookingData);
		updatedDataError.setStartAction(true);
		resetState();
	};

	const note = isObject(bookingData) && isFullString(bookingData.note) && bookingData.note;

	return (
		<Card className={classes.noteCard}>
			<CardHeader title={t('views.bookingDetail.userNote.header')} />
			<Divider />
			<CardContent className={classes.noteContent}>
				{note ?
					<>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>{t('views.ticketsDetails.lastUpdated')}</TableCell>
									<TableCell>
										{localizeDateTime(bookingData.noteLastModified, undefined, {
											day: '2-digit',
											month: '2-digit',
											year: 'numeric',
											hour: '2-digit',
											minute: '2-digit',
										})}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Box pb={2} pl={2} pr={2} pt={2}>
							<Typography variant='body2'>{note}</Typography>
						</Box>
					</>
				:	<Box display='flex' justifyContent='center' pt={10}>
						{note ? null : <CommentOutlinedIcon fontSize='large' />}
					</Box>
				}
				{note ? null : (
					<Box display='flex' justifyContent='center' pt={2}>
						<Box alignItems='center' display='flex' flexDirection='column'>
							<Typography variant='h5'>{t('views.bookingDetail.userNote.description')}</Typography>
							<Typography variant='body2'>
								{t('views.bookingDetail.userNote.subDescription')}
							</Typography>
						</Box>
					</Box>
				)}
			</CardContent>
			{note ?
				<Box display='flex' pb={1} pl={2} pt={3}>
					<StyledButton onClick={handleNoteOpen} startIcon={<EditIcon />} variant='inline-default'>
						{t('ui.button.inline.edit')}
					</StyledButton>
				</Box>
			:	<Box display='flex' justifyContent='center' pb={3} pt={3}>
					<StyledButton onClick={handleNoteOpen} variant='contained-secondary'>
						{t('view.bookings.bookingdetails.button.contained.addnote')}
					</StyledButton>
				</Box>
			}
			<TextDialog
				comment={adminNote}
				commentLength={commentLength}
				disabled={isValid}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				label={t('ui.note')}
				loading={loading}
				maxLength={200}
				open={open}
				setComment={setAdminNote}
				setCommentLength={setCommentLength}
				title={t('views.bookingDetail.userNote.dialogLabel')}
			/>
		</Card>
	);
};

UserComment.propTypes = {
	bookingData: PropTypes.object.isRequired,
	onUpdatedBookingNote: PropTypes.func,
	updatedBookingNote: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		updatedBookingNote: state.details.updatedBookingNote,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdatedBookingNote: (bookingId, adminNote, bookingData) =>
			dispatch(actions.updatedBookingNote(bookingId, adminNote, bookingData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserComment);
