import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 2, 2, 2),
		marginTop: 'auto',
		width: '100%',
	},
	logo: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		objectFit: 'contain',
		marginRight: theme.spacing(1),
	},
}));
