import {
	Card,
	CardContent,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	Divider,
	CardHeader,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { StaticMap } from '../../../../../../components';
import { handleHubReference } from '../../../../../../shared/utility';

const LocationInfo = (props) => {
	const { className, fetchUnavailabilityData } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={`${t('ui.label.location')} ${t('ui.information.label')}`} />
			<Divider />
			<CardContent className={classes.content}>
				<Box display='flex' flexDirection='column' p={1}>
					<Typography pb={2}>
						{t('views.itemManagement.details.instanceDetails.locationInfo.assigned')}
					</Typography>
					<div>
						<StaticMap
							isImage
							lat={fetchUnavailabilityData.hubReference.latitude}
							lng={fetchUnavailabilityData.hubReference.longitude}
						/>
					</div>
				</Box>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.pickUpReturn')}</TableCell>
							<TableCell>{handleHubReference(fetchUnavailabilityData.hubReference)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.gpsCoordinates')}</TableCell>
							<TableCell>{`${fetchUnavailabilityData.hubReference.latitude}, ${fetchUnavailabilityData.hubReference.longitude}`}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

LocationInfo.propTypes = {
	className: PropTypes.string,
	fetchUnavailabilityData: PropTypes.object,
};

export default LocationInfo;
