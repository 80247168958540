import { useState } from 'react';

import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { AlertDialog, HeaderWithStatus } from '../../../../../components';
import { useError } from '../../../../../shared/hooks';
import { isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const maxLength = 200;

const Dialog = (props) => {
	const {
		dialogStatus,
		setDialogStatus,
		updateTicketActivities,
		ticketData,
		setStatus,
		onUpdateTicketActivities,
		status,
		comment,
		setComment,
	} = props;
	const { t } = useTranslation('general');

	const [commentLength, setCommentLength] = useState(0);

	const classes = useStyles();
	const {
		data: ticketActivitiesData,
		loading: ticketActivitiesLoading,
		error: ticketActivitiesError,
	} = updateTicketActivities;
	const ticketActivitiesDataDone =
		isObject(ticketActivitiesData) && !ticketActivitiesLoading && !ticketActivitiesError;

	const updateTicketMessage = useError({
		value: updateTicketActivities,
		message: `${t('ui.successfully')} ${t('ui.updated')} ${t('ui.ticket.label')} ${ticketData.id}`,
	});

	const resetState = () => {
		setDialogStatus(false);
		setComment('');
		setCommentLength(0);
	};

	const handleCancelDialog = () => {
		setStatus(ticketData.status);
		resetState();
	};

	const handleConfirmDialog = () => {
		updateTicketMessage.setStartAction(true);
		onUpdateTicketActivities(ticketData.id, status, comment);
		resetState();
	};

	const handleComment = (event) => {
		const newValue = event.target.value;
		setComment(newValue);
		setCommentLength(newValue.length);
	};

	return (
		<>
			{dialogStatus ?
				<AlertDialog
					content={
						<Box pb={2} pl={3} pr={3}>
							<Box>
								<Box pb={1.8}>
									<HeaderWithStatus
										header={`${t('views.planboard.addBooking.comment')}`}
										headerSize='h6'
										justifyContent='space-between'
										text={`${commentLength}/${maxLength}`}
										variantText='body2'
									/>
								</Box>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									className={classes.description}
									fullWidth
									maxRows={3}
									multiline
									onChange={handleComment}
									rows={3}
									value={comment}
									variant='outlined'
								/>
							</Box>
						</Box>
					}
					dialogDescription={t('views.ticketsDetails.dialogDescription')}
					dialogTitle={t('views.ticketsDetails.ticketStatus')}
					handleClose={handleCancelDialog}
					handleConfirm={handleConfirmDialog}
					loading={ticketActivitiesLoading && !ticketActivitiesDataDone}
					open={dialogStatus}
					variantDescription='body2'
				/>
			:	null}
		</>
	);
};

Dialog.propTypes = {
	ticketData: PropTypes.object,
	dialogStatus: PropTypes.bool,
	setDialogStatus: PropTypes.func,
	setStatus: PropTypes.func,
	status: PropTypes.string,
	comment: PropTypes.string,
	setComment: PropTypes.func,
	setService: PropTypes.func,
	onUpdateTicketActivities: PropTypes.func,
	updateTicketActivities: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		updateTicketActivities: state.details.updateTicketActivities,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateTicketActivities: (ticketId, status, comment) =>
			dispatch(actions.updateTicketActivities(ticketId, status, comment)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
