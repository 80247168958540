import { atomWithDefault } from 'jotai/utils';

import { userInfoAtom } from '~atoms';
import Organisation from '~features/organisations/interfaces/organisation';
import { User } from '~features/users';

const selectedOrganisationAtom = atomWithDefault<Promise<Organisation> | Organisation | null>(async (get) => {
	try {
		const userInfo = await get(userInfoAtom) as User;
		return userInfo?.organisation;
	} catch (error) {
		console.warn(error);
		return null;
	}
});

export default selectedOrganisationAtom;
