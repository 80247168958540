import { Card } from '@mui/material';
import PropTypes from 'prop-types';

const CustomCard = (props) => {
	const { children, ...rest } = props;
	return <Card {...rest}>{children}</Card>;
};

CustomCard.propTypes = {
	children: PropTypes.any,
};

export default CustomCard;
