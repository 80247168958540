import { colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1, 1.5),
		backgroundColor: colors.grey[100],
		borderRadius: 20,
		display: 'flex',
	},
	text: {
		fontWeight: 'bold',
		marginLeft: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		...theme.typography.input,
	},
	error: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.main,
	},
}));
