import path from 'path';

import { PagedResponse } from '~interfaces';
import { BaseReferenceResponse, IdReferenceResponse } from '~interfaces/responses';
import { BaseServiceMapper, TopologyService } from '~services';

import UserInvitation from '../interfaces/userInvitation';

/**
 * A service to get "business logic" users. E.g. user data from
 * the Core service
 */
class UserInvitationsService extends TopologyService {
	public readonly path = 'invitations';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of users
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getUserInvitations({
		page = 1,
		pageSize = 10,
		sortBy = 'id',
		descending = true,
		...args
	}: {
		page: number;
		pageSize: number;
		organisationId?: string;
		userGroupId?: string;
		searchQuery?: string;
		sortBy?: 'id' | 'email' | 'expirtyDate' | 'userGroup' | 'organisation';
		descending?: boolean;
	}): Promise<PagedResponse<UserInvitation>> {
		const { data } = await this._client.get<PagedResponse<UserInvitationResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				userGroupId: args.userGroupId,
				sortBy: sortBy,
				orderDescending: descending,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
			},
		});

		return {
			...data,
			results: data.results.map((el) => UserInvitationServiceMapper.fromResponse(el)),
		};
	}

	async createUserInvitations(data: UserInvitationRequest): Promise<null> {
		const response = await this._client.post(this.path, data);

		return response.data;
	}

	async deleteUserInvitation(id: string): Promise<null> {
		const { data } = await this._client.delete(path.join(this.path, id));

		return data;
	}
}

class UserInvitationServiceMapper {
	static fromResponse(data: UserInvitationResponse): UserInvitation {
		const {
			id,
			expiryDate,
			cultureInfo,
			organisationReference,
			userGroupReference,
			userReference,
			...rest
		} = data;

		return {
			id: id.toString(),
			expiryDate: new Date(expiryDate),
			...(userReference && {
				user: BaseServiceMapper.fromBaseReferenceResponse(userReference),
			}),
			...(userGroupReference && {
				userGroup: BaseServiceMapper.fromBaseReferenceResponse(userGroupReference),
			}),
			organisation: BaseServiceMapper.fromBaseReferenceResponse(organisationReference),
			culture: cultureInfo,
			...rest,
		};
	}
}

interface UserInvitationRequest {
	organisationId: string | number;
	invites: {
		emails: string[];
		userGroupId: string | number;
		culture: string;
	}[];
}

interface UserInvitationResponse extends IdReferenceResponse {
	email: string;
	link: string;
	expiryDate: string;
	seen: boolean;
	status: string;
	cultureInfo?: string;
	organisationReference: BaseReferenceResponse;
	userGroupReference?: BaseReferenceResponse;
	userReference?: BaseReferenceResponse;
}

export default UserInvitationsService;
