class AddressModel {
	public country: string;
	public city: string;
	public postalCode: string;
	public street: string;
	public number: number;
	public addition?: string;

	constructor(
		country: string,
		city: string,
		postalCode: string,
		street: string,
		number: number,
		addition?: string,
	) {
		this.country = country;
		this.city = city;
		this.postalCode = postalCode;
		this.street = street;
		this.number = number;
		this.addition = addition;
	}

	/**
	 * TODO: better naming for this
	 * Get the street, with housenumber and addition as a string
	 * @returns
	 */
	toStreetAndLowerString(): string {
		return `${this.street} ${this.number}${this.addition ? ` ${this.addition}` : ''}`;
	}

	toPostalCodeAndUpString(): string {
		return `${this.postalCode}, ${this.city} (${this.country})`;
	}
}

export default AddressModel;
