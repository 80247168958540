import { useState, useEffect } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Box, AccordionDetails } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddInstance from './AddInstance';
import BookingCard from './BookingCard';
import Characteristics from './Characteristics';
import ItemDetails from './ItemDetails';
import SettingsCard from './SettingsCard';
import SidePanelDetails from './SidePanelDetails';
import { useStyles, Accordion, AccordionSummary } from './style';
import { Wizard, SidepanelContainer, SidePanel, StyledButton } from '../../../../components';
import { categories } from '../../../../constantsOld';
import { usePrevious, useError, useErrorAcceptedRejected } from '../../../../shared/hooks';
import {
	isUndefined,
	isBoolean,
	isObject,
	isFullString,
	isArray,
	isFullArray,
	isNull,
	isInteger,
	isEmptyObject,
	isSuperAdmin,
	isEmptyString,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const AddItem = (props) => {
	const {
		className,
		onAddItem,
		addItem,
		onResetState,
		onAddInstances,
		currentUser,
		onFetchOrganisation,
		organisation,

		onFetchCharacteristics,
		addInstances,
		characteristics,
	} = props;

	const { t } = useTranslation('general');
	const auth = useAuth();
	const navigate = useNavigate();

	const classes = useStyles();

	const [showSidepanel, setShowSidepanel] = useState(false);
	const [showBottomPanel, setShowBottomPanel] = useState(false);
	const [itemStep, setItemStep] = useState(0);
	const [wizardDetails, setWizardDetails] = useState(null);
	const [selectedOrganisation, setSelectedOrganisation] = useState({});
	const [addInstanceList, setAddInstanceList] = useState(false);

	const [organisationChanged, setOrganisationChanged] = useState(false);

	const {
		data: organisationData,
		loading: organisationLoading,
		error: organisationError,
	} = organisation;

	const { data: characteristicsData } = characteristics;

	const { data: currentUserData } = currentUser;

	const { data: addInstancesData } = addInstances;
	useEffect(() => {
		if (
			!isSuperAdmin(auth.user?.profile.role) &&
			isObject(currentUserData) &&
			isEmptyObject(selectedOrganisation)
		) {
			setSelectedOrganisation(currentUserData.organisationReference);
		}
	}, []);

	const selectedOrganisationId =
		!isEmptyObject(selectedOrganisation) ? selectedOrganisation.id : null;
	const prevSelectedOrganisationId = usePrevious(selectedOrganisationId);

	useEffect(() => {
		if (selectedOrganisation !== prevSelectedOrganisationId) {
			setOrganisationChanged(true);
		}
	}, [selectedOrganisationId, prevSelectedOrganisationId]);

	useEffect(() => {
		if (!organisationLoading && !isNull(selectedOrganisationId)) {
			onFetchOrganisation(selectedOrganisationId);
		} else if (
			!organisationLoading &&
			isObject(currentUser.data) &&
			!currentUser.loading &&
			!currentUser.error
		) {
			onFetchOrganisation(currentUser.data.organisationReference.id);
		}
	}, [selectedOrganisationId, currentUser]);

	// Item Details
	const [selectedName, setSelectedName] = useState('');
	const [isValidName, setIsValidName] = useState(null);
	const [selectedDescription, setSelectedDescription] = useState('');

	const [selectedCategory, setSelectedCategory] = useState();

	const prevSelectedCategoryId = usePrevious(selectedCategory);
	const [categoryChanged, setCategoryChanged] = useState(false);

	const addedInstanceErrorMessage = useErrorAcceptedRejected({
		value: addInstances,
		message: {
			positive: t('views.addItem.message.success.addItemInstance'),
			negative: 'Request was rejected',
		},
	});

	useEffect(() => {
		if (selectedCategory !== prevSelectedCategoryId) {
			setCategoryChanged(true);
		}
	}, [selectedCategory, prevSelectedCategoryId]);

	useEffect(() => {
		if (selectedCategory && !isObject(characteristicsData)) {
			onFetchCharacteristics();
		}
	}, [selectedCategory]);

	useEffect(() => {
		if (!isInteger(selectedCategory) && isObject(characteristicsData)) {
			// onResetState('characteristics');
			onResetState('itemDetails');
		}
	}, [selectedCategory]);

	const [itemImages, setItemImages] = useState([]);

	const itemDetailsValid =
		isFullString(selectedName) &&
		isValidName &&
		isInteger(selectedCategory) &&
		isFullArray(itemImages);

	// Settings Card
	const [selectedTermsAndConditions, setSelectedTermsAndConditions] = useState({});
	const [selectedPricingModel, setSelectedPricingModel] = useState({});
	const [selectedBusinessPricing, setSelectedBusinessPricing] = useState({});
	const [selectPolicyModel, setSelectedPolicyModel] = useState({});
	const [selectedServiceOrganisation, setSelectedServiceOrganisation] = useState({});
	const [selectHub, setSelectHub] = useState(null);
	const [reservationType, setReservationType] = useState('');

	const isValidSettings =
		(!isEmptyObject(selectedTermsAndConditions) &&
			!isEmptyObject(selectedPricingModel) &&
			!isEmptyObject(selectPolicyModel) &&
			!isEmptyObject(selectHub)) ||
		(reservationType === 'nonBookable' &&
			!isEmptyObject(selectedTermsAndConditions) &&
			!isEmptyObject(selectHub));
	// BookingCard
	const [bookingTypes, setBookingTypes] = useState([]);

	const isValidBooking = isFullString(reservationType) && !isEmptyObject(selectedOrganisation);
	// Bike Characteristic
	const [bikePropulsionType, setBikePropulsionType] = useState('');
	const [bikeKmRange, setBikeKmRange] = useState('');
	const [bikeSex, setBikeSex] = useState('');
	const [bikeModel, setBikeModel] = useState('');
	const [hasLuggageCarrier, setHasLuggageCarrier] = useState(false);
	const [numberOfGears, setNumberOfGears] = useState('');
	// Car Characteristic
	const [transmissionType, setTransmissionType] = useState('');
	const [carBodyType, setBodyType] = useState('');
	const [hasAirconditioning, setAirconditioning] = useState(false);
	const [numberOfDoors, setNumberOfDoors] = useState(1);
	const [hasTowHook, setHasTowHook] = useState(false);
	// Office Characteristic
	const [numberOfPeople, setNumberOfPeople] = useState('');
	const [workplaceType, setWorkplaceType] = useState('');
	const [hasProjector, setHasProjector] = useState(false);
	const [hasWhiteboard, setHasWhiteboard] = useState(false);
	const [hasSpeakers, setHasSpeakers] = useState(false);
	const [hasCoffeeTea, setHasCoffeeTea] = useState(false);
	const [hasMonitor, setHasMonitor] = useState(false);
	const [hasWiredInternet, setHasWiredInternet] = useState(false);
	const [hasWiFi, setHasWiFi] = useState(false);
	const [hasAppleTV, setHasAppleTV] = useState(false);
	const [hasMicrophone, setHasMicrophone] = useState(false);
	const [isCateringPossible, setIsCateringPossible] = useState(false);
	const [hasHostAvailable, setHasHostAvailable] = useState(false);
	const [hasIncludingLunch, setHasIncludingLunch] = useState(false);
	const [hasIncludingDrinks, setHasIncludingDrinks] = useState(false);
	const [hasIncludingFlatAndSparklingWater, setHasIncludingFlatAndSparklingWater] = useState(false);
	const [hasIncludingPaperAndPencils, setHasIncludingPaperAndPencils] = useState(false);
	const [hasFreeCopying, setHasFreeCopying] = useState(false);
	const [hasTechnicalServicesAvailable, setHasTechnicalServicesAvailable] = useState(false);
	const [hasConferencePhone, setHasConferencePhone] = useState(false);
	// Trailer Characteristic
	const [axleCount, setAxleCount] = useState('singleAxle');
	const [brakeType, setBrakeType] = useState('braked');
	const [hasTrailerCover, setHasTrailerCover] = useState(false);
	const [cargoSpaceL, setCargoSpaceL] = useState('');
	const [cargoSpaceW, setCargoSpaceW] = useState('');
	const [cargoSpaceH, setCargoSpaceH] = useState('');
	const [maxWeightInKg, setMaxWeightInKg] = useState('');
	const [grossWeightInKg, setGrossWeightInKg] = useState('');
	// Parking Lots Characteristic
	const [parkingLotType, setParkingLotType] = useState('');
	const [hasChargingPoint, setHasChargingPoint] = useState(false);
	const [isCovered, setIsCovered] = useState(false);
	const [isRoundTheClock, setIsRoundTheClock] = useState(false);
	// Boat Characteristic
	const [fuelType, setFuelType] = useState('');
	const [engineType, setEngineType] = useState('');
	const [boatBodyType, setBoatBodyType] = useState('');
	const [hasLifeVests, setHasLifeVests] = useState(false);
	const [hasJoystickDocking, setHasJoystickDocking] = useState(false);
	const [hasSprayhood, setHasSprayhood] = useState(false);
	const [hasRefrigerator, setHasRefrigerator] = useState(false);
	const [hasSoundbox, setHasSoundbox] = useState(false);
	// Мore than one category Characteristic
	const [numberOfSeats, setNumberOfSeats] = useState(1);
	const [kmRange, setKmRange] = useState('');
	const [propulsionType, setPropulsionType] = useState('');
	const [hasNavigation, setNavigation] = useState(false);
	const [hasBluetooth, setBluetooth] = useState(false);
	const [includesBoats, setIncludesBoats] = useState([]);

	useEffect(() => {
		const boats = [];
		if (hasNavigation) {
			boats.push('hasNavigation');
		}
		if (hasBluetooth) {
			boats.push('hasBluetooth');
		}
		if (hasSoundbox) {
			boats.push('hasSoundbox');
		}
		if (hasRefrigerator) {
			boats.push('hasRefrigerator');
		}
		if (hasSprayhood) {
			boats.push('hasSprayhood');
		}
		if (hasJoystickDocking) {
			boats.push('hasJoystickDocking');
		}
		if (hasLifeVests) {
			boats.push('hasLifeVests');
		}
		setIncludesBoats(boats);
	}, [
		hasNavigation,
		hasBluetooth,
		hasSoundbox,
		hasRefrigerator,
		hasSprayhood,
		hasJoystickDocking,
		hasLifeVests,
	]);

	const allCharacteristics =
		isInteger(selectedCategory) ?
			{
				...(selectedCategory === categories.BIKES && {
					category: categories.BIKES,
					itemStep: itemStep,
					bikePropulsionType,
					bikeKmRange,
					bikeSex,
					bikeModel,
					hasLuggageCarrier,
					numberOfGears,
				}),
				...(selectedCategory === categories.OFFICES && {
					category: categories.OFFICES,
					itemStep: itemStep,
					numberOfPeople,
					workplaceType,
					hasProjector,
					hasWhiteboard,
					hasSpeakers,
					hasCoffeeTea,
					hasMonitor,
					hasWiredInternet,
					hasWiFi,
					hasAppleTV,
					hasMicrophone,
					isCateringPossible,
					hasHostAvailable,
					hasIncludingLunch,
					hasIncludingDrinks,
					hasIncludingFlatAndSparklingWater,
					hasIncludingPaperAndPencils,
					hasFreeCopying,
					hasTechnicalServicesAvailable,
					hasConferencePhone,
				}),
				...(selectedCategory === categories.CARS && {
					category: categories.CARS,
					itemStep: itemStep,
					numberOfSeats,
					propulsionType,
					transmissionType,
					kmRange,
					carBodyType,
					hasAirconditioning,
					hasNavigation,
					hasBluetooth,
					numberOfDoors,
					hasTowHook,
				}),
				...(selectedCategory === categories.TRAILERS && {
					category: categories.TRAILERS,
					itemStep: itemStep,
					axleCount,
					brakeType,
					hasTrailerCover,
					maxWeightInKg,
					grossWeightInKg,
					cargoSpaceL,
					cargoSpaceW,
					cargoSpaceH,
				}),
				...(selectedCategory === categories.PARKINGLOTS && {
					category: categories.PARKINGLOTS,
					itemStep: itemStep,
					hasChargingPoint,
					isCovered,
					isRoundTheClock,
					parkingLotType,
				}),
				...(selectedCategory === categories.BOATS && {
					category: categories.BOATS,
					itemStep: itemStep,
					numberOfSeats,
					propulsionType,
					fuelType,
					engineType,
					boatBodyType,
					kmRange,
					hasLifeVests,
					hasJoystickDocking,
					hasNavigation,
					hasSprayhood,
					hasRefrigerator,
					hasBluetooth,
					hasSoundbox,
					includesBoats,
				}),
			}
		:	null;

	const bikePropulsion = ['eBike', 'pedelec'];

	const isValidBike =
		isFullString(bikeSex) &&
		isFullString(bikeModel) &&
		isInteger(numberOfGears) &&
		numberOfGears >= 0 &&
		(bikePropulsionType === 'noSupport' ||
			(bikePropulsion.includes(bikePropulsionType) && isInteger(bikeKmRange)));
	const isValidParkingLotType = isFullString(parkingLotType);
	const isValidCar =
		isInteger(numberOfSeats) &&
		isFullString(carBodyType) &&
		isInteger(numberOfDoors) &&
		(propulsionType === 'hybrid' ||
			(propulsionType === 'electric' && isInteger(kmRange)) ||
			(propulsionType === 'fuel' && isFullString(transmissionType)));
	const isValidTrailer =
		isInteger(cargoSpaceL) &&
		isInteger(cargoSpaceW) &&
		isInteger(cargoSpaceH) &&
		isInteger(maxWeightInKg) &&
		isInteger(grossWeightInKg);
	const isValidOffice =
		isInteger(numberOfPeople) && numberOfPeople >= 1 && isFullString(workplaceType);
	const isValidBoat =
		isFullString(engineType) &&
		(propulsionType === 'hybrid' ||
			(propulsionType === 'electric' && isInteger(kmRange)) ||
			(propulsionType === 'fuel' && isFullString(fuelType)));

	const isValidCharacteristics =
		isValidBike ||
		isValidParkingLotType ||
		isValidCar ||
		isValidTrailer ||
		isValidOffice ||
		isValidBoat;

	// AddInstance
	const [instances, setInstances] = useState([]);
	const [itemInstanceValid, setItemInstanceValid] = useState(false);

	const orgDetailsReady = isObject(organisationData) && !organisationLoading && !organisationError;

	const isAbleToAddItemInstances =
		orgDetailsReady &&
		organisationData?.subscription &&
		organisationData.totalBookableItemInstances + instances.length <=
			organisationData.subscription.maxItemInstances;

	const category = [
		{ selected: selectedCategory === categories.BIKES, name: 'bike' },
		{ selected: selectedCategory === categories.OFFICES, name: 'office' },
		{ selected: selectedCategory === categories.CARS, name: 'car' },
		{ selected: selectedCategory === categories.TRAILERS, name: 'trailer' },
		{ selected: selectedCategory === categories.PARKINGLOTS, name: 'parkingLot' },
		{ selected: selectedCategory === categories.ASSETS, name: 'asset' },
		{ selected: selectedCategory === categories.BOATS, name: 'boat' },
	];

	const instanceCategory = category.find((item) => item.selected);

	const instancesBody = instances.map((item) => {
		const hubId = item.find((hub) => hub.name === 'itemInstanceLocation');
		return {
			hubId: hubId.value.id,
			isPublic: false,
			[instanceCategory.name]: item.reduce(
				(instance, field) => ({
					...instance,
					...(field.name !== 'itemInstanceLocation' && { [field.name]: field.value.value }),
				}),
				{},
			),
		};
	});

	useEffect(() => {
		if (addInstanceList) {
			onAddInstances(addedItemData.id, { instances: instancesBody });
		}
	}, [addInstanceList]);

	// add state
	const { data: addedItemData, loading: addedItemLoading, error: addItemError } = addItem;

	const itemErrorHandling = useError({
		value: addItem,
		message: `${t('ui.successfullyAdded')} ${selectedName}`,
		variant: 'success',
	});

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);

	useEffect(() => {
		onResetState('addItem');
	}, []);

	useEffect(() => {
		if (startedFinishWizard && isFullArray(addInstancesData?.accepted)) {
			navigate('/item-management/items');
			itemErrorHandling.setStartAction(false);
			onResetState('addInstances');
		} else if (isFullArray(addInstancesData?.rejected)) {
			setStartedFinishWizard(false);
		}
	}, [startedFinishWizard, addInstancesData]);

	useEffect(() => {
		if (!isEmptyObject(selectedOrganisation)) {
			setSelectedServiceOrganisation(selectedOrganisation);
		}
	}, [selectedOrganisation]);

	const validate = (value, validation, update, negative = false) => {
		if (!negative ? validation(value) : !validation(value)) {
			update(value);
		}
	};

	const callback = (data) => {
		const {
			name,
			validItemName,
			content,
			organisation: updateOrg,
			updatedCategory,
			updatedItemImages,
			updatedTerms,
			updatedPricingModel,
			updatedBusinessPricing,
			updatedPolicyModel,
			updatedServiceProvider,
			updatedReservationType,
			updatedBookingTypes,
			// Bike
			updatedBikePropulsionType,
			updatedBikeKmRange,
			updatedBikeSex,
			updatedBikeModel,
			updatedHasLuggageCarrier,
			updatedNumberOfGears,
			// Car
			updatedNumberOfSeats,
			updatedPropulsionType,
			updatedTransmissionType,
			updatedKmRange,
			updatedCarBodyType,
			updatedHasAirconditioning,
			updatedNumberOfDoors,
			updatedHasTowHook,
			// Office
			updatedNumberOfPeople,
			updatedWorkplaceType,
			updatedHasProjector,
			updatedHasWhiteboard,
			updatedHasSpeakers,
			updatedHasCoffeeTea,
			updatedHasMonitor,
			updatedHasWiredInternet,
			updatedHasWiFi,
			updatedHasAppleTV,
			updatedHasMicrophone,
			updatedIsCateringPossible,
			updatedHasHostAvailable,
			updatedHasIncludingLunch,
			updatedHasIncludingDrinks,
			updatedHasIncludingFlatAndSparklingWater,
			updatedHasIncludingPaperAndPencils,
			updatedHasFreeCopying,
			updatedHasTechnicalServicesAvailable,
			updatedHasConferencePhone,
			// Trailers
			updatedAxleCount,
			updatedBrakeType,
			updatedHasTrailerCover,
			updatedMaxWeightInKg,
			updatedGrossWeightInKg,
			updatedCargoSpaceL,
			updatedCargoSpaceW,
			updatedCargoSpaceH,
			//Parking Lots
			updatedParkingLotType,
			updatedIsCovered,
			updatedHasChargingPoint,
			updatedIsRoundTheClock,
			//Boat
			updatedFuelType,
			updatedEngineType,
			updatedBoatBodyType,
			updatedHasLifeVests,
			updatedHasJoystickDocking,
			updatedHasSprayhood,
			updatedHasRefrigerator,
			updatedHasSoundbox,
			//
			updatedHasNavigation,
			updatedHasBluetooth,
			updatedHub,
			activeStep,
			updatedItemInstanceValid,
			finishedWizard,
		} = data;

		if (isObject(name)) {
			setSelectedName(name.value);
		}

		if (!isEmptyObject(data)) {
			setWizardDetails(data);
		}
		validate(updatedReservationType, isFullString, setReservationType);
		validate(updatedBookingTypes, isArray, setBookingTypes);

		validate(validItemName, isBoolean, setIsValidName);
		if (isObject(content)) {
			setSelectedDescription(content.value);
		}
		if (isSuperAdmin(auth.user?.profile.role) && isObject(updateOrg)) {
			setSelectedOrganisation(updateOrg.value);
		}
		validate(updatedCategory, isEmptyObject, setSelectedCategory, true);

		validate(updatedItemImages, isArray, setItemImages);
		validate(updatedTerms, isUndefined, setSelectedTermsAndConditions, true);
		validate(updatedPricingModel, isUndefined, setSelectedPricingModel, true);
		validate(updatedBusinessPricing, isUndefined, setSelectedBusinessPricing, true);
		validate(updatedPolicyModel, isUndefined, setSelectedPolicyModel, true);
		validate(updatedServiceProvider, isObject, setSelectedServiceOrganisation);

		validate(updatedBikePropulsionType, isFullString, setBikePropulsionType);
		if (isInteger(updatedBikeKmRange) || isEmptyString(updatedBikeKmRange)) {
			setBikeKmRange(updatedBikeKmRange);
		}
		validate(updatedBikeSex, isFullString, setBikeSex);
		validate(updatedBikeModel, isFullString, setBikeModel);
		validate(updatedHasLuggageCarrier, isBoolean, setHasLuggageCarrier);
		if (isInteger(updatedNumberOfGears) || isEmptyString(updatedNumberOfGears)) {
			setNumberOfGears(updatedNumberOfGears);
		}

		validate(updatedNumberOfSeats, isInteger, setNumberOfSeats);
		validate(updatedPropulsionType, isFullString, setPropulsionType);
		validate(updatedTransmissionType, isFullString, setTransmissionType);
		if (isInteger(updatedKmRange) || isEmptyString(updatedKmRange)) {
			setKmRange(updatedKmRange);
		}
		validate(updatedCarBodyType, isFullString, setBodyType);
		validate(updatedHasAirconditioning, isBoolean, setAirconditioning);
		validate(updatedHasNavigation, isBoolean, setNavigation);
		validate(updatedHasBluetooth, isBoolean, setBluetooth);
		validate(updatedNumberOfDoors, isInteger, setNumberOfDoors);
		validate(updatedHasTowHook, isBoolean, setHasTowHook);

		validate(updatedNumberOfPeople, isInteger, setNumberOfPeople);
		validate(updatedWorkplaceType, isFullString, setWorkplaceType);
		validate(updatedHasProjector, isBoolean, setHasProjector);
		validate(updatedHasWhiteboard, isBoolean, setHasWhiteboard);
		validate(updatedHasSpeakers, isBoolean, setHasSpeakers);
		validate(updatedHasCoffeeTea, isBoolean, setHasCoffeeTea);
		validate(updatedHasMonitor, isBoolean, setHasMonitor);
		validate(updatedHasWiredInternet, isBoolean, setHasWiredInternet);
		validate(updatedHasWiFi, isBoolean, setHasWiFi);
		validate(updatedHasAppleTV, isBoolean, setHasAppleTV);
		validate(updatedHasMicrophone, isBoolean, setHasMicrophone);
		validate(updatedIsCateringPossible, isBoolean, setIsCateringPossible);
		validate(updatedHasHostAvailable, isBoolean, setHasHostAvailable);
		validate(updatedHasIncludingLunch, isBoolean, setHasIncludingLunch);
		validate(updatedHasIncludingDrinks, isBoolean, setHasIncludingDrinks);
		validate(
			updatedHasIncludingFlatAndSparklingWater,
			isBoolean,
			setHasIncludingFlatAndSparklingWater,
		);
		validate(updatedHasIncludingPaperAndPencils, isBoolean, setHasIncludingPaperAndPencils);
		validate(updatedHasFreeCopying, isBoolean, setHasFreeCopying);
		validate(updatedHasTechnicalServicesAvailable, isBoolean, setHasTechnicalServicesAvailable);
		validate(updatedHasConferencePhone, isBoolean, setHasConferencePhone);
		// Trailers
		validate(updatedAxleCount, isFullString, setAxleCount);
		validate(updatedBrakeType, isFullString, setBrakeType);
		validate(updatedHasTrailerCover, isBoolean, setHasTrailerCover);

		if (isInteger(updatedMaxWeightInKg) || isEmptyString(updatedMaxWeightInKg)) {
			setMaxWeightInKg(updatedMaxWeightInKg);
		}
		if (isInteger(updatedGrossWeightInKg) || isEmptyString(updatedGrossWeightInKg)) {
			setGrossWeightInKg(updatedGrossWeightInKg);
		}
		if (isInteger(updatedCargoSpaceL) || isEmptyString(updatedCargoSpaceL)) {
			setCargoSpaceL(updatedCargoSpaceL);
		}
		if (isInteger(updatedCargoSpaceW) || isEmptyString(updatedCargoSpaceW)) {
			setCargoSpaceW(updatedCargoSpaceW);
		}
		if (isInteger(updatedCargoSpaceH) || isEmptyString(updatedCargoSpaceH)) {
			setCargoSpaceH(updatedCargoSpaceH);
		}
		// Parking Lots
		validate(updatedParkingLotType, isFullString, setParkingLotType);
		validate(updatedIsCovered, isBoolean, setIsCovered);
		validate(updatedHasChargingPoint, isBoolean, setHasChargingPoint);
		validate(updatedIsRoundTheClock, isBoolean, setIsRoundTheClock);
		// Boat
		validate(updatedFuelType, isFullString, setFuelType);
		validate(updatedEngineType, isFullString, setEngineType);
		validate(updatedBoatBodyType, isFullString, setBoatBodyType);
		validate(updatedHasLifeVests, isBoolean, setHasLifeVests);
		validate(updatedHasJoystickDocking, isBoolean, setHasJoystickDocking);
		validate(updatedHasSprayhood, isBoolean, setHasSprayhood);
		validate(updatedHasRefrigerator, isBoolean, setHasRefrigerator);
		validate(updatedHasSoundbox, isBoolean, setHasSoundbox);

		validate(updatedHub, isArray, setSelectHub, true);
		validate(updatedItemInstanceValid, isBoolean, setItemInstanceValid);
		validate(activeStep, isInteger, setItemStep);

		if (activeStep === 0) {
			setShowSidepanel(false);
		} else {
			setShowSidepanel(true);
		}
		if (
			(selectedCategory !== categories.ASSETS ? activeStep === 4 : activeStep === 3) &&
			isNull(addedItemData) &&
			!startedFinishWizard &&
			!addedItemLoading
		) {
			itemErrorHandling.setStartAction(true);
			const bodyData = {
				hubId: selectHub.id,
				categoryId: selectedCategory,
				termsId: selectedTermsAndConditions.id,
				policyId: selectPolicyModel.id,
				pricingModelId: selectedPricingModel.id,
				businessPricingModelId: selectedBusinessPricing.id,
				...(selectedServiceOrganisation?.partnerOrganisation && {
					serviceProviderId: selectedServiceOrganisation.partnerOrganisation.id,
				}),
				reservationType,
				bookingTypes,
				name: selectedName,
				description: data.content.value,
				...(selectedCategory === categories.PARKINGLOTS && {
					parkingLotCharacteristics: {
						parkingLotType: updatedParkingLotType,
						hasChargingPoint: updatedHasChargingPoint,
						isCovered: updatedIsCovered,
						isRoundTheClock: updatedIsRoundTheClock,
					},
				}),
				...(selectedCategory === categories.BIKES && {
					bikeCharacteristics: {
						bikePropulsionType: updatedBikePropulsionType,
						bikeKmRange: updatedBikeKmRange,
						bikeSex: updatedBikeSex,
						bikeModel: updatedBikeModel,
						hasLuggageCarrier: updatedHasLuggageCarrier,
						numberOfGears: updatedNumberOfGears,
					},
				}),
				...(selectedCategory === categories.TRAILERS && {
					trailerCharacteristics: {
						axleCount: updatedAxleCount,
						brakeType: updatedBrakeType,
						hasTrailerCover: updatedHasTrailerCover,
						maxWeightInKg: updatedMaxWeightInKg,
						grossWeightInKg: updatedGrossWeightInKg,
						cargoSpaceL: updatedCargoSpaceL,
						cargoSpaceW: updatedCargoSpaceW,
						cargoSpaceH: updatedCargoSpaceH,
					},
				}),
				...(selectedCategory === categories.CARS && {
					carCharacteristics: {
						numberOfSeats: updatedNumberOfSeats,
						propulsionType: updatedPropulsionType,
						transmissionType: updatedTransmissionType,
						...(propulsionType === 'electric' && { kmRange: updatedKmRange }),
						carBodyType: updatedCarBodyType,
						hasAirconditioning: updatedHasAirconditioning,
						hasNavigation: updatedHasNavigation,
						hasBlueTooth: updatedHasNavigation,
						numberOfDoors: updatedNumberOfDoors,
						hasTowHook: updatedHasTowHook,
					},
				}),
				...(selectedCategory === categories.BOATS && {
					boatCharacteristics: {
						numberOfSeats: updatedNumberOfSeats,
						propulsionType: updatedPropulsionType,
						engineType: updatedEngineType,
						...(propulsionType === 'fuel' && { fuelType: updatedFuelType }),
						...(propulsionType === 'electric' && { kmRange: updatedKmRange }),
						boatBodyType: updatedBoatBodyType,
						hasLifeVests: updatedHasLifeVests,
						hasJoystickDocking: updatedHasJoystickDocking,
						hasNavigation: updatedHasNavigation,
						hasSprayhood: updatedHasSprayhood,
						hasRefrigerator: updatedHasRefrigerator,
						hasBluetooth: updatedHasBluetooth,
						hasSoundbox: updatedHasSoundbox,
					},
				}),
				...(selectedCategory === categories.OFFICES && {
					officeCharacteristics: {
						numberOfPeople: updatedNumberOfPeople,
						workplaceType: updatedWorkplaceType,
						hasProjector: updatedHasProjector,
						hasWhiteboard: updatedHasWhiteboard,
						hasSpeakers: updatedHasSpeakers,
						hasCoffeeTea: updatedHasCoffeeTea,
						hasMonitor: updatedHasMonitor,
						hasWiredInternet: updatedHasWiredInternet,
						hasWiFi: updatedHasWiFi,
						hasAppleTV: updatedHasAppleTV,
						hasMicrophone: updatedHasMicrophone,
						isCateringPossible: updatedIsCateringPossible,
						hasHostAvailable: updatedHasHostAvailable,
						hasIncludingLunch: updatedHasIncludingLunch,
						hasIncludingDrinks: updatedHasIncludingDrinks,
						hasIncludingFlatAndSparklingWater: updatedHasIncludingFlatAndSparklingWater,
						hasIncludingPaperAndPencils: updatedHasIncludingPaperAndPencils,
						hasFreeCopying: updatedHasFreeCopying,
						hasTechnicalServicesAvailable: updatedHasTechnicalServicesAvailable,
						hasConferencePhone: updatedHasConferencePhone,
					},
				}),
			};
			onAddItem(bodyData, itemImages);
			setShowBottomPanel(true);
		}
		if (finishedWizard) {
			setAddInstanceList(finishedWizard);
		} else {
			setAddInstanceList(false);
		}
		if (finishedWizard && !isObject(addItemError)) {
			addedInstanceErrorMessage.setStartAction(true);
			setStartedFinishWizard(true);
		}
	};
	const addItemInstance = (itemInstanceFields) => {
		if (isFullArray(itemInstanceFields)) {
			setInstances([...instances, itemInstanceFields]);
		}
	};

	useEffect(() => {
		if (addInstanceList) {
			addItemInstance();
		}
	}, [addInstanceList]);

	const handleCheckFieldUnique = (fieldName, value) => {
		let isUniqueField = true;
		instances.forEach((instance) => {
			instance.forEach((field) => {
				if (field.name === fieldName && field.value.value.toLowerCase() === value.toLowerCase()) {
					isUniqueField = false;
				}
			});
		});
		return isUniqueField;
	};

	const addUserSteps = [
		{
			name: t('views.addItem.steps.bookingType'),
			content: BookingCard,
			valid: isValidBooking,
			props: { bookingTypes, reservationType, itemStep, selectedOrganisation },
		},
		{
			name: t('views.addItem.steps.itemDetails'),
			content: ItemDetails,
			valid: itemDetailsValid,
			props: {
				selectedName,
				selectedDescription,
				selectedCategory,
				itemImages,
				itemStep,
				selectedOrganisation,
			},
		},
		...(selectedCategory !== 6 ?
			[
				{
					name: t('ui.characteristics'),
					content: Characteristics,
					valid: isValidCharacteristics,
					props: {
						allCharacteristics,
					},
				},
			]
		:	[]),
		{
			name: t('views.addItem.steps.settings'),
			content: SettingsCard,
			valid: isValidSettings,
			props: {
				selectedOrganisation,
				organisationChanged,
				setOrganisationChanged,
				categoryChanged,
				setCategoryChanged,
				reservationType,
				selectedOrganisationId: isObject(selectedOrganisation) ? selectedOrganisation.id : null,
				selectedCategory,
				selectedTermsAndConditions,
				selectedPricingModel,
				selectPolicyModel,
				selectedServiceOrganisation,
				itemStep,
				selectHub,
				selectedBusinessPricing,
			},
		},
		{
			name: t('views.addItem.steps.instances'),
			content: AddInstance,
			valid: (itemInstanceValid && isFullArray(instances)) || isFullArray(instances),
			props: {
				isAbleToAddItemInstances,
				reservationType,
				selectedCategory,
				addItemInstance,
				itemStep,
				itemId: addedItemData ? addedItemData.id : null,
				handleCheckFieldUniqueLocally: handleCheckFieldUnique,
				selectedOrganisationId: isObject(selectedOrganisation) ? selectedOrganisation.id : null,
				selectHub,
			},
		},
	];

	//Show only 10 instances initially
	const [showAllInstances, setShowAllInstances] = useState(false);
	const [instancesToBeDisplayed, setInstancesToBeDisplayed] = useState(instances);

	useEffect(() => {
		setInstancesToBeDisplayed(showAllInstances ? instances : instances.slice(0, 10));
	}, [showAllInstances, instances.length]);

	const handleShowAllInstances = () => {
		setShowAllInstances(!showAllInstances);
	};

	const sidepanel = (
		<SidepanelContainer className={clsx(classes.root, className)} sticky={true}>
			<SidePanelDetails itemImages={itemImages} itemStep={itemStep} wizardDetails={wizardDetails} />
			{isFullArray(instances) && showBottomPanel ?
				<SidePanel
					className={classes.bottomPanel}
					title={t('views.addItem.sidepanel.title.instances')}
				>
					{instancesToBeDisplayed.map((instance, iIndex) => (
						<Accordion key={iIndex}>
							{instance.map((field, fIndex) => (
								<AccordionSummary
									key={field.name}
									{...(fIndex === 0 && { expandIcon: <ExpandMoreIcon htmlColor='#3f51b5' /> })}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<AccordionDetails className={classes.expansionDetails}>
										<div className={classes.expansion}>
											<Box display='flex' flexDirection='column'>
												<Typography className={classes.panelText}>{field.label}</Typography>
											</Box>
											<Box display='flex' flexDirection='column'>
												<Typography className={classes.panelText} variant='body2'>
													{field.value.value}
												</Typography>
											</Box>
										</div>
									</AccordionDetails>
								</AccordionSummary>
							))}
						</Accordion>
					))}
					{instances?.length > 10 && (
						<StyledButton
							className={classes.showAllButton}
							fullWidth
							onClick={handleShowAllInstances}
							startIcon={showAllInstances ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							variant='contained-tertiary'
						>
							{showAllInstances ?
								`${t('ui.hideAll')} ${t('ui.category.itemInstances')}`
							:	`${t('ui.showAll')} (+${instances.length - 10} ${t('ui.category.itemInstances')})`}
						</StyledButton>
					)}
				</SidePanel>
			:	undefined}
		</SidepanelContainer>
	);

	return (
		<Wizard
			callback={callback}
			loading={startedFinishWizard}
			sidebar={showSidepanel ? sidepanel : undefined}
			stepperType={'progress'}
			steps={addUserSteps}
			title={t('views.addItem.page.title')}
		/>
	);
};

AddItem.propTypes = {
	className: PropTypes.string,

	addItem: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	hubsDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	termsDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	pricingModels: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	policies: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisation: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	addInstances: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	characteristics: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchCharacteristics: PropTypes.func,

	onResetState: PropTypes.func,
	onAddInstances: PropTypes.func,
	onFetchHubs: PropTypes.func,
	onAddItem: PropTypes.func,
	onDeleteItemInstance: PropTypes.func,
	onFetchOrganisation: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		addItem: state.details.addItem,

		deleteItemInstance: state.condition.deleteItemInstance,
		hubsDetails: state.paged.hubs,
		currentUser: state.details.currentUser,
		organisation: state.details.organisation,

		addInstances: state.details.addInstances,
		characteristics: state.details.characteristics,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onAddItem: (bodyData, itemImages) => dispatch(actions.addItem(bodyData, itemImages)),
		onAddInstances: (itemId, body) => dispatch(actions.addInstances(itemId, body)),
		onDeleteItemInstance: (itemId, itemInstanceId) =>
			dispatch(actions.deleteItemInstance(itemId, itemInstanceId)),
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
		onFetchOrganisation: (id) => dispatch(actions.fetchOrganisation(id)),
		onFetchCharacteristics: (id) => dispatch(actions.fetchCharacteristics(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItem);
