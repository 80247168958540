import { useEffect } from 'react';

import { Card, CardContent, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { useStyles } from './style';
import {
	Tip,
	SelectedOrganisationCard,
	SearchAutocomplete,
	FormField,
} from '../../../../../components';
import {
	useWizardFormField,
	useDebouncedWizardSave,
	useDebouncedBackendFieldCheck,
} from '../../../../../shared/hooks';
import { isFullString, isEmptyObject, isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const UserGroupDetails = (props) => {
	const classes = useStyles();
	const {
		organisationsList,
		onFetchOrganisations,
		save,
		organisation,
		userGroupName: savedUserGroupName,
		description,
		currentUser,
		onValidateUserGroupName,
		validatedUserGroupName,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();
	const role = auth.user?.profile.role.toLowerCase();
	const superAdmin = isFullString(role) && role === 'superadmin';

	const {
		data: currentUserData,
		loading: currentUserLoading,
		error: currentUserError,
	} = currentUser;
	const currentUserReady = isObject(currentUserData) && !currentUserLoading && !currentUserError;

	const selectedOrganisation = useWizardFormField(organisation, { required: true });
	useEffect(() => {
		if (superAdmin) {
			save({
				organisation: {
					value: selectedOrganisation.value,
					valid: selectedOrganisation.isValid,
				},
			});
		} else if (currentUserReady) {
			save({
				organisation: {
					value: currentUserData.organisationReference,
					valid: true,
				},
			});
		}
	}, [selectedOrganisation.value, selectedOrganisation.isValid, currentUserReady]);

	const resetSelectedOrganisation = () => {
		selectedOrganisation.onChange({});
	};

	const userGroupName = useWizardFormField(savedUserGroupName, {
		required: true,
		minLength: 3,
		maxLength: 128,
	});
	const checkedUserGroupName = useDebouncedBackendFieldCheck(
		superAdmin ?
			!isEmptyObject(selectedOrganisation.value) ?
				userGroupName.value
			:	''
		:	userGroupName.value,
		onValidateUserGroupName,
		validatedUserGroupName,
		superAdmin ? { organisationId: selectedOrganisation.value.id } : {},
		true,
	);
	useDebouncedWizardSave(
		'userGroupName',
		userGroupName.value,
		userGroupName.isValid && checkedUserGroupName.isUnique,
		save,
		300,
	);

	const userGroupDescription = useWizardFormField(description, { maxLength: 500 });
	useDebouncedWizardSave(
		'userGroupDescription',
		userGroupDescription.value,
		userGroupDescription.isValid,
		save,
		300,
	);

	return (
		<Card>
			<CardContent className={classes.cardContent}>
				<form className={classes.form}>
					{superAdmin ?
						<div>
							<Typography variant='h3'>
								{t('views.addUserGroup.details.chooseOrganisation.title')}
							</Typography>
							<Typography className={classes.subHeader} variant='body2'>
								{t('views.addUserGroup.details.chooseOrganisation.description')}
							</Typography>
							{!isEmptyObject(selectedOrganisation.value) ?
								<SelectedOrganisationCard
									handleClose={resetSelectedOrganisation}
									name={selectedOrganisation.value.name}
								/>
							:	<SearchAutocomplete
									dataList={organisationsList}
									label={t('ui.organisation')}
									listType={'organisations'}
									onFetchData={onFetchOrganisations}
									placeholder={t('views.addPolicy.aboutPolicy.placeholder.searchOrganisation')}
									setSelected={selectedOrganisation.onChange}
								/>
							}
						</div>
					:	null}
					<div>
						<Box>
							<Typography variant='h4'>
								{t('views.addUserGroup.details.userGroupName.title')}{' '}
							</Typography>
							<Typography className={classes.subHeader} variant='body2'>
								{t('views.addUserGroup.details.userGroupName.description')}.
							</Typography>
						</Box>
						<Box>
							<FormField
								extraValidHelperText={
									superAdmin && isEmptyObject(selectedOrganisation.value) ?
										t('views.addUserGroup.error.userGroupNameAlreadyInSystem')
									:	t('views.addUserGroup.error.userGroupNameAlreadyInUse')
								}
								isExtraValid={checkedUserGroupName.isUnique}
								label={t('ui.label.name')}
								name='userGroupName'
								placeholder={t('views.addUserGroup.details.userGroupName.title')}
								required
								variable={userGroupName}
							/>
						</Box>
					</div>
					<div>
						<Box display='flex' justifyContent='space-between' pb={1}>
							<Typography variant='h5'>{t('ui.label.description')}</Typography>
							<Typography className={classes.counterText}>
								{userGroupDescription.value.length}/500 {t('ui.characters')}
							</Typography>
						</Box>
						<FormField
							multiline
							name='userGroupDescription'
							placeholder={t('views.addUserGroup.details.userGroupDescription.description')}
							required
							rows={5}
							variable={userGroupDescription}
						/>
					</div>
					<Tip arrowDirection={'top'} message={t('views.addUserGroup.details.tip')} />
				</form>
			</CardContent>
		</Card>
	);
};
UserGroupDetails.propTypes = {
	className: PropTypes.string,

	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchOrganisations: PropTypes.func,
	organisation: PropTypes.object,
	save: PropTypes.func,
	userGroupName: PropTypes.string,
	description: PropTypes.string,
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onValidateUserGroupName: PropTypes.func,
	validatedUserGroupName: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};
const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,
		currentUser: state.details.currentUser,
		validatedUserGroupName: state.details.validatedUserGroupName,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onValidateUserGroupName: ({ value, organisationId }) =>
			dispatch(actions.validateUserGroupName(value, organisationId)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(UserGroupDetails);
