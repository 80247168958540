import path from 'path';

import axios, { AxiosResponse } from 'axios';

import {
	DayOfWeekSchedule,
	IdReference,
	PagedResponse,
	SkcPagedResponse,
	SkcSingleResponse,
	WeeklySchedule,
} from '~interfaces';
import { IdReferenceRequest } from '~interfaces/requests';
import { BaseReferenceResponse, IdReferenceResponse } from '~interfaces/responses';
import { BaseServiceMapper, TopologyProxiedSkopeiConnectService } from '~services';
import { parseTime, time24Format } from '~utils/dateUtils';
import { dayOfWeekToWeeklySchedule, flattenWeeklySchedules } from '~utils/scheduleUtils';

import ParticipantTypeEnum from '../../accessOld/enums/participantTypeEnum';
import TargetTypeEnum from '../../accessOld/enums/targetTypeEnum';
import SkcAccessRule from '../interfaces/skcAccessRule';
import AccessRuleTarget, { SkcAccessRuleTarget } from '../../accessOld/interfaces/accessRuleTarget';
import AccessRuleWriteable from '../../accessOld/interfaces/accessRuleWriteable';

// Set a date at which it is considered "never ending"
const unlimitedDateMark = new Date(8999, 12, 12);

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcAccessRulesService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'access-rules';

	constructor() {
		super();
	}

	/**
	 * Get a paginated list of access rules
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getAccessRules({
		page = 1,
		pageSize = 10,
		...args
	}: {
		page: number;
		pageSize: number;
		organisationId?: string
	}): Promise<PagedResponse<SkcAccessRule> | null> {
		const { data } = await this._client.get<SkcPagedResponse<SkcAccessRuleResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId,
			},
		});

		return {
			...this.mapMetaResponse(data),
			results: data.data.map((el) => SkcAccessRulesServiceMapper.fromResponse(el)),
		};
	}

	/**
	 * Get the details of a single access rule
	 * @param id
	 * @returns
	 */
	async getAccessRuleById(id: string): Promise<SkcAccessRule> {
		const response = await this._client.get<SkcSingleResponse<SkcAccessRuleResponse>>(path.join(this.path, id));

		return SkcAccessRulesServiceMapper.fromResponse(response.data.data);
	}

	/**
	 * Create an access rule
	 * @param data
	 * @returns Reference to the access rule
	 */
	async createAccessRule(data: SkcAccessRule): Promise<IdReference> {
		const content = SkcAccessRulesServiceMapper.toRequest(data);

		const response = await this._client.post<
			IdReference,
			AxiosResponse<IdReference, SkcAccessRuleRequest>,
			SkcAccessRuleRequest
		>(this.path, content);

		return response.data;
	}

	async updateAccessRule(id: string, data: SkcAccessRule): Promise<IdReference> {
		const content = SkcAccessRulesServiceMapper.toRequest(data);

		const response = await this._client.put<
			SkcSingleResponse<IdReferenceResponse>,
			AxiosResponse<SkcSingleResponse<IdReferenceResponse>, SkcAccessRuleRequest>,
			SkcAccessRuleRequest
		>(
			path.join(this.path, id),
			content
		);

		return {
			id: response.data.data.id.toString()
		};
	}

	/**
	 * Delete an access rule
	 * @param id
	 * @returns
	 */
	async deleteAccessRule(id: string): Promise<IdReference> {
		const { data } = await this._client.delete<IdReference>(path.join(this.path, id));

		return data;
	}
}

class SkcAccessRulesServiceMapper {
	static fromResponse(data: SkcAccessRuleResponse): SkcAccessRule {
		const { id, name, startDate, endDate: endDateString, schedule, userGroup, deviceGroup, ...rest } = data;

		const endDate = endDateString ? new Date(endDateString) : null;

		return {
			...rest,
			...BaseServiceMapper.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			period: {
				start: new Date(startDate),
				end: endDate && endDate < unlimitedDateMark ? endDate : undefined,
			},
			schedule: BaseServiceMapper.fromBaseReferenceResponse(schedule),
			deviceGroup: BaseServiceMapper.fromBaseReferenceResponse(deviceGroup),
			userGroup: BaseServiceMapper.fromBaseReferenceResponse(userGroup),
		};
	}

	static toRequest(data: SkcAccessRule): SkcAccessRuleRequest {
		const { label, period, schedule, userGroup, deviceGroup, ...rest } = data;

		return {
			...rest,
			name: label,
			startDate: period.start.toISOString(),
			endDate: period.end?.toISOString(),
			schedule: schedule.id,
			deviceGroup: deviceGroup.id,
			userGroup: userGroup.id
		};
	}
}

interface BaseAccessRuleDto {
	name: string;
	startDate: string;
	endDate?: string;
	description?: string;
}

interface SkcAccessRuleRequest extends BaseAccessRuleDto {
	schedule: number | string;
	userGroup: number | string;
	deviceGroup: number | string;
}

interface SkcAccessRuleResponse extends BaseAccessRuleDto, BaseReferenceResponse {
	schedule: IdReferenceResponse;
	userGroup: BaseReferenceResponse;
	deviceGroup: BaseReferenceResponse;
};

export default SkcAccessRulesService;
