import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.common.white,
		boxShadow: theme.shadows[1],
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	adminPanel: {
		color: 'black',
	},
}));
